
export const stockTypes = [
  {
    query: '',
    title: 'All'
  },
  {
    query: 'COLLECTION',
    title: 'Collection'
  },
  {
    query: 'REGULAR',
    title: 'Regular'
  },
  {
    query: 'READY-TO-WEAR',
    title: 'Ready to Wear'
  }
]