import Styles from './style.module.scss'

export default function TextArea({
  value = '',
  setValue = () => { },
  placeholder = ''
}) {

  return (
    <div className={Styles.container}>
      <textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  )

}