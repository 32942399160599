import ListingOverviewLayout from '@Organism/Product/ListingOverview'
import Styles from './style.module.scss'
import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function ListingOverviewPage() {

  const { packageListingOverview } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <ListingOverviewLayout
            isLoading={true}
            defaultData={null}
          />
        }
      >
        <Await
          resolve={packageListingOverview}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageListingOverview) => (
            <ListingOverviewLayout
              defaultData={packageListingOverview?.data}
            />

          )}
        </Await>


      </Suspense>
    </div>
  )
}