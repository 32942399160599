import RekhasBG from '@Atom/RekhasBG'
import { priceFormat } from '@Helpers/priceFormat'
import useOutsideClick from '@Hooks/useOutsideClick'
import useWindowSize from '@Hooks/useWindowSize'
import StackedItemInfo from '@Molecule/StackedItemInfo'
import TableSet from '@Molecule/TableSet'
import { useEffect, useMemo, useRef, useState } from 'react'

export default function BuyerListLayout({
  defaultData,
  isLoading,
  trigger,
  setTrigger,
  setShowFilter,
  setSelectedCustomerDetails
}) {
  
  const { width } = useWindowSize()
  const [selectedOrder, setSelectedOrder] = useState(null)

  const itemListPopupRef = useRef()
  const itemListPopupOutsideClick = useOutsideClick(itemListPopupRef);

  useEffect(() => {
    if (selectedOrder && itemListPopupOutsideClick) {
      setSelectedOrder(null);
    }
  }, [itemListPopupOutsideClick, selectedOrder])



  const template = useMemo(() => {
    return ({
      data: defaultData?.customers?.length
      ?
      defaultData?.customers?.map(obj => {
        return {
          ...obj,
          id: obj?._id,
          code: obj?.code,
          customerName: obj?.name,
          customerPhoneNumber: obj?.phoneNumber,
          numOfOrders: obj?.numOfOrders,
          numOfItems: obj?.numOfItems,
          outstanding: `Rp${priceFormat(obj?.totalOutstanding)}`,
          revenue: `Rp${priceFormat(obj?.totalRevenue)}`,
        }
      })
      :
      [],
      data1: [
        {
          id: 1,
          customerName: 'MOHAMMAD BAGUS FIRDIANSYAH',
          customerPhoneNumber: '+62 81293674067',
          orderId: '123456789102938',
          numOfOrders: 20,
          boughtItemsArr: [
            {
              id: 1,
              name: 'COTTON POP MOLLY WHITE',
              category: 'Collection',
              length: '5 Meters',
              type: 'Pants',
              cutter: null,
              tailor: null,
              measurements: [
                {
                  name: 'Panjang',
                  value: '1 m'
                },
                {
                  name: 'Pinggang',
                  value: '0,5 m'
                },
                {
                  name: 'Pinggul',
                  value: '0,5 m'
                },
                {
                  name: 'Lebar',
                  value: '0.5 m'
                }
              ]
            },
            {
              id: 2,
              name: 'ALBERTO WHITE',
              category: 'Regular',
              length: '1,5 Meters',
              type: 'Suit',
              cutter: null,
              tailor: null,
              measurements: [
                {
                  name: 'Panjang',
                  value: '1 m'
                },
                {
                  name: 'Kerah',
                  value: '0,5 m'
                },
                {
                  name: 'Lingkar Dada',
                  value: '0,5 m'
                },
                {
                  name: 'Lebar',
                  value: '0.5 m'
                },
                {
                  name: 'Pundak',
                  value: '0.5 m'
                },
                {
                  name: 'Lengan Panjang',
                  value: '0.5 m'
                },
                {
                  name: 'Diameter LT',
                  value: '0.5 m'
                },
              ]
            },
            {
              id: 3,
              name: 'COTTON POP MOLLY WHITE',
              category: 'Collection',
              length: '5 Meters',
              type: 'Pants',
              cutter: null,
              tailor: null,
              measurements: [
                {
                  name: 'Panjang',
                  value: '1 m'
                },
                {
                  name: 'Pinggang',
                  value: '0,5 m'
                },
                {
                  name: 'Pinggul',
                  value: '0,5 m'
                },
                {
                  name: 'Lebar',
                  value: '0.5 m'
                }
              ]
            },
            {
              id: 4,
              name: 'ALBERTO WHITE',
              category: 'Regular',
              length: '1,5 Meters',
              type: 'Suit',
              cutter: null,
              tailor: null,
              measurements: [
                {
                  name: 'Panjang',
                  value: '1 m'
                },
                {
                  name: 'Kerah',
                  value: '0,5 m'
                },
                {
                  name: 'Lingkar Dada',
                  value: '0,5 m'
                },
                {
                  name: 'Lebar',
                  value: '0.5 m'
                },
                {
                  name: 'Pundak',
                  value: '0.5 m'
                },
                {
                  name: 'Lengan Panjang',
                  value: '0.5 m'
                },
                {
                  name: 'Diameter LT',
                  value: '0.5 m'
                },
              ]
            },
          ],
          outstanding: `Rp${priceFormat(2000000)}`,
          revenue: `Rp${priceFormat(2000000)}`,
          invoices: [
            {
              id: 'ORDER-0000001',
            },
            {
              id: 'ORDER-0000001',
            },
            {
              id: 'ORDER-0000001',
            },
          ]
        }

      ],
      columns: [
        {
          name: 'customerName',
          label: 'Customer Name',
          minWidth: '290px',
          renderData: (row) => (
            <StackedItemInfo
              title={row?.customerName}
              label={row?.customerPhoneNumber || '-'}
              withoutPicture
              titleWidth={width <= 768 ? '150px' : '100%'}
            />
          ),
          skeleton:
            <StackedItemInfo
              isSkeleton
              withoutPicture
              titleWidth={width <= 768 ? '150px' : '100%'}
            />

        },
        {
          name: 'numOfOrders',
          label: 'Num of Orders',
          minWidth: '208px',
          maxWidth: '208px'
        },
        {
          name: 'numOfItems',
          label: 'Have Bought',
          minWidth: '208px',
          // renderData: (row) => (
          //   row?.boughtItemsArr?.length > 1
          //     ? <div
          //       className={Styles.itemsIndicator}
          //     >
          //       <span>{row?.boughtItemsArr?.length} Items</span>
          //       {/* <Button
          //         buttonText='Item List'
          //         size='sm'
          //         variant='secondary'
          //         iconName={selectedOrder && selectedOrder?.id === row?.id ? 'chevron-up' : 'chevron-down'}
          //         iconPlacement='end'
          //         borderRadius='4px'
          //         fixedPadding={'2px 8px'}
          //         withIcon
          //         disableActive
          //         onClick={(e) => handleShowList(e, row)}
          //       /> */}
          //     </div>
          //     : <ItemTitle
          //       name={row?.boughtItemsArr[0]?.name}
          //       category={row?.boughtItemsArr[0]?.category}
          //       length={row?.boughtItemsArr[0]?.length}
          //       type={row?.boughtItemsArr[0]?.type}
          //     />

          // ),
          // renderPopup: (row) => (
          //   (selectedOrder && selectedOrder?.id === row?.id) && (
          //     <div ref={itemListPopupRef} className={Styles.popupWrapper}>
          //       <ItemListPopup
          //         items={row?.boughtItemsArr}
          //       />
          //     </div>
          //   )
          // ),
          // popupPositioning: {
          //   left: '-100px',
          //   top: '20px'
          // }
        },
        {
          name: 'outstanding',
          label: 'Outstanding',
          minWidth: '187px'
        },
        {
          name: 'revenue',
          label: 'Revenue',
          minWidth: '187px'
        },

      ]
    })
  }, [defaultData?.customers, width])

  const extraLabels = useMemo(() => {
    return [
      {
        label: 'Total Outstanding',
        value: priceFormat(defaultData?.customerTotal?.totalOutstanding),
        valueColor: '#CA0606'
      },
      {
        label: 'Total Revenue',
        value: priceFormat(defaultData?.customerTotal?.totalRevenue),
        valueColor: '#37BB09'
      },
    ]
  }, [defaultData?.customerTotal?.totalOutstanding, defaultData?.customerTotal?.totalRevenue])

  return (
    <RekhasBG>
      <TableSet
        type='customer'
        data={template?.data}
        columns={template?.columns}
        onClickRow={(row) => setSelectedCustomerDetails(row)}
        // onClickRow={(row) => navigate(`${pathname}/overview/${row?.id}`)}
        totalPage={defaultData?.totalPage || 0}
        totalData={defaultData?.total || 0}
        withNumbering
        paginationExtraLabels={extraLabels}
        onClickFilter={() => setShowFilter(true)}
        trigger={trigger}
        setTrigger={setTrigger}
        isLoading={isLoading}
        withSkeleton
      />
    </RekhasBG>
  )
}