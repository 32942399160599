import Chip from '@Atom/Chip'
import Styles from './style.module.scss'

export default function ActiveFiltersIndicator({
  filters
}) {
  return (
    <div className={Styles.container}>
      <span>Active Filter</span>
      <div className={Styles.chipsWrapper}>
        {filters?.map((filter, i) => (
          <Chip
            key={i}
            title={filter?.title}
            handleRemove={filter?.handleRemove}
          />
        ))}
      </div>
    </div>
  )
}