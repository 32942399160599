import Icon from '@Atom/Icon'
import NumberInput from '@Atom/NumberInput'
import Tag from '@Atom/Tag'
import useQuery from '@Hooks/useQuery'
import ActiveFiltersIndicator from '@Molecule/ActiveFiltersIndicator'
import DateLabel from '@Molecule/DateLabel'
import FilterModal from '@Molecule/_reusableLayout/FilterModal'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'
import { dateLabelFormatter } from '@Helpers/dateLabelFormatter'

export default function OrderFilterModal({
  handleClose,
  trigger
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()

  const defaultOrderDateFrom = query.get('of')
  const defaultOrderDateTo = query.get('ot')
  const defaultDueDateFrom = query.get('df')
  const defaultDueDateTo = query.get('dt')
  const defaultItemAmount = query.get('ia')
  const defaultPaymentPercentage = useMemo(() => { return query.get('pp')?.split('-')?.filter(obj => obj) || [] }, [query])
  const defaultItemStatus = useMemo(() => { return query.get('is')?.split('-')?.filter(obj => obj) || [] }, [query])
  const defaultScheduleFrom = query.get('sf')
  const defaultScheduleTo = query.get('st')

  const [orderDateFrom, setOrderDateFrom] = useState(defaultOrderDateFrom)
  const [orderDateTo, setOrderDateTo] = useState(defaultOrderDateTo)
  const [dueDateFrom, setDueDateFrom] = useState(defaultDueDateFrom)
  const [dueDateTo, setDueDateTo] = useState(defaultDueDateTo)
  const [itemAmount, setItemAmount] = useState(defaultItemAmount)
  const [paymentPercentage, setPaymentPercentage] = useState(defaultPaymentPercentage)
  const [itemStatus, setItemStatus] = useState(defaultItemStatus)
  const [scheduleFrom, setScheduleFrom] = useState(defaultScheduleFrom)
  const [scheduleTo, setScheduleTo] = useState(defaultScheduleTo)

  useEffect(() => {
    setOrderDateFrom(defaultOrderDateFrom)
    setOrderDateTo(defaultOrderDateTo)
    setDueDateFrom(defaultDueDateFrom)
    setDueDateTo(defaultDueDateTo)
    setItemAmount(defaultItemAmount)
    setPaymentPercentage(defaultPaymentPercentage)
    setItemStatus(defaultItemStatus)
    setScheduleFrom(defaultScheduleFrom)
    setScheduleTo(defaultScheduleTo)
  }, [
    defaultDueDateFrom,
    defaultDueDateTo,
    defaultItemAmount,
    defaultItemStatus,
    defaultOrderDateFrom,
    defaultOrderDateTo,
    defaultPaymentPercentage,
    defaultScheduleFrom,
    defaultScheduleTo,
    trigger
  ])
  const paymentsTemplate = useMemo(() => {
    return [
      {
        percentage: 50,
        type: 'yellow',
        text: 'Paid'
      },
      {
        percentage: 100,
        type: 'green',
        text: 'Paid Off'
      },

    ]
  }, [])

  const statusTemplate = useMemo(() => {
    return [
      {
        status: 'ONPROCESS',
        type: 'yellow',
        text: 'On Process'
      },
      {
        status: 'DELIVERED',
        type: 'green',
        text: 'Delivered'
      },
      {
        status: 'READY',
        type: 'black',
        text: 'Ready'
      },
      {
        status: 'SCHEDULED',
        type: 'blue',
        text: 'Scheduled'
      },

    ]
  }, [])

  const contentTemplate = useMemo(() => {
    return [
      {
        title: 'Order Date',
        description: (
          <div>
            <DateLabel
              intro='From'
              date={dateLabelFormatter(orderDateFrom, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setOrderDateFrom(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
            <DateLabel
              intro='To'
              date={dateLabelFormatter(orderDateTo, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setOrderDateTo(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
          </div>
        )
      },
      {
        title: 'Due Date',
        description: (
          <div>
            <DateLabel
              intro='From'
              date={dateLabelFormatter(dueDateFrom, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setDueDateFrom(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
            <DateLabel
              intro='To'
              date={dateLabelFormatter(dueDateTo, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setDueDateTo(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
          </div>
        )
      },
      {
        title: 'Item',
        description: (
          <div className={Styles.amount} style={{ width: '100%' }}>
            <NumberInput
              value={itemAmount || ''}
              setValue={setItemAmount}
            />
          </div>
        )
      },
      {
        title: 'Payment',
        description: (
          <div className={Styles.payment}>
            {paymentsTemplate?.map((payment, i) => (
              <div
                key={i}
              >
                <button
                  onClick={() => {
                    const found = paymentPercentage?.find(obj => +obj === +payment?.percentage)
                    if (!found) {
                      setPaymentPercentage(p => [...p, +payment?.percentage])
                    } else {
                      setPaymentPercentage(p => [...p?.filter(obj => +obj !== +payment?.percentage)])
                    }
                  }}
                >
                  <Tag
                    type={payment?.type}
                    title={payment?.text}
                  />
                </button>

                {paymentPercentage?.find(obj => +obj === +payment?.percentage) && (
                  <button
                    onClick={() => {
                      const found = paymentPercentage?.find(obj => +obj === +payment?.percentage)
                      if (!found) {
                        setPaymentPercentage(p => [...p, +payment?.percentage])
                      } else {
                        setPaymentPercentage(p => [...p?.filter(obj => +obj !== +payment?.percentage)])
                      }
                    }}
                  >
                    <Icon icon="x-circle" size={20} />
                  </button>
                )}
              </div>
            ))}

          </div>
        )
      },
      {
        title: 'Item Status',
        description: (
          <div className={Styles.status}>
            {statusTemplate?.map((status, i) => (
              <div key={i}>
                <button
                  // onClick={() => setItemStatus(status?.status)}
                  onClick={() => {
                    const found = itemStatus?.find(obj => obj === status?.status)
                    if (!found) {
                      setItemStatus(s => [...s, status?.status])
                    } else {
                      setItemStatus(s => [...s?.filter(obj => obj !== status?.status)])
                    }
                  }}
                >
                  <Tag
                    type={status?.type}
                    title={status?.text}
                  />
                </button>

                {itemStatus?.find(obj => obj === status?.status) && (
                  <button
                    // onClick={() => setItemStatus('')}
                    onClick={() => {
                      const found = itemStatus?.find(obj => obj === status?.status)
                      if (!found) {
                        setItemStatus(s => [...s, status?.status])
                      } else {
                        setItemStatus(s => [...s?.filter(obj => obj !== status?.status)])
                      }
                    }}
                  >
                    <Icon icon="x-circle" size={20} />
                  </button>
                )}
              </div>
            ))}


          </div>
        )
      },
      {
        title: 'Schedule',
        description: (
          <div>
            <DateLabel
              intro='From'
              date={dateLabelFormatter(scheduleFrom, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setScheduleFrom(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
            <DateLabel
              intro='To'
              date={dateLabelFormatter(scheduleTo, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setScheduleTo(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
          </div>
        )
      },
    ]
  }, [dueDateFrom, dueDateTo, itemAmount, itemStatus, orderDateFrom, orderDateTo, paymentPercentage, paymentsTemplate, scheduleFrom, scheduleTo, statusTemplate])

  const filterTemplate = useMemo(() => {
    const arr = []
    if (orderDateFrom || orderDateTo) {
      arr.push({
        title: `Order Date: ${orderDateFrom ? orderDateFrom?.replaceAll('-', '/') : 'DD/MM/YYYY'} - ${orderDateTo ? orderDateTo?.replaceAll('-', '/') : 'DD/MM/YYYY'}`,
        handleRemove: () => {
          setOrderDateFrom('')
          setOrderDateTo('')
        }
      })
    }
    if (dueDateFrom || dueDateTo) {
      arr.push({
        title: `Due Date: ${dueDateFrom ? dueDateFrom?.replaceAll('-', '/') : 'DD/MM/YYYY'} - ${dueDateTo ? dueDateTo?.replaceAll('-', '/') : 'DD/MM/YYYY'}`,
        handleRemove: () => {
          setDueDateFrom('')
          setDueDateTo('')
        }
      })
    }
    if (itemAmount) {
      arr.push({
        title: `Item: ${itemAmount}`,
        handleRemove: () => setItemAmount('')
      })

    }
    if (paymentPercentage?.length) {
      for (var i = 0; i < paymentPercentage.length; i++) {
        const percentage = paymentPercentage[i]
        arr.push({
          title: `Payment: ${+paymentPercentage[i] === 50 ? 'Paid' : 'Paid Off'}`,
          handleRemove: () => setPaymentPercentage(p => [...p?.filter(obj => +obj !== +percentage)])
        })
      }

    }
    if (itemStatus?.length) {
      for (let i = 0; i < itemStatus?.length; i++) {
        const status = itemStatus[i];
        arr.push({
          title: `Item Status: ${statusTemplate?.find(obj => obj?.status === status)?.text}`,
          handleRemove: () => setItemStatus(s => [...s?.filter(obj =>obj !== status)])
        })
      }

    }
    if (scheduleFrom || scheduleTo) {
      arr.push({
        title: `Schedule: ${scheduleFrom ? scheduleFrom?.replaceAll('-', '/') : 'DD/MM/YYYY'} - ${scheduleTo ? scheduleTo?.replaceAll('-', '/') : 'DD/MM/YYYY'}`,
        handleRemove: () => {
          setScheduleFrom('')
          setScheduleTo('')
        }
      })
    }

    return arr
  }, [dueDateFrom, dueDateTo, itemAmount, itemStatus, orderDateFrom, orderDateTo, paymentPercentage, scheduleFrom, scheduleTo, statusTemplate])

  const fixedQuery = useMemo(() => {
    const arr = []

    if (orderDateFrom) {
      arr.push(`of=${orderDateFrom}`)
    }
    if (orderDateTo) {
      arr.push(`ot=${orderDateTo}`)
    }
    if (dueDateFrom) {
      arr.push(`df=${dueDateFrom}`)
    }
    if (dueDateTo) {
      arr.push(`dt=${dueDateTo}`)
    }
    if (itemAmount) {
      arr.push(`ia=${itemAmount}`)
    }
    if (paymentPercentage) {
      arr.push(`pp=${paymentPercentage?.join('-')}`)
    }
    if (itemStatus) {
      arr.push(`is=${itemStatus?.join('-')}`)
    }
    if (scheduleFrom) {
      arr.push(`sf=${scheduleFrom}`)
    }
    if (scheduleTo) {
      arr.push(`st=${scheduleTo}`)
    }
    if (arr?.length) {
      return '?' + arr?.join('&')
    } return ''
  }, [dueDateFrom, dueDateTo, itemAmount, itemStatus, orderDateFrom, orderDateTo, paymentPercentage, scheduleFrom, scheduleTo])

  const handleReset = () => {
    setOrderDateFrom('')
    setOrderDateTo('')
    setDueDateFrom('')
    setDueDateTo('')
    setItemAmount('')
    setPaymentPercentage([])
    setItemStatus([])
    setScheduleFrom('')
    setScheduleTo('')
  }

  const handleApply = () => {
    navigate(pathname + fixedQuery, { replace: true })
    handleClose()
  }


  return (
    <FilterModal
      handleClose={handleClose}
      handleReset={handleReset}
      handleApply={handleApply}
      extraHeader={
        <ActiveFiltersIndicator
          filters={filterTemplate}
        />
      }
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.content}>
          <span>{content?.title}</span>
          {content?.description}
        </div>
      ))}
    </FilterModal>
  )
}