import Styles from './style.module.scss'

export default function AdornmentLabel({
  children
}) {

  return (
    <div className={Styles.container}>
      {children}
    </div>
  )
}