import { stockTypes } from '@Assets/data/stockTypes'
import Icon from '@Atom/Icon'
import Input from '@Atom/Input'
import Tag from '@Atom/Tag'
import useQuery from '@Hooks/useQuery'
import ActiveFiltersIndicator from '@Molecule/ActiveFiltersIndicator'
import FilterModal from '@Molecule/_reusableLayout/FilterModal'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function WorkshopFilterModal({
  handleClose,
  trigger,
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()
  const defaultType = query.get('t')
  const defaultOrderStatus = useMemo(() => { return query.get('os')?.split('-') || [] }, [query])

  const [type, setType] = useState(defaultType || '')
  const [orderStatus, setOrderStatus] = useState(defaultOrderStatus)


  const statusTemplate = useMemo(() => {
    return [
      {
        status: 'ONPROCESS',
        type: 'yellow',
        text: 'On Process'
      },
      {
        status: 'DELIVERED',
        type: 'green',
        text: 'Delivered'
      },
      {
        status: 'READY',
        type: 'black',
        text: 'Ready'
      },
      {
        status: 'SCHEDULED',
        type: 'blue',
        text: 'Scheduled'
      },

    ]
  }, [])

  useEffect(() => {
    setType(defaultType)
    setOrderStatus(defaultOrderStatus)
  }, [defaultType, trigger, defaultOrderStatus])

  const allQueries = useMemo(() => {
    const arr = []
    for (const param of query) {
      arr.push(param);
    }

    const newArr =
      !arr?.find(obj => obj[0] === 'os')
        ? (
          !arr?.find(obj => obj[0] === 't')
            ?
            [...arr, ['os', (orderStatus?.length ? orderStatus?.join('-') : '')], ['t', type]]
            :
            [...arr, ['os', (orderStatus?.length ? orderStatus?.join('-') : '')]]
        )
        : [...arr]


    return newArr
  }, [orderStatus, query, type])

  const fixedQuery = useMemo(() => {
    let str = ''

    const raw = allQueries.join(',')?.split(',')

    const strArr = []

    for (let i = 0; i < raw.length / 2; i++) {
      strArr.push({
        key: raw[i + i],
        value: raw[i + i] === 't'
          ? type || ''
          : (
            raw[i + i] === 'os'
              ? orderStatus?.join('-') || ''
              :
              raw[i + i + 1]

          )
      })
    }


    for (let i = 0; i < strArr.length; i++) {
      const s = strArr[i]
      str += (i === 0 ? `${s?.key}=${s?.value}` : `&${s?.key}=${s?.value}`)
    }


    return str

  }, [allQueries, orderStatus, type])



  const filterTemplate = useMemo(() => {
    const arr = []

    if (type) {
      arr.push({
        title: stockTypes?.find(el => el?.query === type)?.title,
        handleRemove: () => setType('')
      })
    }

    if (orderStatus?.length) {
      for (let i = 0; i < orderStatus?.length; i++) {
        const status = orderStatus[i];
        arr.push({
          title: `Status: ${statusTemplate?.find(obj => obj?.status === status)?.text}`,
          handleRemove: () => setOrderStatus(s => [...s?.filter(s !== status)])
        })
      }

    }
    return arr
  }, [orderStatus, statusTemplate, type])

  const [typeSearch, setTypeSearch] = useState(stockTypes?.find(obj => obj?.query === type)?.title)

  useEffect(() => {
    setType(stockTypes?.find(obj => obj?.title === typeSearch)?.query)
  }, [typeSearch])


  const contentTemplate = useMemo(() => {
    return [
      {
        title: 'Item Type',
        description: (
          <div>
            <Input
              value={typeSearch}
              setValue={setTypeSearch}
              isDropdown
              dropdownOptions={
                stockTypes?.map(obj => obj?.title)
              }
              placeholder={'Search item type'}
            />
          </div>

        )
      },
      {
        title: 'Order Status',
        description: (
          <div className={Styles.status}>
            {statusTemplate?.map((status, i) => (
              <div key={i}>
                <button
                  // onClick={() => setItemStatus(status?.status)}
                  onClick={() => {
                    const found = orderStatus?.find(obj => obj === status?.status)
                    if (!found) {
                      setOrderStatus(s => [...s, status?.status])
                    } else {
                      setOrderStatus(s => [...s?.filter(obj => obj !== status?.status)])
                    }
                  }}
                >
                  <Tag
                    type={status?.type}
                    title={status?.text}
                  />
                </button>

                {orderStatus?.find(obj => obj === status?.status) && (
                  <button
                    // onClick={() => setItemStatus('')}
                    onClick={() => {
                      const found = orderStatus?.find(obj => obj === status?.status)
                      if (!found) {
                        setOrderStatus(s => [...s, status?.status])
                      } else {
                        setOrderStatus(s => [...s?.filter(obj => obj !== status?.status)])
                      }
                    }}
                  >
                    <Icon icon="x-circle" size={20} />
                  </button>
                )}
              </div>
            ))}


          </div>
        )
      }
    ]
  }, [orderStatus, statusTemplate, typeSearch])

  const handleReset = () => {
    setType('')
    setOrderStatus([])
    // setDateAddedFrom('')

  }

  const handleApply = () => {
    navigate(pathname + `?${fixedQuery}`, { replace: true })
    handleClose()
  }


  return (
    <FilterModal
      handleClose={handleClose}
      handleReset={handleReset}
      handleApply={handleApply}
      extraHeader={
        <ActiveFiltersIndicator
          filters={filterTemplate}
        />

      }
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.content}>
          <span>{content?.title}</span>
          {content?.description}
        </div>
      ))}
    </FilterModal>
  )
}