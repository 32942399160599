import { API } from "@Config/api";

export function getEmployees(page, limit, type='', search, joinDateStart='',joinDateEnd='', role='', checkpointCode='') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/user?page=${page}&limit=${limit}&search=${search}&type=${type?.toUpperCase()}&joinDateStart=${joinDateStart}&joinDateEnd=${joinDateEnd}&role=${role}&checkpointCode=${checkpointCode}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function getEmployeeByCode(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/user/${code}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getEmployeeOrder(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/user/order/${code}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}


export function getWorkerList() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get('/user/list', {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

export function addEmployee(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/user`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function editEmployee(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/user/${id}`,payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function editEmployee2(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/user/${id}`, new URLSearchParams(payload), {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
