import ModalHeader from '@Atom/ModalHeader'
import Styles from './style.module.scss'
import InputField from '@Molecule/InputField'
import { useEffect, useState } from 'react'
import UploadField from '@Molecule/UploadField'
import Button from '@Atom/Button'
import { allHasValueObjectChecker } from '@Helpers/allHasValueObjectChecker'
import { updateFeePayment } from '@Services/fee'

export default function UpdateFeePaymentModal({
  onClose,
  fetchData = () => { },
  type,
  id
}) {
  const [form, setForm] = useState({
    date: '',
    nominal: '',
    proof: ''
  })

  // eslint-disable-next-line no-unused-vars
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true)
      await updateFeePayment(id, {
        image: form?.proof,
        type: type === 'fee' ? 'DEFAULT' : 'BONUS',
        paid: form?.nominal
      })
      fetchData()
      setForm({
        date: '',
        nominal: '',
        proof: ''
      })
      setIsSubmitting(false)
      onClose()
    } catch (err) {
      setIsSubmitting(false);
      setError(err?.response?.data?.error);

    }
  }

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={type === 'fee' ? 'Update Paid History' : 'Bonus Fee'}
        paddingY='16px'
        isSticky
      />
      <div className={Styles.content}>
        <InputField
          title={type === 'fee' ? 'Paid Date' : 'Bonus Paid Date'}
          // isDate
          type='date'
          value={form?.date}
          setValue={(newVal) => setForm(f => { return { ...f, date: newVal } })}
        />
        <InputField
          title={type === 'fee' ? 'Paid Amount' : 'Bonus Fee'}
          isCurrency
          value={form?.nominal}
          setValue={(newVal) => setForm(f => { return { ...f, nominal: newVal } })}
        />
        <UploadField
          title={'Payment Proof'}
          isFullWidth
          value={form?.proof}
          setValue={(newVal) => setForm(f => { return { ...f, proof: newVal } })}
        />
      </div>

      <div className={Styles.actions}>
        <Button
          variant='secondary'
          buttonText='Cancel'
          onClick={() => onClose()}
          size='lg'
        />
        <Button
          buttonText='Confirm'
          disabled={!allHasValueObjectChecker(form)}
          onClick={handleConfirm}
          size='lg'
        />
      </div>
    </div>
  )
}