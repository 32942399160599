import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import Images from '@Theme/Images'
import { useEffect, useRef, useState } from 'react'

export default function ThumbnailCard({
  id,
  title,
  thumbnail,
  setIdToDelete
}) {
  const inputRef = useRef()
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(`${title}.png`)
  const [fileUrl, setFileUrl] = useState(thumbnail || '')

  const handleChange = (e) => {
    if (e?.target?.files?.length > 0) {
      setFile(e?.target?.files[0])
      setFileName(e?.target?.files[0]?.name)
    }
  }

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file)
      setFileUrl(objectUrl)

      return () => URL.revokeObjectURL(objectUrl)

    }

  }, [file])

  return (
    <div className={Styles.container}>
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={handleChange}
      />
      <div className={Styles.header}>
        <span>{title}</span>
        <button onClick={() => setIdToDelete(id)}>
          <Icon icon={'trash'} size={20} />
        </button>
      </div>
      {fileUrl
        ?
        <div className={Styles.content}>
          <img src={fileUrl} alt='' />
          <div className={Styles.fileName}>
            <span>{fileName}</span>
          </div>
          <button
            onClick={() => {
              setFile(null)
              setFileName(`${title}.png`)
              setFileUrl(thumbnail || '')
            }}
          >
            <Icon icon={'arrow-uturn-left'} size={24} />
          </button>
          <button
            onClick={() => {
              setFile(null)
              setFileName('')
              setFileUrl('')
            }}
          >
            <Icon icon={'trash'} size={24} />
          </button>
        </div>
        :
        <div className={Styles.empty}>
          <img src={Images.CLOUD} alt='' />
          <button
            onClick={() => inputRef?.current?.click()}
          >
            Upload Image
          </button>
        </div>
      }

    </div>
  )
}