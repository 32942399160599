import EmployeeListLayout from '@Organism/Employee/EmployeeList'
import Styles from './style.module.scss'
import { Suspense, useState } from 'react'
import ModalWrapper from '@Atom/ModalWrapper'
import EmployeeDetailsModal from '@Molecule/_modals/DetailsModals/Employee'
import { Await, useLoaderData } from 'react-router-dom'
import useQuery from '@Hooks/useQuery'
import FeeDetailsModal from '@Molecule/_modals/DetailsModals/Fee'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'
import AddEmployeeModal from '@Molecule/_modals/AddModals/Employee'
import EmployeeFilterModal from '@Molecule/_modals/FilterModals/Employee'

export default function EmployeeListPage() {
  const { packageEmployeeList, checkpointList } = useLoaderData()
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  // const [trigger, setTrigger] = useState(null)
  const [showAdd, setShowAdd] = useState(false)
  const [trigger, setTrigger] = useState('')
  const [showFilter, setShowFilter] = useState(false)
  const [checkpoints, setCheckpoints] = useState([])

  const query = useQuery()
  const tableType = query.get('tt') || 'list'


  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <EmployeeListLayout
            setSelectedEmployee={setSelectedEmployee}
            setShowFilter={setShowFilter}
            setShowAdd={setShowAdd}
            trigger={trigger}
            setTrigger={setTrigger}
            isLoading={true}
            defaultData={{
              totalPage: 0,
              users: [],
              fees: [],
              templateFees: [],
              total: 0
            }}
            checkpoinList={[]}
            setCheckpoints={setCheckpoints}
          />
          // <>loading</>
        }
      >
        <Await
          resolve={packageEmployeeList}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageEmployeeList) => (
            <Suspense
              fallback={
                <EmployeeListLayout
                  setSelectedEmployee={setSelectedEmployee}
                  setShowFilter={setShowFilter}
                  setShowAdd={setShowAdd}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  defaultData={{
                    ...packageEmployeeList?.data,
                    totalPage: packageEmployeeList?.data?.totalPage,
                    users: packageEmployeeList?.data?.users || [],
                    fees: packageEmployeeList?.data?.fees || [],
                    templateFees: packageEmployeeList?.data?.templateFees || packageEmployeeList?.data?.feeTemplate || [],
                    total: packageEmployeeList?.data?.total,
                  }}
                  checkpoinList={[]}
                  setCheckpoints={setCheckpoints}
                  isLoading
                />
              }
            >
              <Await
                resolve={checkpointList}
                errorElement={<AsyncErrorBoundary />}
              >
                {(checkpointList) => (
                  <EmployeeListLayout
                    setSelectedEmployee={setSelectedEmployee}
                    setShowFilter={setShowFilter}
                    setShowAdd={setShowAdd}
                    trigger={trigger}
                    setTrigger={setTrigger}
                    defaultData={{
                      ...packageEmployeeList?.data,
                      totalPage: packageEmployeeList?.data?.totalPage,
                      users: packageEmployeeList?.data?.users || [],
                      fees: packageEmployeeList?.data?.fees || [],
                      templateFees: packageEmployeeList?.data?.templateFees || packageEmployeeList?.data?.feeTemplate || [],
                      total: packageEmployeeList?.data?.total,
                    }}
                    checkpoinList={checkpointList?.data}
                    setCheckpoints={setCheckpoints}
                  />
                )}
              </Await>

            </Suspense>


          )}
        </Await>


      </Suspense>
      <ModalWrapper
        desktopPositioning='right'
        mobilePositioning='bottom'
        show={selectedEmployee}
        setShow={() => setSelectedEmployee(null)}
      >
        {tableType === 'list'
          ?
          <EmployeeDetailsModal
            employee={selectedEmployee}
            handleClose={() => setSelectedEmployee(null)}
            setTrigger={setTrigger}
          />
          :
          <FeeDetailsModal
            employee={selectedEmployee}
            handleClose={() => setSelectedEmployee(null)}
          />
        }

      </ModalWrapper>

      <ModalWrapper
        desktopPositioning='center'
        mobilePositioning='bottom'
        show={showAdd}
        setShow={setShowAdd}
      >
        <AddEmployeeModal
          handleClose={() => setShowAdd(false)}
          setTrigger={setTrigger}
        />
      </ModalWrapper>

      <ModalWrapper
        desktopPositioning='right'
        mobilePositioning='bottom'
        show={showFilter}
        setShow={setShowFilter}
      >
        <EmployeeFilterModal
          handleClose={() => {
            setShowFilter(false)
            // setTrigger(Math.random())
          }}
          trigger={trigger}
          checkpoints={checkpoints}
        />
      </ModalWrapper>
    </div>
  )
}