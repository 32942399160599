import Icon from '@Atom/Icon'
import Tag from '@Atom/Tag'
import { dateLabelFormatter } from '@Helpers/dateLabelFormatter'
import useQuery from '@Hooks/useQuery'
import ActiveFiltersIndicator from '@Molecule/ActiveFiltersIndicator'
import DateLabel from '@Molecule/DateLabel'
import FilterModal from '@Molecule/_reusableLayout/FilterModal'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function InvoiceFilterModal({
  handleClose,
  trigger
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()

  const defaultIssueAtFrom = query.get('df')
  const defaultIssueAtTo = query.get('dt')
  const defaultItemAmount = query.get('ia')
  const defaultPaymentPercentage = useMemo(() => {return query.get('pp') ?  query.get('pp')?.split('::') : [] }, [query])
  const defaultPaymentMethod = useMemo(() => { return query.get('is') ? query.get('is')?.split('::') : [] }, [query])
  const defaultPayDateFrom = query.get('sf')
  const defaultPayDateTo = query.get('st')

  const [dateFrom, setDateFrom] = useState(defaultIssueAtFrom)
  const [dateTo, setDateTo] = useState(defaultIssueAtTo)
  const [itemAmount, setItemAmount] = useState(defaultItemAmount)
  const [paymentPercentage, setPaymentPercentage] = useState(defaultPaymentPercentage)
  const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod)
  const [payDateFrom, setPayDateFrom] = useState(defaultPayDateFrom)
  const [payDateTo, setPayDateTo] = useState(defaultPayDateTo)

  useEffect(() => {
    setDateFrom(defaultIssueAtFrom)
    setDateTo(defaultIssueAtTo)
    setItemAmount(defaultItemAmount)
    setPaymentPercentage(defaultPaymentPercentage)
    setPaymentMethod(defaultPaymentMethod)
    setPayDateFrom(defaultPayDateFrom)
    setPayDateTo(defaultPayDateTo)
  }, [
    defaultIssueAtFrom,
    defaultIssueAtTo,
    defaultItemAmount,
    defaultPaymentMethod,
    defaultPaymentPercentage,
    defaultPayDateFrom,
    defaultPayDateTo,
    trigger
  ])
  const paymentsTemplate = useMemo(() => {
    return [
      {
        percentage: 50,
        type: 'yellow',
        text: 'Paid'
      },
      {
        percentage: 100,
        type: 'green',
        text: 'Paid Off'
      },

    ]
  }, [])

  const methodTemplate = useMemo(() => {
    return [
      {
        status: 'CASH',
        type: 'black',
        text: 'Cash'
      },
      {
        status: 'DEBIT-CARD',
        type: 'black',
        text: 'Debit'
      },
      {
        status: 'E-WALLET',
        type: 'black',
        text: 'E-Wallet'
      },

    ]
  }, [])

  const contentTemplate = useMemo(() => {
    return [
      {
        title: 'Issued At',
        description: (
          <div>
            <DateLabel
              intro='From'
              date={dateLabelFormatter(dateFrom, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setDateFrom(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
            <DateLabel
              intro='To'
              date={dateLabelFormatter(dateTo, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setDateTo(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
          </div>
        )
      },
      {
        title: 'Payment Status',
        description: (
          <div className={Styles.payment}>
            {paymentsTemplate?.map((payment, i) => (
              <div
                key={i}
              >
                <button
                  onClick={() => {
                    const found = paymentPercentage?.find(obj => +obj === +payment?.percentage)
                    if (!found) {
                      setPaymentPercentage(p => [...p, +payment?.percentage])
                    } else {
                      setPaymentPercentage(p => [...p?.filter(obj => +obj !== +payment?.percentage)])
                    }
                  }}
                >
                  <Tag
                    type={payment?.type}
                    title={payment?.text}
                  />
                </button>

                {paymentPercentage?.find(obj => +obj === +payment?.percentage) && (
                  <button
                    onClick={() => {
                      const found = paymentPercentage?.find(obj => +obj === +payment?.percentage)
                      if (!found) {
                        setPaymentPercentage(p => [...p, +payment?.percentage])
                      } else {
                        setPaymentPercentage(p => [...p?.filter(obj => +obj !== +payment?.percentage)])
                      }
                    }}
                  >
                    <Icon icon="x-circle" size={20} />
                  </button>
                )}
              </div>
            ))}

          </div>
        )
      },
      {
        title: 'Payment Method',
        description: (
          <div className={Styles.status}>
            {methodTemplate?.map((status, i) => (
              <div key={i}>
                <button
                  // onClick={() => setItemStatus(status?.status)}
                  onClick={() => {
                    const found = paymentMethod?.find(obj => obj === status?.status)
                    if (!found) {
                      setPaymentMethod(s => [...s, status?.status])
                    } else {
                      setPaymentMethod(s => [...s?.filter(obj => obj !== status?.status)])
                    }
                  }}
                >
                  <Tag
                    type={status?.type}
                    title={status?.text}
                  />
                </button>

                {paymentMethod?.find(obj => obj === status?.status) && (
                  <button
                    // onClick={() => setItemStatus('')}
                    onClick={() => {
                      const found = paymentMethod?.find(obj => obj === status?.status)
                      if (!found) {
                        setPaymentMethod(s => [...s, status?.status])
                      } else {
                        setPaymentMethod(s => [...s?.filter(obj => obj !== status?.status)])
                      }
                    }}
                  >
                    <Icon icon="x-circle" size={20} />
                  </button>
                )}
              </div>
            ))}


          </div>
        )
      },
      {
        title: 'Schedule',
        description: (
          <div>
            <DateLabel
              intro='From'
              date={dateLabelFormatter(payDateFrom, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setPayDateFrom(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
            <DateLabel
              intro='To'
              date={dateLabelFormatter(payDateTo, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setPayDateTo(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
          </div>
        )
      },
    ]
  }, [dateFrom, dateTo, paymentMethod, paymentPercentage, paymentsTemplate, payDateFrom, payDateTo, methodTemplate])

  const filterTemplate = useMemo(() => {
    const arr = []
  
    if (dateFrom || dateTo) {
      arr.push({
        title: `Due Date: ${dateFrom ? dateFrom?.replaceAll('-', '/') : 'DD/MM/YYYY'} - ${dateTo ? dateTo?.replaceAll('-', '/') : 'DD/MM/YYYY'}`,
        handleRemove: () => {
          setDateFrom('')
          setDateTo('')
        }
      })
    }
    if (itemAmount) {
      arr.push({
        title: `Item: ${itemAmount}`,
        handleRemove: () => setItemAmount('')
      })

    }
    if (paymentPercentage?.length) {
      for (var i = 0; i < paymentPercentage.length; i++) {
        const percentage = paymentPercentage[i]
        arr.push({
          title: `Payment: ${+paymentPercentage[i] === 50 ? 'Paid' : 'Paid Off'}`,
          handleRemove: () => setPaymentPercentage(p => [...p?.filter(+p !== +percentage)])
        })
      }

    }
    if (paymentMethod?.length) {
      for (let i = 0; i < paymentMethod?.length; i++) {
        const status = paymentMethod[i];
        arr.push({
          title: `Payment Method: ${methodTemplate?.find(obj => obj?.status === status)?.text}`,
          handleRemove: () => setPaymentMethod(s => [...s?.filter(s !== status)])
        })
      }

    }
    if (payDateFrom || payDateTo) {
      arr.push({
        title: `Schedule: ${payDateFrom ? payDateFrom?.replaceAll('-', '/') : 'DD/MM/YYYY'} - ${payDateTo ? payDateTo?.replaceAll('-', '/') : 'DD/MM/YYYY'}`,
        handleRemove: () => {
          setPayDateFrom('')
          setPayDateTo('')
        }
      })
    }

    return arr
  }, [dateFrom, dateTo, itemAmount, paymentMethod, paymentPercentage, payDateFrom, payDateTo, methodTemplate])

  const fixedQuery = useMemo(() => {
    const arr = []

    if (dateFrom) {
      arr.push(`df=${dateFrom}`)
    }
    if (dateTo) {
      arr.push(`dt=${dateTo}`)
    }
    if (itemAmount) {
      arr.push(`ia=${itemAmount}`)
    }
    if (paymentPercentage) {
      arr.push(`pp=${paymentPercentage?.join('::')}`)
    }
    if (paymentMethod) {
      arr.push(`is=${paymentMethod?.join('::')}`)
    }
    if (payDateFrom) {
      arr.push(`sf=${payDateFrom}`)
    }
    if (payDateTo) {
      arr.push(`st=${payDateTo}`)
    }
    if (arr?.length) {
      return '?' + arr?.join('&')
    } return ''
  }, [dateFrom, dateTo, itemAmount, paymentMethod, paymentPercentage, payDateFrom, payDateTo])

  const handleReset = () => {
    setDateFrom('')
    setDateTo('')
    setItemAmount('')
    setPaymentPercentage([])
    setPaymentMethod([])
    setPayDateFrom('')
    setPayDateTo('')
  }

  const handleApply = () => {
    navigate(pathname + fixedQuery, { replace: true })
    handleClose()
  }


  return (
    <FilterModal
      handleClose={handleClose}
      handleReset={handleReset}
      handleApply={handleApply}
      extraHeader={
        <ActiveFiltersIndicator
          filters={filterTemplate}
        />
      }
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.content}>
          <span>{content?.title}</span>
          {content?.description}
        </div>
      ))}
    </FilterModal>
  )
}