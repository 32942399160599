import Button from '@Atom/Button'
import ModalWrapper from '@Atom/ModalWrapper'
import Searchbar from '@Atom/Searchbar'
import { fileBaseUrl } from '@Config/api'
import { useDebounce } from '@Hooks/useDebounce'
import useQuery from '@Hooks/useQuery'
import ListingCard from '@Molecule/ListingCard'
import AddToCartModal from '@Molecule/_modals/AddToCartModal'
import { getProducts } from '@Services/product'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function CollectionListLayout({
  isLoading,
  defaultData,
  totalPage = 10,
  totalData=0
}) {
  const query = useQuery()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [selectedItem, setSelectedItem] = useState(null)
  const defaultCart = localStorage['cart'] ? JSON.parse(localStorage['cart']) : []
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingSearch, setIsLoadingSearch] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)


  const [data, setData] = useState([])

  useEffect(() => {
    setData(
      defaultData?.map(obj => {
        return {
          ...obj,
          id: obj?._id,
          name: obj?.name,
          code: obj?._id,
          img: obj?.productImages?.length ? fileBaseUrl + obj.productImages[0]?.url : null,
          price: obj?.price,
          isMeasured: false,
          isCustomSizing: true,
          showMeasurementStatus: true,
          sleevesType: 'long',
          size: '-',
          // measurements: Object.entries(obj?.size)?.map(m => { return { name: m[0], value: m[1] + ' cm', title: splitCamelCase(m[0]) } }),
        }
      })
    )
  }, [defaultData])
  const [addedToCart, setAddedToCart] = useState(defaultCart)

  const fetchBySearch = useCallback(async () => {
    setIsLoadingSearch(true)
    const priceFrom = query.get("pf") || "";
    const priceTo = query.get("pt") || "";
    const dateFrom = query.get("df") || "";
    const dateTo = query.get("dt") || "";
    try {
      const response = await getProducts(
        'collection',
        1,
        30,
        priceFrom,
        priceTo,
        dateFrom,
        dateTo,
        debouncedSearchTerm
      )
      setData([...response?.data?.products?.map(obj => {
        return {
          ...obj,
          id: obj?._id,
          code: obj?._id,
          name: obj?.name,
          img: obj?.productImages?.length ? fileBaseUrl + obj.productImages[0]?.url : null,
          price: obj?.price,
          isMeasured: false,
          isCustomSizing: true,
          showMeasurementStatus: true,
          sleevesType: 'long',
          size: '-',
          // measurements: Object.entries(obj?.size)?.map(m => { return { name: m[0], value: m[1] + ' cm', title: splitCamelCase(m[0]) } }),
        }
      })])

      setIsLoadingSearch(false)
    } catch (err) {
      setIsLoadingSearch(false)
      console.log(err, 'error on loading more')
    }
  }, [debouncedSearchTerm, query])

  useEffect(()=> {
    fetchBySearch()
  }, [fetchBySearch])

  const fetchMore = useCallback(async () => {
    setIsLoadingMore(true)
    const priceFrom = query.get("pf") || "";
    const priceTo = query.get("pt") || "";
    const dateFrom = query.get("df") || "";
    const dateTo = query.get("dt") || "";
    try {
      const response = await getProducts(
        'collection',
        currentPage + 1,
        30,
        priceFrom,
        priceTo,
        dateFrom,
        dateTo,
        debouncedSearchTerm
      )
      setData([...data, ...response?.data?.products?.map(obj => {
        return {
          ...obj,
          id: obj?._id,
          name: obj?.name,
          img: obj?.productImages?.length ? fileBaseUrl + obj.productImages[0]?.url : null,
          price: obj?.price,
          isMeasured: false,
          isCustomSizing: true,
          showMeasurementStatus: true,
          sleevesType: 'long',
          size: '-',
          // measurements: Object.entries(obj?.size)?.map(m => { return { name: m[0], value: m[1] + ' cm', title: splitCamelCase(m[0]) } }),
        }
      })])
      // if (currentPage < totalPage) {
        setCurrentPage(currentPage + 1)
      // }
      setIsLoadingMore(false)
    } catch (err) {
      setIsLoadingMore(false)
      console.log(err, 'error on loading more')
    }
  }, [currentPage, data, debouncedSearchTerm, query])

  useEffect(() => {
    if (selectedItem && !addedToCart?.find(obj => obj === selectedItem)) {
      setAddedToCart([...addedToCart, selectedItem])
    }
  }, [addedToCart, selectedItem])

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(addedToCart))
  }, [addedToCart])



  const navigate = useNavigate()

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Button
          variant='tertiary'
          size='lg'
          buttonText='Back to Products'
          fixedPadding={'0px'}
          withIcon
          iconName={'chevron-left'}
          iconPlacement='start'
          borderRadius='0'
          disableActive
          customIconSize={16}
          onClick={() => navigate('/product')}
        />
        <div className={Styles.bottom}>
          <div className={Styles.left}>
            <h3>Collection <span>({totalData})</span></h3>
          </div>
          <div className={Styles.right}>
            <Button
              variant='tertiary'
              size='md'
              withIcon
              iconName={'currency-dollar'}
              iconPlacement='end'
              buttonText='Filters'
              disableActive
            />
            <Button
              variant='tertiary'
              size='md'
              withIcon
              iconName={'arrow-long-down'}
              iconPlacement='end'
              buttonText='Sort By'
              disableActive
            />
          </div>
        </div>
      </div>
      <Searchbar
        width='100%'
        placeholder='Search by product code'
        value={searchTerm}
        setValue={setSearchTerm}
      />
      <div className={Styles.content}>
        {!isLoading && !isLoadingSearch
          ? data?.map((each, i) => (
            <ListingCard
              key={i}
              item={each}
              setSelectedItem={setSelectedItem}
              isCollection
            />
          ))
          :
          [...Array(3)]?.map((_, i) => (
            <ListingCard
              key={i}
              isLoading={isLoading}
              isCollection
            />
          ))
        }
      </div>
      <div className={Styles.moreActions}>
        {
          isLoadingMore || isLoadingSearch || isLoading
            ?
            <span>Loading...</span>
            :
            currentPage < totalPage
              ?
              <Button
                buttonText='Click to Load More'
                variant='tertiary'
                withIcon
                iconName='chevron-down'
                iconPlacement='end'
                onClick={fetchMore}
              />
              :
              <></>
        }
      </div>
      <ModalWrapper
        show={selectedItem}
        desktopPositioning='center'
        mobilePositioning='center'
      >
        {selectedItem
          &&
          <AddToCartModal
            src={selectedItem?.img}
            name={selectedItem?.name}
            size={selectedItem?.size}
            price={selectedItem?.price}
            id={selectedItem?.productCode}
            data={selectedItem}
          />}

      </ModalWrapper>
    </div>
  )
}