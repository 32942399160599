import Button from '@Atom/Button'
import ItemTitle from '@Atom/ItemTitle'
import RekhasBG from '@Atom/RekhasBG'
import Tag from '@Atom/Tag'
import { priceFormat } from '@Helpers/priceFormat'
import useOutsideClick from '@Hooks/useOutsideClick'
import useWindowSize from '@Hooks/useWindowSize'
import StackedItemInfo from '@Molecule/StackedItemInfo'
import TableSet from '@Molecule/TableSet'
import ItemListPopup from '@Molecule/_popups/ItemListPopup'
import { getCustomerList } from "@Services/customer";
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Styles from './style.module.scss'
import GenerateInvoiceModal from '@Molecule/_modals/GenerateInvoiceModal'

export default function InvoiceListLayout({
  setSelectedInvoiceDetails,
  setShowDetails,
  defaultData,
  isLoading = false,
  setShowFilter,
  trigger,
  setTrigger,
}) {

  const [selectedOrder, setSelectedOrder] = useState(null)
  const [customerList, setCustomerList] = useState([])
  // const handleShowList = (e, row) => {
  //   e.stopPropagation()
  //   setSelectedOrder(row)
  // }

  const { width } = useWindowSize()

  const [loadingData, setLoadingData] = useState(isLoading)
  const [showGenerateInvoice, setShowGenerateInvoice] = useState(false)

  useEffect(() => {
    setLoadingData(isLoading)
  }, [isLoading])


  const itemListPopupRef = useRef()
  const itemListPopupOutsideClick = useOutsideClick(itemListPopupRef);

  const handleClickRow = useCallback((row) => {
    setSelectedInvoiceDetails(row)
    setShowDetails(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  useEffect(() => {
    if (selectedOrder && itemListPopupOutsideClick) {
      setSelectedOrder(null);
    }
  }, [itemListPopupOutsideClick, selectedOrder])


  const template = useMemo(() => {
    return ({
      // data: [],
      data: defaultData?.invoices?.length
        ?
        defaultData?.invoices?.map(obj => {
          return {
            ...obj,
            id: obj?._id,
            orderCode: obj?.orderCode,
            orderDate: moment(new Date(obj?.createdAt)).format('L'),
            customerName: obj?.customer?.name,
            customerPhoneNumber: obj?.customer?.phone || '-',
            customerAddress: obj?.customer?.address || '-',
            dueDate: moment(new Date(obj?.dueDate)).format('L'),
            currentDueDate: obj?.currentDueDate ? moment(new Date(obj?.currentDueDate)).format('L') : '-',
            total: obj?.totalPrice || '-',
            paymentHistory: obj?.payments,
            paymentMethod: obj?.paymentMethod || '-',
            paymentDate: obj?.payments?.length ? moment(new Date(obj?.payments[0]?.date))?.format('ll') : '-',
            // itemsArr: [],
            itemsArr: obj?.summaries.map(item => {
              return {
                ...item,
                id: item?._id,
                category: item?.productType,
                measurements: []
                // measurements:Object.entries(item?.measurement)?.map(m=> {return {name: m[0], value: m[1] + ' cm', title: splitCamelCase(m[0])}})
              }
            })
          }
        })
        :
        []
      ,
      columns: [
        {
          name: 'orderDate',
          label: 'Issued At',
          minWidth: width <= 768 ? '108px' : '124px'
        },
        {
          name: 'code',
          label: 'Invoice Number',
          minWidth: width <= 768 ? '176px' : '200px'
        },
        {
          name: 'customerName',
          label: 'Customer Details',
          // minWidth: '429px',
          // maxWidth: '453px',
          maxWidth: width <= 768 ? '176px' : '253px',
          minWidth: width <= 768 ? '176px' : '253px',
          renderData: (row) => (
            <StackedItemInfo
              title={row?.customerName}
              label={row?.customerPhoneNumber || '-'}
              withoutPicture
              titleWidth={width <= 768 ? '150px' : '100%'}
            />
          )
        },
        {
          name: 'customerAddress',
          label: 'Customer Address',
          minWidth: '280px',
          hideOnMobile: true,
        },
        // {
        //   name: 'dueDate',
        //   label: 'Due Date',
        //   hideOnMobile: true,
        //   renderData: (row) => (
        //     <div className={Styles?.dueDate}>
        //       <div className={Styles.left}>
        //         <span>{row?.dueDate}</span>
        //         {
        //           row?.currentDueDate && <Tag
        //             type={"blue"}
        //             title={row?.currentDueDate}
        //             noBullet
        //             fixedFontSize={'1.2rem'}
        //             fixedPadding={'0.2rem 0.4rem'}
        //           />
        //         }

        //       </div>
        //       <DatePickerButton
        //         value={row?.currentDueDate || row?.dueDate}
        //       />
        //     </div>
        //   )
        // },
        {
          name: 'itemsArr',
          label: 'Item',
          hideOnMobile: true,
          minWidth: '246px',
          maxWidth: '270px',
          renderData: (row) => (
            row?.itemsArr?.length > 1
              ? <div
                className={Styles.itemsIndicator}
              >
                <span>{row?.itemsArr?.length} Items</span>
                {/* <Button
                  buttonText='Item List'
                  size='sm'
                  variant='secondary'
                  iconName={selectedOrder && selectedOrder?.id === row?.id ? 'chevron-up' : 'chevron-down'}
                  iconPlacement='end'
                  borderRadius='4px'
                  fixedPadding={'2px 8px'}
                  withIcon
                  disableActive
                  onClick={(e) => handleShowList(e, row)}
                /> */}
              </div>
              : <ItemTitle
                name={row?.itemsArr[0]?.name}
                category={row?.itemsArr[0]?.category}
                length={row?.itemsArr[0]?.length}
                type={row?.itemsArr[0]?.type}
              />

          ),
          renderPopup: (row) => (
            (selectedOrder && selectedOrder?.id === row?.id) && (
              <div ref={itemListPopupRef} className={Styles.popupWrapper}>
                <ItemListPopup
                  items={row?.itemsArr}
                />
              </div>
            )
          ),
          // popupWrapperClassName: Styles.popupWrapper,
          popupPositioning: {
            left: '-100px',
            top: '20px'
          }

        },
        {
          name: 'fabrics',
          label: 'Fabrics',
          minWidth: '200px',
          renderData: (row) => (
            <p className={Styles.fabrics}>{row?.summaries?.map(obj => obj?.textiles)?.flat()?.map(t => (
              <span>{t?.code ? `• ${t?.code}` : '-'}</span>
            ))}</p>
          )
        },
        {
          name: 'sum',
          label: 'Sum',
          minWidth: '150px',
          hideOnMobile: true,
          renderData: (row) => (
            <span>{`Rp${priceFormat(row?.sum)}`}</span>
          )
        },
        {
          name: 'paymentPercentage',
          label: 'Pay Status',
          minWidth: '150px',
          hideOnMobile: true,
          renderData: (row) => (

            <Tag
              title={row?.status === 'PAID' ? 'Paid' : (
                row?.status === 'PAID-OFF'
                  ?
                  'Paid Off'
                  :
                  'Waiting for Payment'
              )}
              type={row?.status === 'PAID' ? 'yellow' : (
                row?.status === 'PAID-OFF'
                  ?
                  'green'
                  :
                  'black'
              )}
            />

          )
        },
        {
          name: 'paymentMethod',
          label: 'Pay Mthd',
          minWidth: '104px',
          hideOnMobile: true,
          renderData: (row) => (
            <Tag
              title={row?.paymentMethod || '-'}
              noBullet
            />
          )

        },
        {
          name: '',
          label: '',
          showOnMobile: true,
          renderData: (row) => (
            <div>
              <Button
                state='icon-only'
                variant='secondary'
                disableActive
                withIcon
                iconName={'list-bullet'}
                size='24'
                fixedPadding={'12px'}
                onClick={() => handleClickRow(row)}
              />
            </div>
          )
        }

      ]
    })
  }, [defaultData?.invoices, handleClickRow, selectedOrder, width])

  const refreshPage = () => {
    window.location.reload();
  }

  return (
    <RekhasBG>
      <TableSet
        type='invoice'
        data={template?.data}
        columns={template?.columns}
        onClickRow={(row) => handleClickRow(row)}
        // totalData={defaultData?.totalData}
        onClickAdd={async () => {
          const getCustomers = await getCustomerList();
          if(getCustomers?.data) {
            setCustomerList(getCustomers.data)
            setShowGenerateInvoice(true)
          }
        }}
        onClickFilter={() => setShowFilter(true)}
        isLoading={loadingData}
        setIsLoading={setLoadingData}
        withSkeleton
        trigger={trigger}
        setTrigger={setTrigger}

      />
      {
        showGenerateInvoice
        &&
        <GenerateInvoiceModal
          show={showGenerateInvoice}
          handleClose={() => setShowGenerateInvoice(false)}
          isManual={true}
          fetchData={refreshPage}
          customerList={customerList}
        />
      }
    </RekhasBG>
  )
}