import AdornmentLabel from '@Atom/AdornmentLabel'
import Avatar from '@Atom/Avatar'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import ModalHeader from '@Atom/ModalHeader'
import { fileBaseUrl } from '@Config/api'
import { makeRandomString } from '@Helpers/makeRandomString'
import InputField from '@Molecule/InputField'
import StackedItemInfo from '@Molecule/StackedItemInfo'
import TextAreaField from '@Molecule/TextAreaField'
import { editEmployee, getEmployeeByCode } from '@Services/employee'
import Images from '@Theme/Images'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Styles from './style.module.scss'
import { getCheckpointList } from '@Services/checkpoint'
import ModalWrapper from '@Atom/ModalWrapper'
import WideErrorModal from '@Molecule/_modals/WideErrorModal'

// const stringToDate = (str) => {
//   const arr = str?.split('/')
//   return [arr[1], arr[0], arr[2]]?.join('/')
// }

const removeStarter = (str) => {
  const newStr = str?.replaceAll(' ', '')?.replaceAll('+', '')
  if (newStr?.startsWith('62')) {
    return newStr?.slice(2)
  }
  return newStr
}

export default function EmployeeDetailsModal({
  employee,
  handleClose = () => { },
  setTrigger = () => { }
}) {
  const [data, setData] = useState(employee)
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [imgUrl, setImgUrl] = useState(employee?.img)
  const [file, setFile] = useState(null)
  const [placementData, setPlacementData] = useState(null)

  const [checkpoints, setCheckpoints] = useState([])

  const [error, setError] = useState(null)

  const fetchCheckpoints = useCallback(async () => {
    try {
      const response = await getCheckpointList()
      setCheckpoints(response?.data)
    } catch (err) {
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
    }
  }, [])

  useEffect(() => {
    fetchCheckpoints()
  }, [fetchCheckpoints])


  // const [error, setError] = useState()

  const [form, setForm] = useState({
    nickName: '',
    fullName: '',
    whatsAppNumber: '',
    role: '',
    storeName: '',
    file: null
  })

  const inputRef = useRef()

  const onChangeFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(undefined)
      return
    }

    setFile(e.target.files[0])
  }


  const fetchData = useCallback(async () => {
    try {
      if (!employee?.code) {
        return false
      } else {
        setIsLoading(true)
        const { data: resData } = await getEmployeeByCode(employee?.code)

        const res = resData?.length ? resData[0] : resData

        setData({
          ...res,
          fullName: res?.fullName || '',
          nickName: res?.nickName || '',
          whatsAppNumber: removeStarter(res?.whatsAppNumber || ''),
          phoneNumber: res?.whatsAppNumber,
          role: res?.role,
          file: res?.imageUrl ? fileBaseUrl + res?.imageUrl : ''
        })
        setPlacementData(res?.placement)
        setIsLoading(false)
      }

    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }, [employee?.code])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    setForm({
      ...data,
      storeName: data?.placement?.name
    })
  }, [data])

  useEffect(() => {
    if (!file) {
      setImgUrl('')
      return
    }

    const objectUrl = URL.createObjectURL(file)
    setImgUrl(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [file])


  useEffect(() => {
    setForm(f => ({
      ...f,
      img: employee?.img,
      name: employee?.name || '',
      whatsAppNumber: removeStarter(data?.whatsAppNumber || ''),
      storeName: employee?.placement?.name,

    }))
  }, [data?.whatsAppNumber, employee])

  useEffect(() => setImgUrl(employee?.img), [employee])

  const handleChangeForm = (newVal, name) => {
    setForm(f => ({
      ...f,
      [name]: newVal
    }))
  }

  const detailsTemplate = useMemo(() => {
    if (employee)
      return [
        {
          name: 'fullName',
          label: '',
          component:
            <div className={Styles.profileCard}>
              <StackedItemInfo
                imgSize='50px'
                picture={data?.imageUrl ? fileBaseUrl + data?.imageUrl : employee?.img || Images.AVA_DEFAULT}
                title={data?.fullName || employee?.name}
                label={data?.role}
              />
            </div>
        },
        {
          name: 'nickName',
          label: 'Nickname',
        },
        {
          name: 'phoneNumber',
          label: 'Phone Number',
        },
        {
          name: 'placement',
          label: 'Placement',
          component:
            <div className={Styles.placement}>
              <Avatar
                src={placementData?.imageUrl ? fileBaseUrl + placementData?.imageUrl : ''}
                size='60px'
                borderRadius='6px'
                withIcon
                iconName={'photo'}
              />
              <span>{placementData?.name || '-'}</span>
            </div>
        }
      ]
  }, [data?.fullName, data?.imageUrl, data?.role, employee, placementData?.imageUrl, placementData?.name])


  const editTemplate = useMemo(() => {
    if (employee) {
      return [
        {
          name: 'img',
          label: 'Profile Image',
          component:
            <div className={Styles.profileCard}>
              <input ref={inputRef}
                type='file'
                onChange={onChangeFile}
                hidden />
              <Avatar
                src={imgUrl || Images?.AVA_DEFAULT}
                size='50px'
                borderRadius='4px'
              />
              <span>{employee?.name}</span>
              <button
                onClick={() => inputRef?.current?.click()}
              >
                <Icon
                  icon={'arrow-uturn-left'}
                  size={24}
                />
              </button>
              <button
                onClick={() => {
                  setFile(null)
                  setImgUrl('')
                }}
              >
                <Icon
                  icon={'trash'}
                  size={24}
                />

              </button>
            </div>
        },
        {
          name: 'fullName',
          label: 'Full Name',
          placeholder: 'Ex: Mohammad Reza Adipati',
          type: 'text',
        },
        {
          name: 'nickName',
          label: 'Nick Name',
          placeholder: 'Enter nickname',
          type: 'text',
        },
        {
          name: 'whatsAppNumber',
          label: 'Phone Number',
          type: 'text',
          placeholder: '812xxxxxxxx',
          withAdornment: true,
          adornmentPosition: 'start',
          adornmentComponent: <AdornmentLabel>+62</AdornmentLabel>
        },
        {
          name: 'role',
          label: 'Role',
          isDropdown: true,
          placeholder: 'Select role',
          dropdownOptions: [
            "SALES",
            "COURIER",
            "CUTTER",
            "TAILOR",
            "PIC",
            "OFFICE",
            "DIRECTOR"
          ]
        },
        {
          name: 'storeName',
          label: 'Placement',
          isDropdown: true,
          placeholder: 'Select placement store',
          dropdownOptions: checkpoints?.map(obj => obj?.name)
        },
      ]
    }
  }, [checkpoints, employee, imgUrl])

  const handleSave = async () => {
    try {
      await editEmployee({
        nickName: form?.nickName,
        fullName: form?.fullName,
        whatsAppNumber: '62' + form?.whatsAppNumber,
        role: form?.role,
        file: file,
        checkPointCode: checkpoints?.find(obj => obj?.name === form?.storeName)?.code
      }, data?.code)
      fetchData()
      setEditMode(false)
      setTrigger(makeRandomString(5))
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'Employee Details'}
        withIcon
        iconName={'user-group'}
        withCloseButton
        handleClose={handleClose}
        isSticky
        titleAdditionalComponent={
          <div className={Styles.editButtonWrapper}>
            <Button
              variant='secondary'
              buttonText='Edit'
              withIcon
              iconName={'pencil'}
              iconPlacement='start'
              size='sm'
              borderRadius='4px'
              onClick={() => setEditMode(!editMode)}
              customClassName={editMode ? Styles.active : ''}
              disableActive
            />
          </div>}
      />
      {editMode
        ?
        <div className={Styles.form}>
          {editTemplate?.map((each, i) => (
            !each?.isTextArea
              ? (!each?.component
                ? (
                  <InputField
                    title={each?.label}
                    value={form[each?.name] || ''}
                    setValue={(newVal) => handleChangeForm(newVal, each?.name)}
                    placeholder={each?.placeholder}
                    type={each?.type}
                    isDropdown={each?.isDropdown}
                    dropdownOptions={each?.dropdownOptions || []}
                    withAdornment={each?.withAdornment}
                    adornmentPosition={each?.adornmentPosition}
                    adornmentComponent={each?.adornmentComponent}
                  />)
                : (
                  <div className={Styles.each}>
                    <div className={Styles.title}>{each?.label}</div>
                    {each?.component}
                  </div>
                )
              )
              : <TextAreaField
                title={each?.label}
                value={form[each?.name] || ''}
                setValue={(newVal) => handleChangeForm(newVal, each?.name)}
                placeholder={each?.placeholder}
              />
          ))}
        </div>
        :
        <div className={Styles.content}>
          {
            detailsTemplate?.map((each, i) => (
              <div>
                {each?.label && <span>{each?.label}</span>}
                {each?.component || <span>{data ? data[each?.name] || '-' : ''}</span>}
              </div>
            ))
          }
        </div>
      }
      {editMode && <div className={Styles.actionsWrapper}>
        <Button
          type='button'
          variant='primary'
          buttonText='Save Data'
          size='lg'
          onClick={handleSave}
          customClassName={Styles.saveButtom}
        />

      </div>
      }

      {error
        &&
        <ModalWrapper
          show={error}
          setShow={() => {
            setError(null)
          }
          }
        >
          <WideErrorModal
            code={error?.code || 500}
            error={error?.message || 'Something went wrong!'}
            withButton
            onClose={() => {
              setError(null)
            }
            }
          />
        </ModalWrapper>

      }
    </div>
  )
}