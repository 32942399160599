import Styles from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import Icon from '@Atom/Icon'

export default function NavTab({
  icon,
  title,
  path,
  newOrderAmount
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isActive = useMemo(() => {
    if (pathname?.split('/')[1] === path) {
      return true
    } return false
  }, [path, pathname])


  return (
    <button className={`${Styles.container} ${isActive ? Styles.active : ''}`} onClick={() => {
      if (pathname?.replaceAll('/', '') !== path) {
        navigate(path)
      }
    }}>
      <Icon icon={icon} size={20} className={Styles.icon} />
      <span>{title}</span>
      {/* {(title === 'Order' && +newOrderAmount > 0)
        && <span className={Styles.redTag}>{newOrderAmount}</span>
      } */}

    </button>
  )
}