import Icon from '@Atom/Icon'
import SingleItemCard from '@Molecule/SingleItemCard'
import SizeCard from '@Molecule/SizeCard'
import { useState } from 'react'
import Styles from './style.module.scss'
import Button from '@Atom/Button'
import DNone from '@Atom/DNone'

export default function CustomerOrderCard({
  data,
  withButton = false,
  buttonText,
  onClickButton = () => { },
  disableProceed = false,
  onDeleteItem = () => { },
  noActions = false,
  noEdit = false,
  noDelete = false,
}) {
  const [expand, setExpand] = useState(false)

  return (
    <div className={Styles.container}>
      <div className={Styles.header} onClick={() => setExpand(!expand)}>
        <span>{data?.customerName}</span>
        <Icon icon={expand ? 'chevron-up' : 'chevron-down'} size={24} />
      </div>
      {
        expand &&
        <div className={Styles.content}>
          {data?.sizes?.filter(obj => obj?.items?.length)?.map((s, iS) => (
            <div
              key={iS}
              className={Styles.perSizeWrapper}
            >
              <PerSize
                s={s}
                onDeleteItem={onDeleteItem}
                noActions={noActions}
                noEdit={noEdit}
                noDelete={noDelete}
              />
              <div className={Styles.divider} />
            </div>

          ))}
          {withButton
            &&
            <Button
              buttonText={buttonText}
              size='lg'
              onClick={onClickButton}
              disabled={disableProceed}

            />
          }
        </div>
      }


    </div>
  )
}

export const PerSize = ({ s, onDeleteItem = () => { }, noActions = false, noEdit = false, noDelete = false, hideOnNoItem = false }) => {

  if (hideOnNoItem && !s?.items?.length) {
    return (
      <DNone />
    )
  }

  return (
    <div className={Styles.perSize}>
      {s?.items?.length
        ? s?.items?.map((i, iI) => (
          <SingleItemCard
            key={iI}
            isReadyToWear={i?.isReadyToWear}
            isAlter={i?.isAlter}
            isFabricOnly={i?.isFabricOnly}
            label={i?.label}
            fabrics={i?.fabrics}
            notes={i?.notes}
            description={i?.description}
            onDelete={onDeleteItem}
            noDelete={noDelete}
            noActions={noActions}
            noEdit={noEdit}
            itemID={i?.id}
            sizeID={s?.id}
          />
        ))
        :
        <DNone />
      }
      <SizeCard
        sizeName={s?.sizeName}
        isDefault={s?.isDefault}
        sizeID={s?.id}
        photos={s?.photos?.filter(obj => obj?.uri)}
        measurements={s?.measurements}
        isV2
        isV3={s?.measurements?.length === 2}
      />
    </div>
  )
}