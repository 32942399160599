import Styles from './style.module.scss'

export default function Switcher({
  options = [],
  value,
  setValue=()=> {}
}) {

  return (
    <div className={Styles.container}>
      {
        options?.map((option, i) => (
          <button
            key={i}
            className={value === option.value ? Styles.active : ''}
            onClick={() => setValue(option?.value)}
          >
            {option?.title}
          </button>
        ))
      }
    </div>
  )
}