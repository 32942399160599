import Icon from '@Atom/Icon'
import Label from '@Atom/Label'
import { fileBaseUrl } from '@Config/api'
import { omitKeys } from '@Helpers/omitKey'
import { priceFormat } from '@Helpers/priceFormat'
import { splitCamelCase } from '@Helpers/splitCamelCase'
import FooterOnlyMiniTable from '@Molecule/FooterOnlyMiniTable'
import ItemListCard from '@Molecule/ItemListCard'
import SizeCard from '@Molecule/SizeCard'
import DetailsModal from '@Molecule/_reusableLayout/DetailsModal'
import { getCustomer } from '@Services/customer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import useQuery from '@Hooks/useQuery'

export default function BuyerDetailsModal({
  buyer,
  handleClose,
}) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true)
  const query = useQuery()

  const [data, setData] = useState(null)

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data: resData } = await (getCustomer(buyer?.code, query.get('df'), query.get('dt')))
      const obj = resData
      setData({
        ...obj,
        id: obj?._id,
        code: obj?.code,
        customerName: obj?.name,
        customerPhoneNumber: obj?.phoneNumber,
        numOfOrders: obj?.numOfOrders,
        numOfItems: obj?.numOfItems
      })
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }, [buyer?.code, query])

  useEffect(() => {
    if (buyer?.code) {
      fetchData()
    }
  }, [buyer?.code, fetchData])

  // eslint-disable-next-line no-unused-vars
  const tagTemplate = useMemo(() => {
    const status = buyer?.status
    switch (status) {
      case 'ONPROCESS':
        return {
          type: 'yellow',
          title: 'On Process'
        }
      case 'DELIVERED':
        return {
          type: 'green',
          title: 'Delivered'
        }
      case 'READY':
        return {
          type: 'black',
          title: 'Ready'
        }
      case 'SCHEDULED':
        return {
          type: 'blue',
          title: 'Scheduled'
        }
      case 'ONBUYER':
        return {
          type: 'blue',
          title: 'Scheduled'
        }
      case 'RETURN':
        return {
          type: 'yellow',
          title: 'Returning to workshop'
        }
      default:
        return {
          type: 'yellow',
          title: 'On Process'
        }
    }
  }, [buyer])

  const contentTemplate = useMemo(() => {
    return ([
      {
        title: 'Num of Orders',
        component:
          <div className={Styles.regular}>
            {data?.orders?.length}
          </div>
      },
      {
        title: 'Have Bought',
        component:
          <div className={Styles.haveBought}>
            <div className={Styles.haveBoughtIntro}>
              <Label
                title={'Items'}
                desc={data?.items}
              />
              <Label
                title={'Transactions'}
                desc={buyer?.numOfOrders}
              />
            </div>
            {/* <div className={Styles.status}>
              <Tag
                type={'yellow'}
                title={'On Process 1'}
              />
              <Tag
                type={'green'}
                title={'Delivered 5'}
              />
            </div> */}
            <ItemListCard
              withTagPerItem
              mappedData={data?.orders?.length
                ?
                data?.orders?.map(obj => {
                  return {
                    id: obj?._id,
                    orderID: obj?.code,
                    dueDate: obj?.dueDate ? new Date(obj?.dueDate) : '',
                    sizeTypes: obj?.products?.length
                      ?
                      obj?.products?.map(i => {
                        return {
                          id: i?._id,
                          name: i.pattern?.name,
                          items: [
                            {
                              id: i?.id,
                              isAlter: false,
                              label: i?.category,
                              notes: i?.description,
                              fabrics: i?.textiles?.map(t => {
                                return {
                                  id: t?._id,
                                  type: '',
                                  fabricType: '',
                                  fabricName: t?.name,
                                  fabricCode: t?.code,
                                  img: t?.imageUrl ? fileBaseUrl + t?.imageUrl : ''
                                }
                              })
                            }
                          ],
                          measurements: [],
                          customerPhotos: i?.pattern?.photos?.map(p => {
                            return {
                              ...p,
                              id: p?._id,
                              url: p?.uri ? fileBaseUrl + p?.uri : ''
                            }
                          })
                        }
                      })
                      :
                      []
                  }
                })
                :
                []
              }
            />
          </div>

      },
      {
        title: 'Size Pattern',
        component:
          <div className={Styles.patterns}>
            {
              data?.patterns?.map((s, sI) => (
                <SizeCard
                  key={sI}
                  sizeName={s?.name}
                  sizeID={s?.code}
                  measurements={
                    Object.entries(omitKeys([
                      'code',
                      'name',
                      'photos',
                      'remark',
                      'createdAt',
                      'customerCode',
                      'updatedAt',
                      '__v',
                      '_id'
                    ], s))
                      ?.map((m) => {
                        // if (m[0] !== "photos")
                        return {
                          name: m[0],
                          value: m[1],
                          title: splitCamelCase(m[0]),
                        };
                      })
                  }
                  customBorderColor='#CED4DA'
                  photos={s?.photos?.map(p => {
                    return {
                      id: p?._id,
                      url: p?.uri ? fileBaseUrl + p?.uri : ''
                    }
                  })}
                />
              ))
            }
            {/* <SizeCard
              sizeName={'Stephen'}
              sizeID={'001'}
              measurements={[
                {
                  name: 'Width',
                  value: '50'
                },
                {
                  name: 'Collar',
                  value: '50'
                },
                {
                  name: 'Chest',
                  value: '50'
                },
                {
                  name: 'Waist',
                  value: '50'
                },
                {
                  name: 'Hips',
                  value: '50'
                },
                {
                  name: 'Wide',
                  value: '50'
                },
                {
                  name: 'Shoulders',
                  value: '50'
                },
                {
                  name: 'Sleeves(LS)',
                  value: '50'
                },
                {
                  name: 'Hand Diameter',
                  value: '50'
                },
                {
                  name: 'Wrist Diameter',
                  value: '50'
                },
              ]}
              customBorderColor='#CED4DA'
              photos={
                [
                  {
                    id: 1,
                    url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                  },
                  {
                    id: 2,
                    url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                  },
                  {
                    id: 3,
                    url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                  },
                ]
              }
            /> */}
          </div>
      },
      {
        title: 'Outstanding',
        component:
          <FooterOnlyMiniTable
            data={data?.outstandings?.map(obj => {
              return {
                id: obj?.orderID,
                nominal: obj?.value
              }
            })}
            columns={[
              {
                name: 'nominal',
                title: 'Outstanding',
                tdClassName: Styles.grey,
                renderData: (row) => (
                  <span>Order ID: {row?.id}</span>
                )
              },
              {
                name: 'total',
                title: `Rp${priceFormat(data?.outstandingTotal)}`,
                align: 'right',
                renderData: (row) => (
                  <span>Rp{priceFormat(row?.nominal)}</span>
                )
              }
            ]}
          />
      },
      {
        title: 'Revenue',
        component:
          <FooterOnlyMiniTable
            data={data?.revenues?.map(obj => {
              return {
                id: obj?.orderID,
                nominal: obj?.value
              }
            })}
            columns={[
              {
                name: 'nominal',
                title: 'Revenue Total',
                tdClassName: Styles.grey,
                renderData: (row) => (
                  <span>Order ID: {row?.id}</span>
                )
              },
              {
                name: 'total',
                title: `Rp${priceFormat(data?.revenueTotal)}`,
                align: 'right',
                renderData: (row) => (
                  <span>Rp{priceFormat(row?.nominal)}</span>
                )
              }
            ]}
          />
      },
      {
        title: 'Invoice',
        component:
          <div className={Styles.invoices}>
            {data?.invoices?.map((each, i) => (
              <div key={i} className={Styles.invoice}>
                <span className={Styles.no}>{i + 1}</span>
                <Icon icon={'document'} size={24} />
                <span className={Styles.id}>
                  {`Order ID: ${each?.orderCode}`}
                </span>
                <a
                  href={`/invoice-view/${each?.code}`}
                 target="_blank"
                  rel="noopener noreferrer"
                >See Detail</a>
              </div>
            ))}
          </div>
      }

    ])

  }, [buyer?.numOfOrders, data?.invoices, data?.items, data?.orders, data?.outstandingTotal, data?.outstandings, data?.patterns, data?.revenueTotal, data?.revenues])

  return (
    <DetailsModal
      modalTitle={'Customer Details'}
      modalIcon={'list-bullet'}
      handleClose={handleClose}
      extraHeaderIntro={buyer?.customerName}
      extraHeaderTitle={buyer?.customerPhoneNumber}
      noTag
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.each}>
          {content?.title && <span>{content?.title}</span>}
          {content?.component}
        </div>
      ))}
    </DetailsModal>
  )
}