import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import { useMemo } from 'react'

export default function Tag({
  type,
  title,
  fixedFontSize,
  fixedPadding,
  borderRadius,
  noBullet = false,
  withBoxShadow
}) {

  const className = useMemo(() => {
    switch (type) {
      case 'yellow':
        return Styles.yellow
      case 'green':
        return Styles.green
      case 'red':
        return Styles.red
      case 'blue':
        return Styles.blue
      case 'orange':
        return Styles.orange
      case 'priority':
        return Styles.priority
      case 'p1':
        return Styles.p1
      case 'p2':
        return Styles.p2
      case 'p3':
        return Styles.p3
      default:
        return Styles.black
    }
  }, [type])

  return (
    <div className={`${Styles.container} ${className}`} style={{ padding: fixedPadding || 'auto', borderRadius: borderRadius || 'auto', boxShadow: withBoxShadow ? '0px 0px 8px 0px rgba(0, 0, 0, 0.20)' : 'none' }}>
      {!noBullet && <Icon icon={"dot"} size={8} className={Styles.icon} />}
      <div className={Styles.title} style={{ fontSize: fixedFontSize || 'auto' }}>{title}</div>
    </div>
  )
}