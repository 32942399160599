import axios from "axios";

export const API = axios.create({
  // baseURL : 'http://localhost:6060/api/v1'
  baseURL: 'https://api.rekhas.id/api/v1'
});

// export const fileBaseUrl = "http://localhost:6060/api/file/";
export const fileBaseUrl = "https://api.rekhas.id/api/file/";
// 
