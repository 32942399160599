import Button from '@Atom/Button'
import Styles from './style.module.scss'
import Images from '@Theme/Images'

export default function WideErrorModal({
  code = '500',
  error = 'Something went wrong',
  desc = 'Please try again later',
  withButton = false,
  onClose = () => { },
  buttonComponent = <Button
    onClick={onClose}
    buttonText='Go back'
    variant='primary'
    withIcon
    iconName='arrow-small-left'
    disableActive
  />
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.top}>
        <div>
          <h3>{code}</h3>
          <p>{error}</p>
        </div>
        <img src={Images.QUESTIONING_NO_BG} alt='' />
      </div>
      <div className={Styles.bottom}>
        <span>Contact engineers to fix the problem</span>
        {withButton
        &&
        buttonComponent
        }
      </div>
    </div>
  )
}