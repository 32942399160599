import ModalWrapper from '@Atom/ModalWrapper'
import InvoiceDetailsModal from '@Molecule/_modals/DetailsModals/Invoice'
import InvoiceListLayout from '@Organism/Invoice/InvoiceList'
import { Suspense, useState } from 'react'
import Styles from './style.module.scss'
import PaidOffFormModal from '@Molecule/_modals/PaidOffFormModal'
import { Await, useLoaderData } from 'react-router-dom'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'
import InvoiceFilterModal from '@Molecule/_modals/FilterModals/Invoice'

export default function InvoiceListPage() {
  const { packageInvoiceList } = useLoaderData()

  const [showDetails, setShowDetails] = useState(false)
  const [selectedInvoiceDetails, setSelectedInvoiceDetails] = useState(null)
  const [showPaidOffForm, setShowPaidOffForm] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [trigger, setTrigger] = useState('')

  // eslint-disable-next-line no-unused-vars
  const [workers, setWorkers] = useState({
    cutters: [],
    tailors: [],
    couriers: []
  })


  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <InvoiceListLayout
            setSelectedInvoiceDetails={setSelectedInvoiceDetails}
            setShowDetails={setShowDetails}
            defaultData={{
              "orders": [],
              "page": 0,
              "totalPage": 0,
              'total': 0
            }}
            isLoading
            setShowFilter={setShowFilter}
            trigger={trigger}
            setTrigger={setTrigger}

          // setShowChangeToReadySetter={setShowChangeToReadySetter}
          />
          // <>loading</>
        }
      >
        <Await
          resolve={packageInvoiceList}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageInvoiceList) => (
            <InvoiceListLayout
              setSelectedInvoiceDetails={setSelectedInvoiceDetails}
              setShowDetails={setShowDetails}
              defaultData={packageInvoiceList?.data}
              setShowFilter={setShowFilter}
              trigger={trigger}
              setTrigger={setTrigger}

            // setShowChangeToReadySetter={setShowChangeToReadySetter}
            />

          )}
        </Await>


      </Suspense>
      {/* <InvoiceListLayout
        setSelectedInvoiceDetails={setSelectedInvoiceDetails}
        setShowDetails={setShowDetails}
      /> */}
      <div>
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={showFilter}
          setShow={setShowFilter}
        >
          <InvoiceFilterModal
            handleClose={() => {
              setShowFilter(false)
              // setTrigger(Math.random())
            }}
            trigger={trigger}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={selectedInvoiceDetails && showDetails}
          setShow={setShowDetails}
        >
          <InvoiceDetailsModal
            order={selectedInvoiceDetails}
            handleClose={() => setSelectedInvoiceDetails(null)}
            onOpenPaidOffForm={() => {
              // setShowDetails(false)
              setShowPaidOffForm(true)
            }}
            trigger={trigger}
            setTrigger={setTrigger}
            workers={workers}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showPaidOffForm}
          setShow={setShowPaidOffForm}
        >
          <PaidOffFormModal
            onClickCancel={() => {
              setShowPaidOffForm(false)
              // setShowDetails(true)
            }}
            orderId={selectedInvoiceDetails?.code}
            remainingValue={selectedInvoiceDetails?.remaining || '0'}
          />
        </ModalWrapper>
      </div>
    </div>
  )
}