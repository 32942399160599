import Icon from "@Atom/Icon";
import Label from "@Atom/Label";
import Tag from "@Atom/Tag";
import SizeCard from "@Molecule/SizeCard";
import StackedItemInfo from "@Molecule/StackedItemInfo";
import moment from "moment";
import { useState } from "react";
import Styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

export default function ItemListCard({ mappedData, withTagPerItem = false, noOrderID = false, isDetailed = false, navigateToStockDetails = false }) {
  console.log(mappedData, 'md')
  return (
    <div className={Styles.container}>
      {mappedData?.map((o, oIdx) => (
        <PerOrderID
          key={oIdx}
          index={oIdx}
          data={o}
          list={mappedData}
          withTagPerItem={withTagPerItem}
          noID={noOrderID}
          isDetailed={isDetailed}
          navigateToStockDetails={navigateToStockDetails}
        />
      ))}
    </div>
  );
}

const PerOrderID = ({ index, data, list, withTagPerItem, noID = false, isDetailed = false, navigateToStockDetails = false }) => {
  const [isExpandded, setIsExpanded] = useState(false);
  const navigate = useNavigate()
  const getNumber = (oIdx, sIdx, idx) => {
    const itemsMultipleArr = list[oIdx]?.sizeTypes?.map((obj) => {
      return obj?.items;
    });

    const itemsArr = [];
    for (let i = 0; i < itemsMultipleArr.length; i++) {
      let arr = itemsMultipleArr[i] || [];

      for (let j = 0; j < arr?.length; j++) {
        itemsArr.push({
          ...arr[j],
          identifier: `${i}-${j}`,
        });
      }
    }

    const index = itemsArr?.findIndex(
      (obj) => obj.identifier === `${sIdx}-${idx}`
    );
    return index + 1;
  };


  return (
    <div className={Styles.section}>
      {!noID
        &&
        <div
          className={Styles.headLabel}
          onClick={() => setIsExpanded(!isExpandded)}
        >
          <span>{`Order ID: ${data?.orderID}`}</span>
          {!!data?.dueDate &&
            <div className={Styles.date}>
              <Icon icon={"calendar"} size={20} />
              <span>{moment(new Date(data?.dueDate))?.format("l")}</span>
            </div>
          }
        </div>
      }

      {true && (
        <div className={Styles.details}>
          {!noID
            &&
            <div className={Styles.divider} />
          }
          <div className={Styles.sizes}>
            {data?.sizeTypes?.map((s, sIdx) => (
              <div key={sIdx} className={Styles.size}>
                <div className={Styles.items}>
                  {s?.items?.map((i, idx) => (
                    <div key={`i-${i?.id}`} className={Styles.item}>
                      <div className={Styles.number}>
                        {getNumber(index, sIdx, idx)}
                      </div>
                      <div className={Styles.details}>
                        {i?.isReadyToWear || i?.isFabricOnly ? (
                          <div className={Styles.rdt}>
                            <div className={Styles.title}>
                              <div className={Styles.logo}>
                                <img
                                  src={
                                    i?.fabrics?.length
                                      ? i?.fabrics[0]?.img
                                      : i?.fabrics?.img
                                  }
                                  alt=""
                                />
                              </div>
                              <div className={Styles.labelWrapper}>
                                <div className={Styles.label}>
                                  {i?.label} {i?.name}
                                </div>
                              </div>
                              {withTagPerItem && (
                                <Tag type={"yellow"} title={"On Process"} />
                              )}
                            </div>
                            {i?.fabrics?.map((f) => (
                              <StackedItemInfo
                                key={`f-${f?.id}`}
                                withoutPicture
                                title={`${f?.fabricType ? f?.fabricType + " - " : ""
                                  }${f?.fabricName}`}
                                label={f?.fabricCode}
                                titleWidth="auto"
                                titleFontSize="16px"
                                imgSize="4.8rem"
                                qty={f?.qty || "1x"}
                                price={f?.price || ""}
                                isDetailed={isDetailed}
                                onClick={navigateToStockDetails && f?.fabricCode ? () => {
                                  navigate(`/stock/overview/${f?.fabricCode?.replaceAll('/', '::')}?t=${f?.fabricType?.toLowerCase()}`)
                                } : undefined}
                              />
                            ))}
                          </div>
                        ) : i?.isAlter ? (
                          <div className={Styles.alter}>
                            <Label desc={"Alter"} padding="0px 8px" />
                            <div className={Styles.alterQty}>
                              <StackedItemInfo
                                withoutPicture
                                title={i?.fabrics[0]?.fabricName || 'Tailoring Fabric'}
                                label={i?.label}
                                titleFontSize="14px"
                                isDetailed={isDetailed}
                              />
                              <span>{i?.qty}</span>
                            </div>
                            {/* <div className={Styles.alterDesc}>
                              <span>Descriptions</span>
                              <span>{i?.desc}</span>
                            </div> */}
                            <div className={Styles.alterNotes}>
                              <span>Notes</span>
                              <span>{i?.notes}</span>
                            </div>
                          </div>
                        ) : (
                          <div className={Styles.reg}>
                            <div className={Styles.title}>
                              <div className={Styles.logo}>
                                <Icon
                                  icon={i?.label?.toLowerCase()}
                                  size={32}
                                />
                              </div>
                              <div className={Styles.labelWrapper}>
                                <div className={Styles.label}>{i?.label}</div>
                              </div>
                              {withTagPerItem && (
                                <Tag type={"yellow"} title={"On Process"} />
                              )}
                            </div>
                            <div className={Styles.fabrics}>
                              {i?.fabrics?.map((f) => (
                                <StackedItemInfo
                                  key={`f-${f?.id}`}
                                  picture={f?.img}
                                  title={`${f?.fabricType ? f?.fabricType + " - " : ""
                                    }${f?.fabricName}`}
                                    label={f?.fabricCode}
                                    // titleWidth="300px"
                                  titleWidth="auto"
                                  titleFontSize="16px"
                                  imgSize="3.2rem"
                                  qty={f?.qty || "1x"}
                                  price={f?.price || ""}
                                  isDetailed={isDetailed}
                                  onClick={navigateToStockDetails && f?.fabricCode ? () => {
                                    navigate(`/stock/overview/${f?.fabricCode?.replaceAll('/', '::')}?t=${f?.fabricType?.toLowerCase()}`)
                                  } : undefined}
                                />
                              ))}
                            </div>
                            {i?.notes && (
                              <div className={Styles.notes}>
                                <span>Notes:</span>
                                <span>{i?.notes}</span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                {
                  !!s?.measurements?.length
                  &&
                  <SizeCard
                    sizeName={s?.name}
                    sizeID={s?.id}
                    measurements={s?.measurements}
                    photos={s?.customerPhotos}
                    isDefault={s?.isDefault}
                  />
                }

              </div>
            ))}
          </div>
          {index !== list?.length - 1 && <div></div>}
        </div>
      )}
    </div>
  );
};
