import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  text: {
    fontFamily: 'Helvetica',
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#6C757D'
  }
})

export default styles