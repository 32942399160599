import ModalWrapper from '@Atom/ModalWrapper'
import useQuery from '@Hooks/useQuery'
import DeliveryDetailsModal from '@Molecule/_modals/DetailsModals/Delivery'
import DeliveryFilterModal from '@Molecule/_modals/FilterModals/Delivery'
import DeliveryListLayout from '@Organism/Delivery/DeliveryList'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'
import { Suspense, useMemo, useState } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Styles from './style.module.scss'

export default function DeliveryListPage() {

  const { packageDeliveryList } = useLoaderData()
  const [selectedDeliveryDetails, setSelectedDeliveryDetails] = useState(null)
  const [showFilter, setShowFilter] = useState(false)
  const [trigger, setTrigger] = useState('')

  // useEffect(() => {
  //   setTrigger(makeRandomString(5))
  // }, [showFilter])

  const query = useQuery()
  const user = localStorage?.getItem('user') ? JSON?.parse(localStorage.getItem('user')) : null;
  const role = user?.role
  const detailModalType = useMemo(() => {
    const activeTab = query.get('t')
    switch (activeTab) {
      case 'stock':
        switch (selectedDeliveryDetails?.status) {
          case 'RETURN':
            return 'm2'
          default:
            return 'm1'
        }
      default:
        switch (role?.toLowerCase()) {
          case 'director':
            switch (selectedDeliveryDetails?.status) {
              case 'RETURN':
                return 'm2'
              default:
                return 'm1'
            }
          case 'courier':
            switch (selectedDeliveryDetails?.status) {
              case 'RETURN':
                return 'm2'
              default:
                return 'm1'
            }
          default:
            return 'default'
        }
    }
  }, [query, role, selectedDeliveryDetails?.status])


  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <DeliveryListLayout
            setSelectedDeliveryDetails={setSelectedDeliveryDetails}
            setShowFilter={setShowFilter}
            defaultData={{
              "deliveries": [],
              "page": 0,
              "totalPage": 0,
              'total': 0
            }}
            isLoading
            trigger={trigger}
            setTrigger={setTrigger}
          // setShowChangeToReadySetter={setShowChangeToReadySetter}
          />
          // <>loading</>
        }
      >
        <Await
          resolve={packageDeliveryList}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageDeliveryList) => (
            <DeliveryListLayout
              setSelectedDeliveryDetails={setSelectedDeliveryDetails}
              setShowFilter={setShowFilter}
              defaultData={packageDeliveryList?.data}
              trigger={trigger}
              setTrigger={setTrigger}
            // setShowChangeToReadySetter={setShowChangeToReadySetter}
            />

          )}
        </Await>


      </Suspense>
      <div>
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={selectedDeliveryDetails}
          setShow={() => setSelectedDeliveryDetails(null)}
        >
          <DeliveryDetailsModal
            delivery={selectedDeliveryDetails}
            handleClose={() => setSelectedDeliveryDetails(null)}
            type={detailModalType}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={showFilter}
          setShow={setShowFilter}
        >
          <DeliveryFilterModal
            handleClose={() => setShowFilter(false)}
            trigger={trigger}
          />
        </ModalWrapper>

        {/* <ModalWrapper
          show={false}
          desktopPositioning='center'
          mobilePositioning='center'
        >
          <PicturesPreviewModal
          srcArr={[
            'https://veteransdistribution.com/wp-content/uploads/2020/07/pre-delivery-inspections.jpeg',
            'https://1021sunrise.com/wp-content/uploads/2019/08/courier-service-1024x682.jpeg',
            'https://veteransdistribution.com/wp-content/uploads/2020/07/pre-delivery-inspections.jpeg',
            'https://1021sunrise.com/wp-content/uploads/2019/08/courier-service-1024x682.jpeg',
            'https://veteransdistribution.com/wp-content/uploads/2020/07/pre-delivery-inspections.jpeg',
            'https://1021sunrise.com/wp-content/uploads/2019/08/courier-service-1024x682.jpeg',
            'https://veteransdistribution.com/wp-content/uploads/2020/07/pre-delivery-inspections.jpeg',
            'https://1021sunrise.com/wp-content/uploads/2019/08/courier-service-1024x682.jpeg',
            'https://veteransdistribution.com/wp-content/uploads/2020/07/pre-delivery-inspections.jpeg',
            'https://1021sunrise.com/wp-content/uploads/2019/08/courier-service-1024x682.jpeg',
            'https://veteransdistribution.com/wp-content/uploads/2020/07/pre-delivery-inspections.jpeg',
          ]}
          />

        </ModalWrapper> */}
      </div>
    </div>
  )
}