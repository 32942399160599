import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function Snackbar({
  title = 'Status Updated',
  desc = 'Status changed',
  onClose = () => { }
}) {

  return (
    <div className={Styles.container}>
      <Icon icon={'check-circle'} size={48} />
      <div>
        <h3>{title}</h3>
        <div>{desc}</div>
      </div>
      <button onClick={onClose}>
        <Icon icon={'x-mark'} size={24} />
      </button>
    </div>
  )
}