import Button from '@Atom/Button';
import Checkbox from '@Atom/Checkbox';
import Icon from '@Atom/Icon';
import { priceFormat } from '@Helpers/priceFormat';
import Styles from './style.module.scss';
import { useMemo } from 'react';

export default function CartItemDetailsCard({
  checkedItems,
  setCheckedItems,
  item,
  setSelectedItem,
  setMode,
  forceHideMeasurementStatus = false,
  noActions = false,
  noChekcbox = false,
  denom = '',
  onDeleteItem = () => { }
}) {

  const measurements = useMemo(() => {
    const all = item?.measurements
    if (!item?.type?.toLowerCase()?.includes('regular')) {
      return all?.filter(obj => obj?.name?.toLowerCase() !== 'length')
    } return all
  }, [item?.measurements, item?.type])

  return (
    <div className={Styles.container}>
      <div className={Styles.itemHeader}>
        {!noChekcbox
          &&
          <Checkbox
            isChecked={checkedItems?.find(obj => obj?.id === item?.id) ? true : false}
            setIsChecked={() => {
              const index = checkedItems.indexOf(item);
              if (index !== -1) {
                const newCheckedItems = [...checkedItems]
                newCheckedItems.splice(index, 1)
                setCheckedItems(newCheckedItems)
              } else {
                setCheckedItems([
                  ...checkedItems,
                  item
                ])
              }
            }}
          />
        }

        <img
          src={item?.img}
          alt=''
        />
        <div className={Styles.details}>
          <span className={Styles.name}>
            {item?.name}
          </span>
          {
            item?.showMeasurementStatus && !forceHideMeasurementStatus
            &&
            <span className={`${Styles.status} ${item?.isMeasured ? Styles.measured : ''}`}>
              {item?.isMeasured ? 'Size measured' : 'Size has not been measured'}
            </span>
          }
          <span className={Styles.price}>
            Rp {priceFormat(item?.price)}
          </span>
        </div>
        {
          !noActions
          &&
          <div className={Styles.itemActions}>
            {item?.isCustomSizing
              &&
              <Button
                variant={!item?.isMeasured ? 'primary' : 'secondary'}
                buttonText={item?.isMeasured ? 'Edit Size Measure' : 'Measure Size'}
                withIcon
                iconName={'pencil'}
                iconPlacement='start'
                customIconSize={20}
                disableActive
                onClick={() => {
                  setSelectedItem(item)
                  setMode('measurement')
                }}
              />
            }
            <button
              onClick={onDeleteItem}
            >
              <Icon
                icon={'trash'}
                size={24}
              />
            </button>
          </div>
        }

      </div>
      {
        measurements?.filter(obj => obj?.value)?.length
          ?
          <div
            className={Styles.measurements}
            style={{
              paddingLeft: noChekcbox ? 0 : '40px'
            }}
          >
            {
              measurements?.filter(obj => obj?.value)?.map((each, i) => (
                <div key={i}>
                  <span>{each?.title}</span>
                  <span>{each?.value} {!each?.value?.includes(denom) && denom}</span>
                </div>
              ))
            }
          </div>
          : null
      }

    </div>
  )
}