
export const deliveryTypes = [
  {
    query: '',
    title: 'Order'
  },
  {
    query: 'stock',
    title: 'Stock'
  },
]
export const masterDeliveryTypes = [
  {
    query: '',
    title: 'Customer'
  },
  {
    query: 'stock',
    title: 'Stock'
  },
]