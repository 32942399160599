import Tag from '@Atom/Tag'
import Styles from './style.module.scss'
import Avatar from '@Atom/Avatar'

export default function BuyerNameWithTag({
  intro = '',
  name = '',
  tagType = 'yellow',
  tagTitle = 'tag title goes here',
  noTag,
  withPhoneNumber = false,
  withEmail = false,
  phoneNumber = '+62...',
  email = '-',
  withImage,
  image
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.top}>
        <span>{intro}</span>
        {!noTag
          && <Tag
            type={tagType}
            title={tagTitle}
            withBoxShadow
          />
        }
      </div>
      <div className={Styles.profile}>
        {withImage
          &&
          <Avatar
            size='32px'
            src={image}
            borderRadius='50%'
          />
        }
        <div>
          <span>{name}</span>
          
          {withPhoneNumber && <span className={Styles.phoneNumber}>{phoneNumber}</span>}

          {withEmail && <span className={Styles.phoneNumber}>{email}</span>}

        </div>
      </div>
    </div>
  )
}