import { API } from "@Config/api";

export function getCheckpoints(page, limit, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/checkpoint?page=${page}&limit=${limit}&search=${search}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}
export function getCheckpointList() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/checkpoint/list`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function getCheckpoint(id, page, limit, type, search, startDate = '', endDate = '', priceLow = '', priceAbove = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/checkpoint/${id}?page=${page}&limit=${limit}&type=${type}&search=${search}&startDate=${startDate}&endDate=${endDate}&priceLow=${priceLow}&priceAbove=${priceAbove}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function editCheckpoint(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/checkpoint/${id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          // 'Content-Type': 'application/x-www-form-urlencoded'
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}