import Skeleton from 'react-loading-skeleton'
import Styles from './style.module.scss'
import { useState } from 'react'

export default function SimulationThumbnail({
  label,
  img,
  onClick = () => { },
  isLoading = false
}) {

  const [fileLoading, setFileLoading] = useState(true)


  if (isLoading) {
    return (
      <div className={Styles.container} onClick={!isLoading ? onClick : () => { }}>
        <div className={Styles.card}>
          <Skeleton className={Styles.img} />
          <Skeleton enableAnimation={false} className={Styles.replace} />
        </div>
        <Skeleton className={Styles.label} />
      </div>)
  }

  return (
    <div className={Styles.container} onClick={!isLoading ? onClick : () => { }}>
      <div className={Styles.card}>
        {
          fileLoading
          &&
          <div className={Styles.placeholder}>

            <Skeleton className={Styles.img} />
          </div>
        }
        <img
          src={img}
          alt=''
         onLoad={() => setFileLoading(false)}
        />
        <button>Replace Thumbnail</button>
      </div>
      <span>{label}</span>
    </div>
  )
}