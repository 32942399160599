import { Await, useLoaderData } from 'react-router-dom'
import styles from './style'
import { Suspense, useMemo } from 'react'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'
import { Document, Image, PDFViewer, Page, Text, View, pdf } from '@react-pdf/renderer'
import Images from '@Theme/Images'
import moment from 'moment'
import { fileBaseUrl } from '@Config/api'
import useDeviceType from '@Helpers/deviceChecker'
import { saveAs } from 'file-saver';
import Button from '@Atom/Button'

export default function DeliveryLetter() {
  const { packageDeliveryDetails } = useLoaderData()

  return (
    <Suspense
      fallback={
        <>loading</>
      }
    >
      <Await
        resolve={packageDeliveryDetails}
        errorElement={<AsyncErrorBoundary />}
      >
        {(packageDeliveryDetails) => (
          <DeliveryLetterPDF
            defaultData={packageDeliveryDetails?.data}
          />
        )}
      </Await>
    </Suspense>
  )
}

const DeliveryLetterPDF = ({
  defaultData
}) => {
  const deviceType = useDeviceType();

  const typeInBahasa = {
    TROUSER: 'Celana',
    SUIT: 'Jas',
    SHIRT: 'Kemeja',
  }


  const contentTemplate = useMemo(() => {
    return [
      {
        title: 'Schedule',
        component:
          <View style={styles.schedule}>
            <Image src={Images.CALENDAR_DAYS} style={styles.scLogo} />
            <Text style={styles.scText}>{defaultData?.scheduledAt ? moment(new Date(defaultData?.scheduledAt))?.format('DD/MM/YYYY') : '-'}</Text>
          </View>

      },
      {
        title: 'Items',
        component:
          defaultData?.items?.length || defaultData?.processtextiles?.length ?
            <View style={styles.items}>
              {defaultData?.processtextiles?.textiles?.length
                ?
                    <View>
                    {defaultData?.processtextiles?.textiles.map((each, i) => (
                      <View style={styles.productDetail} key={i}>
                        <View>
                          <Text style={styles.productName}>{i+1}. {typeInBahasa[defaultData.processtextiles.type] ?? defaultData.processtextiles.type} {'('}{each.fabric}{')'}</Text>
                        </View>
                        <View>
                          <Text style={styles.productName}>{each?.qty}x</Text>
                        </View>
                      </View>
                    ))}
                      <View style={styles.notes}>
                        <Text style={styles.nTitle}>
                          Notes:
                        </Text>
                        <Text style={styles.nText}>
                          {defaultData?.processtextiles?.notes || '-'}
                        </Text>
                      </View>
                    </View>
                :
                    <View>
                      <View style={styles.notes}>
                        <Text style={styles.nTitle}>
                          Notes:
                        </Text>
                        <Text style={styles.nText}>
                          {defaultData?.items[0]?.notes || '-'}
                        </Text>
                      </View>
                      <View style={styles.table}>
                          <View>
                            <View style={styles.tableRow}> 
                              <View style={styles.tabelColNum}> 
                                <Text style={styles.tableCell}>No</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Product</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Qty</Text> 
                              </View>
                            </View>
                            {defaultData?.items?.map((textile, idx) => (
                              <View key={idx} style={styles.tableRow}>
                                <View style={styles.tabelColNum}> 
                                  <Text style={styles.tableCell}>{idx+1}</Text>
                                </View> 
                                <View style={styles.tableCol}> 
                                  <Text style={styles.tableCell}>{textile?.name}</Text>
                                </View> 
                                <View style={styles.tableCol}> 
                                  <Text style={styles.tableCell}>{textile.qty}</Text>
                                </View>
                              </View>
                            ))}
                          </View>
                      </View>
                    </View>
              }
            </View>
            :
            <Text style={styles.text}>no item</Text>
      },
      {
        title: 'Address',
        component:
          <Text style={styles.text}>
            {defaultData?.to?.address}
          </Text>
      },
      {
        title: 'Notes',
        component:
          <Text style={styles.text}>
            {defaultData?.note || '-'}
          </Text>
      },
    ]
  }, [defaultData?.items, defaultData?.note, defaultData?.processtextiles, defaultData?.scheduledAt, defaultData?.to?.address])

  const PDFDocument = () => {
    return (
      <Document>
        <Page size={"A4"} style={styles.page}>
          <View style={styles.headerWrapper} fixed>
            <Image src={Images.LOGO_NO_ICON} style={styles.logo} />
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <Text style={styles.title}>Delivery Letter</Text>
                <Text style={styles.subtitle}>Delivery ID: {defaultData?.code}</Text>
              </View>
              <View style={styles.deliverTo}>
                <Text style={styles.dtTitle}>
                  Deliver To
                </Text>
                <Text style={styles.dtSubTitle}>
                  {defaultData?.to?.name}
                </Text>
              </View>
              {/* <View style={styles.headerRight}>
                <Text style={styles.dueDateTitle}>Due Date</Text>
                <View style={styles.dueDateContent}>
                  <Image src={Images.CALENDAR_DAYS} style={styles.calendar} />
                  <Text style={styles.formerDueDate}>{data?.dueDate || '-'}</Text>
                  <Text style={styles.currentDueDate}>{data?.currentDueDate || '-'}</Text>
                </View>
              </View> */}
            </View>

          </View>
          <View style={styles.content}>
            {
              contentTemplate?.map((c, cI) => (
                <View key={cI} style={styles.section}>
                  <Text style={styles.sTitle}>{c?.title}</Text>
                  {c?.component}
                </View>
              ))
            }
          </View>
        </Page>
      </Document>
    )
  }

  const handleDownload = async () => {
    const blob = await pdf(<PDFDocument />).toBlob();
    saveAs(blob, 'document.pdf');
  };

  if(deviceType === 'mobile') return (
    <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Button
        buttonText='Download Delivery Letter'
        onClick={handleDownload}
      />
    </div>
  )

  return (
    <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
      <PDFDocument/>
    </PDFViewer>
  )
}

