import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    borderRadius: '8px',
    border: '1px solid #E9ECEF',
    padding: '8px',
    gap: '4px'
  },
  headers: {
    gap: '8px',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px'
  },
  avatar: {
    display: 'block',
    width: '40px',
    aspectRatio: 1,
    backgroundColor: '#e9ecef',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  smallAvatar: {
    display: 'block',
    width: '16px',
    aspectRatio: 1,
    backgroundColor: '#e9ecef',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  mediumAvatar: {
    display: 'block',
    width: '24px',
    aspectRatio: 1,
    backgroundColor: '#e9ecef',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    aspectRatio: 1,
    objectFit: 'cover',
  },
  desc: {
    flexGrow: 1
  },
  type: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
  },
  typeLabelWrapper: {
    flexGrow: 1
  },
  typeLabel: {
    padding: '2px 8px',
    border: '1px solid #DEE2E6',
    borderRadius: '4px',
    alignSelf: 'flex-start'
  },
  typeName: {
    fontSize: '10px',
  },
  name: {
    fontSize: '10px',
    fontWeight: 'normal',
    // fontFamily: 'Helvetica-Bold'
  },
  code: {
    fontSize: '10px',
    fontWeight: 'normal',
    color: '#ADB5BD'
    // fontFamily: 'Helvetica-Bold'
  },
  title: {

  },
  price: {
    fontSize: '12px',
    fontWeight: 400,
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  sizes: {
    flexDirection: 'row',
    gap: '4px',
    flexWrap: 'wrap'
  },
  size: {
    width: '102.5px',
    padding: '4px',
    border: '1px solid #E9ECEF',
    borderRadius: '4px'
  },
  sizeName: {
    fontSize: '10px',
    fontWeight: 400
  },
  sizeValue: {
    fontSize: '10px',
    fontWeight: 400,
    color: '#6C757D'
  },
  notes: {
    padding: '12px',
    gap: '8px',
    borderRadius: '8px',
    border: '1px solid #E9ECEF',
    backgroundColor: '#F8F9FA'
  },
  notesTitle: {
    fontSize: '12px',
    fontWeight: 500
  },
  notesContent: {
    fontSize: '12px',
    fontWeight: 400
  }
})

export default styles