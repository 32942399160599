import StockOverviewLayout from '@Organism/Stock/StockOverview'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'
import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Styles from './style.module.scss'
// import CameraView from '@Molecule/CameraView'

export default function StockOverviewPage() {
  const { packageStockOverview } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <StockOverviewLayout
            isLoading={true}
            defaultData={null}
          />
        }
      >
        <Await
          resolve={packageStockOverview}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageStockOverview) => (
            <StockOverviewLayout
              defaultData={packageStockOverview?.data}
            />

          )}
        </Await>


      </Suspense>
    </div>
  )
}