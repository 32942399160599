import ItemDetails from '@Molecule/ItemDetails'
import Styles from './style.module.scss'

export default function ItemListPopup({
  items
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>Item List</div>
      <div className={Styles.content}>
        {
          items?.map((item, i) => (
            <div key={i}>
              <span>{i + 1}</span>
              <ItemDetails
                item={item}
              />
            </div>
          ))
        }
      </div>
    </div>
  )

}