import Avatar from '@Atom/Avatar'
import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import Images from '@Theme/Images'

export default function AssignedWorkerLabel({
  title,
  img,
  name,
  setValue = () => { },
  noButton = false
}) {

  return (
    <div className={Styles.container}>
      <span>{title}</span>
      <div>
        <Avatar src={img || Images.AVA_DEFAULT} size='24px' />
        <span>{name}</span>
        {!noButton
          &&
          <button>
            <Icon icon={'arrow-uturn-left'} size={20} />
          </button>
        }
      </div>
    </div>
  )
}