import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function ModalHeader({
  title,
  withIcon,
  iconName,
  iconSize = 28,
  withCloseButton,
  paddingX = '24px',
  paddingY = '24px',
  isSticky = false,
  extraComponent,
  titleAdditionalComponent,
  handleClose = () => { }
}) {

  return (
    <div className={`${Styles.wrapper} ${isSticky ? Styles.sticky : ''}`}>

      <div className={`${Styles.container} `} style={{ padding: `${paddingY} ${paddingX}` }}>
        {withIcon && <Icon icon={iconName} size={iconSize} />}
        <div className={Styles.left}>
          <span>{title}</span>
          {titleAdditionalComponent}
        </div>
        {withCloseButton
          && <button onClick={handleClose}>
            <Icon icon={'x-circle-solid'} size={28} />
          </button>
        }
      </div>
      {extraComponent && (
        <div className={Styles.extra} style={{ padding: `${paddingY} ${paddingX}` }}>
          {extraComponent}
        </div>
      )}
    </div>
  )
}