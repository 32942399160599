import Icon from '@Atom/Icon'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function ProductLayout({
  mode
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const template = useMemo(() => {

    return ([
      {
        title: 'Simulation',
        desc: 'Select our materials, measure size, and prepare for delivery!',
        path: 'simulation'
      },
      {
        title: 'Ready To Wear',
        desc: 'Select our very own creation and wear them in style!',
        path: 'listing'
      },
      {
        title: 'Collection',
        desc: 'Select our very own collection',
        path: 'collection'
      },
      {
        title: 'Alter',
        desc: 'Alter you style with the best',
        path: 'alter'
      },
    ])
  }, [])

  return (
    <div className={Styles.container}>
      {
        template?.map((each, i) => (
          <div
            key={i}
            onClick={() => navigate(`${pathname}/${each?.path}`)}
          >
            <div>
              <span>{each?.title}</span>
              <Icon icon={'arrow-right-circle'} size={40} />
            </div>

            <span>{each?.desc}</span>

          </div>
        ))
      }

    </div>
  )

}

 // return (
  //   <div className={Styles.container}>
  //     <div className={Styles.header}>
  //       <div className={Styles.top}>
  //         <h3>Product</h3>
  //         <div className={Styles.typesWrapper}>

  //           <button
  //             className={`${Styles.typeIndicator} ${mode === 'listing' ? Styles.active : ''}`}
  //             onClick={() => navigate(`${pathname}?m=listing`, { replace: true })}
  //           >
  //             Ready To Wear
  //           </button>
  //           <button
  //             className={`${Styles.typeIndicator} ${mode === 'simulation' ? Styles.active : ''}`}
  //             onClick={() => navigate(`${pathname}?m=simulation`, { replace: true })}
  //           >
  //             Simulation
  //           </button>

  //         </div>
  //       </div>
  //       <div className={Styles.bottom}>
  //         <div className={Styles.wrapper}>
  //           <div className={Styles.left}>
  //             <div className={Styles.title}>
  //               <h3>{template?.title}</h3>
  //               <Icon icon={template?.icon} className={template?.iconClassName} size={template?.iconSize} />
  //             </div>
  //             <span>{template?.sub}</span>
  //           </div>
  //           <div className={Styles.right}>
  //             <img src={Images.BLUE_SHIRT} alt='' />
  //             <img src={Images.BLACK_SUIT} alt='' />
  //           </div>
  //         </div>

  //       </div>
  //     </div>
  //     <div className={Styles.content}>
  //       {mode === 'listing'
  //         ? <ListingLayout />
  //         : <SimulationLayout />
  //       }
  //     </div>

  //   </div>
  // )