import DeliveryLetter from "@Molecule/DeliveryLetter";
import Invoice from "@Molecule/Invoice";
import CustomerFile from "@Molecule/Invoice/CustomerFile";
import ReactOpening from "@Organism/ReactOpening";
import AsyncErrorBoundary from "@Organism/_utils/AsyncErrorBoundary";
import { getCheckpoint, getCheckpointList, getCheckpoints } from "@Services/checkpoint";
import { getCustomerList, getCustomers } from "@Services/customer";
import { getDeliverSync, getDeliveries, getDelivery, getDeliveryById } from "@Services/delivery";
import { getEmployees, getWorkerList } from "@Services/employee";
import { getFees } from "@Services/fee";
import { getInvoice, getInvoices } from "@Services/invoice";
import { getOrder, getOrderSync, getOrders } from "@Services/order";
import {
  getProduct,
  getProducts,
  getProductsByCategory,
  getSimulationThumbnails,
} from "@Services/product";
import { getStock, getStocks } from "@Services/stock";
import { getWorkshops, getWorkshopsReqeust } from "@Services/workshop";
import BuyerListPage from "@Views/Buyers/BuyerList";
import CartPage from "@Views/Cart";
import CheckpointListPage from "@Views/Checkpoint/CheckpointList";
import CheckpointOverviewPage from "@Views/Checkpoint/CheckpointOverview";
import DeliveryListPage from "@Views/Delivery/DeliveryList";
import EmployeeListPage from "@Views/Employee/EmployeeList";
import FeeListPage from "@Views/Fee/FeeList";
import InvoiceListPage from "@Views/Invoice/InvoiceList";
import LogListPage from "@Views/Log/LogList";
import LoginPage from "@Views/Login";
import MainPage from "@Views/Main";
import OrderListPage from "@Views/Order/OrderList";
import ProductPage from "@Views/Product";
import AlterPage from "@Views/Product/Alter";
import CollectionListPage from "@Views/Product/Collection/CollectionList";
import CollectionOverviewPage from "@Views/Product/Collection/CollectionOverview";
import ListingPage from "@Views/Product/Listing";
import ListingOverviewPage from "@Views/Product/ListingOverview";
import SimulationPage from "@Views/Product/Simulation";
import SimulationOverviewPage from "@Views/Product/SimulationOverview";
import StartMeasurePage from "@Views/Product/StartMeasureCollection";
import StartMeasureReadyPage from "@Views/Product/StartMeasureReady";
import SetupPage from "@Views/Setup";
import StockListPage from "@Views/Stock/StockList";
import StockOverviewPage from "@Views/Stock/StockOverview";
import WorkshopListPage from "@Views/Workshop/WorkshopList";
import WorkshopRequestListPage from "@Views/WorkshopRequest/WorkshopRequestList";

const { createBrowserRouter, redirect, defer } = require("react-router-dom");

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    loader: (route) => {
      if (!localStorage.getItem("accessToken")) {
        throw redirect("/login");
      }
      return null;
    },
    children: [
      {
        index: true,
        element: <ReactOpening />,
        loader: () => {
          const user = localStorage?.getItem('user') ? JSON.parse(localStorage['user']) : {}
          const role = user?.role || ''
          if (role) {
            switch (role) {
              case "DIRECTOR":
                throw redirect("/stock");
              case "OFFICE":
                throw redirect("/stock");
              case "SALES":
                throw redirect("/product");
              case "COURIER":
                throw redirect("/delivery");
              default:
                throw redirect("/unregistered-role");
            }
          } else {
            throw redirect("/login");
          }
        },
      },
      {
        path: "stock",
        children: [
          {
            index: true,
            element: <StockListPage />,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const type = query.get("t") || "";
              const limit = query.get("l") || 30;
              const page = query.get("p") || 1;
              const search = query.get("s") || "";
              const priceFrom = query.get("pf") || "";
              const priceTo = query.get("pt") || "";
              const dateFrom = query.get("df") || "";
              const dateTo = query.get("dt") || "";
              const sortBy = query.get("sortBy") || "";

              const convertDate = (str) => {
                const arr = str?.split("/");
                return [arr[2], arr[1], arr[0]]?.join("-");
              };

              const dfStr = dateFrom ? convertDate(dateFrom) : "";
              const dtStr = dateFrom ? convertDate(dateTo) : "";

              return defer({
                packageStockList: getStocks(
                  type,
                  page,
                  limit,
                  priceFrom,
                  priceTo,
                  dfStr,
                  dtStr,
                  search,
                  sortBy
                ),
                packageDeliverSync: getDeliverSync(),
              });
            },
          },
          {
            path: "overview/:id",
            element: <StockOverviewPage />,
            loader: async ({ params }) => {
              const { id } = params;

              return defer({
                packageStockOverview: getStock(id?.replaceAll('::', '%2F')?.replaceAll(' ', '%20')?.replaceAll(':_:','%5Ct' )),
              });
            },
          },
        ],
      },
      {
        path: "order",
        children: [
          {
            index: true,
            element: <OrderListPage />,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const limit = query.get("l") || 30;
              const page = query.get("p") || 1;
              const search = query.get("s") || "";
              const type = query.get("t") || "TRANSACTION";
              const dateFrom = query.get("of") || ''
              const dateTo = query.get("ot") || ''

              const convertDate = (str) => {
                const arr = str?.split("/");
                return [arr[2], arr[1], arr[0]]?.join("-");
              };

              const dfStr = dateFrom ? convertDate(dateFrom) : "";
              const dtStr = dateFrom ? convertDate(dateTo) : "";


              return defer({
                packageOrderList: getOrders(page, limit, search, type?.toUpperCase(), dfStr, dtStr),
                packageWorkerList: getWorkerList(),
              });
            },
          },
        ],
      },
      {
        path: "invoice",
        children: [
          {
            index: true,
            element: <InvoiceListPage />,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const limit = query.get("l") || 30;
              const page = query.get("p") || 1;
              const search = query.get("s") || "";
              const dateFrom = query.get("df") || "";
              const dateTo = query.get("dt") || "";
              const payDateFrom = query.get("sf") || "";
              const payDateTo = query.get("st") || "";

              const defaultPaymentMethod = query?.get('is') ? query.get('is')?.split('::') : []
              const defaultPaymentStatus = query?.get('pp') ? query.get('pp')?.split('::')?.map(obj => +obj === 50 ? 'PAID' : 'PAID-OFF') : []

              const convertDate = (str) => {
                const arr = str?.split("/");
                return [arr[2], arr[1], arr[0]]?.join("-");
              };


              const dfStr = dateFrom ? convertDate(dateFrom) : "";
              const dtStr = dateFrom ? convertDate(dateTo) : "";
              const paymentMethodStr = defaultPaymentMethod[0] || ''
              const paymentStatusStr = defaultPaymentStatus[0] || ''
              const pfStr = payDateFrom ? convertDate(payDateFrom) : "";
              const ptStr = payDateTo ? convertDate(payDateTo) : "";

              return defer({
                packageInvoiceList: getInvoices(page, limit, search, dfStr, dtStr, paymentMethodStr, paymentStatusStr, pfStr, ptStr),
              });
            },
          },
          {
            path: ":id",
            element: <Invoice />,
            loader: async ({ params }) => {
              const { id } = params;

              return defer({
                packageInvoiceDetails: getInvoice(id),
              });
            },
          },
        ],
      },
      {
        path: "delivery",
        children: [
          {
            index: true,
            element: <DeliveryListPage />,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const limit = query.get("l") || 30;
              const page = query.get("p") || 1;
              const search = query.get("s") || "";
              const status = query.get('is') || ''
              const type = query.get('t') || 'customer'
              const scheduledAt = query.get('sa') || ''
              const deliveredAt = query.get('da') || ''


              return defer({
                packageDeliveryList: getDeliveries(
                  type?.toUpperCase(),
                  page,
                  limit,
                  status,
                  search,
                  scheduledAt,
                  deliveredAt
                ),
              });
            },
          },

        ],
      },
      {
        path: "product",
        children: [
          {
            index: true,
            element: <ProductPage />,
          },
          {
            path: "simulation",
            children: [
              {
                index: true,
                element: <SimulationPage />,
                loader: async () => {
                  return defer({
                    packageSimulationThumbnails: getSimulationThumbnails(),
                  });
                },
              },
              {
                path: "simulate",
                element: <SimulationOverviewPage />,
                loader: async (route) => {
                  const url = new URL(route?.request?.url);
                  const query = url.searchParams;
                  const category = query.get("c") || '';

                  return defer({
                    packageSimulationThumbnails: getSimulationThumbnails(),
                    packageSimulationTemplate: getProductsByCategory(category),
                  });
                },
              },
            ],
          },
          {
            path: "listing",
            children: [
              {
                index: true,
                element: <ListingPage />,
                loader: async (route) => {
                  const url = new URL(route?.request?.url);
                  const query = url.searchParams;
                  const type = "ready-to-wear";
                  const limit = query.get("l") || 30;
                  const page = query.get("p") || 1;
                  const search = query.get("s") || "";
                  const priceFrom = query.get("pf") || "";
                  const priceTo = query.get("pt") || "";
                  const dateFrom = query.get("df") || "";
                  const dateTo = query.get("dt") || "";

                  return defer({
                    packageListingList: getProducts(
                      type,
                      page,
                      limit,
                      priceFrom,
                      priceTo,
                      dateFrom,
                      dateTo,
                      search
                    ),
                  });
                },
              },
              {
                path: "overview/:id",
                element: <ListingOverviewPage />,
                loader: async ({ params }) => {
                  const { id } = params;

                  return defer({
                    packageListingOverview: getProduct(id),
                  });
                },
              },
              {
                path: "start-measure/:id",
                element: <StartMeasureReadyPage />,
                loader: async ({ params }) => {
                  const { id } = params;

                  return defer({
                    packageMeasurements: getStock(id?.replaceAll('::', '%2F')),
                    customerList: getCustomerList(),
                  });
                },
              },
            ],
          },
          {
            path: "collection",
            children: [
              {
                index: true,
                element: <CollectionListPage />,
                loader: async (route) => {
                  const url = new URL(route?.request?.url);
                  const query = url.searchParams;
                  const type = "collection";
                  const limit = query.get("l") || 30;
                  const page = query.get("p") || 1;
                  const search = query.get("s") || "";
                  const priceFrom = query.get("pf") || "";
                  const priceTo = query.get("pt") || "";
                  const dateFrom = query.get("df") || "";
                  const dateTo = query.get("dt") || "";

                  return defer({
                    packageCollectionList: getProducts(
                      type,
                      page,
                      limit,
                      priceFrom,
                      priceTo,
                      dateFrom,
                      dateTo,
                      search
                    ),
                  });
                },
              },
              {
                path: "overview/:id",
                element: <CollectionOverviewPage />,
                loader: async ({ params }) => {
                  const { id } = params;

                  return defer({
                    packageCollectionOverview: getProduct(id?.replaceAll('::', '%2F')),
                  });
                },
              },
              {
                path: "start-measure/:id",
                element: <StartMeasurePage />,
                loader: async ({ params }) => {
                  const { id } = params;

                  return defer({
                    packageMeasurements: getStock(id?.replaceAll('::', '%2F')),
                    customerList: getCustomerList(),
                  });
                },
              },
            ],
          },
          {
            path: "alter",
            element: <AlterPage />,
            loader: async () => {
              return defer({
                customerList: getCustomerList(),
              })
            }
          },
          {
            path: "cart",
            element: <CartPage />,
            loader: async () => {
              return defer({
                packageOrderSync: getOrderSync(),
              });
            },
          },
        ],
      },
      {
        path: "fee",
        children: [
          {
            index: true,
            element: <FeeListPage />,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const limit = query.get("l") || 30;
              const page = query.get("p") || 1;
              const search = query.get("s") || "";
              const type = query.get("e") || "tailor";

              return defer({
                packageFees: getFees(page, limit, type, search),
              });
            },
          },
        ],
      },
      {
        path: "customer",
        children: [
          {
            index: true,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const limit = query.get("l") || 30;
              const page = query.get("p") || 1;
              const search = query.get("s") || "";
              const dateFrom = query.get("df") || "";
              const dateTo = query.get("dt") || "";
              const defaultHaveBought = query.get('b')
              const defaultNumOfOrder = query.get('o')
              // const defaultOutstanding = query.get('ot')
              // const defaultHaveSpent = query.get('sp')

              const numOfItemLow = defaultHaveBought ? defaultHaveBought?.split('-')[0] : ''
              const numOfItemHigh = defaultHaveBought ? defaultHaveBought?.split('-')[1] : ''
              const numOfOrderLow = defaultNumOfOrder ? defaultNumOfOrder?.split('-')[0] : ''
              const numOfOrderHigh = defaultNumOfOrder ? defaultNumOfOrder?.split('-')[1] : ''

              return defer({
                packageCustomers: getCustomers(page, limit, search, dateFrom, dateTo, numOfItemLow, numOfItemHigh, numOfOrderLow, numOfOrderHigh),
              });
            },
            element: <BuyerListPage />,
          },
        ],
      },
      {
        path: "log",
        children: [
          {
            index: true,
            element: <LogListPage />,
          },
        ],
      },

      {
        path: "setup",
        children: [
          {
            index: true,
            element: <SetupPage />,
          },
        ],
      },
      {
        path: "checkpoint",
        children: [
          {
            index: true,
            element: <CheckpointListPage />,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const limit = query.get("l") || 10;
              const page = query.get("p") || 1;
              const search = query.get("sc") || "";

              return defer({
                packageCheckpointList: getCheckpoints(page, limit, search),
                packageWorkerList: getWorkerList(),
              });
            },
          },
          {
            path: "overview/:id",
            children: [
              {
                index: true,
                element: <CheckpointOverviewPage />,
                loader: async (route) => {
                  const { id } = route?.params;
                  const url = new URL(route?.request?.url);
                  const query = url.searchParams;
                  const limit = query.get("l") || 30;
                  const page = query.get("p") || 1;
                  const search = query.get("s") || "";
                  const type = query.get("t") || "";

                  return defer({
                    packageCheckpointOverview: getCheckpoint(
                      id,
                      page,
                      limit,
                      type,
                      search
                    ),
                    packageDeliverSync: getDeliverSync(),
                  });
                },
              },
              {
                path: "stock/:code",
                element: <StockOverviewPage />,
                loader: async ({ params }) => {
                  const { code } = params;

                  return defer({
                    packageStockOverview: getStock(code),
                  });
                },
              },
            ],
          },
        ],
      },
      {
        path: "workshop",
        children: [
          {
            index: true,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const limit = query.get("l") || 30;
              const page = query.get("p") || 1;
              const search = query.get("s") || "";

              return defer({
                packageWorkshops: getWorkshops(page, limit, search),
              });
            },
            element: <WorkshopListPage />,
          },
        ],
      },
      {
        path: "workshop-request",
        children: [
          {
            index: true,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const limit = query.get("l") || 30;
              const page = query.get("p") || 1;
              const search = query.get("s") || "";

              return defer({
                packageWorkshops: getWorkshopsReqeust(page, limit, search),
              });
            },
            element: <WorkshopRequestListPage />,
          },
        ],
      },
      {
        path: "employee",
        children: [
          {
            index: true,
            element: <EmployeeListPage />,
            loader: async (route) => {
              const url = new URL(route?.request?.url);
              const query = url.searchParams;
              const limit = query.get("l") || 30;
              const page = query.get("p") || 1;
              const search = query.get("s") || "";
              const type = query.get("t") || "tailor";
              const tableType = query.get("tt")
              const joinDateStart = query.get('df') || ''
              const joinDateEnd = query.get('dt') || ''
              const role = query.get('r') || ''
              const checkpointCode = query.get('pl') || ''

              return defer({
                packageEmployeeList: tableType === 'fee' ? getEmployees(page, limit, type, search, joinDateStart, joinDateEnd) : getEmployees(page, limit, '', search, joinDateStart, joinDateEnd, role, checkpointCode),
                checkpointList: getCheckpointList(),
                // packageFees: getFees(page, limit, type, search),
              });
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    loader: () => {
      if (localStorage.getItem("accessToken")) {
        throw redirect("/");
      }
      return null;
    },
    element: <LoginPage />,
  },
  {
    path: "/invoice-view/:id",
    element: <Invoice />,
    loader: async ({ params }) => {
      const { id } = params;

      return defer({
        packageInvoiceDetails: getInvoice(id),
      });
    },
  },
  {
    path: "/customer-file/:id",
    element: <CustomerFile />,
    loader: async ({ params }) => {
      const { id } = params;

      return defer({
        packageInvoiceDetails: getInvoice(id),
      });
    },
  },
  {
    path: "/cutting-file/:id",
    element: <Invoice />,
    loader: async ({ params }) => {
      const { id } = params;

      return defer({
        packageInvoiceDetails: getOrder(id, 'TRANSACTION'),
      });
    },
  },
  {
    path: 'delivery-letter/:id',
    element: <DeliveryLetter />,
    loader: async ({ params, request }) => {
      const url = new URL(request?.url);
      const query = url.searchParams;
      const { id } = params;
      const type = query.get("type") || 'CUSTOMER';
      const idQuery = query.get("id");


      return defer({
        packageDeliveryDetails: getDeliveryById(atob(idQuery), type),
      });
    },
  },
  {
    path: '*',
    element: <AsyncErrorBoundary isUnregisteredRole={window.location.pathname?.includes('/unregistered-role')} isNotFound height="100vh" />
  }
]);

export default router;
