import Avatar from '@Atom/Avatar'
import Styles from './style.module.scss'
import Input from '@Atom/Input'
import Button from '@Atom/Button';

export default function ItemsQuantityList({
  title,
  data = [],
  setData = () => { }
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.content}>
        {
          data?.map((each, i) => (
            <div key={i} className={Styles.each}>
              <div className={Styles.left}>
                <span>{i + 1}</span>
                <Avatar
                  src={each?.img}
                  size='24px'
                  borderRadius='6px'
                />
                <span>{each?.name}</span>
              </div>
              <div className={Styles.right}>
                <Input
                  type='number'
                  customClassName={Styles.inputNumber}
                  value={each?.qty}
                  max={each?.maxQty}
                  min={1}
                  setValue={(newVal) => {
                    const newData = data.map((data) => {
                      if (data.id === each?.id && +newVal <= each?.maxQty) {
                        return { ...data, qty: newVal ? +newVal : '' };
                      }
                      return data;
                    });

                    setData(newData);
                  }}
                />
                <Button
                  type='primary'
                  onClick={() => {
                    const newData = data.map((data) => {
                      if (data.id === each?.id) {
                        return { ...data, qty: data?.maxQty };
                      }
                      return data;
                    });

                    setData(newData);
                  }}
                  disabled={each?.qty === each?.maxQty}
                  disableActive
                  customClassName={Styles.maxButton}
                  size='sm'
                  buttonText={each?.qty === each?.maxQty ? 'Maxed Out' : 'Maximize'}
                />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}