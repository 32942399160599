import { sizingTypes, suitSizes, shirtSizes, trouserSizes } from "@Assets/data/sizingTypes";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import Label from "@Atom/Label";
import ModalWrapper from "@Atom/ModalWrapper";
import QuestioningEmptyHandler from "@Atom/QuestioningEmptyHandler";
import Radio from "@Atom/Radio";
import Searchbar from "@Atom/Searchbar";
import TextArea from "@Atom/TextArea";
import { fileBaseUrl } from "@Config/api";
import { makeRandomString } from "@Helpers/makeRandomString";
import { priceFormat } from "@Helpers/priceFormat";
import { splitCamelCase } from "@Helpers/splitCamelCase";
import useQuery from "@Hooks/useQuery";
import CartItemDetailsCard from "@Molecule/CartItemDetailsCard";
import InputField from "@Molecule/InputField";
import MeasurementCard from "@Molecule/MeasurementCard";
import MeasurementField from "@Molecule/MeasurementField";
import PriceDetailsCard from "@Molecule/PriceDetailsCard";
import RadioField from "@Molecule/RadioField";
import SampleCard from "@Molecule/SampleCard";
import StackedItemInfo from "@Molecule/StackedItemInfo";
import AddToCartModal from "@Molecule/_modals/AddToCartModal";
import WideErrorModal from "@Molecule/_modals/WideErrorModal";
import { addCustomer, deletePattern, editSizeMeasurement, getCustomerByCode, updateSizeMeasurement } from "@Services/customer";
import { getProductList } from "@Services/product";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import { useDebounce } from "@Hooks/useDebounce";

export default function StartMeasureLayout({
  defaultData,
  customerList,
  isLoading,
}) {
  const [mode, setMode] = useState("measure");
  const [customerCode, setCustomerCode] = useState('')
  const [error, setError] = useState(null)



  const [showAddToCart, setShowAddToCart] = useState(false);
  const navigate = useNavigate();
  const [editCustomNameMode, setEditCustomNameMode] = useState(false);

  const [data, setData] = useState({
    ...defaultData,
    id: defaultData?._id,
    name: defaultData?.name,
    productCode: defaultData?.productCode,
    img: defaultData?.images?.length
      ? fileBaseUrl + defaultData?.images[0]?.url
      : null,
    price: defaultData?.price,
    isMeasured: false,
    isCustomSizing: true,
    showMeasurementStatus: true,
    sleevesType: "long",
    size: "-",
    measurements: defaultData?.size
      ? Object?.entries(defaultData?.size)?.map((m) => {
        return { name: m[0], value: m[1], title: splitCamelCase(m[0]) };
      })
      : [],
  });
  const [notes, setNotes] = useState("");

  // eslint-disable-next-line no-unused-vars
  const handleChangeData = useCallback(
    (name, newVal) => {
      setData((prev) => {
        return {
          ...data,
          [name]: newVal,
        };
      });
    },
    [data]
  );



  // const fabricOptions = useMemo(() => {
  //   // return []
  //   return [
  //     {
  //       id: 1,
  //       name: "Baby Amigo",
  //       picture:
  //         "https://www.dalstonmillfabrics.co.uk/pub/media/catalog/product/cache/1313879062af4fe4b91d2ab2cd3e697f/_/a/_a_l_allover-leopard_2.jpg",
  //     },
  //     {
  //       id: 1,
  //       name: "Baby Amigo",
  //       picture:
  //         "https://www.dalstonmillfabrics.co.uk/pub/media/catalog/product/cache/1313879062af4fe4b91d2ab2cd3e697f/_/a/_a_l_allover-leopard_2.jpg",
  //     },
  //     {
  //       id: 1,
  //       name: "Baby Amigo",
  //       picture:
  //         "https://www.dalstonmillfabrics.co.uk/pub/media/catalog/product/cache/1313879062af4fe4b91d2ab2cd3e697f/_/a/_a_l_allover-leopard_2.jpg",
  //     },
  //   ];
  // }, []);

  const paymentOptions = useMemo(() => {
    return [
      {
        name: "Full Payment",
        value: "full",
      },
      {
        name: "Installments",
        value: "installments",
      },
    ];
  }, []);

  const paymentMethodOptions = useMemo(() => {
    return [
      {
        name: "Cash",
        value: "cash",
      },
      {
        name: "Debit Card",
        value: "debit",
      },
      {
        name: "E-Wallet",
        value: "eWallet",
      },
    ];
  }, []);

  const [measurementFormTemplate, setMeasurementFormTemplate] = useState({
    size: sizingTypes?.map(obj => {
      return {
        name: obj?.name,
        title: obj?.title,
        value: ''
      }
    }) || [],
    name: `Size ${makeRandomString(5)}`,
    photos: [
      {
        id: 1,
        name: "",
        src: null,
        angle: "FRONT",
        remark: "",
      },
      {
        id: 2,
        name: "",
        src: null,
        angle: "SIDE",
        remark: "",
      },
      {
        id: 3,
        name: "",
        src: null,
        angle: "BACK",
        remark: "",
      },
      {
        id: 4,
        name: "",
        src: null,
        angle: "FRONT-1",
        remark: "",
      },
      {
        id: 5,
        name: "",
        src: null,
        angle: "SIDE-1",
        remark: "",
      },
      {
        id: 6,
        name: "",
        src: null,
        angle: "BACK-1",
        remark: "",
      },
    ],
    remark: "",
  });

  const [fabrics, setFabrics] = useState([]);
  const [selectedFabrics, setSelectedFabrics] = useState(null);
  const query = useQuery();

  const category = query.get("c") || "SUIT";

  useEffect(() => {
    if (data.name) {
      setFabrics((prev) => [
        {
          type: data.type,
          name: data?.name,
          productCode: data?.productCode || "",
          qty: 1,
          price: data?.price,
          images: data?.images,
          fabric: data?.fabric,
          _id: data._id,
        },
      ]);
    }
  }, [data]);


  const [addFabricMode, setAddFabricMode] = useState(false);
  const [typeFilter, setTypeFilter] = useState("COLLECTION");
  const [searchFilter, setSearchFilter] = useState("");
  const [productList, setProductList] = useState([]);
  const [isLoadingProductList, setIsLoadingProductList] = useState(false)

  const debouncedSearchTerm = useDebounce(searchFilter, 500)

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoadingProductList(true)
        const { data } = await getProductList(typeFilter, debouncedSearchTerm);
        // const { data } = await getProducts(typeFilter, 1, 10, '', '', '', '', searchFilter);
        setProductList(data?.map(obj => {
          return {
            ...obj,
            type: typeFilter?.toUpperCase()
          }
        }));
        setIsLoadingProductList(false)
      } catch (err) {
        setIsLoadingProductList(false)
        setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
        console.log(err, 'error on fetching product list')
      }

    };
    if (debouncedSearchTerm) {
      getData();
    }
  }, [typeFilter, searchFilter, debouncedSearchTerm]);

  const [form, setForm] = useState({
    customerName: "",
    customerAddress: "",
    customerWhatsApp: "",
    customerEmail: "",
  });

  const [sizeMeasurement, setSizeMeasurement] = useState([]);
  const [selectedMeasurementCode, setSelectedMeasurementCode] = useState("");
  const [selectedMeasurementId, setSelectedMeasurementId] = useState("");
  const [isCustomMeasurement, setIsCustomMeasurement] = useState(false);



  const fetchSize = useCallback(async () => {
    if (customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === form?.customerWhatsApp?.toUpperCase())?.code || '') {
      const code = customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === form?.customerWhatsApp?.toUpperCase())?.code || ''
      // const code = customerList.find(
      //   (el) => el.name === form.customerName
      // ).code;
      let fetch = await getCustomerByCode(code);
      const res = fetch?.data;

      let sizes = sizingTypes;
      if (category === "SHIRT") {
        sizes = shirtSizes
      } else if (category === "SUIT") {
        sizes = suitSizes
      } else if (category === "TROUSER") {
        sizes = trouserSizes
      }

      for (let i = 0; i < res.length; i++) {
        const arr = [];
        const size = res[i].measurement;
        const keys = Object.keys(size);
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j];
          const filter = sizes.map((el) => el.name);
          for (let k = 0; k < filter.length; k++) {
            if (filter[k] === key) {
              arr.push({
                name: filter[k],
                title: sizes[k].title,
                value: size[key],
              });
            }
          }
        }
        res[i].size = arr;
      }
      setSelectedMeasurementCode("#" + res[0]?.code);
      setSelectedMeasurementId(0);
      setSizeMeasurement(res?.map(r => {
        return {
          ...r,
          photos: ['FRONT', 'SIDE', 'BACK', 'FRONT-1', 'SIDE-1', 'BACK-1']
            // eslint-disable-next-line array-callback-return
            ?.map((p) => {
              const found = r?.photos?.find(obj => obj?.angle === p)
              // if(found) {
              return {
                angle: p,
                remark: found?.remark || p,
                uri: found?.uri || '',
                _id: found?._id || ''
              }
              // }
            })

        }
      }));
      
      setMeasurementFormTemplate({
        size: sizes?.map(obj => {
          return {
            name: obj?.name,
            title: obj?.title,
            value: ''
          }
        }) || [],
        name: `Size ${makeRandomString(5)}`,
        photos: [
          {
            id: 1,
            name: "",
            src: null,
            angle: "FRONT",
            remark: "",
          },
          {
            id: 2,
            name: "",
            src: null,
            angle: "SIDE",
            remark: "",
          },
          {
            id: 3,
            name: "",
            src: null,
            angle: "BACK",
            remark: "",
          },
          {
            id: 4,
            name: "",
            src: null,
            angle: "FRONT-1",
            remark: "",
          },
          {
            id: 5,
            name: "",
            src: null,
            angle: "SIDE-1",
            remark: "",
          },
          {
            id: 6,
            name: "",
            src: null,
            angle: "BACK-1",
            remark: "",
          },
        ],
        remark: "",
      });
    } else {
      setSizeMeasurement([]);
    }
  }, [customerList, form?.customerWhatsApp]);

  useEffect(() => {
    fetchSize();
  }, [form.customerName, customerList, fetchSize]);

  const [newCustomer, setNewCustomer] = useState(false);

  useEffect(() => {
    if (newCustomer) {
      setIsCustomMeasurement(true)
    }
  }, [newCustomer])

  const addToCart = useCallback(() => {
    setCustomerCode(customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === form?.customerWhatsApp?.toUpperCase())?.code || '')
    const selectedSize =
      sizeMeasurement.length > 0 && !isCustomMeasurement
        ? sizeMeasurement[selectedMeasurementId]
        : measurementFormTemplate;
    let dataFromStorage = JSON.parse(localStorage.getItem("cart")) || [];
    const items = {
      id: new Date().getTime(),
      isReadyToWear: false,
      isAlter: false,
      isFabricOnly: false,
      label: category,
      fabrics: fabrics.map((el) => ({
        type: el.type,
        name: el.name,
        code: el.productCode || "",
        qty: 1,
        price: el?.price,
        imgUrl: el?.images[0]?.url,
        fabric: el.fabric,
        _id: el._id,
      })),
      // [
      // {
      //   type: "",
      //   name: data.name,
      //   code: data.productCode || "",
      //   qty: "1x",
      //   price: 3000000,
      //   imgUrl: data.img,
      //   fabric: data.fabric,
      // },
      // ],
      notes: notes,
    };

    const tempCustomerCode = customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === (newCustomer ? '62' + form?.customerWhatsApp?.toUpperCase() : form?.customerWhatsApp?.toUpperCase()))?.code || ''


    if (dataFromStorage.some((el) => el.customerCode === tempCustomerCode)) {
      dataFromStorage = dataFromStorage.map((el) =>
        el.customerCode === tempCustomerCode
          ? {
            ...el,
            sizes:
              el.sizes.some((el1) => el1.sizeName === selectedSize?.name) &&
                selectedSize?.name !== "Default"
                ? el.sizes.map((el1) =>
                  el1.sizeName === selectedSize.name
                    ? {
                      ...el1,
                      items: [...el1.items, items],
                      photos: selectedSize.photos || [],
                      measurements: selectedSize.size,
                      code: selectedSize?.code || "",
                    }
                    : el1
                )
                : [
                  ...el.sizes,
                  {
                    measurements: selectedSize.size,
                    code: selectedSize?.code || "",
                    sizeName: selectedSize.name,
                    items: [items],
                    photos: selectedSize.photos || [],
                    id: selectedSize?.code || "",
                  },
                ],
          }
          : el
      );
    } else {
      dataFromStorage.push({
        ...form,
        customerWhatsApp: `${newCustomer ? '62' : ''}` + form?.customerWhatsApp,
        customerCode: customerList?.find(obj => obj?.name?.toUpperCase() === form?.customerName?.toUpperCase())?.code || '',
        id: new Date().getTime(),
        sizes: [
          {
            measurements: selectedSize.size,
            sizeName: selectedSize.name,
            items: [items],
            photos: selectedSize.photos || [],
            id: new Date().getTime(),
            code: selectedSize?.code || "",
          },
        ],
      });
    }
    return dataFromStorage;
  }, [category, customerList, fabrics, form, isCustomMeasurement, measurementFormTemplate, newCustomer, notes, selectedMeasurementId, sizeMeasurement]);

  const newCustomerForm = useMemo(() => {

    const formData = new FormData()
    formData.append('name', form?.customerName)
    formData.append('phoneNumber', '62' + form?.customerWhatsApp)
    formData.append('email', form?.customerEmail)
    formData.append('address', form?.customerAddress)
    if (newCustomer) {
      for (let i = 0; i < measurementFormTemplate?.size?.length; i++) {
        formData.append(`size[${measurementFormTemplate?.size[i]?.name}]`, measurementFormTemplate?.size[i]?.value)
      }
      formData.append('size[name]', measurementFormTemplate?.name)

      const photosToMap = measurementFormTemplate?.photos?.filter(obj => obj?.src)

      for (let i = 0; i < photosToMap.length; i++) {
        formData.append(`photos[${i}][angle]`, photosToMap[i]?.angle)
        formData.append(`photos[${i}][remark]`, photosToMap[i]?.remark)
        formData.append('images', photosToMap[i]?.src || '')
      }
    }

    return formData
  }, [form?.customerAddress, form?.customerEmail, form?.customerName, form?.customerWhatsApp, measurementFormTemplate?.name, measurementFormTemplate?.photos, measurementFormTemplate?.size, newCustomer])

  const [measurementToEdit, setMeasurementToEdit] = useState(null)


  const disableAddToCart = useMemo(() => {
    if (newCustomer) {
      if (!form?.customerName
        || !form?.customerAddress
        || !form?.customerWhatsApp
        || !measurementFormTemplate?.photos[0]?.src
        || !measurementFormTemplate?.photos[1]?.src
        || !measurementFormTemplate?.photos[2]?.src
      ) {
        return true
      }
    } else {
      return isCustomMeasurement
    }
  }, [form?.customerAddress, form?.customerName, form?.customerWhatsApp, isCustomMeasurement, measurementFormTemplate?.photos, newCustomer])

  const handleAddToCart = useCallback(async () => {
    if (newCustomer) {
      try {
        const response = await addCustomer(newCustomerForm)
        setCustomerCode(response?.data?.code)
        const addToCartObj = addToCart()?.map(obj => {
          if (obj?.customerWhatsApp === '62' + form?.customerWhatsApp) {
            return {
              ...obj,
              customerCode: response?.data?.code,
              sizes: [
                {
                  ...obj?.sizes[0],
                  code: response?.data?.size?.code,
                  // sizeName: response?.data?.size?.name,
                  photos: response?.data?.size?.photos
                }
              ]
            }
          } else {
            return {
              ...obj
            }
          }
        })
        localStorage?.setItem("cart", JSON?.stringify(addToCartObj));
        setShowAddToCart(true);

      } catch (err) {
        setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
        console.log(err?.response?.data?.error || err, 'error on creating new customer')
      }
    } else {
      localStorage?.setItem("cart", JSON?.stringify(addToCart()));
      setShowAddToCart(true);

    }
  }, [addToCart, form?.customerWhatsApp, newCustomer, newCustomerForm])
  const handleSaveAddMeasurement = useCallback(async () => {
    try {
      await updateSizeMeasurement(
        form?.customerCode,
        measurementFormTemplate
      );
      setIsCustomMeasurement(false);
      fetchSize();

    } catch (err) {
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
    }

  }, [fetchSize, form.customerCode, measurementFormTemplate]);

  const [customerSearch, setCustomerSearch] = useState('')

  useEffect(() => {
    if (!sizeMeasurement?.length) {
      setIsCustomMeasurement(true)
    } else {
      setIsCustomMeasurement(false)
    }
  }, [sizeMeasurement?.length, customerSearch])

  const measurementTemplate = useMemo(() => {
    return [
      {
        title: "Customer",
        component: !newCustomer ? (
          <div className={Styles.customer}>
            <InputField
              placeholder={"Select Existing Customer"}
              isDropdown
              dropdownOptions={customerList?.map((obj) => `${obj?.name} - ${obj?.phoneNumber}`)}
              // value={form?.customerName}
              value={customerSearch}
              setValue={(newVal) => {
                setCustomerSearch(newVal)
                const findCustomer = newVal?.includes(' - ')
                  ? customerList.find(
                    (el) => el?.phoneNumber === newVal?.split(' - ')[1]
                  )
                  : null

                return setForm((f) => {
                  return {
                    ...f,
                    customerName: newVal?.includes(' - ') ? newVal?.split(' - ')[0] : newVal,
                    customerAddress: findCustomer?.address,
                    customerWhatsApp: findCustomer?.phoneNumber,
                    customerEmail: findCustomer?.email,
                    customerCode: findCustomer?.code,
                  };
                });
              }}
            />
            <div className={Styles.orSeparator}>
              <div />
              <span>OR</span>
              <div />
            </div>
            <Button
              variant="secondary"
              withIcon
              iconName={"plus"}
              size="lg"
              buttonText="Add New Customer"
              onClick={() => {
                setNewCustomer(true);

                let sizes = [];
                if (category === "SHIRT") {
                  sizes = shirtSizes
                } else if (category === "SUIT") {
                  sizes = suitSizes
                } else if (category === "TROUSER") {
                  sizes = trouserSizes
                }

                setMeasurementFormTemplate((prev) => ({
                  ...prev,
                  size: sizes,
                }));

                setSizeMeasurement([]);
                setForm({
                  customerName: "",
                  customerAddress: "",
                  customerWhatsApp: "",
                  customerEmail: "",
                });
              }}
            />
          </div>
        ) : (
          <div className={Styles.new}>
            <Button
              variant="tertiary"
              buttonText="Cancel"
              withIcon
              iconName={"x-mark"}
              customClassName={Styles.float}
              disableActive
              disableHover
              onClick={() => setNewCustomer(false)}
            />
            <InputField
              title={"Customer Name"}
              placeholder={"Customer name"}
              value={form?.customerName}
              setValue={(newVal) =>
                setForm((f) => {
                  return {
                    ...f,
                    customerName: newVal,
                  };
                })
              }
              required
            />
            <InputField
              title={"Customer Address"}
              placeholder={"Customer address"}
              value={form?.customerAddress}
              setValue={(newVal) =>
                setForm((f) => {
                  return {
                    ...f,
                    customerAddress: newVal,
                  };
                })
              }
              required
            />
            <InputField
              title={"Customer Whatsapp"}
              type="tel"
              placeholder={"8xxxx"}
              value={form?.customerWhatsApp}
              setValue={(newVal) =>
                setForm((f) => {
                  return {
                    ...f,
                    customerWhatsApp: newVal,
                  };
                })
              }
              required
            />
            <InputField
              title={"Customer Email"}
              placeholder={"Customer email"}
              value={form?.customerEmail}
              setValue={(newVal) =>
                setForm((f) => {
                  return {
                    ...f,
                    customerEmail: newVal,
                  };
                })
              }
            />
          </div>
        ),
      },
      {
        title: "Product",
        component: (
          <div className={Styles.fabricCard}>
            <div className={Styles.type}>
              <Label
                desc={
                  <Icon
                    icon={
                      category.toLowerCase() === "trouser"
                        ? "pants"
                        : category.toLowerCase()
                    }
                    size={40}
                  />
                }
                padding="0px"
                customClassName={Styles.icon}
              />
              <Label desc={category} padding="0px 8px" />
            </div>
            <div className={Styles.fabrics}>
              <div className={Styles.listed}>
                {fabrics.map((el, idx) => (
                  <div className={Styles.fabric} key={idx}>
                    <StackedItemInfo
                      picture={el?.images[0]?.url ? fileBaseUrl + el?.images[0]?.url : ''}
                      title={el.type + "-" + el?.name}
                      label={el.productCode}
                    />
                    <div className={Styles.length}>
                      <span>1x</span>
                      <span>Rp{priceFormat(el?.price)}</span>
                    </div>
                  </div>
                ))}
                {/* other fabrics go here... */}
              </div>
              <div className={Styles.divider} />
              <div className={Styles.add}>
                <Button
                  variant="tertiary"
                  buttonText="Add Other Fabric"
                  disableActive
                  disableHover
                  size="lg"
                  fixedPadding={"0"}
                  withIcon
                  iconName="plus"
                  customIconSize={20}
                  onClick={() => setAddFabricMode(true)}
                />
                {addFabricMode && (
                  <div className={Styles.addFabric}>
                    <div className={Styles.controller}>
                      <div className={Styles.leftSection}>
                        <span>Select Fabric</span>
                        <Input
                          value={typeFilter}
                          setValue={setTypeFilter}
                          placeholder={"Select type"}
                          isDropdown
                          dropdownOptions={["COLLECTION", "REGULAR"]}
                          customClassName={Styles.typeDD}
                          customAdornmentClassName={Styles.chevron}
                          customDropdownPositioning={{ top: "36px" }}
                        />
                        <Searchbar
                          placeholder="Search fabric"
                          customClassName={Styles.fabricSearch}
                          adornmentPositioning={{ left: "8px", top: "2px" }}
                          iconSize="20px"
                          value={searchFilter}
                          setValue={setSearchFilter}
                        />
                      </div>
                      <Button
                        variant="tertiary"
                        size="md"
                        fixedPadding={"0px"}
                        buttonText="Cancel"
                        onClick={() => setAddFabricMode(false)}
                        disableActive
                        disableHover
                      />
                    </div>
                    <div
                      className={`${Styles.flCard} ${!searchFilter || !productList?.length || isLoadingProductList
                        ? Styles.centered
                        : ""
                        }`}
                    >
                      {!searchFilter
                        ? (
                          <QuestioningEmptyHandler text="Input fabric name on search bar" />
                        ) : (
                          isLoadingProductList
                            ?
                            <QuestioningEmptyHandler text="Loading data..." />
                            :
                            !productList?.length ? (
                              <QuestioningEmptyHandler text="No data available" />
                            )
                              : (
                                productList
                                  ?.filter(obj => obj?.productCode?.toLowerCase()?.includes(searchFilter?.toLowerCase()))
                                  ?.map((each, i) => (
                                    <div
                                      key={i}
                                      className={Styles.each}
                                      style={{
                                        // backgroundImage: `url(${fileBaseUrl + each?.productImages[0]?.url})`,
                                        // backgroundImage: `url(https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500)`,
                                        backgroundColor: 'grey',
                                        border:
                                          selectedFabrics?._id === each._id
                                            ? "3px solid #231FFF"
                                            : "none",
                                        cursor: "pointer",
                                        position: 'relative',
                                        overflow: 'clip'
                                      }}
                                      onClick={() => setSelectedFabrics(each)}
                                    >
                                      {each?.images?.length
                                        &&
                                        <img style={{
                                          position: "absolute",
                                          zIndex: 0
                                        }} src={fileBaseUrl + each?.images[0]?.url} alt="" />
                                      }
                                      <span style={{
                                        position: "absolute",
                                        zIndex: 1,
                                        backgroundColor: '#00000080'
                                      }}>{each?.productCode}</span>
                                    </div>
                                  ))
                              )
                        )
                      }
                    </div>
                    <Button
                      customClassName={Styles.fullWidth}
                      variant="primary"
                      size="lg"
                      buttonText="Add"
                      onClick={() => {
                        setFabrics((prev) => [...prev, selectedFabrics]);
                        setSelectedFabrics(null);
                        setAddFabricMode(false);
                        setSearchFilter("");
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Size Measurements",
        component: !isCustomMeasurement ? (
          <div className={Styles.sizes}>
            {sizeMeasurement.length > 0 ? (
              sizeMeasurement.map((el, idx) => (
                <div key={idx}>
                  <MeasurementCard
                    index={idx}
                    // key={idx}
                    // withRadioButton={false}
                    onSelect={(val) => {
                      setSelectedMeasurementCode(val);
                      setSelectedMeasurementId(idx);
                    }}
                    isChecked={"#" + el.code === selectedMeasurementCode}
                    name={el.name}
                    code={"#" + el.code}
                    measurements={el.size}
                    photos={el.photos.filter(obj => obj?.uri)?.map((el) => el?.uri ? fileBaseUrl + el?.uri : el)}
                    isEditMode={el?.code === measurementToEdit?.code}
                    sizes={sizeMeasurement}
                    setSizes={setSizeMeasurement}
                    defaultMeasurements={measurementFormTemplate?.size}
                    handleEdit={() => setMeasurementToEdit(el)}
                    handleDelete={async () => {
                      try {
                        await deletePattern(el?.code)
                        await fetchSize()
                      } catch (err) {
                        setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
                        console.log(err?.response?.data?.error || err, 'error on creating new customer')
                      }
                    }}
                    withCancelEditButton
                    onCancelEdit={() => setMeasurementToEdit(null)}
                    hasToClickSave
                    withSaveButton
                    onSave={async () => {
                      try {
                        await editSizeMeasurement(
                          customerList?.find(obj => obj?.name?.toUpperCase() === form?.customerName?.toUpperCase())?.code || '',
                          el,
                          el?.code
                        )
                        await fetchSize()
                        setMeasurementToEdit(null)
                      } catch (err) {
                        setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
                      }
                    }}
                  />
                </div>
              ))
            ) : (
              <MeasurementCard
                // key={idx}
                // withRadioButton={false}
                onSelect={(val) => setSelectedMeasurementCode(val)}
                isChecked={""}
                name={"Default"}
                code={""}
                measurements={measurementFormTemplate?.size?.map((el) => ({
                  ...el,
                  value: el.value ?? '0' + 'cm',
                }))}
                // photos={}
                withRadioButton={false}
                handleEdit={() => setIsCustomMeasurement(true)}
              />
            )}
            <div style={{ display: "grid", marginTop: "18px" }}>
              <Button
                variant="secondary"
                withIcon
                iconName={"plus"}
                size="lg"
                buttonText="Add Other Measurement"
                onClick={() => {
                  setIsCustomMeasurement(true);
                  // setSelectedMeasurementCode("");
                  // setSelectedMeasurementId("");
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <p onClick={() => setIsCustomMeasurement(false)} style={{ fontSize: '16px', color: 'red', cursor: 'pointer' }}>Cancel</p>
            <div style={{ marginTop: '24px' }} className={Styles.customSize}>
              <div>
                <div className={Styles.customName}>
                  {editCustomNameMode ? (
                    <input
                      placeholder="Size Name"
                      value={measurementFormTemplate.name}
                      onChange={(e) =>
                        setMeasurementFormTemplate((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  ) : (
                    <p>{measurementFormTemplate?.name}</p>
                  )}
                  {!editCustomNameMode && (
                    <button
                      onClick={() => {
                        setEditCustomNameMode(true);
                        setMeasurementFormTemplate((prev) => ({
                          ...prev,
                          name: "",
                        }));
                      }}
                    >
                      <Icon icon={"pencil"} size={24} />
                    </button>
                  )}
                </div>
                {/* {!newCustomer
                  &&
                  <Button
                    variant="tertiary"
                    buttonText="Back to default size"
                    withIcon
                    iconName={"x-mark"}
                    disableActive
                    disableHover
                    onClick={() => setIsCustomMeasurement(false)}
                  />
                } */}

              </div>
              <div className={Styles.field}>
                {measurementFormTemplate?.size?.map((each, i) => (
                  <MeasurementField
                    key={i}
                    title={each?.title}
                    value={each?.value}
                    setValue={(newVal) => {
                      const newTemplate = measurementFormTemplate?.size?.map(
                        (obj) => {
                          if (obj?.name === each?.name) {
                            return {
                              ...obj,
                              value: newVal,
                            };
                          }
                          return obj;
                        }
                      );
                      setMeasurementFormTemplate((prev) => ({
                        ...prev,
                        size: newTemplate,
                      }));
                    }}
                    placeholder={each?.placeholder}
                    denom={"cm"}
                    extraTitleLabel={
                      each?.name?.toLowerCase()?.includes("sleeves") ? (
                        <div className={Styles.radios}>
                          <div
                            onClick={() => {
                              setData((f) => {
                                return {
                                  ...f,
                                  sleevesType: "long",
                                };
                              });
                            }}
                          >
                            <Radio isChecked={data?.sleevesType === "long"} />
                            <span>Long</span>
                          </div>
                          <div
                            onClick={() => {
                              setData((f) => {
                                return {
                                  ...f,
                                  sleevesType: "short",
                                };
                              });
                            }}
                          >
                            <Radio isChecked={data?.sleevesType === "short"} />
                            <span>Short</span>
                          </div>
                        </div>
                      ) : undefined
                    }
                  />
                ))}
              </div>
              <p className={Styles.titleSize}>Photos</p>
              <div className={Styles.photos}>
                {measurementFormTemplate.photos?.map((eachP, iP) => (
                  <SampleCard
                    key={iP}
                    // isFullWidth
                    size="100%"
                    isOverview
                    src={eachP?.src}
                    setSrc={(newSrc) => {
                      const newPhotos = [...measurementFormTemplate.photos];
                      newPhotos[iP].src = newSrc || "";
                      measurementFormTemplate.photos = newPhotos;
                      setMeasurementFormTemplate((prev) => ({
                        ...prev,
                        photos: newPhotos,
                      }));
                    }}
                  />
                ))}
              </div>
              {!newCustomer && (
                <div style={{ display: "grid", marginTop: "18px" }}>
                  <Button
                    variant="secondary"
                    withIcon
                    iconName={"saved"}
                    size="lg"
                    buttonText="Save"
                    onClick={() => {
                      handleSaveAddMeasurement();
                      // setSelectedMeasurementCode("");
                      // setSelectedMeasurementId("");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "Notes",
        component: (
          <TextArea placeholder="Notes" value={notes} setValue={setNotes} />
        ),
      },
    ];
  }, [newCustomer, customerList, customerSearch, form?.customerName, form?.customerAddress, form?.customerWhatsApp, form?.customerEmail, category, fabrics, addFabricMode, typeFilter, searchFilter, productList, isLoadingProductList, isCustomMeasurement, sizeMeasurement, data.measurements, data?.sleevesType, editCustomNameMode, measurementFormTemplate, notes, selectedFabrics, selectedMeasurementCode, measurementToEdit?.code, fetchSize, handleSaveAddMeasurement]);

  const [summaryForm, setSummaryForm] = useState({
    customerName: "",
    customerAddress: "",
    customerWhatsApp: "",
    customerEmail: "",
    customerFrontPhoto: null,
    customerBackPhoto: null,
    customerSidePhoto: null,
    paymentType: "full",
    paymentMethod: "cash",
  });

  const handleChangeSummaryForm = useCallback((newVal, name) => {
    setSummaryForm((f) => ({ ...f, [name]: newVal }));
  }, []);

  const invoiceTemplate = useMemo(() => {
    return [
      {
        title: "",
        component: (
          <CartItemDetailsCard
            // checkedItems={checkedItems}
            // setCheckedItems={setCheckedItems}
            item={data}
            // setSelectedItem={setSelectedItem}
            setMode={setMode}
            denom="cm"
            // onDeleteItem={() => {
            //   const newList = [...list]
            //   const index = newList?.indexOf(item)
            //   if (index !== -1) {
            //     newList.splice(index, 1)
            //     setList(newList)
            //   }
            // }}
            noActions
            noChekcbox
          />
        ),
      },
      {
        title: "Customer Details",
        component: (
          <div className={Styles.customerInfo}>
            <InputField
              title={"Customer Name"}
              placeholder={"Customer name"}
              value={summaryForm?.customerName}
              setValue={(newVal) =>
                handleChangeSummaryForm(newVal, "customerName")
              }
            />
            <InputField
              title={"Customer Address"}
              placeholder={"Customer address"}
              value={summaryForm?.customerAddress}
              setValue={(newVal) =>
                handleChangeSummaryForm(newVal, "customerAddress")
              }
            />
            <InputField
              type="tel"
              title={"Customer WhatsApp"}
              placeholder={"Customer WhatsApp"}
              value={summaryForm?.customerWhatsApp}
              setValue={(newVal) =>
                handleChangeSummaryForm(newVal, "customerWhatsApp")
              }
            />
            <InputField
              title={"Customer Email"}
              placeholder={"example@gmail.com"}
              value={summaryForm?.customerEmail}
              setValue={(newVal) =>
                handleChangeSummaryForm(newVal, "customerEmail")
              }
            />
          </div>
        ),
      },
      {
        title: "",
        // isCustomRender: true,
        component: <PriceDetailsCard items={[data]} subTotal={data?.price} />,
      },

      {
        title: "Payment",
        component: (
          <RadioField
            title={""}
            value={summaryForm?.paymentType}
            setValue={(newVal) =>
              handleChangeSummaryForm(newVal, "paymentType")
            }
            options={paymentOptions}
          />
        ),
      },
      {
        title: "Payment Method",
        component: (
          <RadioField
            title={""}
            value={summaryForm?.paymentMethod}
            setValue={(newVal) =>
              handleChangeSummaryForm(newVal, "paymentMethod")
            }
            options={paymentMethodOptions}
          />
        ),
      },
      {
        title: "",
        component: (
          <Button
            variant="primary"
            buttonText="Print Invoice"
            size="lg"
            disableActive
          />
        ),
      },
    ];
  }, [
    data,
    handleChangeSummaryForm,
    paymentMethodOptions,
    paymentOptions,
    summaryForm?.customerAddress,
    summaryForm?.customerEmail,
    summaryForm?.customerName,
    summaryForm?.customerWhatsApp,
    summaryForm?.paymentMethod,
    summaryForm?.paymentType,
  ]);

  const template = useMemo(() => {
    switch (mode) {
      case "invoice":
        return {
          backNavTitle: "Back to Size Measure",
          onClickBackNav: () => {
            setMode("measure");
          },
          title: "Invoice",
          content: (
            <div className={Styles.invoice}>
              {invoiceTemplate?.map((each, i) => (
                <div key={i}>
                  {each?.title && <h4>{each?.title}</h4>}
                  {each?.component}
                </div>
              ))}
            </div>
          ),
        };
      default:
        return {
          backNavTitle: "Back",
          onClickBackNav: () => {
            navigate(-1);
          },
          title: "Size Measure",
          content: (
            <div className={Styles.measure}>
              {!newCustomer && !form?.customerName
                ? measurementTemplate?.slice(0, 2)?.map((each, i) => (
                  <div key={i}>
                    {each?.title && <h4>{each?.title}</h4>}
                    {each?.component}
                  </div>
                ))
                : measurementTemplate?.map((each, i) => (
                  <div key={i}>
                    {each?.title && <h4>{each?.title}</h4>}
                    {each?.component}
                  </div>
                ))}

              {/* {measurementTemplate?.map((each, i) => (
                <div key={i}>
                  {each?.title && <h4>{each?.title}</h4>}
                  {each?.component}
                </div>
              ))} */}
              {!newCustomer && !form?.customerName ? (
                <></>
              ) : (
                <Button
                  customClassName={Styles.fullWidth}
                  variant="primary"
                  size="lg"
                  buttonText="Add To Cart"
                  withIcon
                  iconName={"shopping-cart"}
                  iconPlacement="start"
                  disabled={disableAddToCart}
                  onClick={handleAddToCart}
                />
              )}
            </div>
          ),
        };
    }
  }, [disableAddToCart, form?.customerName, handleAddToCart, invoiceTemplate, measurementTemplate, mode, navigate, newCustomer]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Button
          variant="tertiary"
          size="lg"
          disableActive
          buttonText={template?.backNavTitle}
          onClick={template?.onClickBackNav}
          withIcon
          iconName={"chevron-left"}
          iconPlacement="start"
          customIconSize={16}
          fixedPadding={"0px"}
        />
        <h3>{template?.title}</h3>
      </div>
      {template?.content}
      <ModalWrapper
        show={showAddToCart}
        desktopPositioning="center"
        mobilePositioning="center"
      >
        {showAddToCart && (
          <AddToCartModal
            src={data?.img}
            name={data?.name}
            size={data?.size || ""}
            price={data?.price}
            id={data?.id}
            data={data}
            customerID={customerCode}
          />
        )}
      </ModalWrapper>
      <ModalWrapper
        show={error}
        setShow={() => setError(null)}
      >
        <WideErrorModal
          code={error?.code || 500}
          error={error?.message || 'Something went wrong!'}
          withButton
          onClose={() => setError(null)}
        />

      </ModalWrapper>
    </div>
  );
}
