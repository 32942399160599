import SimulationLayout from '@Organism/Product/Simulation'
import Styles from './style.module.scss'
import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function SimulationPage() {
  const { packageSimulationThumbnails } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <SimulationLayout
            isLoading={true}
            defaultData={[]}
          />
        }
      >
        <Await
          resolve={packageSimulationThumbnails}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageSimulationThumbnails) => (
            <SimulationLayout
              defaultData={packageSimulationThumbnails?.data}
            />
          )}

        </Await>
      </Suspense>
    </div>
  )
}