import ItemTitle from '@Atom/ItemTitle'
import Styles from './style.module.scss'

export default function ItemDetails({ item }) {

  return (
    <div className={Styles.container}>
      <ItemTitle
        name={item?.name}
        category={item?.category}
        length={item?.length}
        type={item?.type}
      />
      <div className={Styles.mWrapper}>
        {item?.measurements?.map((m, i)=> (
          <div key={i}>
            <span>{m?.title || m?.name}</span>
            <span>{m?.value}</span>
          </div>
        ))}
      </div>
    </div>
  )
}