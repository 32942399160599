import Icon from '@Atom/Icon'
import Images from '@Theme/Images'
import Styles from './style.module.scss'

export default function NavProfile({
  profilePicture = null,
  username = 'Anonymous',
  role = 'unknown',
  expand,
  setExpand = ()=> {}
}) {


  return (
    <div className={Styles.container} onClick={() => setExpand(!expand)}>
      <img src={profilePicture || Images.AVA_DEFAULT} alt="pp" />
      <div className={Styles.descWrapper}>
        <span>{username}</span>
        <div className={Styles.separator} />
        <span>{role}</span>
      </div>
      <button onClick={() => setExpand(!expand)}>
        <Icon icon={expand ? 'chevron-up' : 'chevron-down'} size={20} />
      </button>
    </div>
  )
}