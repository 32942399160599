import { Document, Font, Image, PDFViewer, Page, Text, View } from "@react-pdf/renderer";
import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import moment from "moment";

import AsyncErrorBoundary from "@Organism/_utils/AsyncErrorBoundary";
import Images from "@Theme/Images";
import useQuery from "@Hooks/useQuery";
import DetailsText1 from "../DetailsText1";
import DetailsText2 from "../DetailsText2";
import DetailsTitle from "../DetailsTitle";
import styles from "./style";


Font.register({
    family: 'Helvetica-Neue',
    fonts: [
      {
        src: '@Assets/fonts/HelveticaNeue-Regular.ttf'
      },
      {
        src: '@Assets/fonts/HelveticaNeue-Medium.ttf',
        fontWeight: 'medium'
      },
    ]
});

export default function CustomerFile() {
    const { packageInvoiceDetails } = useLoaderData()
    const query = useQuery();
    const itinery = query?.get('itinery')
    const remark = query?.get('remark')

    return (
        <Suspense
            fallback={
                <>loading</>
            }
        >
            <Await
                resolve={packageInvoiceDetails}
                errorElement={<AsyncErrorBoundary/>}
            >
                {(packageInvoiceDetails) => (
                    <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
                        <Document>
                            <Page size="A4" style={styles.page}>
                                <View style={styles.headerWrapper} fixed>
                                    <Image src={Images.LOGO_NO_ICON} style={styles.logo} />
                                    <View style={styles.header}>
                                    <View style={styles.headerLeft}>
                                        <Text style={styles.title}>Customer File</Text>
                                        <Text style={styles.subtitle}>Order ID: {packageInvoiceDetails?.data?.code}</Text>
                                    </View>
                                    <View style={styles.headerRight}>
                                        <View style={styles.headerRightSection}>
                                        <Text style={styles.dueDateTitle}>Order Date</Text>
                                        <View style={styles.dueDateContent}>
                                            <Image src={Images.CALENDAR_DAYS} style={styles.calendar} />
                                            <Text style={styles.formerDueDate}>{packageInvoiceDetails?.data?.invoiceDate ? moment(packageInvoiceDetails?.data?.invoiceDate)?.format('LL') : moment(packageInvoiceDetails?.data?.createdAt)?.format('LL')}</Text>
                                            {/* <Text style={styles.currentDueDate}>{datsa?.currentDueDate || '-'}</Text> */}
                                        </View>
                                        </View>
                                        <View style={styles.headerRightSection}>
                                        <Text style={styles.dueDateTitle}>Due Date</Text>
                                        <View style={styles.dueDateContent}>
                                            <Image src={Images.CALENDAR_DAYS} style={styles.calendar} />
                                            <Text style={styles.formerDueDate}>{packageInvoiceDetails?.data?.dueDate ? moment(packageInvoiceDetails?.data?.dueDate)?.format('LL') : moment(packageInvoiceDetails?.data?.createdAt)?.format('LL')}</Text>
                                        </View>
                                        </View>


                                    </View>
                                    </View>

                                </View>

                                <View style={styles.content}>
                                    <View style={styles.table}>
                                        <View>
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tabelLabel}> 
                                                    <Text style={styles.tableCell}>Name</Text> 
                                                </View>
                                                <View style={styles.tableCol}> 
                                                    <Text style={styles.tableCell}>: {packageInvoiceDetails?.data?.customer?.name}</Text> 
                                                </View> 
                                            </View>
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tabelLabel}> 
                                                    <Text style={styles.tableCell}>Address</Text> 
                                                </View>
                                                <View style={styles.tableCol}> 
                                                    <Text style={styles.tableCell}>: {packageInvoiceDetails?.data?.customer?.address}</Text> 
                                                </View> 
                                            </View>
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tabelLabel}> 
                                                    <Text style={styles.tableCell}>Telephone No</Text> 
                                                </View>
                                                <View style={styles.tableCol}> 
                                                    <Text style={styles.tableCell}>: {packageInvoiceDetails?.data?.customer?.phone}</Text> 
                                                </View> 
                                            </View>
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tabelLabel}> 
                                                    <Text style={styles.tableCell}>Itinery</Text> 
                                                </View>
                                                <View style={styles.tableCol}> 
                                                    <Text style={styles.tableCell}>: {itinery}</Text> 
                                                </View> 
                                            </View>
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tabelLabel}> 
                                                    <Text style={styles.tableCell}>Received</Text> 
                                                </View>
                                                <View style={styles.tableCol}> 
                                                    <Text style={styles.tableCell}>: {moment(packageInvoiceDetails?.data?.createdAt).format('LL')}</Text> 
                                                </View> 
                                            </View>
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tabelLabel}> 
                                                    <Text style={styles.tableCell}>Due Date</Text> 
                                                </View>
                                                <View style={styles.tableCol}> 
                                                    <Text style={styles.tableCell}>: {moment(packageInvoiceDetails?.data?.dueDate).format('LL')}</Text> 
                                                </View> 
                                            </View>
                                        </View>
                                    </View>

                                    <View style={styles.invoiceOnlyWrapper}>
                                        <View style={styles.payment}>
                                            <DetailsTitle title={'Remark'} />
                                            <DetailsText1 text={remark || '-'} />
                                        </View>
                                    </View>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                )}
            </Await>
        </Suspense>
    )
};
