import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function Chip({
  title,
  handleRemove
}) {

  return (
    <div className={Styles.container}>
      <span>{title}</span>
      <button onClick={handleRemove}>
        <Icon icon="x-circle" size={20} />
      </button>
    </div>
  )

}