import ModalHeader from '@Atom/ModalHeader'
import Styles from './style.module.scss'
import BuyerNameWithTag from '@Atom/BuyerNameWithTag'

export default function DetailsModal({
  modalTitle,
  modalIcon,
  children,
  handleClose = () => { },
  extraHeaderIntro,
  extraHeaderTitle,
  tagType,
  tagTitle,
  image,
  withImage,
  noTag = false,
  customExtraComponent,
  noPadding = false,
  customClassName = ''
}) {
  return (
    <div className={`${Styles.container} ${customClassName}`}>
      <ModalHeader
        title={modalTitle}
        iconName={modalIcon}
        withIcon
        withCloseButton
        isSticky
        extraComponent={customExtraComponent
          ?
          customExtraComponent
          :
          <BuyerNameWithTag
            intro={extraHeaderIntro}
            name={extraHeaderTitle}
            tagType={tagType}
            tagTitle={tagTitle}
            noTag={noTag}
            withImage={withImage}
            image={image}
          />
        }
        handleClose={handleClose}
      />
      <div className={`${Styles.content} ${noPadding ? Styles.noPadding : ''}`}>
        {children}
      </div>
    </div>
  )
}