import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function ViewImgModal({ 
  img,
  setImg = ()=> {}, 
  setShow =()=> {}
}) {

  return (
    <div className={Styles.container}>
      <button onClick={()=> setShow(false)}>
        <Icon icon={'x-circle-solid'} size={28} />
      </button>
      <img src={img} alt='' />
    </div>
  )
}