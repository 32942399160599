/* eslint-disable eqeqeq */
import AdornmentLabel from '@Atom/AdornmentLabel';
import Avatar from '@Atom/Avatar';
import Icon from '@Atom/Icon';
import { priceFormat } from '@Helpers/priceFormat';
import { stringToNumber } from '@Helpers/stringToNumber';
import useOutsideClick from '@Hooks/useOutsideClick';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Styles from './style.module.scss';

export default function Input({
  type = 'text',
  withAdornment,
  adornmentPosition,
  adornmentComponent,
  placeholder,
  value,
  setValue = () => { },
  isCurrency,
  isDate,
  isDropdown,
  isSelect = false,
  dropdownOptions,
  customClassName = '',
  min = 0,
  max = 1000,
  noChevron,
  isDropdownWithImage = false,
  imagesArr,
  customAdornmentClassName = '',
  customDropdownPositioning = { top: '58px' },
  isEmail = false,
  isNumber = false,
  readOnly = false,
  disabled = false,
  onClickDropdownItem = () => {},
}) {

  const dateRef = useRef()




  const [expandDropdown, setExpandDropdown] = useState(false)
  const [expandAll, setExpandAll] = useState(true)

  const options = useMemo(() => {
    if (expandAll) {
      return dropdownOptions
    }
    return dropdownOptions?.filter(obj => obj?.toLowerCase()?.includes(value?.toLowerCase()))
  }, [dropdownOptions, expandAll, value])

  useEffect(() => {
    if (!expandDropdown) {
      setExpandAll(true)
    }
  }, [expandDropdown])


  useEffect(() => {
    if (!expandDropdown && isDropdown && value && !dropdownOptions?.find(obj => obj === value)) {
      if (!dropdownOptions?.find(el => el?.toLowerCase() === value?.toLowerCase())) {
        setValue('')
      } else {
        setValue(dropdownOptions?.find(el => el?.toLowerCase() === value?.toLowerCase()))
      }
    }
  }, [dropdownOptions, expandDropdown, isDropdown, setValue, value])

  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);


  useEffect(() => {
    if (boxOutsideClick && expandDropdown) {
      setExpandDropdown(false);
    }
  }, [boxOutsideClick, expandDropdown]);

  const handleChangeDropdown = useCallback((e) => {
    setExpandDropdown(true)
    setExpandAll(false)
    setValue(e?.target?.value)
  }, [setValue])

  const handleChangeCurrency = useCallback((e) => {
    const {
      target: { value }
    } = e;

    if (value === "") {
      return setValue(0);
    }

    const valueAsNumber = stringToNumber(value || '0');

    return setValue(valueAsNumber || 0);
  }, [setValue]);

  const phoneNumberValidation = useCallback((event) => {
    var key = event.which || event.keyCode;
    if (!value?.length) {
      // eslint-disable-next-line eqeqeq
      if (key && key != 56 && key != 9) {
        event.preventDefault()
      }
    } else {
      // eslint-disable-next-line eqeqeq
      if (key && (key <= 47 || key >= 58) && key != 8 && key != 9 && key != 37 && key !== 39) {
        event.preventDefault();
      }

    }
  }, [value?.length])

  function onlyNumberInput(event) {
    var key = event.which || event.keyCode;
    if (key && (key <= 47 || key >= 58) && key != 8) {
      if (key != 9) {
        event.preventDefault();
      }
    }
  }

  function onlyMeasurement(event) {
    var key = event.which || event.keyCode;
    if (key && (key <= 47 || key >= 58) && key != 8) {
      if (key != 9 && key != 190 && key != 191 && key != 37 && key != 39) {
        event.preventDefault();
      }
    }
  }

  const content = useMemo(() => {
    switch (type) {
      case 'text':
        return (
          <div ref={boxRef}
            className={`${Styles.inputWrapper} ${(withAdornment && adornmentPosition) ? Styles[`${adornmentPosition}Adornment`] : ''} ${isCurrency ? Styles.currency : ''} ${customClassName}`}
            onClick={() => isDropdown && setExpandDropdown(true)}
          >
            {isCurrency && <div className={Styles.currencyAdornment}><AdornmentLabel>Rp</AdornmentLabel></div>}
            <input
              type={type}
              placeholder={placeholder}
              value={(isCurrency) ? (value ? priceFormat(+value) : '') : value}
              readOnly={isSelect || readOnly}
              disabled={disabled}
              onChange={(e) =>
                isCurrency
                  ? handleChangeCurrency(e)
                  : (isDropdown
                    ? handleChangeDropdown(e) : setValue(e.target.value))}
            />
            {adornmentComponent && <div className={Styles.adornmentWrapper}>{adornmentComponent}</div>}
            {isDropdown
              &&
              <button className={`${Styles.dropdownAdornment} ${customAdornmentClassName}`} onClick={(e) => {
                e.stopPropagation()
                setExpandDropdown(!expandDropdown)
              }}>
                <Icon icon={expandDropdown ? 'chevron-up' : 'chevron-down'} size={24} />
              </button>}
            {
              expandDropdown && (
                <div className={Styles.optionsWrapper} style={customDropdownPositioning}>
                  {options
                    ?.length
                    ? options
                      ?.map((option, i) => (
                        <div
                          key={i}
                          className={option === value ? Styles.active : ''}
                          onClick={(e) => {
                            e?.preventDefault()
                            e?.stopPropagation()
                            setExpandDropdown(false)
                            setValue(option)
                            onClickDropdownItem?.(option)
                          }}>
                          {isDropdownWithImage && <Avatar src={imagesArr[i]} size='24px' />}
                          <span>{option}</span>
                        </div>
                      )) : (
                      <div>
                        no option
                      </div>
                    )}
                </div>
              )
            }

          </div>
        )
      case 'tel':
        return (
          <div ref={boxRef}
            className={`${Styles.inputWrapper} ${Styles.tel}`}
          >
            <div className={Styles.adornment}>+62</div>
            <input
              type={'tel'}
              pattern="[0-9]*"
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                if (e.target.validity.valid) {
                  setValue(e.target.value)
                }
              }
              }
              onKeyDown={phoneNumberValidation}
              readOnly={readOnly}
              disabled={disabled}
            />
          </div>
        )
      case 'date':
        return (
          <div className={`${Styles.inputWrapper} ${Styles.date}`} onClick={() => dateRef?.current?.showPicker()}>
            <span>{value ? moment(new Date(value))?.format('ll') : 'DD/MM/YYYY'}</span>
            <input
              type={type}
              ref={dateRef}
              placeholder={placeholder}
              value={moment(new Date(value))?.format('YYYY-MM-DD')}
              onChange={(e) => setValue(e.target.value)}
              readOnly={readOnly}
              disabled={disabled}
            />
            <button className={Styles.dateAdornment}>
              <Icon icon={'calendar'} size={24} color={'#6C757D'} />
            </button>
          </div>
        )
      case 'number':
        return (
          <div className={`${Styles.inputWrapper} ${(withAdornment && adornmentPosition) ? Styles[`${adornmentPosition}Adornment`] : ''} ${customClassName}`}>
            <input
              type={'text'}
              placeholder={placeholder}
              value={value}
              onChange={(e) => { setValue(e.target.value) }}
              min={min}
              max={max}
              onKeyDown={(e) => onlyNumberInput(e)}
              readOnly={readOnly}
              disabled={disabled}
            />
            <div className={Styles.endAdornments}>
              {!noChevron
                &&
                <div className={Styles.defaultAdornments}>
                  <button onClick={() => setValue(
                    value < max ? value + 1 : value
                  )}><Icon icon={'chevron-up'} size={12} /></button>
                  <button onClick={() => setValue(
                    value > min ? value - 1 : value
                  )}><Icon icon={'chevron-down'} size={12} /></button>
                </div>
              }

              {adornmentPosition === 'end' && adornmentComponent}
            </div>

          </div>
        )
      case 'measurement':
        return (
          <div className={`${Styles.inputWrapper} ${(withAdornment && adornmentPosition) ? Styles[`${adornmentPosition}Adornment`] : ''} ${customClassName}`}>
            <input
              type={'text'}
              placeholder={placeholder}
              value={value}
              onChange={(e) => { setValue(e?.target?.value?.replaceAll('cm', '')) }}
              min={min}
              max={max}
              onKeyDown={(e) => onlyMeasurement(e)}
              readOnly={readOnly}
              disabled={disabled}
            />
            <div className={Styles.endAdornments}>
              {!noChevron
                &&
                <div className={Styles.defaultAdornments}>
                  <button onClick={() => setValue(
                    value < max ? value + 1 : value
                  )}><Icon icon={'chevron-up'} size={12} /></button>
                  <button onClick={() => setValue(
                    value > min ? value - 1 : value
                  )}><Icon icon={'chevron-down'} size={12} /></button>
                </div>
              }

              {adornmentPosition === 'end' && adornmentComponent}
            </div>

          </div>
        )
      default:
        return (
          <div>
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              readOnly={readOnly}
              disabled={disabled}
            />
          </div>
        )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adornmentComponent, adornmentPosition, customAdornmentClassName, customClassName, customDropdownPositioning, disabled, expandDropdown, handleChangeCurrency, handleChangeDropdown, imagesArr, isCurrency, isDropdown, isDropdownWithImage, isSelect, max, min, noChevron, options, phoneNumberValidation, placeholder, readOnly, setValue, type, value, withAdornment])

  return content
}