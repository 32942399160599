import React from 'react'
import logo from '@Assets/svg/logo.svg';
import ReactLink from '@Atom/ReactLink';

export default function ReactOpening() {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Edit <code>src/App.js</code> and save to reload.
      </p>
      <ReactLink/>
    </header>
  )
}
