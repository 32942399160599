import CollectionOverviewLayout from '@Organism/Product/Collection/CollectionOverview'
import Styles from './style.module.scss'
import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function CollectionOverviewPage() {
  const { packageCollectionOverview } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <CollectionOverviewLayout
            isLoading={true}
            defaultData={null}
          />
        }
      >
        <Await
          resolve={packageCollectionOverview}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageCollectionOverview) => (
            <CollectionOverviewLayout
              defaultData={packageCollectionOverview?.data}
            />

          )}
        </Await>


      </Suspense>
    </div>
  )
}