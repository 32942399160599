import { useMemo } from "react";
import Styles from "./style.module.scss";
import StackedItemInfo from "@Molecule/StackedItemInfo";
import TableSet from "@Molecule/TableSet";
import RekhasBG from "@Atom/RekhasBG";
import Tag from "@Atom/Tag";
import useWindowSize from "@Hooks/useWindowSize";
import { fileBaseUrl } from "@Config/api";

export default function WorkshopRequestListLayout({
  defaultData,
  isLoading,
  trigger,
  setTrigger,
  setSelectedWorkshop,
  setShowFilter,
}) {
  const { width } = useWindowSize();

  const template = useMemo(() => {
    return {
      data: defaultData?.processes.map((el) => ({
        ...el,
        fabricCode: el.fabrics[0].code,
      })),
      // [
      //   {
      //     id: 1,
      //     name: 'Italiano Barzagli',
      //     code: 'SEFFIRORICCI-CLASSIC-19-05/1138',
      //     category: 'Collection',
      //     type: 'Suit',
      //     imgUrl: 'https://www.tessuti-shop.com/cdn/shop/products/IMG_1225_07b1d3a6-a224-4d27-a8f2-803142e94ec0@2x.jpg?v=1628738348',
      //     fabricCode: 'ROBERTO BLACK',
      //     customerName: 'MOHAMMAD BAGUS FIRDIANSYAH',
      //     customerPhoneNumber: '+62 81293674067',
      //     orderId: '123456789102938',
      //     fabrics: [
      //       {
      //         id: 1,
      //         type: 'Primary',
      //         fabricType: 'Collection',
      //         fabricName: 'Italiano Barzagli',
      //         fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
      //         img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
      //       },
      //       {
      //         id: 2,
      //         type: 'Other',
      //         fabricType: 'Collection',
      //         fabricName: 'Del Piero',
      //         fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
      //         img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
      //       },
      //     ],
      //     sizes: [
      //       {
      //         name: `When I'm fat`,
      //         measurements: [
      //           {
      //             name: 'Panjang',
      //             value: '1 m'
      //           },
      //           {
      //             name: 'Pinggang',
      //             value: '0,5 m'
      //           },
      //           {
      //             name: 'Pinggul',
      //             value: '0,5 m'
      //           },
      //           {
      //             name: 'Lebar',
      //             value: '0.5 m'
      //           }
      //         ],
      //       }
      //     ],
      //     customerPhotos: [
      //       {
      //         id: 1,
      //         name: 'p1',
      //         url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJe-IKABR_0rbo0wlWrJISkxGJopWbYFkuo1Vg3nCH9_OKd9s_0Es4-TDIzUVPz-ioXxA&usqp=CAU'
      //       },
      //       {
      //         id: 2,
      //         name: 'p1',
      //         url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJe-IKABR_0rbo0wlWrJISkxGJopWbYFkuo1Vg3nCH9_OKd9s_0Es4-TDIzUVPz-ioXxA&usqp=CAU'
      //       },
      //       {
      //         id: 3,
      //         name: 'p1',
      //         url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJe-IKABR_0rbo0wlWrJISkxGJopWbYFkuo1Vg3nCH9_OKd9s_0Es4-TDIzUVPz-ioXxA&usqp=CAU'
      //       },
      //       {
      //         id: 4,
      //         name: 'p1',
      //         url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJe-IKABR_0rbo0wlWrJISkxGJopWbYFkuo1Vg3nCH9_OKd9s_0Es4-TDIzUVPz-ioXxA&usqp=CAU'
      //       },
      //       {
      //         id: 5,
      //         name: 'p1',
      //         url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJe-IKABR_0rbo0wlWrJISkxGJopWbYFkuo1Vg3nCH9_OKd9s_0Es4-TDIzUVPz-ioXxA&usqp=CAU'
      //       },
      //       {
      //         id: 6,
      //         name: 'p1',
      //         url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJe-IKABR_0rbo0wlWrJISkxGJopWbYFkuo1Vg3nCH9_OKd9s_0Es4-TDIzUVPz-ioXxA&usqp=CAU'
      //       },
      //     ],
      //     notes: 'Notes I Want To Create Glamorous Suit!',
      //     status: 'REQUESTED',
      //     address: 'Jl. Curug Jaya A no. 77 Jaticempaka, Pondok Gede, Kota Bekasi'
      //   },
      // ],
      columns: [
        {
          name: "name",
          label: "Product Name",
          minWidth: "297px",
          thClassName: Styles.stickyLeft,
          tdClassName: Styles.stickyLeft,
          renderData: (row) => (
            <StackedItemInfo
              title={row?.fabrics[0]?.name}
              label={`${row?.fabrics[0]?.type}`}
              picture={fileBaseUrl + row?.fabrics[0]?.imageUrl}
              // isV2
              // iconName='suit'
            />
          ),
        },
        // {
        //   name: 'code',
        //   label: 'Product Code',
        //   minWidth: '208px',
        //   maxWidth: '208px'
        // },
        {
          name: "fabricCode",
          label: "Fabric Code",
          minWidth: "208px",
        },
        {
          name: "status",
          label: "Status",
          minWidth: "141px",
          renderData: (row) => (
            <div className={Styles.itemStatus}>
              <Tag
                type={
                  row.status === "ONDELIVERY"
                    ? "yellow"
                    : row.status === "DELIVERED"
                    ? "green"
                    : row?.status === "ON-REQUEST"
                    ? "blue"
                    : row?.status === "READY"
                    ? "black"
                    : "orange"
                }
                title={
                  row.status === "ONDELIVERY"
                    ? "On Delivery"
                    : row?.status === "DELIVERED"
                    ? "Delivered"
                    : row?.status === "ON-REQUEST"
                    ? "Requested"
                    : row?.status === "READY"
                    ? "Ready"
                    : "On Delivery"
                }
              />
              {/* {row?.itemStatus === 'READY'
                &&
                <Button
                  variant='secondary'
                  size='sm'
                  buttonText='Set Schedule'
                  borderRadius='4px'
                  onClick={(e) => {
                    e.stopPropagation();
                    setOrderId(row?.id)
                    setShowScheduleSetter(true)
                  }}
                />
              } */}
            </div>
          ),
        },
        {
          name: "customerName",
          label: "Customer Name",
          minWidth: "290px",
          renderData: (row) => (
            <StackedItemInfo
              title={row?.customerName}
              label={row?.customerPhoneNumber || "-"}
              withoutPicture
              titleWidth={width <= 768 ? "150px" : "100%"}
              // renderExtraTopLabel={() => {
              //   return (
              //     row?.isNew ? <div className={Styles.newTag}>New Order</div> : <></>
              //   )
              // }}
            />
          ),
        },
        {
          name: "orderID",
          label: "Order Id",
          minWidth: "187px",
        },
      ],
    };
  }, [defaultData?.processes, width]);

  const handleClickRow = (row) => {
    setSelectedWorkshop(row);
  };

  return (
    <RekhasBG>
      <TableSet
        type={"workshop-request"}
        data={template?.data}
        columns={template?.columns}
        onClickFilter={() => setShowFilter(true)}
        onClickRow={(row) => handleClickRow(row)}
        totalPage={defaultData?.totalPage || 0}
        totalData={defaultData?.total || 0}
        withNumbering
      />
    </RekhasBG>
  );
}
