import Avatar from '@Atom/Avatar'
import Button from '@Atom/Button'
import ModalHeader from '@Atom/ModalHeader'
import InputField from '@Molecule/InputField'
import ItemDetails from '@Molecule/ItemDetails'
import { assignWorker } from '@Services/order'
import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function AssignWorkerModal({
  selectedItems,
  setShow,
  workers,
  setTrigger,
  onSuccess
}) {


  const [isSubmitting, setIsSubmitting] = useState(false)

  const [form, setForm] = useState({
    cutter: '',
    tailor: ''
  })

  useEffect(()=> {
    setForm({
      cutter: '',
      tailor: ''  
    })
  }, [selectedItems])


  const cutters = useMemo(() => {
    return workers?.cutters?.map(obj => obj?.name)
  }, [workers?.cutters])

  const tailors = useMemo(() => {
    return workers?.tailors?.map(obj => obj?.name)
  }, [workers?.tailors])


  const formToSend = useMemo(() => {
    return {
      data: selectedItems?.map(obj => {
        return {
          id: obj?.id,
          tailorCode: workers?.tailors?.find(t => t?.name === form?.tailor)?.code,
          cutterCode: workers?.cutters?.find(c => c?.name === form?.cutter)?.code
        }
      })
    }
  }, [form?.cutter, form?.tailor, selectedItems, workers?.cutters, workers?.tailors])

  const disableSubmit = useMemo(() => {
    if (!form?.cutter || !form?.tailor || isSubmitting) {
      return true
    } return false
  }, [form?.cutter, form?.tailor, isSubmitting])

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true)
      await assignWorker(formToSend)
      setIsSubmitting(false)
      setTrigger(makeRandomString(5))
      onSuccess()
    } catch (err) {
      setIsSubmitting(false)
      console.log(err, 'error on assigning worker')
    }
  }


  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'Assign Cutter & Tailor'}
        paddingY='16px'
      />
      <div className={Styles.content}>
        <div className={Styles.itemsWrapper}>
          <span>{selectedItems?.length} Items</span>
          <div className={Styles.items}>
            {selectedItems?.map((item, i) => (
              <div
                key={item?.id}
              >
                <span>{i + 1}</span>
                <Avatar src={item?.img} size='32px' borderRadius='6px' />
                <ItemDetails
                  item={item}
                />
              </div>
            ))}
          </div>
        </div>
        <InputField
          title={'Cutter'}
          value={form?.cutter}
          setValue={(newVal) => setForm(f => ({ ...f, cutter: newVal }))}
          isDropdown
          dropdownOptions={cutters}
          placeholder={'Search and select employee'}
        // isDropdownWithImage
        // imagesArr={[
        //   Images?.AVA_DEFAULT,
        //   'https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?cs=srgb&dl=pexels-mohamed-abdelghaffar-771742.jpg&fm=jpg',
        //   'https://e1.pxfuel.com/desktop-wallpaper/903/679/desktop-wallpaper-97-aesthetic-best-profile-pic-for-instagram-for-boy-instagram-dp-boys.jpg'
        // ]}
        />
        <InputField
          title={'Tailor'}
          value={form?.tailor}
          setValue={(newVal) => setForm(f => ({ ...f, tailor: newVal }))}
          isDropdown
          dropdownOptions={tailors}
          placeholder={'Search and select employee'}
        // isDropdownWithImage
        // imagesArr={[
        //   Images?.AVA_DEFAULT,
        //   'https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?cs=srgb&dl=pexels-mohamed-abdelghaffar-771742.jpg&fm=jpg',
        //   'https://e1.pxfuel.com/desktop-wallpaper/903/679/desktop-wallpaper-97-aesthetic-best-profile-pic-for-instagram-for-boy-instagram-dp-boys.jpg'
        // ]}

        />
      </div>
      <div className={Styles.actions}>
        <Button
          variant='secondary'
          buttonText='Cancel'
          onClick={() => setShow(false)}
          size='lg'
        />
        <Button
          buttonText='Confirm'
          onClick={handleConfirm}
          size='lg'
          disabled={disableSubmit}
        />
      </div>
    </div>
  )
}