import { API } from "@Config/api";

export function getDeliveries(type, page, limit, status, search, scheduleAt = '', deliveredAt = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/deliver?type=${type}&page=${page}&limit=${limit}&search=${search}&status=${status}&scheduleAt=${scheduleAt}&deliveredAt=${deliveredAt}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function getDeliverSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get('/deliver/sync', {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

export function deleteDeliveries(ids = []) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(`/deliver/delete`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
        params: {
          ids: `[${ids?.join(', ')}]`
        }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function uploadDeliveryProof(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/deliver/${id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getDelivery(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/deliver/${code}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
}

export function getDeliveryById(id, type) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/deliver/detail/${id}?type=${type}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
}
