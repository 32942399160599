import Avatar from '@Atom/Avatar'
import Icon from '@Atom/Icon'
import NavProfile from '@Atom/NavProfile'
import NavTab from '@Atom/NavTab'
import NavbarSlider from '@Atom/NavbarSlider'
import { fileBaseUrl } from '@Config/api'
import useOutsideClick from '@Hooks/useOutsideClick'
import ProfilePopup from '@Molecule/_popups/ProfilePopup'
import Images from '@Theme/Images'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'


export default function Navbar({ children, ...props }) {
  const { pathname } = useLocation()

  const [expandNav, setExpandNav] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [newOrderAmount, setNewOrderAmount] = useState(1)
  const navigate = useNavigate()
  const [expand, setExpand] = useState(false)

  const user = localStorage['user'] ? JSON.parse(localStorage['user']) : null
  const username = user?.nickName || ''
  const role = user?.role === 'OFFICE' ? (
    user?.defaultRole === 'SALES'
      ?
      'SALES'
      :
      'OFFICE'
  ) : user?.role
  const img = user?.imageUrl ? fileBaseUrl + user?.imageUrl : Images.AVA_DEFAULT

  const tabOptions = useMemo(() => {
    return [
      {
        icon: 'rectangle-stack',
        title: 'Stock',
        path: 'stock',
        roles: ['director', 'office', 'sales']
      },
      {
        icon: 'archive-box',
        title: 'Order',
        path: 'order',
        roles: ['director', 'office', 'sales']
      },
      {
        icon: 'document-check',
        title: 'Invoice',
        path: 'invoice',
        roles: ['director', 'office']
      },
      {
        icon: 'paper-airplane',
        title: 'Delivery',
        path: 'delivery',
        roles: ['director', 'office', 'courier']
      },
      {
        icon: 'rectangle-group',
        title: 'Product',
        path: 'product',
        roles: ['director', 'office', 'sales']
      },
      // {
      //   icon: 'cog-6-tooth',
      //   title: 'Setup',
      //   path: 'setup'
      // },
      {
        icon: 'map-pin',
        title: 'Checkpoint',
        path: 'checkpoint',
        roles: ['director', 'office']
      },
      {
        icon: 'map-pin',
        title: 'Workshop',
        path: 'workshop',
        roles: ['director']
      },
      {
        icon: 'user-group',
        title: 'Employee',
        path: 'employee',
        roles: ['director', 'office']
      },
      {
        icon: 'fee',
        title: 'Fee',
        path: 'fee',
        roles: []
      },
      {
        icon: 'heart',
        title: 'Customer',
        path: 'customer',
        roles: ['director', 'office']
      },
      {
        icon: 'clock',
        title: 'Log',
        path: 'log',
        roles: ['director', 'office']
      },
      {
        icon: 'map-pin',
        title: 'Requests',
        path: 'workshop-request',
        roles: ['office']
      },
      // {
      //   icon: 'shopping-cart',
      //   title: 'Cart',
      //   path: 'cart',
      //   roles: ['sales']
      // },
    ]

  }, [])

  const tabsByRole = useMemo(() => {
    return tabOptions?.filter(obj => obj?.roles?.includes(role?.toLowerCase()))
  }, [role, tabOptions])

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);


  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(elementRef.current.offsetHeight);
      localStorage.setItem('topBarHeight', elementRef.current.offsetHeight)
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const topBarRef = useRef()
  const backupRef = useRef()

  const boxOutsideClick = useOutsideClick(topBarRef)

  useEffect(() => {
    if (expandNav && boxOutsideClick) {
      setExpandNav(false)
    }
  }, [boxOutsideClick, expandNav])

  const popupRef = useRef()
  // const backupPopupRef = useRef()

  const outsidePopupClick = useOutsideClick(popupRef)

  useEffect(() => {
    if (expand && outsidePopupClick) {
      setExpand(false)
    }
  }, [expand, outsidePopupClick])



  return (
    <div className={Styles.container} >
      <NavbarSlider
        show={expandNav}
        setShow={setExpandNav}
      >
        <div className={Styles.topBar} ref={expandNav ? topBarRef : backupRef}>
          <div className={Styles.top}>
            <Avatar
              src={img}
              size='50px'
            />
            <div className={Styles.name}>
              <span>{username}</span>
              <div className={Styles.divider} />
              <span>{role}</span>
            </div>
            <img src={Images.MAIN_LOGO} alt='' />
          </div>
          <div className={Styles.main}>
            {tabsByRole?.map((tab, i) => (
              <button key={i} onClick={() => {
                if (pathname?.replaceAll('/', '') !== tab?.path) {
                  navigate(tab?.path)
                }
                setExpandNav(false)
              }}>
                <Icon icon={tab?.icon} size={16} />
                <span>{tab?.title}</span>
                {/* {(tab?.title === 'Order' && +newOrderAmount > 0)
                  && <span className={Styles.redTag}>{newOrderAmount}</span>
                } */}
              </button>
            ))}
          </div>
          <div className={Styles.footer}>
            <button onClick={() => {
              sessionStorage?.clear()
              localStorage?.clear()
              navigate('/')
            }}>Keluar dari akun</button>
          </div>
        </div>
      </NavbarSlider>
      <div ref={elementRef} className={Styles.navWrapper}>
        <div className={Styles.navContent}>
          <div className={`${Styles.showOnMobileAndTablet}`}>
            <button
              onClick={() => setExpandNav(true)}
            >
              <Icon
                icon={'bars-3'}
                size={24}
              />
            </button>
          </div>
          <div className={Styles.logoWrapper}>
            <img src={Images?.MAIN_LOGO} alt="rekhas" />
          </div>
          {
            role !== 'DRIVER'
              ?
              <div className={`${Styles.showOnTablet} ${Styles.extra}`}>
                <button>
                  <Icon
                    icon={'magnifying-glass'}
                    size={24}
                  />
                </button>
                <button
                  onClick={() => navigate('/product/cart')}
                >
                  <Icon
                    icon={'shopping-cart'}
                    size={24}
                  />
                </button>
              </div>
              :
              <></>
          }

          <div className={Styles.tabsWrapper}>
            {tabsByRole?.map((tab, i) => (
              <NavTab
                key={i}
                icon={tab?.icon}
                title={tab?.title}
                path={tab?.path}
                newOrderAmount={newOrderAmount}
              />
            ))}
          </div>
          <NavProfile
            profilePicture={img}
            username={username}
            role={role}
            expand={expand}
            setExpand={setExpand}
          />
          {expand
            &&
            <div ref={popupRef} className={Styles.popupWrapper}>
              <ProfilePopup />
            </div>
          }


        </div>
      </div>

      <div className={Styles.contentWrapper}
        style={{ height: `calc(100vh - ${height}px)`, overflowY: 'scroll', overflowX: 'clip' }}
      >

        <div className={Styles.content}>
          {children}
        </div>
      </div>
    </div>
  )
}