import ModalWrapper from "@Atom/ModalWrapper";
import WorkshopRequestDetailsModal from "@Molecule/_modals/DetailsModals/WorkshopRequest";
import WorkshopFilterModal from "@Molecule/_modals/FilterModals/Workshop";
import WorkshopRequestListLayout from "@Organism/WorkshopRequest/WorkshopRequestList";
import { Suspense, useState } from "react";
import Styles from "./style.module.scss";
import { Await, useLoaderData } from "react-router-dom";
import AsyncErrorBoundary from "@Organism/_utils/AsyncErrorBoundary";

export default function WorkshopRequestListPage() {
  const { packageWorkshops } = useLoaderData()
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  const [trigger, setTrigger] = useState("");

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <WorkshopRequestListLayout
            defaultData={null}
            isLoading={false}
            trigger={trigger}
            setTrigger={setTrigger}
            setSelectedWorkshop={setSelectedWorkshop}
            setShowFilter={setShowFilter}
          />
        }
      >
        <Await resolve={packageWorkshops} errorElement={<AsyncErrorBoundary />}>
          {(packageWorkshops) => (
            <WorkshopRequestListLayout
              defaultData={packageWorkshops?.data}
              isLoading={false}
              trigger={trigger}
              setTrigger={setTrigger}
              setSelectedWorkshop={setSelectedWorkshop}
              setShowFilter={setShowFilter}
            />
          )}
        </Await>
      </Suspense>

      <div>
        <ModalWrapper
          desktopPositioning="right"
          mobilePositioning="bottom"
          show={selectedWorkshop ? true : false}
          consoling={true}
          setShow={() => setSelectedWorkshop(null)}
        >
          <WorkshopRequestDetailsModal
            selectedWorkshop={selectedWorkshop}
            handleClose={() => setSelectedWorkshop(null)}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning="right"
          mobilePositioning="bottom"
          show={showFilter}
          setShow={setShowFilter}
        >
          <WorkshopFilterModal
            handleClose={() => {
              setShowFilter(false);
              // setTrigger(Math.random())
            }}
            trigger={trigger}
          />
        </ModalWrapper>
      </div>
    </div>
  );
}
