/* eslint-disable no-unused-vars */
import Icon from '@Atom/Icon';
import { priceFormat } from '@Helpers/priceFormat';
import imglyRemoveBackground from "@imgly/background-removal";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Styles from './style.module.scss';
import Skeleton from 'react-loading-skeleton';

export default function ListingCard({
  item,
  setSelectedItem = () => { },
  isCollection = false,
  isLoading = false,
}) {
  const [url, setUrl] = useState(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // useEffect(() => {
  //   let config = {
  //     fetchArgs: {
  //       mode: 'no-cors'
  //     }
  //   };
  //   try {
  //     imglyRemoveBackground(item?.img, config).then((blob) => {
  //       // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
  //       setUrl(URL.createObjectURL(blob));
  //     })
  //   } catch (err) {
  //     // setUrl('error')
  //     console.log(err)
  //   }

  // }, [item])

  if (isLoading) {

    return (
      <div className={`${Styles.container} ${isCollection ? Styles.collection : ''}`}
      >
        {/* <Skeleton className={Styles.top}/> */}
        <div className={Styles.top}>
          <Skeleton className={Styles.img} />
          {/* <Skeleton enableAnimation={false} className={Styles.button} /> */}
        </div>
        <div className={Styles.bottom}>
          <Skeleton className={Styles.name} />
          <Skeleton className={Styles.collection} />
          {/* <span>{item?.name}</span>
          {!isCollection && <span>Collection: {item?.collection || '-'}</span>
          } */}
          <div>
            <div>
              <Skeleton className={Styles.price} />
            </div>
            <Skeleton className={Styles.icon} />
            <Skeleton className={Styles.qty} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`${Styles.container} ${isCollection ? Styles.collection : ''}`}
      onClick={() => navigate(`${pathname}/overview/${item?._id?.replaceAll('/', '::')}`)}
    >
      <div className={Styles.top}>
        {item?.img && <img src={item?.img} alt='' />}
        {/* {item?.img && !url && 'loading image'} */}
        {/* <button
          onClick={(e) => {
            e.stopPropagation()
            setSelectedItem(item)
          }}
        >
          <Icon icon={'shopping-cart'} size={20} />
        </button> */}
      </div>
      <div className={Styles.bottom}>
        <span>{item?.name}</span>
        <span>{item?.code}</span>
        {/* {!isCollection && <span>Collection: {item?.collection || '-'}</span>
        } */}
        <div>
          <span>Rp {priceFormat(item?.price)}</span>
          <Icon icon={'box-group'} size={20} />
          <span>{item?.qty}</span>
        </div>
      </div>
    </div>
  )
}