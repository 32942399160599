import { stockTypes } from '@Assets/data/stockTypes'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import RekhasBG from '@Atom/RekhasBG'
import { fileBaseUrl } from '@Config/api'
import { priceFormat } from '@Helpers/priceFormat'
import useQuery from '@Hooks/useQuery'
import GroupedAvatars from '@Molecule/GroupedAvatars'
import StackedItemInfo from '@Molecule/StackedItemInfo'
import TableSet from '@Molecule/TableSet'
import StockListSkeleton from '@Molecule/_skeletons/Stock/StockList'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function StockListLayout({
  setShowFilter,
  setShowAdd,
  isLoading,
  defaultData,
  trigger,
  setTrigger,
  defaultSync
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()
  const type = query.get('t') || ''
  const template = useMemo(() => {
    return ({
      data: defaultData?.products?.map((each, i) => {
        return {
          ...each,
          id: each?._id,
          name: each?.name,
          category: stockTypes?.find(obj => obj?.query === each?.type)?.title,
          type: each?.type,
          code: each?.productCode,
          fabricCode: each?.fabric,
          qty: each?.qty,
          description: each?.description,
          price: priceFormat(each?.price),
          createdAt: moment(new Date(each?.createdAt))?.format('L'),
          checkpoint: each?.store?.name,
          img: each?.productImages?.length ? each?.productImages?.find(obj => obj?.imageDescription === 'MAIN')?.url : null,
          imgCollections: each?.productImages?.map(obj => fileBaseUrl + obj?.url)
        }
      }),
      columns: [
        {
          name: 'name',
          label: 'Product Name',
          maxWidth: '283px',
          minWidth: '283px',
          renderData: (row) => (
            <StackedItemInfo
              title={row?.name}
              label={row?.category}
              picture={row?.img ? fileBaseUrl + row?.img : null}
              maxWidth='283px'
            />
          )
        },
        {
          name: 'code',
          label: 'Product Code',
          minWidth: '224px',
          maxWidth: '224px',
          hideOnMobile: true,
        },
        {
          name: 'fabricCode',
          label: 'Fabric Code',
          minWidth: '190px',
          maxWidth: '190px',
          hideOnMobile: true,
        },
        {
          name: 'qty',
          label: 'Qty',
          // label: !type || type?.toLowerCase()?.includes('ready') ? 'Qty' : 'Length',
          hideOnMobile: true,
          align: 'center',
          renderData: (row) => (
            <span>{row?.qty}{row?.type?.toLocaleLowerCase()?.includes('regular') ? 'm' : ''}</span>
          )
        },
        {
          name: 'description',
          label: 'Description',
          minWidth: '200px',
          maxWidth: '200px',
          hideOnMobile: true,
        },
        {
          name: 'price',
          label: 'Price',
          hideOnMobile: true,
          align: 'right'
        },
        {
          name: 'createdAt',
          label: 'Date Added',
          minWidth: '124px',
          hideOnMobile: true,
        },
        {
          name: 'checkpoint',
          label: 'Checkpoint',
          minWidth: '170px',
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.checkpoint}>
              <Icon icon={'map-pin-solid'} size={20} color={'#DD0B0B'} />
              <span>{row?.checkpoint || '-'}</span>
            </div>
          )
        },
        {
          name: 'image',
          label: 'Image',
          hideOnMobile: true,
          renderData: (row) => (
            (row?.imgCollections && row?.imgCollections?.length) ?
              <GroupedAvatars
                srcArr={row?.imgCollections}
                borderRadius='4px'
              /> : <>no image</>
          )
        },
        {
          name: '',
          label: '',
          showOnMobile: true,
          renderData: (row) => (
            <div>
              <Button
                state='icon-only'
                variant='secondary'
                disableActive
                withIcon
                iconName={'list-bullet'}
                size='24'
                fixedPadding={'12px'}
                onClick={() => navigate(`${pathname}/overview/${row?.code?.replaceAll('/', '::')}?t=${type}`)}
              />
            </div>
          )
        }
      ]
    })
  }, [defaultData?.products, navigate, pathname, type])

  const [loadingData, setLoadingData] = useState(isLoading)

  useEffect(() => {
    setLoadingData(isLoading)
  }, [isLoading])


  return (
    <RekhasBG maxHeight='980px'>
      {!isLoading
        ?
        <TableSet
          type='stock'
          data={template?.data}
          columns={template?.columns}
          onClickRow={(row) => navigate(`${pathname}/overview/${row?.code?.replaceAll('/', '::')?.replaceAll('\t', ':_:')}?t=${row?.type?.toLowerCase()}`)}
          onClickFilter={() => setShowFilter(true)}
          onClickAdd={() => setShowAdd(true)}
          totalPage={defaultData?.totalPage}
          totalData={defaultData?.total}
          trigger={trigger}
          setTrigger={setTrigger}
          deliverSync={defaultSync}
          isLoading={loadingData}
          setIsLoading={setLoadingData}
          withSkeleton
          disableCheck={(checkedArr, data) => {
            if (checkedArr?.length) {
              if (checkedArr[0]?.checkpointCode !== data?.checkpointCode) {
                return true
              }
            } return false
          }}
        />
        :
        <StockListSkeleton
          type='stock'
          data={template?.data}
          columns={template?.columns}
          onClickFilter={() => setShowFilter(true)}
          onClickAdd={() => setShowAdd(true)}
        />

      }
    </RekhasBG>
  )
}