import { API } from "@Config/api";

export function getFees(page, limit, type, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/fee?page=${page}&limit=${limit}&type=${type}&search=${search}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function updateFeePayment(id,payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/user/payment/${id}`,payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (err) {
      reject(err)
    }
  })
}