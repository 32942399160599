import Styles from './style.module.scss'

export default function FooterOnlyMiniTable({
  columns = [],
  data = []
}) {

  return (
    <div className={Styles.container}>
      <table>
        <tbody>
          {data?.map((d, iD) => (
            <tr key={iD} className={iD !== data?.length - 1 ? Styles.bb : ''}>
              {columns?.map((c, iC) => (
                <td
                  key={iC}
                  className={`${c?.tdClassName || ''}`}
                  style={{
                    textAlign: c?.align || 'left'
                  }}
                >
                  {c?.renderData ? c?.renderData(d) : d[c?.name]}
                </td>
              ))}
            </tr>
          ))}
          <tr className={Styles.footer}>
            {columns?.map((c, iC) => (
              <td
                key={iC} className={c?.thClassName || ''}
                style={{
                  textAlign: c?.align || 'left'
                }}
              >
                {c?.title}
              </td>
            ))}
          </tr>
        </tbody>
        {/* <thead>
          <tr>
            {columns?.map((c, iC) => (
              <th key={iC} className={c?.thClassName || ''}>
                {c?.title}
              </th>
            ))}
          </tr>

        </thead> */}
      </table>
    </div>
  )
}