import Input from '@Atom/Input'
import Styles from './style.module.scss'

export default function InputField({
  title,
  type = 'text',
  withAdornment,
  adornmentPosition,
  adornmentComponent,
  placeholder,
  value,
  setValue,
  isCurrency = false,
  isDate = false,
  isDropdown = false,
  isSelect = false,
  dropdownOptions,
  min,
  max,
  noChevron = false,
  isDropdownWithImage = false,
  imagesArr,
  isEmail = false,
  isNumber = false,
  required = false,
  requiredLabel = "*",
  readOnly = false,
  disabled=false,
  onClickDropdownItem = () => {},
}) {

  return (
    <div className={Styles.container}>
      {title
        &&
        <div className={Styles.title}>
          {title}
          {required && <span className={Styles.redStar}>{requiredLabel}</span>}
        </div>
      }

      <Input
        type={type}
        withAdornment={withAdornment}
        adornmentComponent={adornmentComponent}
        adornmentPosition={adornmentPosition}
        placeholder={placeholder}
        value={value}
        setValue={setValue}
        isCurrency={isCurrency}
        isDate={isDate}
        isDropdown={isDropdown}
        isSelect={isSelect}
        dropdownOptions={dropdownOptions}
        min={min}
        max={max}
        noChevron={noChevron}
        isDropdownWithImage={isDropdownWithImage}
        imagesArr={imagesArr}
        isEmail={isEmail}
        isNumber={isNumber}
        readOnly={readOnly}
        disabled={disabled}
        onClickDropdownItem={onClickDropdownItem}
      />
    </div>
  )
}