import { fileBaseUrl } from "@Config/api";
import { priceFormat } from "@Helpers/priceFormat";
import Images from "@Theme/Images";
import { Image, Text, View } from "@react-pdf/renderer";
import styles from "./style";

export default function ItemCard({
  item,
  size,
  cuttingFileType
}) {
  const isCuttingFile = cuttingFileType ? true : false

  // const [imageUrl, setImageUrl] = useState(null);
  // const imageDescription = 'A dynamically fetched image';

  // useEffect(() => {
  //   if (item?.img) {
  //     fetch(item?.img)
  //       .then(response => response.json())
  //       .then(data => setImageUrl(data.url));

  //   }
  // }, [item?.img]);

  // console.log(imageUrl, 'iu')

  return (
    <View style={styles.container} wrap={false}>
      {!isCuttingFile
        ?
        (item?.textiles?.length > 1
          ?
          <View style={styles.headers}>
            {item?.category !== 'Ready to Wear'
              &&
              <View style={styles.header}>
                <View style={styles.avatar}>
                  <Image src={Images.SUIT} style={styles.img} />
                </View>
                <View style={styles.typeLabelWrapper}>
                  <View style={styles.typeLabel}>
                    <Text style={styles.typeName}>{item?.category === 'Ready to Wear' ? item?.category : item?.type}</Text>
                  </View>
                </View>
                <View style={styles.price}>
                  <Text>1x</Text>
                  {cuttingFileType === 'order'
                    &&
                    <Text>Rp{priceFormat(size?.items[0]?.price)}</Text>
                  }
                </View>
              </View>
            }
            {item?.textiles?.map((sItem, i) => (
              <View key={i} style={styles.header}>
                <View style={sItem?.category === 'Ready to Wear' ? styles.avatar : styles.mediumAvatar}>
                  {sItem?.imageUrl && (
                    <Image src={sItem?.imageUrl ? fileBaseUrl + sItem?.imageUrl : ''} style={styles.img} />
                  )}
                </View>
                <View style={styles.desc}>
                  <View style={styles.type}>
                    {/* {sItem?.category !== 'Ready to Wear'
                    &&
                    <View style={styles.smallAvatar}>
                      {sItem?.type === 'Suit' && <Image src={Images.SUIT} style={styles.img} />}
                    </View>
                  } */}
                    {sItem?.category === 'Ready to Wear'
                      &&
                      <View style={styles.typeLabel}>
                        <Text style={styles.typeName}>{sItem?.category === 'Ready to Wear' ? sItem?.category : sItem?.type}</Text>
                      </View>}
                  </View>
                  <Text style={styles.name}>{sItem?.category !== 'Ready to Wear' ? `${sItem?.type}: ${sItem?.name}` : sItem?.name}</Text>
                  <Text style={styles.code}>{sItem?.fabricCode}</Text>
                </View>
                {sItem?.category === 'Ready to Wear'
                  &&
                  <View style={styles.price}>
                    <Text>{sItem?.qty}</Text>
                    {cuttingFileType === 'order'
                      &&
                      <Text>Rp{priceFormat(sItem?.price)}</Text>
                    }
                  </View>

                }


              </View>
            ))}
          </View>
          :
          <View style={styles.header}>
            {
              item?.name !== 'ALTER'
              &&
              <View style={styles.avatar}>
                <Image source={{ uri: item?.img }} style={styles.img} />
              </View>

            }
            <View style={styles.desc}>
              <View style={styles.type}>
                {item?.category !== 'Ready to Wear'
                  &&
                  <View style={styles.smallAvatar}>
                    {item?.type?.toLowerCase() === 'suit' && <Image src={Images.SUIT_SS} style={styles.img} />}
                    {item?.type?.toLowerCase() === 'trouser' && <Image src={Images.TROUSER_SS} style={styles.img} />}
                    {item?.type?.toLowerCase() === 'shirt' && <Image src={Images.SHIRT_SS} style={styles.img} />}
                  </View>
                }
                <View style={styles.typeLabel}>
                  <Text style={styles.typeName}>{item?.category === 'Ready to Wear' ? item?.category : item?.type}</Text>
                </View>
              </View>
              <Text style={styles.name}>{item?.category !== 'Ready to Wear' ? `${item?.category ? item?.category + ': ' : ''}${item?.name}` : item?.name}</Text>
              <Text style={styles.code}>{item?.fabricCode}</Text>
            </View>
            <View style={styles.price}>
              <Text>{item?.qty}</Text>
              <Text>Rp{priceFormat(item?.price)}</Text>
            </View>
          </View>
        )


        :
        <View style={styles.headers}>
          {size?.items[0]?.category !== 'Ready to Wear'
            &&
            <View style={styles.header}>
              <View style={styles.avatar}>
                <Image src={Images.SUIT} style={styles.img} />
              </View>
              <View style={styles.typeLabelWrapper}>
                <View style={styles.typeLabel}>
                  <Text style={styles.typeName}>{size?.type || size?.category}</Text>
                </View>
              </View>
              <View style={styles.price}>
                <Text>{size?.items[0]?.qty}</Text>
                {cuttingFileType === 'order'
                  &&
                  <Text>Rp{priceFormat(size?.items[0]?.price)}</Text>
                }
              </View>
            </View>
          }
          {size?.items?.map((sItem, i) => (
            <View key={i} style={styles.header}>
              <View style={sItem?.category === 'Ready to Wear' ? styles.avatar : styles.mediumAvatar}>
                {sItem?.img && (
                  <Image src={sItem?.img} style={styles.img} />
                )}
              </View>
              <View style={styles.desc}>
                <View style={styles.type}>
                  {/* {sItem?.category !== 'Ready to Wear'
                    &&
                    <View style={styles.smallAvatar}>
                      {sItem?.type === 'Suit' && <Image src={Images.SUIT} style={styles.img} />}
                    </View>
                  } */}
                  {sItem?.category === 'Ready to Wear'
                    &&
                    <View style={styles.typeLabel}>
                      <Text style={styles.typeName}>{sItem?.category === 'Ready to Wear' ? sItem?.category : sItem?.type}</Text>
                    </View>}
                </View>
                <Text style={styles.name}>{sItem?.category !== 'Ready to Wear' ? `${sItem?.category}: ${sItem?.name}` : sItem?.name}</Text>
                <Text style={styles.code}>{sItem?.fabricCode}</Text>
              </View>
              {sItem?.category === 'Ready to Wear'
                &&
                <View style={styles.price}>
                  <Text>{sItem?.qty}</Text>
                  {cuttingFileType === 'order'
                    &&
                    <Text>Rp{priceFormat(sItem?.price)}</Text>
                  }
                </View>

              }


            </View>
          ))}
        </View>

      }



      {isCuttingFile
        &&
        <View style={styles.sizes}>
          {
            size?.measurements?.map((size, i) => (
              <View key={i} style={styles.size}>
                <Text style={styles.sizeName}>{size?.title || size?.name}</Text>
                <Text style={styles.sizeValue}>{size?.value}</Text>
              </View>
            ))
          }
        </View>
      }

      {cuttingFileType === 'order'
        &&
        <View style={styles.notes}>
          <Text style={styles.notesTitle}>Notes</Text>
          <Text style={styles.notesContent}>{size?.notes}</Text>
        </View>
      }

    </View>
  )
}