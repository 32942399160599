import { API } from "@Config/api";

export function getStocks(type, page, limit, lowLimitPrice, highLimitPrice, startDate, endDate, search, sortBy) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/stock/type?type=${type}&page=${page}&limit=${limit}&lowLimitPrice=${lowLimitPrice}&highLimitPrice=${highLimitPrice}&startDate=${startDate}&endDate=${endDate}&search=${search}&sortBy=${sortBy}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function deleteStocks(codesArr = []) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(`/stock/code?data=${codesArr?.join(';')}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function checkStock(code = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/stock/check`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
        params: { code }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}
export function checkBulkStock(codes = []) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/stock/check-bulk`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
        params: {
          codes: `[${codes?.map(obj => obj
            // ?.replaceAll('/', '%2F')?.replaceAll(' ', '%20')
          )
            ?.join(', ')}]`
        }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}
export function getStock(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/stock/code/${code}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

export function addStock(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/stock`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addBulkStock(file) {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await API.post(`/stock/bulk`,
        formData
        , {
          headers: {
            Authorization: localStorage.getItem('accessToken'),
            'Content-Type': 'multipart/form-data'
          },
        });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function editStock(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/stock/${id}`, new URLSearchParams(payload), {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// export function deliverToCheckpoint(payload) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const { data } = await API.patch('/stock/delivery', payload, {
//         headers: {
//           Authorization: localStorage.getItem('accessToken'),
//         },
//       });

//       resolve(data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

export function deliver(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post('/deliver', payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

