import OrderListLayout from '@Organism/Order/OrderList'
import Styles from './style.module.scss'
import ModalWrapper from '@Atom/ModalWrapper'
import { Suspense, useEffect, useMemo, useState } from 'react'
import OrderDetailsModal from '@Molecule/_modals/DetailsModals/Order'
import OrderFilterModal from '@Molecule/_modals/FilterModals/Order'
import ScheduleSetterModal from '@Molecule/_modals/ScheduleSetterModal'
import ChangeToReadyModal from '@Molecule/_modals/ChangeToReadyModal'
import OrderActionConfirmationModal from '@Molecule/_modals/OrderActionConfirmationModal'
import { Await, useLoaderData } from 'react-router-dom'
import useQuery from '@Hooks/useQuery'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function OrderListPage() {
  const { packageOrderList, packageWorkerList } = useLoaderData()
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null)
  const [showFilter, setShowFilter] = useState(false)
  const [trigger, setTrigger] = useState('')

  const [showChangeToReadySetter, setShowChangeToReadySetter] = useState(false)
  const [showScheduleSetter, setShowScheduleSetter] = useState(false)
  const [orderId, setOrderId] = useState(null)

  // eslint-disable-next-line no-unused-vars
  const [showDetails, setShowDetails] = useState(false)

  const [confirmationType, setConfirmationType] = useState('hold')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)

  const [workers, setWorkers] = useState({
    cutters: [],
    tailors: [],
    couriers: []
  })

  const query = useQuery()
  const user = localStorage?.getItem('user') ? JSON?.parse(localStorage.getItem('user')) : null;
  const role = user?.role
  const detailModalType = useMemo(() => {
    const activeTab = query.get('t')
    switch (activeTab) {
      case 'customer':
        return 'm2'
      case 'item':
        return 'm3'
      default:
        switch (role?.toLowerCase()) {
          case 'director':
            return 'm1'
          case 'office':
            return 'm1'
          default:
            return 'default'
        }
    }
  }, [query, role])

  useEffect(() => {
    setOrderId(selectedOrderDetails?.code || null)
  }, [selectedOrderDetails?.code])

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <OrderListLayout
            setSelectedOrderDetails={setSelectedOrderDetails}
            setShowFilter={setShowFilter}
            setOrderId={setOrderId}
            setShowScheduleSetter={setShowScheduleSetter}
            setShowDetails={setShowDetails}
            workerList={{
              cutters: [],
              tailors: [],
              couriers: []
            }}
            defaultData={{
              "orders": [],
              "page": 0,
              "totalPage": 0,
              'total': 0
            }}
            isLoading
            setWorkers={setWorkers}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        }
      >
        <Await
          resolve={packageOrderList}
          errorElement={
            <AsyncErrorBoundary />
          }
        >
          {(packageOrderList) => (
            <Suspense
              fallback={
                <OrderListLayout
                  setSelectedOrderDetails={setSelectedOrderDetails}
                  setShowFilter={setShowFilter}
                  setOrderId={setOrderId}
                  setShowScheduleSetter={setShowScheduleSetter}
                  setShowDetails={setShowDetails}
                  workerList={{
                    cutters: [],
                    tailors: [],
                    couriers: []
                  }}
                  defaultData={packageOrderList?.data}
                  setWorkers={setWorkers}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  isLoading
                />
              }
            >
              <Await
                resolve={packageWorkerList}
                errorElement={<AsyncErrorBoundary />}
              >
                {(packageWorkerList) => (
                  <OrderListLayout
                    setSelectedOrderDetails={setSelectedOrderDetails}
                    setShowFilter={setShowFilter}
                    setOrderId={setOrderId}
                    setShowScheduleSetter={setShowScheduleSetter}
                    setShowDetails={setShowDetails}
                    defaultData={packageOrderList?.data}
                    workerList={packageWorkerList?.data}
                    setWorkers={setWorkers}
                    trigger={trigger}
                    setTrigger={setTrigger}
                  // setShowChangeToReadySetter={setShowChangeToReadySetter}
                  />
                )}

              </Await>
            </Suspense>


          )}
        </Await>


      </Suspense>
      <div>
        {selectedOrderDetails
          &&
          <ModalWrapper
            desktopPositioning='right'
            mobilePositioning='bottom'
            show={selectedOrderDetails ? true : false}
            consoling={true}
            setShow={() => setSelectedOrderDetails(null)}
          >
            <OrderDetailsModal
              order={selectedOrderDetails}
              type={detailModalType}
              workers={workers}
              setTrigger={setTrigger}
              trigger={trigger}
              handleClose={() => {
                setSelectedOrderDetails(null)
                setShowDetails(false)
              }}
              setShow={setShowDetails}
              onOpenSetSchedule={() => {
                setShowScheduleSetter(true)
                // setShowDetails(false)
              }}
              onOpenHold={() => {
                setConfirmationType('hold')
                setShowActionConfirmation(true)
                // setShowDetails(false)
              }}
              onOpenCancel={() => {
                setConfirmationType('cancel')
                setShowActionConfirmation(true)
                // setShowDetails(false)
              }}
              onOpenResume={() => {
                setConfirmationType('resume')
                setShowActionConfirmation(true)
                // setShowDetails(false)
              }}
            />
          </ModalWrapper>
        }

        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={showFilter}
          setShow={setShowFilter}
        >
          <OrderFilterModal
            handleClose={() => setShowFilter(false)}
            trigger={trigger}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showScheduleSetter}
          setShow={setShowScheduleSetter}
        >
          <ScheduleSetterModal
            handleClose={() => {
              setShowScheduleSetter(false)
              // setShowDetails(true)
            }}
            orderId={orderId}
            workers={workers}
            setTrigger={setTrigger}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showChangeToReadySetter}
          setShow={setShowChangeToReadySetter}
        >
          <ChangeToReadyModal
            handleClose={() => {
              setShowChangeToReadySetter(false)
              // setShowDetails(true)
            }}
            orderId={orderId}
            show={showChangeToReadySetter}
            workers={workers}
            setTrigger={setTrigger}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showActionConfirmation}
          setShow={setShowActionConfirmation}
        >
          <OrderActionConfirmationModal
            handleClose={() => {
              setShowActionConfirmation(false)
              // setShowDetails(true)
            }}
            orderId={orderId}
            type={confirmationType}
            setTrigger={setTrigger}
          // show={showChangeToReadySetter}
          />
        </ModalWrapper>
      </div>

    </div>
  )
}