import Button, { DatePickerButton } from "@Atom/Button";
import ItemTitle from "@Atom/ItemTitle";
import RekhasBG from "@Atom/RekhasBG";
import Tag from "@Atom/Tag";
import useOutsideClick from "@Hooks/useOutsideClick";
import TableSet from "@Molecule/TableSet";
import ItemListPopup from "@Molecule/_popups/ItemListPopup";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import StackedItemInfo from "@Molecule/StackedItemInfo";
import { splitCamelCase } from "@Helpers/splitCamelCase";
import useQuery from "@Hooks/useQuery";
import Avatar from "@Atom/Avatar";
import { fileBaseUrl } from "@Config/api";
import { capitalizeWords } from "@Helpers/capitalizeWords";
import Images from "@Theme/Images";

export default function OrderListLayout({
  setSelectedOrderDetails,
  setShowFilter,
  setOrderId,
  setShowScheduleSetter,
  setShowDetails,
  defaultData,
  workerList,
  setWorkers,
  isLoading,
  trigger,
  setTrigger,
}) {
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [loadingData, setLoadingData] = useState(isLoading);
  const user = localStorage.getItem("user")
    ? JSON?.parse(localStorage.getItem("user"))
    : null;
  const role = user?.role;
  const query = useQuery();
  const typeQuery = query?.get("t") || "transaction";

  useEffect(() => {
    setLoadingData(isLoading);
  }, [isLoading]);

  const handleShowList = useCallback(
    (e, row) => {
      e.stopPropagation();
      if (row?.id !== selectedOrder?.id) {
        setSelectedOrder(row);
      } else {
        setSelectedOrder(null);
      }
    },
    [selectedOrder]
  );

  const { width } = useWindowSize();

  useEffect(() => {
    if (!isLoading) {
      setWorkers(workerList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerList, isLoading]);

  const itemListPopupRef = useRef();
  const itemListPopupOutsideClick = useOutsideClick(itemListPopupRef);

  useEffect(() => {
    // if (selectedOrder && itemListPopupOutsideClick) {
    //   setSelectedOrder(null);
    // }
  }, [itemListPopupOutsideClick, selectedOrder]);

  const handleClickRow = useCallback(
    (row) => {
      setSelectedOrderDetails({ ...row, type: typeQuery });
      setShowDetails(true);
    },
    [setSelectedOrderDetails, setShowDetails, typeQuery]
  );

  const template = useMemo(() => {
    return {
      data: defaultData?.orders?.map((obj) => {
        return {
          ...obj,
          id: obj?._id,
          orderDate: moment(new Date(obj?.orderDate)).format("L"),
          deliverDate: obj?.deliverDate
            ? moment(new Date(obj?.deliverDate)).format("L")
            : "-",
          itemStatus: obj?.status?.replace(" ", ""),
          dueDate: moment(new Date(obj?.dueDate)).format("L"),
          itemsArr: obj?.item?.list?.map((item) => {
            return {
              ...item,
              id: item?._id,
              category: item?.productType,
              measurements: item?.measurement ?
                Object.entries(item?.measurement)?.map((m) => {
                  return {
                    name: m[0],
                    value: m[1] + " cm",
                    title: splitCamelCase(m[0]),
                  };
                }) : [],
              cutter: {
                id: item?.cutterCode,
                name: item?.cutterName,
                img: item?.cutterImg || null,
              },
              tailor: {
                id: item?.tailorCode,
                name: item?.tailorName,
                img: item?.tailorImg || null,
              },
            };
          }),
        };
      }),
      masterTransactionsData: defaultData?.orders?.length
        ? defaultData?.orders?.map((obj) => {
          return {
            ...obj,
            id: obj?._id,
            orderId: obj?.code,
            orderDate: obj?.date
              ? moment(new Date(obj?.date)).format("L")
              : "-",
            customerName: obj?.customer?.name,
            customerPhoneNumber: obj?.customer?.phoneNumber,
            customerAddress: obj?.customer?.address || "-",
            orderStatus: obj?.status?.replaceAll("-", ""),
            salesName: obj?.sales?.name,
            salesProfilePicture: obj?.sales?.profilePicture
              ? fileBaseUrl + obj?.sales?.profilePicture
              : Images.AVA_DEFAULT,
            salesPhoneNumber: obj?.sales?.whatsAppNumber,
            invoiceID: "",
          };
        })
        : [],
      masterCustomersData: defaultData?.orders?.length
        ? defaultData?.orders?.map((obj) => {
          return {
            ...obj,
            ...obj.customer,
            customerName: obj.customer?.name,
            id: obj?._id,
            _id: obj?._id,
            lastOrderDate: moment(obj.lastOrder)?.format("l"),
          };
        })
        : [],
      masterItemsData: defaultData?.orders?.length
        ? defaultData?.orders?.map((obj) => {
          return {
            ...obj,
            id: obj._id,
            orderDate: obj?.orderDate
              ? moment(new Date(obj?.orderDate)).format("L")
              : "-",
            itemType: obj?.type,
            collection: obj?.name,
            customerName: obj?.customerName,
            customerPhoneNumber: obj?.phoneNumber,
            customerAddress: obj?.address || "-",
            orderStatus: obj?.status,
            priorityScore: obj?.priority === "NORMAL" ? 0 : obj?.priority === "SUPER" ? 2 : 1,
            fabricPhotos: obj?.textiles?.map((p) => {
              return {
                ...p,
                id: p?._id,
                name: p?.name,
                url: p?.imageUrl ? fileBaseUrl + p?.imageUrl?.replaceAll(fileBaseUrl, '') : "",
              };
            }),
            label: obj?.type,
            notes: obj?.notes,
            fabrics: obj?.textiles?.map((p) => {
              return {
                ...p,
                id: p?._id,
                type: "",
                fabricType: "",
                fabricName: p?.name,
                fabricCode: p?.code,
                img: p?.imageUrl ? fileBaseUrl + p?.imageUrl : "",
              };
            }),
          };
        })
        : [],
      columns: [
        {
          name: "orderDate",
          label: "Order Date",
          minWidth: width <= 768 ? "108px" : "124px",
        },
        {
          name: "customerName",
          label: "Customer Name",
          // minWidth: '323px',
          // maxWidth: '323px',
          maxWidth: width <= 768 ? "176px" : "323px",
          minWidth: width <= 768 ? "176px" : "323px",
          renderData: (row) => (
            <StackedItemInfo
              title={row?.customerName}
              label={row?.customerPhoneNumber || "-"}
              withoutPicture
              titleWidth={width <= 768 ? "150px" : "100%"}
              renderExtraTopLabel={() => {
                return row?.isNew ? (
                  <div className={Styles.newTag}>New Order</div>
                ) : (
                  <></>
                );
              }}
            />
          ),
        },
        {
          name: "customerAddress",
          label: "Customer Address",
          minWidth: "280px",
          hideOnMobile: true,
        },
        {
          name: "dueDate",
          label: "Due Date",
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles?.dueDate}>
              <div className={Styles.left}>
                <span>{row?.dueDate}</span>
                {row?.currentDueDate && (
                  <Tag
                    type={"blue"}
                    title={row?.currentDueDate}
                    noBullet
                    fixedFontSize={"1.2rem"}
                    fixedPadding={"0.2rem 0.4rem"}
                  />
                )}
              </div>
              <DatePickerButton value={row?.currentDueDate || row?.dueDate} />
            </div>
          ),
        },
        {
          name: "itemsArr",
          label: "Item",
          hideOnMobile: true,
          minWidth: "246px",
          maxWidth: "270px",
          renderData: (row) =>
            row?.itemsArr?.length > 1 ? (
              <div className={Styles.itemsIndicator}>
                <span>{row?.itemsArr?.length} Items</span>
                <Button
                  buttonText="Item List"
                  size="sm"
                  variant="secondary"
                  iconName={
                    selectedOrder && selectedOrder?.id === row?.id
                      ? "chevron-up"
                      : "chevron-down"
                  }
                  iconPlacement="end"
                  borderRadius="4px"
                  fixedPadding={"2px 8px"}
                  withIcon
                  disableActive
                  onClick={(e) => handleShowList(e, row)}
                />
              </div>
            ) : (
              <ItemTitle
                name={row?.itemsArr?.length ? row?.itemsArr[0]?.name : ""}
                category={row?.itemsArr?.length ? row?.itemsArr[0]?.category : ""}
                length={row?.itemsArr?.length ? row?.itemsArr[0]?.length : ""}
                type={row?.itemsArr?.length ? row?.itemsArr[0]?.type : ""}
              />
            ),
          renderPopup: (row) =>
            selectedOrder &&
            selectedOrder?.id === row?.id && (
              <div ref={itemListPopupRef} className={Styles.popupWrapper}>
                <ItemListPopup items={row?.itemsArr} />
              </div>
            ),
          // popupWrapperClassName: Styles.popupWrapper,
          popupPositioning: {
            left: "-100px",
            top: "20px",
          },
        },
        // {
        //   name: 'paymentPercentage',
        //   label: 'Payment',
        //   minWidth: '150px',
        //   hideOnMobile: true,
        //   renderData: (row) => (
        //     <Tag
        //       title={row?.paymentPercentage === 50 ? '50%' : 'Paid Off'}
        //       type={row?.paymentPercentage === 50 ? 'yellow' : 'green'}
        //     />
        //   )
        // },
        {
          name: "itemStatus",
          label: "Item Status",
          minWidth: "209px",
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.itemStatus}>
              <Tag
                type={
                  row.itemStatus === "ONPROCESS"
                    ? "yellow"
                    : row.itemStatus === "DELIVERED "
                      ? "green"
                      : row?.itemStatus === "SCHEDULED"
                        ? "blue"
                        : row?.itemStatus === "READY"
                          ? "black"
                          : "green"
                }
                title={
                  row.itemStatus === "ONPROCESS"
                    ? "On Process"
                    : row?.itemStatus === "DELIVERED "
                      ? "Delivered"
                      : row?.itemStatus === "SCHEDULED"
                        ? `Scheduled: ${row?.deliverDate || "-"}`
                        : row?.itemStatus === "READY"
                          ? "Ready"
                          : "Delivered"
                }
              />
              {/* {row?.itemStatus === 'READY'
                &&
                <Button
                  variant='secondary'
                  size='sm'
                  buttonText='Set Schedule'
                  borderRadius='4px'
                  onClick={(e) => {
                    e.stopPropagation();
                    setOrderId(row?.id)
                    setShowScheduleSetter(true)
                  }}
                />
              } */}
            </div>
          ),
        },
        {
          name: "",
          label: "",
          showOnMobile: true,
          renderData: (row) => (
            <div>
              <Button
                state="icon-only"
                variant="secondary"
                disableActive
                withIcon
                iconName={"list-bullet"}
                size="24"
                fixedPadding={"12px"}
                onClick={() => handleClickRow(row)}
              />
            </div>
          ),
        },
      ],
      masterTransactionsColumns: [
        {
          name: "orderId",
          label: "Order ID",
          minWidth: width <= 768 ? "108px" : "124px",
        },
        {
          name: "invoiceCode",
          label: "Invoice Number",
          minWidth: "280px",
        },
        {
          name: "customerName",
          label: "Customer Name",
          // minWidth: '323px',
          // maxWidth: '323px',
          maxWidth: width <= 768 ? "176px" : "323px",
          minWidth: width <= 768 ? "176px" : "323px",
          renderData: (row) => (
            <StackedItemInfo
              title={row?.customerName}
              label={row?.customerPhoneNumber || "-"}
              withoutPicture
              titleWidth={width <= 768 ? "150px" : "100%"}
              renderExtraTopLabel={() => {
                return row?.isNew ? (
                  <div className={Styles.newTag}>New Order</div>
                ) : (
                  <></>
                );
              }}
            />
          ),
        },
        {
          name: "orderDate",
          label: "Order Date",
          minWidth: "280px",
          hideOnMobile: true,
        },
        {
          name: "orderStatus",
          label: "Order Status",
          minWidth: "209px",
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.itemStatus}>
              <Tag
                type={
                  row?.orderStatus === "ONPROCESS"
                    ? "yellow"
                    : row?.orderStatus === "DONE"
                      ? "green"
                      : row?.orderStatus === "ONHOLD"
                        ? "black"
                        : row?.orderStatus === "CANCELLED"
                          ? "red"
                          : "yellow"
                }
                title={
                  row.orderStatus === "ONPROCESS"
                    ? "On Process"
                    : row?.orderStatus === "DONE"
                      ? "Done"
                      : row?.orderStatus === "ONHOLD"
                        ? "On Hold"
                        : row?.orderStatus === "CANCELLED"
                          ? "Cancelled"
                          : capitalizeWords(row?.status?.replaceAll('-', ' '))
                }
              />
              {/* {row?.itemStatus === 'READY'
                &&
                <Button
                  variant='secondary'
                  size='sm'
                  buttonText='Set Schedule'
                  borderRadius='4px'
                  onClick={(e) => {
                    e.stopPropagation();
                    setOrderId(row?.id)
                    setShowScheduleSetter(true)
                  }}
                />
              } */}
            </div>
          ),
        },
        {
          name: "salesName",
          label: "Salesman",
          hideOnMobile: true,
          renderData: (row) => (
            <StackedItemInfo
              picture={row?.salesProfilePicture}
              title={row?.salesName}
              label={row?.salesPhoneNumber}
              imgRadius="50%"
            />
          ),
        },
        {
          name: "",
          label: "",
          showOnMobile: true,
          renderData: (row) => (
            <div>
              <Button
                state="icon-only"
                variant="secondary"
                disableActive
                withIcon
                iconName={"list-bullet"}
                size="24"
                fixedPadding={"12px"}
                onClick={() => handleClickRow(row)}
              />
            </div>
          ),
        },
      ],
      masterCustomersColumns: [
        {
          name: "customerName",
          label: "Customer Name",
          // minWidth: '323px',
          // maxWidth: '323px',
          maxWidth: width <= 768 ? "176px" : "323px",
          minWidth: width <= 768 ? "176px" : "323px",
          renderData: (row) => (
            <StackedItemInfo
              title={row?.customerName}
              label={row?.customerPhoneNumber || "-"}
              withoutPicture
              titleWidth={width <= 768 ? "150px" : "100%"}
              renderExtraTopLabel={() => {
                return row?.isNew ? (
                  <div className={Styles.newTag}>New Order</div>
                ) : (
                  <></>
                );
              }}
            />
          ),
        },
        {
          name: "lastOrderDate",
          label: "Last Order Date",
          minWidth: "300px",
          hideOnMobile: true,
        },
        {
          name: "orderStatus",
          label: "Status",
          minWidth: "300px",
          renderData: (row) => (
            <div className={Styles.itemStatus}>
              <Tag
                type={
                  row?.orderStatus === "ONPROCESS"
                    ? "yellow"
                    : row?.orderStatus === "DONE"
                      ? "green"
                      : row?.orderStatus === "ONHOLD"
                        ? "black"
                        : row?.orderStatus === "CANCELLED"
                          ? "red"
                          : "yellow"
                }
                title={capitalizeWords(row?.status?.replaceAll('-', ' '))}
              />
            </div>
          ),
          hideOnMobile: true,
        },
        {
          name: '',
          label: '',
          showOnMobile: true,
          align: 'right',
          minWidth: '80px',
          maxWidth: '80px',
          renderData: (row) => (
            // <div>
              <Button
                state='icon-only'
                variant='secondary'
                disableActive
                withIcon
                iconName={'list-bullet'}
                size='24'
                fixedPadding={'12px'}
                onClick={() => handleClickRow(row)}
              />
            // </div>
          )
        }
      ],
      masterItemsColumns: [
        {
          name: "itemType",
          label: "Item Name",
          renderData: (row) => (
            <StackedItemInfo
              title={row?.itemType}
              label={`Collection: ${row?.collection}`}
              // withoutPicture
              iconName={
                row?.itemType?.toLowerCase() === "trouser" ||
                  row?.itemType?.toLowerCase() === "pants"
                  ? "pants"
                  : row?.itemType?.toLowerCase()
              }
              isV2
              noImageV2={row?.status === 'REQUESTED'}
              titleWidth={"auto"}
              renderExtraTopLabel={() => {
                return row?.priorityScore > 0 ? (
                  <Tag
                    type={`p${row?.priorityScore}`}
                    noBullet
                    title={
                      row?.priorityScore === 1
                        ? "Priority"
                        : row?.priorityScore === 2
                          ? "Super Priority"
                          : "SSP"
                    }
                  />
                ) : (
                  <></>
                );
              }}
            />
          ),
        },
        {
          name: "customerName",
          label: "Customer Name",
          maxWidth: width <= 768 ? "176px" : "323px",
          minWidth: width <= 768 ? "176px" : "323px",
          hideOnMobile: true,
          renderData: (row) => (
            <StackedItemInfo
              title={row?.customerName}
              label={row?.customerPhoneNumber || "-"}
              withoutPicture
              titleWidth={width <= 768 ? "150px" : "100%"}
              renderExtraTopLabel={() => {
                return row?.isNew ? (
                  <div className={Styles.newTag}>New Order</div>
                ) : (
                  <></>
                );
              }}
            />
          ),
        },
        {
          name: "status",
          label: "Order Status",
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.itemStatus}>
              <Tag
                type={
                  row?.status?.replaceAll("-", "") === "ONPROCESS"
                    ? "yellow"
                    : row?.status === "DONE" || row?.status === "DELIVERED"
                      ? "green"
                      : row?.status === "ON-HOLD"
                        ? "black"
                        : row?.status === "CANCELLED"
                          ? "red"
                          : "blue"
                }
                title={
                  capitalizeWords(row?.status?.replaceAll('-', ' '))
                  // row.status?.replaceAll("-", "") === "ONPROCESS"
                  //   ? "On Process"
                  //   : row?.status === "DONE" || row?.status === "DELIVERED"
                  //     ? capitalizeWords(row?.status)
                  //     : row?.status === "ONHOLD"
                  //       ? "On Hold"
                  //       : row?.status === "CANCELLED"
                  //         ? "Cancelled"
                  //         : "On Delivery"
                }
              />
              {/* {row?.itemStatus === 'READY'
                &&
                <Button
                  variant='secondary'
                  size='sm'
                  buttonText='Set Schedule'
                  borderRadius='4px'
                  onClick={(e) => {
                    e.stopPropagation();
                    setOrderId(row?.id)
                    setShowScheduleSetter(true)
                  }}
                />
              } */}
            </div>
          ),
        },
        {
          name: "fabric",
          label: "Fabric",
          minWidth: "200px",
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.fabrics}>
              {row?.fabricPhotos?.map((obj, i) => (
                <Avatar key={i} src={obj?.url} size="32px" borderRadius="6px" />
              ))}
            </div>
          ),
        },
        {
          name: '',
          label: '',
          showOnMobile: true,
          align: 'right',
          minWidth: '80px',
          maxWidth: '80px',
          renderData: (row) => (
            // <div>
              <Button
                state='icon-only'
                variant='secondary'
                disableActive
                withIcon
                iconName={'list-bullet'}
                size='24'
                fixedPadding={'12px'}
                onClick={() => handleClickRow(row)}
              />
            // </div>
          )
        }
      ],
    };
  }, [
    defaultData?.orders,
    handleClickRow,
    handleShowList,
    selectedOrder,
    width,
  ]);


  const [parentTabType, setParentTabType] = useState(typeQuery);


  // console.log(typeQuery, 'tab');

  return (
    <RekhasBG maxHeight='980px'>
      <TableSet
        type={role === "DIRECTOR" || role === "OFFICE" ? "order-director" : "order"}
        isLoading={loadingData}
        setIsLoading={setLoadingData}
        data={
          role === "DIRECTOR" || role === "OFFICE"
            ? parentTabType === "transaction" || parentTabType === ""
              ? template?.masterTransactionsData
              : parentTabType === "customer" || parentTabType === ""
                ? template?.masterCustomersData
                : template?.masterItemsData
            : template?.data
        }
        columns={
          role === "DIRECTOR" || role === "OFFICE"
            ? parentTabType === "transaction" || parentTabType === ""
              ? template?.masterTransactionsColumns
              : parentTabType === "customer" || parentTabType === ""
                ? template?.masterCustomersColumns
                : template?.masterItemsColumns
            : template?.columns
        }
        onClickRow={(row) => handleClickRow(row)}
        onClickFilter={() => setShowFilter(true)}
        totalPage={defaultData?.totalPage}
        totalData={defaultData?.total}
        trigger={trigger}
        setTrigger={setTrigger}
        withNumbering={false}
        setParentTabType={setParentTabType}
        withSkeleton
      />
      {/* } */}
    </RekhasBG>
  );
}
