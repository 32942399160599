import ModalHeader from '@Atom/ModalHeader'
import Styles from './style.module.scss'
import InputField from '@Molecule/InputField'
import { useState } from 'react'
import UploadField from '@Molecule/UploadField'
import Button from '@Atom/Button'

export default function AddCategoryModal({
  setShow = () => { }
}) {
  const [name, setName] = useState('')
  const [thumbnail, setThumbnail] = useState(null)

  const handleAdd = () => { }

  return (
    <div className={Styles.container}>
      <ModalHeader
        paddingX='24px'
        paddingY='16px'
        title={'Add Other Category'}
        withIcon
        iconName={'plus'}
      />
      <div className={Styles.content}>
        <InputField
          title={'Category name'}
          value={name}
          setValue={setName}
          placeholder={'Example: Vest'}
        />
        <UploadField
          title={'Thumbnail Image'}
          value={thumbnail}
          setValue={setThumbnail}
          isFullWidth
        />
      </div>
      <div className={Styles.actionsWrapper}>
        <Button
          type='button'
          variant='secondary'
          buttonText={'Cancel'}
          size='md'
          onClick={() => setShow(false)}
        />
        <Button
          type='button'
          variant='primary'
          buttonText={'Add Category'}
          size='md'
          onClick={handleAdd}
        />
      </div>
    </div>
  )
}