/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'
import { useDropzone } from "react-dropzone";
import Images from '@Theme/Images';
import ModalWrapper from '@Atom/ModalWrapper';
import ViewImgModal from '@Molecule/_modals/ViewImgModal';
import Skeleton from 'react-loading-skeleton';
import { fileBaseUrl } from '@Config/api';

export default function SampleCard({
  src,
  setSrc = () => { },
  noDelete = false,
  name = '',
  size = '292px',
  isOverview = false,
  isFullWidth = false,
  disableReplace = false,
  onClickImage,
  isLoading,
  previewOverlayTransparent = false
}) {
  const [file, setFile] = useState(src)
  const [popupImg, setPopupImg] = useState(false)
  const [fileUrl, setFileUrl] = useState(src ? (typeof src === 'object' ? URL.createObjectURL(src) : src) : '')
  const inputRef = useRef()

  const [fileLoading, setFileLoading] = useState(!!fileUrl)

  useEffect(() => {
    if (src && typeof src === 'object') {
      setFileUrl(URL.createObjectURL(src))
    }
  }, [src])


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSrc(file), [file])
  useEffect(() => setFile(src), [src])

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept, fileRejections, acceptedFiles } = useDropzone({
    // onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png',
        //  '.webp'
      ]
    },
    multiple: false,
    noClick: true,
    maxSize: 1048576 * 3
  });

  useEffect(() => {
    fileRejections?.length && console.log('rejected')
  }, [fileRejections])

  useEffect(() => {
    if (acceptedFiles?.length) {
      setFile(acceptedFiles[0])
      setFileUrl(URL.createObjectURL(acceptedFiles[0]))
    }
  }, [acceptedFiles])

  if (isLoading) {
    return (
      <div className={Styles.container}>
        <Skeleton style={{ height: size, aspectRatio: !isFullWidth ? '1' : 'auto', width: isFullWidth ? '100%' : size }} />
        <div className={Styles.top}>
          <Skeleton className={Styles.name} />
          <div className={Styles.actions}>
            <Skeleton className={Styles.replace} enableAnimation={false} />
            <Skeleton className={Styles.delete} enableAnimation={false} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div  {...getRootProps()} className={`${Styles.container} ${file ? Styles.withFile : ''} ${isDragAccept ? Styles.accept : ''}`} style={{ height: size, aspectRatio: !isFullWidth ? '1' : 'auto', width: isFullWidth ? '100%' : size }}>
      <input
        {...getInputProps()}
        ref={inputRef}
        type='file'
        disabled={disableReplace}
        hidden
      />
      {file
        ? <div className={Styles.fileWrapper}>
          {
            fileLoading
            &&
            <div  style={{ height: size, aspectRatio: !isFullWidth ? '1' : 'auto', width: isFullWidth ? '100%' : size }} className={Styles.placeholder}>
              <Skeleton style={{ height: size, aspectRatio: !isFullWidth ? '1' : 'auto', width: isFullWidth ? '100%' : size }} />
            </div>
          }
          {file && fileUrl && typeof fileUrl !== Object &&
            <img
              src={fileUrl}
              alt=''
              onClick={() => {
                if (onClickImage) {
                  onClickImage()
                } else {
                  setPopupImg(true)
                }
              }}
            onLoad={()=> setFileLoading(false)}
            />}
          {name &&
            <div className={Styles.left}>
              {name}
            </div>}
          {
            !disableReplace && <div className={Styles.right}>
              <button onClick={() => inputRef?.current?.click()}>Replace</button>
              {
                !noDelete
                &&
                <button onClick={() => {
                  setSrc(null)
                  setFileUrl('')
                  setFile(null)
                }}><Icon icon={'trash'} size={16} /></button>
              }

            </div>
          }

        </div>
        : (
          isOverview

            ?

            <div className={Styles.emptyOverviewWrapper}>
              <button onClick={() => inputRef?.current?.click()}>
                <Icon icon={'plus'} size={32} />
              </button>
              {name && <div className={Styles.title}>
                {name}
              </div>}

            </div>
            :
            <div className={Styles.uploadWrapper}>
              {name && <div className={Styles.title}>
                {name}
              </div>}

              <img src={Images.CLOUD} alt='' />
              <button onClick={() => inputRef?.current?.click()}>Upload Image</button>
              <span>Or drag image here</span>
              <span>Maximum size of image is 3 MB</span>
            </div>
        )
      }
      <div
        style={{ visibility: popupImg ? 'visible' : 'hidden', zIndex: 'inherit' }}
      >
        <ModalWrapper
          show={popupImg}
          setShow={setPopupImg}
          isOverlayTransparent={previewOverlayTransparent}
        >
          <ViewImgModal
            img={fileUrl && typeof fileUrl === String
              ? (fileUrl?.includes('blob') ? fileUrl : fileBaseUrl + fileUrl?.replaceAll(fileBaseUrl, ''))
              : fileUrl}
            setShow={setPopupImg}
          />
        </ModalWrapper>
      </div>


    </div>
  )
}