import { API } from "@Config/api";

export function getInvoices(page, limit, search, issueAtStart = '', issueAtEnd = '', paymentMethod = '', status = '', payDateStart = '', payDateEnd = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/invoice?page=${page}&limit=${limit}&search=${search}&issueAtStart=${issueAtStart}&issueAtEnd=${issueAtEnd}&paymentMethod=${paymentMethod}&status=${status}&payDateStart=${payDateStart}&payDateEnd=${payDateEnd}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function getInvoice(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/invoice/generate/${code}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (err) {
      reject(err)
    }
  })
}

export function updatePayment(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/invoice/${id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (err) {
      reject(err)
    }
  })
}
export function createInvoice(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/invoice`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (err) {
      reject(err)
    }
  })
}

export function createManualInvoice(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/invoice/create-manual`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (err) {
      reject(err)
    }
  })
}