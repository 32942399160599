import { stockTypes } from '@Assets/data/stockTypes'
import Avatar from '@Atom/Avatar'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import Input from '@Atom/Input'
import Searchbar from '@Atom/Searchbar'
import TextArea from '@Atom/TextArea'
import { fileBaseUrl } from '@Config/api'
import { makeRandomString } from '@Helpers/makeRandomString'
import { omitKeys } from '@Helpers/omitKey'
import { splitCamelCase } from '@Helpers/splitCamelCase'
import useOutsideClick from '@Hooks/useOutsideClick'
import useQuery from '@Hooks/useQuery'
import GroupedAvatars from '@Molecule/GroupedAvatars'
import InputField from '@Molecule/InputField'
import StackedItemInfo from '@Molecule/StackedItemInfo'
import TableSet from '@Molecule/TableSet'
import UploadField from '@Molecule/UploadField'
import { editCheckpoint, getCheckpoint } from '@Services/checkpoint'
import Images from '@Theme/Images'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function CheckpointListLayout({
  defaultData,
  isLoading,
  trigger,
  setTrigger = () => { },
  workerList = [],
  setShowFilter,
  setShowAdd,
}) {
  const navigate = useNavigate()
  const query = useQuery()
  const page = query.get('p')

  const [loadingData, setLoadingData] = useState(isLoading)

  useEffect(() => {
    setLoadingData(isLoading)
  }, [isLoading])

  const withInOut = useMemo(() => {
    if (query?.get('df') && query?.get('dt')) {
      return true
    }
    return false
  }, [query])


  const [checkpointData, setCheckpointData] = useState()
  const [onFetching, setOnFetching] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [editMode, setEditMode] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null)

  const itemListPopupRef = useRef()
  const itemListPopupOutsideClick = useOutsideClick(itemListPopupRef);


  useEffect(() => {
    if (selectedCheckpoint && itemListPopupOutsideClick) {
      setSelectedCheckpoint(null);
    }
  }, [itemListPopupOutsideClick, selectedCheckpoint])

  const [activeCheckpoint, setActiveCheckpoint] = useState(null)

  const checkpointMasterTemplate = useMemo(() => {
    return {
      checkpoints: defaultData?.checkpoints?.length ? defaultData?.checkpoints?.map(obj => {
        return {
          ...obj,
          id: obj?.code,
          name: obj?.name,
          phoneNumber: obj?.phoneNumber,
          type: obj?.type,
          address: obj?.address,
          picName: obj?.user?.fullName,
          picImg: obj?.user?.imageUrl ? fileBaseUrl + obj?.user?.imageUrl : Images.AVA_DEFAULT,
          picPhoneNumber: obj?.user?.whatsAppNumber,
          stocksArr: obj?.stock?.map(stock => {
            return {
              ...stock,
              name: stock?.name,
              category: stock?.type,
              length: '-',
              type: stock?.category,
              measurements: stock ? Object.entries(stock?.size)?.map(m => { return { name: m[0], value: m[1] + ' cm', title: splitCamelCase(m[0]) } }) : []
            }
          })
        }
      }) : []
      ,
      data:
        checkpointData?.stock?.length
          ?
          checkpointData?.stock?.map((obj) => {
            return {
              ...obj,
              id: obj?._id,
              code: obj?.productCode,
              name: obj?.name,
              imageUrl: obj?.images?.length ? (obj?.images?.find(m => m?.imageDescription === 'MAIN')?.url || obj?.images[0]?.url) : '',
              category: stockTypes?.find((t) => t?.query === obj?.type)?.title,
              qty: obj?.qty,
              fabricCode: obj?.fabric,
              imgCollections: obj?.images?.map(obj => fileBaseUrl + obj?.url),
              store: {
                code: activeCheckpoint?.code,
                name: activeCheckpoint?.name,
                type: activeCheckpoint?.type,
                phoneNumber: activeCheckpoint?.phone,
                address: activeCheckpoint?.address
              }

              // stock: obj?.stock?.map((s) => {
              //   return {
              //     ...s,
              //     id: s?._id,
              //     name: s?.name,
              //     category: stockTypes?.find((t) => t?.query === s?.type)?.title,
              //     code: s?.productCode,
              //     qty: s?.qty
              //   }
              // })
            }
          })
          :
          []
      ,
      columns: [
        {
          name: 'name',
          label: 'Product Name',
          maxWidth: '283px',
          minWidth: '283px',
          renderData: (row) => (
            <StackedItemInfo
              title={row?.name}
              label={row?.category}
              picture={row?.imageUrl ? fileBaseUrl + row?.imageUrl : ''}
              maxWidth='283px'
              withIcon
              iconName={'photo'}
              iconSize='50px'
            />
          )
        },
        {
          name: 'code',
          label: 'Product Code',
          minWidth: '224px',
          maxWidth: '224px',
        },
        {
          name: 'fabricCode',
          label: 'Fabric Code',
          minWidth: '190px',
          maxWidth: '190px',
        },
        {
          name: 'qty',
          label: 'Qty',
          align: 'center',
        },
        {
          name: 'in',
          label: 'In',
          align: 'center',
          renderData: (row) => (
            <div className={Styles.stat}>
              <span>{row?.in || 0}</span>
              <Icon icon={'chevron-double-up'} size={'20'} color={'#52E41E'} />
            </div>
          )
        },
        {
          name: 'out',
          label: 'Out',
          align: 'center',
          renderData: (row) => (
            <div className={Styles.stat}>
              <span>{row?.out || 0}</span>
              <Icon icon={'chevron-double-down'} size={'20'} color={'#DD0B0B'} />
            </div>
          )
        },
        // {
        //   name: 'description',
        //   label: 'Description',
        //   minWidth: '200px',
        //   maxWidth: '200px',
        // },
        {
          name: 'price',
          label: 'Price',
          align: 'right',
        },
        {
          name: 'createdAt',
          label: 'Date Added',
          minWidth: '124px',
          renderData: (row) => (
            <span>
              {row?.createdAt ? moment(new Date(row?.createdAt))?.format('ll') : '-'}
            </span>
          )
        },
        {
          name: 'image',
          label: 'Image',
          renderData: (row) => (
            (row?.imgCollections && row?.imgCollections?.length) ?
              <GroupedAvatars
                srcArr={row?.imgCollections}
                borderRadius='4px'
              /> : <>no image</>
          )
        }
      ]
    }
  }, [activeCheckpoint?.address, activeCheckpoint?.code, activeCheckpoint?.name, activeCheckpoint?.phone, activeCheckpoint?.type, checkpointData?.stock, defaultData?.checkpoints])

  useEffect(() => {
    if (!activeCheckpoint) {
      setActiveCheckpoint(checkpointMasterTemplate?.checkpoints[0])
    }
  }, [activeCheckpoint, checkpointMasterTemplate?.checkpoints])

  useEffect(() => {
    if (checkpointMasterTemplate?.checkpoints?.length && query?.get('cc' === 'code')) {
      setActiveCheckpoint(checkpointMasterTemplate?.checkpoints[0])
    }
  }, [checkpointMasterTemplate?.checkpoints, query])

  useEffect(() => {
    const priceFrom = query.get("pf") || "";
    const priceTo = query.get("pt") || "";
    const dateFrom = query.get("df") || "";
    const dateTo = query.get("dt") || "";

    const convertDate = (str) => {
      const arr = str?.split("/");
      return [arr[2], arr[1], arr[0]]?.join("-");
    }

    const dfStr = dateFrom ? convertDate(dateFrom) : "";
    const dtStr = dateFrom ? convertDate(dateTo) : "";
    const fetchData = async () => {
      if (activeCheckpoint) {
        setOnFetching(true)
        const { data } = await getCheckpoint(activeCheckpoint?.code, page ?? 1, 10, query.get('t'), query.get('s'), dfStr, dtStr, priceFrom, priceTo)
        setCheckpointData(data)
        setOnFetching(false)
      }
    }

    try {
      fetchData()

      //  setCheckpointData(data)
    } catch (err) {
      console.log(err)
    }
  }, [activeCheckpoint, activeCheckpoint?.code, page, query])

  const [expandCheckpoints, setExpandCheckpoints] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')

  const filteredCheckpoints = useMemo(() => {
    return checkpointMasterTemplate?.checkpoints?.filter(obj => obj?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
  }, [checkpointMasterTemplate, searchTerm])

  const [form, setForm] = useState({
    image: activeCheckpoint?.imageUrl ? fileBaseUrl + activeCheckpoint?.imageUrl : '',
    name: activeCheckpoint?.name,
    phoneNumber: activeCheckpoint?.phoneNumber ? activeCheckpoint?.phoneNumber?.slice(2, activeCheckpoint?.phoneNumber?.length) : '',
    address: activeCheckpoint?.address,
    picCode: activeCheckpoint?.picCode,
    picName: activeCheckpoint?.picName,
    type: activeCheckpoint?.type
  })

  const disableSave = useMemo(() => {
    // if (!isObjectAllFilled(omitKeys(
    //   [
    //     'picture',
    //     'type'
    //   ]
    //   , form)) || isSubmitting) {
    //   return true
    // } return false
    return isSubmitting
  }, [isSubmitting])

  const handleSave = async () => {
    try {
      setIsSubmitting(true)
      await editCheckpoint(omitKeys([
        // 'picture',
        'picName',
      ], {
        ...form,
        phoneNumber: '62' + form?.phoneNumber,
        image: form?.image instanceof File ? form?.image : form?.image?.replaceAll(fileBaseUrl, '')
      }), activeCheckpoint?.code)
      setIsSubmitting(false)
      setEditMode(false)
      setTrigger(makeRandomString(5))
      setActiveCheckpoint(prev => {
        return {
          ...prev,
          ...omitKeys([
            // 'image'
          ], form),
          phoneNumber: '62' + form?.phoneNumber
        }
      })
      // window.location.reload()
    } catch (err) {
      console.log(err)
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    setForm({
      image: activeCheckpoint?.imageUrl ? fileBaseUrl + activeCheckpoint?.imageUrl : '',
      name: activeCheckpoint?.name,
      phoneNumber: activeCheckpoint?.phoneNumber ? activeCheckpoint?.phoneNumber?.slice(2, activeCheckpoint?.phoneNumber?.length) : '',
      address: activeCheckpoint?.address,
      picCode: activeCheckpoint?.picCode,
      picName: activeCheckpoint?.picName,
      type: activeCheckpoint?.type
    })
  }, [activeCheckpoint, editMode])

  const handleChangeForm = (name, newVal) => {
    setForm(prev => {
      return {
        ...prev,
        [name]: newVal
      }
    })
  }

  const detailsTemplate = useMemo(() => {
    return [
      {
        name: 'image',
        label: '',
        component:
          <Avatar
            src={(activeCheckpoint?.imageUrl ? fileBaseUrl + activeCheckpoint?.imageUrl : '')}
            size='100%'
            customHeight='200px'
            borderRadius='6px'
            aspectRatio={'unset'}
            withIcon
            iconName={'photo'}
            iconSize='50px'
          />,
        editComponent:
          <UploadField
            value={form?.image}
            setValue={(newVal) => {
              handleChangeForm('image', newVal)
            }}
            size='200px'
            borderRadius='6px'
            withIcon
            iconName={'photo'}
            iconSize='50px'
            isFullWidth
          />
      },
      {
        name: 'name',
        label: 'Nama Toko',
        editComponent:
          <Input
            value={form?.name}
            setValue={(newVal) => handleChangeForm('name', newVal)}

          />
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        editComponent:
          <Input
            value={form?.phoneNumber}
            setValue={(newVal) => handleChangeForm('phoneNumber', newVal)}
            type='tel'
            placeholder={'Enter phone number'}
          />
      },
      {
        name: 'address',
        label: 'Address',
        editComponent:
          <TextArea
            value={form?.address}
            setValue={(newVal) => handleChangeForm('address', newVal)}
            placeholder='Enter address'
          />
      },
      {
        name: 'picName',
        component:
          <StackedItemInfo
            picture={activeCheckpoint?.picProfilePicture || Images.AVA_DEFAULT}
            imgRadius='50%'
            imgSize='24px'
            title={activeCheckpoint?.picName || '-'}
            titleFontSize='16px'
          />,
        editComponent:
          <InputField
            title={'Person in Charge'}
            isDropdown={true}
            dropdownOptions={workerList?.couriers?.map(obj => obj?.name)}
            value={form?.picName}
            setValue={(newVal) => {
              handleChangeForm('picName', newVal)
              handleChangeForm('picCode', workerList?.couriers?.find(obj => obj?.name === newVal)?.code)
            }}
            placeholder={'Select pic'}
          />

      }
    ]
  }, [activeCheckpoint?.imageUrl, activeCheckpoint?.picName, activeCheckpoint?.picProfilePicture, form?.address, form?.image, form?.name, form?.phoneNumber, form?.picName, workerList?.couriers])

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Checkpoint</h3>
        <div className={Styles.checkpointIndicator} onClick={() => setExpandCheckpoints(!expandCheckpoints)}>
          <Avatar src={activeCheckpoint?.imageUrl ? fileBaseUrl + activeCheckpoint?.imageUrl : ''} size='24px' borderRadius='6px' />
          <span>{activeCheckpoint?.name || '-'}</span>
          <Icon icon={expandCheckpoints ? 'chevron-up' : 'chevron-down'} size={24} />
          {expandCheckpoints
            &&
            <div className={Styles.checkpointBox} onClick={(e) => e?.stopPropagation()}>
              <Searchbar
                value={searchTerm}
                setValue={setSearchTerm}
                placeholder='Search for checkpoint'
                width='100%'
                customClassName={Styles.searchbar}
                adornmentPositioning={{ top: '6px', left: '8px' }}
              />
              <div className={Styles.checkpoints}>
                {filteredCheckpoints?.map((each, i) => (
                  <div key={i} className={Styles.checkpoint} onClick={() => {
                    setActiveCheckpoint(each)
                    setExpandCheckpoints(false)
                  }}>
                    <Avatar src={each?.imageUrl ? fileBaseUrl + each?.imageUrl : ''} size='24px' borderRadius='6px' />
                    <span>{each?.name}</span>
                  </div>
                ))}
                {!filteredCheckpoints?.length
                  &&
                  <span className={Styles.noOption}>no option</span>
                }
              </div>
            </div>

          }
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.left}>
          <div className={Styles.cardHeader}>
            <Icon icon={'queue-list'} size={32} />
            <span>Details</span>
            <button onClick={() => setEditMode(!editMode)}>
              <Icon icon={!editMode ? 'pencil' : 'x-mark'} size={24} />
            </button>
          </div>
          <div className={Styles.cardContent}>
            {
              detailsTemplate?.map((each, i) => (
                <div
                  key={i}
                  className={Styles.each}
                >
                  {each?.label && <span>{each?.label}</span>}
                  {!editMode
                    ?
                    each?.component ||
                    <div>{activeCheckpoint ? activeCheckpoint[each?.name] : '-'}</div>
                    : each?.editComponent ||
                    <div>{activeCheckpoint ? activeCheckpoint[each?.name] : '-'}</div>
                  }
                </div>
              ))
            }
          </div>
          {
            editMode
            &&
            <div className={Styles.action}>
              <Button
                buttonText={isSubmitting ? 'Saving...' : 'Save'}
                disabled={disableSave}
                size='lg'
                onClick={handleSave}
              />
            </div>
          }
        </div>
        {/* <div className={Styles.right}> */}
        <TableSet
          type='stock-store'
          data={checkpointMasterTemplate?.data || []}
          columns={withInOut ? checkpointMasterTemplate?.columns : checkpointMasterTemplate?.columns?.filter(obj => obj?.name !== 'in' && obj?.name !== 'out')}
          noAdd
          // onClickAdd={() => setShowAdd(true)}
          onClickRow={(row) => navigate(`/stock/overview/${row?.code?.replaceAll('/', '::')}?t=${row?.type?.toLowerCase()}`)}
          onClickFilter={() => setShowFilter(true)}
          // onClickAdd={() => setShowAdd(true)}
          // deliverSync={defaultSync}
          trigger={trigger}
          setTrigger={setTrigger}
          totalPage={checkpointData?.totalPage || 0}
          totalData={checkpointData?.total || 0}
          additionalQuery={`&cc=${activeCheckpoint?.code || 'code'}`}
          // deliverSync={{ cps: checkpointMasterTemplate?.checkpoints }}
          deliverSync={{ ...workerList, cps: defaultData?.checkpoints }}
          isLoading={loadingData || onFetching || query.get('cc') === 'code'}
          setIsLoading={setLoadingData}
          withSkeleton
        />

        {/* </div> */}
      </div>
    </div>
  )
}