export const sizingTypes = [
  {
    name: 'length',
    title: 'Length'
  },
  {
    name: 'waist',
    title: 'Waist'
  },
  {
    name: 'chest',
    title: 'Chest'
  },
  {
    name: 'collar',
    title: 'Collar'
  },
  {
    name: 'shoulders',
    title: 'Shoulder'
  },
  {
    name: 'sleeveLength',
    title: 'Sleeve Length'
  },
  {
    name: 'upperSleeveRim',
    title: 'Upper Sleeve Rim'
  },
  {
    name: 'lowerSleeveRim',
    title: 'Lower Sleeve Rim'
  },
  {
    name: 'thigh',
    title: 'Thigh'
  },
  {
    name: 'knee',
    title: 'Knee'
  },
  {
    name: 'foot',
    title: 'Foot'
  },
  {
    name: 'kriss',
    title: 'Kriss'
  },
  {
    name: 'hip',
    title: 'Hip'
  },
  {
    name: 'cuff',
    title: 'Cuff'
  },
  {
    name: 'armLength',
    title: 'Arm Length'
  },
]

export const suitSizes = [
  {
    name: 'neck',
    title: 'Neck'
  },
  {
    name: 'chest',
    title: 'Chest'
  },
  {
    name: 'waist',
    title: 'Waist'
  },
  {
    name: 'hip',
    title: 'Hip'
  },
  {
    name: 'shoulder',
    title: 'Shoulder Width'
  },
  {
    name: 'sleeveLength',
    title: 'Sleeve Length'
  },
  {
    name: 'armHole',
    title: 'Arm Hole'
  },
  {
    name: 'sleeveCircumference',
    title: 'Sleeve Circumference'
  },
  {
    name: 'length',
    title: 'Jacket Length'
  },
  {
    name: 'backWidth',
    title: 'Back Width'
  },
]

export const shirtSizes = [
  {
    name: 'neck',
    title: 'Neck'
  },
  {
    name: 'chest',
    title: 'Chest'
  },
  {
    name: 'waist',
    title: 'Waist'
  },
  {
    name: 'hip',
    title: 'Hip'
  },
  {
    name: 'shoulder',
    title: 'Shoulder Width'
  },
  {
    name: 'sleeveLength',
    title: 'Sleeve Length'
  },
  {
    name: 'armHole',
    title: 'Arm Hole'
  }
]

export const trouserSizes = [
  {
    name: 'waist',
    title: 'Waist'
  },
  {
    name: 'hip',
    title: 'Hip'
  },
  {
    name: 'outseam',
    title: 'Outseam'
  },
  {
    name: 'inseam',
    title: 'Inseam'
  },
  {
    name: 'thigh',
    title: 'Thigh'
  },
  {
    name: 'knee',
    title: 'Knee'
  },
  {
    name: 'legOpening',
    title: 'Leg Opening'
  },
  {
    name: 'rise',
    title: 'Rise'
  }
]
