import Button from '@Atom/Button'
import Styles from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import Avatar from '@Atom/Avatar'
import Icon from '@Atom/Icon'
import StackedItemInfo from '@Molecule/StackedItemInfo'
import Images from '@Theme/Images'
import moment from 'moment'
import GroupedAvatars from '@Molecule/GroupedAvatars'
import TableSet from '@Molecule/TableSet'
import ModalWrapper from '@Atom/ModalWrapper'
import StockFilterModal from '@Molecule/_modals/FilterModals/Stock'
import { fileBaseUrl } from '@Config/api'
import { priceFormat } from '@Helpers/priceFormat'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function CheckpointOverviewLayout({
  isLoading,
  defaultData,
  defaultSync,
  trigger,
  setTrigger
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    setTrigger(makeRandomString(5))
  }, [setTrigger, showFilter])



  const details = useMemo(() => {
    return {
      name: defaultData?.name,
      img: defaultData?.imageUrl ? fileBaseUrl + defaultData?.imageUrl : null,
      phoneNumber: defaultData?.phoneNumber || '-',
      address: defaultData?.address,
      picName: defaultData?.user?.fullName,
      picProfilePicture: defaultData?.user?.imageUrl ? fileBaseUrl + defaultData?.user?.imageUrl : Images.AVA_DEFAULT
    }
  }, [defaultData])

  const detailsTemplate = useMemo(() => {
    return [
      {
        name: 'img',
        label: null,
        component: <Avatar
          src={details?.img}
          size='48px'
          borderRadius='6px'
          alt=''
        />
      },
      {
        name: 'name',
        label: 'Nama Toko'
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number'
      },
      {
        name: 'address',
        label: 'Address'
      },
      {
        name: 'picName',
        label: 'PIC',
        component:
          <div className={Styles.pic}>
            <Avatar src={details?.picProfilePicture || Images.AVA_DEFAULT} size='24px' />
            <span>{details?.picName}</span>
          </div>
      }
    ]
  }, [details])

  const stockTemplate = useMemo(() => {
    return ({
      data: defaultData?.stock?.map(obj => {
        return {
          ...obj,
          id: obj?._id,
          name: obj?.name,
          category: obj?.type,
          code: obj?.productCode,
          fabricCode: obj?.fabric,
          qty: obj?.qty,
          description: obj?.description,
          price: priceFormat(obj?.price),
          createdAt: moment(new Date(obj?.createdAt)).format('L'),
          img: obj?.images?.length ?  obj?.images[0]?.url : null,
          imgCollections: obj?.images?.map(img => { return fileBaseUrl + img?.url })
        }
      }),
      columns: [
        {
          name: 'name',
          label: 'Product Name',
          maxWidth: '283px',
          minWidth: '283px',
          renderData: (row) => (
            <StackedItemInfo
              title={row?.name}
              label={row?.category}
              picture={row?.img ? fileBaseUrl + row?.img : ''}
              maxWidth='283px'
            />
          )
        },
        {
          name: 'code',
          label: 'Product Code',
          minWidth: '224px',
          maxWidth: '224px',
        },
        {
          name: 'fabricCode',
          label: 'Fabric Code',
          minWidth: '190px',
          maxWidth: '190px',
        },
        {
          name: 'qty',
          label: 'Qty',
          align: 'center',
        },
        // {
        //   name: 'description',
        //   label: 'Description',
        //   minWidth: '200px',
        //   maxWidth: '200px',
        // },
        {
          name: 'price',
          label: 'Price',
          align: 'right',
        },
        {
          name: 'createdAt',
          label: 'Date Added',
          minWidth: '124px'
        },
        {
          name: 'image',
          label: 'Image',
          renderData: (row) => (
            (row?.imgCollections && row?.imgCollections?.length) ?
              <GroupedAvatars
                srcArr={row?.imgCollections}
                borderRadius='4px'
              /> : <>no image</>
          )
        }
      ]
    })
  }, [defaultData?.stock])

  if (isLoading) {
    return <>loading</>
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Button
          variant='tertiary'
          size='lg'
          buttonText='Back to Table'
          disableActive
          withIcon
          iconName={'chevron-left'}
          iconPlacement='start'
          iconSize='20'
          fixedPadding={'0px 0px'}
          onClick={() => navigate('/checkpoint', { replace: true })}
        />
        <h1>Checkpoint Overview</h1>
      </div>
      <div className={Styles.content}>
        <div className={Styles.details}>
          <div className={Styles.dHeader}>
            <Icon
              icon={'queue-list'}
              size={32}
            />
            <span>Details</span>
            <Icon
              icon={'pencil'}
              size={24}
            />
          </div>
          <div className={Styles.dContent}>
            {
              detailsTemplate?.map((each, i) => (
                <div key={i}>
                  <span>{each?.label}</span>
                  <span>{each?.component || details[each?.name]}</span>
                </div>
              ))
            }
          </div>
        </div>
        <div className={Styles.stocks}>
          <TableSet
            type='stock-store'
            data={stockTemplate?.data}
            columns={stockTemplate?.columns}
            noAdd
            onClickRow={(row) => navigate(`${pathname}/stock/${row.code}?t=${row?.type?.toLowerCase()}`)}
            onClickFilter={() => setShowFilter(true)}
          // onClickAdd={() => setShowAdd(true)}
          deliverSync={defaultSync}
          trigger={trigger}
          setTrigger={setTrigger}
          />
        </div>
      </div>
      <ModalWrapper
        desktopPositioning='right'
        mobilePositioning='bottom'
        show={showFilter}>
        <StockFilterModal
          handleClose={() => {
            setShowFilter(false)
            setTrigger(makeRandomString(5))
          }}
          trigger={trigger}
        />
      </ModalWrapper>
    </div>
  )
}