import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function EditFormTooltip({
  title = 'Assign Master & Tukang',
  descComponent,
  desc = `Before updating your status, please make sure to assign Master & Tukang First`
}) {

  return (
    <div className={Styles.container}>
      <h3>{title}</h3>
      <Icon icon={'pencil'} size={64} />
      {descComponent || <p>{desc}</p> }
     
    </div>
  )
}