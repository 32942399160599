import { stockTypes } from '@Assets/data/stockTypes'
import Button from '@Atom/Button'
import ModalHeader from '@Atom/ModalHeader'
import { fileBaseUrl } from '@Config/api'
import { isObjectAllFilled } from '@Helpers/isObjectAllFilled'
import { makeRandomString } from '@Helpers/makeRandomString'
import { omitKey, omitKeys } from '@Helpers/omitKey'
import useQuery from '@Hooks/useQuery'
import InputField from '@Molecule/InputField'
import ItemsQuantityAdjusterField from '@Molecule/ItemsQuantityAdjusterField'
import TextAreaField from '@Molecule/TextAreaField'
import { getCustomerList } from '@Services/customer'
import { deliver } from '@Services/stock'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'

export default function DeliveryModal({
  data = [],
  setShow = () => { },
  deliverSync,
  setTrigger,
}) {

  const query = useQuery()

  const type = query.get('t') || ''

  const [checkPoint, setCheckPoint] = useState('')
  const [customer, setCustomer] = useState('')
  const [customers, setCustomers] = useState([])

  const fetchCustomers = useCallback(async () => {
    try {
      const { data } = await getCustomerList()
      setCustomers(data?.map(obj => {
        return {
          ...obj,
          id: obj?.code,
          name: obj?.name
        }
      }))
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    fetchCustomers()
  }, [fetchCustomers])

  // const customers = useMemo(() => {
  //   return [
  //     {
  //       id: 1,
  //       name: 'Budi Sunyoto'
  //     },
  //     {
  //       id: 2,
  //       name: 'Rinto Sunardi'
  //     },
  //     {
  //       id: 3,
  //       name: ''
  //     }
  //   ]
  // }, [])


  const [isSubmitting, setIsSubmitting] = useState(false)

  const [form, setForm] = useState({
    type: 'CHECKPOINT',
    checkPointId: '',
    customerId: '',
    deliveryDate: '',
    pickupDate: '',
    items: data?.map(obj => {
      return {
        ...obj,
        id: obj?.id,
        code: obj?.code,
        name: obj?.name,
        maxQty: obj?.qty,
        img: obj?.img ? fileBaseUrl + obj?.img : null,
        qty: 1
      }
    }),
    driverId: '',
    note: ''
  })

  const refetchForm = useCallback(() => {
    setForm(f => ({
      ...f,
      type: 'CHECKPOINT',
      checkPointId: '',
      customerId: '',
      deliveryDate: '',
      items: data?.map(obj => {
        return {
          ...obj,
          id: obj?.id,
          code: obj?.code,
          name: obj?.name,
          maxQty: obj?.qty,
          img: obj?.img ? fileBaseUrl + obj?.img : null,
          qty: 1
        }
      }),
      driverId: '',
      note: ''
    }))
    setCheckPoint('')
    setCustomer('')
    setDriver('')
  }, [data])

  useEffect(() => {
    refetchForm()
  }, [refetchForm])

  useEffect(() => {
    handleChange('', 'checkPointId')
    handleChange('', 'customerId')
  }, [form?.type])

  const formattedForm = useMemo(() => {
    if (form?.type?.toLowerCase() === 'checkpoint') {
      return {
        type: 'CHECKPOINT',
        isPriority: 'NORMAL',
        from: {
          code: data[0]?.store?.code,
          name: data[0]?.store?.name,
          type: data[0]?.store?.type,
          phone: data[0]?.store?.phoneNumber,
          address: data[0]?.store?.address
        },
        to: {
          code: deliverSync?.cps?.find(obj => obj?.name === checkPoint)?.code,
          name: checkPoint,
          type: deliverSync?.cps?.find(obj => obj?.name === checkPoint)?.type,
          phone: deliverSync?.cps?.find(obj => obj?.name === checkPoint)?.phone,
          address: deliverSync?.cps?.find(obj => obj?.name === checkPoint)?.address,
        },
        scheduleAt: form?.deliveryDate ? moment(new Date(form?.deliveryDate))?.format('YYYY/MM/DD') : '',
        note: form?.note,
        items: form?.items?.map(item => {
          return {
            name: item?.name,
            code: item?.productCode,
            type: item?.type,
            fabric: item?.fabric,
            imageUrl: item?.productImages?.length ? item?.productImages[0]?.url : '',
            qty: item?.qty,
            pattern: item?.size
          }
        }),
        courierCode: form?.driverId
      }
    }


    return {
      type: 'ITEM',
      id: null,
      isPriority: 'NORMAL',
      from: {
        code: data[0]?.store?.code,
        name: data[0]?.store?.name,
        type: data[0]?.store?.type,
        phone: data[0]?.store?.phoneNumber,
        address: data[0]?.store?.address
      },
      to: {
        code: customers?.find(obj => obj?.name === customer)?.id,
        name: customer,
        type: 'CUSTOMER',
        phone: customers?.find(obj => obj?.name === customer)?.phoneNumber,
        address: customers?.find(obj => obj?.name === customer)?.address,
      },
      scheduleAt: form?.deliveryDate ? moment(new Date(form?.deliveryDate))?.format('YYYY/MM/DD') : '',
      note: form?.note,
      items: form?.items?.map(item => {
        return {
          name: item?.name,
          // code: item?.productCode,
          type: item?.type,
          orderID: null,
          notes: null,
          // fabric: item?.fabric,
          imageUrl: item?.productImages?.length ? item?.productImages[0]?.url : '',
          // qty: item?.qty,
          pattern: item?.size,
          textile: [
            {
              name: item?.name,
              code: item?.productCode,
              type: item?.type,
              fabric: item?.fabric,
              imageUrl: item?.img?.replaceAll(fileBaseUrl, ''),
              qty: item?.qty,
            }
          ]
        }
      }),
      courierCode: form?.driverId
    }

  }, [checkPoint, customer, customers, data, deliverSync?.cps, form?.deliveryDate, form?.driverId, form?.items, form?.note, form?.type])

  const disableSubmit = useMemo(() => {
    if (form?.type?.toLowerCase() === 'checkpoint') {
      return !isObjectAllFilled(omitKey('customerId', (omitKeys(['notes', 'note'], formattedForm)))) || isSubmitting
    }

    return !isObjectAllFilled(omitKey('checkpointCode', (omitKeys(['notes', 'id', 'note'], formattedForm)))) || isSubmitting

  }, [form?.type, formattedForm, isSubmitting])


  const handleChange = (newVal, name) => {
    setForm(f => ({
      ...f,
      [name]: newVal
    }))
  }



  // useEffect(() => {
  //   if (checkPoint) {
  //     setForm(f => ({
  //       ...f,
  //       checkPointId: deliverSync?.cps?.find(obj => obj.name === checkPoint)?.code
  //     }))
  //   }
  // }, [checkPoint, deliverSync?.cps])

  // useEffect(() => {
  //   if (customer) {
  //     setForm(f => ({
  //       ...f,
  //       customerId: customers?.find(obj => obj.name === checkPoint)?.id
  //     }))
  //   }
  // }, [checkPoint, customer, customers])

  const [driver, setDriver] = useState('')

  useEffect(() => {
    if (driver) {
      setForm(f => ({
        ...f,
        driverId: deliverSync?.couriers?.find(obj => obj.name === driver)?.code
      }))
    }
  }, [deliverSync?.couriers, driver])

  const handleCancel = () => {
    setShow(false)
    refetchForm()
  }
  const handleConfirm = async () => {
    try {
      setIsSubmitting(true)
      await deliver(formattedForm)
      setTrigger(makeRandomString(5))
      setIsSubmitting(false)
      setShow(false)

    } catch (err) {
      setIsSubmitting(false)
      console.log(err)
    }
  }

  const types = [
    'Checkpoint',
    'Customer',
    // 'Return'
  ]

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'Deliver'}
        withIcon
        iconName='paper-airplane'
        paddingX='2.4rem'
        paddingY='1.6rem'
        titleAdditionalComponent={<div className={Styles.typeIndicator}>{stockTypes?.find(obj => obj?.query?.toLowerCase() === type)?.title}</div>}
        isSticky
      />
      <div className={Styles.content}>
        <div className={`${Styles.typeWrapper} ${form?.type ? Styles.selected : ''}`}>
          <InputField
            title={'Delivery Type'}
            placeholder={'Select delivery type'}
            isDropdown
            dropdownOptions={types}
            value={form?.type}
            disabled
            setValue={(newVal) => handleChange(newVal, 'type')}
          />
        </div>
        {form?.type && types?.find(obj => obj?.toLowerCase() === form?.type?.toLowerCase())
          &&
          <div className={Styles.restWrapper}>
            {form?.type?.toLowerCase() !== 'checkpoint'
              ?
              <InputField
                title={'Customer'}
                value={customer}
                setValue={setCustomer}
                placeholder={'Select customer'}
                isDropdown={true}
                dropdownOptions={customers?.map(obj => obj?.name)}
              />
              :
              <InputField
                title={'Checkpoint'}
                value={checkPoint}
                setValue={setCheckPoint}
                placeholder={'Select checkpoint'}
                isDropdown={true}
                dropdownOptions={deliverSync?.cps?.map(obj => obj?.name)}
              />

            }
            <InputField
              type='date'
              title={form?.type === 'Return' ? 'Return Pickup Date' : 'Delivery Date'}
              value={form?.pickupDate || form?.deliveryDate}
              setValue={(newVal) => handleChange(newVal, type === 'Return' ? 'pickupDate' : 'deliveryDate')}
            />
            <ItemsQuantityAdjusterField
              title={'How much quantity would you like to deliver to store?'}
              selectedItemsTitle={'Selected Items'}
              value={form?.items}
              setValue={(newVal) => handleChange(newVal, 'items')}
            />
            <InputField
              title={'Driver'}
              value={driver}
              setValue={setDriver}
              placeholder={'Select driver'}
              isDropdown={true}
              dropdownOptions={deliverSync?.couriers?.map(obj => obj?.name)}
            />
            <TextAreaField
              title={'Note'}
              value={form?.note}
              setValue={(newVal) => handleChange(newVal, 'note')}
              placeholder={'Note to driver'}
            />
          </div>
        }


      </div>
      <div className={Styles.actionsWrapper}>
        <Button
          type='button'
          variant='secondary'
          buttonText={'Cancel'}
          size='lg'
          onClick={handleCancel}
        />
        <Button
          type='button'
          variant='primary'
          buttonText={isSubmitting ? 'Confirming...' : 'Confirm'}
          size='lg'
          onClick={handleConfirm}
          disabled={disableSubmit}
        />

      </div>
    </div >
  )
}