import Styles from './style.module.scss'

export default function ValueOnlyField({
  title,
  value
}) {

  return (
    <div className={Styles.container}>
      <span>{title}</span>
      <span>{value}</span>
    </div>
  )
}