import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import Tag from "@Atom/Tag";
import { fileBaseUrl } from "@Config/api";
import useOutsideClick from "@Hooks/useOutsideClick";
import InputField from "@Molecule/InputField";
import StackedItemInfo from "@Molecule/StackedItemInfo";
import TextAreaField from "@Molecule/TextAreaField";
import ConfirmationModal from "@Molecule/_modals/ConfirmationModal";
import DetailsModal from "@Molecule/_reusableLayout/DetailsModal";
import { getWorkerList } from "@Services/employee";
import { getWorkshopById, updateStatusDelivery } from "@Services/workshop";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";

export default function WorkshopRequestDetailsModal({
  handleClose = () => {},
  selectedWorkshop,
  onOpenHold = () => {},
  onOpenCancel = () => {},
}) {
  const [showToOnProcess, setShowToOnProcess] = useState(false);
  const [workshop, setWorkshop] = useState(null);

  // const [assignWorkerForm, setAssignWorkerForm] = useState("");

  const getWorkshop = useCallback(async () => {
    const { data } = await getWorkshopById(selectedWorkshop?._id);
    if (data.status) {
      setWorkshop((prev) => ({ ...prev, ...data }));
    }
  }, [selectedWorkshop?._id])
  
  useEffect(() => {
    if (selectedWorkshop?._id) {
      getWorkshop();
    }
  }, [getWorkshop, selectedWorkshop]);

  const [showSnackbar, setShowSnackbar] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [scheduleMode, setScheduleMode] = useState(false);

  const [scheduleForm, setScheduleForm] = useState({
    driver: "",
    deliveryDate: workshop?.deliveryDate,
    note: workshop?.deliveryNote,
  });

  const [userList, setUserList] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const { data } = await getWorkerList();
      setUserList(data);
    };
    getData();
  }, []);

  const driverList = useMemo(() => {
    return (
      userList?.couriers?.map((el) => ({ id: el.code, name: el.name })) || []
    );
  }, [userList]);

  const onChangeScheduleForm = (newVal, name) => {
    setScheduleForm((prev) => {
      return {
        ...prev,
        [name]: newVal,
      };
    });
  };

  // useEffect(() => {
  //   setShowToOnProcess(false)
  //   setAssignWorkerForm({
  //     master: '',
  //     tukang: ''
  //   })
  //   setScheduleMode(false)
  // }, [workshop])

  const [activeTooltip, setActiveTooltip] = useState("");

  const contentTemplate = useMemo(() => {
    return [
      {
        title: "Product Code",
        component: (
          <div className={Styles.desc}>{workshop?.textiles[0]?.code}</div>
        ),
      },
      {
        title: "Fabric Code",
        component: (
          <div className={Styles.desc}>{workshop?.textiles[0]?.code}</div>
        ),
      },
      {
        title: "Customer Name",
        component: (
          <div className={Styles.customerName}>
            <span>{workshop?.customerName}</span>
            <span>{workshop?.customerPhoneNumber}</span>
          </div>
        ),
      },
      {
        title: "Order ID",
        component: <div className={Styles.desc}>{workshop?.orderID}</div>,
      },
    ];
  }, [workshop]);

  const myElementRef = useRef(null);

  const outsideTooltip = useOutsideClick(myElementRef);

  // useEffect(() => {
  //   setActiveTooltip('')
  // }, [assignWorkerForm])

  useEffect(() => {
    if (activeTooltip && outsideTooltip) {
      setActiveTooltip("");
    }
  }, [activeTooltip, outsideTooltip]);

  useEffect(() => {
    if (showSnackbar) {
      setTimeout(() => {
        setShowSnackbar(false);
      }, 2000);
    }
  }, [showSnackbar]);

  const handleSubmitSchedule = useCallback(async () => {
    const dataToSend = {
      type: "REQUEST",
      isPriority: "NORMAL",
      to: workshop?.to,
      from: workshop?.from,
      scheduleAt: scheduleForm?.deliveryDate,
      note: scheduleForm?.note,
      items: [
        {
          _id: workshop?._id,
          name: workshop?.name,
          type: workshop?.type,
          orderID: workshop?.orderID,
          textiles: workshop?.textiles,
        },
      ],
      courierCode: driverList?.find((el) => el?.name === scheduleForm?.driver)?.id,
    };
    const res = await updateStatusDelivery(dataToSend);
    if (res?.status) {
      setScheduleMode(false);
      setWorkshop((prev) => ({ ...prev, ...res.data }));
    }
  }, [driverList, scheduleForm?.deliveryDate, scheduleForm?.driver, scheduleForm?.note, workshop?._id, workshop?.from, workshop?.name, workshop?.orderID, workshop?.textiles, workshop?.to, workshop?.type])

  const deliveryDetailsTemplate = useMemo(() => {
    return [
      {
        title: "Address",
        component: <div className={Styles.desc}>{workshop?.address}</div>,
      },
      {
        title: "Driver",
        component: (
          <StackedItemInfo
            picture={Images.AVA_DEFAULT}
            imgRadius="50%"
            title={
              driverList.find((el) => el?.id === workshop?.deliveryCode)?.name ||
              ""
            }
          />
        ),
      },
      {
        title: "Delivery Date",
        component: (
          <div className={Styles.date}>
            <Icon icon={"calendar"} size={24} />
            <span>
              {workshop?.deliveryDate
                ? moment(new Date(workshop?.deliveryDate))?.format("ll")
                : "-"}
            </span>
          </div>
        ),
      },
      {
        title: "Note",
        component: (
          <div className={Styles.desc}>{workshop?.deliveryNote || "-"}</div>
        ),
      },
    ];
  }, [driverList, workshop?.address, workshop?.deliveryCode, workshop?.deliveryDate, workshop?.deliveryNote]);

  const additionalContent = useMemo(() => {
    switch (workshop?.status) {
      case "ON-REQUEST":
        return [
          {
            title: "",
            component: (
              <Button
                type="button"
                variant="primary"
                buttonText="Schedule Delivery"
                withIcon
                iconName="calendar"
                size="lg"
                onClick={() =>
                  setWorkshop((el) => ({ ...el, status: "REQUESTED" }))
                }
              />
            ),
          },
        ];
      case "REQUESTED":
        return [
          {
            title: "Schedule Delivery",
            component: (
              <div className={Styles.scheduleForm}>
                <div className={Styles.address}>
                  <span>Address</span>
                  <div>
                    <p>{workshop?.to?.address}</p>
                  </div>
                </div>
                <InputField
                  title={"Driver"}
                  value={scheduleForm?.driver}
                  setValue={(newVal) => onChangeScheduleForm(newVal, "driver")}
                  placeholder={"Select driver"}
                  isDropdown
                  dropdownOptions={driverList.map((obj) => obj?.name)}
                />
                <InputField
                  title={"Delivery Date"}
                  value={scheduleForm?.deliveryDate}
                  setValue={(newVal) =>
                    onChangeScheduleForm(newVal, "deliveryDate")
                  }
                  type="date"
                />
                <TextAreaField
                  title={"Driver"}
                  value={scheduleForm?.note}
                  setValue={(newVal) => onChangeScheduleForm(newVal, "note")}
                  placeholder={"Note to driver"}
                />
                <div className={Styles.scheduleActions}>
                  <Button
                    buttonText={"Submit Schedule"}
                    size="lg"
                    onClick={() => handleSubmitSchedule()}
                    withIcon
                    iconName={"calendar"}
                    // fixedPadding={'8px 16px'}
                  />
                  <Button
                    buttonText={"Cancel"}
                    size="lg"
                    variant="secondary"
                    onClick={() =>
                      setWorkshop((el) => ({ ...el, status: "ON-REQUEST" }))
                    }
                    withIcon
                    iconName={"x-mark"}
                    // fixedPadding={'8px 16px'}
                  />
                </div>
              </div>
            ),
          },
        ];
      default:
        return [
          {
            title: "Delivery Details",
            component: (
              <div className={Styles.deliveryDetails}>
                {deliveryDetailsTemplate?.map((each, i) => (
                  <div key={i}>
                    <span>{each?.title}</span>
                    {each?.component}
                  </div>
                ))}
              </div>
            ),
          },
        ];
    }
  }, [workshop?.status, workshop?.to?.address, scheduleForm?.driver, scheduleForm?.deliveryDate, scheduleForm?.note, driverList, deliveryDetailsTemplate, handleSubmitSchedule]);

  return (
    <DetailsModal
      modalTitle={"Workshop Details"}
      modalIcon={"list-bullet"}
      handleClose={handleClose}
      customExtraComponent={
        <div className={Styles?.extraCard}>
          {/* <Avatar
            src={workshop?.imgUrl}
            size='56px'
            bworkshopRadius='6px'
            // withIcon
            // iconName={workshop?.type?.toLowerCase()}
            // iconSize='48px'
            borderRadius='4px'
            iconColor='#6C757D'
          /> */}
          <StackedItemInfo
            picture={fileBaseUrl + workshop?.textiles[0]?.imageUrl || ""}
            imgSize="44px"
            title={workshop?.name}
            label={workshop?.type}
            maxWidth="100%"
            renderExtraTopLabel={() => (
              <Tag
                type={
                  workshop?.status === "ONDELIVERY"
                    ? "yellow"
                    : workshop?.status === "DELIVERED"
                    ? "green"
                    : workshop?.status === "ON-REQUEST"
                    ? "blue"
                    : workshop?.status === "READY"
                    ? "black"
                    : "orange"
                }
                title={
                  workshop?.status === "ONDELIVERY"
                    ? "On Delivery"
                    : workshop?.status === "DELIVERED"
                    ? "Delivered"
                    : workshop?.status === "ON-REQUEST"
                    ? "Requested"
                    : workshop?.status === "READY"
                    ? "Ready"
                    : "On Delivery"
                }
              />
            )}
          />
        </div>
      }
      noTag
      noPadding
    >
      <div className={Styles.content}>
        {contentTemplate?.map((content, i) => (
          <div key={i}>
            {(content?.title || content?.titleComponent) &&
              (content?.titleComponent || <span>{content?.title}</span>)}
            {content?.component}
          </div>
        ))}
        {additionalContent?.map((content, i) => (
          <div key={i}>
            {(content?.title || content?.titleComponent) &&
              (content?.titleComponent || <span>{content?.title}</span>)}
            {content?.component}
          </div>
        ))}
      </div>

      {showToOnProcess && (
        <ModalWrapper show={showToOnProcess} setShow={setShowToOnProcess}>
          <ConfirmationModal
            title="Update Status to On Process"
            desc="Are you sure you want to update status?"
            cancelButtonText="No, go back"
            confirmButtonText="Yes, update"
            onCancel={() => setShowToOnProcess(false)}
            onConfirm={() => {
              setShowSnackbar(true);
              setShowToOnProcess(false);
            }}
          />
        </ModalWrapper>
      )}
    </DetailsModal>
  );
}
