import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import { useState } from 'react'

export default function Searchbar({
  value,
  setValue=()=>{},
  placeholder = 'Search',
  width = '10rem',
  accordionMode = false,
  customClassName='',
  adornmentPositioning={  top: '1.1rem', left: '1.6rem'},
  iconSize='24px'
}) {
  const [expand, setExpand] = useState(false)

  return (
    <div className={`${Styles.container} ${customClassName} ${accordionMode ? Styles.accordion : ''} ${expand ? Styles.expand :''}`}
     style={{ width: accordionMode ? (expand ? width : '48px') : width }}
     >
      <button onClick={() => {
        accordionMode && setExpand(!expand)
      }}
      style={adornmentPositioning}
      >
        <Icon icon="magnifying-glass" size={iconSize} className={Styles.icon} />
      </button>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        // style={{
        //   paddingInline: accordionMode ? (expand ? '1.6rem' : 0) : '1.6rem'
        // }}
      />
    </div>
  )
}