import ListingLayout from '@Organism/Product/Listing'
import Styles from './style.module.scss'
import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function ListingPage() {
  const { packageListingList } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <ListingLayout
            isLoading={true}
            defaultData={null}
          />
        }
      >
        <Await
          resolve={packageListingList}
          errorElement={<AsyncErrorBoundary />}
        >
        {(packageCollectionOverview) => (
          <ListingLayout
            defaultData={packageCollectionOverview?.data?.products}
            totalPage={packageCollectionOverview?.data?.totalPage}
            totalData={packageCollectionOverview?.data?.total}
          />

        )}
      </Await>
    </Suspense>
    </div >
  )
}