/* eslint-disable no-unused-vars */
import Button from '@Atom/Button'
import { priceFormat } from '@Helpers/priceFormat'
import Slider from '@Molecule/Slider'
import { useEffect, useMemo, useRef, useState } from 'react'
import Styles from './style.module.scss'
import Avatar from '@Atom/Avatar'
import useWindowSize from '@Hooks/useWindowSize'
import { useNavigate, useParams } from 'react-router-dom'
import { fileBaseUrl } from '@Config/api'
import { splitCamelCase } from '@Helpers/splitCamelCase'
import Icon from '@Atom/Icon'
import Skeleton from 'react-loading-skeleton'

export default function ListingOverviewLayout({
  isLoading,
  defaultData
}) {
  const topBarHeight = localStorage.getItem('topBarHeight')
  const details = useMemo(() => {
    return {
      ...defaultData,
      name: defaultData?.name,
      price: defaultData?.price,
      variants: defaultData?.image?.map(obj => { return { name: obj?.url, img: fileBaseUrl + obj?.url } }),
      material: defaultData?.fabric,
      details: defaultData?.description,
      measurements: defaultData?.measurements ? Object.entries(defaultData?.measurements)?.map(m => { return { name: m[0], value: m[1] + ' cm', title: splitCamelCase(m[0]) } }) : [],
      qty: defaultData?.storeDetail?.map(obj =>obj?.qty)?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      },0)
    }

  }, [defaultData])

  const [activeIndex, setActiveIndex] = useState(0)

  const template = useMemo(() => {
    return [
      {
        title: '',
        noBg: true,
        component:
          <div className={Styles.variantsWrapper}>
            {!isLoading
              ?
              details?.variants?.map((each, i) => (
                <button
                  key={i}
                  className={i === activeIndex ? Styles.active : ''}
                  onClick={() => sliderRef?.current?.slickGoTo(i)}
                // onClick={()=> console.log(sliderRef?.current, 't')}
                >
                  <img src={each?.img} alt='' />
                </button>
              ))
              :
              [...Array(3)]?.map((_, i) => (
                <Skeleton key={i} className={Styles.button} />
              ))
            }
          </div>
      },
      {
        title: 'Sizes',
        icon: 'ruler',
        component:
          <div className={Styles.sizesWrapper}>
            {!isLoading
              ?
              details?.measurements?.map((each, i) => (
                <div key={i}>
                  <span>{each?.title || each?.name}</span>
                  <span>{each?.value}</span>
                </div>
              ))
              :
              [...Array(4)]?.map((_, i) => (
                <Skeleton key={i} className={Styles.div} />
              ))
            }
          </div>
      },
      {
        title: 'Stock Inventory',
        icon: 'map-pin',
        extraHeaderComponent:
          !isLoading
            ?
            <div className={Styles.inventoryExtraHeader}>
              <Icon icon={'box-group'} size={24} />
              <p><span>Stock:</span><span>{` (${details?.qty})`}</span></p>
            </div>
            :
            <div className={Styles.inventoryExtraHeader}>
              <Skeleton className={Styles.stockAmount} />
            </div>
        ,
        component:
          <div className={Styles.inventoryWrapper}>
            {!isLoading
              ?
              details?.storeDetail?.map((each, i) => (
                <div key={i}>
                  <span>{each?.name}</span>
                  <span>{`Stock: ${each?.qty}`}</span>
                </div>
              ))
              :
              [...Array(3)]?.map((_, i) => (
                <Skeleton key={i} className={Styles.div} />
              ))
            }
          </div>
      },
      {
        title: 'Product Details',
        component:

          <div className={Styles.productDetailsWrapper}>
            <div className={Styles.materialCard}>
              {/* <Avatar
                src={details?.materialImg}
                size='50px'
                borderRadius='8px'
              /> */}
              <div className={Styles.name}>
                <span>Materials</span>
                {!isLoading
                  ?
                  <span>{details?.material}</span>
                  :
                  <Skeleton className={Styles.material} />
                }
              </div>
            </div>
            <div className={Styles.detailsDesc}>
              {!isLoading
                ?
                <span>{details?.details}</span>
                :
                <Skeleton className={Styles.desc} />
              }
            </div>
            {/* <div className={Styles.detailsList}>
              {details?.details?.map((each, i) => (
                <div key={i}>
                  <span>&#x2022;</span>
                  <span>{each}</span>
                </div>
              ))}
            </div> */}
          </div>
      }
    ]
  }, [isLoading, details?.variants, details?.measurements, details?.qty, details?.storeDetail, details?.material, details?.details, activeIndex])

  const { id } = useParams()
  const handleAddToCart = () => { }
  const handleStartMeasure = () => {
    navigate(`/product/listing/start-measure/${id}`)
  }

  const { width } = useWindowSize()

  const sliderRef = useRef()
  const sliderWrapperRef = useRef()
  const [sliderHeight, setSliderHeight] = useState()

  const getSliderHeight = () => {
    const newHeight = sliderRef?.current?.clientHeight;
    setSliderHeight(newHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", getSliderHeight);
  }, []);

  const navigate = useNavigate()

  return (
    <div className={Styles.container}>
      <div className={`${Styles.header} ${Styles.showOnMobile}`}>
        <Button
          variant='tertiary'
          size='lg'
          withIcon
          iconName={'chevron-left'}
          buttonText='Back to Listing'
          customIconSize={'16px'}
          fixedPadding={'0px'}
          disableActive
          onClick={() => navigate('/product/listing')}
        />
        {!isLoading
          ?
          <div className={Styles.bottom}>
            <span>{details?.name}</span>
            <span>Rp{priceFormat(details?.price)}</span>
          </div>
          :
          <div className={Styles.bottom}>
            <Skeleton className={Styles.name} />
            <Skeleton className={Styles.price} />
          </div>
        }

      </div>
      <div className={Styles.content}>
        {!isLoading
          ?
          !!details?.variants?.length
            ?
            <div className={Styles.sliderWrapper}>
              {details?.variants?.length
                &&
                <Slider
                  refToForward={sliderRef}
                  setIndex={setActiveIndex}
                  showDots={width <= 1024 ? true : false}
                  showArrows={width > 1024 ? true : false}
                >
                  {details?.variants?.map((each, i) => (
                    <div key={i} className={Styles.each}>
                      <img src={each?.img} alt='' />
                    </div>
                  ))}
                </Slider>
              }

            </div>
            :
            <div className={Styles.noImage}>
            </div>

          :
          <Skeleton className={Styles.sliderWrapper} />
        }

        <div className={Styles.detailsWrapper}>
          <div className={`${Styles.header} ${Styles.hideOnMobile}`}>
            <Button
              variant='tertiary'
              size='lg'
              withIcon
              iconName={'chevron-left'}
              buttonText='Back to Listing'
              customIconSize={'16px'}
              fixedPadding={'0px'}
              disableActive
              onClick={() => navigate('/product/listing')}
            />
            {!isLoading
              ?
              <div className={Styles.bottom}>
                <span>{details?.name}</span>
                <span>Rp{priceFormat(details?.price)}</span>
              </div>
              :
              <div className={Styles.bottom}>
                <Skeleton className={Styles.name} />
                <Skeleton className={Styles.price} />
              </div>
            }
          </div>
          <div className={Styles.details}>
            {template?.map((each, i) => (
              <div key={i} className={`${Styles.card} ${each?.noBg ? Styles.noBg : ''}`}>
                {(each?.title || each?.icon)
                  &&
                  <div className={Styles.cardHeader}>
                    {each?.icon && <Icon icon={each?.icon} size={24} />}
                    {each?.title && <span>{each?.title}</span>}
                    {each?.extraHeaderComponent && each?.extraHeaderComponent}
                  </div>
                }
                {each?.component}
              </div>
            ))}
          </div>
          {!isLoading
            &&
            <div className={Styles.actions}>
              {/* <Button
                type='button'
                variant='secondary'
                buttonText={'Add To Cart'}
                size='lg'
                withIcon
                iconName={'shopping-cart'}
                iconPlacement='start'
                onClick={handleAddToCart}
              /> */}
              <Button
                type='button'
                variant='primary'
                buttonText={'Start Measure'}
                size='lg'
                withIcon
                iconName={'arrow-long-right'}
                iconPlacement='end'
                onClick={handleStartMeasure}
              />
            </div>
          }

        </div>

      </div>

    </div>
  )
}

