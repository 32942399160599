import { useCallback, useMemo } from 'react'
import Styles from './style.module.scss'
import StackedItemInfo from '@Molecule/StackedItemInfo'
import TableSet from '@Molecule/TableSet'
import RekhasBG from '@Atom/RekhasBG'
import Tag from '@Atom/Tag'
import useWindowSize from '@Hooks/useWindowSize'
import GroupedAvatars from '@Molecule/GroupedAvatars'
import { fileBaseUrl } from '@Config/api'
import Button from '@Atom/Button'
import { capitalizeWords } from '@Helpers/capitalizeWords'

export default function WorkshopListLayout({
  defaultData,
  isLoading,
  trigger,
  setTrigger,
  setSelectedWorkshop,
  setShowFilter
}) {

  const { width } = useWindowSize()

  const handleClickRow = useCallback((row) => {
    setSelectedWorkshop(row)
  }, [setSelectedWorkshop])


  const template = useMemo(() => {
    return ({
      data: defaultData?.processes?.length
        ?
        defaultData?.processes?.map(obj => {
          return {
            ...obj,
            id: obj?._id,
            type: obj?.type,
            customerName: obj?.customerName,
            customerPhoneNumber: obj?.customerPhoneNumber,
            orderId: obj?.orderID,
            status: obj?.status?.replaceAll('-', ' '),
            fabrics: obj?.fabrics?.map(f => {
              return {
                ...f,
                id: f?._id,
                fabricType: '',
                fabricName: f?.fabric,
                fabricCode: f?.code,
                img: f?.imageUrl ? fileBaseUrl + f?.imageUrl?.replaceAll(fileBaseUrl, '') : '',
              }
            })
          }
        })
        :
        [],
      columns: [
        {
          name: 'name',
          label: 'Product Name',
          maxWidth: width <= 768 ? "100px" : "323px",
          minWidth: width <= 768 ? "100px" : "323px",
          thClassName: Styles.stickyLeft,
          tdClassName: Styles.stickyLeft,
          renderData: (row) => (
            <StackedItemInfo
              title={row?.type}
              label={`${row?.fabrics?.length} Fabrics`}
              picture={row?.imgUrl}
              isV2
              maxWidth={width <= 768 ? "130px" : "auto"}
              iconName={row?.status !== 'REQUESTED' ? row?.type?.toLowerCase() || 'suit' : ''}
              noImageV2={row?.status === 'REQUESTED'}
            />
          ),
          skeleton:
            <StackedItemInfo
              // title={row?.type}
              // label={`${row?.category}: ${row?.name}`}
              // picture={row?.imgUrl}
              isV2
              isSkeleton
            // iconName='suit'
            />
        },
        {
          name: 'notes',
          label: 'Notes',
          minWidth: '208px',
          maxWidth: '208px',
          hideOnMobile: true,
        },
        {
          name: 'code',
          label: 'Fabrics',
          minWidth: '208px',
          maxWidth: '208px',
          hideOnMobile: true,
          renderData: (row) => (
            (row?.fabrics && row?.fabrics?.length) ?
              <GroupedAvatars
                srcArr={row?.fabrics?.map(obj => obj?.img || '')}
                borderRadius='4px'
              /> : <>no image</>
          )
        },
        {
          name: 'status',
          label: 'Status',
          minWidth: '141px',
          hideOnMobile: true,
          renderData: (row) => (
            <div className={Styles.itemStatus}>
              <Tag
                type={
                  row.status === 'ON GOING'
                    ? 'yellow'
                    : (row.status === 'DELIVERED'
                      ? 'green'
                      : (row?.status?.includes('REQUEST')
                        ? 'blue'
                        : row?.status === 'READY' ? 'black' :
                          row?.status === 'CANCELLED'
                            ? 'red' :
                            row?.status === 'SCHEDULED' ? 'orange' : 'yellow'))}

                title={capitalizeWords(row?.status)}
              />
              {/* {row?.itemStatus === 'READY'
                &&
                <Button
                  variant='secondary'
                  size='sm'
                  buttonText='Set Schedule'
                  borderRadius='4px'
                  onClick={(e) => {
                    e.stopPropagation();
                    setOrderId(row?.id)
                    setShowScheduleSetter(true)
                  }}
                />
              } */}
            </div>
          )
        },
        {
          name: 'customerName',
          label: 'Customer Name',
          minWidth: '290px',
          hideOnMobile: true,
          renderData: (row) => (
            <StackedItemInfo
              title={row?.customerName}
              label={row?.customerPhoneNumber || '-'}
              withoutPicture
              titleWidth={width <= 768 ? '150px' : '100%'}
            />
          )
        },
        {
          name: 'orderId',
          label: 'Order Id',
          minWidth: width <= 768 ? '100px' : '187px',
          maxWidth: width <= 768 ? '100px' : '187px',
        },
        {
          name: '',
          label: '',
          showOnMobile: true,
          align: 'right',
          minWidth: '80px',
          maxWidth: '80px',
          renderData: (row) => (
            // <div>
            <Button
              state='icon-only'
              variant='secondary'
              disableActive
              withIcon
              iconName={'list-bullet'}
              size='24'
              fixedPadding={'12px'}
              onClick={() => handleClickRow(row)}
            />
            // </div>
          )
        }
      ]
    })
  }, [defaultData?.processes, handleClickRow, width])


  const user = localStorage?.getItem('user') ? JSON?.parse(localStorage.getItem('user')) : null;
  const role = user?.role


  return (
    <RekhasBG>
      <TableSet
        type={role === 'TOKO' ? 'workshop-request' : 'workshop'}
        data={template?.data}
        columns={template?.columns}
        onClickFilter={() => setShowFilter(true)}
        onClickRow={(row) => handleClickRow(row)}
        totalPage={defaultData?.totalPage || 0}
        totalData={defaultData?.total || 0}
        withSkeleton
        withNumbering
        isLoading={isLoading}
        trigger={trigger}
        setTrigger={setTrigger}
      />
    </RekhasBG>
  )
}