import ModalWrapper from '@Atom/ModalWrapper'
import useOutsideClick from '@Hooks/useOutsideClick'
import WorkshopDetailsModal from '@Molecule/_modals/DetailsModals/Workshop'
import WorkshopFilterModal from '@Molecule/_modals/FilterModals/Workshop'
import WorkshopListLayout from '@Organism/Workshop/WorkshopList'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'
import { Suspense, useEffect, useRef, useState } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Styles from './style.module.scss'
import OrderActionConfirmationModal from '@Molecule/_modals/OrderActionConfirmationModal'

export default function WorkshopListPage() {
  const { packageWorkshops } = useLoaderData()
  const [selectedWorkshop, setSelectedWorkshop] = useState(null)
  const [showFilter, setShowFilter] = useState(false)

  const [trigger, setTrigger] = useState('')

  const [confirmationType, setConfirmationType] = useState('hold')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)

  const [orderId, setOrderId] = useState(null)


  // const { width } = useWindowSize()

  const ref = useRef()

  const outsideClick = useOutsideClick(ref)

  useEffect(() => {
    if (selectedWorkshop && outsideClick) {
      setSelectedWorkshop(false)
    }
  }, [outsideClick, selectedWorkshop])

  useEffect(() => {
    setOrderId(selectedWorkshop?.orderID || null)
  }, [selectedWorkshop?.orderID])


  return (
    <div className={Styles.container}>
      <Suspense
        fallback={<WorkshopListLayout
          defaultData={null}
          isLoading={true}
          trigger={trigger}
          setTrigger={setTrigger}
          setSelectedWorkshop={setSelectedWorkshop}
          setShowFilter={setShowFilter}
        />}
      >
        <Await
          resolve={packageWorkshops}
          errorElement={
            <AsyncErrorBoundary />
          }
        >
          {(packageWorkshops) => (
            <WorkshopListLayout
              defaultData={packageWorkshops?.data}
              isLoading={false}
              trigger={trigger}
              setTrigger={setTrigger}
              setSelectedWorkshop={setSelectedWorkshop}
              setShowFilter={setShowFilter}
            />
          )}

        </Await>

      </Suspense>

      <div>
        {/* <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={selectedWorkshop ? true : false}
          setShow={() => setSelectedWorkshop(null)}
        >
          <WorkshopDetailsModal
            workshop={selectedWorkshop}
            handleClose={() => setSelectedWorkshop(null)}
          />
        </ModalWrapper> */}
        {/* {
          selectedWorkshop
          &&
          <div style={{
            position: 'fixed',
            top: width > 768 ? '20px' : 'auto',
            bottom: width > 768 ? 'auto' : '0px',
            background: width > 768 ? 'transparent' : 'rgba(0, 0, 0, 0.30)',
            zIndex: 10,
            width: width > 768 ? 'calc(100vw - 20px)' : '100vw',
            maxWidth: 'calc(1440px)',
            height: width > 768 ? 'calc(100vh - 40px)' : '100vh',
            overflowY: 'scroll',
            overflowX: 'visible',
            paddingTop: width > 768 ? '' : '',
          }}>
            {
              width > 768
              &&
              <div
                style={{
                  position: 'fixed',
                  width: '50rem',
                  height: 'calc(100vh - 40px)',
                  maxHeight: '1200px',
                  boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.20)',
                  right: width > 1440 ? 'calc((100vw - 1440px) / 2)' : '20px',
                }}
              />
            }

            <div
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: width > 768 ? 'row' : 'column',
                height: width > 768 ? 'auto' : '100vh'

              }}
            >
              <div ref={ref}>
                <WorkshopDetailsModal
                  workshopData={selectedWorkshop}
                  handleClose={() => setSelectedWorkshop(null)}
                />
              </div>

            </div>

          </div>
        } */}
        {selectedWorkshop
          &&
          <ModalWrapper
            desktopPositioning='right'
            mobilePositioning='bottom'
            show={selectedWorkshop ? true : false}
            consoling={true}
            setShow={() => setSelectedWorkshop(null)}
          >
            <WorkshopDetailsModal
              workshopData={selectedWorkshop}
              handleClose={() => setSelectedWorkshop(null)}
              setTrigger={setTrigger}
              onOpenHold={() => {
                setConfirmationType('hold')
                setShowActionConfirmation(true)
                // setShowDetails(false)
              }}
              onOpenCancel={() => {
                setConfirmationType('cancel')
                setShowActionConfirmation(true)
                // setShowDetails(false)
              }}
              onOpenResume={() => {
                setConfirmationType('resume')
                setShowActionConfirmation(true)
                // setShowDetails(false)
              }}
            />
          </ModalWrapper>
        }


        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={showFilter}
          setShow={setShowFilter}
        >
          <WorkshopFilterModal
            handleClose={() => {
              setShowFilter(false)
              // setTrigger(Math.random())
            }}
            trigger={trigger}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showActionConfirmation}
          setShow={setShowActionConfirmation}
        >
          <OrderActionConfirmationModal
            handleClose={() => {
              setShowActionConfirmation(false)
              // setShowDetails(true)
            }}
            orderId={orderId}
            type={confirmationType}
            setTrigger={setTrigger}
          // show={showChangeToReadySetter}
          />
        </ModalWrapper>
      </div>
    </div>
  )
}