import Button from "@Atom/Button";
import Images from "@Theme/Images";
import { useAsyncError, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function AsyncErrorBoundary({ height = 'calc(100vh - 62px)', isNotFound = false, isUnregisteredRole }) {
  // const defaultErr =
  const err = useAsyncError()
  const error = err?.response?.data;
  const errorBackup = useAsyncError();
  const navigate = useNavigate()

  // if (+error?.code === 404) {
  //   return (
  //     <NotFoundPage
  //       type="data"
  //       notFullWidth
  //     />
  //   )
  // }

  return (
    <div className={Styles.container} style={{ height }}>
      <div className={Styles.box}>
        <h3>{isNotFound
          ?
          '404' : (
            error?.code || errorBackup?.code)}</h3>
        <p className={Styles.message}>
          {isNotFound
            ?
            isUnregisteredRole ? 'Unregistered role' : 'Page not found!'
            :
            (error?.message || errorBackup?.message || 'Something went wrong!')}
        </p>
        {
          isNotFound
            ?
            <Button
              buttonText={isUnregisteredRole ? 'Logout' : "Go to Main Page"}
              withIcon
              onClick={() => {
                if (isUnregisteredRole) {
                  sessionStorage.clear()
                  localStorage.clear()
                }
                navigate('/')
              }}
            />
            :
            <Button
              buttonText="Go back"
              withIcon
              iconName="arrow-small-left"
              onClick={() => navigate(-1)}
            />
        }

      </div>
      <img src={Images.QUESTIONING_NO_BG} alt="" className={Styles.img} />
    </div>
  );
}
