import Button from '@Atom/Button'
import { addOrder } from '@Services/order'
import { useCallback, useState } from 'react'
import WideErrorModal from '../WideErrorModal'
import Styles from './style.module.scss'

export default function ProceedOrderModal({
  setIsSure,
  setShowProceed,
  formToSend,
  idsToRemove
}) {
  const [error, setError] = useState('')

  const clearCart = useCallback(() => {
    const defaultCart = localStorage?.getItem('cart') ? JSON.parse(localStorage?.getItem('cart')) : []
    for (let i = 0; i < defaultCart.length; i++) {
      if (idsToRemove?.find(obj => obj === defaultCart[i]?.customerCode)) {
        defaultCart?.splice(i, 1)
      }
    }
    localStorage?.setItem('cart', JSON.stringify(defaultCart))
  }, [idsToRemove])

  const onProceed = async () => {
    try {
      await addOrder(formToSend)
      clearCart()
      setIsSure(true)
      setShowProceed(false)

    } catch (err) {
      setError(err?.response?.data?.error || 'Something went wrong');
      console.log(err)
    }
  }

  if(error){
    return (
      <WideErrorModal
      withButton
      onClose={() => {
        setShowProceed(false)
        setError('')
      }}
      />
    )
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <h3>Proceed Order</h3>
        <span>Are you sure you want to process this order?</span>

      </div>
      <div className={Styles.actions}>
        <Button
          variant='secondary'
          buttonText='Cancel'
          size='lg'
          onClick={() => setShowProceed(false)}
        />
        <Button
          variant='primary'
          buttonText='Yes, proceed'
          size='lg'
          onClick={onProceed}
        />
      </div>
    </div>
  )
}