import { shirtSizes, sizingTypes, suitSizes, trouserSizes } from "@Assets/data/sizingTypes";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import Radio from "@Atom/Radio";
import TextArea from "@Atom/TextArea";
import { fileBaseUrl } from "@Config/api";
import { splitCamelCase } from "@Helpers/splitCamelCase";
import CartItemDetailsCard from "@Molecule/CartItemDetailsCard";
import InputField from "@Molecule/InputField";
import MeasurementCard from "@Molecule/MeasurementCard";
import MeasurementField from "@Molecule/MeasurementField";
import PriceDetailsCard from "@Molecule/PriceDetailsCard";
import RadioField from "@Molecule/RadioField";
import SampleCard from "@Molecule/SampleCard";
import AddToCartModal from "@Molecule/_modals/AddToCartModal";
import WideErrorModal from "@Molecule/_modals/WideErrorModal";
import { addCustomer, deletePattern, editSizeMeasurement, getCustomerByCode, updateSizeMeasurement } from "@Services/customer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import { makeRandomString } from "@Helpers/makeRandomString";

export default function StartMeasureLayoutReady({
  defaultData,
  customerList,
  isLoading,
}) {
  const [mode, setMode] = useState("measure");
  const [customerCode, setCustomerCode] = useState('')
  const [error, setError] = useState(null)
  const [showConfirmSameItem, setShowConfirmSameItem] = useState(false)
  const [objectToSave, setObjectToSave] = useState(null)


  const [showAddToCart, setShowAddToCart] = useState(false);
  const navigate = useNavigate();

  const [measurementToEdit, setMeasurementToEdit] = useState(null)


  const [data, setData] = useState({
    ...defaultData,
    id: defaultData?._id,
    name: defaultData?.name,
    productCode: defaultData?.productCode,
    img: defaultData?.images?.length
      ? fileBaseUrl + defaultData?.images.find((el) => el?.imageDescription === "MAIN")?.url
      : null,
    price: defaultData?.price,
    isMeasured: false,
    isCustomSizing: true,
    showMeasurementStatus: true,
    sleevesType: "long",
    size: "-",
    measurements: defaultData?.size
      ? Object?.entries(defaultData?.size)?.map((m) => {
        return { name: m[0], value: m[1], title: splitCamelCase(m[0]) };
      })
      : [],
  });
  const [editCustomNameMode, setEditCustomNameMode] = useState(false);

  const paymentOptions = useMemo(() => {
    return [
      {
        name: "Full Payment",
        value: "full",
      },
      {
        name: "Installments",
        value: "installments",
      },
    ];
  }, []);

  const paymentMethodOptions = useMemo(() => {
    return [
      {
        name: "Cash",
        value: "cash",
      },
      {
        name: "Debit Card",
        value: "debit",
      },
      {
        name: "E-Wallet",
        value: "eWallet",
      },
    ];
  }, []);

  const [form, setForm] = useState({
    customerName: "",
    customerAddress: "",
    customerWhatsApp: "",
    customerEmail: "",
    customerCode: "",
  });

  const [newCustomer, setNewCustomer] = useState(false);
  const [sizeMeasurement, setSizeMeasurement] = useState([]);
  const [selectedMeasurementCode, setSelectedMeasurementCode] = useState("");
  const [selectedMeasurementId, setSelectedMeasurementId] = useState("");

  useEffect(() => {
    if (customerList?.length < 1) {
      setNewCustomer(true);
    }
  }, [customerList]);

  const fetchSize = useCallback(async () => {
    if (customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === form?.customerWhatsApp?.toUpperCase())?.code || '') {
      const code = customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === form?.customerWhatsApp?.toUpperCase())?.code || ''
      let fetch = await getCustomerByCode(code);
      const res = fetch.data;


      const filter = sizingTypes.map((el) => el.name);


      for (let i = 0; i < res.length; i++) {
        const arr = [];
        const size = res[i].measurement;
        const keys = Object.keys(size);
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j];
          for (let k = 0; k < filter.length; k++) {
            if (filter[k] === key) {
              arr.push({
                name: filter[k],
                title: sizingTypes[k].title,
                value: size[key],
              });
            }
          }
        }
        const rest = sizingTypes?.filter(obj => !arr?.find(m => m?.name === obj?.name))

        for (let j = 0; j < rest?.length; j++) {
          arr.push({
            name: rest[j]?.name,
            title: rest[j].title,
            value: '0',
          })
        }

        res[i].size = arr;
      }
      !selectedMeasurementCode && setSelectedMeasurementCode("#" + res[0]?.code)
      !selectedMeasurementId && setSelectedMeasurementId(0);
      setSizeMeasurement(res?.map(r => {
        return {
          ...r,
          photos: ['FRONT', 'SIDE', 'BACK', 'FRONT-1', 'SIDE-1', 'BACK-1']
            // eslint-disable-next-line array-callback-return
            ?.map((p) => {
              const found = r?.photos?.find(obj => obj?.angle === p)
              // if(found) {
              return {
                angle: p,
                remark: found?.remark || p,
                uri: found?.uri || '',
                _id: found?._id || ''
              }
              // }
            })

        }
      }));

      setMeasurementFormTemplate({
        size: sizingTypes?.map(obj => {
          return {
            name: obj?.name,
            title: obj?.title,
            value: ''
          }
        }) || [],
        name: `Size ${makeRandomString(5)}`,
        photos: [
          {
            id: 1,
            name: "",
            src: null,
            angle: "FRONT",
            remark: "",
          },
          {
            id: 2,
            name: "",
            src: null,
            angle: "SIDE",
            remark: "",
          },
          {
            id: 3,
            name: "",
            src: null,
            angle: "BACK",
            remark: "",
          },
          {
            id: 4,
            name: "",
            src: null,
            angle: "FRONT-1",
            remark: "",
          },
          {
            id: 5,
            name: "",
            src: null,
            angle: "SIDE-1",
            remark: "",
          },
          {
            id: 6,
            name: "",
            src: null,
            angle: "BACK-1",
            remark: "",
          },
        ],
        remark: "",
      });
    } else {
      setSizeMeasurement([]);
    }
  }, [customerList, form?.customerWhatsApp, selectedMeasurementCode, selectedMeasurementId]);

  useEffect(() => {
    fetchSize();
  }, [form.customerName, customerList, fetchSize]);

  const [measurementFormTemplate, setMeasurementFormTemplate] = useState({
    size: sizingTypes?.map(obj => {
      return {
        name: obj?.name,
        title: obj?.title,
        value: ''
      }
    }) || [],
    name: `Size ${makeRandomString(5)}`,
    photos: [
      {
        id: 1,
        name: "",
        src: null,
        angle: "FRONT",
        remark: "",
      },
      {
        id: 2,
        name: "",
        src: null,
        angle: "SIDE",
        remark: "",
      },
      {
        id: 3,
        name: "",
        src: null,
        angle: "BACK",
        remark: "",
      },
      {
        id: 4,
        name: "",
        src: null,
        angle: "FRONT-1",
        remark: "",
      },
      {
        id: 5,
        name: "",
        src: null,
        angle: "SIDE-1",
        remark: "",
      },
      {
        id: 6,
        name: "",
        src: null,
        angle: "BACK-1",
        remark: "",
      },
    ],
    remark: "",
  });

  const [isCustomMeasurement, setIsCustomMeasurement] = useState(false);
  const [notes, setNotes] = useState("");



  const addToCart = useCallback(() => {
    const tempCustomerCode = customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === (newCustomer ? '62' + form?.customerWhatsApp?.toUpperCase() : form?.customerWhatsApp?.toUpperCase()))?.code || ''
    setCustomerCode(customerList?.find(obj => obj?.name?.toUpperCase() === form?.customerName?.toUpperCase())?.code || '')
    const selectedSize =
      sizeMeasurement.length > 0 && !isCustomMeasurement
        ? sizeMeasurement[selectedMeasurementId]
        : measurementFormTemplate;
    let dataFromStorage = JSON.parse(localStorage.getItem("cart")) || [];
    // if (dataFromStorage?.find(obj => obj?.customerCode === tempCustomerCode)?.sizes?.find(obj => obj?.code === selectedSize?.code)?.items?.find(obj => obj?.fabrics[0]?.code === data?.productCode && obj?.notes === notes)) {
    //   setShowConfirmSameItem(true)
    // }

    const items = {
      id: new Date().getTime(),
      isReadyToWear: true,
      isAlter: false,
      isFabricOnly: false,
      label: "Ready To Wear",
      category: "SHIRT",
      fabrics: [
        {
          type: "READY-TO-WEAR",
          name: data.name,
          code: data.productCode || "",
          qty: 1,
          price: data?.price,
          imgUrl: data.img,
          fabric: data.fabric,
          _id: data._id,
        },
      ],
      notes: notes,
    };




    if (dataFromStorage.some((el) => el.customerCode === tempCustomerCode)) {
      dataFromStorage = dataFromStorage.map((el) =>
        el.customerCode === tempCustomerCode
          ? {
            ...el,
            sizes:
              el.sizes.some((el1) => el1.sizeName === selectedSize?.name) &&
                selectedSize?.name !== "Default"
                ? el.sizes.map((el1) =>
                  el1.sizeName === selectedSize.name
                    ? {
                      ...el1,
                      // items: [...el1.items, items],
                      items:
                        el1?.items?.find(i => i?.fabrics[0]?.code === items?.fabrics[0]?.code && i?.notes === items?.notes)
                          ?
                          (el1?.items?.map(obj => obj?.fabrics[0]?.code === items?.fabrics[0]?.code && obj?.notes === items?.notes
                            ?
                            {
                              ...obj,
                              fabrics: [
                                {
                                  ...obj?.fabrics[0],
                                  qty: obj?.fabrics[0]?.qty + items?.fabrics[0]?.qty
                                }
                              ],
                            }
                            : {
                              ...obj
                            }
                          ))
                          :
                          [...el1.items, items]
                      // (el1?.items?.map(obj => obj?.fabrics[0]?.code === items?.fabrics[0]?.code
                      //   ?
                      //   {
                      //     ...obj,
                      //     fabrics: [
                      //       {
                      //         ...obj?.fabrics[0],
                      //         qty: obj?.fabrics[0]?.qty + items?.fabrics[0]?.qty
                      //       }
                      //     ]
                      //   }
                      //   : {
                      //     ...obj
                      //   }
                      // ))
                      ,
                      measurements: selectedSize.size,
                      code: selectedSize?.code || "",
                    }
                    : el1
                )
                : [
                  ...el.sizes,
                  {
                    measurements: selectedSize.size,
                    code: selectedSize?.code || "",
                    sizeName: selectedSize.name,
                    items: [items],
                    photos: selectedSize.photos || [],
                    id: selectedSize?.code || "",
                  },
                ],
          }
          : el
      );
    } else {
      dataFromStorage.push({
        ...form,
        customerWhatsApp: `${newCustomer ? '62' : ''}` + form?.customerWhatsApp,
        customerCode: customerList?.find(obj => obj?.name?.toUpperCase() === form?.customerName?.toUpperCase())?.code || '',
        id: new Date().getTime(),
        sizes: [
          {
            measurements: selectedSize.size,
            sizeName: selectedSize.name,
            items: [items],
            photos: selectedSize.photos || [],
            id: new Date().getTime(),
            code: selectedSize?.code || "",
          },
        ],
      });
    }
    return dataFromStorage;
  }, [customerList, data._id, data.fabric, data.img, data.name, data?.price, data.productCode, form, isCustomMeasurement, measurementFormTemplate, newCustomer, notes, selectedMeasurementId, sizeMeasurement]);

  const handleSaveAddMeasurement = useCallback(async () => {
    const res = await updateSizeMeasurement(
      form.customerCode,
      measurementFormTemplate
    );
    if (res?.status) {
      setSelectedMeasurementCode('#' + res?.data?.code)
      setIsCustomMeasurement(false);
      fetchSize();
    }


  }, [fetchSize, form.customerCode, measurementFormTemplate]);

  useEffect(() => {
    if (newCustomer) {
      setIsCustomMeasurement(true)
    }
  }, [newCustomer])

  const newCustomerForm = useMemo(() => {
    const formData = new FormData()
    formData.append('name', form?.customerName)
    formData.append('phoneNumber', '62' + form?.customerWhatsApp)
    formData.append('email', form?.customerEmail)
    formData.append('address', form?.customerAddress)
    if (newCustomer) {
      for (let i = 0; i < measurementFormTemplate?.size?.length; i++) {
        formData.append(`size[${measurementFormTemplate?.size[i]?.name}]`, measurementFormTemplate?.size[i]?.value)
      }
      formData.append('size[name]', measurementFormTemplate?.name)

      const photosToMap = measurementFormTemplate?.photos?.filter(obj => obj?.src)

      for (let i = 0; i < photosToMap?.length; i++) {
        formData.append(`photos[${i}][angle]`, photosToMap[i]?.angle)
        formData.append(`photos[${i}][remark]`, photosToMap[i]?.remark)
        formData.append('images', photosToMap[i]?.src || '')
      }
    }

    return formData
  }, [form?.customerAddress, form?.customerEmail, form?.customerName, form?.customerWhatsApp, measurementFormTemplate?.name, measurementFormTemplate?.photos, measurementFormTemplate?.size, newCustomer])

  const disableAddToCart = useMemo(() => {
    // if (newCustomer) {
    //   if (!form?.customerName
    //     || !form?.customerAddress
    //     || !form?.customerWhatsApp
    //     || !measurementFormTemplate?.photos[0]?.src
    //     || !measurementFormTemplate?.photos[1]?.src
    //     || !measurementFormTemplate?.photos[2]?.src
    //   ) {
    //     return true
    //   }
    // }
    // return isCustomMeasurement
    if (newCustomer) {
      if (!form?.customerName
        || !form?.customerAddress
        || !form?.customerWhatsApp
        || !measurementFormTemplate?.photos[0]?.src
        || !measurementFormTemplate?.photos[1]?.src
        || !measurementFormTemplate?.photos[2]?.src
      ) {
        return true
      }
    } else {
      return isCustomMeasurement
    }
  }, [form?.customerAddress, form?.customerName, form?.customerWhatsApp, isCustomMeasurement, measurementFormTemplate?.photos, newCustomer])


  const handleAddToCart = useCallback(async () => {
    let dataFromStorage = JSON.parse(localStorage.getItem("cart")) || [];
    const selectedSize =
      sizeMeasurement.length > 0 && !isCustomMeasurement
        ? sizeMeasurement[selectedMeasurementId]
        : measurementFormTemplate;
    const tempCustomerCode = customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === (newCustomer ? '62' + form?.customerWhatsApp?.toUpperCase() : form?.customerWhatsApp?.toUpperCase()))?.code || ''
    if (dataFromStorage?.find(obj => obj?.customerCode === tempCustomerCode)?.sizes?.find(obj => obj?.code === selectedSize?.code)?.items?.find(obj => obj?.fabrics[0]?.code === data?.productCode && obj?.notes === notes)) {
      setShowConfirmSameItem(true)
    } else {
      setShowConfirmSameItem(false)
    }
    if (newCustomer) {
      try {
        const response = await addCustomer(newCustomerForm)
        setCustomerCode(response?.data?.code)
        const addToCartObj = addToCart()?.map(obj => {
          if (obj?.customerWhatsApp === '62' + form?.customerWhatsApp) {
            return {
              ...obj,
              customerCode: response?.data?.code,
              sizes: [
                {
                  ...obj?.sizes[0],
                  code: response?.data?.size?.code,
                  // sizeName: response?.data?.size?.name,
                  photos: response?.data?.size?.photos
                }
              ]
            }
          } else {
            return {
              ...obj
            }
          }
        })
        setObjectToSave(JSON?.stringify(addToCartObj))
        if (!showConfirmSameItem) {
          localStorage?.setItem("cart", JSON?.stringify(addToCartObj));
          setShowAddToCart(true);
        }
      } catch (err) {
        setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
        console.log(err?.response?.data?.error || err, 'error on creating new customer')
      }
    } else {
      setObjectToSave(JSON?.stringify(addToCart()))
      if (!showConfirmSameItem) {
        localStorage?.setItem("cart", JSON?.stringify(addToCart()));
        setShowAddToCart(true);
      }

    }
  }, [addToCart, customerList, data?.productCode, form?.customerWhatsApp, isCustomMeasurement, measurementFormTemplate, newCustomer, newCustomerForm, notes, selectedMeasurementId, showConfirmSameItem, sizeMeasurement])

  useEffect(() => {
    if (sizeMeasurement?.length) {
      setSelectedMeasurementId(sizeMeasurement?.findIndex(obj => obj?.code === selectedMeasurementCode?.replace('#', '')))
    }
  }, [selectedMeasurementCode, sizeMeasurement])

  useEffect(() => {
    setSelectedMeasurementCode('')
  }, [form?.customerName])

  const [customerSearch, setCustomerSearch] = useState('')



  const measurementTemplate = useMemo(() => {
    return [
      {
        title: "Customer",
        component: !newCustomer ? (
          <div className={Styles.customer}>
            <InputField
              placeholder={"Select Existing Customer"}
              isDropdown
              dropdownOptions={customerList?.map((obj) => `${obj?.name} - ${obj?.phoneNumber}`)}
              // value={form?.customerName}
              value={customerSearch}
              setValue={(newVal) => {
                setCustomerSearch(newVal)
                const findCustomer = newVal?.includes(' - ')
                  ? customerList.find(
                    (el) => el?.phoneNumber === newVal?.split(' - ')[1]
                  )
                  : null
                return setForm((f) => {
                  return {
                    ...f,
                    customerName: newVal?.includes(' - ') ? newVal?.split(' - ')[0] : newVal,
                    customerAddress: findCustomer?.address,
                    customerWhatsApp: findCustomer?.phoneNumber,
                    customerEmail: findCustomer?.email,
                    customerCode: findCustomer?.code,
                  };
                });
              }}
            />
            <div className={Styles.orSeparator}>
              <div />
              <span>OR</span>
              <div />
            </div>
            <Button
              variant="secondary"
              withIcon
              iconName={"plus"}
              size="lg"
              buttonText="Add New Customer"
              onClick={() => {
                setNewCustomer(true);

                // let category = 'SHIRT';
                // let sizes = [];
                // if (category === "SHIRT") {
                //   sizes = shirtSizes
                // } else if (category === "SUIT") {
                //   sizes = suitSizes
                // } else if (category === "TROUSER") {
                //   sizes = trouserSizes
                // }
                // setMeasurementFormTemplate((prev) => ({
                //       ...prev,
                //       size: sizes,
                // }));

                setSizeMeasurement([]);
                setForm({
                  customerName: "",
                  customerAddress: "",
                  customerWhatsApp: "",
                  customerEmail: "",
                });
              }}
            />
          </div>
        ) : (
          <div className={Styles.new}>
            {
              !newCustomer
              &&
              <Button
                variant="tertiary"
                buttonText="Cancel"
                withIcon
                iconName={"x-mark"}
                customClassName={Styles.float}
                disableActive
                disableHover
                onClick={() => setNewCustomer(false)}
              />
            }

            <InputField
              title={"Customer Name"}
              placeholder={"Customer name"}
              value={form?.customerName}
              setValue={(newVal) =>
                setForm((f) => {
                  return {
                    ...f,
                    customerName: newVal,
                  };
                })
              }
              required
            />
            <InputField
              title={"Customer Address"}
              placeholder={"Customer address"}
              value={form?.customerAddress}
              setValue={(newVal) =>
                setForm((f) => {
                  return {
                    ...f,
                    customerAddress: newVal,
                  };
                })
              }
              required
            />
            <InputField
              title={"Customer Whatsapp"}
              type="tel"
              placeholder={"8xxxx"}
              value={form?.customerWhatsApp}
              setValue={(newVal) =>
                setForm((f) => {
                  return {
                    ...f,
                    customerWhatsApp: newVal,
                  };
                })
              }
              required
            />
            <InputField
              title={"Customer Email"}
              placeholder={"Customer email"}
              value={form?.customerEmail}
              setValue={(newVal) =>
                setForm((f) => {
                  return {
                    ...f,
                    customerEmail: newVal,
                  };
                })
              }
            />
          </div>
        ),
      },
      {
        title: "Product",
        component: (
          <div className={Styles.fabricCard}>
            <div className={Styles.cardImage}>
              <img src={data.img} alt="product" />
            </div>
            <div className={Styles.cardContent}>
              <div className={Styles.label}>
                <p>Ready To Wear</p>
              </div>
              <div className={Styles.detail}>
                <div className={Styles.leftSection}>
                  <p>{data.name}</p>
                  <p>{data.productCode}</p>
                </div>
                <div className={Styles.rightSection}>
                  <p>1x</p>
                  <p>{data?.price?.toLocaleString("id")}</p>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Size Measurements 1",
        component: !isCustomMeasurement ? (
          <div className={Styles.sizes}>
            {sizeMeasurement.length > 0 ? (
              sizeMeasurement.map((el, idx) => (
                <div key={idx}>
                  <MeasurementCard
                    index={idx}
                    // key={idx}
                    // withRadioButton={false}
                    onSelect={(val) => {
                      setSelectedMeasurementCode(val);
                      setSelectedMeasurementId(idx);
                    }}
                    isChecked={"#" + el.code === selectedMeasurementCode}
                    name={el.name}
                    code={"#" + el.code}
                    measurements={el.size}
                    // photos={el.photos.map((el) =>
                    //   el?.uri ? fileBaseUrl + el?.uri : el
                    // )}
                    photos={el.photos.filter(obj => obj?.uri)?.map((el) => el?.uri ? fileBaseUrl + el?.uri : el)}
                    isEditMode={el?.code === measurementToEdit?.code}
                    sizes={sizeMeasurement}
                    setSizes={setSizeMeasurement}
                    defaultMeasurements={measurementFormTemplate?.size}
                    handleEdit={() => setMeasurementToEdit(el)}
                    handleDelete={async () => {
                      try {
                        await deletePattern(el?.code)
                        await fetchSize()
                      } catch (err) {
                        setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
                        console.log(err?.response?.data?.error || err, 'error on creating new customer')
                      }
                    }}
                    withCancelEditButton
                    onCancelEdit={() => setMeasurementToEdit(null)}
                    hasToClickSave
                    withSaveButton
                    onSave={async () => {
                      try {
                        await editSizeMeasurement(
                          customerList?.find(obj => obj?.name?.toUpperCase() === form?.customerName?.toUpperCase())?.code || '',
                          el,
                          el?.code
                        )
                        await fetchSize()
                        setMeasurementToEdit(null)
                      } catch (err) {
                        setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
                      }
                    }}
                  />
                </div>
              ))
            ) : (
              <MeasurementCard
                // key={idx}
                // withRadioButton={false}
                onSelect={(val) => setSelectedMeasurementCode(val)}
                isChecked={""}
                name={"Default"}
                code={""}
                measurements={data.measurements.map((el) => ({
                  ...el,
                  value: el.value + " cm",
                }))}
                // photos={}
                withRadioButton={false}
              />
            )}
            <div style={{ display: "grid", marginTop: "18px" }}>
              <Button
                variant="secondary"
                withIcon
                iconName={"plus"}
                size="lg"
                buttonText="Add Other Measurement"
                onClick={() => {
                  setIsCustomMeasurement(true);
                  // setSelectedMeasurementCode("");
                  // setSelectedMeasurementId("");
                }}
              />
            </div>
          </div>
        ) : (
          <div className={Styles.customSize}>
            <div>
              <div className={Styles.customName}>
                {editCustomNameMode ? (
                  <input
                    placeholder="Size Name"
                    value={measurementFormTemplate.name}
                    onChange={(e) =>
                      setMeasurementFormTemplate((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                ) : (
                  <p>{measurementFormTemplate?.name}</p>
                )}
                {!editCustomNameMode && (
                  <button
                    onClick={() => {
                      setEditCustomNameMode(true);
                      setMeasurementFormTemplate((prev) => ({
                        ...prev,
                        name: "",
                      }));
                    }}
                  >
                    <Icon icon={"pencil"} size={24} />
                  </button>
                )}
              </div>

              {!newCustomer
                &&

                <Button
                  variant="tertiary"
                  buttonText="Back to default size"
                  withIcon
                  iconName={"x-mark"}
                  disableActive
                  disableHover
                  onClick={() => setIsCustomMeasurement(false)}
                />
              }
            </div>
            <div className={Styles.field}>
              {measurementFormTemplate?.size?.map((each, i) => (
                <MeasurementField
                  key={i}
                  title={each?.title}
                  value={each?.value}
                  setValue={(newVal) => {
                    const newTemplate = measurementFormTemplate?.size?.map(
                      (obj) => {
                        if (obj?.name === each?.name) {
                          return {
                            ...obj,
                            value: newVal,
                          };
                        }
                        return obj;
                      }
                    );
                    setMeasurementFormTemplate((prev) => ({
                      ...prev,
                      size: newTemplate,
                    }));
                  }}
                  placeholder={each?.placeholder}
                  denom={"cm"}
                  extraTitleLabel={
                    each?.name?.toLowerCase()?.includes("sleeves") ? (
                      <div className={Styles.radios}>
                        <div
                          onClick={() => {
                            setData((f) => {
                              return {
                                ...f,
                                sleevesType: "long",
                              };
                            });
                          }}
                        >
                          <Radio isChecked={data?.sleevesType === "long"} />
                          <span>Long</span>
                        </div>
                        <div
                          onClick={() => {
                            setData((f) => {
                              return {
                                ...f,
                                sleevesType: "short",
                              };
                            });
                          }}
                        >
                          <Radio isChecked={data?.sleevesType === "short"} />
                          <span>Short</span>
                        </div>
                      </div>
                    ) : undefined
                  }
                />
              ))}
            </div>
            <p className={Styles.titleSize}>Photos</p>
            <div className={Styles.photos}>
              {measurementFormTemplate.photos?.map((eachP, iP) => (
                <SampleCard
                  key={iP}
                  // isFullWidth
                  size="100%"
                  isOverview
                  src={eachP?.src}
                  setSrc={(newSrc) => {
                    const newPhotos = [...measurementFormTemplate.photos];
                    newPhotos[iP].src = newSrc || "";
                    measurementFormTemplate.photos = newPhotos;
                    setMeasurementFormTemplate((prev) => ({
                      ...prev,
                      photos: newPhotos,
                    }));
                  }}
                />
              ))}
            </div>
            {!newCustomer && (
              <div style={{ display: "grid", marginTop: "18px" }}>
                <Button
                  variant="secondary"
                  withIcon
                  iconName={"saved"}
                  size="lg"
                  buttonText="Save"
                  onClick={() => {
                    handleSaveAddMeasurement();
                    // setSelectedMeasurementCode("");
                    // setSelectedMeasurementId("");
                  }}
                />
              </div>
            )}
          </div>
        ),
      },
      {
        title: "Notes",
        component: (
          <TextArea placeholder="Notes" value={notes} setValue={setNotes} />
        ),
      },
    ];
  }, [newCustomer, customerList, customerSearch, form?.customerName, form?.customerAddress, form?.customerWhatsApp, form?.customerEmail, data.img, data.name, data.productCode, data?.price, data.measurements, data?.sleevesType, isCustomMeasurement, sizeMeasurement, editCustomNameMode, measurementFormTemplate, notes, selectedMeasurementCode, measurementToEdit?.code, fetchSize, handleSaveAddMeasurement]);

  const [summaryForm, setSummaryForm] = useState({
    customerName: "",
    customerAddress: "",
    customerWhatsApp: "",
    customerEmail: "",
    customerFrontPhoto: null,
    customerBackPhoto: null,
    customerSidePhoto: null,
    paymentType: "full",
    paymentMethod: "cash",
  });

  const handleChangeSummaryForm = useCallback((newVal, name) => {
    setSummaryForm((f) => ({ ...f, [name]: newVal }));
  }, []);



  const invoiceTemplate = useMemo(() => {
    return [
      {
        title: "",
        component: (
          <CartItemDetailsCard
            // checkedItems={checkedItems}
            // setCheckedItems={setCheckedItems}
            item={data}
            // setSelectedItem={setSelectedItem}
            setMode={setMode}
            denom="cm"
            // onDeleteItem={() => {
            //   const newList = [...list]
            //   const index = newList?.indexOf(item)
            //   if (index !== -1) {
            //     newList.splice(index, 1)
            //     setList(newList)
            //   }
            // }}
            noActions
            noChekcbox
          />
        ),
      },
      {
        title: "Customer Details",
        component: (
          <div className={Styles.customerInfo}>
            <InputField
              title={"Customer Name"}
              placeholder={"Customer name"}
              value={summaryForm?.customerName}
              setValue={(newVal) =>
                handleChangeSummaryForm(newVal, "customerName")
              }
            />
            <InputField
              title={"Customer Address"}
              placeholder={"Customer address"}
              value={summaryForm?.customerAddress}
              setValue={(newVal) =>
                handleChangeSummaryForm(newVal, "customerAddress")
              }
            />
            <InputField
              type="tel"
              title={"Customer WhatsApp"}
              placeholder={"Customer WhatsApp"}
              value={summaryForm?.customerWhatsApp}
              setValue={(newVal) =>
                handleChangeSummaryForm(newVal, "customerWhatsApp")
              }
            />
            <InputField
              title={"Customer Email"}
              placeholder={"example@gmail.com"}
              value={summaryForm?.customerEmail}
              setValue={(newVal) =>
                handleChangeSummaryForm(newVal, "customerEmail")
              }
            />
          </div>
        ),
      },
      {
        title: "",
        // isCustomRender: true,
        component: <PriceDetailsCard items={[data]} subTotal={data?.price} />,
      },

      {
        title: "Payment",
        component: (
          <RadioField
            title={""}
            value={summaryForm?.paymentType}
            setValue={(newVal) =>
              handleChangeSummaryForm(newVal, "paymentType")
            }
            options={paymentOptions}
          />
        ),
      },
      {
        title: "Payment Method",
        component: (
          <RadioField
            title={""}
            value={summaryForm?.paymentMethod}
            setValue={(newVal) =>
              handleChangeSummaryForm(newVal, "paymentMethod")
            }
            options={paymentMethodOptions}
          />
        ),
      },
      {
        title: "",
        component: (
          <Button
            variant="primary"
            buttonText="Print Invoice"
            size="lg"
            disableActive
          />
        ),
      },
    ];
  }, [
    data,
    handleChangeSummaryForm,
    paymentMethodOptions,
    paymentOptions,
    summaryForm?.customerAddress,
    summaryForm?.customerEmail,
    summaryForm?.customerName,
    summaryForm?.customerWhatsApp,
    summaryForm?.paymentMethod,
    summaryForm?.paymentType,
  ]);

  const template = useMemo(() => {
    switch (mode) {
      case "invoice":
        return {
          backNavTitle: "Back to Size Measure",
          onClickBackNav: () => {
            setMode("measure");
          },
          title: "Invoice",
          content: (
            <div className={Styles.invoice}>
              {invoiceTemplate?.map((each, i) => (
                <div key={i}>
                  {each?.title && <h4>{each?.title}</h4>}
                  {each?.component}
                </div>
              ))}
            </div>
          ),
        };
      default:
        return {
          backNavTitle: "Back",
          onClickBackNav: () => {
            navigate(-1);
          },
          title: "Size Measure",
          content: (
            <div className={Styles.measure}>
              {!newCustomer && !form?.customerName
                ? measurementTemplate?.slice(0, 2)?.map((each, i) => (
                  <div key={i}>
                    {each?.title && <h4>{each?.title}</h4>}
                    {each?.component}
                  </div>
                ))
                : measurementTemplate?.map((each, i) => (
                  <div key={i}>
                    {each?.title && <h4>{each?.title}</h4>}
                    {each?.component}
                  </div>
                ))}
              {!newCustomer && !form?.customerName ? (
                <></>
              ) : (
                <Button
                  customClassName={Styles.fullWidth}
                  variant="primary"
                  size="lg"
                  buttonText="Add To Cart"
                  withIcon
                  iconName={"shopping-cart"}
                  iconPlacement="start"
                  disabled={disableAddToCart}
                  onClick={handleAddToCart}
                />
              )}
            </div>
          ),
        };
    }
  }, [disableAddToCart, form?.customerName, handleAddToCart, invoiceTemplate, measurementTemplate, mode, navigate, newCustomer]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Button
          variant="tertiary"
          size="lg"
          disableActive
          buttonText={template?.backNavTitle}
          onClick={template?.onClickBackNav}
          withIcon
          iconName={"chevron-left"}
          iconPlacement="start"
          customIconSize={16}
          fixedPadding={"0px"}
        />
        <h3>{template?.title}</h3>
      </div>
      {template?.content}
      <ModalWrapper
        show={showAddToCart}
        desktopPositioning="center"
        mobilePositioning="center"
      >
        {showAddToCart && (
          <AddToCartModal
            src={data?.img}
            name={data?.name}
            size={data?.size || ""}
            price={data?.price}
            id={data?.id}
            data={data}
            customerID={customerCode}
          />
        )}
      </ModalWrapper>
      <ModalWrapper
        show={showConfirmSameItem}
        desktopPositioning="center"
        mobilePositioning="center"
      >
        {showConfirmSameItem && (
          <AddToCartModal
            src={data?.img}
            name={data?.name}
            size={data?.size || ""}
            price={data?.price}
            id={data?.id}
            data={data}
            customerID={customerCode}
            type='confirmation'
            onCancel={()=> setShowConfirmSameItem(false)}
            onSubmit={()=> {
              localStorage?.setItem("cart", objectToSave)
              setShowConfirmSameItem(false)
              setShowAddToCart(true)
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper
        show={error}
        setShow={() => setError(null)}
      >
        <WideErrorModal
          code={error?.code || 500}
          error={error?.message || 'Something went wrong!'}
          withButton
          onClose={() => setError(null)}
        />

      </ModalWrapper>
    </div>
  );
}
