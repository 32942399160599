import Images from '@Theme/Images'
import Styles from './style.module.scss'
import Avatar from '@Atom/Avatar'

export default function ErrorModal({
  title = 'Submit Failed',
  descArr = ['Something went wrong.', 'Please try again later.'],
  // setShow=()=> {}
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Avatar src={Images.CIRCLE_X_RED} size='72px' />
      </div>
      <div className={Styles.content}>
        <h3>{title}</h3>
        <div>
          {descArr?.map((each, i) => (
            <p key={i}>{each}</p>
          ))}
        </div>
      </div>
    </div>
  )
}