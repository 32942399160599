import { deliveryTypes, masterDeliveryTypes } from '@Assets/data/deliveryTypes'
import { employeeTypes } from '@Assets/data/employeeTypes'
import { orderMasterTypes } from '@Assets/data/orderMasterTypes'
import { stockTypes } from '@Assets/data/stockTypes'
import Button from '@Atom/Button'
import Checkbox from '@Atom/Checkbox'
import Icon from '@Atom/Icon'
import ModalWrapper from '@Atom/ModalWrapper'
import Pagination from '@Atom/Pagination'
import QuestioningEmptyHandler from '@Atom/QuestioningEmptyHandler'
import Searchbar from '@Atom/Searchbar'
import { fileBaseUrl } from '@Config/api'
import { capitalizeWords } from '@Helpers/capitalizeWords'
import { makeRandomString } from '@Helpers/makeRandomString'
import { priceFormat } from '@Helpers/priceFormat'
import { useDebounce } from '@Hooks/useDebounce'
import useQuery from '@Hooks/useQuery'
import useWindowSize from '@Hooks/useWindowSize'
import DeleteModal from '@Molecule/_modals/DeleteModal'
import DeliveryModal from '@Molecule/_modals/DeliveryModal'
import FeeSetterModal from '@Molecule/_modals/FeeSetterModal'
import WideErrorModal from '@Molecule/_modals/WideErrorModal'
import { downloadCheckpoints, downloadCustomers, downloadDeliveries, downloadEmployees, downloadInvoices, downloadOrders, downloadStocks, downloadWorkshops } from '@Services/download'
import { deleteStocks } from '@Services/stock'
import moment from 'moment'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'
import { deleteDeliveries } from '@Services/delivery'
import LoadingModal from '@Molecule/_modals/LoadingModal'

const ExcelJS = require("exceljs");


export default function TableSet({
  type,
  data,
  columns,
  totalPage,
  totalData = data?.length,
  onClickRow = () => { },
  onClickFilter = () => { },
  onClickAdd = () => { },
  noAdd,
  isLoading,
  setIsLoading = () => { },
  trigger = '',
  setTrigger = () => { },
  deliverSync,
  feeSync = [],
  withNumbering = false,
  setParentTabType = () => { },
  customHeight = '100%',
  paginationExtraLabels = [],
  additionalQuery = '',
  withSkeleton = false,
  disableCheck = () => { return false },
}) {
  const [value, setValue] = useState(30);

  const [error, setError] = useState()
  const [currentSortBy, setCurrentSortBy] = useState('')


  const [tabType, setTabType] = useState('')

  const location = useLocation();
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [showFeeSetter, setShowFeeSetter] = useState(false)

  const [showDelete, setShowDelete] = useState(false)

  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const query = useQuery()
  const typeQuery = query.get('t') || ''
  const pageQuery = query.get('p') || ''
  // const deliveryTypeQuery = query.get('dvt') || ''
  const dateFrom = query.get('df')
  const dateTo = query.get('dt')

  // useEffect(() => {
  //   if (tabType !== typeQuery) {
  //     setIsLoading(true)
  //   } else {
  //     setIsLoading(false)
  //   }
  // }, [setIsLoading, tabType, typeQuery])



  useEffect(() => {
    setParentTabType(tabType)
  }, [setParentTabType, tabType])


  const [searchTerm, setSearchTerm] = useState(query.get('s') || '')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  useEffect(() => {
    setSelectedPage(1)
  }, [debouncedSearchTerm, typeQuery])


  const [showDeliveryModal, setShowDeliveryModal] = useState(false)

  const [expandStatus, setExpandStatus] = useState(false)

  const [checkedArr, setCheckedArr] = useState([])


  const handleCheck = (data) => {
    const id = data?.id
    if (!checkedArr?.find(obj => obj?.id === id)) {
      setCheckedArr([...checkedArr, data])
    } else {
      // const index = checkedArr.indexOf(id)
      const newArr = [...checkedArr]

      // if (index > -1) {
      //   newArr.splice(index, 1);
      // }
      setCheckedArr(newArr?.filter(obj => obj?.id !== id))
    }
  }

  const selectedData = useMemo(() => {
    // const arr = []
    // for (let i = 0; i < checkedArr.length; i++) {
    //   const found = data.find(obj => obj?.id === checkedArr[i]?.id)
    //   if (found) {
    //     arr.push(found)
    //   }
    // }
    // return arr
    return checkedArr
  }, [checkedArr])


  const template = useMemo(() => {
    switch (type) {
      case 'stock':
        return ({
          title: 'Stock',
          icon: 'rectangle-stack',
          deleteLabel: 'stock',
          addButton: true,
          addButtonText: 'Add Stock',
          searchPlaceholder: 'Search products name, product code, etc.',
          itemListName: 'stocks',
          selectComponents: [
            <Button
              buttonText='Deliver'
              variant='secondary'
              withIcon
              iconName={'paper-airplane'}
              iconPlacement='end'
              size='md'
              onClick={() => setShowDeliveryModal(true)}
              disabled={!checkedArr?.length}
            />
          ]
        })
      case 'stock-store':
        return ({
          title: 'Stock',
          icon: 'rectangle-stack',
          deleteLabel: 'stock',
          addButton: true,
          addButtonText: 'Add Stock',
          searchPlaceholder: 'Search products name, product code, etc.',
          itemListName: 'stocks',
          selectComponents: [
            <Button
              buttonText='Deliver To Checkpoint'
              variant='secondary'
              withIcon
              iconName={'paper-airplane'}
              iconPlacement='end'
              size='md'
              onClick={() => setShowDeliveryModal(true)}
              disabled={!checkedArr?.length}
            />
          ]
        })
      case 'order':
        return ({
          title: 'Order',
          icon: 'archive-box',
          deleteLabel: 'order',
          addButton: false,
          searchPlaceholder: 'Search orders',
          itemListName: 'orders',
          selectComponents: []
        })
      case 'order-director':
        return ({
          title: 'Order',
          icon: 'archive-box',
          deleteLabel: 'order',
          addButton: false,
          searchPlaceholder: 'Search orders',
          itemListName: 'orders',
          selectComponents: []
        })
      case 'invoice':
        return ({
          title: 'Invoice',
          icon: 'document-arrow-down',
          deleteLabel: 'invoice',
          addButton: true,
          addButtonText: 'Quick Invoice',
          searchPlaceholder: 'Search orders',
          itemListName: 'orders',
          selectComponents: []
        })
      case 'delivery':
        return ({
          title: 'Delivery',
          icon: 'paper-airplane',
          deleteLabel: 'delivery',
          addButton: false,
          searchPlaceholder: 'Search deliveries',
          itemListName: 'schedules',
          selectComponents: [
            <Button
              buttonText='Set as Priority'
              variant='secondary'
              size='md'
              fixedPadding={'0.75rem 1.2rem'}
              disableActive
            />,
            <div className={Styles.editStatus}>
              <Button
                buttonText='Edit Status'
                variant='secondary'
                withIcon
                iconName={!expandStatus ? 'chevron-down' : 'chevron-up'}
                iconPlacement='end'
                size='md'
                onClick={() => setExpandStatus(!expandStatus)}
                disableActive
              />
              {expandStatus && (
                <div className={Styles.options}>
                  <button>Delivered</button>
                  <button>Ready</button>
                  <button>On Delivery</button>
                </div>
              )}
            </div>

          ]
        })
      case 'delivery-director':
        return ({
          title: 'Delivery',
          icon: 'paper-airplane',
          deleteLabel: 'delivery',
          addButton: false,
          searchPlaceholder: 'Search deliveries',
          itemListName: 'schedules',
          selectComponents: [
            <Button
              buttonText='Set as Priority'
              variant='secondary'
              size='md'
              fixedPadding={'0.75rem 1.2rem'}
              disableActive
            />,
            <div className={Styles.editStatus}>
              <Button
                buttonText='Edit Status'
                variant='secondary'
                withIcon
                iconName={!expandStatus ? 'chevron-down' : 'chevron-up'}
                iconPlacement='end'
                size='md'
                onClick={() => setExpandStatus(!expandStatus)}
                disableActive
              />
              {expandStatus && (
                <div className={Styles.options}>
                  <button>Delivered</button>
                  <button>Ready</button>
                  <button>On Delivery</button>
                </div>
              )}
            </div>

          ]
        })
      case 'checkpoint':
        return ({
          title: 'Checkpoint',
          icon: 'map-pin',
          deleteLabel: 'store',
          addButton: true,
          addButtonText: 'Add Store',
          searchPlaceholder: 'Search store',
          itemListName: 'stores',
          selectComponents: []
        })
      case 'workshop':
        return ({
          title: 'Workshop',
          icon: 'map-pin',
          deleteLabel: 'store',
          addButton: false,
          searchPlaceholder: 'Search product',
          itemListName: 'data',
          selectComponents: []
        })
      case 'workshop-request':
        return ({
          title: 'Workshop Requests',
          icon: 'map-pin',
          deleteLabel: 'store',
          addButton: false,
          searchPlaceholder: 'Search store',
          itemListName: 'stores',
          selectComponents: []
        })
      case 'employee':
        return ({
          title: 'Employee',
          icon: 'user-group',
          deleteLabel: 'employee',
          addButton: true,
          addButtonText: 'Add Employee',
          searchPlaceholder: 'Search employee',
          itemListName: 'employees',
          selectComponents: []
        })
      case 'employee-director':
        return ({
          title: 'List',
          icon: 'list-bullet',
          deleteLabel: 'employee',
          addButton: true,
          addButtonText: 'Add Employee',
          searchPlaceholder: 'Search employee',
          itemListName: 'employees',
          selectComponents: []
        })
      case 'fee':
        return ({
          title: 'Fee',
          icon: 'fee',
          deleteLabel: 'fee',
          addButton: false,
          searchPlaceholder: 'Search employee',
          itemListName: 'employees',
          selectComponents: []
        })
      case 'customer':
        return ({
          title: 'Customer',
          icon: 'heart',
          deleteLabel: 'customer',
          addButton: false,
          searchPlaceholder: 'Search customer',
          itemListName: 'customers',
          selectComponents: []
        })
      case 'log':
        return ({
          title: 'Log',
          icon: 'clock',
          deleteLabel: 'log',
          addButton: false,
          searchPlaceholder: 'Search log',
          itemListName: 'log',
          selectComponents: []
        })
      default:
        return ({
          title: 'Stock',
          icon: 'rectangle-stack',
          deleteLabel: 'stock',
          addButton: true,
          addButtonText: 'Add Stock',
          searchPlaceholder: 'Search products name, product code, etc.',
          itemListName: 'stocks',
          selectComponents: []
        })
    }
  }, [checkedArr, type, expandStatus])

  const [expandSelect, setExpandSelect] = useState(false)

  useEffect(() => {
    setExpandSelect(false)
    setCheckedArr([])
  }, [trigger])

  const onClickSelect = () => {
    setExpandSelect(true)
  }


  // eslint-disable-next-line no-unused-vars
  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, [expandSelect]);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const currentQuery = useMemo(() => {
    const arr = []
    let str = ''
    for (const param of query) {
      arr.push(param);
    }

    const raw = arr.join(',')?.split(',')

    const strArr = []

    for (let i = 0; i < raw.length / 2; i++) {
      strArr.push({
        key: raw[i + i],
        value: raw[i + i + 1]
      })
    }


    for (let i = 0; i < strArr.length; i++) {
      const s = strArr[i]
      str += (i === 0 ? `${s?.key}=${s?.value}` : `&${s?.key}=${s?.value}`)
    }


    return str


  }, [query])


  const allQueries = useMemo(() => {
    const arr = []
    for (const param of query) {
      arr.push(param);
    }

    const additionals = additionalQuery ? additionalQuery?.slice(1)?.split('&')?.map(obj => obj?.split('=')) : []

    for (const param of additionals) {
      if (!query?.get(param[0])) {
        arr.push(param);
      } else {
        const index = arr?.findIndex(obj => obj[0] === param[0])
        arr[index][1] = param[1]
      }
    }

    // const newArr = !query?.get('p') ? [...arr, ['p', selectedPage]] : [...arr]
    const newArr =
      !query?.get('p')
        ? (
          !query?.get('t') ?
            (!query?.get('s')
              ?
              (
                !query?.get('tr')
                  ?
                  [...arr, ['p', selectedPage], ['t', tabType], ['s', debouncedSearchTerm], ['tr', trigger]]
                  :
                  [...arr, ['p', selectedPage], ['t', tabType], ['s', debouncedSearchTerm]]
              )
              :
              [...arr, ['p', selectedPage], ['t', tabType]]

            )
            :
            [...arr, ['p', selectedPage]]

        )
        : (
          [...arr]
        )

    return newArr

  }, [additionalQuery, debouncedSearchTerm, query, selectedPage, tabType, trigger])

  const fixedQuery = useMemo(() => {
    let str = ''

    const raw = allQueries.join(',')?.split(',')

    const strArr = []

    for (let i = 0; i < raw.length / 2; i++) {
      strArr.push({
        key: raw[i + i],
        value: raw[i + i] === 'p'
          ? selectedPage
          : (
            raw[i + i] === 't'
              ? tabType
              :
              (raw[i + i] === 's'
                ? debouncedSearchTerm
                : (
                  raw[i + i] === 'tr'
                    ?
                    trigger
                    :
                    raw[i + i + 1]
                )
              )
          )
      })
    }


    for (let i = 0; i < strArr.length; i++) {
      const s = strArr[i]
      str += (i === 0 ? `${s?.key}=${s?.value}` : `&${s?.key}=${s?.value}`)
    }


    return str

  }, [allQueries, debouncedSearchTerm, selectedPage, tabType, trigger])

  useEffect(() => {
    if (currentQuery !== fixedQuery) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [currentQuery, fixedQuery, setIsLoading, tabType, typeQuery])


  useEffect(() => {
    navigate(`${pathname}?${fixedQuery}`)
  }, [fixedQuery, pathname, navigate])

  const { width } = useWindowSize()


  // eslint-disable-next-line no-unused-vars
  const [isDownloading, setIsDownloading] = useState(false)


  const onDownload = async () => {
    const downloadType = query.get("t") || "";
    const search = query.get("s") || "";
    const priceFrom = query.get("pf") || "";
    const priceTo = query.get("pt") || "";
    const dateFrom = query.get("df") || query.get('of') || "";
    const dateTo = query.get("dt") || query.get('ot') || "";

    const payDateFrom = query.get("sf") || "";
    const payDateTo = query.get("st") || "";

    const defaultPaymentMethod = query?.get('is') ? query.get('is')?.split('::') : []
    const defaultPaymentStatus = query?.get('pp') ? query.get('pp')?.split('::')?.map(obj => +obj === 50 ? 'PAID' : 'PAID-OFF') : []

    const convertDate = (str) => {
      const arr = str?.split("/");
      return [arr[2], arr[1], arr[0]]?.join("-");
    };


    const dfStr = dateFrom ? convertDate(dateFrom) : "";
    const dtStr = dateFrom ? convertDate(dateTo) : "";

    const paymentMethodStr = defaultPaymentMethod[0] || ''
    const paymentStatusStr = defaultPaymentStatus[0] || ''
    const pfStr = payDateFrom ? convertDate(payDateFrom) : "";
    const ptStr = payDateTo ? convertDate(payDateTo) : "";

    const status = query.get('is') || ''

    const scheduledAt = query.get('sa') || ''
    const deliveredAt = query.get('da') || ''

    const role = query.get('r') || ''

    const checkpointCode = query.get('pl') || ''

    const defaultHaveBought = query.get('b')
    const defaultNumOfOrder = query.get('o')
    // const defaultOutstanding = query.get('ot')
    // const defaultHaveSpent = query.get('sp')

    const numOfItemLow = defaultHaveBought ? defaultHaveBought?.split('-')[0] : ''
    const numOfItemHigh = defaultHaveBought ? defaultHaveBought?.split('-')[1] : ''
    const numOfOrderLow = defaultNumOfOrder ? defaultNumOfOrder?.split('-')[0] : ''
    const numOfOrderHigh = defaultNumOfOrder ? defaultNumOfOrder?.split('-')[1] : ''

    let res
    let resData

    try {
      setIsDownloading(true)
      switch (type) {
        case 'stock':
          res = await downloadStocks(downloadType, search, priceFrom, priceTo, dfStr, dtStr)
          resData = res?.data?.products?.map(obj => {
            return {
              productName: obj?.name,
              productCode: obj?.productCode,
              fabricCode: obj?.fabric,
              qty: obj?.qty,
              description: obj?.description || '-',
              price: `Rp${priceFormat(obj?.price)}`,
              dateAdded: moment(new Date(obj?.createdAt))?.format('L'),
              checkpoint: obj?.store?.name,
              image: obj?.productImages?.length ? fileBaseUrl + obj?.productImages[0]?.url : ''
            }
          })
          break
        case 'stock-store':
          res = await downloadStocks(downloadType, search, priceFrom, priceTo, dfStr, dtStr)
          resData = res?.data?.products?.map(obj => {
            return {
              productName: obj?.name,
              productCode: obj?.productCode,
              fabricCode: obj?.fabric,
              qty: obj?.qty,
              description: obj?.description || '-',
              price: `Rp${priceFormat(obj?.price)}`,
              dateAdded: moment(new Date(obj?.createdAt))?.format('L'),
              checkpoint: obj?.store?.name,
              image: obj?.productImages?.length ? fileBaseUrl + obj?.productImages[0]?.url : ''
            }
          })
          break
        case 'order':
          res = await downloadOrders(downloadType?.toUpperCase() || 'TRANSACTION', search, dfStr, dtStr)
          resData = res?.data?.orders?.map(obj => {
            switch (tabType?.toUpperCase()) {
              case 'CUSTOMER':
                return {
                  customerName: obj?.customer?.name,
                  customerCode: obj?.customer?.code,
                  customerPhoneNumber: obj?.customer?.phoneNumber,
                  lastOrderDate: moment(obj?.order?.date)?.format('L'),
                  status: obj?.status,
                }
              case 'ITEM':
                return {
                  itemName: obj?.name,
                  itemType: obj?.type,
                  customerName: obj?.customerName,
                  customerPhoneNumber: obj?.phoneNumber,
                  orderStatus: obj?.status,
                }
              default:
                return {
                  orderID: obj?.code,
                  customerName: obj?.customer?.name,
                  customerCode: obj?.customer?.code,
                  customerPhoneNumber: obj?.customer?.phoneNumber,
                  orderStatus: obj?.status?.replaceAll('-', ' '),
                  salesmanName: obj?.sales?.name,
                  salesmanCode: obj?.sales?.code,
                  salesmanPhoneNumber: obj?.sales?.whatsAppNumber
                }
            }
          })
          break
        case 'order-director':
          res = await downloadOrders(downloadType?.toUpperCase() || 'TRANSACTION', search, dfStr, dtStr)
          resData = res?.data?.orders?.map(obj => {
            switch (tabType?.toUpperCase()) {
              case 'CUSTOMER':
                return {
                  customerName: obj?.customer?.name,
                  customerCode: obj?.customer?.code,
                  customerPhoneNumber: obj?.customer?.phoneNumber,
                  lastOrderDate: moment(obj?.order?.date)?.format('L'),
                  status: obj?.status,
                }
              case 'ITEM':
                return {
                  itemName: obj?.name,
                  itemType: obj?.type,
                  customerName: obj?.customerName,
                  customerPhoneNumber: obj?.phoneNumber,
                  orderStatus: obj?.status,
                }
              default:
                return {
                  orderID: obj?.code,
                  customerName: obj?.customer?.name,
                  customerCode: obj?.customer?.code,
                  customerPhoneNumber: obj?.customer?.phoneNumber,
                  orderStatus: obj?.status?.replaceAll('-', ' '),
                  salesmanName: obj?.sales?.name,
                  salesmanCode: obj?.sales?.code,
                  salesmanPhoneNumber: obj?.sales?.whatsAppNumber
                }
            }
          })
          break
        case 'invoice':
          res = await downloadInvoices(search, dfStr, dtStr, paymentMethodStr, paymentStatusStr, pfStr, ptStr)
          resData = res?.data?.invoices?.map(obj => {
            return {
              issuedAt: moment(obj?.createdAt)?.format('L'),
              code: obj?.code,
              customerName: obj?.customer?.name,
              customerCode: obj?.customer?.code,
              customerPhoneNumber: obj?.customer?.phone,
              customerAddress: obj?.customer?.address,
              numOfItems: obj?.numOfItems,
              fabrics: obj?.summaries?.map(obj => obj?.textiles)?.flat()?.map(t => t?.code)?.join(', '),
              sum: `Rp${priceFormat(obj?.sum)}`,
              dueDate: moment(new Date(obj?.dueDate))?.format('L'),
              paymentStatus: obj?.status?.replaceAll('-', ' '),
              paymentMethod: obj?.paymentMethod
            }
          })
          break
        case 'delivery':
          res = await downloadDeliveries(downloadType?.toUpperCase() || 'CUSTOMER', status, search, scheduledAt, deliveredAt)
          switch (tabType?.toUpperCase()) {
            case 'STOCK':
              resData = res?.data?.deliveries?.map(obj => {
                return {
                  ...obj,
                  scheduledAt: moment(obj?.scheduledAt)?.format('L'),
                  deliveredAt: obj?.deliveredAt ? moment(obj?.deliveredAt)?.format('L') : '',
                  deliveryID: obj?.code,
                  sourceCheckpoint: obj?.from?.name,
                  receivingCheckpoint: obj?.to?.name,
                  numOfItems: obj?.numOfItems,
                  items: obj?.items?.map(obj => `${obj?.name} (${obj?.code} / ${obj?.qty}x)`)?.join(', '),
                  status: obj?.status?.replaceAll('-', ' '),
                }
              })
              break
            default:
              resData = res?.data?.deliveries?.map(obj => {
                return {
                  ...obj,
                  scheduledAt: moment(obj?.scheduledAt)?.format('L'),
                  deliveredAt: obj?.deliveredAt ? moment(obj?.deliveredAt)?.format('L') : '',
                  deliveryID: obj?.code,
                  source: obj?.from?.name,
                  customerName: obj?.to?.name,
                  customerCode: obj?.to?.code,
                  customerPhoneNumber: obj?.to?.phone,
                  customerAddress: obj?.to?.address,
                  numOfItems: obj?.numOfItems,
                  status: obj?.status?.replaceAll('-', ' ')
                }
              })
          }
          break
        case 'delivery-director':
          res = await downloadDeliveries(downloadType?.toUpperCase() || 'CUSTOMER', status, search, scheduledAt, deliveredAt)
          switch (tabType?.toUpperCase()) {
            case 'STOCK':
              resData = res?.data?.deliveries?.map(obj => {
                return {
                  ...obj,
                  scheduledAt: moment(obj?.scheduledAt)?.format('L'),
                  deliveredAt: obj?.deliveredAt ? moment(obj?.deliveredAt)?.format('L') : '',
                  deliveryID: obj?.code,
                  sourceCheckpoint: obj?.from?.name,
                  receivingCheckpoint: obj?.to?.name,
                  numOfItems: obj?.numOfItems,
                  items: obj?.items?.map(obj => `${obj?.name} (${obj?.code} / ${obj?.qty}x)`)?.join(', '),
                  status: obj?.status?.replaceAll('-', ' '),
                }
              })
              break
            default:
              resData = res?.data?.deliveries?.map(obj => {
                return {
                  ...obj,
                  scheduledAt: moment(obj?.scheduledAt)?.format('L'),
                  deliveredAt: obj?.deliveredAt ? moment(obj?.deliveredAt)?.format('L') : '',
                  deliveryID: obj?.code,
                  source: obj?.from?.name,
                  customerName: obj?.to?.name,
                  customerCode: obj?.to?.code,
                  customerPhoneNumber: obj?.to?.phone,
                  customerAddress: obj?.to?.address,
                  numOfItems: obj?.numOfItems,
                  status: obj?.status?.replaceAll('-', ' ')
                }
              })
          }
          break
        case 'checkpoint':
          res = await downloadCheckpoints(search)
          resData = res?.data?.products?.map(obj => {
            return {
              ...obj
            }
          })
          break
        case 'workshop':
          res = await downloadWorkshops(search)
          resData = res?.data?.processes?.map(obj => {
            return {
              ...obj,
              productName: obj?.name,
              productType: obj?.type,
              notes: obj?.notes,
              fabrics: obj?.fabrics?.filter(obj => obj?.type !== 'ALTER')?.map(obj => `${obj?.name} (${obj?.code} / ${obj?.qty}x)`)?.join(', '),
              status: obj?.status?.replaceAll('-', ' '),
              customerName: obj?.customerName,
              customerPhoneNumber: obj?.customerPhoneNumber,
              orderID: obj?.orderID,
            }
          })
          break
        case 'workshop-request':
          res = await downloadWorkshops(search)
          resData = res?.data?.processes?.map(obj => {
            return {
              ...obj,
              productName: obj?.name,
              productType: obj?.type,
              notes: obj?.notes,
              fabrics: obj?.fabrics?.filter(obj => obj?.type !== 'ALTER')?.map(obj => `${obj?.name} (${obj?.code} / ${obj?.qty}x)`)?.join(', '),
              status: obj?.status?.replaceAll('-', ' '),
              customerName: obj?.customerName,
              customerPhoneNumber: obj?.customerPhoneNumber,
              orderID: obj?.orderID,
            }
          })
          break
        case 'employee':
          res = await downloadEmployees(downloadType, search, dfStr, dtStr, role, checkpointCode)
          resData = res?.data?.users?.map(obj => {
            return {
              ...obj,
              code: obj?.code,
              fullName: obj.fullName,
              whatsAppNumber: obj?.whatsAppNumber,
              role: obj?.role,
              nickname: obj?.nickName,
              placement: obj?.placement?.name,
            }
          })
          break
        case 'fee':
          res = await downloadEmployees(downloadType || 'TAILOR', search, dfStr, dtStr, role, checkpointCode)
          resData = res?.data?.users?.map(obj => {
            return {
              ...obj,
              lastUpdate: moment(obj.lastUpdate)?.format('L'),
              employeeName: obj?.name,
              employeeRole: obj?.role,
              employeePhoneNumber: obj?.phoneNumber,
              numOfItems: obj?.items,
              fee: `Rp${priceFormat(obj?.fee)}`,
            }
          })
          break
        case 'employee-director':
          res = await downloadEmployees(downloadType, search, dfStr, dtStr, role, checkpointCode)
          resData = res?.data?.users?.map(obj => {
            return {
              ...obj,
              code: obj?.code,
              fullName: obj.fullName,
              whatsAppNumber: obj?.whatsAppNumber,
              role: obj?.role,
              nickname: obj.nickName,
              placement: obj.placement?.name,
            }
          })
          break
        case 'customer':
          res = await downloadCustomers(search, dfStr, dtStr, numOfItemLow, numOfItemHigh, numOfOrderLow, numOfOrderHigh)
          resData = res?.data?.map(obj => {
            return {
              ...obj,
              code: obj.code,
              name: obj.name,
              phoneNumber: obj.phoneNumber,
              numOfOrders: obj.numOfOrders,
              numOfItems: obj.numOfItems,
              outstanding: `Rp${priceFormat(obj?.totalOutstanding)}`,
              revenue: `Rp${priceFormat(obj?.totalRevenue)}`,
            }
          })
          break
        default:
          break
      }

      try {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("My Sheet");
        sheet.properties.defaultRowHeight = 80;

        sheet.getRow(1).font = {
          // name: "Noto Sans",
          size: 16,
          bold: true,
        };
        let columns = []
        switch (type) {
          case 'stock':
            columns = [
              {
                header: "No.",
                key: "no",
                width: 5,
              },
              {
                header: 'Product Name',
                key: 'productName',
                width: 20
              },
              {
                header: 'Product Code',
                key: 'productCode',
                width: 20
              },
              {
                header: 'Fabric Code',
                key: 'fabricCode',
                width: 20
              },
              {
                header: 'Qty',
                key: 'qty',
                width: 10
              },
              {
                header: 'Description',
                key: 'description',
                width: 20
              },
              {
                header: 'Price',
                key: 'price',
                width: 20
              },
              {
                header: 'Date Added',
                key: 'dateAdded',
                width: 20
              },
              {
                header: 'Checkpoint',
                key: 'checkpoint',
                width: 20
              },
              {
                header: 'Image',
                key: 'image',
                width: 20,
                isHyperLink: true
              },
            ]

            break
          case 'stock-store':
            columns = [
              {
                header: "No.",
                key: "no",
                width: 5,
              },
              {
                header: 'Product Name',
                key: 'productName',
                width: 20
              },
              {
                header: 'Product Code',
                key: 'productCode',
                width: 20
              },
              {
                header: 'Fabric Code',
                key: 'fabricCode',
                width: 20
              },
              {
                header: 'Qty',
                key: 'qty',
                width: 10
              },
              {
                header: 'Description',
                key: 'description',
                width: 20
              },
              {
                header: 'Price',
                key: 'price',
                width: 20
              },
              {
                header: 'Date Added',
                key: 'dateAdded',
                width: 20
              },
              {
                header: 'Checkpoint',
                key: 'checkpoint',
                width: 20
              },
              {
                header: 'Image',
                key: 'image',
                width: 20,
                isHyperLink: true
              },
            ]

            break
          case 'order':
            switch (tabType?.toUpperCase()) {
              case 'CUSTOMER':
                columns = [
                  {
                    header: 'No.',
                    key: 'no',
                    width: 5
                  },
                  {
                    header: 'Customer Name',
                    key: 'customerName',
                    width: 20
                  },
                  {
                    header: 'Customer Code',
                    key: 'customerCode',
                    width: 20
                  },
                  {
                    header: 'Customer Phone Number',
                    key: 'customerPhoneNumber',
                    width: 20
                  },
                  {
                    header: 'Last Order Date',
                    key: 'lastOrderDate',
                    width: 15
                  },
                  {
                    header: 'Status',
                    key: 'status',
                    width: 15
                  },
                ]
                break
              case 'ITEM':
                columns = [
                  {
                    header: 'No.',
                    key: 'no',
                    width: 5
                  },
                  {
                    header: 'Item Name',
                    key: 'itemName',
                    width: 20
                  },
                  {
                    header: 'Item Type',
                    key: 'itemType',
                    width: 20
                  },
                  {
                    header: 'Customer Name',
                    key: 'customerName',
                    width: 20
                  },
                  {
                    header: 'Customer Phone Number',
                    key: 'customerPhoneNumber',
                    width: 20
                  },
                  {
                    header: 'Order Status',
                    key: 'orderStatus',
                    width: 15
                  }
                ]
                break
              default:
                columns = [
                  {
                    header: 'No.',
                    key: 'no',
                    width: 5
                  },
                  {
                    header: 'Customer Name',
                    key: 'customerName',
                    width: 20
                  },
                  {
                    header: 'Customer Code',
                    key: 'customerCode',
                    width: 20
                  },
                  {
                    header: 'Customer Phone Number',
                    key: 'customerPhoneNumber',
                    width: 20
                  },
                  {
                    header: 'Order Date',
                    key: 'orderDate',
                    width: 15
                  },
                  {
                    header: 'Order Status',
                    key: 'orderStatus',
                    width: 15
                  },
                  {
                    header: 'Salesman Name',
                    key: 'salesmanName',
                    width: 20
                  },
                  {
                    header: 'Salesman Code',
                    key: 'salesmanCode',
                    width: 15
                  },
                  {
                    header: 'Salesman Phone Number',
                    key: 'salesmanPhoneNumber',
                    width: 20
                  }
                ]
            }
            break
          case 'order-director':
            switch (tabType?.toUpperCase()) {
              case 'CUSTOMER':
                columns = [
                  {
                    header: 'No.',
                    key: 'no',
                    width: 5
                  },
                  {
                    header: 'Customer Name',
                    key: 'customerName',
                    width: 20
                  },
                  {
                    header: 'Customer Code',
                    key: 'customerCode',
                    width: 20
                  },
                  {
                    header: 'Customer Phone Number',
                    key: 'customerPhoneNumber',
                    width: 20
                  },
                  {
                    header: 'Last Order Date',
                    key: 'lastOrderDate',
                    width: 15
                  },
                  {
                    header: 'Status',
                    key: 'status',
                    width: 15
                  },
                ]
                break
              case 'ITEM':
                columns = [
                  {
                    header: 'No.',
                    key: 'no',
                    width: 5
                  },
                  {
                    header: 'Item Name',
                    key: 'itemName',
                    width: 20
                  },
                  {
                    header: 'Item Type',
                    key: 'itemType',
                    width: 20
                  },
                  {
                    header: 'Customer Name',
                    key: 'customerName',
                    width: 20
                  },
                  {
                    header: 'Customer Phone Number',
                    key: 'customerPhoneNumber',
                    width: 20
                  },
                  {
                    header: 'Order Status',
                    key: 'orderStatus',
                    width: 15
                  }
                ]
                break
              default:
                columns = [
                  {
                    header: 'No.',
                    key: 'no',
                    width: 5
                  },
                  {
                    header: 'Customer Name',
                    key: 'customerName',
                    width: 20
                  },
                  {
                    header: 'Customer Code',
                    key: 'customerCode',
                    width: 20
                  },
                  {
                    header: 'Customer Phone Number',
                    key: 'customerPhoneNumber',
                    width: 20
                  },
                  {
                    header: 'Order Date',
                    key: 'orderDate',
                    width: 15
                  },
                  {
                    header: 'Order Status',
                    key: 'orderStatus',
                    width: 15
                  },
                  {
                    header: 'Salesman Name',
                    key: 'salesmanName',
                    width: 20
                  },
                  {
                    header: 'Salesman Code',
                    key: 'salesmanCode',
                    width: 15
                  },
                  {
                    header: 'Salesman Phone Number',
                    key: 'salesmanPhoneNumber',
                    width: 20
                  }
                ]
            }
            break
          case 'invoice':
            columns = [
              {
                header: "No.",
                key: "no",
                width: 5,
              },
              {
                header: 'Issued At',
                key: 'issuedAt',
                width: 20
              },
              {
                header: 'Invoice Number',
                key: 'code',
                width: 20
              },
              {
                header: 'Customer Name',
                key: 'customerName',
                width: 20
              },
              {
                header: 'Customer Code',
                key: 'customerCode',
                width: 20
              },
              {
                header: 'Customer Phone Number',
                key: 'customerPhoneNumber',
                width: 20
              },
              {
                header: 'Customer Address',
                key: 'customerAddress',
                width: 20
              },
              {
                header: 'Num Of Items',
                key: 'numOfItems',
                width: 20
              },
              {
                header: 'Fabrics',
                key: 'fabrics',
                width: 20
              },
              {
                header: 'Sum',
                key: 'sum',
                width: 15
              },
              {
                header: 'Due Date',
                key: 'dueDate',
                width: 20
              },
              {
                header: 'Payment Status',
                key: 'paymentStatus',
                width: 20
              },
              {
                header: 'Payment Method',
                key: 'paymentMethod',
                width: 20
              }
            ]
            break
          case 'delivery':
            switch (tabType?.toUpperCase()) {
              case 'STOCK':
                columns = [
                  {
                    header: "No.",
                    key: "no",
                    width: 5,
                  },
                  {
                    header: 'Scheduled At',
                    key: 'scheduledAt',
                    width: 20
                  },
                  {
                    header: 'Delivered At',
                    key: 'deliveredAt',
                    width: 20
                  },
                  {
                    header: 'Delivery ID',
                    key: 'deliveryID',
                    width: 20
                  },
                  {
                    header: 'Source Checkpoint',
                    key: 'sourceCheckpoint',
                    width: 20
                  },
                  {
                    header: 'Receiving Checkpoint',
                    key: 'receivingCheckpoint',
                    width: 20
                  },
                  {
                    header: 'Items',
                    key: 'items',
                    width: 20
                  },
                  {
                    header: 'Num Of Items',
                    key: 'numOfItems',
                    width: 20
                  },
                  {
                    header: 'Status',
                    key: 'status',
                    width: 20
                  },
                ]
                break
              default:
                columns = [
                  {
                    header: "No.",
                    key: "no",
                    width: 5,
                  },
                  {
                    header: 'Scheduled At',
                    key: 'scheduledAt',
                    width: 20
                  },
                  {
                    header: 'Delivered At',
                    key: 'deliveredAt',
                    width: 20
                  },
                  {
                    header: 'Delivery ID',
                    key: 'deliveryID',
                    width: 20
                  },
                  {
                    header: 'Source',
                    key: 'source',
                    width: 20
                  },
                  {
                    header: 'Customer Name',
                    key: 'customerName',
                    width: 20
                  },
                  {
                    header: 'Customer Code',
                    key: 'customerCode',
                    width: 20
                  },
                  {
                    header: 'Customer Phone Number',
                    key: 'customerPhoneNumber',
                    width: 20
                  },
                  {
                    header: 'Customer Address',
                    key: 'customerAddress',
                    width: 20
                  },
                  {
                    header: 'Num Of Items',
                    key: 'numOfItems',
                    width: 20
                  },
                  {
                    header: 'Status',
                    key: 'status',
                    width: 20
                  },
                ]
            }
            break
          case 'delivery-director':
            switch (tabType?.toUpperCase()) {
              case 'STOCK':
                columns = [
                  {
                    header: "No.",
                    key: "no",
                    width: 5,
                  },
                  {
                    header: 'Scheduled At',
                    key: 'scheduledAt',
                    width: 20
                  },
                  {
                    header: 'Delivered At',
                    key: 'deliveredAt',
                    width: 20
                  },
                  {
                    header: 'Delivery ID',
                    key: 'deliveryID',
                    width: 20
                  },
                  {
                    header: 'Source Checkpoint',
                    key: 'sourceCheckpoint',
                    width: 20
                  },
                  {
                    header: 'Receiving Checkpoint',
                    key: 'receivingCheckpoint',
                    width: 20
                  },
                  {
                    header: 'Items',
                    key: 'items',
                    width: 20
                  },
                  {
                    header: 'Num Of Items',
                    key: 'numOfItems',
                    width: 20
                  },
                  {
                    header: 'Status',
                    key: 'status',
                    width: 20
                  },
                ]
                break
              default:
                columns = [
                  {
                    header: "No.",
                    key: "no",
                    width: 5,
                  },
                  {
                    header: 'Scheduled At',
                    key: 'scheduledAt',
                    width: 20
                  },
                  {
                    header: 'Delivered At',
                    key: 'deliveredAt',
                    width: 20
                  },
                  {
                    header: 'Delivery ID',
                    key: 'deliveryID',
                    width: 20
                  },
                  {
                    header: 'Source',
                    key: 'source',
                    width: 20
                  },
                  {
                    header: 'Customer Name',
                    key: 'customerName',
                    width: 20
                  },
                  {
                    header: 'Customer Code',
                    key: 'customerCode',
                    width: 20
                  },
                  {
                    header: 'Customer Phone Number',
                    key: 'customerPhoneNumber',
                    width: 20
                  },
                  {
                    header: 'Customer Address',
                    key: 'customerAddress',
                    width: 20
                  },
                  {
                    header: 'Num Of Items',
                    key: 'numOfItems',
                    width: 20
                  },
                  {
                    header: 'Status',
                    key: 'status',
                    width: 20
                  },
                ]
            }
            break
          case 'workshop':
            columns = [
              {
                header: "No.",
                key: "no",
                width: 5,
              },
              {
                header: 'Product Name',
                key: 'productName',
                width: 20
              },
              {
                header: 'Product Type',
                key: 'productType',
                width: 20
              },
              {
                header: 'Notes',
                key: 'notes',
                width: 20
              },
              {
                header: 'Status',
                key: 'status',
                width: 20
              },
              {
                header: 'Customer Name',
                key: 'customerName',
                width: 20
              },
              {
                header: 'Customer Phone Number',
                key: 'customerPhoneNumber',
                width: 20
              },
              {
                header: 'Order ID',
                key: 'orderID',
                width: 20
              }


            ]
            break
          case 'workshop-request':
            columns = [
              {
                header: "No.",
                key: "no",
                width: 5,
              },
              {
                header: 'Product Name',
                key: 'productName',
                width: 20
              },
              {
                header: 'Product Type',
                key: 'productType',
                width: 20
              },
              {
                header: 'Notes',
                key: 'notes',
                width: 20
              },
              {
                header: 'Status',
                key: 'status',
                width: 20
              },
              {
                header: 'Customer Name',
                key: 'customerName',
                width: 20
              },
              {
                header: 'Customer Phone Number',
                key: 'customerPhoneNumber',
                width: 20
              },
              {
                header: 'Order ID',
                key: 'orderID',
                width: 20
              }


            ]
            break
          case 'employee':
            columns = [
              {
                header: 'No.',
                key: 'no',
                width: 5
              },
              {
                header: 'Code',
                key: 'code',
                width: 20
              },
              {
                header: 'Full Name',
                key: 'fullName',
                width: 20
              },
              {
                header: 'WhatsApp Number',
                key: 'whatsAppNumber',
                width: 20
              },
              {
                header: 'Role',
                key: 'role',
                width: 15
              },
              {
                header: 'Nickname',
                key: 'nickname',
                width: 20
              },
              {
                header: 'Placement',
                key: 'placement',
                width: 20
              }
            ]
            break
          case 'employee-director':
            columns = [
              {
                header: 'No.',
                key: 'no',
                width: 5
              },
              {
                header: 'Code',
                key: 'code',
                width: 20
              },
              {
                header: 'Full Name',
                key: 'fullName',
                width: 20
              },
              {
                header: 'WhatsApp Number',
                key: 'whatsAppNumber',
                width: 20
              },
              {
                header: 'Role',
                key: 'role',
                width: 15
              },
              {
                header: 'Nickname',
                key: 'nickname',
                width: 20
              },
              {
                header: 'Placement',
                key: 'placement',
                width: 20
              }
            ]
            break
          case 'fee':
            columns = [
              {
                header: 'No.',
                key: 'no',
                width: 5
              },
              {
                header: 'Last Update',
                key: 'lastUpdate',
                width: 20
              },
              {
                header: 'Employee Name',
                key: 'employeeName',
                width: 20
              },
              {
                header: 'Employee Role',
                key: 'employeeRole',
                width: 20
              },
              {
                header: 'Employee Phone Number',
                key: 'employeePhoneNumber',
                width: 20
              },
              {
                header: 'Num of Items',
                key: 'numOfItems',
                width: 15
              },
              {
                header: 'Fee',
                key: 'fee',
                width: 20
              }
            ]
            break
          case 'customer':
            columns = [
              {
                header: 'No.',
                key: 'no',
                width: 5
              },
              {
                header: 'Code',
                key: 'code',
                width: 20
              },
              {
                header: 'Name',
                key: 'name',
                width: 20
              },
              {
                header: 'Phone Number',
                key: 'phoneNumber',
                width: 20
              },
              {
                header: 'Num of Orders',
                key: 'numOfOrders',
                width: 20
              },
              {
                header: 'Num of Items',
                key: 'numOfItems',
                width: 20
              },
              {
                header: 'Outstanding',
                key: 'outstanding',
                width: 20
              },
              {
                header: 'Revenue',
                key: 'revenue',
                width: 20
              }
            ]
            break
          default:
            break
        }

        sheet.columns = columns;

        let promise;
        if (columns?.length) {
          promise = Promise.all(
            resData?.map(async (el, index) => {
              sheet.addRow(columns?.map?.(obj => obj?.key)?.reduce((a, v) => ({
                ...a,
                no: index + 1,
                [v]: columns?.find(obj => obj?.key === v)?.isHyperLink ? {
                  text: el[v],
                  hyperlink: el[v]
                } : el[v],
              })));
            })
          );

          promise.then(() => {
            workbook.xlsx.writeBuffer().then(function (report) {
              const blob = new Blob([report], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const url = window.URL.createObjectURL(blob);
              const anchor = document.createElement("a");
              anchor.href = url;
              anchor.download = `${capitalizeWords(type?.includes('-') ? type?.split('-')[0] : type)} Data/${moment(new Date())?.format('YYYY/MM/DD')}.xlsx`;
              anchor.click();
              window.URL.revokeObjectURL(url);
              setIsDownloading(false);
            });
          });

        }

        setIsDownloading(false)


      } catch (err) {
        setIsDownloading(false)
        setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
        console.log(err, 'error on mapping to excel')
      }

    } catch (err) {
      setIsDownloading(false)
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
      console.log(err, 'error on fetching download data')
    }




  }


  const isTitleColumn = useMemo(() => {
    switch (type) {
      case 'stock':
        return true
      case 'stock-store':
        return true
      case 'delivery':
        return true
      default:
        return false
    }
  }, [type])

  const dateFromRef = useRef()
  const dateToRef = useRef()

  const countString = useMemo(() => {
    switch (typeQuery) {
      case 'tailor':
        return 'piece'
      case 'cutter':
        return 'piece'
      case 'driver':
        return 'destination'
      default:
        return 'piece'
    }
  }, [typeQuery])

  const feeType = useMemo(() => {
    if (!query?.get('t')) {
      return 'tailorFee'
    } return `${query.get('t')}Fee`
  }, [query])

  const feeCategories = useMemo(() => {
    return [
      {
        id: 1,
        name: 'Suit',
        fee: feeSync?.length ? feeSync?.find(obj => obj?.name?.toLowerCase() === 'suit')?.[feeType] : '',
        code: feeSync?.length ? feeSync?.find(obj => obj?.name?.toLowerCase() === 'suit')?.code : '',
      },
      {
        id: 2,
        name: 'Shirt',
        fee: feeSync?.length ? feeSync?.find(obj => obj?.name?.toLowerCase() === 'shirt')?.[feeType] : '',
        code: feeSync?.length ? feeSync?.find(obj => obj?.name?.toLowerCase() === 'shirt')?.code : '',
      },
      {
        id: 3,
        name: 'Trouser',
        fee: feeSync?.length ? feeSync?.find(obj => obj?.name?.toLowerCase() === 'trouser')?.[feeType] : '',
        code: feeSync?.length ? feeSync?.find(obj => obj?.name?.toLowerCase() === 'trouser')?.code : '',
      }
    ]
  }, [feeSync, feeType])



  const handleDelete = async () => {
    try {
      if (type === 'stock') {
        const codesArr = data?.filter(obj => {
          return checkedArr.some(f => {
            return f?.id === obj?.id
          })
        })?.map(obj => obj?.code?.replaceAll('::', '%2F')?.replaceAll('/', '%2F')?.replaceAll(' ', '%20'))
        await deleteStocks(codesArr)
        setTrigger(makeRandomString(5))
        setShowDelete(false)
      }
      if (type === 'delivery-director') {
        const codesArr = data?.filter(obj => {
          return checkedArr.some(f => {
            return f?.id === obj?.id
          })
        })?.map(obj => obj?.code?.replaceAll('::', '%2F')?.replaceAll('/', '%2F')?.replaceAll(' ', '%20'))
        await deleteDeliveries(codesArr)
        setTrigger(makeRandomString(5))
        setShowDelete(false)
      }
      if (type === 'delivery') {
        const codesArr = data?.filter(obj => {
          return checkedArr.some(f => {
            return f?.id === obj?.id
          })
        })?.map(obj => obj?.code?.replaceAll('::', '%2F')?.replaceAll('/', '%2F')?.replaceAll(' ', '%20'))
        await deleteDeliveries(codesArr)
        setTrigger(makeRandomString(5))
        setShowDelete(false)
      }
    } catch (err) {
      console.log(err)
    }
  }


  return (
    <div className={Styles.container} style={{ height: customHeight }}>
      <div className={Styles.header} ref={headerRef}>
        <div className={`${Styles.top} ${isTitleColumn ? Styles.column : ''}`}>
          <div className={Styles.left}>
            <div className={Styles.title}>
              <Icon icon={template?.icon} size={40} />
              <h3>{template?.title}</h3>
            </div>
            <div className={Styles.typesWrapper}>
              {
                type === 'stock' && stockTypes?.map((s, i) => (
                  <button
                    key={i}
                    className={`${Styles.typeIndicator} ${tabType === s?.query ? Styles.active : ''}`}
                    // onClick={() => navigate(`${pathname}?t=${s?.query}`, { replace: true })}
                    onClick={() => setTabType(s?.query)}
                  >
                    {s?.title}
                  </button>
                ))
              }
              {
                type === 'stock-store' && stockTypes?.map((s, i) => (
                  <button
                    key={i}
                    className={`${Styles.typeIndicator} ${Styles.v2} ${typeQuery === s?.query ? Styles.active : ''}`}
                    // onClick={() => navigate(`${pathname}?t=${s?.query}`, { replace: true })}
                    onClick={() => setTabType(s?.query)}
                  >
                    {s?.title}
                  </button>
                ))
              }
              {
                type === 'order-director' && orderMasterTypes?.map((s, i) => (
                  <button
                    key={i}
                    className={`${Styles.typeIndicator} ${tabType === s?.query ? Styles.active : ''}`}
                    // onClick={() => navigate(`${pathname}?t=${s?.query}`, { replace: true })}
                    onClick={() => setTabType(s?.query)}
                  >
                    {s?.title}
                  </button>
                ))
              }
              {
                type === 'delivery' && deliveryTypes?.map((s, i) => (
                  <button
                    key={i}
                    className={`${Styles.typeIndicator} ${tabType === s?.query ? Styles.active : ''}`}
                    onClick={() => {
                      setTabType(s?.query)
                      navigate(`${pathname}?dvt=${s?.query}`, { replace: true })
                    }
                    }

                  >
                    {s?.title}
                  </button>
                ))
              }
              {
                type === 'delivery-director' && masterDeliveryTypes?.map((s, i) => (
                  <button
                    key={i}
                    className={`${Styles.typeIndicator} ${tabType === s?.query ? Styles.active : ''}`}
                    onClick={() => {
                      setTabType(s?.query)
                      navigate(`${pathname}?dvt=${s?.query}&t=${typeQuery}&p=${pageQuery}`, { replace: true })
                    }
                    }

                  >
                    {s?.title}
                  </button>
                ))
              }
              {
                type === 'fee' && employeeTypes?.filter(obj => obj?.title?.toLowerCase() !== 'sales')?.map((s, i) => (
                  <button key={i} className={`${Styles.typeIndicator} ${tabType === s?.query ? Styles.active : ''}`} onClick={() =>
                    //  navigate(`${pathname}?e=${s?.query}`, { replace: true })
                    setTabType(s?.query)
                  }>
                    <Icon icon={s?.query || 'tailor'} size={20} />
                    <span>
                      {s?.title}
                    </span>
                  </button>
                ))
              }
            </div>

          </div>
          {type !== 'fee'
            &&
            <div className={Styles.right}>
              <Button
                variant='tertiary'
                size='lg'
                buttonText='Filter'
                withIcon
                iconName={"filter"}
                iconPlacement='end'
                disableActive
                onClick={onClickFilter}
                state={width <= 768 ? 'icon-only' : ''}
              />
              {
                !expandSelect
                  ? <Button
                    variant='tertiary'
                    size='lg'
                    buttonText='Select'
                    withIcon
                    iconName={"check-circle"}
                    iconPlacement='end'
                    onClick={onClickSelect}
                    disableActive
                    state={width <= 768 ? 'icon-only' : ''}
                  />
                  : <div className={Styles.selectWrapper}>
                    <Button
                      variant='tertiary'
                      buttonText='Select'
                      withIcon
                      iconName={'x-circle'}
                      onClick={() => setExpandSelect(false)}
                      disableActive
                      size='md'

                    />

                    {template?.selectComponents?.map((each, i) => <Fragment key={i}>{each}</Fragment>)}

                    <Button
                      variant='secondary'
                      buttonText='Delete'
                      fixedPadding={'0.6rem 1.2rem'}
                      size='md'
                      // disableActive
                      disabled={!checkedArr?.length}
                      onClick={() => setShowDelete(true)}
                    />
                  </div>
              }

            </div>
          }
          {type === 'fee'
            &&
            <div className={Styles.feeSetter}>
              {feeCategories?.map((f) => (
                <div key={f?.id} className={Styles.perType}>
                  <div className={Styles.fee}>
                    <span>{f?.name} Fee</span>
                    <p>
                      <span>{`Rp ${priceFormat(f?.fee) || '-'}`} </span>
                      <span>{`/${countString}`}</span>
                    </p>
                  </div>
                </div>
              ))}
              <button onClick={() => setShowFeeSetter(true)}>
                <Icon icon='cog-6-tooth' size={24} />
              </button>

            </div>

          }

        </div>
        <div className={Styles.bottom}>
          <div className={Styles.left}>
            <Searchbar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder={template?.searchPlaceholder}
              width={width <= 768 ? '19rem' : '38rem'}
              accordionMode={width <= 768 ? true : false}
            />
          </div>
          <div className={Styles.right}>
            {
              (type === 'fee' || type === 'customer')
              &&
              <div className={Styles.dateFilter}>
                <button onClick={() => dateFromRef?.current?.showPicker()}>
                  <input ref={dateFromRef} type='date' onChange={(e) => navigate(`${pathname}?df=${e?.target?.value ? moment(e?.target?.value)?.format('YYYY-MM-DD') : ''}&dt=${dateTo || ''}`, { replace: true })} />
                  <Icon icon={'calendar'} size={24} />
                  <span>{dateFrom ? moment(dateFrom)?.format('L') : 'From'}</span>
                </button>
                <button onClick={() => dateToRef?.current?.showPicker()}>
                  <input ref={dateToRef} type='date' onChange={(e) => navigate(`${pathname}?df=${dateFrom || ''}&dt=${moment(e?.target?.value)?.format('YYYY-MM-DD') || ''}`, { replace: true })} />
                  <Icon icon={'calendar'} size={24} />
                  <span>{dateTo ? moment(dateTo)?.format('L') : 'To'}</span>
                </button>
              </div>
            }
            <Button
              variant='secondary'
              size='lg'
              buttonText='Download'
              withIcon
              iconName={"document-arrow-down"}
              iconPlacement='start'
              disableActive
              state={width <= 768 ? 'icon-only' : ''}
              fixedPadding={'12px'}
              onClick={onDownload}
              disabled={isDownloading}
            // customClassName={width <= 768 ? Styles.square : ''}
            />

            {template?.addButton && !noAdd &&
              <Button
                variant='primary'
                size='lg'
                buttonText={template?.addButtonText}
                withIcon
                iconName={"plus"}
                iconPlacement='start'
                onClick={onClickAdd}
                disableActive
                state={width <= 768 ? 'icon-only' : ''}
                fixedPadding={'12px'}
              />
            }
          </div>
        </div>
        {/* <div className={Styles.filler} style={{ position: 'absolute', top: `${headerHeight}px`, left: 0 }} /> */}
      </div>
      <div className={Styles.content}>
        <table >
          <thead
          //  style={{ position: 'sticky', top: `${headerHeight + 2}px` }}
          >
            <tr>
              {expandSelect
                &&
                <th></th>
              }
              {withNumbering
                &&
                <th
                  style={{
                    maxWidth: '50px',
                    minWidth: '50px'
                  }}
                >
                  <div>
                    <span>No</span>
                    <button>
                      <Icon icon="chevron-up-down" size={20} />
                    </button>

                  </div>
                </th>
              }
              {columns?.map((column, i) => (
                <th key={column?.name} style={{
                  textAlign: column?.align || 'left',
                  minWidth: column?.minWidth || 'auto',
                  maxWidth: column?.maxWidth || '100px'
                }}
                  // className={column?.thClassName || ''}
                  className={`${column?.thClassName || ''} ${column?.hideOnMobile ? Styles.hideOnMobile : ''} ${column?.showOnMobile ? Styles.showOnMobile : ''}`}
                >
                  <div
                    className={Styles[column?.align + 'Align']}
                  >
                    {i === 0 && expandSelect
                      &&
                      <div className={Styles.hide}>
                        {/* <input type='checkbox' style={{width: '24px', height: '24px', border: '1px solid #ced4da'}}  /> */}
                        {/* <Checkbox
                          isChecked={false}
                          setIsChecked={() => { }}
                        /> */}
                      </div>
                    }

                    {column?.renderLabel ? column?.renderLabel(column) :
                      <span>{column?.label}</span>}

                    {column?.label &&
                      <button onClick={() => {
                        const label = String(column.label).search(' ') ? column.label.trim().toLowerCase().split(' ').join('_') : column.label;

                        let val = '';

                        const sortBy = query.get('sortBy')

                        if(sortBy) {
                          if(sortBy === label + '_ASC') {
                            val = label + '_DESC'
                          } else {
                            val = label + '_ASC'
                          }
                        } else {
                          val = label + '_ASC'
                        }

                        const sortParam = new URLSearchParams(location.search);
                        sortParam.set('sortBy', val);
                        
                        navigate(`${location.pathname}?${sortParam.toString()}`, { replace: true });
                      }}>
                        <Icon icon="chevron-up-down" size={20} />
                      </button>

                    }
                  </div>


                  <div className={Styles.borderBottom} />
                  <div className={Styles.borderTop} />
                  {
                    column?.withBorderRight && <div className={Styles.borderRight} />
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>

            {!isLoading && data?.map((row, i) => (
              <tr
                key={i}
                onClick={() => {
                  if (width > 480) {
                    onClickRow(row)
                  }
                }}
              >
                {
                  expandSelect
                  &&
                  <td onClick={(e) => e.stopPropagation()}>
                    {/* <input type='checkbox' style={{width: '24px', height: '24px', border: '1px solid #ced4da'}}  /> */}
                    <Checkbox
                      isChecked={checkedArr?.find(obj => obj?.id === row?.id) ? true : false}
                      setIsChecked={() => handleCheck(row)}
                      disabled={disableCheck(checkedArr, row)}
                      hideOnDisabled
                    />
                  </td>

                }
                {withNumbering
                  &&
                  <td
                    style={{
                      maxWidth: '50px',
                      minWidth: '50px'
                    }}

                  >
                    <div>
                      {i + 1}
                    </div>
                  </td>
                }
                {columns?.map((column) => (
                  <td
                    id={column?.renderPopup ? `td-${row?.id}` : ''}
                    key={column?.name}
                    // className={column?.tdClassName || ''}
                    className={`${column?.tdClassName || ''} ${column?.hideOnMobile ? Styles.hideOnMobile : ''} ${column?.showOnMobile ? Styles.showOnMobile : ''}`}
                    style={{ textAlign: column.align || 'left' }}
                  >
                    <div className={Styles.wrapper}
                      style={{
                        textAlign: column.align || 'left',
                        justifyContent: column?.align || 'left'
                      }}
                    >


                      {/* {ic === 0 && expandSelect
                        &&
                        <div onClick={(e) => e.stopPropagation()}>
                          <input type='checkbox' style={{width: '24px', height: '24px', border: '1px solid #ced4da'}}  />
                          <Checkbox
                            isChecked={checkedArr?.find(obj => obj === row?.id) ? true : false}
                            setIsChecked={(newVal) => handleCheck(row?.id)}
                          />
                        </div>
                      } */}
                      {column?.renderData ? column?.renderData(row) : <span>{row?.[column?.name] || '-'}</span>}
                      {column?.withBorderRight && <div className={Styles.borderRight} />}
                      {column?.renderPopup
                        &&
                        <div style={{ position: 'relative' }}>
                          <div
                            className={`${column?.popupWrapperClassName ? column?.popupWrapperClassName : ''}`}
                            style={{
                              position: 'absolute',
                              zIndex: 10,
                              marginTop: column?.popupPositioning?.top,
                              marginLeft: column?.popupPositioning?.left,
                            }}
                          >{column?.renderPopup(row)}</div>
                        </div>

                      }
                    </div>
                  </td>
                ))}
              </tr>
            ))}
            {isLoading && withSkeleton && [...Array(3)]?.map((row, i) => (
              <tr
                key={i}
                onClick={() => {
                  if (width > 480) {
                    onClickRow(row)
                  }
                }}
              >
                {withNumbering
                  &&
                  <td
                    style={{
                      maxWidth: '50px',
                      minWidth: '50px'
                    }}

                  >
                    <div>{i + 1}</div>
                  </td>
                }
                {columns?.map((column, ic) => (
                  <td
                    id={column?.renderPopup ? `td-${row?.id}` : ''}
                    key={column?.name}
                    // className={column?.tdClassName || ''}
                    className={`${column?.tdClassName || ''} ${column?.hideOnMobile ? Styles.hideOnMobile : ''} ${column?.showOnMobile ? Styles.showOnMobile : ''}`}
                    style={{ textAlign: column.align || 'left' }}
                  >
                    <div className={Styles.wrapper}
                      style={{
                        textAlign: column.align || 'left',
                      }}
                    >
                      {column?.skeleton
                        ||
                        <Skeleton duration={2} className={Styles.defaultSkeleton} />

                      }


                      {ic === 0 && expandSelect
                        &&
                        <div onClick={(e) => e.stopPropagation()}>
                          {/* <input type='checkbox' style={{width: '24px', height: '24px', border: '1px solid #ced4da'}}  /> */}
                          <Checkbox
                            isChecked={checkedArr?.find(obj => obj?.id === row?.id) ? true : false}
                            setIsChecked={() => handleCheck(row?.id)}
                          />
                        </div>
                      }
                      {column?.withBorderRight && <div className={Styles.borderRight} />}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {!data?.length
          &&
          !isLoading
          &&
          //  <div className={Styles.emptyPlaceholder}>no data yet</div>
          <div className={Styles.emptyPlaceholder}>
            <QuestioningEmptyHandler text={`No ${template?.itemListName}`} />
          </div>
        }
        {isLoading && !withSkeleton && <div className={Styles.emptyPlaceholder}>loading data...</div>}
      </div>
      <div className={Styles.paginationWrapper}>
        <Pagination
          total={totalData}
          totalPage={totalPage || Math?.ceil(totalData / value)}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          itemListName={template?.itemListName}
          extraLabels={paginationExtraLabels}
        />
      </div>

      <div className={`${Styles.deliveryModalWrapper} ${showDeliveryModal ? Styles.active : ''}`}>
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showDeliveryModal}
        >
          <DeliveryModal
            data={selectedData}
            setShow={setShowDeliveryModal}
            deliverSync={deliverSync}
            setTrigger={setTrigger}
          />
        </ModalWrapper>
      </div>

      {
        showFeeSetter

        &&

        <div className={`${Styles.feeSetterModalWrapper} ${showFeeSetter ? Styles.active : ''}`}>
          <ModalWrapper
            desktopPositioning='center'
            mobilePositioning='bottom'
            show={showFeeSetter}
          >
            <FeeSetterModal
              setShow={setShowFeeSetter}
              employeeType={tabType}
              defaultFees={feeCategories}
              setTrigger={setTrigger}
            />

          </ModalWrapper>
        </div>
      }

      <div className={`${Styles.deleteModalWrapper} ${showDelete ? Styles.active : ''}`}>
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showDelete}
          setShow={setShowDelete}
        >
          <DeleteModal
            onDelete={handleDelete}
            setShow={setShowDelete}
            label={template?.deleteLabel}
          />

        </ModalWrapper>
      </div>

      {error
        &&
        <ModalWrapper
          show={error}
          setShow={() => {
            // setConfirmationMode(false)
            setError(null)
          }
          }
        >
          <WideErrorModal
            code={error?.code || 500}
            error={error?.message || 'Something went wrong!'}
            withButton
            onClose={() => {
              setError(null)
              // setConfirmationMode(false)
            }
            }
          />
        </ModalWrapper>

      }
      <LoadingModal
        show={isDownloading}
      />
    </div>
  )
}