import { stockTypes } from '@Assets/data/stockTypes'
import { priceFormat } from '@Helpers/priceFormat'
import useQuery from '@Hooks/useQuery'
import ActiveFiltersIndicator from '@Molecule/ActiveFiltersIndicator'
import DateLabel from '@Molecule/DateLabel'
import PriceLabel from '@Molecule/PriceLabel'
import FilterModal from '@Molecule/_reusableLayout/FilterModal'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'
import { dateLabelFormatter } from '@Helpers/dateLabelFormatter'

export default function StockFilterModal({
  handleClose,
  trigger,
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()
  const defaultDateAddedFrom = query.get('df')
  const defaultDateAddedTo = query.get('dt')
  const defaultPriceFrom = query.get('pf')
  const defaultPriceTo = query.get('pt')
  const defaultType = query.get('t')

  const [dateAddedFrom, setDateAddedFrom] = useState(defaultDateAddedFrom || '')
  const [dateAddedTo, setDateAddedTo] = useState(defaultDateAddedTo || '')
  const [priceFrom, setPriceFrom] = useState(defaultPriceFrom || '')
  const [priceTo, setPriceTo] = useState(defaultPriceTo || '')
  const [type, setType] = useState(defaultType || '')

  useEffect(() => {
    setType(defaultType)
    setDateAddedFrom(defaultDateAddedFrom)
    setDateAddedTo(defaultDateAddedTo)
    setPriceFrom(defaultPriceFrom)
    setPriceTo(defaultPriceTo)
  }, [
    defaultType,
    defaultDateAddedFrom,
    defaultDateAddedTo,
    defaultPriceFrom,
    defaultPriceTo,
    trigger
  ])

  const allQueries = useMemo(() => {
    const arr = []
    for (const param of query) {
      arr.push(param);
    }

    const newArr =
      !arr?.find(obj => obj[0] === 'df')
        ? (
          !arr?.find(obj => obj[0] === 'dt')
            ?
            (
              !arr?.find(obj => obj[0] === 'pf')
                ?
                (
                  !arr?.find(obj => obj[0] === 'pt')
                    ?
                    [...arr, ['df', dateAddedFrom], ['dt', dateAddedTo], ['pf', priceFrom], ['pt', priceTo]]
                    :
                    [...arr, ['df', dateAddedFrom], ['dt', dateAddedTo], ['pf', priceFrom]]
                )
                :
                [...arr, ['df', dateAddedFrom], ['dt', dateAddedTo]]

            )
            :
            [...arr, ['df', dateAddedFrom]]
        )
        : [...arr]

    return newArr
  }, [dateAddedFrom, dateAddedTo, priceFrom, priceTo, query])


  const fixedQuery = useMemo(() => {
    let str = ''

    const raw = allQueries.join(',')?.split(',')

    const strArr = []

    for (let i = 0; i < raw.length / 2; i++) {
      strArr.push({
        key: raw[i + i],
        value: raw[i + i] === 'df'
          ? dateAddedFrom || ''
          : (
            raw[i + i] === 'dt'
              ? dateAddedTo || ''
              :
              (raw[i + i] === 'pf'
                ? priceFrom || ''
                : (
                  raw[i + i] === 'pt'
                    ?
                    priceTo || ''
                    :
                    raw[i + i + 1]
                )
              )
          )
      })
    }


    for (let i = 0; i < strArr.length; i++) {
      const s = strArr[i]
      str += (i === 0 ? `${s?.key}=${s?.value}` : `&${s?.key}=${s?.value}`)
    }


    return str

  }, [allQueries, dateAddedFrom, dateAddedTo, priceFrom, priceTo])


  // const fixedQuery = useMemo(() => {
  //   const arr = []
  //   if (type) {
  //     arr.push(`t=${type}`)
  //   }
  //   if (dateAddedFrom) {
  //     arr.push(`df=${dateAddedFrom.replaceAll('/', '-')}`)
  //   }
  //   if (dateAddedTo) {
  //     arr.push(`dt=${dateAddedTo}`)
  //   }
  //   if (priceFrom) {
  //     arr.push(`pf=${priceFrom}`)
  //   }
  //   if (priceTo) {
  //     arr.push(`pt=${priceTo}`)
  //   }
  //   if (arr?.length) {
  //     return '?' + arr?.join('&')
  //   } return ''
  // }, [
  //   type,
  //   dateAddedFrom,
  //   dateAddedTo,
  //   priceFrom,
  //   priceTo
  // ])

  const filterTemplate = useMemo(() => {
    const arr = []
    if (dateAddedFrom || dateAddedTo) {
      arr.push({
        title: `Date Added: ${dateAddedFrom ? dateAddedFrom?.replaceAll('-', '/') : 'DD/MM/YYYY'} - ${dateAddedTo ? dateAddedTo?.replaceAll('-', '/') : 'DD/MM/YYYY'}`,
        handleRemove: () => {
          setDateAddedFrom('')
          setDateAddedTo('')
        }
      })
    }

    if (type) {
      arr.push({
        title: stockTypes?.find(el => el?.query === defaultType)?.title,
        handleRemove: () => setType('')
      })
    }

    if (priceFrom || priceTo) {
      arr.push({
        title: `Price: Rp ${priceFrom ? priceFormat(priceFrom) : '-'} - Rp ${priceTo ? priceFormat(priceTo) : '-'}`,
        handleRemove: () => {
          setPriceFrom('')
          setPriceTo('')
        }
      })
    }
    return arr
  }, [dateAddedFrom, dateAddedTo, defaultType, priceFrom, priceTo, type])

  const contentTemplate = useMemo(() => {
    return [
      {
        title: 'Date Added',
        description: (
          <div className={Styles.dateRangeWrapper}>
            <DateLabel
              intro='From'
              date={dateLabelFormatter(dateAddedFrom, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setDateAddedFrom(moment(new Date(e?.target?.value))?.format('L'))}
            />
            <DateLabel
              intro='To'
              date={dateLabelFormatter(dateAddedTo, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setDateAddedTo(moment(new Date(e?.target?.value))?.format('L'))}
            />
          </div>
        )
      },
      {
        title: 'Price',
        description: (
          <div className={Styles.priceRangeWrapper}>
            <PriceLabel
              intro='From'
              price={priceFrom}
              changeAble
              onChange={(newVal) => setPriceFrom(newVal)}
            />
            <PriceLabel
              intro='To'
              price={priceTo}
              changeAble
              onChange={(newVal) => setPriceTo(newVal)}
            />
          </div>
        )
      }
    ]
  }, [dateAddedFrom, dateAddedTo, priceFrom, priceTo])

  const handleReset = () => {
    setType('')
    setDateAddedFrom('')
    setDateAddedTo('')
    setPriceFrom('')
    setPriceTo('')
  }

  const handleApply = () => {
    navigate(pathname + `?${fixedQuery}`, { replace: true })
    handleClose()
  }


  return (
    <FilterModal
      handleClose={handleClose}
      handleReset={handleReset}
      handleApply={handleApply}
      extraHeader={
        <ActiveFiltersIndicator
          filters={filterTemplate}
        />

      }
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.content}>
          <span>{content?.title}</span>
          {content?.description}
        </div>
      ))}
    </FilterModal>
  )
}