import Button from '@Atom/Button'
import Styles from './style.module.scss'

export default function ConfirmationModal({
  title = 'Confirmation',
  desc = 'Are you sure to confirm?',
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  onCancel = () => { },
  onConfirm = () => { }
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <span>{title}</span>
        <span>{desc}</span>
      </div>
      <div className={Styles.actions}>
        <Button
          type='button'
          variant='secondary'
          buttonText={cancelButtonText}
          disableActive
          fixedPadding={'1rem 1.6rem'}
          onClick={onCancel}
        />
        <Button
          type='button'
          variant='primary'
          buttonText={confirmButtonText}
          disableActive
          fixedPadding={'1rem 1.6rem'}
          onClick={onConfirm}
        />
      </div>
    </div>
  )
}