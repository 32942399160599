import { stockTypes } from '@Assets/data/stockTypes'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import Pagination from '@Atom/Pagination'
import Searchbar from '@Atom/Searchbar'
import useQuery from '@Hooks/useQuery'
import useWindowSize from '@Hooks/useWindowSize'
import { useEffect, useMemo, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'


export default function StockListSkeleton({
  type,
  columns,
  totalPage,
  onClickFilter = () => { },
  onClickAdd = () => { },
  noAdd,
}) {
  const [value, setValue] = useState(10);

  const data = useMemo(() => { return [...Array(10)] }, [])
  const totalData = data.length

  const navigate = useNavigate()
  const { pathname } = useLocation()


  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('')


  const [expandStatus, setExpandStatus] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [checkedArr, setCheckedArr] = useState([])




  const template = useMemo(() => {
    switch (type) {
      case 'stock':
        return ({
          title: 'Stock',
          icon: 'rectangle-stack',
          addButton: true,
          addButtonText: 'Add Stock',
          searchPlaceholder: 'Search products name, product code, etc.',
          itemListName: 'stocks',
          selectComponents: [
            <Button
              buttonText='Deliver To Checkpoint'
              variant='secondary'
              withIcon
              iconName={'paper-airplane'}
              iconPlacement='end'
              size='md'
              disabled={!checkedArr?.length}
            />
          ]
        })
      case 'order':
        return ({
          title: 'Order',
          icon: 'archive-box',
          addButton: true,
          addButtonText: 'Add Manual',
          searchPlaceholder: 'Search orders',
          itemListName: 'orders',
          selectComponents: []
        })
      case 'invoice':
        return ({
          title: 'Invoice',
          icon: 'document-arrow-down',
          addButton: false,
          // addButtonText: 'Add Manual',
          searchPlaceholder: 'Search orders',
          itemListName: 'orders',
          selectComponents: []
        })
      case 'delivery':
        return ({
          title: 'Delivery',
          icon: 'paper-airplane',
          addButton: false,
          searchPlaceholder: 'Search deliveries',
          itemListName: 'schedules',
          selectComponents: [
            <Button
              buttonText='Set as Priority'
              variant='secondary'
              size='md'
              fixedPadding={'0.75rem 1.2rem'}
              disableActive
            />,
            <div className={Styles.editStatus}>
              <Button
                buttonText='Edit Status'
                variant='secondary'
                withIcon
                iconName={!expandStatus ? 'chevron-down' : 'chevron-up'}
                iconPlacement='end'
                size='md'
                onClick={() => setExpandStatus(!expandStatus)}
                disableActive
              />
              {expandStatus && (
                <div className={Styles.options}>
                  <button>Delivered</button>
                  <button>Ready</button>
                  <button>On Delivery</button>
                </div>
              )}
            </div>

          ]
        })
      case 'checkpoint':
        return ({
          title: 'Checkpoint',
          icon: 'map-pin',
          addButton: true,
          addButtonText: 'Add Store',
          searchPlaceholder: 'Search store',
          itemListName: 'stores',
          selectComponents: []
        })
      case 'employee':
        return ({
          title: 'Employee',
          icon: 'user-group',
          addButton: true,
          addButtonText: 'Add Employee',
          searchPlaceholder: 'Search employee',
          itemListName: 'employees',
          selectComponents: []
        })
      case 'fee':
        return ({
          title: 'Fee',
          icon: 'fee',
          addButton: false,
          searchPlaceholder: 'Search employee',
          itemListName: 'employees',
          selectComponents: []
        })

      default:
        return ({
          title: 'Stock',
          icon: 'rectangle-stack',
          addButton: true,
          addButtonText: 'Add Stock',
          searchPlaceholder: 'Search products name, product code, etc.',
          itemListName: 'stocks',
          selectComponents: []
        })
    }
  }, [checkedArr, type, expandStatus])



  // eslint-disable-next-line no-unused-vars
  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };


  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const query = useQuery()
  const typeQuery = query.get('t') || ''
  // const employeeTypeQuery = query.get('e') || 'tailor'

  const { width } = useWindowSize()


  const isTitleColumn = useMemo(() => {
    switch (type) {
      case 'stock':
        return true
      case 'stock-store':
        return true
      case 'delivery':
        return true
      default:
        return false
    }
  }, [type])


  const skeletons = [
    <div className={Styles.skeleton1}>
      <Skeleton duration={2} className={Styles.avatar} />
      <div>
        <Skeleton duration={2} className={Styles.name} />
        <Skeleton duration={2} className={Styles.label} />
      </div>
    </div>,
    <Skeleton duration={2} className={Styles.skeleton2} />,
    <Skeleton duration={2} className={Styles.skeleton3} />,
    <Skeleton duration={2} className={Styles.skeleton4} />,
    <Skeleton duration={2} className={Styles.skeleton5} />,
    <Skeleton duration={2} className={Styles.skeleton6} />,
    <Skeleton duration={2} className={Styles.skeleton7} />,
    <div className={Styles.skeleton8}>
      <Skeleton duration={2} className={Styles.each} />
      <Skeleton duration={2} className={Styles.each} />
      <Skeleton duration={2} className={Styles.each} />
      <Skeleton duration={2} className={Styles.each} />
    </div>,
  ]

  return (
    <div className={Styles.container}>
      <div className={Styles.header} ref={headerRef}>
        <div className={`${Styles.top} ${isTitleColumn ? Styles.column : ''}`}>
          <div className={Styles.left}>
            <div className={Styles.title}>
              <Icon icon={template?.icon} size={40} />
              <h3>{template?.title}</h3>
            </div>
            <div className={Styles.typesWrapper}>
              {
                type === 'stock' && stockTypes?.map((s, i) => (
                  <button
                    key={i}
                    className={`${Styles.typeIndicator} ${typeQuery === s?.query ? Styles.active : ''}`}
                    onClick={() => navigate(`${pathname}?t=${s?.query}`, { replace: true })}
                  >
                    {s?.title}
                  </button>
                ))
              }
            </div>

          </div>
          {type !== 'fee'
            &&
            <div className={Styles.right}>
              <Button
                variant='tertiary'
                size='lg'
                buttonText='Filter'
                withIcon
                iconName={"filter"}
                iconPlacement='end'
                disableActive
                onClick={onClickFilter}
                state={width <= 768 ? 'icon-only' : ''}
              />

            </div>
          }

        </div>
        <div className={Styles.bottom}>
          <div className={Styles.left}>
            <Searchbar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder={template?.searchPlaceholder}
              width={width <= 768 ? '19rem' : '38rem'}
              accordionMode={width <= 768 ? true : false}
            />
          </div>
          <div className={Styles.right}>
            <Button
              variant='secondary'
              size='lg'
              buttonText='Download'
              withIcon
              iconName={"document-arrow-down"}
              iconPlacement='start'
              disableActive
              state={width <= 768 ? 'icon-only' : ''}
              fixedPadding={'12px'}
            // customClassName={width <= 768 ? Styles.square : ''}
            />

            {template?.addButton && !noAdd &&
              <Button
                variant='primary'
                size='lg'
                buttonText={template?.addButtonText}
                withIcon
                iconName={"plus"}
                iconPlacement='start'
                onClick={onClickAdd}
                disableActive
                state={width <= 768 ? 'icon-only' : ''}
                fixedPadding={'12px'}
              />
            }
          </div>
        </div>
        {/* <div className={Styles.filler} style={{ position: 'absolute', top: `${headerHeight}px`, left: 0 }} /> */}
      </div>
      <div className={Styles.content}>
        <table >
          <thead
          //  style={{ position: 'sticky', top: `${headerHeight + 2}px` }}
          >
            <tr>
              {columns?.map((column, i) => (
                <th key={column?.name} style={{
                  textAlign: column?.align || 'left',
                  minWidth: column?.minWidth || 'auto',
                  maxWidth: column?.maxWidth || '100px'
                }}
                  // className={column?.thClassName || ''}
                  className={`${column?.thClassName || ''} ${column?.hideOnMobile ? Styles.hideOnMobile : ''} ${column?.showOnMobile ? Styles.showOnMobile : ''}`}
                >
                  <div
                    className={Styles[column?.align + 'Align']}
                  >

                    {column?.renderLabel ? column?.renderLabel(column) :
                      <span>{column?.label}</span>}

                    {column?.label &&
                      <button>
                        <Icon icon="chevron-up-down" size={20} />
                      </button>

                    }
                  </div>


                  <div className={Styles.borderBottom} />
                  <div className={Styles.borderTop} />
                  {
                    column?.withBorderRight && <div className={Styles.borderRight} />
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, i) => (
              <tr
                key={i}
              >
                {columns?.map((column, ic) => (
                  <td
                    id={column?.renderPopup ? `td-${row?.id}` : ''}
                    key={column?.name}
                    // className={column?.tdClassName || ''}
                    className={`${column?.tdClassName || ''} ${column?.hideOnMobile ? Styles.hideOnMobile : ''} ${column?.showOnMobile ? Styles.showOnMobile : ''}`}
                    style={{ textAlign: column.align || 'left' }}
                  >
                    <div className={Styles.wrapper}
                      style={{
                        textAlign: column.align || 'left',
                      }}
                    >
                      {skeletons[ic]}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {!data?.length && <div className={Styles.emptyPlaceholder}>no data yet</div>}
      </div>
      <div className={Styles.paginationWrapper}>
        <Pagination
          total={totalData}
          totalPage={totalPage || Math?.ceil(totalData / value)}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          itemListName={template?.itemListName}
        />
      </div>


    </div>
  )
}