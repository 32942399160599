import TextArea from '@Atom/TextArea'
import Styles from './style.module.scss'

export default function TextAreaField({
  title,
  placeholder,
  value,
  setValue
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>{title}</div>
      <TextArea
        placeholder={placeholder}
        value={value}
        setValue={setValue}
      />
    </div>
  )

}