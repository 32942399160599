import './App.scss';
import { RouterProvider } from 'react-router-dom';
import router from '@Navigators/index';
import "moment/locale/id";

function App() {
  return (
    <RouterProvider router={ router }/>
  )
}

export default App;
