import { fileBaseUrl } from "@Config/api";
import { omitKeys } from "@Helpers/omitKey";
import { priceFormat } from "@Helpers/priceFormat";
import { splitCamelCase } from "@Helpers/splitCamelCase";
import useQuery from "@Hooks/useQuery";
import AsyncErrorBoundary from "@Organism/_utils/AsyncErrorBoundary";
import Images from "@Theme/Images";
import { Document, Font, Image, PDFViewer, Page, Text, View } from "@react-pdf/renderer";
import { Suspense, useEffect, useMemo } from "react";
import { Await, useLoaderData } from "react-router-dom";
import DetailsText1 from "./DetailsText1";
import DetailsText2 from "./DetailsText2";
import DetailsTitle from "./DetailsTitle";
import ItemCard from "./ItemCard";
import styles from "./style";
import moment from "moment";
import { capitalizeWords } from "@Helpers/capitalizeWords";

Font.register({
  family: 'Helvetica-Neue',
  fonts: [
    {
      src: '@Assets/fonts/HelveticaNeue-Regular.ttf'
    },
    {
      src: '@Assets/fonts/HelveticaNeue-Medium.ttf',
      fontWeight: 'medium'
    },
  ]
})

// Font.register({
//   family: 'Helvetica-Neue',
//   src: regular,
//   format: 'truetype'
// })

export default function Invoice() {
  const { packageInvoiceDetails } = useLoaderData()

  return (
    <Suspense
      fallback={
        <>loading</>
      }
    >
      <Await
        resolve={packageInvoiceDetails}
        errorElement={<AsyncErrorBoundary />}
      >
        {(packageInvoiceDetails) => (
          <InvoicePDF
            defaultData={packageInvoiceDetails?.data}
          />
        )}

      </Await>
    </Suspense>
  )
}

const InvoicePDF = ({
  defaultData
}) => {
  const query = useQuery()

  const cuttingFileType = query?.get('cft')

  const data = useMemo(() => {
    if (!cuttingFileType && defaultData) {
      const dataToMap = defaultData
      return ({
        ...dataToMap,
        code: dataToMap?.code,
        invoiceDate: dataToMap?.order?.createdAt ? new Date(dataToMap?.order?.createdAt) : '',
        dueDate: dataToMap?.order?.dueDate ? new Date(dataToMap?.order?.dueDate) : '',
        currentDueDate: dataToMap?.order?.suggestedDueDate ? new Date(dataToMap?.order?.suggestedDueDate) : '',
        payment: `${dataToMap?.paymentType ? capitalizeWords(dataToMap?.paymentType?.replaceAll('-', ' ')) : ''}${dataToMap?.paymentType ? ' • ' : ''}${capitalizeWords(dataToMap?.status?.replaceAll('-', ' '))} • ${capitalizeWords(dataToMap?.paymentMethod?.replaceAll('-', ' '))}`,
        bank: dataToMap?.order?.accountNumber ? `${dataToMap?.order?.bankName || '-'} • ${dataToMap?.order?.accountNumber || '-'}` : '-',
        paid: defaultData?.payments?.map(obj => obj?.amount || 0)?.reduce((accumulator, currentValue) => {
          return accumulator + currentValue
        }, 0),
        subTotal: defaultData?.sum,
        items: dataToMap?.order ? dataToMap?.order?.products?.map(item => {
          return {
            ...item,
            qty: item?.qty || item?.textiles[0]?.qty,
            id: item?._id || item?.name,
            name: item?.name,
            fabricCode: item?.textiles[0]?.code,
            //TODO:price belum sreg
            price: item?.textiles[0]?.price,
            category: item?.category,
            type: item?.type || item?.category,
            img: fileBaseUrl + item?.textiles[0]?.imageUrl,
            // measurements: Object?.entries(item?.measurement)?.map(m => { return { objName: m[0], value: m[1] + ' cm', name: splitCamelCase(m[0]) } }),
            measurements: item?.pattern?.measurement
              ?
              Object.entries(item?.pattern?.measurement)
                // eslint-disable-next-line array-callback-return
                ?.map((m) => {
                  if (m[0] !== "photos") {
                    return {
                      name: m[0],
                      value: m[1],
                      title: splitCamelCase(m[0]),
                    };
                  }

                })
              :
              []
            ,
            cutter: {
              id: item?.cutterCode || '',
              name: item?.cutterName || '',
              img: item?.cutterImg || null
            },
            tailor: {
              id: item?.tailorCode,
              name: item?.tailorName,
              img: item?.tailorImg || null
            },
          }
        }) : dataToMap?.summaries,
        customerName: dataToMap?.customer?.name,
        customerPhoneNumber: dataToMap?.customer?.phone,
        customerAddress: dataToMap?.customer?.address
      })
    } else if (cuttingFileType && defaultData) {
      return defaultData
    } return null

  }, [defaultData])


  const cuttingData = useMemo(() => {
    return {
      ...data,
      code: data?.code,
      invoiceDate: data?.order?.createdAt ? new Date(data?.order?.createdAt) : '',
      dueDate: data?.order?.dueDate ? new Date(data?.order?.dueDate) : '',
      currentDueDate: data?.order?.suggestedDueDate ? new Date(data?.order?.suggestedDueDate) : '',
      payment: data?.paymentMethod,
      subTotal: data?.sum,
      sizes: data?.products?.map(p => {
        return {
          ...p,
          id: p?.pattern?.code,
          qty: 1,
          category: p?.category,
          measurements: p?.pattern?.measurement
            ?
            Object.entries(omitKeys(['photos'], p?.pattern?.measurement))?.map((m) => {
              // if (m[0] !== "photos") {
              return {
                name: m[0],
                value: m[1],
                title: splitCamelCase(m[0]),
              };
              // }

            }).filter((e) => e.value && e.value > 0)
            : [],
          items: p?.textiles?.map(t => {
            return {
              ...t,
              name: t?.name,
              fabricCode: t?.fabric,
              price: t?.price,
              qty: t?.qty,
              category: t?.type,
              type: p?.type,
              img: t?.imageUrl ? fileBaseUrl + t?.imageUrl : ''
            }
          }),
          notes: p?.notes,

        }
      }),
    }
  }, [data])

  return (
    <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerWrapper} fixed>
            <Image src={Images.LOGO_NO_ICON} style={styles.logo} />
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <Text style={styles.title}>{cuttingFileType ? 'Cutting File' : 'Invoice'}</Text>
                <Text style={styles.subtitle}>Order ID: {data?.code}</Text>
              </View>
              <View style={styles.headerRight}>
                <View style={styles.headerRightSection}>
                  <Text style={styles.dueDateTitle}>Order Date</Text>
                  <View style={styles.dueDateContent}>
                    <Image src={Images.CALENDAR_DAYS} style={styles.calendar} />
                    <Text style={styles.formerDueDate}>{data?.invoiceDate ? moment(data?.invoiceDate)?.format('LL') : moment(data?.createdAt)?.format('LL')}</Text>
                    {/* <Text style={styles.currentDueDate}>{datsa?.currentDueDate || '-'}</Text> */}
                  </View>
                </View>
                <View style={styles.headerRightSection}>
                  <Text style={styles.dueDateTitle}>Due Date</Text>
                  <View style={styles.dueDateContent}>
                    <Image src={Images.CALENDAR_DAYS} style={styles.calendar} />
                    <Text style={styles.formerDueDate}>{data?.dueDate ? moment(data?.dueDate)?.format('LL') : moment(data?.createdAt)?.format('LL')}</Text>
                    {/* <Text style={styles.currentDueDate}>{datsa?.currentDueDate || '-'}</Text> */}
                  </View>
                </View>


              </View>
            </View>

          </View>

          <View style={styles.content}>
            <View style={styles.items}>
              <Text style={styles.itemsTitle}>Items</Text>
              {!cuttingFileType
                ? <div>
                    <View style={styles.table}>
                      <View>
                        <View style={styles.tableRow}> 
                          <View style={styles.tabelColNum}> 
                            <Text style={styles.tableCell}>No</Text> 
                          </View> 
                          <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>Product</Text> 
                          </View> 
                          <View style={styles.tableColQty}> 
                            <Text style={styles.tableCell}>Qty</Text> 
                          </View>
                          <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>Price</Text> 
                          </View>
                        </View>
                      </View>
                    {data?.items?.map((item, i) => (
                      <View key={i} style={styles.tableRow}>
                        <View style={styles.tabelColNum}> 
                          <Text style={styles.tableCell}>{i+1}</Text>
                        </View> 
                        <View style={styles.tableCol}> 
                          <Text style={styles.tableCell}>{item?.name}</Text>
                        </View> 
                        <View style={styles.tableColQty}> 
                          <Text style={styles.tableCell}>{item?.qty}</Text>
                        </View>
                        <View style={styles.tableCol}> 
                          <Text style={styles.tableCell}>{priceFormat(item?.price)}</Text>
                        </View>
                      </View>
                    ))}
                        <View style={styles.tableRow}>
                          <View style={styles.tabelColNumMerged}> 
                            <Text style={styles.tableCell}>Total</Text> 
                          </View>
                          <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{priceFormat(data?.items?.map((el) => el.price * (el.qty || 1))?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 0)}</Text> 
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={styles.tabelColNumMerged}> 
                            <Text style={styles.tableCell}>Paid</Text> 
                          </View>
                          <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{priceFormat(data?.paid)}</Text> 
                          </View>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={styles.tabelColNumMerged}> 
                            <Text style={styles.tableCell}>Outstanding</Text> 
                          </View>
                          <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{priceFormat(data?.subTotal - data?.paid)}</Text> 
                          </View>
                        </View>
                    </View>
                  </div>
                :
                cuttingData?.sizes?.map((size, i) => (
                  <ItemCard
                    key={i}
                    size={size}
                    cuttingFileType={cuttingFileType}
                  />
                ))
              }
            </View>
            <View style={styles.details}>
              <View style={styles.customer} >
                <View style={styles.customerInfo}>
                  <DetailsTitle title={'Customer'} />
                  <View style={styles.infoDetails}>
                    <DetailsText1 text={data?.customerName} />
                    <DetailsText2 text={data?.customerPhoneNumber} />
                    <DetailsText2 text={data?.order?.customer?.email ?? data?.customerEmail} />
                  </View>
                </View>
                <View style={styles.address}>
                  <DetailsTitle title={'Address'} />
                  <DetailsText1 text={data?.customerAddress} />
                </View>
              </View>
              {!cuttingFileType
                &&
                <View style={styles.invoiceOnlyWrapper}>
                  <View style={styles.payment}>
                    <DetailsTitle title={'Notes'} />
                    <DetailsText1 text={data?.notes || '-'} />
                  </View>
                  <View style={styles.payment}>
                    <DetailsTitle title={'Payment'} />
                    <DetailsText1 text={data?.payment} />
                  </View>
                  {
                    data?.paymentMethod === 'TRANSFER'
                    &&
                    <View style={styles.payment}>
                      <DetailsTitle title={'Bank Account'} />
                      <DetailsText1 text={data?.bank} />
                    </View>
                  }


                </View>
              }


            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}
