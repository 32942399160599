import SimulationOverviewLayout from '@Organism/Product/SimulationOverview'
import Styles from './style.module.scss'
import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function SimulationOverviewPage() {
  const { packageSimulationThumbnails, packageSimulationTemplate } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <SimulationOverviewLayout
            isLoading={true}
            defaultData={[]}
            defaultThumbnails={[]}
          />
        }
      >
        <Await
          resolve={packageSimulationThumbnails}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageSimulationThumbnails) => (
            <Suspense
              fallback={
                <SimulationOverviewLayout
                  isLoading={true}
                  defaultData={[]}
                  defaultThumbnails={packageSimulationThumbnails?.data}
                />
              }
            >
              <Await
                resolve={packageSimulationTemplate}
              >
                {(packageSimulationTemplate) => (
                  <SimulationOverviewLayout
                    defaultData={packageSimulationTemplate?.data}
                    defaultThumbnails={packageSimulationThumbnails?.data}
                  />
                )}
              </Await>
            </Suspense>


          )}
        </Await>
      </Suspense>
    </div>
  )
}