import ItemsQuantityList from '@Molecule/ItemsQuantityList'
import Styles from './style.module.scss'

export default function ItemsQuantityAdjusterField({
  title,
  selectedItemsTitle,
  value,
  setValue
}) {


  return (
    <div className={Styles.container}>
      <div className={Styles.title}>{title}</div>
      <ItemsQuantityList
        title={selectedItemsTitle}
        data={value}
        setData={setValue}
      />
    </div>
  )
}