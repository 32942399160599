import { API } from "@Config/api";

export function getOrders(page, limit, search, type = "TRANSACTION", startDate = '', endDate = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/order`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          params: {
            page,
            limit,
            search,
            type,
            startDate,
            endDate
          }
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function assignWorker(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/order/re-assign`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateOrderStatus(id, status) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/order/status/${id}`, new URLSearchParams({status}), {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getOrderSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order/sync`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addOrder(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/order`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function editOrder(payload, orderCode) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/order/update/${orderCode}`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          'Content-Type': 'multipart/form-data'
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getOrder(code, type) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/order/${code}?type=${type.toUpperCase()}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
}
