/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Radio from "@Atom/Radio";
import SampleCard from "@Molecule/SampleCard";
import { useCallback, useEffect, useState } from "react";
import Styles from "./style.module.scss";
import MeasurementField from "@Molecule/MeasurementField";
import { fileBaseUrl } from "@Config/api";

export default function MeasurementCard({
  name,
  code,
  measurements = [],
  photos = [],
  onEdit = () => { },
  isChecked = false,
  onSelect = () => { },
  withRadioButton = true,
  handleEdit = () => { },
  handleDelete = () => { },
  withDeleteButton = true,
  isEditMode,
  index = 0,
  sizes = [],
  setSizes = () => { },
  defaultMeasurements,
  withSaveButton = false,
  onSave = () => { },
  withCancelEditButton = false,
  onCancelEdit = () => { },
  hasToClickSave = false
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [tempSizes, setTempSizes] = useState(sizes)
  const [tempMeasurements, setTempMeasurements] = useState(measurements)

  const [tempName, setTempName] = useState(name)

  const handleSetSizes = useCallback(() => {
    const newSizes = [...tempSizes];
    if (newSizes?.length) {
      newSizes[index].size = tempMeasurements;
      newSizes[index].name = tempName
      setTempSizes(newSizes);
      setSizes(tempSizes)
      onSave(tempSizes[index])
    }
  }, [index, onSave, setSizes, tempMeasurements, tempName, tempSizes])

    useEffect(() => {
      if (!hasToClickSave) {
        // handleSetSizes()
      }
    }, [handleSetSizes, hasToClickSave])


    // useEffect(() => {
    //   setTempSizes(sizes)
    // }, [sizes])

    useEffect(()=> {
      setTempName(name)
    }, [name])

    useEffect(()=> {
      setTempMeasurements(measurements)
    }, [measurements])



  if (isEditMode) {
    return (
      <div className={Styles?.size}>
        <div className={Styles.title}>
          {withRadioButton && (
            <Radio isChecked={isChecked} setValue={() => onSelect(code)} />
          )}
          {/* <span>{name || `Size ${index + 1}`}</span> */}
          <input
          value={tempName}
          onChange={(e)=> setTempName(e?.target?.value)}
          />
          <span></span>
          <div className={Styles.editActions}>
            {withCancelEditButton && (
              <button className={Styles.cancelButton} onClick={onCancelEdit}>
                Cancel
              </button>
            )}

            {withSaveButton && (
              <button className={Styles.saveButton} onClick={handleSetSizes}>
                Save
              </button>
            )}


          </div>
        </div>

        <div className={Styles.sizing}>
          {tempMeasurements?.map((eachM, iM) => (
            <MeasurementField
              key={iM}
              title={eachM?.label || eachM?.title}
              value={eachM?.value}
              setValue={(newVal) => {
                const newMeasurements = tempMeasurements?.map((obj) => {
                  if (obj?.name === eachM?.name) {
                    return {
                      ...obj,
                      value: newVal,
                    };
                  }
                  return obj;
                });
                setTempMeasurements(newMeasurements)
                // const newSizes = [...tempSizes];
                // newSizes[index].size = newMeasurements;
                // setTempSizes(newSizes);
              }}
              placeholder={
                defaultMeasurements?.find((obj) => obj?.name === eachM?.name)
                  ?.label
              }
              denom={eachM?.denom || "cm"}
            />
          ))}
        </div>
        <div className={Styles.title}>
        <span>Photos</span>
        </div>
        <div className={Styles.photos}>
          {tempSizes[index]?.photos?.map((eachP, iP) => (
            <SampleCard
              key={iP}
              isFullWidth
              size="100%"
              isOverview
              aspe
              src={eachP?.src || (eachP?.uri ? fileBaseUrl + eachP?.uri : '')}
              setSrc={(newSrc) => {
                const newPhotos = [...tempSizes[index]?.photos];
                newPhotos[iP].src = newSrc || "";
                // newPhotos[iP].uri = newSrc || "";
                const newSizes = [...tempSizes];
                newSizes[index].photos = newPhotos;
                setTempSizes(newSizes);
              }}
              // src={fileBaseUrl + eachP?.uri}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.left} onClick={() => onSelect(code)}>
          {withRadioButton && <Radio isChecked={isChecked} />}
          <h3>
            {name}
            <span>{code}</span>
          </h3>
        </div>
        <div className={Styles.right}>
          <div className={Styles.actions}>
            {withDeleteButton && (
              <button onClick={handleDelete}>
                <Icon icon={"trash"} size={24} />
              </button>
            )}
            <button onClick={handleEdit}>
              <Icon icon={"pencil"} size={24} />
            </button>
          </div>
          <Button
            variant="tertiary"
            buttonText={!isExpanded ? "See details" : "Hide details"}
            withIcon
            iconName={isExpanded ? "chevron-up" : "chevron-down"}
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
            disableActive
            disableHover
            iconPlacement="end"
            fixedPadding={"0px"}
          />
        </div>
      </div>
      {isExpanded && (
        <div className={Styles.content}>
          <div className={Styles.measurements}>
            {measurements
            // ?.filter(obj => obj?.value && obj?.value !== 0)
            ?.map((each, i) => (
              <div key={i} className={Styles.each}>
                <span>{each?.title || each?.name}</span>
                <span>{each?.value || 0}</span>
              </div>
            ))}
          </div>
          <div className={Styles.photos}>
            {photos?.map((each, i) => (
              <SampleCard
                key={i}
                src={each}
                size="100%"
                disableReplace
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
