import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import ModalHeader from '@Atom/ModalHeader'
import Searchbar from '@Atom/Searchbar'
import { priceFormat } from '@Helpers/priceFormat'
import { addBulkStock, checkBulkStock } from '@Services/stock'
import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import { useDebounce } from '@Hooks/useDebounce'
import { formatBytes } from '@Helpers/formatBytes'
import ModalWrapper from '@Atom/ModalWrapper'
import ErrorModal from '../ErrorModal'

export default function StocksFileOverviewModal({
  file,
  json,
  onClickCancel,
  onSuccess,
}) {


  const [searchTerm, setSearchTerm] = useState('')
  const [isError, setIsError] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const [existingCodes, setExistingCodes] = useState([])

  const [showConfirmation, setShowConfirmation] = useState(false)

  useEffect(() => {
    setShowConfirmation(false)
    setExistingCodes([])
  }, [])

  const template = useMemo(() => {

    return ({
      fileName: file?.name,
      fileSize: formatBytes(file?.size),
      data: json?.map((obj, i) => {
        return {
          id: i,
          name: obj['Name'],
          type: obj['Type'],
          code: obj['Product Code'],
          fabricCode: obj['Fabric Code'],
          qty: obj['Qty'],
          description: obj['Description'],
          price: `Rp ${priceFormat(obj['Price'])}`
        }
      }),
      columns: [
        {
          name: 'name',
          label: 'Product Name',
        },
        {
          name: 'type',
          label: 'Product Type',
        },
        {
          name: 'code',
          label: 'Product Code',
        },
        {
          name: 'fabricCode',
          label: 'Fabric Code',
        },
        {
          name: 'qty',
          label: 'Qty',
          align: 'center'
        },
        {
          name: 'description',
          label: 'Description',
        },
        {
          name: 'price',
          label: 'Price',
          align: 'right',
        }
      ]
    })
  }, [file?.name, file?.size, json])

  const totalStocks = useMemo(() => {
    let total = 0
    for (let i = 0; i < template?.data?.length; i++) {
      total += template?.data[i]?.qty
    }
    return total
  }, [template])


  const onSubmit = async (e,forceAdd = false) => {
    try {
      setIsSubmitting(true)

      if (!forceAdd) {
        const { data } = await checkBulkStock(template?.data?.map(obj => obj?.code))
        if (data?.length) {
          setIsSubmitting(false)
          setExistingCodes(data?.map(obj => obj?.productCode))
          setShowConfirmation(true)
        } else {
          await (addBulkStock(file))
          setIsSubmitting(false)
          onSuccess()
        }
      } else {
        await (addBulkStock(file))
        setIsSubmitting(false)
        onSuccess()
      }

    } catch (err) {
      setIsError(true)
      setIsSubmitting(false)
      console.log(err)
    }
  }

  if (showConfirmation) {
    return (
      <div className={Styles.confirmationContainer}>
        <div className={Styles.content}>
          <span>Confirmation</span>
          <p>Some of the product codes listed bellow have been added before :</p>
          <div>
            {existingCodes?.map((c, cI) => (
              <div key={cI}>
                &#8226; {c}
              </div>
            ))}
          </div>
          <p>Do you want to add quantity to previously added products?</p>
        </div>
        <div className={Styles.actions}>
          <Button
            type='button'
            variant='secondary'
            onClick={() => {
              setShowConfirmation(false)
              setExistingCodes([])
              onClickCancel()
            }}
            buttonText='Cancel'
            size='lg'
          />
          <Button
            type='button'
            variant='primary'
            buttonText={!isSubmitting ? 'Add Quantity' : 'Submitting...'}
            size='lg'
            disabled={isSubmitting ? true : false}
            onClick={(e)=> onSubmit(e,true)}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'File Overview'}
        paddingY='16px'
      />
      <div className={Styles.content}>
        <div className={Styles.header}>
          <div className={Styles.left}>
            <div className={Styles.total}>
              <span>{totalStocks}</span>
              <span>stocks</span>
            </div>
            <div className={Styles.file}>
              <Icon icon={'document'} size={32} />
              <div>
                <span>{template?.fileName}</span>
                <span>{template?.fileSize}</span>
              </div>
              <button onClick={() => {
                setShowConfirmation(false)
                onClickCancel()
                setExistingCodes([])
              }}><Icon icon={'trash'} size={24} /></button>
            </div>
          </div>

          <div className={Styles.search}>
            <Searchbar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder='Search products'
            />
          </div>

        </div>
        <div className={Styles.overview}>
          <table>
            <thead>
              <tr>
                {template?.columns
                  ?.map((column, i) => (
                    <th key={i}
                      style={{ textAlign: column?.align || 'left' }}
                    >
                      {column?.label}
                    </th>

                  ))}
              </tr>
            </thead>
            <tbody>
              {
                template?.data
                  ?.filter(obj => obj?.name?.toLowerCase()?.includes(debouncedSearchTerm?.toLowerCase()))
                  ?.map((data, i) => (
                    <tr key={i}>
                      {template?.columns?.map((column, j) => (
                        <td key={j}>
                          <div
                            style={{ textAlign: column?.align || 'left' }}
                          >
                            {data?.[column?.name]}

                          </div>
                        </td>
                      ))}
                    </tr>
                  ))
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className={Styles.actions}>
        <Button
          variant='secondary'
          buttonText='Cancel'
          onClick={onClickCancel}
          size='lg'
        />
        <Button
          variant='primary'
          buttonText={!isSubmitting ?'Submit' : 'Submitting...'}
          onClick={(e)=>onSubmit(e, false)}
          size='lg'
          disabled={isSubmitting ? true : false}
        />
      </div>
      {isError
        &&
        <ModalWrapper
          show={isError}
          setShow={setIsError}
        >
          <ErrorModal />
        </ModalWrapper>

      }

    </div>
  )
}