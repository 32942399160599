import { useEffect, useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Styles from './style.module.scss';
import Icon from '@Atom/Icon';
import moment from 'moment';



export default function CameraView({
  file = null,
  setFile = () => { },
  setShow,
  onProceed = async () => { }
}) {
  const [tempFile, setTempFile] = useState(null)
  const [isProceeding, setIsProceeding] = useState(false)

  const [preview, setPreview] = useState('')
  function handleTakePhoto(dataUri) {
    // console.log(dataUri, 'du')
    const mainStr = dataUri?.replace('data:', '')
    if (mainStr) {
      setPreview(dataUri)
    }
    fetch(dataUri)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `image_${moment().format("D-M-YYYY")}.png`, {
          type: "image/png",
        });
        // const file = URL.createObjectURL(blob);
        setTempFile(Object.assign(file, {
          preview: URL.createObjectURL(file),
        }))

      });
  }

  function handleTakePhotoAnimationDone(dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
  }

  function handleCameraError(error) {
    console.log('handleCameraError', error);
  }

  function handleCameraStart(stream) {
    console.log('handleCameraStart');
  }

  function handleCameraStop() {
    console.log('handleCameraStop');
  }

  const [cameraWidth, setCameraWidth] = useState(window?.innerWidth)
  const [cameraHeight, setCameraHeight] = useState(window?.innerHeight)

  useEffect(() => {
    const setResize = () => {
      setCameraWidth(window.innerWidth)
      setCameraHeight(window.innerHeight)
    }
    setCameraWidth(window.innerWidth)
    setCameraHeight(window.innerHeight)
    window.addEventListener('resize', setResize)
    return () => {
      window.removeEventListener('resize', setResize)
    }
  }, [])
  return (
    <div className={Styles.container}>
      {!preview
        ?

        <div className={Styles.cameraWrapper}>
          <Camera
            onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
            onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
            onCameraError={(error) => { handleCameraError(error); }}
            idealFacingMode={FACING_MODES.USER}
            idealResolution={{ width: cameraWidth, height: cameraHeight }}
            imageCompression={0.97}
            isImageMirror={false}
            isSilentMode={false}
            isDisplayStartCameraError={true}
            isFullscreen={true}
            sizeFactor={1}
            onCameraStart={(stream) => { handleCameraStart(stream); }}
            onCameraStop={() => { handleCameraStop(); }}
          />
          <button
            className={Styles.backButton}
            onClick={() => {
              setTempFile(null)
              setPreview(null)
              setShow(false)
              window.location.reload()
            }}          >
            <Icon icon={'chevron-left'} size={16} />
            Back to List
          </button>
        </div>
        :
        <div className={Styles.result}>
          <img src={preview} alt='' />
          <div className={Styles.actions}>
            <button
              disabled={isProceeding}
              onClick={() => {
                setTempFile(null)
                setPreview(null)
                setShow(false)
              }}
            >
              Cancel
            </button>
            <button
              disabled={isProceeding}
              className={Styles.circle}
              onClick={() => {
                setTempFile(null)
                setPreview(null)
              }}
            >
              Retake
            </button>
            <button
              disabled={isProceeding}
              onClick={async () => {
                try {
                  setIsProceeding(true)
                  await onProceed(tempFile)
                  setIsProceeding(false)
                  setPreview(null)
                  setShow(false)
                } catch (err) {
                  setIsProceeding(false)
                  console.log(err, 'error on proceeding file')
                }

              }}
            >
              {isProceeding ? 'Proceeding...' : 'Proceed'}
            </button>
          </div>

        </div>
      }

    </div>
  )
}