import ModalWrapper from '@Atom/ModalWrapper'
import Styles from './style.module.scss'
import Rotation from '@Atom/Rotation'

export default function LoadingModal({
  show,
}) {

  return (
    <ModalWrapper
      mobilePositioning='center'
      show={show}
    >
      <div className={Styles.container}>
        <Rotation width='50px' type='default'  />
      </div>
    </ModalWrapper>
  )
}