import Input from '@Atom/Input'
import { dateLabelFormatter } from '@Helpers/dateLabelFormatter'
import useQuery from '@Hooks/useQuery'
import ActiveFiltersIndicator from '@Molecule/ActiveFiltersIndicator'
import DateLabel from '@Molecule/DateLabel'
import FilterModal from '@Molecule/_reusableLayout/FilterModal'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'

export default function EmployeeFilterModal({
  handleClose,
  trigger,
  checkpoints = []
}) {
  const query = useQuery()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const defaultDateAddedFrom = query.get('df') || ''
  const defaultDateAddedTo = query.get('dt') || ''
  const defaultRole = query.get('r') || ''
  const defaultPlacement = query.get('pl') ? checkpoints?.find(obj => obj?.code === query.get('pl'))?.name : ''


  const [joinDateFrom, setJoinDateFrom] = useState(defaultDateAddedFrom || '')
  const [joinDateTo, setJoinDateTo] = useState(defaultDateAddedTo || '')
  const [role, setRole] = useState(defaultRole)
  const [placement, setPlacement] = useState(defaultPlacement)

  useEffect(() => {
    setJoinDateFrom(defaultDateAddedFrom)
    setJoinDateTo(defaultDateAddedTo)
    setRole(defaultRole)
    setPlacement(defaultPlacement)
  }, [defaultDateAddedFrom, defaultDateAddedTo, defaultPlacement, defaultRole])

  const filterTemplate = useMemo(() => {
    const arr = []
    if (joinDateFrom || joinDateTo) {
      arr.push({
        title: `Date Added: ${joinDateFrom ? joinDateFrom?.replaceAll('-', '/') : 'DD/MM/YYYY'} - ${joinDateTo ? joinDateTo?.replaceAll('-', '/') : 'DD/MM/YYYY'}`,
        handleRemove: () => {
          setJoinDateFrom('')
          setJoinDateTo('')
        }
      })
    }

    if (role) {
      arr.push({
        title: `Role: ${role}`,
        handleRemove: () => setRole('')
      })
    }

    if (placement) {
      arr.push({
        title: `Placement: ${placement}`,
        handleRemove: () => {
          setPlacement('')
        }
      })
    }
    return arr
  }, [joinDateFrom, joinDateTo, role, placement])

  const allQueries = useMemo(() => {
    const arr = []
    for (const param of query) {
      arr.push(param);
    }

    const newArr =
      !arr?.find(obj => obj[0] === 'df')
        ? (
          !arr?.find(obj => obj[0] === 'dt')
            ?
            (
              !arr?.find(obj => obj[0] === 'r')
                ?
                (
                  !arr?.find(obj => obj[0] === 'pl')
                    ?
                    [...arr, ['df', joinDateFrom], ['dt', joinDateTo], ['r', role], ['pl', checkpoints?.find(obj => obj?.name === placement)?.code]]
                    :
                    [...arr, ['df', joinDateFrom], ['dt', joinDateTo], ['r', role],]
                )
                :
                [...arr, ['df', joinDateFrom], ['dt', joinDateTo]]

            )
            :
            [...arr, ['df', joinDateFrom]]
        )
        : [...arr]

    return newArr
  }, [checkpoints, joinDateFrom, joinDateTo, placement, query, role])

  const fixedQuery = useMemo(() => {
    let str = ''

    const raw = allQueries.join(',')?.split(',')

    const strArr = []

    const convertDate = (str) => {
      const arr = str?.split('/')
      return [arr[2], arr[1], arr[0]]?.join('-')
    }

    for (let i = 0; i < raw.length / 2; i++) {
      strArr.push({
        key: raw[i + i],
        value: raw[i + i] === 'df'
          ? joinDateFrom ? (joinDateFrom?.includes('/') ? convertDate(joinDateFrom) : joinDateFrom ): ''
          : (
            raw[i + i] === 'dt'
              ? joinDateTo ? (joinDateTo?.includes('/') ? convertDate(joinDateTo) : joinDateTo) : ''
              :
              (raw[i + i] === 'r'
                ? role || ''
                : (
                  raw[i + i] === 'pl'
                    ?
                    checkpoints?.find(obj => obj?.name === placement)?.code || ''
                    :
                    raw[i + i + 1]
                )
              )
          )
      })
    }


    for (let i = 0; i < strArr.length; i++) {
      const s = strArr[i]
      str += (i === 0 ? `${s?.key}=${s?.value}` : `&${s?.key}=${s?.value}`)
    }


    return str

  }, [allQueries, joinDateFrom, joinDateTo, role, checkpoints, placement])



  const contentTemplate = useMemo(() => {
    return [
      {
        title: 'Role',
        description:
          <div className={Styles.roleWrapper}>
            <Input
              value={role}
              setValue={setRole}
              placeholder={'Select role'}
              isDropdown={true}
              dropdownOptions={[
                'COURIER',
                'TAILOR',
                'CUTTER'
              ]}
            />
          </div>

      },
      {
        title: 'Date Added',
        description: (
          <div className={Styles.dateRangeWrapper}>
            <DateLabel
              intro='From'
              date={dateLabelFormatter(joinDateFrom, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setJoinDateFrom(moment(new Date(e?.target?.value))?.format('L'))}
            />
            <DateLabel
              intro='To'
              date={dateLabelFormatter(joinDateTo, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setJoinDateTo(moment(new Date(e?.target?.value))?.format('L'))}
            />
          </div>
        )
      },
      {
        title: 'Placement',
        description:
          <div className={Styles.roleWrapper}>
            <Input
              value={placement}
              setValue={setPlacement}
              placeholder={'Select placement'}
              isDropdown={true}
              dropdownOptions={checkpoints?.map(obj => obj?.name)}
            />
          </div>

      },
    ]
  }, [checkpoints, joinDateFrom, joinDateTo, placement, role])

  const handleReset = () => {
    setJoinDateFrom('')
    setJoinDateTo('')
    setRole('')
    setPlacement('')
  }

  const handleApply = () => {
    navigate(pathname + `?${fixedQuery}`, { replace: true })
    handleClose()
  }
  return (
    <FilterModal
      handleClose={handleClose}
      handleReset={handleReset}
      handleApply={handleApply}
      extraHeader={
        <ActiveFiltersIndicator
          filters={filterTemplate}
        />

      }
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.content}>
          <span>{content?.title}</span>
          {content?.description}
        </div>
      ))}
    </FilterModal>
  )
}