import Slider from '@Molecule/Slider';
import { useEffect, useRef, useState } from 'react';
import Styles from './style.module.scss';
import Icon from '@Atom/Icon';

export default function PicturesPreviewModal({
  srcArr = [],
  onClose = () => { },
  defaultIndex = '0',
  slidesToShow = 6,
  hideArrows = false,
  hideSubArrows = false,
}) {
  const [activeIndex, setActiveIndex] = useState(+defaultIndex)

  useEffect(() => {
    setActiveIndex(+defaultIndex)
  }, [defaultIndex])

  const sliderRef = useRef()
  const miniSliderRef = useRef()

  useEffect(() => {
    sliderRef?.current?.slickGoTo(activeIndex)
    miniSliderRef?.current?.slickGoTo(activeIndex)
  }, [activeIndex])

  return (
    <div className={Styles.container}>
      <button onClick={onClose}>
        <Icon icon={'x-circle-solid'} size={28} />
      </button>
      <div className={Styles.sliderWrapper}>
        <Slider
          showArrows={!hideArrows}
          refToForward={sliderRef}
          setIndex={setActiveIndex}
        >
          {srcArr?.map((each, i) => (
            <div key={i} className={Styles.main}>
              <img className={Styles.mainImg} src={each} alt='' />
            </div>
          ))}
        </Slider>
        <div className={Styles.miniSlider}>
          <Slider
            showArrows={!hideSubArrows}
            slidesToShow={srcArr?.length >slidesToShow ? slidesToShow : srcArr?.length}
            slidesToScroll={1}
            arrowLeftPosition='-32px'
            arrowRightPosition='-32px'
            refToForward={miniSliderRef}
            setIndex={setActiveIndex}
          >
            {srcArr?.map((each, i) => (
              <div key={i} className={`${Styles.sub} ${activeIndex === i ? Styles.active : ''}`}>
                <img onClick={() => setActiveIndex(i)} className={Styles.subImg} src={each} alt='' />
                {activeIndex === i
                &&
                <div onClick={() => setActiveIndex(i)} className={Styles.layer} />
                }
              </div>
            ))}
          </Slider>

        </div>
      </div>
    </div>
  )
}