import Button from '@Atom/Button'
import RekhasBG from '@Atom/RekhasBG'
import { fileBaseUrl } from '@Config/api'
import { priceFormat } from '@Helpers/priceFormat'
import useQuery from '@Hooks/useQuery'
import useWindowSize from '@Hooks/useWindowSize'
import StackedItemInfo from '@Molecule/StackedItemInfo'
import TableSet from '@Molecule/TableSet'
import Images from '@Theme/Images'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'

export default function EmployeeListLayout({
  setSelectedEmployee,
  isLoading,
  defaultData,
  setShowAdd,
  setShowFilter,
  trigger,
  setTrigger,
  checkpoinList=[],
  setCheckpoints=()=>{}
}) {
  const query = useQuery()
  const tableType = query.get('tt') || 'list'
  const type = query.get('t') || 'tukang'

  useEffect(()=> {
    setCheckpoints(checkpoinList)
  }, [checkpoinList, setCheckpoints])

  const [loadingData, setLoadingData] = useState(isLoading)

  useEffect(() => {
    setLoadingData(isLoading)
  }, [isLoading])

  const { width, height } = useWindowSize()


  const template = useMemo(() => {
    return ({
      data: defaultData?.users?.map(obj => {
        return {
          ...obj,
          name: obj?.fullName,
          phoneNumber: obj?.whatsAppNumber,
          img: obj?.imageUrl ? fileBaseUrl + obj?.imageUrl : Images.AVA_DEFAULT,
          role: obj?.role,
          joinDate: obj?.joinDate || '',
          idCard: obj?.idCard,
          address: obj?.address,
          placement: obj?.placement?.name,
          placementImg: obj?.placement?.imageUrl ? fileBaseUrl + obj?.placement?.imageUrl : null
        }
      }),
      masterFeeData: defaultData?.users?.length ?
        defaultData?.users?.map(obj => {
          return {
            ...obj,
            id: obj?._id,
            code: obj?.code,
            lastUpdate: obj?.lastUpdate ? moment(new Date(obj?.lastUpdate))?.format('DD/MM/YYYY') : '',
            name: obj?.name,
            phoneNumber: obj?.phoneNumber,
            address: obj?.address,
            unpaid: +obj?.fee || 0,
            paid: +obj?.paid || 0,
            itemsArr:[],
            itemAmount: obj?.items
          }
        })
        :
        [],
     
      columns: [
        {
          name: 'name',
          label: 'Employee Name',
          minWidth: '270px',
          maxWidth: '270px',
          renderData: (row) => (
            <StackedItemInfo
              title={row?.name}
              label={row?.phoneNumber}
              picture={row?.img || Images.AVA_DEFAULT}
              maxWidth='283px'
              imgRadius='50%'
            />
          ),
          skeleton:
            <StackedItemInfo
              maxWidth='283px'
              imgRadius='50%'
              isSkeleton
            />

        },

        {
          name: 'role',
          label: 'Role',
          minWidth: '124px',
          maxWidth: '124px',
          hideOnMobile: true,
        },
        {
          name: 'nickName',
          label: 'Nickname',
          minWidth: '124px',
          maxWidth: '124px',
          hideOnMobile: true,
        },
        // {
        //   name: 'joinDate',
        //   label: 'Join Date',
        //   minWidth: '124px',
        //   maxWidth: '124px',
        // },
        // {
        //   name: 'idCard',
        //   label: 'Id Card',
        //   minWidth: '267px',
        //   maxWidth: '267px',
        // },
        {
          name: 'placement',
          label: 'Placement',
          minWidth: '280px',
          maxWidth: '280px',
          hideOnMobile: true,
        },
        {
          name: '',
          label: '',
          showOnMobile: true,
          align: 'right',
          minWidth: '80px',
          maxWidth: '80px',
          renderData: (row) => (
            // <div>
              <Button
                state='icon-only'
                variant='secondary'
                disableActive
                withIcon
                iconName={'list-bullet'}
                size='24'
                fixedPadding={'12px'}
                onClick={() => setSelectedEmployee(row)}
              />
            // </div>
          )
        }
      ],
      masterFeeColumns: [
        {
          name: 'lastUpdate',
          label: 'Last Update',
          minWidth: width <= 768 ? '108px' : '124px',
          renderData: (row)=> (
            <span>{row?.lastUpdate}</span>
          )
        },
        {
          name: 'name',
          label: type === 'cutter' ? 'Cutter' : 'Tailor',
          // minWidth: '429px',
          // maxWidth: '453px',
          maxWidth: width <= 768 ? '176px' : '313px',
          minWidth: width <= 768 ? '176px' : '313px',
          renderData: (row) => (
            <StackedItemInfo
              title={row?.name}
              label={row?.phoneNumber || '-'}
              picture={row?.img || Images.AVA_DEFAULT}
              imgRadius='50%'
              titleWidth={width <= 768 ? '150px' : '100%'}
            />
          ),
          skeleton:
            <StackedItemInfo
              imgRadius='50%'
              titleWidth={width <= 768 ? '150px' : '100%'}
              isSkeleton
            />


        },
        {
          name: 'itemsArr',
          label: 'Items',
          hideOnMobile: true,
          minWidth: '246px',
          maxWidth: '270px',
          renderData: (row) => (
            row?.itemAmount > 1
              ? <div
                className={Styles.itemsIndicator}
              >
                <span>{row?.itemAmount} Items</span>
              </div>
              : 
              <>-</>
              // <ItemTitle
              //   name={row?.itemsArr[0]?.name || 'no item'}
              //   category={row?.itemsArr[0]?.category || ''}
              //   length={row?.itemsArr[0]?.length || '- m'}
              //   type={row?.itemsArr[0]?.type || ''}
              // />

          ),



        },
        {
          name: 'unpaid',
          label: 'Unpaid',
          hideOnMobile: true,
          renderData:
            (row) => (<span>Rp {row?.unpaid ? priceFormat(row?.unpaid) : '-'}</span>)
        },
        {
          name: 'paid',
          label: 'Paid',
          hideOnMobile: true,
          renderData:
            (row) => (<span>Rp {row?.paid ? priceFormat(row?.paid) : '-'}</span>)

        },
        // {
        //   name: 'paymentPercentage',
        //   label: 'Payment',
        //   minWidth: '150px',
        //   hideOnMobile: true,
        //   renderData: (row) => (
        //     <Tag
        //       title={row?.paymentPercentage === 50 ? '50%' : 'Paid Off'}
        //       type={row?.paymentPercentage === 50 ? 'yellow' : 'green'}
        //     />
        //   )
        // },
        {
          name: '',
          label: '',
          showOnMobile: true,
          renderData: (row) => (
            <div>
              <Button
                state='icon-only'
                variant='secondary'
                disableActive
                withIcon
                iconName={'list-bullet'}
                size='24'
                fixedPadding={'12px'}
              />
            </div>
          )
        }

      ]
    })
  }, [defaultData?.users, setSelectedEmployee, type, width])

  const user = localStorage.getItem('user') ? JSON?.parse(localStorage.getItem('user')) : null
  const role = user?.role


  const [parentTableType, setParentTableType] = useState(tableType)
  // eslint-disable-next-line no-unused-vars
  const [parentTabType, setParentTabType] = useState(type)


  return (
    <RekhasBG
      isMasterEmployee={role === 'DIRECTOR' || role === 'OFFICE' ? true : false}
      activeTab={parentTableType}
      setActiveTab={setParentTableType}
      maxHeight='1050px'
    >
      <TableSet
        withSkeleton
        customHeight={(role === 'DIRECTOR' || role === 'OFFICE') ? (height < 1440 ? 'calc(100vh - 12rem)' : '865px') : '100%'}
        setParentTabType={setParentTabType}
        type={
          role === 'DIRECTOR' || role === 'OFFICE'
            ?
            (parentTableType === 'fee'
              ?
              'fee'
              :
              'employee-director')

            :
            'employee'
        }
        data={
          role === 'DIRECTOR' || role === 'OFFICE'
            ?
            (parentTableType === 'fee'
              ?
              template?.masterFeeData
              :
              template?.data)

            :
            template?.data

        }
        columns={
          role === 'DIRECTOR' || role === 'OFFICE'
            ?
            (parentTableType === 'fee'
              ?
              template?.masterFeeColumns
              :
              template?.columns)

            :
            template?.columns

        }
        onClickRow={(row) => setSelectedEmployee(row)}
        totalPage={defaultData?.totalPage}
        totalData={defaultData?.total}
        isLoading={loadingData}
        setIsLoading={setLoadingData}
        additionalQuery={`&tt=${parentTableType}`}
        feeSync={defaultData?.templateFee || defaultData?.feeTemplate}
        onClickAdd={() => setShowAdd(true)}
        trigger={trigger}
        setTrigger={setTrigger}
        onClickFilter={() => setShowFilter(true)}
      />
    </RekhasBG>
  )
}