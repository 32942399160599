import Styles from './style.module.scss'

export default function Label({
  title,
  desc,
  isTransparent = false,
  padding = '0.8rem',
  customClassName = '',
  borderRadius = '0.8rem'
}) {

  return (
    <div className={`${Styles.container} ${customClassName}`} style={{ background: isTransparent ? 'transparent' : '#fff', padding, borderRadius }}>
      {title
        &&
        <span>{title}</span>
      }
      <div>{desc}</div>
    </div>
  )
}