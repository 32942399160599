import SampleCard from '@Molecule/SampleCard'
import Styles from './style.module.scss'

export default function UploadField({
  title,
  value,
  setValue,
  fileType,
  cardTitle,
  isFullWidth = false
}) {

  return (
    <div className={Styles.container}>
      {
        title && <div className={Styles.title}>{title}</div>
      }
      <SampleCard
        src={value}
        setSrc={setValue}
        fileType={fileType}
        name={cardTitle}
        size='212px'
        isFullWidth={isFullWidth}
      />
    </div>
  )
}