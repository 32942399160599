import Icon from '@Atom/Icon'
import RekhasBG from '@Atom/RekhasBG'
import { capitalizeWords } from '@Helpers/capitalizeWords'
import TableSet from '@Molecule/TableSet'
import { useMemo } from 'react'
import Styles from './style.module.scss'

export default function LogListLayout({
  defaultData,
  isLoading,
  trigger,
  setTrigger,
  setShowFilter
}) {


  const template = useMemo(() => {
    return ({
      data: [
        // {
        //   description: <p><span>15 Italiano Barzagli</span> was moved from <span>Checkpoint 1</span> to <span>Checkpoint 2</span></p>,
        //   date: moment(new Date())?.format('ll'),
        //   type: 'STOCK'
        // },
        // {
        //   description: <p><span>Italiano Barzagli</span> delivered from <span>Workshop</span> to <span>Stephen Curry</span> location</p>,
        //   date: moment(new Date())?.format('ll'),
        //   type: 'ORDER'
        // },
      ],
      columns: [

        {
          name: 'description',
          label: 'Description',
          minWidth: '208px',
          maxWidth: '208px',
          renderData: (row) => (
            <div className={Styles.desc}>
              {row?.description}
            </div>
          )
        },
        {
          name: 'date',
          label: 'Date',
          minWidth: '187px'
        },
        {
          name: 'type',
          label: 'Type',
          minWidth: '187px',
          renderData: (row) => (
            <div
              className={`${Styles.type} ${Styles[row?.type?.toLowerCase()]}`}
            >
              <Icon icon={row?.type?.toLowerCase() === 'stock' ? 'rectangle-stack' : 'archive-box'} size={20} />
              <span>{capitalizeWords(row?.type)}</span>
            </div>
          )
        },
        // {
        //   name: '',
        //   label: '',
        //   showOnMobile: true,
        //   align: 'right',
        //   minWidth: '80px',
        //   maxWidth: '80px',
        //   renderData: (row) => (
        //     // <div>
        //       <Button
        //         state='icon-only'
        //         variant='secondary'
        //         disableActive
        //         withIcon
        //         iconName={'list-bullet'}
        //         size='24'
        //         fixedPadding={'12px'}
        //         onClick={() => handleClickRow(row)}
        //       />
        //     // </div>
        //   )
        // }

      ]
    })
  }, [])

  return (
    <RekhasBG>
      <TableSet
        type='log'
        data={template?.data}
        columns={template?.columns}
        // onClickRow={(row) => navigate(`${pathname}/overview/${row?.id}`)}
        onClickFilter={() => setShowFilter(true)}
        trigger={trigger}
        setTrigger={setTrigger}
        totalPage={defaultData?.totalPage || 0}
        totalData={defaultData?.total || template?.data?.length}
        withNumbering
        withSkeleton
      />
    </RekhasBG>)
}