import Avatar from "@Atom/Avatar";
import Button from "@Atom/Button";
import Checkbox from "@Atom/Checkbox";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import ModalWrapper from "@Atom/ModalWrapper";
import SmallLabel from "@Atom/SmallLabel";
import Tag from "@Atom/Tag";
import { allHasValueObjectChecker } from "@Helpers/allHasValueObjectChecker";
import useOutsideClick from "@Hooks/useOutsideClick";
import InputField from "@Molecule/InputField";
import SizeCard from "@Molecule/SizeCard";
import Snackbar from "@Molecule/Snackbar";
import StackedItemInfo from "@Molecule/StackedItemInfo";
import TextAreaField from "@Molecule/TextAreaField";
import ConfirmationModal from "@Molecule/_modals/ConfirmationModal";
import DetailsModal from "@Molecule/_reusableLayout/DetailsModal";
import EditFormTooltip from "@Molecule/_tooltips/EditFormTooltip";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Styles from "./style.module.scss";
import { splitCamelCase } from "@Helpers/splitCamelCase";
import { getWorkerList } from "@Services/employee";
import {
  getWorkshopById,
  updateStatusDelivery,
  updateStatus,
  updateWorker,
  updateSchedule,
} from "@Services/workshop";
import Label from "@Atom/Label";
import { makeRandomString } from "@Helpers/makeRandomString";
import { capitalizeWords } from "@Helpers/capitalizeWords";

const Wrapper = styled.div`
  position: absolute;
  top: -66px;
  left: -263px;
  z-index: 1;
`;

export default function WorkshopDetailsModal({
  handleClose = () => { },
  workshopData,
  onOpenHold = () => { },
  onOpenResume = () => { },
  onOpenCancel = () => { },
  setTrigger = () => { }
}) {
  const [showToOnProcess, setShowToOnProcess] = useState(false);
  const [userList, setUserList] = useState(null);

  const [assignWorkerForm, setAssignWorkerForm] = useState({
    master: "",
    tukang: "",
  });

  const userData = useMemo(() => {
    return {
      masters:
        userList?.cutters?.map((el) => ({ id: el.code, name: el.name })) || [],
      tukangs:
        userList?.tailors?.map((el) => ({ id: el.code, name: el.name })) || [],
      drivers:
        userList?.couriers?.map((el) => ({ id: el.code, name: el.name })) || [],
    };
  }, [userList]);

  const [workshop, setWorkshop] = useState(workshopData);

  const onChangeAssignWorkerForm = useCallback(
    (newVal, name) => {
      const findMaster = userData?.masters.find((el) => el?.name === newVal);
      const findTailor = userData?.tukangs.find((el) => el?.name === newVal);
      if (name === "master" && findMaster?.id) {
        updateWorker(findMaster?.id, workshop?.id, "CUTTER");
      } else if (name === "tukang" && findTailor?.id) {
        updateWorker(findTailor?.id, workshop?.id, "TAILOR");
      }
      setAssignWorkerForm((prev) => {
        return {
          ...prev,
          [name]: newVal,
        };
      });
    },
    [userData?.masters, userData?.tukangs, workshop?.id]
  );

  const getWorkshop = useCallback(async () => {
    try {
      const { data } = await getWorkshopById(workshop?.id);
      if (data.status) {
        setWorkshop((prev) => ({ ...prev, ...data }));
      }
    } catch (err) {
      console.log(err, 'err on fetch workshop')
    }

  }, [workshop?.id]);

  useEffect(() => {
    getWorkshop();
  }, [getWorkshop]);

  const [showSnackbar, setShowSnackbar] = useState(false);

  const statusToBe = useMemo(() => {
    switch (workshop?.status) {
      case "REQUESTED":
        return "On Process";
      case "ON-PROCESS":
        return "Ready";
      case "READY":
        return "On Delivery";
      case "ONDELIVERY":
        return "Delivered";
      default:
        return "no status";
    }
  }, [workshop?.status]);

  const [scheduleMode, setScheduleMode] = useState(false);

  const [scheduleForm, setScheduleForm] = useState({
    address: workshop?.address,
    driver: "",
    deliveryDate: workshop?.deliveryDate,
    suggestDate: workshop?.delivery?.suggest,
    pic: workshop?.deliveryDate,
    pickupDate: workshop?.pickupDate,
    note: workshop?.deliveryNote,
    isPriority: workshop?.isPriority || false,
  });

  const [returnMode, setReturnMode] = useState(false);

  useEffect(() => {
    setScheduleForm({
      address: workshop?.to?.address,
      driver: workshop?.driverName || "",
      deliveryDate: workshop?.delivery?.date,
      suggestDate: workshop?.delivery?.suggest,
      pickupDate: workshop?.pickupDate || "",
      note: workshop?.deliveryNote || "",
      isPriority: workshop?.isPriority || false,
    });
  }, [workshop]);

  const onChangeScheduleForm = (newVal, name) => {
    setScheduleForm((prev) => {
      return {
        ...prev,
        [name]: newVal,
      };
    });
  };
  
  useEffect(() => {
    setShowToOnProcess(false);
    setAssignWorkerForm({
      master: "",
      tukang: "",
    });
    setScheduleMode(false);
  }, [workshop]);

  useEffect(() => {
    const getData = async () => {
      const { data } = await getWorkerList();
      setUserList(data);
    };
    getData();
  }, []);

  const [activeTooltip, setActiveTooltip] = useState("");

  useEffect(() => {
    const findMaster = userData.masters.find(
      (el) => el.id === workshop.cutterCode
    );
    if (findMaster) {
      setAssignWorkerForm((prev) => ({ ...prev, master: findMaster.name }));
    }
  }, [userData, workshop]);
  useEffect(() => {
    const findTailor = userData.tukangs.find(
      (el) => el.id === workshop.tailorCode
    );
    if (findTailor) {
      setAssignWorkerForm((prev) => ({ ...prev, tukang: findTailor.name }));
    }
  }, [userData, workshop]);

  const onUpdateToReady = useCallback(async () => {
    try {
      const update = await updateStatus(workshop?.id, "READY");
      setTrigger(makeRandomString(5))
      if (
        allHasValueObjectChecker(assignWorkerForm) &&
        update.status === "SUCCESS"
      ) {
        setShowSnackbar(true);
        setActiveTooltip("");
        getWorkshop();
      } else {
        if (!assignWorkerForm?.master) {
          setActiveTooltip("master");
        } else {
          setActiveTooltip("tukang");
        }
      }
    } catch (err) {
      console.log(err)
    }

  }, [assignWorkerForm, getWorkshop, setTrigger, workshop?.id]);
  const onUpdateToOnProcess = useCallback(async () => {
    try {
      await updateStatus(workshop?.id, "ON-PROCESS");
      getWorkshop()
      setShowSnackbar(true);
      setShowToOnProcess(false);
      setTrigger(makeRandomString(5))
    } catch (err) {
      console.log(err)
    }

  }, [getWorkshop, setTrigger, workshop?.id]);
  // const onUpdateToReady = async() => {
  //   const update = await updateStatus(workshop?.id, "READY");
  //   if (
  //     allHasValueObjectChecker(assignWorkerForm) &&
  //     update.status === "SUCCESS"
  //   ) {
  //     setShowSnackbar(true);
  //     setActiveTooltip("");
  //     getWorkshop();
  //   } else {
  //     if (!assignWorkerForm?.master) {
  //       setActiveTooltip("master");
  //     } else {
  //       setActiveTooltip("tukang");
  //     }
  //   }
  // };

  // eslint-disable-next-line no-unused-vars
  const tagTemplate = useMemo(() => {
    const status = workshop?.itemStatus;
    switch (status) {
      case "ON-PROCESS":
        return {
          type: "yellow",
          title: "On Process",
        };
      case "DELIVERED":
        return {
          type: "green",
          title: "Delivered",
        };
      case "READY":
        return {
          type: "black",
          title: "Ready",
        };
      case "SCHEDULED":
        return {
          type: "blue",
          title: "Scheduled",
        };
      case "ONDELIVERY":
        return {
          type: "blue",
          title: "Scheduled",
        };
      default:
        return {
          type: "yellow",
          title: "On Process",
        };
    }
  }, [workshop]);

  const contentTemplate = useMemo(() => {
    return [
      {
        title: "Item Details",
        component: (
          <div className={Styles.itemDetails}>
            <div className={Styles.intro}>
              <div className={Styles.label}>
                <div>
                  <Icon icon={workshop?.type?.toLowerCase()} size={40} />
                </div>
                {/* <Avatar
                  src={''}
                  withIcon
                  iconName={workshop?.label?.toLowerCase()}
                  iconSize='40px'
                  size='48px'
                  bworkshopRadius='4px'
                  iconColor='#6C757D'
                /> */}
                <div>{workshop?.type}</div>
              </div>
              <div className={Styles.fabrics}>
                {workshop?.fabrics?.map((f, i) =>
                  f.type !== "ALTER" ? (
                    <StackedItemInfo
                      key={i}
                      title={`${i === 0 ? "Primary" : "Other"} Fabric: ${f?.type
                        } - ${f?.name}`}
                      label={f?.fabricCode}
                      maxWidth="100%"
                      titleWidth="100%"
                      titleFontSize="16px"
                      picture={f?.img}
                      imgRadius="6px"
                      imgSize="40px"
                    />
                  ) : (
                    <div className={Styles.alter}>
                      <Label desc={"Alter"} padding="0px 8px" />
                      <div className={Styles.alterQty}>
                        <StackedItemInfo
                          withoutPicture
                          title="Tailoring Fabric"
                          label={workshop?.type}
                          titleFontSize="14px"
                        />
                        <span>{f.qty}x</span>
                      </div>
                      {/* <div className={Styles.alterDesc}>
                        <span>Descriptions</span>
                        <span>{f.desc || '-'}</span>
                      </div> */}
                      <div className={Styles.alterNotes}>
                        <span>Notes</span>
                        <span>{workshop?.notes || '-'}</span>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <SizeCard
              sizeName={workshop?.pattern?.name || ''}
              sizeID={workshop?.pattern?.code || ''}
              measurements={
                workshop?.pattern?.measurement
                  ? Object.entries(workshop?.pattern?.measurement)?.map((m) => {
                    return {
                      name: m[0],
                      value: m[1],
                      title: splitCamelCase(m[0]),
                    };
                  })
                  : []
              }
              customBworkshopColor="#CED4DA"
              photos={workshop?.pattern?.photos || []}
            />
            {/* <div className={Styles.customerPhotos}>
              {workshop?.customerPhotos?.map((p, pIdx) => (
                <button
                  key={pIdx}
                >
                  <Avatar
                    size='24px'
                    src={p?.url}
                    bworkshopRadius='6px'
                  />
                </button>
              ))}
            </div> */}
            <div className={Styles.notes}>
              <span>Notes</span>
              <div className={Styles.text}>{workshop?.notes || '-'}</div>
            </div>
            {/* <Tag type={tagTemplate?.type} title={tagTemplate?.title} /> */}
            <div />
          </div>
        ),
      },
      {
        title: "Fabric Code",
        component: <div className={Styles.desc}>{workshop?.textiles?.length ? workshop?.textiles[0]?.fabric || '-' : '-'}</div>,
      },
      {
        title: "Customer Name",
        component: (
          <div className={Styles.customerName}>
            <span>{workshop?.customerName}</span>
            <span>{workshop?.customerPhoneNumber}</span>
          </div>
        ),
      },
      {
        title: "Order ID",
        component: <div className={Styles.desc}>{workshop?.orderID}</div>,
      },
      // {
      //   title: 'Status Details',
      //   component:
      //     <div className={Styles.statusDetails}>
      //       <Tag
      //         type={tagTemplate?.type}
      //         title={tagTemplate?.title}
      //       />
      //       <span>-</span>
      //       <span>Workshop</span>
      //     </div>
      // },
    ];
  }, [workshop?.type, workshop?.fabrics, workshop?.pattern?.name, workshop?.pattern?.code, workshop?.pattern?.measurement, workshop?.pattern?.photos, workshop?.notes, workshop?.textiles, workshop?.customerName, workshop?.customerPhoneNumber, workshop?.orderID]);

  const myElementRef = useRef(null);
  const backupRef = useRef(null);
  // const [positionTop, setPositionTop] = useState(0);
  // const [positionLeft, setPositionLeft] = useState(0);

  const [changeScheduleMode, setChangeScheduleMode] = useState("");

  const handleSubmitReSchedule = useCallback(async () => {
    const dataToSend = {
      resecheduleDate: moment(new Date(scheduleForm.deliveryDate))?.format(
        "YYYY-MM-DD"
      ),
    };
    const res = await updateSchedule(dataToSend, workshop?.id);

    setTrigger(makeRandomString(5))


    getWorkshop();

    if (res.status) {
      setChangeScheduleMode("");
      // setWorkshop((prev) => ({ ...prev, ...res.data }));
    }
  }, [getWorkshop, scheduleForm.deliveryDate, setTrigger, workshop?.id]);

  const handleSubmitSuggestDate = useCallback(async () => {
    const dataToSend = {
      suggestDate: moment(new Date(scheduleForm.suggestDate))?.format(
        "YYYY-MM-DD"
      ),
    };
    const res = await updateSchedule(dataToSend, workshop?.id);

    setTrigger(makeRandomString(5))


    getWorkshop();

    if (res.status) {
      setChangeScheduleMode("");
      // setWorkshop((prev) => ({ ...prev, ...res.data }));
    }
  }, [getWorkshop, scheduleForm.suggestDate, setTrigger, workshop?.id]);

  const deliveryDetailsTemplate = useMemo(() => {
    return [
      {
        title: "Address",
        component: (
          <div className={Styles.desc}>{workshop?.to?.address || ""}</div>
        ),
      },
      {
        title: "Driver",
        component: (
          <StackedItemInfo
            picture={Images.AVA_DEFAULT}
            imgRadius="50%"
            title={workshop?.delivery?.courierName}
          />
        ),
      },
      {
        title: "Delivery Date",
        component: changeScheduleMode ? (
          changeScheduleMode !== 'suggestDate'
            ?
            <Input
              type="date"
              placeholder={"Select date"}
              value={scheduleForm?.deliveryDate}
              setValue={(newVal) => onChangeScheduleForm(newVal, "deliveryDate")}
            />
            :
            <Input
              type="date"
              placeholder={"Select date"}
              value={scheduleForm?.suggestDate}
              setValue={(newVal) => onChangeScheduleForm(newVal, "suggestDate")}
            />
        ) : (
          <div className={Styles.date}>
            <Icon icon={"calendar"} size={24} />
            <span>
              {workshop?.delivery?.date
                ? moment(new Date(workshop?.delivery?.date))?.format("ll")
                : "-"}
            </span>
            {
              !!workshop?.delivery?.suggest
              &&
              <Tag
                type={'blue'}
                title={moment(new Date(workshop?.delivery?.suggest))?.format('ll')}
                noBullet
              />
            }
          </div>
        ),
      },
      {
        title: "Note",
        component: (
          <div className={Styles.desc}>{workshop?.delivery?.note || "-"}</div>
        ),
      },
      {
        title: "",
        component: (
          <div className={Styles.detailScheduleActions}>
            <Button
              buttonText={
                !changeScheduleMode
                  ? "Reschedule"
                  : changeScheduleMode === "reschedule"
                    ? "Submit Reschedule"
                    : "Submit New Schedule"
              }
              size="lg"
              onClick={() => {
                if (!changeScheduleMode) {
                  setChangeScheduleMode("reschedule");
                } else {
                  if (changeScheduleMode === "reschedule") {
                    handleSubmitReSchedule();
                  } else {
                    handleSubmitSuggestDate();
                  }
                }
              }}
              withIcon
              iconName={"calendar"}
            // fixedPadding={'8px 16px'}
            />
            <Button
              buttonText={!changeScheduleMode ? "Suggest Date" : "Cancel"}
              size="lg"
              variant="secondary"
              onClick={() => {
                if (!changeScheduleMode) {
                  setChangeScheduleMode("suggestDate");
                } else {
                  setChangeScheduleMode("");
                }
              }}
              withIcon
              iconName={!changeScheduleMode ? "calendar" : "x-mark"}
            // fixedPadding={'8px 16px'}
            />
          </div>
        ),
      },
    ];
  }, [changeScheduleMode, handleSubmitReSchedule, handleSubmitSuggestDate, scheduleForm?.deliveryDate, scheduleForm?.suggestDate, workshop?.delivery?.courierName, workshop?.delivery?.date, workshop?.delivery?.note, workshop?.delivery?.suggest, workshop?.to?.address]);

  const outsideTooltip = useOutsideClick(myElementRef);

  useEffect(() => {
    setActiveTooltip("");
  }, [assignWorkerForm]);

  useEffect(() => {
    if (activeTooltip && outsideTooltip) {
      setActiveTooltip("");
    }
  }, [activeTooltip, outsideTooltip]);

  // useEffect(() => {
  //   if (activeTooltip) {

  //     const handleScroll = () => {
  //       const el = myElementRef.current;
  //       setPositionTop(el.scrollTop);
  //       setPositionLeft(el.scrollLeft);
  //     };

  //     const element = myElementRef.current;
  //     element.addEventListener("scroll", handleScroll);

  //     return () => {
  //       element.removeEventListener("scroll", handleScroll);
  //     };
  //   }

  // }, [activeTooltip]);
  const handleSubmitSchedule = useCallback(async () => {
    const findDriver = userData.drivers.find(
      (el) => el.name === scheduleForm.driver
    );
    if (findDriver?.id) {
      const dataToSend = {
        type: "ITEM",
        isPriority: scheduleForm.isPriority ? "PRIORITY" : "NORMAL",
        id: workshop?.id,
        from: workshop?.from,
        to: { ...workshop?.to, address: scheduleForm.address },
        scheduleAt: scheduleForm.deliveryDate,
        note: scheduleForm.note,
        courierCode: findDriver.id,
        items: [
          {
            name: workshop?.name,
            type: workshop?.type,
            orderID: workshop?.orderID || '',
            notes: workshop?.notes || '',
            pattern:{
              ...workshop?.pattern?.measurement,
              name: workshop?.pattern?.name || '',
              remark: workshop?.pattern?.remark || '',
              code: workshop?.pattern?.code || '',
              photos: workshop?.pattern?.photos || [],
              textiles: workshop?.textiles ||[]
            },
            textiles: workshop?.textiles ||[]
          }
        ]
      };
      try {
        const res = await updateStatusDelivery(dataToSend);
        setTrigger(makeRandomString(5))
        getWorkshop()
        if (res.status) {
          setScheduleMode(false);
          // setWorkshop((prev) => ({ ...prev, ...res.data }));
        }
      } catch (err) {
        console.log(err, 'err on submit schedule')
      }

    }
  }, [getWorkshop, scheduleForm.address, scheduleForm.deliveryDate, scheduleForm.driver, scheduleForm.isPriority, scheduleForm.note, setTrigger, userData.drivers, workshop?.from, workshop?.id, workshop?.name, workshop?.notes, workshop?.orderID, workshop?.pattern?.code, workshop?.pattern?.measurement, workshop?.pattern?.name, workshop?.pattern?.photos, workshop?.pattern?.remark, workshop?.textiles, workshop?.to, workshop?.type]);

  const additionalContent = useMemo(() => {
    switch (workshop?.status) {
      case "ON-REQUEST":
        return [
          {
            title: "",
            component: (
              <Button
                buttonText="Update Status to On Process"
                size="lg"
                onClick={() => setShowToOnProcess(true)}
              // fixedPadding={'8px 16px'}
              />
            ),
          },
        ];
      // case "REQUESTED":
      //   return [
      //     {
      //       title: "",
      //       component: (
      //         <Button
      //           buttonText="Update Status to On Process"
      //           size="lg"
      //           onClick={() => setShowToOnProcess(true)}
      //         // fixedPadding={'8px 16px'}
      //         />
      //       ),
      //     },
      //   ];
      case "ON-PROCESS":
        return [
          {
            title: "Assign Master & Tukang",
            component: (
              <div className={Styles.assignWorkerForm}>
                {activeTooltip && (
                  <Wrapper>
                    <EditFormTooltip
                      descComponent={
                        <p>
                          Before updating your status, please make sure to
                          assign <span>Master</span> & <span>Tukang</span> First
                        </p>
                      }
                    />
                  </Wrapper>
                )}
                <div
                // ref={activeTooltip === 'master' ? myElementRef : backupRef}
                >
                  <InputField
                    title={"Master"}
                    value={assignWorkerForm?.master}
                    setValue={(newVal) =>
                      onChangeAssignWorkerForm(newVal, "master")
                    }
                    placeholder={"Search and select employee"}
                    isDropdown
                    dropdownOptions={userData.masters?.map((obj) => obj?.name)}
                  />
                  {/* {activeTooltip === 'master'
                    &&
                    <Wrapper >
                      <EditFormTooltip
                        descComponent={<p>Before updating your status, please make sure to assign <span>Master</span> & <span>Tukang</span> First</p>}
                      />
                    </Wrapper>
                  } */}
                </div>
                <div
                  ref={activeTooltip === "tukang" ? myElementRef : backupRef}
                >
                  <InputField
                    title={"Tukang"}
                    value={assignWorkerForm?.tukang}
                    setValue={(newVal) =>
                      onChangeAssignWorkerForm(newVal, "tukang")
                    }
                    placeholder={"Search and select employee"}
                    isDropdown
                    dropdownOptions={userData.tukangs?.map((obj) => obj?.name)}
                  />
                  {/* {activeTooltip === 'tukang'
                    &&
                    <Wrapper>
                      <EditFormTooltip />
                    </Wrapper>
                  } */}
                </div>
              </div>
            ),
          },
          {
            title: "",
            component: (
              <Button
                buttonText="Update Status to Ready"
                size="lg"
                onClick={onUpdateToReady}
                // fixedPadding={'8px 16px'}
                disabled={
                  assignWorkerForm.master === "" ||
                  assignWorkerForm.tukang === ""
                }
              />
            ),
          },
        ];
      case "READY":
        return [
          {
            title: "Master & Tukang",
            component: (
              <div className={Styles.workers}>
                <div>
                  <span>Master</span>
                  <StackedItemInfo
                    picture={Images.AVA_DEFAULT}
                    imgRadius="50%"
                    title={assignWorkerForm?.master}
                  />
                </div>
                <div>
                  <span>Tukang</span>
                  <StackedItemInfo
                    picture={Images.AVA_DEFAULT}
                    imgRadius="50%"
                    title={assignWorkerForm?.tukang}
                  />
                </div>
              </div>
            ),
          },
          {
            title: scheduleMode ? "Schedule Delivery" : "",
            component: !scheduleMode ? (
              <Button
                buttonText={"Schedule Delivery"}
                size="lg"
                onClick={() => setScheduleMode(true)}
                withIcon
                iconName={"calendar"}
              // disabled
              // fixedPadding={'8px 16px'}
              />
            ) : (
              <div className={Styles.scheduleForm}>
                <div className={Styles.address}>
                  <span>Address</span>
                  <div>
                    <p>{scheduleForm.address}</p>
                    <button>
                      <Icon icon={"pencil"} size={24} />
                    </button>
                  </div>
                </div>
                <InputField
                  title={"Driver"}
                  value={scheduleForm?.driver}
                  setValue={(newVal) => onChangeScheduleForm(newVal, "driver")}
                  placeholder={"Select driver"}
                  isDropdown
                  dropdownOptions={userData.drivers?.map((obj) => obj?.name)}
                />
                <InputField
                  title={"Delivery Date"}
                  value={scheduleForm?.deliveryDate}
                  setValue={(newVal) =>
                    onChangeScheduleForm(newVal, "deliveryDate")
                  }
                  type="date"
                />
                <TextAreaField
                  title={"Driver"}
                  value={scheduleForm?.note}
                  setValue={(newVal) => onChangeScheduleForm(newVal, "note")}
                  placeholder={"Note to driver"}
                />
                <div className={Styles.isPriority}>
                  <Checkbox
                    isChecked={scheduleForm?.isPriority}
                    setIsChecked={(newVal) =>
                      onChangeScheduleForm(newVal, "isPriority")
                    }
                  />
                  <span>Set as priority</span>
                </div>
                <div className={Styles.scheduleActions}>
                  <Button
                    buttonText={"Submit Schedule"}
                    size="lg"
                    onClick={() => handleSubmitSchedule()}
                    withIcon
                    iconName={"calendar"}
                  // fixedPadding={'8px 16px'}
                  />
                  <Button
                    buttonText={"Cancel"}
                    size="lg"
                    variant="secondary"
                    onClick={() => setScheduleMode(false)}
                    withIcon
                    iconName={"x-mark"}
                  // fixedPadding={'8px 16px'}
                  />
                </div>
              </div>
            ),
          },
        ];
      case "SCHEDULED":
        return [
          {
            title: "Delivery Details",
            component: (
              <div className={Styles.deliveryDetails}>
                {deliveryDetailsTemplate?.map((each, i) => (
                  <div key={i}>
                    <span>{each?.title}</span>
                    {each?.component}
                  </div>
                ))}
              </div>
            ),
          },
        ];
      case "ON-DELIVERY":
        return [
          {
            title: "Delivery Details",
            component: (
              <div className={Styles.deliveryDetails}>
                {deliveryDetailsTemplate?.map((each, i) => (
                  <div key={i}>
                    <span>{each?.title}</span>
                    {each?.component}
                  </div>
                ))}
              </div>
            ),
          },
        ];
      case "DELIVERED":
        switch (returnMode) {
          case true:
            return [
              {
                title: "Address",
                component: (
                  <div className={Styles.desc}>{workshop?.address}</div>
                ),
              },
              {
                title: "Delivered Date",
                component: (
                  <div className={Styles.date}>
                    <Icon icon={"calendar"} size={24} />
                    <span>
                      {workshop?.deliveryDate
                        ? moment(new Date(workshop?.deliveryDate))?.format("ll")
                        : "-"}
                    </span>
                  </div>
                ),
              },
              {
                title: "Delivery Details",
                component: (
                  <Button
                    buttonText="Go to Delivery Details"
                    variant="secondary"
                    withIcon
                    iconName={"arrow-right"}
                    iconPlacement="end"
                    customIconSize={"20px"}
                  />
                ),
              },
              {
                title: "Schedule Return for Revamp",
                component: (
                  <div className={Styles.scheduleForm}>
                    <InputField
                      title={"Driver"}
                      value={scheduleForm?.driver}
                      setValue={(newVal) =>
                        onChangeScheduleForm(newVal, "driver")
                      }
                      placeholder={"Select driver"}
                      isDropdown
                      dropdownOptions={userData.drivers?.map(
                        (obj) => obj?.name
                      )}
                    />
                    <InputField
                      title={"Pickup Date"}
                      placeholder={"Select date"}
                      value={scheduleForm?.pickupDate}
                      setValue={(newVal) =>
                        onChangeScheduleForm(newVal, "pickupDate")
                      }
                      type="date"
                    />
                    <TextAreaField
                      title={"Driver"}
                      value={scheduleForm?.note}
                      setValue={(newVal) =>
                        onChangeScheduleForm(newVal, "note")
                      }
                      placeholder={"Note to driver"}
                    />
                    <div className={Styles.isPriority}>
                      <Checkbox
                        isChecked={scheduleForm?.isPriority}
                        setIsChecked={(newVal) =>
                          onChangeScheduleForm(newVal, "isPriority")
                        }
                      />
                      <span>Set as priority</span>
                    </div>
                    <div className={Styles.scheduleActions}>
                      <Button
                        buttonText={"Submit Schedule"}
                        size="lg"
                        onClick={() => setReturnMode(false)}
                        withIcon
                        iconName={"calendar"}
                      // fixedPadding={'8px 16px'}
                      />
                      <Button
                        buttonText={"Cancel"}
                        size="lg"
                        variant="secondary"
                        onClick={() => setReturnMode(false)}
                        withIcon
                        iconName={"x-mark"}
                      // fixedPadding={'8px 16px'}
                      />
                    </div>
                  </div>
                ),
              },
            ];
          default:
            return [
              {
                title: "Master & Tukang",
                component: (
                  <div className={Styles.workers}>
                    <div>
                      <span>Master</span>
                      <StackedItemInfo
                        picture={Images.AVA_DEFAULT}
                        imgRadius="50%"
                        title="Aldy Lorenzo"
                      />
                    </div>
                    <div>
                      <span>Tukang</span>
                      <StackedItemInfo
                        picture={Images.AVA_DEFAULT}
                        imgRadius="50%"
                        title="Aldy Lorenzo"
                      />
                    </div>
                  </div>
                ),
              },
              {
                title: "Delivery Details",
                component: (
                  <div className={Styles.deliveryDetails}>
                    {deliveryDetailsTemplate
                      ?.filter((obj) => obj?.title)
                      ?.map((each, i) => (
                        <div key={i}>
                          <span>{each?.title}</span>
                          {each?.component}
                        </div>
                      ))}
                  </div>
                ),
              },
              {
                title: "Delivery Details",
                component: (
                  <Button
                    buttonText="Go to Delivery Details"
                    variant="secondary"
                    withIcon
                    iconName={"arrow-right"}
                    iconPlacement="end"
                    customIconSize={"20px"}
                  />
                ),
              },
              {
                title: "",
                component: (
                  <Button
                    type="button"
                    variant="secondary"
                    // buttonText={!confirmationMode ? 'Submit' : 'Yes, submit stock'}
                    withIcon
                    iconName={"calendar"}
                    buttonText="Schedule Return for Revamp"
                    size="lg"
                    onClick={() => setReturnMode(true)}
                  // onClick={!confirmationMode ? () => setConfirmationMode(true) : handleSubmit}
                  />
                ),
              },
            ];
        }
      default:
        return [];
    }
  }, [
    activeTooltip,
    assignWorkerForm.master,
    assignWorkerForm.tukang,
    deliveryDetailsTemplate,
    handleSubmitSchedule,
    onChangeAssignWorkerForm,
    onUpdateToReady,
    returnMode,
    scheduleForm.address,
    scheduleForm?.deliveryDate,
    scheduleForm?.driver,
    scheduleForm?.isPriority,
    scheduleForm?.note,
    scheduleForm?.pickupDate,
    scheduleMode,
    userData.drivers,
    userData.masters,
    userData.tukangs,
    workshop?.address,
    workshop?.deliveryDate,
    workshop?.status,
  ]);

  useEffect(() => {
    if (showSnackbar) {
      setTimeout(() => {
        setShowSnackbar(false);
      }, 2000);
    }
  }, [showSnackbar]);


  return (
    <DetailsModal
      // customClassName={Styles.container}
      modalTitle={"Workshop Details"}
      modalIcon={"list-bullet"}
      handleClose={handleClose}
      customExtraComponent={
        <div className={Styles?.extraCard}>
          {
            workshop?.type !== 'READY-TO-WEAR'
            && workshop?.type !== 'COLLECTION'
            && workshop?.type !== 'REGULER'
            &&
            <Avatar
              // src={workshop?.imgUrl}
              size="56px"
              bworkshopRadius="6px"
              withIcon
              iconName={workshop?.type?.toLowerCase()}
              iconSize="48px"
              borderRadius="4px"
              iconColor="#6C757D"
            />
          }

          <div className={Styles.title}>
            <div>
              <SmallLabel text={workshop?.type} />
              <Tag
                type={
                  workshop?.status === "ON-PROCESS"
                    ? "yellow"
                    : workshop?.status === "DELIVERED"
                      ? "green"
                      : workshop?.status?.includes('REQUEST')
                        ? "blue"
                        : workshop?.status === "READY"
                          ? "black"
                          : "orange"
                }
                // title={
                //   workshop?.status === "ON-PROCESS"
                //     ? "On Process"
                //     : workshop?.status === "DELIVERED"
                //       ? "Delivered"
                //       : workshop?.status?.includes('REQUEST')
                //         ? "Requested"
                //         : workshop?.status === "READY"
                //           ? "Ready"
                //           : "On Delivery"
                // }
                title={capitalizeWords(workshop?.status?.replaceAll('-', ' '))}
              />{" "}
            </div>
            {
              workshop?.name === 'ALTER'
                ?
                <span>ALTER</span>
                :
                <span>{`${workshop?.fabrics[0]?.type}: ${workshop?.fabrics[0]?.name}`}</span>
            }
          </div>
          {showSnackbar && (
            <div className={Styles.snacbarWrapper}>
              <Snackbar
                desc={
                  <p>
                    Status changed to <span>{statusToBe}</span>
                  </p>
                }
                onClose={() => setShowSnackbar(false)}
              />
            </div>
          )}
        </div>
      }
      noTag
      noPadding
    >
      <div className={Styles.content}>
        {/* <div style={{ width: '100px', height: '100px', background: 'red', overflow: 'visible', overflowY: 'clip' }}>
          test
          <div
            style={{
              width: '100px',
              height: '100px',
              background: 'purple',
              position: 'relative'
            }}
          >
 <button
            style={{ width: '200px', height: '10px', background: 'yellow', position: 'absolute', left: '-100px' }}
          >

          </button>
          </div>

        </div> */}
        {contentTemplate?.map((content, i) => (
          <div key={i}>
            {(content?.title || content?.titleComponent) &&
              (content?.titleComponent || <span>{content?.title}</span>)}
            {content?.component}
          </div>
        ))}
        {additionalContent?.map((content, i) => (
          <div key={i}>
            {(content?.title || content?.titleComponent) &&
              (content?.titleComponent || <span>{content?.title}</span>)}
            {content?.component}
          </div>
        ))}
      </div>
      {workshop?.status !== "DELIVERED" && (
        <div className={Styles.actions}>
          <Button
            type="button"
            variant="secondary"
            // buttonText={!confirmationMode ? 'Clear Form' : 'No, back to form'}
            buttonText={workshop?.status === 'ON-HOLD' ? 'Resume Order' : "Hold Order"}
            size="lg"
            customColor={"#E79800"}
            onClick={workshop?.status === 'ON-HOLD' ? onOpenResume : onOpenHold}
          // onClick={!confirmationMode ? handleReset : () => setConfirmationMode(false)}
          />
          <Button
            type="button"
            variant="secondary"
            // buttonText={!confirmationMode ? 'Submit' : 'Yes, submit stock'}
            buttonText="Cancel Order"
            size="lg"
            customColor={"#DD0B0B"}
            onClick={onOpenCancel}
          // onClick={!confirmationMode ? () => setConfirmationMode(true) : handleSubmit}
          />
        </div>
      )}
      {showToOnProcess && (
        <ModalWrapper show={showToOnProcess} setShow={setShowToOnProcess}>
          <ConfirmationModal
            title="Update Status to On Process"
            desc="Are you sure you want to update status?"
            cancelButtonText="No, go back"
            confirmButtonText="Yes, update"
            onCancel={() => setShowToOnProcess(false)}
            // onConfirm={() => {
            //   // setShowSnackbar(true);
            //   // setShowToOnProcess(false);
            // }}
            onConfirm={onUpdateToOnProcess}
          />
        </ModalWrapper>
      )}
    </DetailsModal>
  );
}
