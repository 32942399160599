import Button from '@Atom/Button'
import ModalHeader from '@Atom/ModalHeader'
import ModalWrapper from '@Atom/ModalWrapper'
import TextAreaField from '@Molecule/TextAreaField'
import InputField from "@Molecule/InputField";
import { createInvoice, createManualInvoice } from '@Services/invoice'
import { capitalizeWords } from "@Helpers/capitalizeWords";
import moment from 'moment'
import { useCallback, useState, useMemo, useEffect } from 'react'
import { getOrderSync } from "@Services/order";

import _ from 'lodash';
import Icon from '@Atom/Icon'
import RadioField from "@Molecule/RadioField";

import WideErrorModal from '../WideErrorModal'
import Styles from './style.module.scss'
import { getProductList } from '@Services/product';
import { priceFormat } from '@Helpers/priceFormat';

export default function GenerateInvoiceModal({
  show,
  handleClose = () => { },
  data = {},
  fetchData = () => { },
  isManual = false,
  customerList = [],
}) {

  const [error, setError] = useState()

  const [customerSearch, setCustomerSearch] = useState('');
  const [newCustomer, setNewCustomer] = useState(false);
  const [form, setForm] = useState({
    customerName: '',
    customerAddress: '',
    customerPhoneNumber: '',
    customerEmail: '',
    customerCode: '',
    fabric: '',
    products: [],
    bankName: '',
    accountNumber: ''
  });
  const [notes, setNotes] = useState('')
  const [search, setSearch] = useState('')
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  // const [bankName, setBankName] = useState('')
  // const [accountNumber, setAccountNumber] = useState('')
  const [onGenerating, setOnGenerating] = useState(false)
  const [defaultSync, setDefaultSync] = useState([])

  useEffect(() => {
    getOrderSync()
      .then((resp) => {
        if(resp?.data) setDefaultSync(resp.data)
      })
  }, [])

  const onGenerateInvoice = useCallback(async () => {
    try {
      setOnGenerating(true)
      await createInvoice({
        orderCode: data?.code,
        customerCode: data?.customerCode,
        customerName: data?.customerName,
        customerPhone: data?.customerPhone,
        customerAddress: data?.customerAddress,
        notes: notes || '',
        bankName: data?.bankName || '',
        accountNumber: data?.accountNumber || '',
        status: data?.status,
        dueDate: data?.dueDate ? moment(new Date(data?.dueDate))?.format('YYYY-MM-DD') : '',
        items: data?.summaries?.map((p, pI) => {
          return {
            name: p?.name,
            price: parseInt(p?.price || ''),
            qty: p?.qty || 1,
            type: p?.type || p?.textiles[0]?.type,
            category: p?.category || '',
            textiles: p?.textiles?.map((t, tI) => {
              return {
                _id: data?.products[pI]?.textiles[tI]?._id || '',
                code: data?.products[pI]?.textiles[tI]?.code || '',
                // name: t?.name,
                price: parseInt(t?.price || ''),
                qty: t?.qty,
                imageUrl: t?.imageUrl || ''
              }
            })
          }
        })
      })
      fetchData()
      setOnGenerating(false)
      handleClose()
    } catch (err) {
      setOnGenerating(false)
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
      console.log(err, 'error on generate invoice')
    }
  }, [data?.accountNumber, data?.bankName, data?.code, data?.customerAddress, data?.customerCode, data?.customerName, data?.customerPhone, data?.dueDate, data?.products, data?.status, data?.summaries, fetchData, handleClose, notes])

  const onCreateManualInvoice = useCallback(async () => {
    try {
      setOnGenerating(true)
      const payload = {
        notes,
        customerName: form.customerName,
        customerAddress: form.customerAddress,
        customerPhoneNumber: form.customerPhoneNumber,
        customerEmail: form.customerEmail,
        customerCode: form.customerCode,
        items: selectedProducts,
        bankName: form.bankName,
        accountNumber: form.accountNumber
      }
      await createManualInvoice(payload)
      fetchData()
      setOnGenerating(false)
      handleClose()
    } catch (err) {
      setOnGenerating(false)
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
    }
  }, 
    [
      fetchData,
      handleClose,
      notes,
      form.customerName,
      form.customerAddress,
      form.customerPhoneNumber,
      form.customerEmail,
      form.customerCode,
      selectedProducts,
      form.accountNumber,
      form.bankName
    ]
  )

  const fetchProducts = (query) => {
    const type = form.fabric === 'READY TO WEAR' ? 'READY-TO-WEAR' : form.fabric;
    getProductList(type, query)
      .then((resp) => {
        if (resp?.data && resp.data?.length) {
          setProducts(resp.data)
        }
      })
  }

  const debouncedFetchResults = useCallback(
    _.debounce((query) => fetchProducts(query), 300),
    [form.fabric]
  );

  const handleInputChange = (value) => {
    setSearch(value);
    debouncedFetchResults(value);
  };


  const onClickDropdownItem = (value) => {
    const findExistingSelectedProd = selectedProducts.find((v) => v.productCode === value);
  
    let findSelectedProduct;
    if (findExistingSelectedProd) {
      findSelectedProduct = {
        ...findExistingSelectedProd,
        qty: findExistingSelectedProd.qty + 1
      };
      setSelectedProducts((prevVal) => 
        prevVal.map((product) => 
          product.productCode === value ? findSelectedProduct : product
        )
      );
    } else {
      findSelectedProduct = products
        .filter((v) => v.productCode === value)
        .map((v) => ({
          ...v,
          qty: 1
        }))?.[0]
        setSelectedProducts((prevVal) => ([...prevVal, findSelectedProduct]))
    }

    setTimeout(() => {
      setSearch('');
    }, 500)
  }

  const onDeleteSelectedProduct = useCallback((productCode) => {
      setSelectedProducts((prevVal) => 
        prevVal.filter((product) => product.productCode !== productCode)
      );
  }, [])

  const handleQtyChange = (productCode, newQty) => {
    setSelectedProducts((prevVal) =>
      prevVal.map((product) =>
        product.productCode === productCode ? { ...product, qty: newQty } : product
      )
    );
  };

  return (
    <ModalWrapper
      show={show}
      handleClose={!error ? handleClose : () => setError(null)}
    >
      {
        error
          ?
          <WideErrorModal
            code={error?.code || 500}
            error={error?.message || 'Something went wrong!'}
            withButton
            onClose={() => {
              setError(null)
              // setConfirmationMode(false)
            }
            }
          />
          :
          <div className={`${Styles.container} ${Styles.auto}`}>

            <ModalHeader
              title={'Generate Invoice'}
              // iconName={'plus'}
              withIcon
              withCloseButton
              handleClose={handleClose}
              paddingX='2.4rem'
              paddingY='1.6rem'
              isSticky
            />

            <div className={Styles.content}>

              <div className={Styles.formContainer}>
                {/* <InputField
                  title={"Bank Name"}
                  placeholder={'Bank name'}
                  value={bankName}
                  setValue={setBankName}
                />
                <InputField
                  title={"Account Number"}
                  placeholder={'Account number'}
                  value={accountNumber}
                  setValue={setAccountNumber}
                /> */}
                {
                  isManual && (
                    <>
                      { !newCustomer ? 
                          <>
                            <InputField
                              title={"Customer"}
                              placeholder={"Select Existing Customer"}
                              isDropdown
                              dropdownOptions={customerList?.map((obj) => `${obj?.name} - ${obj?.phoneNumber}`)}
                              // value={form?.customerName}
                              value={customerSearch}
                              setValue={(newVal) => {
                                setCustomerSearch(newVal)
                                const findCustomer = newVal?.includes(' - ')
                                  ? customerList.find(
                                    (el) => el?.phoneNumber === newVal?.split(' - ')[1]
                                  )
                                  : null
                                return setForm((f) => {
                                  return {
                                    ...f,
                                    customerName: newVal?.includes(' - ') ? newVal?.split(' - ')[0] : newVal,
                                    customerAddress: findCustomer?.address,
                                    customerPhoneNumber: findCustomer?.phoneNumber,
                                    customerEmail: findCustomer?.email,
                                    customerCode: findCustomer?.code,
                                  };
                                });
                              }}
                            />
                            <div className={Styles.orSeparator}>
                              <div />
                              <span>OR</span>
                              <div />
                            </div>
                            <Button
                              variant="secondary"
                              withIcon
                              iconName={"plus"}
                              size="lg"
                              buttonText="Add New Customer"
                              onClick={() => {
                                setNewCustomer(true);
                                setForm(prev => ({
                                  ...prev,
                                  customerName: "",
                                  customerAddress: "",
                                  customerWhatsApp: "",
                                  customerEmail: "",
                                }));
                              }}
                            />
                          </>
                        : 
                          <div className={Styles.new}>
                            <button style={{ color: 'red' }} onClick={() => setNewCustomer(false)}>Cancel</button>

                            <div style={{ height: '10px' }}></div>
                            <InputField
                              title={"Customer Name"}
                              placeholder={"Customer name"}
                              value={form?.customerName}
                              setValue={(newVal) =>
                                setForm((f) => {
                                  return {
                                    ...f,
                                    customerName: newVal,
                                  };
                                })
                              }
                            />
                            <InputField
                              title={"Customer Address"}
                              placeholder={"Customer address"}
                              value={form?.customerAddress}
                              setValue={(newVal) =>
                                setForm((f) => {
                                  return {
                                    ...f,
                                    customerAddress: newVal,
                                  };
                                })
                              }
                            />
                            <InputField
                              title={"Customer Whatsapp"}
                              type="tel"
                              placeholder={"8xxxx"}
                              value={form?.customerWhatsApp}
                              setValue={(newVal) =>
                                setForm((f) => {
                                  return {
                                    ...f,
                                    customerWhatsApp: newVal,
                                  };
                                })
                              }
                              required
                            />
                            <InputField
                              title={"Customer Email"}
                              placeholder={"Customer email"}
                              value={form?.customerEmail}
                              setValue={(newVal) =>
                                setForm((f) => {
                                  return {
                                    ...f,
                                    customerEmail: newVal,
                                  };
                                })
                              }
                            />
                          </div>
                      }
                      <InputField
                        title={"Category"}
                        placeholder={"Select Fabric"}
                        isDropdown
                        dropdownOptions={['COLLECTION', 'REGULAR', 'READY TO WEAR']}
                        value={form.fabric}
                        setValue={val => {
                          setForm((prevVal) => ({
                            ...prevVal,
                            fabric: val
                          }))
                        }}
                      />
                      <InputField
                        title={"Product"}
                        placeholder={"Select Product"}
                        isDropdown
                        disabled={form.fabric?.length == 0}
                        dropdownOptions={products?.map((obj) => `${obj?.productCode}`)}
                        value={search}
                        setValue={handleInputChange}
                        onClickDropdownItem={onClickDropdownItem}
                      />
                      <div className={Styles.divider}></div>
                      {selectedProducts && selectedProducts?.length > 0 && (
                        <table>
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Product Code</th>
                              <th>Product Name</th>
                              <th>Qty</th>
                              <th>Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedProducts.map((selectedProduct, i) => (
                              <tr key={i}>
                                <td style={{ textAlign: 'center' }}>{i + 1}</td>
                                <td style={{ textAlign: 'center' }}>{selectedProduct?.productCode}</td>
                                <td style={{ textAlign: 'center' }}>{selectedProduct?.name}</td>
                                <td style={{ textAlign: 'center' }}>
                                    <input
                                      type='number'
                                      className={Styles.inputQty}
                                      min={0}
                                      value={selectedProduct.qty}
                                      onChange={e => handleQtyChange(selectedProduct.productCode, e.target.value?.length > 0 ? parseInt(e.target.value) : '')}
                                    />
                                </td>
                                <td>{priceFormat((selectedProduct?.price ?? 0) * selectedProduct.qty)}</td>
                                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px' }}>
                                  <button
                                    onClick={() => onDeleteSelectedProduct(selectedProduct.productCode)}
                                  >
                                    <Icon icon={'x-circle-solid'} size={24} />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>Total</td>
                              <td>{priceFormat(selectedProducts.reduce((sum, product) => sum + (product.price * product.qty), 0) ?? 0)}</td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                    </>
                  )
                }
                <TextAreaField
                  title={'Notes'}
                  placeholder={'Notes'}
                  value={notes}
                  setValue={setNotes}

                />
              </div>

            </div>

            <div className={Styles.actionsWrapper}>
              <Button
                type='button'
                variant='secondary'
                buttonText='Cancel'
                size='lg'
                onClick={handleClose}
              />
              <Button
                type='button'
                variant='primary'
                buttonText={onGenerating ? 'Generating Invoice...' : 'Generate Invoice'}
                size='lg'
                disabled={onGenerating || !form?.customerWhatsApp}
                onClick={isManual ? onCreateManualInvoice : onGenerateInvoice}
              />
            </div>


          </div>
      }

    </ModalWrapper>
  )



}