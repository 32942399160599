import { API } from "@Config/api";

export function login(phoneNumber) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/sign-in`, { phoneNumber: phoneNumber }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function resendOTP(employeeId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/resend-otp/${employeeId}`, null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export const verifyOTP = (hash, otp) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/verify-otp/${hash}`, { otp: otp }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
