import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import { fileBaseUrl } from '@Config/api'
import SimulationThumbnail from '@Molecule/SimulationThumbnail'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function SimulationLayout({
  isLoading,
  defaultData
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()


  const categories = useMemo(() => {
    return defaultData?.map(obj => {
      return {
        label: obj?.name,
        query: obj?.code,
        img: obj?.imageUrl ? fileBaseUrl + obj?.imageUrl : null,
      }
    })

  }, [defaultData])


  return (
    <div className={Styles.container}>
      <div className={Styles.banner} >
        <div className={Styles.title}>
          <span>Simulation</span>
          <Icon icon={'solar_hanger-bold'} size={45} />
        </div>
        <span>Pick category and fabrics, then simulate.</span>
      </div>
      <div className={Styles.navBack}>
        <Button
          variant='tertiary'
          size='lg'
          buttonText='Back to Products'
          fixedPadding={'0px'}
          withIcon
          iconName={'chevron-left'}
          iconPlacement='start'
          borderRadius='0'
          disableActive
          customIconSize={16}
          onClick={() => navigate('/product')}
        />
      </div>

      <div className={Styles.header}>

        <h4>Category</h4>
        {/* <Button
          variant='secondary'
          size='md'
          buttonText='Add Other Category'
          withIcon
          iconName={'plus'}
          iconPlacement='start'
          fixedPadding={'0.6rem 1.2rem'}
          disableActive
          borderRadius='6px'
        /> */}
      </div>
      <div className={Styles.content}>
        {
          !isLoading
            ?
            categories?.map((each, i) => (
              <SimulationThumbnail
                key={i}
                label={each.label}
                img={each.img}
                onClick={() => navigate(`${pathname}/simulate?c=${each?.query}&t=`)}
              />
            ))
            :
            [...Array(3)]?.map((_, i) => (
              <SimulationThumbnail
                key={i}
                isLoading
              />
            ))
        }
      </div>
    </div>
  )
}