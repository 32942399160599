import Styles from './style.module.scss'

export default function Radio({
  isChecked = false,
  setValue = () => { }
}) {

  return (
    <div
      className={`${Styles.outer} ${isChecked ? Styles.checked : ''}`}
      onClick={setValue}
    >
      <div className={Styles.inner} />
    </div>
  )
}