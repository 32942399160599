import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import { priceFormat } from '@Helpers/priceFormat'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function AddToCartModal({
  src,
  name,
  size,
  price = 0,
  customerID = '',
  type = '',
  onCancel = () => { },
  onSubmit = () => { }
}) {

  const navigate = useNavigate()

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Icon icon={type === 'confirmation' ? 'information-solid' : 'check-circle-solid'} size={20} color={type === 'confirmation' ? 'orange' : '#37BB09'} />
        <span>{type === 'confirmation' ? 'Same item has been added before' : 'Added To Cart'}</span>
      </div>
      <div className={Styles.content}>
        <div className={Styles.left}>
          <img
            src={src}
            alt=''
          />
        </div>
        <div className={Styles.right}>
          <span>{name}</span>
          <span>Size {size}</span>
          <span>Rp {priceFormat(price)}</span>
        </div>
      </div>
      <div className={Styles.actions}>
        <Button
          size='lg'
          variant='secondary'
          buttonText={type === 'confirmation' ? 'Cancel' : 'View Cart'}
          disableActive
          fixedPadding={'1rem 1.6rem'}
          onClick={() => type === 'confirmation' ? onCancel() : navigate(`/product/cart`)}
        />
        <Button
          size='lg'
          variant='primary'
          buttonText={type === 'confirmation' ? 'Add Again' : 'Checkout'}
          disableActive
          fixedPadding={'1rem 1.6rem'}
          onClick={() => type === 'confirmation' ? onSubmit() : navigate(`/product/cart?c=${customerID}`)}
        />
      </div>

    </div>
  )
}