import Avatar from '@Atom/Avatar'
import Styles from './style.module.scss'
import Images from '@Theme/Images'

export default function QuestioningEmptyHandler({
  text = ''
}) {

  return (
    <div className={Styles.container}>
      <Avatar size='120px' src={Images.QUESTIONING} />
      {text
        &&
        <span>{text}</span>
      }
    </div>
  )
}