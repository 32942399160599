import useQuery from '@Hooks/useQuery'
import ProductLayout from '@Organism/Product'
import Styles from './style.module.scss'

export default function ProductPage() {
  const query = useQuery()

  const mode = query.get('m') || 'listing'

  return (
    <div className={Styles.container}>
      <ProductLayout
        mode={mode}
      />
    </div>
  )
}