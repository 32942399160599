import SetupLayout from '@Organism/Setup'
import Styles from './style.module.scss'

export default function SetupPage() {

  return (
    <div className={Styles.container}>
      <SetupLayout />
    </div>
  )
}