import Radio from '@Atom/Radio'
import Styles from './style.module.scss'

export default function RadioField({
  title,
  value,
  setValue,
  options
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.optionsWrapper}>
        {options?.map((option, i) => (
          <div
            key={i}
            className={`${Styles.option} ${option?.value === value ? Styles.active : ''}`}
            onClick={() => setValue(option?.value)}
          >
            <span>{option?.name}</span>
            <Radio
              isChecked={option?.value === value ? true : false}
              setValue={() => setValue(option?.value)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

