import Button from '@Atom/Button'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'

export default function DeleteCategoryModal({
  id,
  setIdToDelete = () => { }
}) {

  const handleDelete = () => {
    console.log(id)
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <Icon icon={'trash'} size={32} />
        <span>Delete Category</span>
        <span>Are you sure you want to delete this category?</span>
      </div>
      <div className={Styles.actionsWrapper}>
        <Button
          type='button'
          variant='secondary'
          buttonText={'Cancel'}
          size='md'
          onClick={() => setIdToDelete(null)}
        />
        <Button
          type='button'
          variant='primary'
          buttonText={'Add Category'}
          size='md'
          onClick={handleDelete}
        />
      </div>
    </div>
  )
}