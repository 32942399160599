import { API } from "@Config/api";

export function downloadStocks(type, search = '', lowLimitPrice, highLimitPrice, startDate, endDate) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/stock/download?type=${type}&search=${search}&lowLimitPrice=${lowLimitPrice}&highLimitPrice=${highLimitPrice}&startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function downloadOrders(type = "TRANSACTION", search = '', startDate = '', endDate = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/order/download`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          params: {
            type,
            search,
            startDate,
            endDate
          }
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function downloadInvoices(search = '', issueAtStart = '', issueAtEnd = '', paymentMethod = '', status = '', payDateStart = '', payDateEnd = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/invoice/download?search=${search}&issueAtStart=${issueAtStart}&issueAtEnd=${issueAtEnd}&paymentMethod=${paymentMethod}&status=${status}&payDateStart=${payDateStart}&payDateEnd=${payDateEnd}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function downloadDeliveries(type, status, search = '', scheduleAt = '', deliveredAt = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/deliver/download?type=${type}&status=${status}&search=${search}&scheduleAt=${scheduleAt}&deliveredAt=${deliveredAt}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function downloadCheckpoints(search = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/checkpoint/download?search=${search}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}
export function downloadWorkshops(search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/process/download?search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}


export function downloadEmployees(type = '', search = '', joinDateStart = '', joinDateEnd = '', role = '', checkpointCode = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/user/download?search=${search}&type=${type?.toUpperCase()}&joinDateStart=${joinDateStart}&joinDateEnd=${joinDateEnd}&role=${role}&checkpointCode=${checkpointCode}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function downloadCustomers(
  search,
  startDate = '',
  endDate = '',
  numOfItemsLow = '',
  numOfItemsHigh = '',
  numOrdersLow = '',
  numOrdersHigh = '',
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/customer/download?search=${search}&type=TABLE&startDate=${startDate}&endDate=${endDate}&numOfItemsLow=${numOfItemsLow}&numOfItemsHigh=${numOfItemsHigh}&numOrdersLow=${numOrdersLow}&numOrdersHigh=${numOrdersHigh}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}