import Navbar from '@Molecule/Navbar';
import { Outlet } from 'react-router-dom';


export default function MainPage() {
  // return (
  //   <div className="App">
  //     <ReactOpening/>
  //   </div>
  // );

  return (
    <Navbar>
      <Outlet />
    </Navbar>
  )
}
