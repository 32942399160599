import Avatar from "@Atom/Avatar";
import Button from "@Atom/Button";
import BuyerNameWithTag from "@Atom/BuyerNameWithTag";
import Checkbox from "@Atom/Checkbox";
import ModalHeader from "@Atom/ModalHeader";
import ModalWrapper from "@Atom/ModalWrapper";
import Tag from "@Atom/Tag";
import { makeRandomString } from "@Helpers/makeRandomString";
import { splitCamelCase } from "@Helpers/splitCamelCase";
import useWindowSize from "@Hooks/useWindowSize";
import AssignedWorkerLabel from "@Molecule/AssignedWorkerLabel";
import DateLabel from "@Molecule/DateLabel";
import ItemDetails from "@Molecule/ItemDetails";
import AssignWorkerModal from "@Molecule/_modals/AssignWorkerModal";
import { editOrder, getOrder } from "@Services/order";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { fileBaseUrl } from "@Config/api";
import StackedItemInfo from "@Molecule/StackedItemInfo";
import CustomerName from "@Atom/CustomerName";
import ItemListCard from "@Molecule/ItemListCard";
import Icon from "@Atom/Icon";
import SizeCard from "@Molecule/SizeCard";
import Label from "@Atom/Label";
import { omitKeys } from "@Helpers/omitKey";
import { capitalizeWords } from "@Helpers/capitalizeWords";
import { createInvoice } from "@Services/invoice";
import WideErrorModal from "@Molecule/_modals/WideErrorModal";
import GenerateInvoiceModal from "@Molecule/_modals/GenerateInvoiceModal";

const stringToDate = (str) => {
  const arr = str?.split("/");
  return [arr[1], arr[0], arr[2]]?.join("/");
};

export default function OrderDetailsModal({
  order,
  handleClose,
  onOpenSetSchedule,
  onOpenHold,
  onOpenResume = () => { },
  onOpenCancel,
  setShow,
  workers,
  setTrigger,
  trigger,
  type = "default",
  isFromInvoice = false
}) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);



  const [data, setData] = useState(null);
  const [showGenerateInvoice, setShowGenerateInvoice] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: resData } = await getOrder(
        !isFromInvoice
          ?
          order?.code ? order?.code : order?.id
          :
          order?.orderCode
        ,
        !isFromInvoice
          ?
          order?.type === "customer" ? "CUSTOMER" : order?.type
          :
          order?.orderCode?.split('-')[0] === 'ORDER'
            ?
            'TRANSACTION'
            :
            (
              order?.orderCode?.split('-')[0] === 'CUSTOMER'
                ?
                'CUSTOMER'
                :
                'ITEM'
            )
      );
      setData({
        ...resData,
        id: resData?._id,
        orderDate: resData?.createdAt
          ? moment(new Date(resData?.createdAt)).format("YYYY-MM-DD")
          : "",
        deliverDate: resData?.deliver?.date
          ? moment(new Date(resData?.deliver?.date)).format("YYYY-MM-DD")
          : "",
        scheduledAt: resData?.deliver?.date
          ? moment(new Date(resData?.deliver?.date)).format("YYYY-MM-DD")
          : "",
        itemStatus: resData?.status?.replaceAll("-", ""),
        dueDate: resData?.dueDate
          ? moment(new Date(resData?.dueDate)).format("YYYY-MM-DD")
          : "",
        driver: {
          name: resData?.courierName,
          code: resData?.courierCode,
          img: null,
        },
        // itemsArr: resData?.items?.map(item => {
        //   return {
        //     ...item,
        //     id: item?._id,
        //     category: item?.productType,
        //     measurements: Object.entries(item?.measurement)?.map(m => { return { name: m[0], value: m[1] + ' cm', title: splitCamelCase(m[0]) } }),
        //     img: item?.image?.url,
        //     cutter: {
        //       id: item?.cutterCode,
        //       name: item?.cutterName,
        //       img: item?.cutterImg || null
        //     },
        //     tailor: {
        //       id: item?.tailorCode,
        //       name: item?.tailorName,
        //       img: item?.tailorImg || null
        //     },
        //   }
        // })
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }, [isFromInvoice, order?.code, order?.id, order?.orderCode, order?.type]);

  useEffect(() => {
    if (order?.code || order?._id || order?.orderCode) {
      fetchData();
    }
  }, [fetchData, order?._id, order?.code, order?.orderCode, trigger]);

  const [checkedItems, setCheckedItems] = useState([]);
  const [changingToReady, setChangingToReady] = useState(false);

  const [error, setError] = useState()

  const onChangeToReady = useCallback(async () => {
    try {
      setChangingToReady(true);
      const formData = new FormData();
      formData?.append("status", "READY");
      await editOrder(formData, order?.code);
      setChangingToReady(false);
      setTrigger(makeRandomString(5));
      handleClose();
    } catch (err) {
      setChangingToReady(false);
      console.log(err);
    }
  }, [handleClose, order?.code, setTrigger]);

  // eslint-disable-next-line no-unused-vars
  const onGenerateInvoice = useCallback(async () => {
    try {
      await createInvoice({
        orderCode: data?.code,
        customerCode: data?.customerCode,
        customerName: data?.customerName,
        customerPhone: data?.customerPhone,
        customerAddress: data?.customerAddress,
        notes: data?.notes || '',
        status: data?.status,
        paymentMethod: data?.paymentMethod || '',
        paymentType: data?.paymentType || '',
        dueDate: data?.dueDate ? moment(new Date(data?.dueDate))?.format('YYYY-MM-DD') : '',
        items: data?.summaries?.map((p, pI) => {
          return {
            name: p?.name,
            price: parseInt(p?.price || ''),
            qty: p?.qty || 1,
            type: p?.type || p?.textiles[0]?.type,
            category: p?.category || '',
            textiles: p?.textiles?.map((t, tI) => {
              return {
                _id: data?.products[pI]?.textiles[tI]?._id || '',
                code: data?.products[pI]?.textiles[tI]?.code || '',
                // name: t?.name,
                price: parseInt(t?.price || ''),
                qty: t?.qty,
                imageUrl: t?.imageUrl || ''
              }
            })
          }
        })
      })
      fetchData()
    } catch (err) {
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
      console.log(err, 'error on generate invoice')
    }
  }, [data?.code, data?.customerAddress, data?.customerCode, data?.customerName, data?.customerPhone, data?.dueDate, data?.notes, data?.paymentMethod, data?.paymentType, data?.products, data?.status, data?.summaries, fetchData])
  // useEffect(() => {
  //   setCheckedItems([])
  // }, [order])

  const [showAssign, setShowAssign] = useState(false);

  const isCheckedAll = useMemo(() => {
    if (data && data?.itemsArr?.length) {
      for (let i = 0; i < data?.itemsArr.length; i++) {
        if (!checkedItems?.find((obj) => obj?.id === data?.itemsArr[i]?.id)) {
          return false;
        }
      }

      return true;
    }
    return false;
  }, [checkedItems, data]);

  const handleCheckAll = useCallback(() => {
    if (!isCheckedAll) {
      setCheckedItems(data?.itemsArr);
    } else {
      setCheckedItems([]);
    }
  }, [data?.itemsArr, isCheckedAll]);

  const disableChangeToReady = useMemo(() => {
    if (changingToReady) {
      return true;
    }
    if (data && data?.itemsArr?.length) {
      const items = data?.itemsArr;
      const tailorUnasigned = items?.find((obj) => !obj?.tailorName);
      const cutterUnassigned = items?.find((obj) => !obj?.cutterName);
      if (tailorUnasigned || cutterUnassigned) {
        return true;
      }
    }
    return false;
  }, [changingToReady, data]);

  // clg

  const tagTemplate = useMemo(() => {
    const status = data?.status;
    switch (status) {
      case "ON-PROCESS":
        return {
          type: "yellow",
          title: "On Process",
        };
      case "ON-GOING":
        return {
          type: "yellow",
          title: "On Going",
        };
      case "DELIVERED":
        return {
          type: "green",
          title: "Delivered",
        };
      case "READY":
        return {
          type: "black",
          title: "Ready",
        };
      case "SCHEDULED":
        return {
          type: "blue",
          title: "Scheduled",
        };
      case "ON-DELIVERY":
        return {
          type: "blue",
          title: "Scheduled",
        };
      case "CANCELLED":
        return {
          type: "red",
          title: "Cancelled",
        };
      default:
        return {
          type: "black",
          title: capitalizeWords(order?.status?.replaceAll('-', ' ')),
        };
    }
  }, [data?.status, order?.status]);

  // eslint-disable-next-line no-unused-vars
  const dataMeasure = useMemo(() => {
    if (data?.products) {
      return data?.products?.map((el) => ({
        id: el.pattern.code || "",
        name: el.pattern.name || "default",
        customerPhotos: el?.pattern?.photos?.map((el1, idx) => ({
          ...el1,
          url: fileBaseUrl + el1.uri?.replaceAll(fileBaseUrl, ''),
          id: idx,
        })),
        items: [
          {
            id: el?._id,
            isReadyToWear: el?.textiles[0]?.type === "READY-TO-WEAR",
            label: el?.category,
            notes: el?.description || el?.notes,
            isAlter: el?.textiles?.[0]?.type === "ALTER",
            fabrics: el?.textiles?.map((textiles) => ({
              id: textiles?._id,
              type: "Primary",
              fabricType: el?.type,
              fabricName: textiles?.name,
              fabricCode: textiles?.code,
              img: fileBaseUrl + textiles?.imageUrl?.replaceAll(fileBaseUrl, ''),
            })),
          },
        ],
        measurements:
          el.pattern &&
          Object.entries(el?.pattern?.measurement)
            ?.map((m) => {
              // if (m[0] !== "photos")
              return {
                name: m[0],
                value: m[1],
                title: splitCamelCase(m[0]),
              };
            })
            .filter(
              (fill, idx) => fill.name !== "photos" && fill.name !== "code"
            ),
      }));
    }
  }, [data]);

  const dataMeasureCustomer = useMemo(() => {
    if (data?.orders) {
      return data?.orders?.map((ord, idx) => ({
        id: idx,
        orderID: ord?.code,
        dueDate: new Date(),
        sizeTypes: ord?.items?.map((el) => ({
          id: el?.pattern.code || "",
          name: el?.pattern.name || "default",
          customerPhotos: el?.pattern?.photos?.map((el1, idx) => ({
            ...el1,
            url: fileBaseUrl + el1.uri?.replaceAll(fileBaseUrl, ''),
            id: idx,
          })),
          items: [
            {
              id: el?._id,
              isReadyToWear: el?.textiles?.[0]?.type === "READY-TO-WEAR",
              label: el?.category,
              notes: el?.description || el?.notes,
              isAlter: el?.textiles?.[0]?.type === "ALTER",
              fabrics: el?.textiles?.map((textiles) => ({
                id: textiles?._id,
                type: "Primary",
                fabricType: el?.type,
                fabricName: textiles?.name,
                fabricCode: textiles?.code,
                img: fileBaseUrl + textiles?.imageUrl?.replaceAll(fileBaseUrl, ''),
              })),
            },
          ],
          measurements:
            el?.pattern.measurement &&
            Object.entries(el?.pattern.measurement)
              ?.map((m) => {
                // if (m[0] !== "photos")
                return {
                  name: m[0],
                  value: m[1],
                  title: splitCamelCase(m[0]),
                };
              })
              .filter(
                (fill, idx) => fill?.name !== "photos" && fill?.name !== "code"
              ),
        })),
      }));
    }
  }, [data]);


  const contentTemplate = useMemo(() => {
    switch (type) {
      case "m1":
        return [
          {
            title: "Customer Name",
            component: (
              <CustomerName
                name={order?.customerName}
                phoneNumber={order?.customerPhoneNumber}
              />
            ),
          },
          {
            title: "Address",
            component: (
              <div className={Styles.address}>{data?.customerAddress}</div>
            ),
          },
          {
            title: "Order Date",
            component: (
              <div className={Styles.date}>
                <DateLabel
                  intro="Order date"
                  date={order?.orderDate ? stringToDate(order?.orderDate) : ""}
                />
              </div>
            ),
          },
          {
            tilte: "",
            component: (
              <div className={Styles.salesman}>
                <div className={Styles.card}>
                  <span>Sales</span>
                  <StackedItemInfo
                    picture={order?.salesProfilePicture || Images?.AVA_DEFAULT}
                    title={order?.salesName}
                    label={order?.salesPhoneNumber}
                    imgRadius="50%"
                  />
                </div>
                <div className={Styles.comission}>
                  <span>Comission</span>
                  <Label desc={"Rp-"} />
                </div>
                <Button
                  variant="primary"
                  buttonText="Comission"
                  borderRadius="6px"
                  withIcon
                  iconName={"currency-dollar"}
                  disabled
                />
              </div>
            ),
          },
          {
            title: "Items",
            component: (
              <ItemListCard
                isDetailed
                // navigateToStockDetails
                mappedData={[
                  {
                    id: data?._id,
                    orderID: data?.code,
                    dueDate: data?.dueDate ? new Date(data?.dueDate) : '',
                    sizeTypes: data?.products?.map(i => {
                      return {
                        id: i?.pattern?.code,
                        name: i?.pattern?.name,
                        items: [
                          {
                            id: i?._id,
                            isAlter: i?.textiles?.[0]?.type === 'ALTER',
                            label: i?.category,
                            notes: i?.notes,
                            fabrics: i?.textiles?.map(t => {
                              return {
                                id: t?._id,
                                type: t?.type,
                                fabricType: t?.type,
                                fabricName: t?.name || i?.name,
                                fabricCode: t?.code,
                                img: t?.imageUrl || t?.uri ? fileBaseUrl + t?.imageUrl?.replaceAll(fileBaseUrl, '') || t?.uri?.replaceAll(fileBaseUrl, '') : '',
                                price: t?.price
                              }
                            })
                          }
                        ],
                        measurements: Object.entries(omitKeys([
                          'code',
                          'name',
                          'photos',
                          'remark',
                          'createdAt',
                          'customerCode',
                          'updatedAt',
                          '__v',
                          '_id'
                        ], i?.pattern?.measurement))
                          ?.map((m) => {
                            // if (m[0] !== "photos")
                            return {
                              name: m[0],
                              value: m[1],
                              title: splitCamelCase(m[0]),
                            };
                          }),
                        customerPhotos: i?.pattern?.photos?.map(p => {
                          return {
                            id: p?._id,
                            url: p?.uri ? fileBaseUrl + p?.uri : ''
                          }
                        })
                      }
                    })
                  }
                ]}
              />
            ),
          },
          {
            title: "Invoice",
            component: (

              !data?.invoiceID
                ?
                <Button
                  buttonText="Generate Invoice"
                  variant="secondary"
                  customIconSize={20}
                  withIcon
                  iconName={"arrow-long-right"}
                  iconPlacement="end"
                  onClick={() => setShowGenerateInvoice(true)}
                />
                :
                // <div>
                <a
                  href={`/invoice-view/${data?.invoiceID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    buttonText="Go to Invoice Details"
                    variant="secondary"
                    customIconSize={20}
                    withIcon
                    iconName={"arrow-long-right"}
                    iconPlacement="end"
                    disabled={!data?.invoiceID}
                  />
                </a>

            ),
          },
          {
            title: "Cutting File",
            component: (
              <a
                href={`/cutting-file/${data?.code}?cft=true`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  buttonText="See Cutting File"
                  variant="secondary"
                  customIconSize={20}
                  withIcon
                  iconName={"document-text"}
                  iconPlacement="start"
                  disabled={!data?.invoiceID}
                />
              </a>
            ),
          },
        ];
      case "m2":
        return [
          {
            title: "Address",
            component: <div className={Styles.address}>{data?.address}</div>,
          },
          {
            title: "Last Order Date",
            component: (
              <div className={Styles.date}>
                <DateLabel
                  intro="Order date"
                  date={data?.orders?.length ? (data?.orders[data?.orders?.length - 1]?.createdAt) : ""}
                />
              </div>
            ),
          },
          {
            title: "Items",
            component: dataMeasureCustomer?.length
              ?
              <ItemListCard mappedData={dataMeasureCustomer} />
              :
              <span>no item</span>
            ,
          },
          {
            title: "Invoices",
            component: (
              <div className={Styles.invoices}>
                {data?.orders?.map((each, i) => (
                  <div key={i} className={Styles.invoice}>
                    <span className={Styles.no}>{i + 1}</span>
                    <Icon icon={'document'} size={24} />
                    <span className={Styles.id}>
                      {`Order ID: ${each?.code}`}
                    </span>
                    <a
                      href={`/invoice-view/${each?.invoice?.code}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >See Detail</a>
                  </div>
                ))}
              </div>
            ),
          },
          {
            title: "Cutting Files",
            component: (
              <div className={Styles.invoices}>
                {data?.orders?.map((each, i) => (
                  <div key={i} className={Styles.invoice}>
                    <span className={Styles.no}>{i + 1}</span>
                    <Icon icon={'document-text'} size={24} />
                    <span className={Styles.id}>
                      {`Order ID: ${each?.code}`}
                    </span>
                    <a
                      href={`/invoice-view/${each?.invoice?.code}?cft=true`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >See Detail</a>
                  </div>
                ))}
              </div>

            ),
          },
        ];
      case "m3":
        return [
          {
            title: "Order ID",
            component: <div className={Styles.address}>{data?.orderID}</div>,
          },
          {
            title: "Customer Name",
            component: (
              <div className={Styles.customerName}>
                <span>{order?.customerName}</span>
                <span>{order?.customerPhoneNumber}</span>
              </div>
            ),
          },
          {
            title: "Status Details",
            component: (
              <div className={Styles.statusDetails}>
                <Tag type={tagTemplate?.type} title={tagTemplate?.title} />
                <span>-</span>
                <span>Workshop</span>
              </div>
            ),
          },
          {
            title: "Item Details",
            component: (
              <div className={Styles.itemDetails}>
                <div className={Styles.intro}>
                  <div className={Styles.label}>
                    <div>
                      <Icon
                        icon={
                          data?.type?.toLowerCase() === "trouser"
                            ? "pants"
                            : data?.textiles[0]?.type.toLowerCase() === "alter"
                              ? "alter"
                              : data?.type?.toLowerCase()
                        }
                        size={40}
                      />
                    </div>

                    <div>{data?.textiles?.[0]?.type}</div>
                  </div>
                  {data?.textiles?.[0]?.type !== "ALTER" ? (
                    <div className={Styles.fabrics}>
                      {data?.textiles?.map((f, i) => (
                        <StackedItemInfo
                          key={i}
                          title={`Fabric: ${capitalizeWords(f?.type?.replaceAll('-', ' '))} - ${f?.name}`}
                          label={f?.fabric}
                          maxWidth="100%"
                          titleWidth="100%"
                          titleFontSize="16px"
                          picture={f?.imageUrl ? fileBaseUrl + f?.imageUrl?.replaceAll(fileBaseUrl, '') : ''}
                          imgRadius="6px"
                          imgSize="40px"
                        />
                      ))}
                    </div>
                  ) : (
                    <div className={Styles.fabrics}>
                      {order?.fabrics?.map((f, i) => (
                        <div className={Styles.alter}>
                          {/* <Label desc={"Alter"} padding="0px 8px" /> */}
                          <div className={Styles.alterQty}>
                            <StackedItemInfo
                              withoutPicture
                              title="Tailoring Fabric"
                              label={order?.label}
                              titleFontSize="14px"
                            />
                            <span>{order?.qty}</span>
                          </div>
                          {/* <div className={Styles.alterDesc}>
                            <span>Descriptions</span>
                            <span>{f?.desc}</span>
                          </div> */}
                          <div className={Styles.alterNotes}>
                            <span>Notes</span>
                            <span>{data?.notes}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <SizeCard
                  sizeName={data?.pattern?.name || 'Default'}
                  sizeID={data?.pattern?.cod || ''}
                  measurements={
                    data?.pattern?.measurement
                      ? Object.entries(data?.pattern?.measurement)?.map((m) => {
                        return {
                          name: m[0],
                          value: m[1],
                          title: splitCamelCase(m[0]),
                        };
                      })
                      : []
                  }
                  customBorderColor="#CED4DA"
                  // photos={[]}
                  photos={data?.pattern?.photos.map((el) => ({
                    ...el,
                    url: fileBaseUrl + el?.uri?.replaceAll(fileBaseUrl, ''),
                  }))}
                />
                {/* <div className={Styles.customerPhotos}>
                  {order?.customerPhotos?.map((p, pIdx) => (
                    <button
                      key={pIdx}
                    >
                      <Avatar
                        size='24px'
                        src={p?.url}
                        borderRadius='6px'
                      />
                    </button>
                  ))}
                </div> */}
                <div className={Styles.notes}>
                  <span>Notes</span>
                  <div className={Styles.text}>{data?.notes || "-"}</div>
                </div>
                <Tag type={tagTemplate?.type} title={tagTemplate?.title} />
              </div>
            ),
          },
        ];
      default:
        return [
          {
            title: "Address",
            component: (
              <div className={Styles.address}>{data?.customerAddress}</div>
            ),
          },
          {
            title: "Order and Due Date",
            component: (
              <div className={Styles.dates}>
                <DateLabel
                  intro="Order date"
                  date={data?.orderDate ? stringToDate(data?.orderDate) : ""}
                />
                <DateLabel
                  intro="Due date"
                  date={data?.dueDate ? stringToDate(data?.dueDate) : ""}
                  extraDate={
                    data?.currentDueDate
                      ? stringToDate(data?.currentDueDate)
                      : ""
                  }
                />
              </div>
            ),
          },
          {
            title: "Items",
            component: (
              <div className={Styles.items}>
                {(data?.itemStatus === "ONPROCESS" ||
                  data?.itemsArr?.find((obj) => !obj?.cutterName) ||
                  data?.itemsArr?.find((obj) => !obj?.tailorName)) && (
                    <div
                      className={`${Styles.noBorderBottom} ${Styles.checkAll}`}
                    >
                      <Checkbox
                        isChecked={isCheckedAll}
                        setIsChecked={handleCheckAll}
                      />
                      <span>Select All Items</span>
                    </div>
                  )}
                {data?.itemsArr?.map((each, i) => (
                  <div
                    key={i}
                    className={`${Styles.item} ${data?.itemStatus !== "ONPROCESS" &&
                      i === data?.itemsArr?.length - 1
                      ? Styles.noBorderBottom
                      : ""
                      }`}
                  >
                    <div>
                      {(data?.itemStatus === "ONPROCESS" ||
                        data?.itemsArr?.find((obj) => !obj?.cutterName) ||
                        data?.itemsArr?.find((obj) => !obj?.tailorName)) && (
                          <Checkbox
                            isChecked={checkedItems?.find(
                              (obj) => obj?.id === each?.id
                            )}
                            setIsChecked={() => {
                              const index = checkedItems.indexOf(each);
                              if (index !== -1) {
                                const newCheckedItems = [...checkedItems];
                                newCheckedItems.splice(index, 1);
                                setCheckedItems(newCheckedItems);
                              } else {
                                setCheckedItems([...checkedItems, each]);
                              }
                            }}
                          />
                        )}
                      <span>{i + 1}</span>
                      <Avatar
                        src={each?.img ? fileBaseUrl + each?.img?.replaceAll(fileBaseUrl, '') : ""}
                        size="32px"
                        borderRadius="6px"
                      />
                      <ItemDetails item={each} />
                    </div>
                    {(each?.cutter || each?.tailor) && (
                      <div className={Styles.workers}>
                        {each?.cutterName && (
                          <AssignedWorkerLabel
                            title={"Cutter"}
                            name={each?.cutter?.name || "unnasigned"}
                            img={each?.cutter?.img}
                            setValue={() => { }}
                          />
                        )}
                        {each?.tailorName && (
                          <AssignedWorkerLabel
                            title={"Tailor"}
                            name={each?.tailor?.name || "unnasigned"}
                            img={each?.cutter?.img}
                            setValue={() => { }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
                {(data?.itemStatus === "ONPROCESS" ||
                  data?.itemsArr?.find((obj) => !obj?.tailorName) ||
                  data?.itemsArr?.find((obj) => !obj?.cutterName)) && (
                    <div className={Styles.assignButton}>
                      <Button
                        buttonText="Assign Cutter & Tailor"
                        disabled={!checkedItems?.length}
                        onClick={() => setShowAssign(true)}
                      />
                    </div>
                  )}
              </div>
            ),
          },
          {
            title: "Item Status",
            titleComponent: (
              <div className={Styles.title}>
                <span>Item Status</span>
                <Tag title={tagTemplate?.title} type={tagTemplate?.type} />
              </div>
            ),
            component: (
              <div className={Styles.statusAction}>
                {data?.itemStatus?.includes("PROCESS") && (
                  <div className={Styles.onProcess}>
                    <Button
                      variant="primary"
                      buttonText="Change to Ready"
                      withIcon
                      iconName={"arrow-uturn-right"}
                      size="lg"
                      disabled={disableChangeToReady}
                      onClick={() => {
                        onChangeToReady();
                      }}
                    />
                  </div>
                )}
                {data?.itemStatus === "READY" && (
                  <div className={Styles.onProcess}>
                    <Button
                      variant="primary"
                      buttonText="Schedule Delivery"
                      withIcon
                      iconName={"calendar"}
                      size="lg"
                      onClick={() => {
                        onOpenSetSchedule();
                      }}
                    />
                  </div>
                )}
                {(data?.itemStatus === "SCHEDULED" ||
                  data?.itemStatus === "ONDELIVERY") && (
                    <div className={Styles.ready}>
                      <AssignedWorkerLabel
                        title={"Driver"}
                        img={data?.driver?.img || Images.AVA_DEFAULT}
                        name={data?.driver?.name || "-"}
                        noButton
                      />
                      <DateLabel
                        intro="Scheduled on"
                        date={
                          order?.deliverDate
                            ? stringToDate(data?.deliverDate)
                            : ""
                        }
                      />
                    </div>
                  )}
                {data?.itemStatus === "DELIVERED" && (
                  <div className={Styles.delivered}>
                    <AssignedWorkerLabel
                      title={"Driver"}
                      img={data?.driver?.img || Images.AVA_DEFAULT}
                      name={data?.driver?.name || "-"}
                      noButton
                    />
                    <DateLabel
                      intro="Scheduled on"
                      date={
                        order?.deliverDate
                          ? stringToDate(data?.deliverDate)
                          : ""
                      }
                    />
                  </div>
                )}
              </div>
            ),
          },
          {
            title: "Invoice",
            component: (
              !order?.invoiceID
                ?
                <Button
                  buttonText="Go to Invoice Details"
                  variant="secondary"
                  customIconSize={20}
                  withIcon
                  iconName={"arrow-long-right"}
                  iconPlacement="end"
                  disabled={!order?.invoiceID}
                />
                :
                // <div>
                <a
                  href={`/invoice-view/${order?.invoiceID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    buttonText="Go to Invoice Details"
                    variant="secondary"
                    customIconSize={20}
                    withIcon
                    iconName={"arrow-long-right"}
                    iconPlacement="end"
                    disabled={!order?.invoiceID}
                  />
                </a>
            ),

            // </div>
          },
        ];
    }
  }, [type, order?.customerName, order?.customerPhoneNumber, order?.orderDate, order?.salesProfilePicture, order?.salesName, order?.salesPhoneNumber, order?.invoiceID, order?.fabrics, order?.deliverDate, order?.label, order?.qty, data?.customerAddress, data?._id, data?.code, data?.dueDate, data?.products, data?.invoiceID, data?.address, data?.orders, data?.orderID, data?.type, data?.textiles, data?.pattern?.name, data?.pattern?.cod, data?.pattern?.measurement, data?.pattern?.photos, data?.notes, data?.orderDate, data?.currentDueDate, data?.itemStatus, data?.itemsArr, data?.driver?.img, data?.driver?.name, data?.deliverDate, dataMeasureCustomer, tagTemplate?.type, tagTemplate?.title, isCheckedAll, handleCheckAll, checkedItems, disableChangeToReady, onChangeToReady, onOpenSetSchedule]);

  const { width } = useWindowSize();


  switch (type) {
    case "m1": //director-transaction
      return (
        <div className={Styles.container}>
          <ModalHeader
            title={"Order Details"}
            iconName={"list-bullet"}
            withIcon
            withCloseButton
            isSticky
            extraComponent={
              <BuyerNameWithTag
                intro="Order ID"
                withPhoneNumber
                phoneNumber={data?.code || data?.id}
                tagType={tagTemplate?.type}
                tagTitle={tagTemplate?.title}
              // noTag
              // withPhoneNumber
              // phoneNumber={order?.}
              />
            }
            handleClose={handleClose}
            paddingX={width <= 768 ? "16px" : "24px"}
            paddingY={width <= 768 ? "16px" : "24px"}
          />
          <div className={Styles.content}>
            {contentTemplate?.map((content, i) => (
              <div key={i}>
                {content?.titleComponent
                  ? content?.titleComponent
                  : content?.title && <span>{content?.title}</span>}
                {content?.component}
              </div>
            ))}
            {order?.itemStatus === "DELIVERED" && (
              <div>
                <span>Delivery Photo</span>
                <Avatar
                  src={
                    "https://cdn.antaranews.com/cache/1200x800/2021/11/15/karyawan-J-T-Express-menyusun-paket-untuk-dikirim.jpg"
                  }
                  borderRadius="8px"
                  size="100%"
                />
              </div>
            )}
          </div>
          {order?.itemStatus !== "DELIVERED" && (
            <div className={Styles.actions}>
              <Button
                type="button"
                variant="secondary"
                // buttonText={!confirmationMode ? 'Clear Form' : 'No, back to form'}
                buttonText={data?.status === 'ON-HOLD' ? 'Resume Order' : "Hold Order"}
                size="lg"
                customColor={"#E79800"}
                onClick={data?.status === 'ON-HOLD' ? onOpenResume : onOpenHold}
              // onClick={!confirmationMode ? handleReset : () => setConfirmationMode(false)}
              />
              <Button
                type="button"
                variant="secondary"
                // buttonText={!confirmationMode ? 'Submit' : 'Yes, submit stock'}
                buttonText="Cancel Order"
                size="lg"
                customColor={"#DD0B0B"}
                onClick={onOpenCancel}
              // onClick={!confirmationMode ? () => setConfirmationMode(true) : handleSubmit}
              />
            </div>
          )}

          {error
            &&
            <ModalWrapper
              show={error}
              setShow={() => {
                // setConfirmationMode(false)
                setError(null)
              }
              }
            >
              <WideErrorModal
                code={error?.code || 500}
                error={error?.message || 'Something went wrong!'}
                withButton
                onClose={() => {
                  setError(null)
                  // setConfirmationMode(false)
                }
                }
              />
            </ModalWrapper>

          }
        {
            showGenerateInvoice
            &&
            <GenerateInvoiceModal
              data={data}
              show={showGenerateInvoice}
              handleClose={() => setShowGenerateInvoice(false)}
              fetchData={fetchData}
            />
          }

        </div>
      );
    case "m2": //director-transaction
      return (
        <div className={Styles.container}>
          <ModalHeader
            title={"Order Details"}
            iconName={"list-bullet"}
            withIcon
            withCloseButton
            isSticky
            extraComponent={
              <BuyerNameWithTag
                intro="Customer Name"
                name={data?.name}
                phoneNumber={"+" + data?.phoneNumber}
                withPhoneNumber
                tagTitle={tagTemplate?.title}
                tagType={tagTemplate?.type}
                noTag
              />
            }
            handleClose={handleClose}
            paddingX={width <= 768 ? "16px" : "24px"}
            paddingY={width <= 768 ? "16px" : "24px"}
          />
          <div className={Styles.content}>
            {contentTemplate?.map((content, i) => (
              <div key={i}>
                {content?.titleComponent
                  ? content?.titleComponent
                  : content?.title && <span>{content?.title}</span>}
                {content?.component}
              </div>
            ))}
            {order?.itemStatus === "DELIVERED" && (
              <div>
                <span>Delivery Photo</span>
                <Avatar
                  src={
                    "https://cdn.antaranews.com/cache/1200x800/2021/11/15/karyawan-J-T-Express-menyusun-paket-untuk-dikirim.jpg"
                  }
                  borderRadius="8px"
                  size="100%"
                />
              </div>
            )}
          </div>
          {order?.itemStatus !== "DELIVERED" && (
            <div className={Styles.actions}>
              <Button
                type="button"
                variant="secondary"
                // buttonText={!confirmationMode ? 'Clear Form' : 'No, back to form'}
                buttonText={data?.status === 'ON-HOLD' ? 'Resume Order' : "Hold Order"}
                size="lg"
                customColor={"#E79800"}
                onClick={data?.status === 'ON-HOLD' ? onOpenResume : onOpenHold}
              // onClick={!confirmationMode ? handleReset : () => setConfirmationMode(false)}
              />
              <Button
                type="button"
                variant="secondary"
                // buttonText={!confirmationMode ? 'Submit' : 'Yes, submit stock'}
                buttonText="Cancel Order"
                size="lg"
                customColor={"#DD0B0B"}
                onClick={onOpenCancel}
              // onClick={!confirmationMode ? () => setConfirmationMode(true) : handleSubmit}
              />
            </div>
          )}
          {error
            &&
            <ModalWrapper
              show={error}
              setShow={() => {
                // setConfirmationMode(false)
                setError(null)
              }
              }
            >
              <WideErrorModal
                code={error?.code || 500}
                error={error?.message || 'Something went wrong!'}
                withButton
                onClose={() => {
                  setError(null)
                  // setConfirmationMode(false)
                }
                }
              />
            </ModalWrapper>

          }
        {
            showGenerateInvoice
            &&
            <GenerateInvoiceModal
              data={data}
              show={showGenerateInvoice}
              handleClose={() => setShowGenerateInvoice(false)}
              fetchData={fetchData}
            />
          }

        </div>
      );
    case "m3": //director-item
      return (
        <div className={Styles.container}>
          <ModalHeader
            title={"Order Details"}
            iconName={"list-bullet"}
            withIcon
            withCloseButton
            isSticky
            extraComponent={
              <div className={Styles.m2ExtraComponent}>
                {
                  (order?.itemType === 'SUIT'
                    || order?.itemType === 'TROUSER'
                    || order?.itemType === 'PANTS'
                    || order?.itemType === 'PANT'
                    || order?.itemType === 'SHIRT')
                  &&
                  <div className={Styles.logo}>
                    <Icon
                      icon={
                        order?.itemType?.toLowerCase() === "trouser"
                          ? "pants"
                          : order?.itemType?.toLowerCase() || 'photo'
                      }
                      size={48}
                    />
                  </div>
                }
                <div className={Styles.title}>
                  <div className={Styles.top}>
                    <div>
                      <span>{order?.itemType}</span>
                      {order?.priorityScore > 0 ? (
                        <Tag
                          type={`p${order?.priorityScore}`}
                          noBullet
                          title={
                            order?.priorityScore === 1
                              ? "Priority"
                              : order?.priorityScore === 2
                                ? "Super Priority"
                                : "SSP"
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>

                    <Tag type={tagTemplate?.type} title={tagTemplate?.title} />
                  </div>
                  <span>{`Collection: ${order?.collection || "-"}`}</span>
                </div>
              </div>
            }
            handleClose={handleClose}
            paddingX={width <= 768 ? "16px" : "24px"}
            paddingY={width <= 768 ? "16px" : "24px"}
          />
          <div className={Styles.content}>
            {contentTemplate?.map((content, i) => (
              <div key={i}>
                {content?.titleComponent
                  ? content?.titleComponent
                  : content?.title && <span>{content?.title}</span>}
                {content?.component}
              </div>
            ))}
            {order?.itemStatus === "DELIVERED" && (
              <div>
                <span>Delivery Photo</span>
                <Avatar
                  src={
                    "https://cdn.antaranews.com/cache/1200x800/2021/11/15/karyawan-J-T-Express-menyusun-paket-untuk-dikirim.jpg"
                  }
                  borderRadius="8px"
                  size="100%"
                />
              </div>
            )}
          </div>
          {order?.itemStatus !== "DELIVERED" && (
            <div className={Styles.actions}>
              <Button
                type="button"
                variant="secondary"
                // buttonText={!confirmationMode ? 'Clear Form' : 'No, back to form'}
                buttonText={data?.status === 'ON-HOLD' ? 'Resume Order' : "Hold Order"}
                size="lg"
                customColor={"#E79800"}
                onClick={data?.status === 'ON-HOLD' ? onOpenResume : onOpenHold}
              // onClick={!confirmationMode ? handleReset : () => setConfirmationMode(false)}
              />
              <Button
                type="button"
                variant="secondary"
                // buttonText={!confirmationMode ? 'Submit' : 'Yes, submit stock'}
                buttonText="Cancel Order"
                size="lg"
                customColor={"#DD0B0B"}
                onClick={onOpenCancel}
              // onClick={!confirmationMode ? () => setConfirmationMode(true) : handleSubmit}
              />
            </div>
          )}
          {error
            &&
            <ModalWrapper
              show={error}
              setShow={() => {
                // setConfirmationMode(false)
                setError(null)
              }
              }
            >
              <WideErrorModal
                code={error?.code || 500}
                error={error?.message || 'Something went wrong!'}
                withButton
                onClose={() => {
                  setError(null)
                  // setConfirmationMode(false)
                }
                }
              />
            </ModalWrapper>

          }
        {
            showGenerateInvoice
            &&
            <GenerateInvoiceModal
              data={data}
              show={showGenerateInvoice}
              handleClose={() => setShowGenerateInvoice(false)}
              fetchData={fetchData}
            />
          }

        </div>
      );

    default:
      return (
        <div className={Styles.container}>
          <ModalHeader
            title={"Order Details"}
            iconName={"list-bullet"}
            withIcon
            withCloseButton
            isSticky
            extraComponent={
              <BuyerNameWithTag
                intro="Ordered by"
                name={order?.customerName}
                tagType={tagTemplate?.type}
                tagTitle={tagTemplate?.title}
                noTag
                withPhoneNumber
                phoneNumber={order?.customerPhoneNumber}
              />
            }
            handleClose={handleClose}
            paddingX={width <= 768 ? "16px" : "24px"}
            paddingY={width <= 768 ? "16px" : "24px"}
          />
          <div className={Styles.content}>
            {contentTemplate?.map((content, i) => (
              <div key={i}>
                {content?.titleComponent || <span>{content?.title}</span>}
                {content?.component}
              </div>
            ))}
            {order?.itemStatus === "DELIVERED" && (
              <div>
                <span>Delivery Photo</span>
                <Avatar
                  src={
                    "https://cdn.antaranews.com/cache/1200x800/2021/11/15/karyawan-J-T-Express-menyusun-paket-untuk-dikirim.jpg"
                  }
                  borderRadius="8px"
                  size="100%"
                />
              </div>
            )}
          </div>
          {order?.itemStatus !== "DELIVERED" && (
            <div className={Styles.actions}>
              <Button
                type="button"
                variant="secondary"
                // buttonText={!confirmationMode ? 'Clear Form' : 'No, back to form'}
                buttonText={data?.status === 'ON-HOLD' ? 'Resume Order' : "Hold Order"}
                size="lg"
                customColor={"#E79800"}
                onClick={data?.status === 'ON-HOLD' ? onOpenResume : onOpenHold}
              // onClick={!confirmationMode ? handleReset : () => setConfirmationMode(false)}
              />
              <Button
                type="button"
                variant="secondary"
                // buttonText={!confirmationMode ? 'Submit' : 'Yes, submit stock'}
                buttonText="Cancel Order"
                size="lg"
                customColor={"#DD0B0B"}
                onClick={onOpenCancel}
              // onClick={!confirmationMode ? () => setConfirmationMode(true) : handleSubmit}
              />
            </div>
          )}

          <div style={{ visibility: showAssign ? "visible" : "hidden" }}>
            <ModalWrapper show={showAssign}>
              <div style={{ visibility: showAssign ? "visible" : "hidden" }}>
                <AssignWorkerModal
                  selectedItems={checkedItems}
                  setShow={setShowAssign}
                  workers={workers}
                  setTrigger={setTrigger}
                  onSuccess={() => {
                    setShowAssign(false);
                    handleClose();
                  }}
                />
              </div>
            </ModalWrapper>
          </div>
          {error
            &&
            <ModalWrapper
              show={error}
              setShow={() => {
                // setConfirmationMode(false)
                setError(null)
              }
              }
            >
              <WideErrorModal
                code={error?.code || 500}
                error={error?.message || 'Something went wrong!'}
                withButton
                onClose={() => {
                  setError(null)
                  // setConfirmationMode(false)
                }
                }
              />
            </ModalWrapper>

          }
          {
            showGenerateInvoice
            &&
            <GenerateInvoiceModal
              data={data}
              show={showGenerateInvoice}
              handleClose={() => setShowGenerateInvoice(false)}
              fetchData={fetchData}
            />
          }

        </div>
      );
  }
}
