import { useMemo, useState } from 'react'
import Styles from './style.module.scss'
import ModalHeader from '@Atom/ModalHeader'
import InputField from '@Molecule/InputField'
import Button from '@Atom/Button'

export default function ChangeToReadyModal({
  show,
  handleClose = () => { },
  orderId = '',
  workers=[],
  setTrigger
}) {
  const [cutter, setCutter] = useState('')
  const [tailor, setTailor] = useState('')

  const cutters = useMemo(() => {
    return workers?.cutters?.map(obj => obj?.name)
  }, [workers?.cutters])
  const tailors = useMemo(() => {
    return workers?.tailors?.map(obj => obj?.name)
  }, [workers?.tailors])

  const handleConfirm = async()=>{

  }

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'Change to Ready'}
        paddingX='2.4rem'
        paddingY='1.6rem'
        isSticky
      />
      <div className={Styles.content}>
        <InputField
          title={'Cutter'}
          type='text'
          value={cutter}
          setValue={setCutter}
          isDropdown={true}
          dropdownOptions={cutters}
          placeholder={'Search and select employee'}
        />
        <InputField
          title={'Tailor'}
          type='text'
          value={tailor}
          setValue={setTailor}
          isDropdown={true}
          dropdownOptions={tailors}
          placeholder={'Search and select employee'}
        />
      </div>
      <div className={Styles.actions}>
        <Button
          variant='secondary'
          size='lg'
          buttonText='Cancel'
          onClick={handleClose}
        />
        <Button
          variant='primary'
          size='lg'
          buttonText='Confirm'
          onClick={handleConfirm}
        />
      </div>
    </div>
  )

}