import { priceFormat } from '@Helpers/priceFormat'
import useQuery from '@Hooks/useQuery'
import ActiveFiltersIndicator from '@Molecule/ActiveFiltersIndicator'
import InputField from '@Molecule/InputField'
import FilterModal from '@Molecule/_reusableLayout/FilterModal'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function BuyerFilterModal({
  handleClose,
  trigger
}) {

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()


  const defaultNumOfOrder = query.get('o')
  const defaultHaveBought = query.get('b')
  const defaultOutstanding = query.get('ot')
  const defaultHaveSpent = query.get('sp')

  const [minNumOfOrder, setMinNumOfOrder] = useState(defaultNumOfOrder?.split('-')[0] || '')
  const [maxNumOfOrder, setMaxNumOfOrder] = useState(defaultNumOfOrder?.split('-')[1] || '')
  const [minHaveBought, setMinHaveBought] = useState(defaultHaveBought?.split('-')[0] || '')
  const [maxHaveBought, setMaxHaveBought] = useState(defaultHaveBought?.split('-')[1] || '')
  const [minOutstanding, setMinOutstanding] = useState(defaultOutstanding?.split('-')[0] || '')
  const [maxOutstanding, setMaxOutstanding] = useState(defaultOutstanding?.split('-')[1] || '')
  const [minHaveSpent, setMinHaveSpent] = useState(defaultHaveSpent?.split('-')[0] || '')
  const [maxHaveSpent, setMaxHaveSpent] = useState(defaultHaveSpent?.split('-')[1] || '')



  useEffect(() => {
    setMinNumOfOrder(defaultNumOfOrder?.split('-')[0] || '')
    setMaxNumOfOrder(defaultNumOfOrder?.split('-')[1] || '')
    setMinHaveBought(defaultHaveBought?.split('-')[0] || '')
    setMaxHaveBought(defaultHaveBought?.split('-')[1] || '')
    setMinOutstanding(defaultOutstanding?.split('-')[0] || '')
    setMaxOutstanding(defaultOutstanding?.split('-')[1] || '')
    setMinHaveSpent(defaultHaveSpent?.split('-')[0] || '')
    setMaxHaveSpent(defaultHaveSpent?.split('-')[1] || '')

  }, [defaultHaveBought, defaultHaveSpent, defaultNumOfOrder, defaultOutstanding, trigger])


  const allQueries = useMemo(() => {
    const arr = []
    for (const param of query) {
      arr.push(param);
    }

    const newArr =
      !arr?.find(obj => obj[0] === 'o')
        ? (
          !arr?.find(obj => obj[0] === 'b')
            ?
            (
              !arr?.find(obj => obj[0] === 'ot')
                ?
                (
                  !arr?.find(obj => obj[0] === 'sp')
                    ?
                    [...arr, ['o', `${minNumOfOrder}-${maxNumOfOrder}`], ['b', `${minHaveBought}-${maxHaveBought}`], ['ot', `${minOutstanding}-${maxOutstanding}`], ['sp', `${minHaveSpent}-${maxHaveSpent}`]]
                    :
                    [...arr, ['o', `${minNumOfOrder}-${maxNumOfOrder}`], ['b', `${minHaveBought}-${maxHaveBought}`], ['ot', `${minOutstanding}-${maxOutstanding}`],]
                )
                :
                [...arr, ['o', `${minNumOfOrder}-${maxNumOfOrder}`], ['b', `${minHaveBought}-${maxHaveBought}`]]

            )
            :
            [...arr, ['o', `${minNumOfOrder}-${maxNumOfOrder}`]]
        )
        : [...arr]

    return newArr
  }, [maxHaveBought, maxHaveSpent, maxNumOfOrder, maxOutstanding, minHaveBought, minHaveSpent, minNumOfOrder, minOutstanding, query])


  const fixedQuery = useMemo(() => {
    let str = ''

    const raw = allQueries.join(',')?.split(',')

    const strArr = []

    for (let i = 0; i < raw.length / 2; i++) {
      strArr.push({
        key: raw[i + i],
        value: raw[i + i] === 'o'
          ? `${minNumOfOrder || ''}-${maxNumOfOrder || ''}` || ''
          : (
            raw[i + i] === 'b'
              ? `${minHaveBought || ''}-${maxHaveBought || ''}` || ''
              :
              (raw[i + i] === 'ot'
                ? `${minOutstanding || ''}-${maxOutstanding || ''}` || ''
                : (
                  raw[i + i] === 'sp'
                    ?
                    `${minHaveSpent || ''}-${maxHaveSpent || ''}` || ''
                    :
                    raw[i + i + 1]
                )
              )
          )
      })
    }


    for (let i = 0; i < strArr.length; i++) {
      const s = strArr[i]
      str += (i === 0 ? `${s?.key}=${s?.value}` : `&${s?.key}=${s?.value}`)
    }


    return str

  }, [allQueries, maxHaveBought, maxHaveSpent, maxNumOfOrder, maxOutstanding, minHaveBought, minHaveSpent, minNumOfOrder, minOutstanding])

  const filterTemplate = useMemo(() => {
    const arr = []

    if (minNumOfOrder || maxNumOfOrder) {
      arr.push({
        title: `Num of Order: ${minNumOfOrder || ''} Pcs - ${maxNumOfOrder || ''} Pcs`,
        handleRemove: () => {
          setMinNumOfOrder('')
          setMaxNumOfOrder('')
        }
      })
    }

    if (minHaveBought || maxHaveBought) {
      arr.push({
        title: `Have Bought: ${minHaveBought || ''} Pcs - ${maxHaveBought || ''} Pcs`,
        handleRemove: () => {
          setMinHaveBought('')
          setMaxHaveBought('')
        }
      })
    }
    if (minOutstanding || maxOutstanding) {
      arr.push({
        title: `Outstanding: Rp${priceFormat(minOutstanding || '')} - Rp${priceFormat(maxOutstanding || '')}`,
        handleRemove: () => {
          setMinOutstanding('')
          setMaxOutstanding('')
        }
      })
    }
    if (minHaveSpent || maxHaveSpent) {
      arr.push({
        title: `Have Spent: Rp${priceFormat(minHaveSpent || '')} - Rp${priceFormat(maxHaveSpent || '')}`,
        handleRemove: () => {
          setMinHaveSpent('')
          setMaxHaveSpent('')
        }
      })
    }
    return arr
  }, [maxHaveBought, maxHaveSpent, maxNumOfOrder, maxOutstanding, minHaveBought, minHaveSpent, minNumOfOrder, minOutstanding])

  const contentTemplate = useMemo(() => {
    return [
      {
        title: 'Num of Orders',
        description: (
          <div className={Styles.half}>
            <InputField
              title={'Min'}
              type='number'
              value={minNumOfOrder}
              setValue={setMinNumOfOrder}
              adornmentPosition={'end'}
              adornmentComponent={
                <div className={Styles.adornment}>Pcs</div>
              }
              placeholder={'Min'}
            />
            <InputField
              title={'Max'}
              type='number'
              value={maxNumOfOrder}
              setValue={setMaxNumOfOrder}
              adornmentPosition={'end'}
              adornmentComponent={
                <div className={Styles.adornment}>Pcs</div>
              }
              placeholder={'Max'}
            />
          </div>
        )
      },
      {
        title: 'Have Bought',
        description: (
          <div className={Styles.half}>
            <InputField
              title={'Min'}
              type='number'
              value={minHaveBought}
              setValue={setMinHaveBought}
              adornmentPosition={'end'}
              adornmentComponent={
                <div className={Styles.adornment}>Pcs</div>
              }
              placeholder={'Min'}
            />
            <InputField
              title={'Max'}
              type='number'
              value={maxHaveBought}
              setValue={setMaxHaveBought}
              adornmentPosition={'end'}
              adornmentComponent={
                <div className={Styles.adornment}>Pcs</div>
              }
              placeholder={'Max'}
            />
          </div>
        )
      },
      {
        title: 'Outstanding',
        description: (
          <div className={Styles.half}>
            <InputField
              title={'Min'}
              type='text'
              value={minOutstanding}
              setValue={setMinOutstanding}
              placeholder={'Min'}
              isCurrency
            />
            <InputField
              title={'Max'}
              type='text'
              value={maxOutstanding}
              setValue={setMaxOutstanding}
              placeholder={'Max'}
              isCurrency
            />
          </div>
        )
      },
      {
        title: 'Have Spent',
        description: (
          <div className={Styles.half}>
            <InputField
              title={'Min'}
              type='text'
              value={minHaveSpent}
              setValue={setMinHaveSpent}
              placeholder={'Min'}
              isCurrency
            />
            <InputField
              title={'Max'}
              type='text'
              value={maxHaveSpent}
              setValue={setMaxHaveSpent}
              placeholder={'Max'}
              isCurrency
            />
          </div>
        )
      },

    ]
  }, [maxHaveBought, maxHaveSpent, maxNumOfOrder, maxOutstanding, minHaveBought, minHaveSpent, minNumOfOrder, minOutstanding])

  const handleReset = () => {
    setMinNumOfOrder('')
    setMaxNumOfOrder('')
    setMinHaveBought('')
    setMaxHaveBought('')
    setMinOutstanding('')
    setMaxOutstanding('')
    setMinHaveSpent('')
    setMaxHaveSpent('')
  }

  const handleApply = () => {
    navigate(pathname + `?${fixedQuery}`, { replace: true })
    handleClose()
  }

  return (
    <FilterModal
      handleClose={handleClose}
      handleReset={handleReset}
      handleApply={handleApply}
      extraHeader={
        <ActiveFiltersIndicator
          filters={filterTemplate}
        />
      }
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.content}>
          <span>{content?.title}</span>
          {content?.description}
        </div>
      ))}
    </FilterModal>
  )
}