import Icon from "@Atom/Icon";
import { forwardRef, useMemo, useRef } from "react";
import { default as SlickSlider } from "react-slick";
import "./style.scss";



const Slider = forwardRef(function Slder({
  children,
  setIndex = () => { },
  showDots = false,
  showArrows = false,
  refToForward,
  slidesToShow = 1,
  slidesToScroll = 1,
  arrowLeftPosition='24px',
  arrowRightPosition='24px',
  ...props
}, ref) {
  const settings = {
    dots: showDots,
    arrows: false,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
    beforeChange: (oldIndex, newIndex) => {
      setIndex(newIndex)
    },
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <button>next</button>
  };

  const slider = useRef()

  const activeRef = useMemo(() => {
    if (refToForward) {
      return refToForward
    } return slider
  }, [refToForward])

  return (
    <div style={{ position: 'relative' }}>
      <SlickSlider ref={activeRef} {...settings}>{children}</SlickSlider>
      {showArrows &&
        <div
        >
          <button
            onClick={() => activeRef?.current?.slickPrev()}
            style={{
              position: 'absolute',
              top: 'calc(50% - 12.5px)',
              left: arrowLeftPosition,
              zIndex: 2
            }}
          >
            <Icon icon={'chevron-left'} size={25} />
          </button>
          <button
            onClick={() => activeRef?.current?.slickNext()}
            style={{
              position: 'absolute',
              top: 'calc(50% - 12.5px)',
              right: arrowRightPosition,
              zIndex: 2
            }}
          >
            <Icon icon={'chevron-right'} size={25} />
          </button>
        </div>
      }


    </div>
  );
}
)

export default Slider