import Icon from '@Atom/Icon';
import Styles from './style.module.scss'

export default function NumberInput({
  value = '',
  setValue,
  min = 1,
  max = 1000
}) {
  return (
    <div className={Styles.container}>
      <input
        value={value}
        onChange={(e) => {
          const re = /^[0-9\b]+$/;
          if (e.target.value === "" || re.test(e.target.value)) {
            setValue(
              +e.target.value <= max && +e.target.value >= min
                ? +e.target.value
                : +e.target.value > max
                  ? max
                  : +e.target.value < min
                    ? ''
                    : null
            );
          }
        }}
      />
      <div className={Styles.arrows}>
        <button
          onClick={() =>
            setValue(
              value < max ? (value += 1) : value
            )
          }
        >
          <Icon size={12} icon="chevron-up" />
        </button>
        <button
          onClick={() =>
            setValue(
              value > min ? (value -= 1) : value
            )
          }
        >
          <Icon size={12} icon="chevron-down" />
        </button>
      </div>
    </div>
  )
}