import CartLayout from '@Organism/Cart'
import Styles from './style.module.scss'
import { Suspense, useState } from 'react'
import ModalWrapper from '@Atom/ModalWrapper'
import ProceedOrderModal from '@Molecule/_modals/ProceedOrderModal'
import { Await, useLoaderData } from 'react-router-dom'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function CartPage() {
  const { packageOrderSync } = useLoaderData()
  const [showProceed, setShowProceed] = useState(false)
  const [isSure, setIsSure] = useState(false)

  const [formToSend, setFormToSend] = useState(null)
  const [idsToRemove, setIdsToRemove] = useState([])

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={<CartLayout
          setShowProceed={setShowProceed}
          isSure={isSure}
          setIdsToRemove={setIdsToRemove}
          defaultSync={{
            type: [],
            method: []
          }}
          isLoading
          setFormToSend={setFormToSend}
        />}
      >
        <Await
          resolve={packageOrderSync}
          errorElement={
            <AsyncErrorBoundary />
          }
        >
          {(packageOrderSync) => (
            <CartLayout
              setShowProceed={setShowProceed}
              setIdsToRemove={setIdsToRemove}
              isSure={isSure}
              defaultSync={packageOrderSync?.data}
              setFormToSend={setFormToSend}
            />
          )}

        </Await>

      </Suspense>

      <div>
        <ModalWrapper
          show={showProceed}
          desktopPositioning='center'
          mobilePositioning='bottom'
        >
          <ProceedOrderModal
            setIsSure={setIsSure}
            setShowProceed={setShowProceed}
            formToSend={formToSend}
            idsToRemove={idsToRemove}
          />
        </ModalWrapper>
      </div>
    </div>
  )
}