import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '24px',
    gap: '16px'
  },
  headerWrapper: {
    gap: '12px'
  },
  logo: {
    height: '24px',
    objectFit: 'contain',
    alignSelf: 'flex-start'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerLeft: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '24px',
    // fontStyle: 'normal',
    fontWeight: 700,
  },
  subtitle: {
    fontSize: '12px',
    fontStyle: 'normal',
    // fontFamily: 'Helvetica-Bold',
    fontWeight: 500,
    // lineHeight: '16px', 
  },
  headerRight: {
    // flexDirection: 'column',
    // gap: '4px',
    // borderRadius: '8px',
    // border: '1px solid #CED4DA',
    // backgroundColor: '#F8F9FA',
    // padding: '8px'
    flexDirection: 'row',
    gap: '4px'
  },
  headerRightSection: {
    flexDirection: 'column',
    gap: '4px',
    borderRadius: '8px',
    border: '1px solid #CED4DA',
    backgroundColor: '#F8F9FA',
    padding: '8px'
  },
  dueDateTitle: {
    fontSize: '10px',
    fontWeight: 400,
  },
  dueDateContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
  },
  calendar: {
    width: '16px',
    objectFit: 'contain'
  },
  formerDueDate: {
    fontSize: '10px',
    fontWeight: 400,
  },
  currentDueDate: {
    backgroundColor: '#E5E4FF',
    paddingHorizontal: '4px',
    borderRadius: '50px',
    fontSize: '10px',
    fontWeight: 400,
    color: '#231FFF',
  },
  content: {
    gap: '16px',
  },
  items: {
    gap: '4px'
  },
  itemsTitle: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  details: {
    gap: '24px',
  },
  customer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  customerInfo: {
    width: '50%',
    flexShrink: 0,
    gap: '8px'
  },
  infoDetails: {
    gap: '4px'
  },
  address: {
    width: '50%',
    flexShrink: 0,
    alignItems: 'flex-end',
    gap: '8px',
    textAlign: 'right'
  },
  payment: {
    gap: '8px',
  },
  price: {
    gap: '8px'
  },
  list: {
    gap: '4px'
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  separator: {
    width: '100%',
    height: '1px',
    backgroundColor: '#E9ECEF'
  },
  subTotal: {
    gap: '8px',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  tax: {
    gap: '8px',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  total: {
    gap: '8px',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  invoiceOnlyWrapper: {
    gap: '16px'
  },
  table: {
    marginTop: '20px',
    display: 'table', 
    width: 'auto', 
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: 'auto', 
    flexDirection: 'row' 
  },
  tabelColNum: {
    width: '10%', 
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol: { 
    width: '35%', 
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableColQty: { 
    width: '20%', 
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tabelColNumMerged: {
    width: '65%', 
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCell: { 
    margin: 'auto', 
    marginTop: 5, 
    fontSize: 10 
  }

});

export default styles