
import Styles from "./style.module.scss";
import { Await, useLoaderData } from "react-router-dom";
import { Suspense } from "react";
import AsyncErrorBoundary from "@Organism/_utils/AsyncErrorBoundary";
import StartMeasureLayout from "@Organism/Product/StartMeasureCollection";

export default function StartMeasureReadyPage() {
  const { packageMeasurements, customerList } = useLoaderData();

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <StartMeasureLayout isLoading={true} defaultData={null} />
        }
      >
        <Await
          resolve={packageMeasurements}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageMeasurements) => (
            <Suspense
              fallback={
                <StartMeasureLayout
                  defaultData={packageMeasurements?.data}
                  customerList={[]}
                />
              }
            >
              <Await
                resolve={customerList}
                errorElement={<AsyncErrorBoundary />}
              >
                {(customerList) => (
                  <StartMeasureLayout
                    defaultData={packageMeasurements?.data}
                    customerList={customerList?.data}
                  />
                )}
              </Await>
            </Suspense>
          )}
        </Await>
      </Suspense>
    </div>
  );
}
