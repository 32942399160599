import Avatar from "@Atom/Avatar";
import Styles from "./style.module.scss";
import Label from "@Atom/Label";
import StackedItemInfo from "@Molecule/StackedItemInfo";
import Icon from "@Atom/Icon";
import { priceFormat } from "@Helpers/priceFormat";
import { fileBaseUrl } from "@Config/api";

export default function SingleItemCard({
  isReadyToWear,
  isAlter,
  isFabricOnly,
  label,
  fabrics,
  description,
  notes,
  onEdit = () => { },
  noEdit = false,
  noDelete = false,
  onDelete = () => { },
  sizeID = '',
  itemID = ''
}) {
  return (
    <div className={Styles.container}>
      {/* {!isAlter
        && */}
      <div className={Styles.left}>
        <Avatar
          size="72px"
          withIcon={!isReadyToWear && !isFabricOnly ? true : false}
          src={
            isReadyToWear || isFabricOnly || isAlter ? fabrics[0]?.imgUrl : ""
          }
          borderRadius="4px"
          iconName={
            label?.toLowerCase() === "trouser" ? "pants" : label.toLowerCase()
          }
          iconSize="64px"
          backgroundColor="#fff"
        />
      </div>
      {/* } */}

      <div className={Styles.right}>
        <div className={Styles.rightTop}>
          <Label desc={label} padding="0px 8px" borderRadius="4px" />
          <div className={Styles.actions}>
            {!noEdit
              &&
              <button onClick={onEdit}>
                <Icon icon={"pencil"} size={20} color={"#000"} />
              </button>
            }
            {
              !noDelete
              &&
              <button onClick={() => onDelete({ sizeID, itemID })}>
                <Icon icon={"trash"} size={20} color={"#DD0B0B"} />
              </button>
            }
          </div>
        </div>
        <div className={Styles.rightMiddle}>
          {fabrics?.map((f, iF) => (
            <div key={iF} className={Styles.perFabric}>
              <StackedItemInfo
                withoutPicture={
                  isReadyToWear || isFabricOnly || isAlter ? true : false
                }
                picture={fileBaseUrl + f?.imgUrl}
                title={
                  !isReadyToWear && !isAlter
                    ? `${f?.type} - ${f?.name}`
                    : f?.name
                }
                label={f?.code}
                titleFontSize="16px"
                titleWidth="100%"
              />
              <div className={Styles.amount}>
                <span>{f?.qty}x</span>
                <span>Rp{priceFormat(f?.price)}</span>
              </div>
            </div>
          ))}
        </div>
        {description && (
          <div className={Styles.description}>
            <span>{description}</span>
          </div>
        )}
        {notes && (
          <div className={Styles.notes}>
            <span>{notes}</span>
          </div>
        )}
      </div>
    </div>
  );
}
