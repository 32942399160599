import LogListLayout from '@Organism/Log/LogList'
import Styles from './style.module.scss'
import { useEffect, useState } from 'react'
import ModalWrapper from '@Atom/ModalWrapper'
import LogFilterModal from '@Molecule/_modals/FilterModals/Log'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function LogListPage() {
  const [showFilter, setShowFilter] = useState(false)
  const [trigger, setTrigger] = useState(null)

  useEffect(() => {
    setTrigger(makeRandomString(5))
  }, [showFilter])

  return (
    <div className={Styles.container}>
      <LogListLayout
        defaultData={null}
        isLoading={false}
        trigger={trigger}
        setTrigger={setTrigger}
        setShowFilter={setShowFilter}
      />
      <div>
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={showFilter}
          setShow={setShowFilter}
        >
          <LogFilterModal
            handleClose={() => setShowFilter(false)}
            trigger={trigger}
          />
        </ModalWrapper>
      </div>
    </div>
  )
}