/*eslint-disable import/no-anonymous-default-export */

export default ({
  MAIN_LOGO: require('@Assets/Images/main-logo.png'),
  AVA_DEFAULT: require('@Assets/Images/ava-default.png'),
  CLOUD: require('@Assets/Images/cloud.png'),
  BLUE_SHIRT: require('@Assets/Images/blue-shirt.png'),
  BLACK_SUIT: require('@Assets/Images/black-suit.png'),
  SIMULATION_PLACEHOLDER: require('@Assets/Images/simulation-placeholder.png'),
  LOGO_NO_ICON: require('@Assets/Images/logo-no-icon.png'),
  SIMULATION_BANNER: require('@Assets/Images/simulation-banner.png'),
  READY_TO_WEAR_BANNER: require('@Assets/Images/ready-to-wear-banner.png'),
  COLLECTION_BANNER: require('@Assets/Images/collection-banner.png'),
  ALTER_BANNER: require('@Assets/Images/alter-banner.png'),
  SIMULATION_BANNER_LONG: require('@Assets/Images/simulation-banner-long.png'),
  AUTH_BG: require('@Assets/Images/auth-bg.png'),
  CIRCLE_X_RED: require('@Assets/Images/circle-x-red.png'),
  CALENDAR_DAYS: require('@Assets/Images/calendar-days.png'),
  SUIT: require('@Assets/Images/suit.png'),
  QUESTIONING: require('@Assets/Images/questioning.png'),
  QUESTIONING_NO_BG: require('@Assets/Images/questioning-no-bg.png'),
  SUIT_SS: require('@Assets/Images/suit-ss.png'),
  SHIRT_SS: require('@Assets/Images/shirt-ss.png'),
  TROUSER_SS: require('@Assets/Images/trouser-ss.png'),
  ALTER_SS: require('@Assets/Images/alter-ss.png'),
  LOADER: require('@Assets/Images/loader.png'),
  LOADER_V2: require('@Assets/Images/loader-v2.png')
})