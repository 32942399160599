import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import useQuery from '@Hooks/useQuery'
import InputField from '@Molecule/InputField'
import OTPField from '@Molecule/OTPField'
import { login, verifyOTP } from '@Services/auth'
import Images from '@Theme/Images'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function LoginLayout() {
  const query = useQuery()
  const hash = query.get('h')
  const submittedPhoneNumber = query.get('p')
  const [phoneNumber, setPhoneNumber] = useState(submittedPhoneNumber || '')
  const [otp, setOtp] = useState('')

  const [error, setError] = useState('')

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  }, [error, setError]);

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const filler = useMemo(() => {
    const arr = [];
    for (let i = 3; i < submittedPhoneNumber?.length - 4; i++) {
      arr.push("*");
    }
    return arr;
  }, [submittedPhoneNumber]);



  const timerRef = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState('00:00');

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total, hours, minutes, seconds
    };
  }

  const startTimer = useCallback((e) => {
    let { total, minutes, seconds }
      = getTimeRemaining(e);
    if (total >= 0) {

      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        // (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      )
    }
  }, [])

  const clearTimer = useCallback((e) => {

    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next   
    setTimer('00:60');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    timerRef.current = id;
  }, [startTimer]);

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  }

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickResend = useCallback(async (e) => {
    try {
      e.preventDefault()
      const { response } = await login('62' + phoneNumber)
      navigate(`${pathname}?h=${response?.data}&p=${phoneNumber}`)

      clearTimer(getDeadTime());
    } catch (err) {
      console.log(err)
    }
  }, [clearTimer, navigate, pathname, phoneNumber]);

  const template = useMemo(() => {
    const doesContainHash = hash ? true : false;

    switch (doesContainHash) {
      case true:
        return ({
          title: 'Enter the code',
          subTitle: `One Time Password(OTP) has been sent to your mobile ${filler?.join('')}${submittedPhoneNumber?.slice(submittedPhoneNumber?.length - 4, submittedPhoneNumber?.length)}`,
          buttonText: 'Continue',
          buttonDisabled: otp?.length !== 4 ? true : false,
          component:
            <div className={Styles.OTPField}>
              <OTPField
                title={'OTP'}
                value={otp}
                setValue={setOtp}
                placeholder={'81xxxxxxxxx'}
              />
              <div className={Styles.resendWrapper}>
                <span>{timer}</span>
                <button
                  onClick={onClickResend}
                  disabled={timer !== '00:00'}
                >
                  Resend OTP
                </button>
              </div>
            </div>
          ,
          onClickButton: async () => {
            try {
              const { data } = await verifyOTP(hash, otp)
              localStorage.setItem('accessToken', data?.accessToken)
              localStorage.setItem('user', JSON.stringify({
                ...data?.user,
                defaultRole: data?.user?.role,
                role: data?.user?.isPic || data?.user?.role === 'SALES' ? 'OFFICE' : data?.user?.role
              }))
              navigate('/')
            } catch (err) {
              setError(err?.response?.data?.message || 'Something went wrong')
              console.log(err)
            }
          }
        })
      default:
        return ({
          title: 'Welcome Back',
          subTitle: 'Please enter your mobile phone number',
          buttonText: 'Next',
          buttonDisabled: !phoneNumber ? true : false,
          component:
            <div className={Styles.loginField}>
              <InputField
                title={'Phone number'}
                type='tel'
                value={phoneNumber}
                setValue={setPhoneNumber}
                placeholder={'81xxxxxxxxx'}
              />
            </div>
          ,
          onClickButton: async (e) => {
            try {
              e.preventDefault()
              const { response } = await login('62' + phoneNumber)

              navigate(`${pathname}?h=${response?.data}&p=${phoneNumber}`)

            } catch (err) {
              setError(err?.response?.data?.message || 'Something went wrong')
              console.log(err)
            }
          }
        })

    }
  }, [filler, hash, navigate, onClickResend, otp, pathname, phoneNumber, submittedPhoneNumber, timer])

  return (
    <div className={Styles.container}>
      <div className={Styles.overlay} />
      <div className={Styles.imgWrapper}>
        <img src={Images.LOGO_NO_ICON} alt='' />
      </div>
      <div
        className={Styles.card}
      >
        <Icon icon={'spade'} size={32} />
        <div className={Styles.title}>
          <h3>{template?.title}</h3>
          <span>{template?.subTitle}</span>
        </div>
        {template?.component}
        <div className={`${Styles.errorWrapper} ${error ? Styles.visible : Styles.transparent}`}>
          {error || 'error placeholder'}
        </div>
        <Button
          variant='primary'
          buttonText={template?.buttonText}
          onClick={template?.onClickButton}
          disabled={template?.buttonDisabled}
          size='lg'
        />
      </div>
      {hash && (
        <Button
          variant='tertiary'
          size='md'
          buttonText='Back to entering phone numbers'
          fixedPadding={'0px'}
          withIcon
          iconName={'arrow-small-left'}
          disableHover
          disableActive
          onClick={() => navigate(`${pathname}?p=${submittedPhoneNumber}`)}
        />
      )}
    </div>
  )
}