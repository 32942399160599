import CollectionListLayout from '@Organism/Product/Collection/CollectionList'
import Styles from './style.module.scss'
import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function CollectionListPage() {
  const { packageCollectionList } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <CollectionListLayout
            isLoading={true}
            defaultData={null}
          />
          // <>loading</>
        }
      >
        <Await
          resolve={packageCollectionList}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageCollectionList) => (
            <CollectionListLayout
              defaultData={packageCollectionList?.data?.products}
              totalPage={packageCollectionList?.data?.totalPage}
              totalData={packageCollectionList?.data?.total}
            />

          )}
        </Await>


      </Suspense>
    </div>
  )
}