import Avatar from '@Atom/Avatar'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import ModalWrapper from '@Atom/ModalWrapper'
import { fileBaseUrl } from '@Config/api'
import { capitalizeWords } from '@Helpers/capitalizeWords'
import { omitKeys } from '@Helpers/omitKey'
import { priceFormat } from '@Helpers/priceFormat'
import { splitCamelCase } from '@Helpers/splitCamelCase'
import useQuery from '@Hooks/useQuery'
import ItemListCard from '@Molecule/ItemListCard'
import UpdateFeePaymentModal from '@Molecule/_modals/UpdateFeePaymentModal'
import DetailsModal from '@Molecule/_reusableLayout/DetailsModal'
import { getEmployeeOrder } from '@Services/employee'
import Images from '@Theme/Images'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'

const removeStarter = (str) => {
  const newStr = str?.replaceAll(' ', '')?.replaceAll('+', '')
  if (newStr?.startsWith('62')) {
    return newStr?.slice(2)
  }
  return newStr
}

export default function FeeDetailsModal({
  handleClose = () => { },
  employee
}) {
  const [data, setData] = useState()

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)

  const [showUpdatePaymentType, setShowUpdatePaymentType] = useState('')
  const query = useQuery()

  const fetchData = useCallback(async () => {
    try {
      // if (!employee?.code) {
      //   return false
      // }
      setIsLoading(true)
      const { data: resData } = await getEmployeeOrder(employee?.code || 'USER-0000015')

      const res = resData

      setData({
        ...res,
        fullName: res?.fullName || '',
        nickName: res?.nickName || '',
        phoneNumber: removeStarter(employee?.whatsAppNumber || ''),
        role: res?.role,
        file: res?.imageUrl ? fileBaseUrl + res?.imageUrl : ''
      })
      // setPlacementData(res?.placement)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }, [employee?.code, employee?.whatsAppNumber])

  const totalPaid = useMemo(() => {
    if (data?.padiHistory?.length) {
      return data?.padiHistory?.map(obj => obj?.paid)?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)
    }
    return 0
  }, [data?.padiHistory])

  useEffect(() => {
    if (employee) {
      fetchData()
    }
  }, [employee, fetchData])

  const contentTemplate = useMemo(() => {
    return [
      // {
      //   title: 'Order by',
      //   component:
      //     <StackedItemInfo
      //       title={data?.customerName}
      //       label={data?.customerPhoneNumber}
      //       withoutPicture
      //     />
      // },
      {
        title: '',
        component:
          <div className={Styles.dateRange}>
            <div>
              <span>From</span>
              <div className={Styles.date}>
                <Icon icon={'calendar'} size={24} />
                <span>{data?.assignedDateFrom || '-'}</span>
              </div>
            </div>
            <div>
              <span>To</span>
              <div className={Styles.date}>
                <Icon icon={'calendar'} size={24} />
                <span>{data?.assignedDateTo || '-'}</span>
              </div>
            </div>
          </div>
      },
      {
        title: 'Items',
        component:
          <div className={Styles.itemList}>
            {
              data?.orders?.length
              ?
              <ItemListCard
              mappedData={
               data?.orders?.length
                 ?
                 data?.orders?.map(o => {
                   return {
                     ...o,
                     id: o?._id,
                     orderID: o?.orderID,
                     dueDate: new Date(o?.dueDate),
                     sizeTypes: [
                       {
                         id: o?.pattern?.code,
                         name: o?.pattern?.name,
                         items: [
                           {
                             id: o?._id,
                             isReadyToWear: o?.type?.includes('READY'),
                             label: o?.type,
                             notes: o?.notes,
                             fabrics: o?.textiles?.map(t => {
                               return {
                                 ...t,
                                 type: '',
                                 fabricType: t?.type?.replaceAll('-', ' '),
                                 fabricName: t?.name,
                                 fabricCode: t?.fabric,
                                 img: t?.imageUrl ? fileBaseUrl + t?.imageUrl?.replaceAll(fileBaseUrl, '') : ''
                               }
                             })
                           }
                         ],
                         measurements: Object.entries(omitKeys([
                           'code',
                           'name',
                           'photos',
                           'remark',
                           'createdAt',
                           'customerCode',
                           'updatedAt',
                           '__v',
                           '_id'
                         ], o?.pattern))
                           ?.map((m) => {
                             // if (m[0] !== "photos")
                             return {
                               name: m[0],
                               value: m[1],
                               title: splitCamelCase(m[0]),
                             };
                           }),
                         customerPhotos: o?.pattern?.photos?.map(p => {
                           return {
                             id: p?._id,
                             url: p?.uri ? fileBaseUrl + p?.uri : ''
                           }
                         })
                       }
                     ],
 
                   }
                 })
                 :
                 []
             }
            
             />
             :
             <span>-</span>
            }
           
          </div>
      },
      // {
      //   title: 'Items',
      //   component:
      //     <div className={Styles.items}>
      //       {data?.itemsArr?.map((each, i) => (
      //         <div key={i}>
      //           <span>{i + 1}</span>
      //           <Avatar src={each?.img || null} size='32px' borderRadius='6px' />
      //           <ItemDetails
      //             item={each}
      //           />
      //           <div>
      //             <span>{timeAgo(each?.updatedAt || new Date())} •</span>
      //             <span className={each?.status?.toLowerCase() === 'done' ? Styles.done : Styles.onProgress}> {each?.status || 'On Progress'}</span>
      //           </div>
      //         </div>
      //       ))}
      //     </div>
      // },
      {
        title: 'Fee Details',
        component:
          <div className={Styles.fee}>
            <div className={Styles.intro}>
              <div>
                <span>Outstanding</span>
                <span>Rp {priceFormat(data?.fee - totalPaid || 0)}</span>
              </div>
              <div>
                <span>Total Paid Fee</span>
                <span>Rp {priceFormat(totalPaid || 0)}</span>
              </div>
              <div>
                <span>Total Fee</span>
                <span>Rp {priceFormat(data?.fee)}</span>
              </div>
            </div>

            <div className={Styles.history}>
              <span>Paid History</span>
              <div className={Styles.historyTableWrapper}>
                <table className={Styles.historyTable}>
                  <thead>
                    <tr>
                      <td>No</td>
                      <td>Img</td>
                      <td>Paid Date</td>
                      <td>Fee Payment</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.padiHistory?.map((paid, i) => (
                      <tr key={i}>
                        <td>
                          {i + 1}
                        </td>
                        <td>
                          <Avatar
                            src={paid?.uri ? fileBaseUrl + paid?.uri : ''}
                            borderRadius='2px'
                            size='24px' />
                        </td>
                        <td>
                          {moment(new Date(paid?.date))?.format('L')}
                        </td>

                        <td>Rp {paid?.paid}</td>
                      </tr>
                    ))}
                  </tbody>

                </table>
                {!data?.padiHistory?.length
                  &&
                  <div className={Styles.tableFiller}>No data</div>
                }
              </div>
            </div>
            <Button
              buttonText='Update Paid History'
              withIcon
              iconName={'plus'}
              borderRadius='6px'
              customIconSize={20}
              onClick={() => setShowUpdatePaymentType('fee')}
            />
          </div>
      },
      {
        title: 'Bonus Fee Paid',
        component:
          <div className={Styles.bonusFee}>

            <div className={Styles.history}>
              <div className={Styles.historyTableWrapper}>
                <table className={Styles.historyTable}>
                  <thead>
                    <tr>
                      <td>No</td>
                      <td>Img</td>
                      <td>Paid Date</td>
                      <td>Fee Payment</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.bonusFee?.map((paid, i) => (
                      <tr key={i}>
                        <td>
                          {i + 1}
                        </td>
                        <td>
                          <Avatar
                            src={paid?.uri ? fileBaseUrl + paid?.uri : ''}
                            borderRadius='2px'
                            size='24px' />
                        </td>
                        <td>
                          {moment(new Date(paid?.date))?.format('L')}
                        </td>

                        <td>Rp {paid?.paid}</td>
                      </tr>
                    ))}
                  </tbody>

                </table>
                {!data?.bonusFee?.length
                  &&
                  <div className={Styles.tableFiller}>No data</div>
                }
              </div>
            </div>
            <Button
              buttonText='Update Bonus'
              withIcon
              iconName={'plus'}
              borderRadius='6px'
              customIconSize={20}
              onClick={() => setShowUpdatePaymentType('bonus')}
            />
          </div>
      }
      // {
      //   title: 'Bonus Pee Faid',
      //   component:
      //     <div className={Styles.bonusFee}>
      //       <div className={Styles.unpaid}>
      //         <span>Unpaid</span>
      //         <div>Rp {data?.unpaid ? priceFormat(data?.unpaid) : '-'}</div>
      //       </div>
      //       <div className={Styles.paid}>
      //         <span>Paid</span>
      //         <div>Rp {data?.paid ? priceFormat(data?.paid) : '-'}</div>
      //       </div>
      //       {!paymentProof
      //         ?
      //         <Button
      //           buttonText='Upload Payment Proof'
      //         />
      //         :
      //         <div className={Styles.proof}>
      //           <img src={paymentProof?.img} alt='' />
      //           <div>
      //             <span>{paymentProof?.title}</span>
      //             <span>Photo uploaded at {paymentProof?.addedAt}</span>
      //           </div>
      //           <button>
      //             <Icon icon={'arrow-uturn-left'} size={24} />
      //           </button>
      //         </div>
      //       }
      //     </div>
      // },

    ]
  }, [data?.assignedDateFrom, data?.assignedDateTo, data?.bonusFee, data?.fee, data?.orders, data?.padiHistory, totalPaid])

  return (
    <DetailsModal
      modalTitle={`${capitalizeWords(data?.role || '')} Details`}
      modalIcon={query.get('e') !== 'cutter' ? 'tailor' : 'cutter'}
      handleClose={handleClose}
      extraHeaderIntro={capitalizeWords(data?.role || '')}
      extraHeaderTitle={data?.fullName}
      image={data?.img || Images?.AVA_DEFAULT}
      withImage
      noTag
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.each}>
          {content?.title && <span>{content?.title}</span>}
          {content?.component}
        </div>
      ))}
      <div style={{ visibility: showUpdatePaymentType ? 'visible' : 'hidden' }}>
        <ModalWrapper show={showUpdatePaymentType}>
          <UpdateFeePaymentModal
            onClose={() => setShowUpdatePaymentType('')}
            type={showUpdatePaymentType}
            fetchData={fetchData}
            id={data?.code}
          />
        </ModalWrapper>

      </div>
    </DetailsModal>
  )
}