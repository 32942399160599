import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import Skeleton from 'react-loading-skeleton'
import { useState } from 'react'

export default function Avatar({
  src,
  size = '3.2rem',
  customHeight = '',
  borderRadius = '50%',
  iconName,
  iconSize = '2.4rem',
  withIcon,
  iconColor = '',
  backgroundColor = '#E9ECEF',
  isSkeleton = false,
  aspectRatio = 1
}) {

  const [isLoading, setIsLoading] = useState((src !== undefined) && (src !== '') && (src !== null))

  if (isSkeleton) {
    return (
      <Skeleton duration={2} className={Styles.imgPlaceholder} style={{ width: size, aspectRatio, borderRadius: borderRadius, backgroundColor: backgroundColor }} />
    )
  }

  if ((src !== undefined) && (src !== '') && (src !== null)) {
    return (
      <div className={Styles.wrapper}>
        {
          isLoading
          &&
          <div className={Styles.loadingPlaceholder} >
            <Skeleton duration={2} className={Styles.imgPlaceholder} style={{ width: size, aspectRatio, borderRadius: borderRadius, backgroundColor: backgroundColor }} />
          </div>

        }
        <img
          onLoad={(e) => setIsLoading(false)}
          src={src} alt="" className={Styles.img}
          style={{ width: size, height: customHeight || size, aspectRatio, borderRadius: borderRadius, backgroundColor: backgroundColor }} />
      </div>
    )
  } else {
    return <div className={Styles.imgPlaceholder} style={{ width: size, height: customHeight || size, aspectRatio, borderRadius: borderRadius, backgroundColor: backgroundColor }}>
      {withIcon && <Icon color={iconColor} icon={iconName} size={iconSize} />}
    </div>
  }
}