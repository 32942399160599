import ModalHeader from '@Atom/ModalHeader'
import Styles from './style.module.scss'
import { useEffect, useMemo, useState } from 'react'
import useQuery from '@Hooks/useQuery'
import Radio from '@Atom/Radio'
import Checkbox from '@Atom/Checkbox'
import Button from '@Atom/Button'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ListingFilterModal({
  setShow = () => { }
}) {
  const query = useQuery()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const defaultSortBy = query.get('s') || ''
  const defaultByPrice = useMemo(() => {
    return query.get('p')?.split('n') || []
  }, [query])
  const [sortBy, setSortBy] = useState(defaultSortBy)
  const [byPrice, setByPrice] = useState(defaultByPrice)

  useEffect(() => {
    setSortBy(defaultSortBy)
    setByPrice(defaultByPrice)
  }, [defaultByPrice, defaultSortBy])

  const sortOptions = useMemo(() => {
    return [
      {
        query: 'newest',
        title: 'Newest',
      },
      {
        query: 'oldest',
        title: 'Oldest'
      },
      {
        query: 'price-high-low',
        title: 'Price: High-Low'
      },
      {
        query: 'price-low-high',
        title: 'Price: Low-High'
      }
    ]
  }, [])

  const byPriceOptions = useMemo(() => {
    return [
      {
        query: '0-1000000',
        title: 'Under Rp1.000.000'
      },
      {
        query: '1000000-5000000',
        title: 'Rp1.000.000 - Rp5.000.000'
      },
      {
        query: '5000000-10000000',
        title: 'Rp5.000.000 - Rp10.000.000'
      }
    ]
  }, [])

  return (
    <div className={Styles.container}>
      <ModalHeader
        paddingX='16px'
        paddingY='16px'
        title={'Filter'}
        withCloseButton
        handleClose={() => setShow(false)}
        isSticky
      />
      <div className={Styles.content}>
        <FilterSection
          title={'Sort by'}
          options={sortOptions}
          type={sortBy}
          setType={setSortBy}
        />
        <FilterSection
          title={'Shop By Price'}
          options={byPriceOptions}
          type={byPrice}
          setType={setByPrice}
          isArray
        />
      </div>
      <div className={Styles.actions}>
        <Button
          variant='secondary'
          buttonText='Clear'
          onClick={() => {
            setSortBy('')
            setByPrice([])
          }}
          size='lg'
        />
        <Button
          variant='primary'
          buttonText='Apply'
          onClick={() => {
            setShow(false)
            navigate(`${pathname}?s=${sortBy}&p=${byPrice?.join('n')}`, { replace: true })
          }}
          size='lg'
        />
      </div>
    </div>
  )
}

const FilterSection = ({ title, options, type, setType, isArray = false }) => {
  return (
    <div className={Styles.section}>
      <span>{title}</span>
      <div className={Styles.options}>
        {options?.map((option, i) => (
          <div key={i} onClick={() => {
            if (!isArray) {
              if (type !== option?.query) {
                setType(option?.query)
              } else {
                setType('')
              }
            } else {
              const t = [...type]
              const found = t?.find(obj => obj === option?.query)
              if (!found) {
                setType([...t, option?.query])
              } else {
                setType(t?.filter(obj => obj !== option?.query))
              }

            }
          }}>
            {!isArray ? <Radio
              isChecked={type === option?.query}
            /> : <Checkbox
              isChecked={type?.find(obj => obj === option?.query)}
            />}
            <span>{option?.title}</span>
          </div>
        ))}
      </div>
    </div>
  )
}