import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import { capitalizeWords } from '@Helpers/capitalizeWords'
import Button from '@Atom/Button'

export default function DeleteModal({
  onDelete = () => { },
  setShow,
  label = 'Stock'
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.logo}>
        <Icon icon={'trash'} size={'72px'} />
      </div>
      <div className={Styles.desc}>
        <span>{`Delete ${capitalizeWords(label)}`}</span>
        <span>{`Are you sure you want to delete this ${label?.toLowerCase()}?`}</span>
      </div>
      <div className={Styles.actions}>
        <Button
          size='lg'
          variant='secondary'
          buttonText='Cancel'
          disableActive
          fixedPadding={'1rem 1.6rem'}
          onClick={()=> setShow(false)}
        // onClick={() => navigate(`/product/cart`)}
        />
        <Button
          size='lg'
          variant='primary'
          buttonText={`Yes, delete ${label?.toLowerCase()}`}
          disableActive
          fixedPadding={'1rem 1.6rem'}
          onClick={onDelete}
        // onClick={() => navigate(`${pathname}/start-measure/${id}`)}
        />
      </div>

    </div>
  )
}