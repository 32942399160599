import ModalHeader from '@Atom/ModalHeader'
import Styles from './style.module.scss'
import { useCallback, useEffect, useMemo, useState } from 'react'
import InputField from '@Molecule/InputField'
import Button from '@Atom/Button'
import UploadField from '@Molecule/UploadField'
import { addEmployee } from '@Services/employee'
import { makeRandomString } from '@Helpers/makeRandomString'
import { allHasValueObjectChecker } from '@Helpers/allHasValueObjectChecker'
import { getCheckpointList } from '@Services/checkpoint'
import ModalWrapper from '@Atom/ModalWrapper'
import WideErrorModal from '@Molecule/_modals/WideErrorModal'

export default function AddEmployeeModal({
  handleClose = () => { },
  setTrigger = () => { }

}) {
  const [checkpoints, setCheckpoints] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [form, setForm] = useState({
    nickName: '',
    fullName: '',
    phoneNumber: '',
    role: '',
    storeCode: '',
    file: null
  })

  const fetchCheckpoints = useCallback(async () => {
    try {
      const response = await getCheckpointList()
      setCheckpoints(response?.data)
    } catch (err) {
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
    }
  }, [])

  useEffect(() => {
    fetchCheckpoints()
  }, [fetchCheckpoints])

  useEffect(() => {
    setIsSubmitting(false)
  }, [])

  useEffect(() => {
    setForm({
      nickName: '',
      fullName: '',
      phoneNumber: '',
      role: '',
      storeCode: '',
      file: null
    })
  }, [])

  const template = useMemo(() => {
    return [
      {
        name: 'nickName',
        title: 'Nick Name',
        placeholder: 'Enter nickname'
      },
      {
        name: 'fullName',
        title: 'Full Name',
        placeholder: 'Enter full name'
      },
      {
        name: 'phoneNumber',
        title: 'Phone Number',
        placeholder: 'Enter phone number',
        isPhoneNumber: true
      },
      {
        name: 'role',
        title: 'Role',
        placeholder: 'Select role',
        isDropdown: true,
        dropdownOptions: [
          "SALES",
          "COURIER",
          "CUTTER",
          "TAILOR",
          "PIC",
          "OFFICE"
        ]
      },
      {
        name: 'storeCode',
        title: 'Placement',
        placeholder: 'Select placement',
        isDropdown: true,
        dropdownOptions: checkpoints?.map(obj => obj?.name)
      },
      {
        name: 'file',
        title: 'Profile Picture',
        isFile: true
      }
    ]
  }, [checkpoints])

  const disableSubmit = useMemo(() => {
    if (isSubmitting || !allHasValueObjectChecker(form)) {
      return true
    } return false
  }, [form, isSubmitting])

  const handleSubmit = async (e) => {
    e?.preventDefault()
    try {
      setIsSubmitting(true)
      await addEmployee({
        ...form,
        phoneNumber: '62' + form?.phoneNumber,
        storeCode: checkpoints?.find(obj => obj?.name?.toUpperCase() === form?.storeCode?.toUpperCase())?.code
      })
      setForm({
        nickName: '',
        fullName: '',
        phoneNumber: '',
        role: '',
        storeCode: '',
        file: null
      })
      setTrigger(makeRandomString(5))
      setIsSubmitting(false)
      handleClose()
    } catch (err) {
      console.log(err)
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
      setIsSubmitting(false)
    }
  }

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'Add Employee'}
        iconName={'plus'}
        withIcon
        withCloseButton
        handleClose={handleClose}
        paddingX='2.4rem'
        paddingY='1.6rem'

        isSticky
      />
      <div className={Styles.formContainer}>
        {
          template?.map((each, i) => (
            !each?.isFile
              ?
              <InputField
                key={i}
                title={each?.title}
                value={form[each?.name]}
                setValue={(newVal) => {
                  setForm(prev => {
                    return {
                      ...prev,
                      [each?.name]: newVal
                    }
                  })
                }}
                placeholder={each?.placeholder}
                type={each?.isPhoneNumber ? 'tel' : 'text'}
                isDropdown={each?.isDropdown}
                dropdownOptions={each?.dropdownOptions}
              />
              :
              <UploadField
                key={i}
                title={'Profile Image'}
                cardTitle={''}
                value={form?.file}
                setValue={(newVal) => setForm(prev => {
                  return {
                    ...prev,
                    file: newVal
                  }
                })}
              />
          ))
        }
      </div>
      <div className={Styles.actionsWrapper}>
        <Button
          type='button'
          variant='secondary'
          buttonText={'Clear Form'}
          size='lg'
          onClick={() => {
            setForm({
              nickName: '',
              fullName: '',
              phoneNumber: '',
              role: '',
              file: null
            })
          }}
        />
        <Button
          type='button'
          variant='primary'
          buttonText={isSubmitting ? 'Submitting data...' : 'Submit'}
          size='lg'
          disabled={disableSubmit}
          onClick={handleSubmit}
        />
      </div>

      {error
        &&
        <ModalWrapper
          show={error}
          setShow={() => {
            setError(null)
          }
          }
        >
          <WideErrorModal
            code={error?.code || 500}
            error={error?.message || 'Something went wrong!'}
            withButton
            onClose={() => {
              setError(null)
            }
            }
          />
        </ModalWrapper>

      }
    </div>
  )
}