import LoginLayout from '@Organism/Login'
import Styles from './style.module.scss'

export default function LoginPage() {

  return (
    <div className={Styles.container}>
      <LoginLayout />
    </div>
  )
}