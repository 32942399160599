import Button from "@Atom/Button";
import Radio from "@Atom/Radio";
import { fileBaseUrl } from "@Config/api";
import { allHasValueObjectChecker } from "@Helpers/allHasValueObjectChecker";
import { capitalizeWords } from "@Helpers/capitalizeWords";
import { omitKeys } from "@Helpers/omitKey";
import { priceFormat } from "@Helpers/priceFormat";
import { splitCamelCase } from "@Helpers/splitCamelCase";
import useQuery from "@Hooks/useQuery";
import CartItemDetailsCard from "@Molecule/CartItemDetailsCard";
import CustomerOrderCard, { PerSize } from "@Molecule/CustomerOrderCard";
import InputField from "@Molecule/InputField";
import MeasurementField from "@Molecule/MeasurementField";
import RadioField from "@Molecule/RadioField";
import ValueOnlyField from "@Molecule/ValueOnlyField";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import { getInvoices } from "@Services/invoice";


export default function CartLayout({
  setShowProceed,
  isSure,
  defaultSync,
  isLoading,
  setFormToSend,
  setIdsToRemove,
}) {
  const [mode, setMode] = useState("cart");

  const navigate = useNavigate();
  const query = useQuery();


  const defaultCart = localStorage["cart"]
    ? JSON.parse(localStorage["cart"])
    : [];

  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [list, setList] = useState(defaultCart);

  const defaultAllCustomers = useMemo(() => {
    return localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart"))?.filter(obj => obj?.sizes?.filter(s => s?.items?.length)?.length) : []
  }, []);

  const [allCustomers, setAllCustomers] = useState(defaultAllCustomers)

  const defaultOrderToProceed = useMemo(() => {
    const cID = query.get('c') || ''
    if (cID) {
      setMode("summary");
      return allCustomers?.find(obj => obj?.customerCode === cID);
    } else {
      return null
    }
  }, [allCustomers, query])

  const [orderToProceed, setOrderToProceed] = useState(defaultOrderToProceed);

  // const [orderCategory, setOrderCategory] = useState('Normal')

  const [availableInvoices, setAvailableInvoices] = useState([])

  useEffect(() => {
    if (orderToProceed?.customerWhatsApp || orderToProceed?.customerName) {
      fetchInvoices(orderToProceed?.customerWhatsApp || orderToProceed?.customerName);
    }
  }, [orderToProceed])

  useEffect(() => {
    console.log(availableInvoices, '<<< availableInvoices');
  }, [availableInvoices])

  const fetchInvoices = useCallback((search) => {
    getInvoices(1, 10, search, "", "", "MANUAL-INVOICE")
    .then((resp) => {
      setAvailableInvoices(resp.data.invoices);
    })
  }, [])

  const isCheckedAll = useMemo(() => {
    for (let i = 0; i < list.length; i++) {
      if (!checkedItems?.find((obj) => obj?.id === list[i]?.id)) {
        return false;
      }
    }
    return true;
  }, [checkedItems, list]);

  // eslint-disable-next-line no-unused-vars
  const handleCheckAll = useCallback(() => {
    if (!isCheckedAll) {
      setCheckedItems(list);
    } else {
      setCheckedItems([]);
    }
  }, [isCheckedAll, list]);

  // eslint-disable-next-line no-unused-vars
  const subTotal = useMemo(() => {
    let nominal = 0;
    for (let i = 0; i < checkedItems.length; i++) {
      nominal += checkedItems[i]?.price || 0;
    }
    return nominal;
  }, [checkedItems]);

  const [summaryForm, setSummaryForm] = useState({
    dueDate: "",
    // customerName: '',
    // customerAddress: '',
    // customerWhatsApp: '',
    // customerEmail: '',
    // customerFrontPhoto: null,
    // customerBackPhoto: null,
    // customerSidePhoto: null,
    // paymentType: 'FULL-PAYMENT',
    // installmentAmount: "",
    paymentMethod: "CASH",
    paymentType: "PARTLY-PAYMENT",
    bankName: "",
    accountNumber: "",
    orderCategory: "NORMAL",
    invoiceId: ""
  });

  const handleChangeSummaryForm = useCallback((newVal, name) => {
    setSummaryForm((f) => ({ ...f, [name]: newVal }));
  }, []);

  // useEffect(()=> {
  //   // if(orderToProceed)
  //   handleChangeSummaryForm(orderToProceed?.customerName, 'customerName')
  //   handleChangeSummaryForm(orderToProceed?.customerAddress, 'customerAddress')
  //   handleChangeSummaryForm(orderToProceed?.customerWhatsApp, 'customerWhatsApp')
  //   handleChangeSummaryForm(orderToProceed?.customerWhatsApp, 'customerWhatsApp')
  // }, [])

  // eslint-disable-next-line no-unused-vars
  const paymentOptions = useMemo(() => {
    // return defaultSync?.type?.map((obj) => {
    //   return {
    //     name: capitalizeWords(obj),
    //     value: obj,
    //   };
    // });
    return [
      {
        name: 'Partly Payment',
        value: 'PARTLY-PAYMENT',
      },
      {
        name: 'Full Payment',
        value: 'FULL-PAYMENT',
      },
    ]
  }, [
    // defaultSync
  ]);

  const paymentMethodOptions = useMemo(() => {
    return defaultSync?.method?.map((obj) => {
      return {
        name: capitalizeWords(obj),
        value: obj,
      };
    });
  }, [defaultSync?.method]);

  const priorityCategories = useMemo(() => {
    return ["NORMAL", "PRIORITY", "SUPER PRIORITY"];
  }, []);

  const pricingTotal = useMemo(() => {
    let total = 0;
    if (!orderToProceed) {
      return 0;
    }
    const sizes = orderToProceed?.sizes;
    for (let i = 0; i < sizes.length; i++) {
      const items = sizes[i]?.items;
      for (let j = 0; j < items.length; j++) {
        const fabrics = items[j]?.fabrics;
        for (let k = 0; k < fabrics.length; k++) {
          total += +fabrics[k]?.price;
        }
      }
    }
    return total;
  }, [orderToProceed]);


  const summaryFormTemplate = useMemo(() => {
    return [
      {
        title: "Customer Details",
        component: (
          <div className={Styles.customerInfo}>
            <ValueOnlyField
              title={"Customer Name"}
              // placeholder={'Customer name'}
              value={orderToProceed?.customerName}
            // setValue={(newVal) => handleChangeSummaryForm(newVal, 'customerName')}
            />
            <ValueOnlyField
              title={"Customer Address"}
              // placeholder={'Customer address'}
              value={orderToProceed?.customerAddress}
            // setValue={(newVal) => handleChangeSummaryForm(newVal, 'customerAddress')}
            />
            <ValueOnlyField
              // type='tel'
              title={"Customer WhatsApp"}
              // placeholder={'Customer WhatsApp'}
              value={orderToProceed?.customerWhatsApp}
            // setValue={(newVal) => handleChangeSummaryForm(newVal, 'customerWhatsApp')}
            />
            <ValueOnlyField
              title={"Customer Email"}
              // placeholder={'example@gmail.com'}
              value={orderToProceed?.customerEmail}
            // setValue={(newVal) => handleChangeSummaryForm(newVal, 'customerEmail')}
            />
          </div>
        ),
      },
      {
        title: "Items",
        component: (
          <div className={Styles.sumItemsWrapper}>
            {orderToProceed?.sizes?.map((s, iS) => (
              <PerSize
                s={s}
                key={iS}
                onDeleteItem={({ itemID }) => {
                  const newOrderToProceed = {
                    ...orderToProceed,
                    sizes: orderToProceed?.sizes?.map((size) => {
                      if (size?.code === s?.code) {
                        return {
                          ...size,
                          items: size?.items?.filter((i) => i?.id !== itemID),
                        };
                      } else {
                        return size;
                      }
                    }),
                  };
                  setOrderToProceed(newOrderToProceed);
                }}
                hideOnNoItem
                noEdit
                noDelete={s?.items?.length < 2}
                noActions={true}
              />
            ))}
          </div>
        ),
      },
      {
        title: "Due Date",
        component: (
          <InputField
            type="date"
            value={summaryForm?.dueDate}
            setValue={(newVal) => handleChangeSummaryForm(newVal, "dueDate")}
          />
        ),
      },
      {
        title: "Order Category",
        component: (
          <div className={Styles.categories}>
            {priorityCategories?.map((c, iC) => (
              <div key={iC} className={Styles.category}>
                <Radio
                  isChecked={
                    c?.toLowerCase() ===
                    summaryForm?.orderCategory?.toLowerCase()
                  }
                  setValue={() => handleChangeSummaryForm(c, "orderCategory")}
                />
                <span>{capitalizeWords(c)}</span>
              </div>
            ))}
          </div>
        ),
      },
      {
        title: "Price Details",
        component: (
          <div className={Styles.pricing}>
            <div className={Styles.prices}>
              {orderToProceed?.sizes?.map((s, iS) => (
                <div key={iS} className={Styles.perSize}>
                  {s?.items?.map((i, iI) => (
                    <div key={iI} className={Styles.perItem}>
                      {!i?.isReadyToWear && !i?.isFabricOnly && !i?.isAlter ? (
                        <div className={Styles.withTitle}>
                          <span>{i?.label}</span>
                          <div className={Styles.materials}>
                            {i?.fabrics?.map((f, iF) => (
                              <div key={iF} className={Styles.fabric}>
                                <span>
                                  {/* {f?.type}: {f?.name} */}
                                  {f?.code}
                                </span>
                                <span>Rp{priceFormat(f?.price)}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className={Styles.noTitle}>
                          <span>
                            {i?.label} - {i?.fabrics[0]?.name}
                          </span>
                          <span>Rp{priceFormat(i?.fabrics[0]?.price)}</span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className={Styles.divider} />
            <div className={Styles.pricingTotal}>
              <span>Total</span>
              <span>Rp{priceFormat(pricingTotal)}</span>
            </div>
          </div>
        ),
      },
      {
        title: 'Invoice ID',
        component: (
          <div>
            <InputField
              value={summaryForm?.invoiceId}
              placeholder={'Invoice ID'}
              isDropdown
              dropdownOptions={availableInvoices?.map((obj) => `${obj.code}`)}
              setValue={(newVal) => handleChangeSummaryForm(newVal, "invoiceId")}
            />
          </div>
        )
      },
      {
        title: "Payment Method",
        component: (
          <div className={Styles.payment}>
            {summaryForm?.invoiceId?.length > 0 ? 
              <InputField
                value={summaryForm?.paymentType}
                disabled
              />
             : 
              <RadioField
                title={'Type'}
                value={summaryForm?.paymentType}
                setValue={(newVal) => handleChangeSummaryForm(newVal, 'paymentType')}
                options={paymentOptions}
              />
            }
          
            <div className={Styles.divider} />
            {summaryForm?.invoiceId?.length >0 ? 
                <InputField
                  value={summaryForm?.paymentMethod}
                  disabled
                />
              : 
                <RadioField
                  title={"Method"}
                  value={summaryForm?.paymentMethod}
                  setValue={(newVal) =>
                    handleChangeSummaryForm(newVal, "paymentMethod")
                  }
                  options={paymentMethodOptions}
                />
              }
          </div>
        ),
      },
      {
        title: "Bank Name",
        component: (
          <InputField
            // type="date"
            disabled={summaryForm?.paymentMethod !== 'TRANSFER' || summaryForm?.invoiceId?.length > 0}
            value={summaryForm?.bankName}
            placeholder={'Bank name'}
            setValue={(newVal) => handleChangeSummaryForm(newVal, "bankName")}
          />
        ),
      },
      {
        title: "Account Number",
        component: (
          <InputField
            // type="date"
            disabled={summaryForm?.paymentMethod !== 'TRANSFER' || summaryForm?.invoiceId?.length > 0}
            value={summaryForm?.accountNumber}
            placeholder={'Account number'}
            setValue={(newVal) => handleChangeSummaryForm(newVal, "accountNumber")}
          />
        ),
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChangeSummaryForm, orderToProceed, paymentMethodOptions, paymentOptions, pricingTotal, priorityCategories, summaryForm?.accountNumber, summaryForm?.bankName, summaryForm?.dueDate, summaryForm?.orderCategory, summaryForm?.paymentMethod, summaryForm?.paymentType, summaryForm?.invoiceId, availableInvoices.length]);

  const receiptTemplate = useMemo(() => 
    [
      {
        title: "Customer Details",
        component: (
          <div className={Styles.customerInfo}>
            <ValueOnlyField
              title={"Customer Name"}
              // placeholder={'Customer name'}
              value={orderToProceed?.customerName}
            // setValue={(newVal) => handleChangeSummaryForm(newVal, 'customerName')}
            />
            <ValueOnlyField
              title={"Customer Address"}
              // placeholder={'Customer address'}
              value={orderToProceed?.customerAddress}
            // setValue={(newVal) => handleChangeSummaryForm(newVal, 'customerAddress')}
            />
            <ValueOnlyField
              // type='tel'
              title={"Customer WhatsApp"}
              // placeholder={'Customer WhatsApp'}
              value={orderToProceed?.customerWhatsApp}
            // setValue={(newVal) => handleChangeSummaryForm(newVal, 'customerWhatsApp')}
            />
            <ValueOnlyField
              title={"Customer Email"}
              // placeholder={'example@gmail.com'}
              value={orderToProceed?.customerEmail}
            // setValue={(newVal) => handleChangeSummaryForm(newVal, 'customerEmail')}
            />
          </div>
        ),
      },
      {
        title: "Items",
        component: (
          <div className={Styles.sumItemsWrapper}>
            {orderToProceed?.sizes?.map((s, iS) => (
              <PerSize s={s} key={iS} noActions noDelete noEdit />
            ))}
          </div>
        ),
      },
      {
        title: "Due date",
        component: (
          <span className={Styles.value}>
            {summaryForm?.dueDate
              ? moment(new Date(summaryForm?.dueDate))?.format("LL")
              : "-"}
          </span>
        ),
      },
      {
        title: "Order Category",
        component: (
          <span className={Styles.value}>
            {capitalizeWords(summaryForm?.orderCategory)}
          </span>
        ),
      },
      {
        title: "Price Details",
        component: (
          <div className={Styles.pricing}>
            <div className={Styles.prices}>
              {orderToProceed?.sizes?.map((s, iS) => (
                <div key={iS} className={Styles.perSize}>
                  {s?.items?.map((i, iI) => (
                    <div key={iI} className={Styles.perItem}>
                      {!i?.isReadyToWear && !i?.isFabricOnly && !i?.isAlter ? (
                        <div className={Styles.withTitle}>
                          <span>{i?.label}</span>
                          <div className={Styles.materials}>
                            {i?.fabrics?.map((f, iF) => (
                              <div key={iF} className={Styles.fabric}>
                                <span>
                                  {f?.type}: {f?.name}
                                </span>
                                <span>Rp{priceFormat(f?.price)}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className={Styles.noTitle}>
                          <span>
                            {i?.label} - {i?.fabrics[0]?.name}
                          </span>
                          <span>Rp{priceFormat(i?.fabrics[0]?.price)}</span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className={Styles.divider} />
            <div className={Styles.pricingTotal}>
              <span>Total Price</span>
              <span>Rp{priceFormat(pricingTotal)}</span>
            </div>
          </div>
        ),
      },
  
      // {
      //   title: 'Price Details',
      //   isCustomRender: true,
      //   component:
      //     <PriceDetailsCard
      //       items={checkedItems}
      //       subTotal={subTotal}
      //     />
      // },
      {
        title: "Invoice ID",
        component:(
          <div className={Styles.invoice}>
              <h3 className={Styles.title}>{summaryForm?.invoiceId}</h3>
          </div>
        )
      },
      {
        title: "Payment",
        component: (
          <div className={Styles.payment}>
            <div>
              <span className={Styles.title}>Type</span>
              <span>
                {paymentOptions?.find(
                  (obj) => obj?.value === summaryForm?.paymentType
                )?.name || "-"}
              </span>
            </div>
            <div className={Styles.divider} />
            <div>
              <span className={Styles.title}>Method</span>
              <span>
                {paymentMethodOptions?.find(
                  (obj) => obj?.value === summaryForm?.paymentMethod
                )?.name || "-"}
              </span>
            </div>
            {
              summaryForm?.paymentMethod === 'TRANSFER'
              &&
              <div>
                <span className={Styles.title}>Bank Account</span>
                <span>
                  {summaryForm?.bankName || ''} - {summaryForm?.accountNumber || ''}
                </span>
              </div>
  
            }
          </div>
        ),
      },
  ], [orderToProceed?.customerAddress, orderToProceed?.customerEmail, orderToProceed?.customerName, orderToProceed?.customerWhatsApp, orderToProceed?.sizes, paymentMethodOptions, paymentOptions, pricingTotal, summaryForm?.accountNumber, summaryForm?.bankName, summaryForm?.dueDate, summaryForm?.orderCategory, summaryForm?.paymentMethod, summaryForm?.paymentType]);

  useEffect(() => {
    if (summaryForm?.invoiceId?.length > 0) {
    
      if (availableInvoices && availableInvoices.length > 0) {
        const getInvoice = availableInvoices.filter((v) => v.code === summaryForm.invoiceId)
  
        if (getInvoice[0]) {
          setSummaryForm((f) => ({ 
            ...f,
            paymentMethod: getInvoice[0]?.paymentMethod || 'CASH',
            paymentType: getInvoice[0]?.paymentType || 'PARTLY-PAYMENT',
            bankName: getInvoice[0]?.bankName || '',
            accountNumber: getInvoice[0]?.accountNumber || ''
          }));
        }
      }
    }
  }, [availableInvoices, summaryForm.invoiceId])

  // const [measurementFormTemplate, setMeasurementFormTemplate] = useState(

  // )

  const sizingTemplate = useMemo(() => {
    if (selectedItem) {
      const all = selectedItem?.measurements?.map((obj) => {
        return {
          name: obj?.name,
          title: obj?.title,
          placeholder: splitCamelCase(obj?.name),
          denom: "cm",
          value:
            obj?.value && obj?.value?.includes(" ")
              ? obj?.value?.split(" ")[0]
              : obj?.value,
        };
      });
      if (!selectedItem?.type?.toLowerCase()?.includes("regular")) {
        return all?.filter(
          (obj) => !obj?.name?.toLowerCase()?.includes("length")
        );
      }
      return all;
    }
    return [];
  }, [selectedItem]);

  const [measurementFormTemplate, setMeasurementFormTemplate] = useState(
    sizingTemplate || []
  );

  useEffect(() => {
    if (sizingTemplate) {
      setMeasurementFormTemplate(sizingTemplate);
    }

    // }
  }, [sizingTemplate]);

  const refetchMeasurementForm = useCallback(() => {
    const newTemplate = measurementFormTemplate?.map((obj) => {
      const found = selectedItem?.measurements?.find(
        (item) => item?.name === obj?.name
      );
      if (found) {
        return {
          ...obj,
          value: found?.value,
        };
      }
      return obj;
    });
    setMeasurementFormTemplate(newTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem?.measurements]);

  useEffect(() => {
    if (selectedItem) {
      // refetchMeasurementForm()
    }
  }, [refetchMeasurementForm, selectedItem]);

  // eslint-disable-next-line no-unused-vars
  const disableProceed = useMemo(() => {
    if (!checkedItems?.length) {
      return true;
    }
    return false;
  }, [checkedItems?.length]);

  const disableContinue = useMemo(() => {
    if (allHasValueObjectChecker(omitKeys([
      'bankName',
      'accountNumber',
      'invoiceId'
    ], summaryForm))) {
      if (summaryForm?.paymentMethod === 'TRANSFER') {
        if (!summaryForm?.bankName || !summaryForm?.accountNumber) {
          return true
        } return false
      }
      return false
    }
    return true;
    // if (summaryForm?.paymentType?.toLowerCase() === 'installment') {
    //   if (allHasValueObjectChecker(summaryForm) && summaryForm?.installmentAmount > 0) {
    //     return false
    //   }
    //   return true
    // } else {
    //   return !allHasValueObjectChecker(omitKey('installmentAmount', summaryForm));
    // }

    // return true
  }, [summaryForm]);



  const proceedOrder = useCallback(() => {
    let dataToSend = {
      customerName: orderToProceed?.customerName,
      customerPhoneNumber: orderToProceed?.customerWhatsApp,
      customerEmail: orderToProceed?.customerEmail,
      customerAddress: orderToProceed?.customerAddress,
      customerCode: orderToProceed?.customerCode || "",
      paymentMethod: summaryForm?.paymentMethod || '',
      paymentType: summaryForm?.paymentType || '',
      bankName: summaryForm?.bankName || '',
      accountNumber: summaryForm?.accountNumber || '',
      items: [],
      dueDate: moment(new Date(summaryForm?.dueDate))?.format("YYYY/MM/DD"),
      priority: summaryForm?.orderCategory?.split(' ')[0],
    };

    if (summaryForm?.invoiceId && summaryForm.invoiceId?.length > 0) {
      dataToSend['invoiceId'] = summaryForm.invoiceId;
    }

    for (let i = 0; i < orderToProceed?.sizes.length; i++) {
      const size = orderToProceed?.sizes[i];
      let items = [];
      for (let j = 0; j < size.items.length; j++) {
        const item = size.items[j];
        let itemToSend = {
          textiles: [],
          pattern: {
            ...size.measurements.reduce((acc, obj) => {
              acc[obj.name] = obj.value;
              return acc;
            }, {}),
            code: size?.code || "",
            name: size.sizeName,
            photos: size.photos?.filter(obj => obj?.uri).map((el) => ({ ...el, url: el.uri })) || [],
          },
          category: !item?.isAlter ? (item?.category || item?.label) : item?.fabrics[0]?.type,
          // name: item.fabrics[0].name,
          name: item.isReadyToWear
            ? "READY-TO-WEAR"
            : item.isAlter
              ? item?.fabrics[0]?.name || 'ALTER'
              : item.label,
          type: item.isReadyToWear
            ? "READY-TO-WEAR"
            : item.isAlter
              ? "ALTER"
              : item.label,
          // description: "test",
          // notes: item.notes,
          notes: item.notes || item?.label,
        };
        for (let k = 0; k < item.fabrics.length; k++) {
          const fabric = item.fabrics[k];
          if (item?.isAlter) {
            itemToSend?.textiles.push({
              price: fabric?.price || 0,
              qty: fabric?.qty,
              type: item?.isAlter ? "ALTER" : fabric?.type,
            });
          } else {
            itemToSend?.textiles?.push({
              name: fabric?.name,
              price: fabric?.price || 0,
              qty: `${fabric?.qty}`?.replaceAll('x', ''),
              imageUrl: fabric?.imgUrl?.replaceAll(fileBaseUrl, ''),
              code: fabric.code,
              _id: fabric?._id || "",
              fabric: fabric?.fabric || "",
              type: item?.isAlter ? "ALTER" : fabric?.type,
            });
          }
        }
        items.push(itemToSend);
      }
      dataToSend.items.push(items);
    }
    return { ...dataToSend, items: dataToSend.items.flat(2) };
  }, [orderToProceed?.customerAddress, orderToProceed?.customerCode, orderToProceed?.customerEmail, orderToProceed?.customerName, orderToProceed?.customerWhatsApp, orderToProceed?.sizes, summaryForm?.accountNumber, summaryForm?.bankName, summaryForm?.dueDate, summaryForm?.orderCategory, summaryForm?.paymentMethod, summaryForm?.paymentType, summaryForm?.invoiceId,])


  const handleDeleteItem = useCallback((itemID, sizeID, perCustomerData) => {
    const cartData = allCustomers?.map(perC => {
      if (perC?.customerCode === perCustomerData?.customerCode) {
        return {
          ...perC,
          sizes: perC?.sizes?.map(perS => {
            if (perS?.id === sizeID) {
              return {
                ...perS,
                items: perS?.items?.filter(obj => obj?.id !== itemID)
              }
            }
            return { ...perS }
          })
        }
      }
      return { ...perC }
    })

    setAllCustomers(cartData)
    localStorage?.setItem('cart', JSON.stringify(cartData))
  }, [allCustomers])

  const template = useMemo(() => {
    switch (mode) {
      case "cart":
        return {
          backNavTitle: "Back",
          onClickBackNav: () => {
            navigate(-1);
          },
          title: "Cart",
          component: (
            <div className={Styles.allCustomers}>
              {allCustomers?.filter(obj => obj?.sizes?.filter(s => s?.items?.length)?.length)?.map((c, iC) => (
                <CustomerOrderCard
                  key={iC}
                  data={c}
                  withButton
                  buttonText={"Proceed Transaction"}
                  onClickButton={() => {
                    setOrderToProceed(c);
                    setMode("summary");
                  }}
                  onDeleteItem={({ itemID, sizeID }) => {
                    handleDeleteItem(itemID, sizeID, c)
                  }}
                  noActions
                  noEdit
                />
              ))}
            </div>
          ),
        };
      case "measurement":
        return {
          backNavTitle: "Back to Cart",
          onClickBackNav: () => setMode("cart"),
          title: "Size Measure",
          component: (
            <div className={Styles.measurement}>
              <div className={Styles.info}>
                <img src={selectedItem?.img} alt="" />
                <div>
                  <span>{selectedItem?.name}</span>
                  <span>
                    {selectedItem?.isMeasured
                      ? "Size measured"
                      : "Size has not been measured"}
                  </span>
                  <span>Rp {priceFormat(selectedItem?.price || 0)}</span>
                </div>
              </div>
              <div className={Styles.form}>
                <h4>Sizing</h4>
                <div className={Styles.field}>
                  {measurementFormTemplate?.map((each, i) => (
                    <MeasurementField
                      key={i}
                      title={each?.title}
                      value={each?.value}
                      setValue={(newVal) => {
                        const newTemplate = measurementFormTemplate?.map(
                          (obj) => {
                            if (obj?.name === each?.name) {
                              return {
                                ...obj,
                                value: newVal,
                              };
                            }
                            return obj;
                          }
                        );
                        setMeasurementFormTemplate(newTemplate);
                      }}
                      placeholder={each?.placeholder}
                      denom={each?.denom}
                      extraTitleLabel={
                        each?.label?.toLowerCase()?.includes("sleeves") ? (
                          <div className={Styles.radios}>
                            <div
                              onClick={() => {
                                // const newList = list?.map(obj => {
                                //   if (obj?.id === selectedItem?.id) {
                                //     return {
                                //       ...obj,
                                //       sleevesType: 'long'
                                //     }
                                //   }
                                //   return obj
                                // })
                                // setList(newList)
                                setSelectedItem((obj) => {
                                  return {
                                    ...obj,
                                    sleevesType: "long",
                                  };
                                });
                              }}
                            >
                              <Radio
                                isChecked={selectedItem?.sleevesType === "long"}
                              />
                              <span>Long Sleeve</span>
                            </div>
                            <div
                              onClick={() => {
                                setSelectedItem((obj) => {
                                  return {
                                    ...obj,
                                    sleevesType: "short",
                                  };
                                });
                              }}
                            >
                              <Radio
                                isChecked={
                                  selectedItem?.sleevesType === "short"
                                }
                              />
                              <span>Short Sleeve</span>
                            </div>
                          </div>
                        ) : undefined
                      }
                    />
                  ))}
                </div>
              </div>
              <Button
                buttonText="Submit size"
                size="lg"
                onClick={() => {
                  const newMeasurement = selectedItem?.measurements?.map(
                    (obj) => {
                      const found = measurementFormTemplate?.find(
                        (template) => template?.name === obj?.name
                      );
                      if (found) {
                        return { ...obj, value: found?.value };
                      }
                      return obj;
                    }
                  );

                  const newList = list?.map((obj) => {
                    if (obj?.id === selectedItem?.id) {
                      return {
                        ...obj,
                        measurements: newMeasurement,
                        isMeasured: true,
                        sleevesType: selectedItem?.sleevesType,
                      };
                    }
                    return obj;
                  });
                  // const newList = list?.map(obj => {
                  //   if (obj?.id === selectedItem?.id) {
                  //     return {
                  //       ...selectedItem
                  //     }
                  //   }
                  //   return obj
                  // })
                  setList(newList);
                  setMode("cart");
                }}
              />
            </div>
          ),
        };
      case "summary":
        return {
          backNavTitle: "Back to Cart",
          onClickBackNav: () => setMode("cart"),
          title: "Order Summary",
          component: (
            <div className={Styles.summary}>
              {/* <div className={Styles.items}>
                {checkedItems?.map((item, i) => (
                  <CartItemDetailsCard
                    key={i}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    item={item}
                    setSelectedItem={setSelectedItem}
                    setMode={setMode}
                    noChekcbox
                    noActions
                    denom="cm"
                  />
                ))}
              </div> */}
              {summaryFormTemplate
                ?.filter(obj => summaryForm?.paymentMethod !== 'TRANSFER' ? obj?.title !== 'Bank Name' && obj?.title !== 'Account Number' : obj)
                ?.map((template, i) => (
                  <div
                    key={i}
                    className={`${Styles.formCard} ${template?.isCustomRender ? Styles.customRender : ""
                      }`}
                  >
                    <h4>{template?.title}</h4>
                    {template?.component}
                  </div>
                ))}
              <Button
                buttonText="Continue"
                size="lg"
                onClick={() => setMode("receipt")}
                disabled={disableContinue}
              />
            </div>
          ),
        };
      case "receipt":
        return {
          backNavTitle: "Back to Order Summary",
          onClickBackNav: () => setMode("summary"),
          title: "Receipt",
          component: (
            <div className={Styles.receipt}>
              <div className={Styles.items}>
                {checkedItems?.map((item, i) => (
                  <CartItemDetailsCard
                    key={i}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    item={item}
                    setSelectedItem={setSelectedItem}
                    setMode={setMode}
                    noChekcbox
                    noActions

                    denom="cm"
                  />
                ))}
              </div>
              {receiptTemplate?.map((template, i) => (
                <div
                  key={i}
                  className={`${Styles.card} ${template?.isCustomRender ? Styles.customRender : ""
                    }`}
                >
                  <h4>{template?.title}</h4>
                  {template?.component}
                </div>
              ))}
              <div className={Styles.actions}>
                <Button
                  variant="primary"
                  buttonText="Proceed Order"
                  size="lg"
                  onClick={() => {
                    setFormToSend(proceedOrder());
                    setIdsToRemove([orderToProceed?.customerCode]);
                    setShowProceed(true);
                  }}
                />
                {/* <Button
                  variant="secondary"
                  buttonText="Print Receipt"
                  size="lg"
                /> */}
              </div>
            </div>
          ),
        };
      default:
        return {
          backNavTitle: "no mode",
          onClickBackNav: () => { },
          title: "no mode",
          component: <div>no mode</div>,
        };
    }
  }, [allCustomers, checkedItems, disableContinue, handleDeleteItem, list, measurementFormTemplate, mode, navigate, orderToProceed?.customerCode, proceedOrder, receiptTemplate, selectedItem?.id, selectedItem?.img, selectedItem?.isMeasured, selectedItem?.measurements, selectedItem?.name, selectedItem?.price, selectedItem?.sleevesType, setFormToSend, setIdsToRemove, setShowProceed, summaryForm?.paymentMethod, summaryFormTemplate]);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    if (!isSure) {
      scrollingTop();
    }
  }, [mode, isSure]);

  if (isSure) {
    return (
      <div className={Styles.sureContainer}>
        <img src={Images.SIMULATION_BANNER} alt="" />
        <div>
          <h3>Order On Process</h3>
          <span>tap the button below to go back to products page</span>
          <Button
            buttonText="Back to products"
            withIcon
            iconName={"arrow-long-right"}
            iconPlacement="end"
            size="lg"
            onClick={() => navigate("/product")}
          />
        </div>
        <img src={Images.READY_TO_WEAR_BANNER} alt="" />
      </div>
    );
  }

  if (isLoading) {
    return <div ref={targetElement}>loading...</div>;
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <div className={Styles.header}>
        <Button
          variant="tertiary"
          buttonText={template?.backNavTitle}
          onClick={template?.onClickBackNav}
          size="lg"
          fixedPadding={"0px"}
          borderRadius="0px"
          withIcon
          iconName={"chevron-left"}
          iconPlacement="start"
          customIconSize={"16"}
          disableActive
        />
        <h3>{template?.title}</h3>
      </div>
      {template?.component}
    </div>
  );
}
