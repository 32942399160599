import Button from '@Atom/Button'
import ItemTitle from '@Atom/ItemTitle'
import RekhasBG from '@Atom/RekhasBG'
import { priceFormat } from '@Helpers/priceFormat'
import useOutsideClick from '@Hooks/useOutsideClick'
import useWindowSize from '@Hooks/useWindowSize'
import StackedItemInfo from '@Molecule/StackedItemInfo'
import TableSet from '@Molecule/TableSet'
import ItemListPopup from '@Molecule/_popups/ItemListPopup'
import Images from '@Theme/Images'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Styles from './style.module.scss'

export default function FeeListLayout({
  setSelectedEmployee = () => { },
  defaultData,
  isLoading
}) {

  const [selectedOrder, setSelectedOrder] = useState(null)

  const handleShowList = useCallback((e, row) => {
    e.stopPropagation()
    if (row?.id !== selectedOrder?.id) {
      setSelectedOrder(row)
    } else {
      setSelectedOrder(null)
    }
  }, [selectedOrder])

  const { width } = useWindowSize()

  const itemListPopupRef = useRef()
  // eslint-disable-next-line no-unused-vars
  const itemListPopupOutsideClick = useOutsideClick(itemListPopupRef);


  // TODO: nambahin paidhistory, status per item + tanggl, date from & to, bonus fee history 
  // * referensi liat di details modal

  const template = useMemo(() => {
    return ({
      data: defaultData?.fees?.map(obj => {
        return {
          id: obj?._id,
          lastUpdate: obj?.lastUpdate ? moment(new Date(obj?.lastUpdate)).format('L') : '',
          name: obj?.name,
          phoneNumber: obj?.phoneNumber,
          address: obj.address,
          customerName: '-',
          customerPhoneNumber: '-',
          assignedDateFrom: '-',
          assignedDateTo: '-',
          unpaid: obj?.totalFee,
          paid: 0,
          itemsArr: obj?.item?.length ? obj?.item?.map(item => {
            return {
              ...item,
              name: item?.name,
              category: '',
              length: '- Meters',
              measurements: [],
              paymentPercentage: 0,
              itemStatus: obj?.status || 'ONPROCESS',
              feePaymentHistory: [],
              bonusPaymentHistory: []
            }
          }) : []
        }
      }),
   
      columns: [
        {
          name: 'lastUpdate',
          label: 'Last Update',
          minWidth: width <= 768 ? '108px' : '124px'
        },
        {
          name: 'name',
          label: 'Tailor',
          // minWidth: '429px',
          // maxWidth: '453px',
          maxWidth: width <= 768 ? '176px' : '313px',
          minWidth: width <= 768 ? '176px' : '313px',
          renderData: (row) => (
            <StackedItemInfo
              title={row?.name}
              label={row?.phoneNumber || '-'}
              picture={row?.img || Images.AVA_DEFAULT}
              imgRadius='50%'
              titleWidth={width <= 768 ? '150px' : '100%'}
            />
          )


        },
        {
          name: 'itemsArr',
          label: 'Items',
          hideOnMobile: true,
          minWidth: '246px',
          maxWidth: '270px',
          renderData: (row) => (
            row?.itemsArr?.length > 1
              ? <div
                className={Styles.itemsIndicator}
              >
                <span>{row?.itemsArr?.length} Items</span>
                <Button
                  buttonText='Item List'
                  size='sm'
                  variant='secondary'
                  iconName={selectedOrder && selectedOrder?.id === row?.id ? 'chevron-up' : 'chevron-down'}
                  iconPlacement='end'
                  borderRadius='4px'
                  fixedPadding={'2px 8px'}
                  withIcon
                  disableActive
                  onClick={(e) => handleShowList(e, row)}
                />
              </div>
              : <ItemTitle
                name={row?.itemsArr[0]?.name || 'no item'}
                category={row?.itemsArr[0]?.category || ''}
                length={row?.itemsArr[0]?.length || '- m'}
                type={row?.itemsArr[0]?.type || ''}
              />

          ),
          renderPopup: (row) => (
            (selectedOrder && selectedOrder?.id === row?.id) && (
              <div ref={itemListPopupRef} className={Styles.popupWrapper}>
                <ItemListPopup
                  items={row?.itemsArr}
                />
              </div>
            )
          ),
          // popupWrapperClassName: Styles.popupWrapper,
          popupPositioning: {
            left: '-100px',
            top: '20px'
          }

        },
        {
          name: 'unpaid',
          label: 'Unpaid',
          hideOnMobile: true,
          renderData:
            (row) => (<span>Rp {row?.unpaid ? priceFormat(row?.unpaid) : '-'}</span>)
        },
        {
          name: 'paid',
          label: 'Paid',
          hideOnMobile: true,
          renderData:
            (row) => (<span>Rp {row?.paid ? priceFormat(row?.paid) : '-'}</span>)

        },
        // {
        //   name: 'paymentPercentage',
        //   label: 'Payment',
        //   minWidth: '150px',
        //   hideOnMobile: true,
        //   renderData: (row) => (
        //     <Tag
        //       title={row?.paymentPercentage === 50 ? '50%' : 'Paid Off'}
        //       type={row?.paymentPercentage === 50 ? 'yellow' : 'green'}
        //     />
        //   )
        // },
        {
          name: '',
          label: '',
          showOnMobile: true,
          renderData: (row) => (
            <div>
              <Button
                state='icon-only'
                variant='secondary'
                disableActive
                withIcon
                iconName={'list-bullet'}
                size='24'
                fixedPadding={'12px'}
              />
            </div>
          )
        }

      ]
    })
  
  }, [defaultData?.fees, handleShowList, selectedOrder, width])

  useEffect(()=> {
    sessionStorage?.setItem('defaultFees', JSON?.stringify(defaultData?.templateFee))
  }, [defaultData?.templateFee])

  // if (isLoading) {
  //   return (
  //     <>loading</>
  //   )
  // }

  return (
    <RekhasBG>
      <TableSet
        type='fee'
        data={template?.data?.length ? template?.data : []}
        columns={template?.columns}
        onClickRow={(row) => setSelectedEmployee(row)}
        feeSync={defaultData?.templateFee}
      />
    </RekhasBG>
  )
}