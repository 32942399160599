import Avatar from '@Atom/Avatar'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import ModalWrapper from '@Atom/ModalWrapper'
import { fileBaseUrl } from '@Config/api'
import { allHasValueObjectChecker } from '@Helpers/allHasValueObjectChecker'
import { makeRandomString } from '@Helpers/makeRandomString'
import { splitCamelCase } from '@Helpers/splitCamelCase'
import useWindowSize from '@Hooks/useWindowSize'
import InputField from '@Molecule/InputField'
import ItemDetails from '@Molecule/ItemDetails'
import ItemListCard from '@Molecule/ItemListCard'
import PicturesPreviewModal from '@Molecule/_modals/PicturesPreviewModal'
import DetailsModal from '@Molecule/_reusableLayout/DetailsModal'
import { getDelivery, uploadDeliveryProof, getDeliveryById } from '@Services/delivery'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import UploadDeliveryPhotoModal from '@Molecule/_modals/UploadDeliveryPhotoModal'

export default function DeliveryDetailsModal({
  delivery,
  handleClose,
  type = 'default',
  trigger = '',
  setTrigger = () => { }
}) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)
  const [showPreview, setShowPreview] = useState(false)
  const { role } = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : ''
  const [expandChangeStatus, setExpandChangeStatus] = useState(false)

  const [showUpload, setShowUpload] = useState(false)

  const [form, setForm] = useState({
    status: 'DELIVERED',
    photos: [
      { name: 'FOTO BUKTI' }
    ],
    images: null,
    recipientsName: ''
  })

  useEffect(() => {
    setForm({
      status: 'DELIVERED',
      photos: [
        { name: 'FOTO BUKTI' }
      ],
      images: null,
      recipientsName: ''
    })
  }, [])

  const handleChangeForm = (name, newVal) => {
    setForm(prev => {
      return {
        ...prev,
        [name]: newVal
      }
    })
  }

  const [onUpdating, setOnUpdating] = useState(false)

  const fetchData = useCallback(async () => {
    if (delivery?._id) {
      try {
        setIsLoading(true)
        // setData)
        const response = await getDeliveryById(delivery?._id, delivery?.type)
        setData(response?.data)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
  }, [delivery?.code])


  const handleUpdateStatus = useCallback(async (newStatus) => {
    try {
      if (newStatus === 'ON-DELIVERY') {
        setOnUpdating(true)
        await uploadDeliveryProof({ status: 'ON-DELIVERY' }, data?.code)
        setTrigger(makeRandomString(5))
        fetchData()
        setOnUpdating(false)
      } else {
        setOnUpdating(true)
        await uploadDeliveryProof(form, data?.code)
        setExpandChangeStatus(false)
        setTrigger(makeRandomString(5))
        fetchData()
        setOnUpdating(false)
      }
    } catch (err) {
      console.log(err, 'err on update status')
    }
  }, [data?.code, fetchData, form, setTrigger])


  const { width } = useWindowSize()


  useEffect(() => {
    fetchData()
  }, [fetchData])

  const proofs = useMemo(() => {
    return data?.photos?.map(obj => {
      return {
        id: obj?._id,
        imgUrl: fileBaseUrl + obj?.uri
      }
    })

  }, [data?.photos])


  const tagTemplate = useMemo(() => {
    const status = data?.status
    switch (status) {
      case 'ONPROCESS':
        return {
          type: 'yellow',
          title: 'On Process'
        }
      case 'DELIVERED':
        return {
          type: 'green',
          title: 'Delivered'
        }
      case 'READY':
        return {
          type: 'black',
          title: 'Ready'
        }
      case 'SCHEDULED':
        return {
          type: 'blue',
          title: 'Scheduled'
        }
      case 'ON-DELIVERY':
        return {
          type: 'yellow',
          title: 'On Delivery'
        }
      case 'RETURN':
        return {
          type: 'yellow',
          title: 'Returning to workshop'
        }
      default:
        return {
          type: 'yellow',
          title: 'On Process'
        }
    }
  }, [data?.status])

  const contentTemplate = useMemo(() => {
    switch (type) {
      case 'm1':
        return ([
          {
            title: 'Delivery ID',
            component:
              <div className={Styles.regular}>
                {delivery?.code}
              </div>
          },
          {
            title: 'Schedule',
            component:
              <div className={Styles.scheduleWrapper}>
                <Icon icon={'calendar'} size={20} />
                <span>{data?.scheduledAt ? moment(new Date(data?.scheduledAt))?.format('DD/MM/YYYY') : ''}</span>
              </div>
          },
          {
            title: 'Items',
            component:
              data?.items?.length
                ?
                <ItemListCard
                  noOrderID
                  navigateToStockDetails
                  withTagPerItem
                  mappedData={
                    data?.items?.length
                      ?
                      [

                        {
                          id: '',
                          orderID: '',
                          dueDate: '',
                          sizeTypes: data?.items?.map(i => {
                            return {
                              id: i?.pattern?.code || '',
                              isDefault: true,
                              name: i?.pattern?.name || '',
                              items: [
                                {
                                  id: makeRandomString(5),
                                  isReadyToWear: !!i?.type?.includes('READY'),
                                  isAlter: data?.processtextiles?.length && data?.processtextiles[0]?.type === 'ALTER',
                                  label: i?.type,
                                  notes: i?.notes,
                                  fabrics: i?.textiles?.length
                                    ? i?.textiles?.map(t => {
                                      return {
                                        id: t?._id,
                                        type: '',
                                        fabricType: t?.type,
                                        fabricName: t?.name,
                                        fabricCode: t?.code,
                                        img: t?.imageUrl ? fileBaseUrl + t?.imageUrl : ''
                                      }
                                    })
                                    :
                                    (
                                      i.pattern?.measurement?.textiles?.length
                                        ?
                                        i?.pattern?.measurement?.textiles?.map(t => {
                                          return {

                                            id: t?.code,
                                            type: t?.type,
                                            fabricType: t?.type,
                                            fabricName: t?.name,
                                            fabricCode: t?.code,
                                            img: t?.imageUrl ? fileBaseUrl + t?.imageUrl : ''
                                          }

                                        })
                                        :
                                       (
                                        data?.processtextiles?.length
                                        ?
                                        data?.processtextiles?.map(t => {
                                          return {

                                            id: t?.code,
                                            type: t?.type,
                                            fabricType: t?.type,
                                            fabricName: t?.name,
                                            fabricCode: t?.code,
                                            img: t?.imageUrl ? fileBaseUrl + t?.imageUrl : ''
                                          }

                                        })
                                        :
                                        [{
                                          id: i?.code,
                                          type: i?.type,
                                          fabricType: i?.type,
                                          fabricName: i?.name,
                                          fabricCode: i?.code,
                                          img: i?.imageUrl ? fileBaseUrl + i?.imageUrl : ''
                                        }]
                                       )
                                    )
                                }
                              ],
                              measurements:
                                i?.pattern?.measurement
                                  ?
                                  Object.entries(i?.pattern?.measurement)
                                    // eslint-disable-next-line array-callback-return
                                    ?.map((m) => {
                                      if (m[0] !== "photos" && m[0] !== 'textiles') {
                                        return {
                                          name: m[0],
                                          value: m[1],
                                          title: splitCamelCase(m[0]),
                                        };
                                      }

                                    })
                                  :
                                  []
                              ,
                              customerPhotos: i?.pattern?.measurement?.photos?.map(p => {
                                return {
                                  id: p?._id,
                                  url: p?.uri ? fileBaseUrl + p?.uri : '',
                                }
                              })
                            }
                          })
                        }
                      ]
                      :
                      []
                  }
                  mappedData1={data?.items?.length
                    ?
                    data?.items?.map(obj => {
                      return {
                        id: obj?.orderID,
                        orderID: obj?.orderID,
                        dueDate: obj?.dueDate ? new Date(obj?.dueDate) : '',
                        sizeTypes: [
                          {
                            id: obj?.pattern?.code || '',
                            name: obj?.pattern?.name || '',
                            items: [
                              {
                                id: makeRandomString(5),
                                isReadyToWear: false,
                                label: obj?.type,
                                notes: obj?.notes,
                                fabric: obj?.textiles?.map(t => {
                                  return {
                                    id: t?._id,
                                    type: '',
                                    fabricType: t?.type,
                                    fabricName: t?.name,
                                    fabricCode: t?.code,
                                    img: t?.imageUrl ? fileBaseUrl + t?.imageUrl : ''
                                  }
                                })
                              }
                            ],
                            measurements:
                              obj?.pattern?.measurement
                                ?
                                Object.entries(obj?.pattern?.measurement)
                                  // eslint-disable-next-line array-callback-return
                                  ?.map((m) => {
                                    if (m[0] !== "photos") {
                                      return {
                                        name: m[0],
                                        value: m[1],
                                        title: splitCamelCase(m[0]),
                                      };
                                    }

                                  })
                                :
                                []
                            ,
                            customerPhotos: obj?.pattern?.measurement?.photos?.map(p => {
                              return {
                                id: p?._id,
                                url: p?.uri ? fileBaseUrl + p?.uri : '',
                              }
                            })
                          }
                        ]
                      }
                    })
                    :
                    []
                  }
                />
                :
                <div className={Styles.regular}>no item</div>
          },
          {
            title: 'Address',
            component:
              <div className={Styles.regular}>
                {data?.to?.address}
              </div>
          },
          {
            title: 'Notes',
            component:
              <div className={Styles.regular}>
                {data?.note}
              </div>
          },
          {
            title: 'Photo',
            component:
              // <div className={Styles.photoWrapper}>
              //   {data?.photos?.length
              //     ? <img src={data?.photos[0]?.uri ? fileBaseUrl + data?.photos[0]?.uri : ''} alt='' />
              //     : <div className={Styles.imgPlaceholder}><Icon icon={'photo'} size={40} color={'#DEE2E6'} /></div>
              //   }
              // </div>
              !proofs?.length
                ?
                <div className={Styles.photoWrapper}>
                  <div className={Styles.imgPlaceholder}><Icon icon={'photo'} size={40} color={'#DEE2E6'} /></div>
                </div>
                :
                // <div className={Styles.proofWrapper}
                //   onClick={() => setShowPreview(true)}
                // >
                //   <Avatar borderRadius='6px' src={proofs[0]?.imgUrl} size='100%' />
                //   {
                //     proofs?.length > 1
                //     &&
                //     <div className={Styles.overlay}>
                //       +{proofs?.length - 1}
                //     </div>
                //   }
                // </div>
                <div className={Styles.deliveryProof}>
                  <div>
                    <span>Delivered at</span>
                    <div>
                      <Icon icon={'calendar'} size={20} />
                      <span>{delivery?.deliveredAt}</span>
                    </div>
                  </div>
                  <div>
                    <div style={{ display:'flex', alignItems: 'center', justifyContent:'space-between' }}>
                      <span>Proof Of Delivery</span>
                      <Button
                        buttonText='Upload Delivery Photo'
                        variant='secondary'
                        onClick={() => setShowUpload(true)}
                      />
                    </div>
                    <div className={Styles.proofWrapper}
                      onClick={() => setShowPreview(true)}
                    >
                      <Avatar borderRadius='6px' src={proofs[0]?.imgUrl} size='100%' />
                      {
                        proofs?.length > 1
                        &&
                        <div className={Styles.overlay}>
                          +{proofs?.length - 1}
                        </div>
                      }
                    </div>
                  </div>
                </div>
          },
        ])
      case 'm2':
        return ([
          {
            title: 'Delivery ID',
            component:
              <div className={Styles.regular}>
                {delivery?.code}
              </div>
          },
          {
            title: 'Schedule',
            component:
              <div className={Styles.scheduleWrapper}>
                <Icon icon={'calendar'} size={20} />
                <span>{delivery?.schedule}</span>
              </div>
          },
          {
            title: 'Items',
            component:
              <ItemListCard
                withTagPerItem
                navigateToStockDetails
                mappedData={[
                  {
                    id: 1,
                    orderID: '1232300',
                    dueDate: new Date(),
                    sizeTypes: [
                      {
                        id: 1,
                        name: 'Stephen Jas',
                        items: [
                          {
                            id: 1,
                            isReadyToWear: false,
                            label: 'Suit',
                            notes: 'I Want To Create Glamorous Suit! sleeve',
                            fabrics: [
                              {
                                id: 1,
                                type: 'Primary',
                                fabricType: 'Collection',
                                fabricName: 'Italiano Barzagli',
                                fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                                img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
                              },
                              {
                                id: 2,
                                type: 'Other',
                                fabricType: 'Collection',
                                fabricName: 'Del Piero',
                                fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                                img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
                              },
                            ],
                          },
                          {
                            id: 2,
                            isReadyToWear: true,
                            label: 'Ready To Wear',
                            name: 'Fire Print Twill Shirt',
                            fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                            img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865',
                          },
                          {
                            id: 3,
                            isReadyToWear: false,
                            label: 'Shirt',
                            notes: 'I Want To Create Glamorous Suit! sleeve',
                            fabrics: [
                              {
                                id: 1,
                                type: 'Primary',
                                fabricType: 'Collection',
                                fabricName: 'Italiano Barzagli',
                                fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                                img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
                              },
                              {
                                id: 2,
                                type: 'Other',
                                fabricType: 'Collection',
                                fabricName: 'Del Piero',
                                fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                                img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
                              },
                            ],
                          },
                        ],
                        measurements: [
                          {
                            name: 'Width',
                            value: '50'
                          },
                          {
                            name: 'Collar',
                            value: '50'
                          },
                          {
                            name: 'Chest',
                            value: '50'
                          },
                          {
                            name: 'Waist',
                            value: '50'
                          },
                          {
                            name: 'Hips',
                            value: '50'
                          },
                          {
                            name: 'Wide',
                            value: '50'
                          },
                          {
                            name: 'Shoulders',
                            value: '50'
                          },
                          {
                            name: 'Sleeves(LS)',
                            value: '50'
                          },
                          {
                            name: 'Hand Diameter',
                            value: '50'
                          },
                          {
                            name: 'Wrist Diameter',
                            value: '50'
                          },
                        ],
                        customerPhotos: [
                          {
                            id: 1,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                          {
                            id: 2,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                          {
                            id: 3,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },

                        ]
                      },
                      {
                        id: 1,
                        name: 'Stephen Celana',
                        items: [

                          {
                            id: 2,
                            isReadyToWear: true,
                            label: 'Ready To Wear',
                            name: 'Fire Print Twill Shirt',
                            fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                            img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865',
                          },

                        ],
                        measurements: [
                          {
                            name: 'Width',
                            value: '50'
                          },
                          {
                            name: 'Collar',
                            value: '50'
                          },
                          {
                            name: 'Chest',
                            value: '50'
                          },
                          {
                            name: 'Waist',
                            value: '50'
                          },
                          {
                            name: 'Hips',
                            value: '50'
                          },
                          {
                            name: 'Wide',
                            value: '50'
                          },
                          {
                            name: 'Shoulders',
                            value: '50'
                          },
                          {
                            name: 'Sleeves(LS)',
                            value: '50'
                          },
                          {
                            name: 'Hand Diameter',
                            value: '50'
                          },
                          {
                            name: 'Wrist Diameter',
                            value: '50'
                          },
                        ],
                        customerPhotos: [
                          {
                            id: 1,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                          {
                            id: 2,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                          {
                            id: 3,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },

                        ]
                      },
                    ],
                    customerPhotos: [
                      {
                        id: 1,
                        url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                      },
                      {
                        id: 2,
                        url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                      },
                      {
                        id: 3,
                        url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                      },
                      {
                        id: 4,
                        url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                      },
                      {
                        id: 5,
                        url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                      },
                      {
                        id: 6,
                        url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                      },
                    ]
                  },
                  {
                    id: 2,
                    orderID: '1232301',
                    dueDate: new Date(),
                    sizeTypes: [
                      {
                        id: 1,
                        name: 'Stephen Jas',
                        items: [
                          {
                            id: 1,
                            isReadyToWear: false,
                            label: 'Suit',
                            notes: 'I Want To Create Glamorous Suit! sleeve',
                            fabrics: [
                              {
                                id: 1,
                                type: 'Primary',
                                fabricType: 'Collection',
                                fabricName: 'Italiano Barzagli',
                                fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                                img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
                              },
                              {
                                id: 2,
                                type: 'Other',
                                fabricType: 'Collection',
                                fabricName: 'Del Piero',
                                fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                                img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
                              },
                            ],
                          },
                          {
                            id: 2,
                            isReadyToWear: true,
                            label: 'Ready To Wear',
                            name: 'Fire Print Twill Shirt',
                            fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                            img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865',
                          },
                          {
                            id: 3,
                            isReadyToWear: false,
                            label: 'Shirt',
                            notes: 'I Want To Create Glamorous Suit! sleeve',
                            fabrics: [
                              {
                                id: 1,
                                type: 'Primary',
                                fabricType: 'Collection',
                                fabricName: 'Italiano Barzagli',
                                fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                                img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
                              },
                              {
                                id: 2,
                                type: 'Other',
                                fabricType: 'Collection',
                                fabricName: 'Del Piero',
                                fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                                img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865'
                              },
                            ],
                          },
                        ],
                        measurements: [
                          {
                            name: 'Width',
                            value: '50'
                          },
                          {
                            name: 'Collar',
                            value: '50'
                          },
                          {
                            name: 'Chest',
                            value: '50'
                          },
                          {
                            name: 'Waist',
                            value: '50'
                          },
                          {
                            name: 'Hips',
                            value: '50'
                          },
                          {
                            name: 'Wide',
                            value: '50'
                          },
                          {
                            name: 'Shoulders',
                            value: '50'
                          },
                          {
                            name: 'Sleeves(LS)',
                            value: '50'
                          },
                          {
                            name: 'Hand Diameter',
                            value: '50'
                          },
                          {
                            name: 'Wrist Diameter',
                            value: '50'
                          },
                        ],
                        customerPhotos: [
                          {
                            id: 1,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                          {
                            id: 2,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                          {
                            id: 3,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                        ]
                      },
                      {
                        id: 1,
                        name: 'Stephen Celana',
                        items: [

                          {
                            id: 2,
                            isReadyToWear: true,
                            label: 'Ready To Wear',
                            name: 'Fire Print Twill Shirt',
                            fabricCode: 'SEFFIRORICCI-CLASSIC-19-05/1138',
                            img: 'https://nelliejoans.co.nz/cdn/shop/products/3E341FF5-A7C3-433F-9048-569C5208A685_1024x1024@2x.jpg?v=1661303865',
                          },

                        ],
                        measurements: [
                          {
                            name: 'Width',
                            value: '50'
                          },
                          {
                            name: 'Collar',
                            value: '50'
                          },
                          {
                            name: 'Chest',
                            value: '50'
                          },
                          {
                            name: 'Waist',
                            value: '50'
                          },
                          {
                            name: 'Hips',
                            value: '50'
                          },
                          {
                            name: 'Wide',
                            value: '50'
                          },
                          {
                            name: 'Shoulders',
                            value: '50'
                          },
                          {
                            name: 'Sleeves(LS)',
                            value: '50'
                          },
                          {
                            name: 'Hand Diameter',
                            value: '50'
                          },
                          {
                            name: 'Wrist Diameter',
                            value: '50'
                          },
                        ],
                        customerPhotos: [
                          {
                            id: 1,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                          {
                            id: 2,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                          {
                            id: 3,
                            url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                          },
                        ]
                      },
                    ],
                    customerPhotos: [
                      {
                        id: 1,
                        url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                      },
                      {
                        id: 2,
                        url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                      },
                      {
                        id: 3,
                        url: 'https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg'
                      },
                    ]
                  },
                ]}
              />

          },
          {
            title: 'Address',
            component:
              <div className={Styles.regular}>
                {delivery?.address}
              </div>
          },
          {
            title: 'Customer Details',
            component:
              <div className={Styles.customerDetails}>
                <span>{delivery?.customerName}</span>
                <span>{delivery?.customerPhoneNumber}</span>
              </div>
          },
          {
            title: 'Notes',
            component:
              <div className={Styles.regular}>
                {delivery?.notes}
              </div>
          },
          {
            title: '',
            component:
              <div className={Styles.deliveryProof}>
                <div>
                  <span>Delivered at</span>
                  <div>
                    <Icon icon={'calendar'} size={20} />
                    <span>{delivery?.deliveredAt}</span>
                  </div>
                </div>
                <div>
                  <span>Proof Of Delivery</span>
                  <div className={Styles.proofWrapper}
                    onClick={() => setShowPreview(true)}
                  >
                    <Avatar borderRadius='6px' src={proofs[0]?.imgUrl} size='100%' />
                    {
                      proofs?.length > 1
                      &&
                      <div className={Styles.overlay}>
                        +{proofs?.length - 1}
                      </div>
                    }
                  </div>
                </div>
              </div>
          },
        ])
      default:
        return ([
          {
            title: 'Delivery ID',
            component:
              <div className={Styles.regular}>
                {delivery?.code}
              </div>
          },
          {
            title: 'Schedule',
            component:
              <div className={Styles.scheduleWrapper}>
                <Icon icon={'calendar'} size={20} />
                <span>{delivery?.schedule}</span>
              </div>
          },
          {
            title: 'Items',
            component:
              <div className={Styles.itemsWrapper}>
                {delivery?.itemsArr?.map((each, i) => (
                  <div key={i}>
                    <span>{i + 1}</span>
                    <Avatar src={null} size='32px' borderRadius='6px' />
                    <ItemDetails
                      item={{
                        ...each,
                        title: each?.fabric,
                        name: each?.fabric,
                        category: each?.category,
                        // type: 
                      }}
                    />
                  </div>
                ))}
              </div>
          },
          {
            title: 'Address',
            component:
              <div className={Styles.regular}>
                {delivery?.address}
              </div>
          },
          {
            title: 'Notes',
            component:
              <div className={Styles.regular}>
                {delivery?.notes}
              </div>
          },
          {
            title: 'Photo',
            component:
              !proofs?.length
                ?
                <div className={Styles.photoWrapper}>
                  <div className={Styles.imgPlaceholder}><Icon icon={'photo'} size={40} color={'#DEE2E6'} /></div>
                </div>
                :
                <div className={Styles.deliveryProof}>
                  <div>
                    <span>Delivered at</span>
                    <div>
                      <Icon icon={'calendar'} size={20} />
                      <span>{delivery?.deliveredAt}</span>
                    </div>
                  </div>
                  <div>
                    <span>Proof Of Delivery</span>
                    <div className={Styles.proofWrapper}
                      onClick={() => setShowPreview(true)}
                    >
                      <Avatar borderRadius='6px' src={proofs[0]?.imgUrl} size='100%' />
                      {
                        proofs?.length > 1
                        &&
                        <div className={Styles.overlay}>
                          +{proofs?.length - 1}
                        </div>
                      }
                    </div>
                  </div>
                </div>
            // <div className={Styles.proofWrapper}
            //   onClick={() => setShowPreview(true)}
            // >
            //   <Avatar borderRadius='6px' src={proofs[0]?.imgUrl} size='100%' />
            //   {
            //     proofs?.length > 1
            //     &&
            //     <div className={Styles.overlay}>
            //       +{proofs?.length - 1}
            //     </div>
            //   }
            // </div>
          },
        ])
    }

  }, [data?.items, data?.note, data?.processtextiles, data?.scheduledAt, data?.to?.address, delivery?.address, delivery?.code, delivery?.customerName, delivery?.customerPhoneNumber, delivery?.deliveredAt, delivery?.itemsArr, delivery?.notes, delivery?.schedule, proofs, type])


  return (
    <DetailsModal
      modalTitle={'Delivery'}
      modalIcon={'paper-airplane'}
      handleClose={handleClose}
      extraHeaderIntro={'Deliver to'}
      extraHeaderTitle={type === 'm2'
        ? <div className={Styles.storeName}>
          <Icon icon={'map-pin-solid'} size={24} color={'#DD0B0B'} />
          <span>{data?.to?.name}</span>
        </div>
        :
        data?.to?.name}
      tagTitle={tagTemplate?.title}
      tagType={tagTemplate?.type}
    // noTag
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.each}>
          {content?.title &&
            (
              content?.title?.toLowerCase() !== 'photo'
                ?
                <span>{content?.title}</span>
                :
                <div className={Styles.title}>
                  <span className={Styles.span}>{content?.title}</span>
                  {!proofs?.length && role === 'DIRECTOR'
                    &&
                    // <button className={Styles.uploadButton}></button>
                    <Button
                      buttonText='Upload Delivery Photo'
                      variant='secondary'
                      onClick={() => setShowUpload(true)}
                    />
                  }
                </div>
            )}
          {content?.component}
        </div>
      ))}
      <div className={Styles.each}>
        <span>Delivery Letter</span>
        <Button
          buttonText="Generate Letter"
          variant='secondary'
          size='lg'
          onClick={() =>
            window.open(
              `/delivery-letter/${data?.code}?id=${btoa(data?._id)}&type=${data?.type}`,
              "_blank",
              "rel=noopener noreferrer"
            )
          }
        />
      </div>
      {
        role === 'COURIER'
        &&

        <div className={Styles.actions}>

          {
            data?.status !== 'ON-DELIVERY'
            &&
            data?.status !== 'DELIVERED'
            &&
            <Button
              buttonText='Change Status'
              variant='secondary'
              iconName={!expandChangeStatus ? 'chevron-down' : 'chevron-up'}
              withIcon
              iconPlacement='end'
              size='lg'
              onClick={() => setExpandChangeStatus(!expandChangeStatus)}
            />
          }

          {
            data?.status === 'ON-DELIVERY'
            &&
            <div className={Styles.fieldsWrapper}>
              <InputField
                title={'Name Penerima'}
                value={form?.recipientsName}
                setValue={(newVal) => handleChangeForm('recipientsName', newVal)}
                placeholder={'Nama penerima'}
              />
              <div className={Styles.photoField}>
                <span>Delivery Photo</span>
                <label htmlFor={`cameraPhoto${data?.code}`}>

                  <div
                    className={Styles.photoBox}
                    onClick={(e) => {
                      e?.stopPropagation()
                      // setShowCameraMode(true)
                      // setSelectedOrderToConfirm(row)
                    }}
                  >
                    {form?.images
                      ?
                      <div className={Styles.imageWrapper}>
                        <Avatar
                          src={form?.images ? URL.createObjectURL(form?.images) : ''}
                          borderRadius='4px'
                          size='100%'
                          withIcon
                          iconName={'camera'}
                        />
                      </div>

                      :
                      <div className={Styles.noImg}>
                        <Icon icon={'camera'} size={50} />
                        <span>Take delivery photo</span>
                      </div>
                    }


                    <input
                      type="file"
                      // ref={inputFileRef}
                      name={`cameraPhoto${data?.code}`}
                      capture="environment"
                      accept="image/*"
                      onChange={async (e) => {
                        try {
                          if (e?.target?.files?.length > 0) {
                            const newFile = e?.target?.files[0]
                            handleChangeForm('images', newFile)
                          }

                        } catch (err) {
                          console.log(err, 'error on uploading file')
                        }
                      }}
                      hidden
                      multiple={false}
                      id={`cameraPhoto${data?.code}`}
                    />
                  </div>
                </label>
              </div>
            </div>
          }

          {
            (expandChangeStatus || data?.status === 'ON-DELIVERY')
            &&
            <div className={Styles.options}>
              {data?.status !== 'ON-DELIVERY'
                &&
                <Button
                  buttonText={onUpdating ? 'Changing Status...' : 'On Delivery'}
                  withIcon
                  iconName='driver'
                  iconPlacement='start'
                  size='lg'
                  disabled={onUpdating}
                  onClick={() => handleUpdateStatus('ON-DELIVERY')}
                />
              }
              <Button
                variant={data?.status !== 'ON-DELIVERY' ? 'tertiary' : 'primary'}
                buttonText='Finish Delivery'
                withIcon
                size='lg'
                iconPlacement='start'
                iconName='check-circle'
                disableActive
                disabled={data?.status === 'ON-DELIVERY' ? !allHasValueObjectChecker(form) : false}
                onClick={() => data?.status === 'ON-DELIVERY' && allHasValueObjectChecker(form) && handleUpdateStatus('DELIVERED')}
                customClassName={data?.status !== 'ON-DELIVERY' ? Styles.disabled : ''}
              />
            </div>
          }


          {/* <label htmlFor={`cameraPhoto${data?.code}`}>

            <div
              className={Styles.photoWrapper}
              onClick={(e) => {
                e?.stopPropagation()
                // setShowCameraMode(true)
                // setSelectedOrderToConfirm(row)
              }}
            >
              <Avatar
                src={data?.photo}
                borderRadius='4px'
                size='4rem'
                withIcon
                iconName={'camera'}
              />
              <input
                type="file"
                // ref={inputFileRef}
                name={`cameraPhoto${data?.code}`}
                capture="environment"
                accept="image/*"
                onChange={async (e) => {
                  try {
                    if (e?.target?.files?.length > 0) {
                      const newFile = e?.target?.files[0]
                      const formData = new FormData()
                      formData?.append('images', newFile)
                      formData?.append(`photos[0][name]`, 'FOTO BUKTI')
                      formData?.append(`status`, 'DELIVERED')
                      await uploadDeliveryProof(formData, data?.code)
                      fetchData()
                      setTrigger(Math?.random())
                    }

                  } catch (err) {
                    console.log(err, 'error on uploading file')
                  }
                }}
                hidden
                multiple={false}
                id={`cameraPhoto${data?.code}`}
              />
            </div>
          </label> */}
        </div>

      }
      {
        showPreview &&
        <ModalWrapper
          show={showPreview}
          setShow={setShowPreview}
          desktopPositioning='center'
          mobilePositioning='center'
          noPadding

        >
          <PicturesPreviewModal
            srcArr={proofs?.map(obj => obj?.imgUrl)}
            onClose={() => setShowPreview(false)}
            slidesToShow={width > 768 ? 6 : 3}
          />
        </ModalWrapper>
      }

      {
        showUpload
        &&
        <ModalWrapper
          show={showUpload}
          setShow={setShowUpload}
          desktopPositioning='center'
          mobilePositioning='center'
          noPadding
        >
          <UploadDeliveryPhotoModal
            onClose={() => setShowUpload(false)}
            data={data}
            fetchData={fetchData}
            setTrigger={setTrigger}
          />
        </ModalWrapper>
      }

    </DetailsModal>
  )
}