import Avatar from '@Atom/Avatar'
import Styles from './style.module.scss'

export default function GroupedAvatars({
  srcArr = [],
  size = '2.4rem',
  borderRadius = '50%',
  gap = '2px',
  mappingTimes = 3
}) {

  return (
    <div className={Styles.container} style={{ gap: gap }}>
      {srcArr?.slice(0, mappingTimes)?.map((src, i) => (
        <Avatar
          key={i}
          src={src}
          size={size}
          borderRadius={borderRadius}
        />
      ))}
      {srcArr?.length > mappingTimes && <div className={Styles.overTimes} style={{ height: size, minWidth: size, borderRadius: borderRadius }}>+{+srcArr?.length - mappingTimes}</div>}
    </div>
  )
}