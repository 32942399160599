import useWindowSize from '@Hooks/useWindowSize';
import { useEffect, useMemo } from 'react';
import Styles from './style.module.scss';

export default function NavbarSlider({
  children,
  show,
  setShow,
  // forceHide,
  ...props
}) {

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [show])

  const { width } = useWindowSize()

  const paddingInline = useMemo(() => {
    if (width > 1440) {
      return (width - 1440) / 2 + 32 + 'px'
    } else {
      return '32px'
    }
  }, [width])


  return (
    <div
      className={`${Styles.container} ${show ? Styles.onShow : Styles.onHide}`}
      style={{ paddingInline: paddingInline }}
    >
      {children}
      {show
      &&
      <div onClick={()=> setShow(false)} className={Styles.overlay}/>
      }
    </div>
  )
}