import CheckpointOverviewLayout from '@Organism/Checkpoint/CheckpointOverview'
import Styles from './style.module.scss'
import { Await, useLoaderData } from 'react-router-dom'
import { Suspense, useState } from 'react'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function CheckpointOverviewPage() {
  const { packageCheckpointOverview, packageDeliverSync } = useLoaderData()
  const [trigger, setTrigger] = useState('')


  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <CheckpointOverviewLayout
            isLoading={true}
            defaultData={{
              user: null,
              stock: [],
              total: 0,
              totalPage: 0
            }}
            defaultSync={{
              couriers: [],
              cps: []
            }}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        }
      >
        <Await
          resolve={packageCheckpointOverview}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageCheckpointOverview) => (
            <Suspense
              fallback={
                <CheckpointOverviewLayout
                  isLoading={true}
                  defaultData={packageCheckpointOverview?.data}
                  defaultSync={{
                    couriers: [],
                    cps: []
                  }}
                  trigger={trigger}
                  setTrigger={setTrigger}
                />

              }
            >
              <Await
                resolve={packageDeliverSync}
                errorElement={<AsyncErrorBoundary />}
              >
                {(packageDeliverSync) => (
                  <CheckpointOverviewLayout
                    defaultData={packageCheckpointOverview?.data}
                    defaultSync={packageDeliverSync?.data}
                    trigger={trigger}
                    setTrigger={setTrigger}
                  />
                )}

              </Await>
            </Suspense>

          )}
        </Await>


      </Suspense>
      {/* <CheckpointOverviewLayout /> */}
    </div>
  )
}