/* eslint-disable no-unused-vars */
import Button from '@Atom/Button'
import { fileBaseUrl } from '@Config/api'
import { priceFormat } from '@Helpers/priceFormat'
import useWindowSize from '@Hooks/useWindowSize'
import Slider from '@Molecule/Slider'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Styles from './style.module.scss'
import { splitCamelCase } from '@Helpers/splitCamelCase'
import ModalWrapper from '@Atom/ModalWrapper'
import AddToCartModal from '@Molecule/_modals/AddToCartModal'
import Icon from '@Atom/Icon'
import Skeleton from 'react-loading-skeleton'
import { sum } from '@Helpers/sum'
import SelectCategoryModal from '@Molecule/_modals/SelectCategoryModal'

export default function CollectionOverviewLayout({
  isLoading = false,
  defaultData
}) {

  const [showAddToCart, setShowAddToCart] = useState(false)
  const [showSelectCategory, setShowSelectCategory] = useState(false)

  const details = useMemo(() => {
    return {
      ...defaultData,
      name: defaultData?.name,
      price: defaultData?.price,
      variants: defaultData?.image?.map(obj => { return { name: obj?.url, img: fileBaseUrl + obj?.url } }),
      material: defaultData?.fabric,
      details: defaultData?.description,
    }
  }, [defaultData])

  const [activeIndex, setActiveIndex] = useState(0)

  const template = useMemo(() => {
    return [
      {
        title: 'Stock Inventory',
        icon: 'map-pin',
        extraHeaderComponent:
          !isLoading
            ?
            <div className={Styles.inventoryExtraHeader}>
              <Icon icon={'box-group'} size={24} />
              <p><span>Stock:</span><span>{` (${details?.qty || (details?.storeDetail?.length ? sum(details?.storeDetail?.map(obj => obj?.qty)) : 0)})`}</span></p>
            </div>
            :
            <div className={Styles.inventoryExtraHeader}>
              <Skeleton className={Styles.stockAmount} />
            </div>,
        component:
          <div className={Styles.inventoryWrapper}>
            {!isLoading
              ?
              details?.storeDetail?.map((each, i) => (
                <div key={i}>
                  <span>{each?.name}</span>
                  <span>{`Stock: ${each?.qty}`}</span>
                </div>
              ))
              :
              [...Array(3)]?.map((_, i) => (
                <Skeleton key={i} className={Styles.div} />
              ))
            }
          </div>
      },
      {
        title: 'Product Details',
        component:
          <div className={Styles.detailsDesc}>
            {!isLoading
              ?
              <span>{details?.details}</span>
              :
              <Skeleton className={Styles.desc} />
            }
          </div>
      }
    ]
  }, [details?.details, details?.qty, details?.storeDetail, isLoading])

  const { id } = useParams()
  const data = useMemo(() => {
    // return defaultData?.map(obj => {
    return {
      ...defaultData,
      id: defaultData?._id,
      name: defaultData?.name,
      img: defaultData?.images?.length ? fileBaseUrl + defaultData.images[0]?.url : null,
      price: defaultData?.price,
      isMeasured: false,
      isCustomSizing: true,
      showMeasurementStatus: true,
      sleevesType: 'long',
      size: '-',
      measurements: defaultData?.size ? Object?.entries(defaultData?.size)?.map(m => { return { name: m[0], value: m[1], title: splitCamelCase(m[0]) } }) : [],
    }
    // })
  }, [defaultData])
  const handleAddToCart = () => {
    const defaultCart = localStorage?.getItem('cart') ? JSON.parse(localStorage?.getItem('cart')) : []
    localStorage?.setItem('cart', JSON?.stringify([...defaultCart, data]))
    setShowAddToCart(true)
  }
  const handleStartMeasure = () => {
    navigate(`/product/listing/start-measure/${id}`)
  }

  const { width } = useWindowSize()

  const sliderRef = useRef()
  const sliderWrapperRef = useRef()
  const [sliderHeight, setSliderHeight] = useState()

  const getSliderHeight = () => {
    const newHeight = sliderRef?.current?.clientHeight;
    setSliderHeight(newHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", getSliderHeight);
  }, []);

  const navigate = useNavigate()

  return (
    <div className={Styles.container}>
      <div className={`${Styles.header} ${Styles.showOnMobile}`}>
        <Button
          variant='tertiary'
          size='lg'
          withIcon
          iconName={'chevron-left'}
          buttonText='Back to Collection'
          customIconSize={'16px'}
          fixedPadding={'0px'}
          disableActive
          onClick={() => navigate('/product/collection')}
        />
        {!isLoading
          ?
          <div className={Styles.bottom}>
            <span>{details?.name}</span>
            <span>Rp{priceFormat(details?.price)}</span>
          </div>
          :
          <div className={Styles.bottom}>
            <Skeleton className={Styles.name} />
            <Skeleton className={Styles.price} />
          </div>
        }
      </div>
      <div className={Styles.content}>
        {!isLoading
          ?
          !!details?.variants?.length
            ?
            <div className={Styles.sliderWrapper}>

              <Slider
                refToForward={sliderRef}
                setIndex={setActiveIndex}
                showDots={width <= 1024 ? true : false}
                showArrows={width > 1024 ? true : false}
              >
                {details?.variants?.map((each, i) => (
                  <div key={i} className={Styles.each}>
                    <img src={each?.img} alt='' />
                  </div>
                ))}
              </Slider>
            </div>

            :
            <div className={Styles.noImage}>
            </div>



          :
          <Skeleton className={Styles.sliderWrapper} />
        }
        <div className={Styles.detailsWrapper}>
          <div className={`${Styles.header} ${Styles.hideOnMobile}`}>
            <Button
              variant='tertiary'
              size='lg'
              withIcon
              iconName={'chevron-left'}
              buttonText='Back to Collection'
              customIconSize={'16px'}
              fixedPadding={'0px'}
              disableActive
              onClick={() => navigate('/product/collection')}
            />
            {!isLoading
              ?
              <div className={Styles.bottom}>
                <span>{details?.name}</span>
                <span>Rp{priceFormat(details?.price)}</span>
              </div>
              :
              <div className={Styles.bottom}>
                <Skeleton className={Styles.name} />
                <Skeleton className={Styles.price} />
              </div>
            }
          </div>
          <div className={Styles.details}>
            <div className={Styles.variantsWrapper}>
              {!isLoading
                ?
                details?.variants?.map((each, i) => (
                  <button
                    key={i}
                    className={i === activeIndex ? Styles.active : ''}
                    onClick={() => sliderRef?.current?.slickGoTo(i)}
                  >
                    <img src={each?.img} alt='' />
                  </button>
                ))
                :
                [...Array(3)]?.map((_, i) => (
                  <Skeleton key={i} className={Styles.button} />
                ))
              }
            </div>
            {template?.map((each, i) => (
              <div key={i} className={`${Styles.card} ${each?.noBg ? Styles.noBg : ''}`}>
                {(each?.title || each?.icon)
                  &&
                  <div className={Styles.cardHeader}>
                    {each?.icon && <Icon icon={each?.icon} size={24} />}
                    {each?.title && <span>{each?.title}</span>}
                    {each?.extraHeaderComponent && each?.extraHeaderComponent}
                  </div>
                }
                {each?.component}
              </div>
            ))}
          </div>
          {!isLoading
            &&
            <div className={Styles.actions}>
              <Button
                type='button'
                variant='secondary'
                buttonText={'Go to Simulation'}
                size='lg'
                // withIcon
                // iconName={'shopping-cart'}
                // iconPlacement='start'
                onClick={() => {
                  navigate(`/product/simulation/simulate?c=TEMPLATE-0000001&t=&f=${data?.productCode}`)
                }}
              />
              <Button
                type='button'
                variant='primary'
                buttonText={'Create Order'}
                size='lg'
                withIcon
                // iconName={'arrow-long-right'}
                iconPlacement='end'
                onClick={() => setShowSelectCategory(true)}
              />
            </div>
          }

        </div>

      </div>

      <ModalWrapper
        show={showAddToCart}
        desktopPositioning='center'
        mobilePositioning='center'
      >
        {showAddToCart
          &&
          <AddToCartModal
            src={data?.img}
            name={data?.name}
            size={data?.size || ''}
            price={data?.price}
            id={data?.id}
            data={data}
          />}

      </ModalWrapper>
      <ModalWrapper
        show={showSelectCategory}
        setShow={setShowSelectCategory}
        desktopPositioning='center'
        mobilePositioning='bottom'
      >
        {showSelectCategory
          &&
          <SelectCategoryModal
            handleClose={() => setShowSelectCategory(false)}
            productCode={id}
          />
        }

      </ModalWrapper>

    </div>
  )
}

