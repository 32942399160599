import Icon from '@Atom/Icon';
import Styles from './style.module.scss'
import Button from '@Atom/Button';
import { useState } from 'react';

export default function AddToCartConfirmationModal({
  dataToAdd,
  setShow
}) {
  const [addingToCart, setAdddingToCart] = useState(false)

  return (
    <div className={Styles.container}>
      <div className={Styles.logo}>
        <Icon icon={'shopping-cart'} size={72} />
      </div>
      <div className={Styles.content}>
        <h3>Add To Cart</h3>
        <span>Are you sure stock data filled in previously has been correct?</span>
      </div>
      <div className={Styles.actions}>
        <Button
          type='button'
          variant='secondary'
          buttonText={'Cancel'}
          disableActive
          size='lg'
          fixedPadding={'10px 16px'}
          onClick={() => setShow(false)}
        />
        <Button
          type='button'
          variant='primary'
          buttonText={!addingToCart ? 'Yes, add to cart' : 'Adding to cart...'}
          size='lg'
          fixedPadding={'10px 16px'}
          disableActive
          disabled={addingToCart}
          onClick={() => {
            try {
              setAdddingToCart(true)
              const defaultCart = localStorage?.getItem('cart') ? JSON.parse(localStorage?.getItem('cart')) : []
              localStorage?.setItem('cart', JSON.stringify([...defaultCart, dataToAdd]))
              setShow(false)
            } catch (err) {
              setAdddingToCart(false)
              console.log(err)
            }
          }}
        />

      </div>
    </div>
  )
}