export const orderMasterTypes = [
  {
    title: 'Transactions',
    query: ''
  },
  {
    title: 'Customers',
    query: 'customer'
  },
  {
    title: 'Items',
    query: 'item'
  }
]