import { API } from "@Config/api";

export function getProducts(type, page, limit, lowLimitPrice, highLimitPrice, startDate, endDate, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/product?type=${type}&page=${page}&limit=${limit}&lowLimitPrice=${lowLimitPrice}&highLimitPrice=${highLimitPrice}&startDate=${startDate}&endDate=${endDate}&search=${search}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function getProduct(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/product/${code}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

export function getProductsByCategory(category) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/template/${category}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

export function getSimulationThumbnails() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/template`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

export function getTemplateByCode(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/simulation/template/${code}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

export function getProductList(type, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/product/list?type=${type}&search=${search}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        }
      })
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}


export function addProductImage(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/product-image`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}


export function editProductImage(image, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/product-image/${id}`, { image }, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addSimulationImages(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/simulation`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function editSimulationImage(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/simulation/template`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function deleteSimulationImage(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(`/simulation/template/${id}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function editFee(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/template/fee`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
