// /* eslint-disable no-unused-vars */
import { sizingTypes } from '@Assets/data/sizingTypes'
import { stockTypes } from '@Assets/data/stockTypes'
import Avatar from '@Atom/Avatar'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import Searchbar from '@Atom/Searchbar'
import { fileBaseUrl } from '@Config/api'
import { useDebounce } from '@Hooks/useDebounce'
import useQuery from '@Hooks/useQuery'
import useWindowSize from '@Hooks/useWindowSize'
import Slider from '@Molecule/Slider'
import { getProductsByCategory } from '@Services/product'
import Images from '@Theme/Images'
import { useCallback, useEffect, useMemo, useState } from 'react'
// import Skeleton from 'react-loading-skelceton'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function SimulationOverviewLayout({
  isLoading,
  defaultData,
  defaultThumbnails
}) {
  // const [showAddToCart, setShowAddToCart] = useState(false)
  const [data, setData] = useState(defaultData[0]?.simulations)

  const categories = useMemo(() => {
    return defaultThumbnails?.map(obj => {
      return {
        label: obj?.name,
        img: obj?.imageUrl ? fileBaseUrl + obj?.imageUrl : null,
        query: obj?.code
      }
    })
  }, [defaultThumbnails])


  const types = useMemo(() => {
    return stockTypes?.filter(obj => !obj?.query?.includes('READY'))?.map(obj => ({
      label: obj?.title,
      query: obj?.query
    }))
  }, [])

  const fabrics = useMemo(() => {
    // return defaultData[0]?.simulations?.map(obj => {
    return data?.map(obj => {
      // const code = defaultData[0]?.code
      return {
        ...obj?.product,
        id: obj?.product?._id,
        code: obj?.product?.productCode,
        type: obj?.product?.type,
        name: obj?.product?.name,
        img: obj?.product?.image ? fileBaseUrl + obj?.product?.image : null,
        descriptions: obj?.product?.description,
        details: obj?.product?.details || '-',
        isMeasured: false,
        isCustomSizing: true,
        showMeasurementStatus: true,
        sleevesType: 'long',
        size: '-',
        measurements: sizingTypes?.map(s => {
          return {
            ...s,
            value: 0
          }
        }),
        images: obj?.product?.simulation?.images?.map(img => fileBaseUrl + img?.url)
      }
    })

  }, [data])




  const query = useQuery()

  const category = query.get('c') || categories[0]?.query || ''
  // const defaultType = query.get('t')
  const fabric = query.get('f') || ''




  // eslint-disable-next-line no-unused-vars
  const [expandTypes, setExpandTypes] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')

  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const navigate = useNavigate()
  // const { pathname } = useLocation()

  // const [categoryQuery, setCategoryQuery] = useState(`c=${category || ''}`)
  const [selectedCategoryCode, setSelectedCategoryCode] = useState(category || '')
  // const [type, setType] = useState(defaultType)
  // const [fabricQuery, setFabricQuery] = useState(`f=${fabric}`)
  const [fabricCode, setFabricCode] = useState(fabric || '')

  const [imagesV2, setImagesV2] = useState([])


  const selectedCategory = useMemo(() => {
    return categories?.find(obj => obj?.query === category)
  }, [categories, category])

  const selectedFabric = useMemo(() => {
    return fabrics?.find(obj => `${obj?.code}` === fabricCode?.replaceAll('::', '/'))
  }, [fabricCode, fabrics])


  const fetchData = useCallback(async () => {
    try {
      const { data } = await getProductsByCategory(selectedCategoryCode)
      setData(data?.length ? data[0]?.simulations : [])
    } catch (err) {
      console.log(err, 'error fetch')
    }
  }, [selectedCategoryCode])

  useEffect(() => {
    fetchData()
  }, [fetchData])


  // useEffect(() => {
  //   navigate(`${pathname}?${categoryQuery}&t=${type}&${fabricQuery?.replaceAll('/', '::')}`, { replace: true })
  // }, [categoryQuery, type, fabricQuery, pathname, navigate])

  // const images = useMemo(() => {
  //   if (defaultData?.length) {
  //     const all = defaultData[0]
  //       ?.simulations
  //       ?.find(obj => obj?.product?.productCode === fabric?.replaceAll('::', '/'))
  //       ?.product?.simulation?.images?.map(obj => {
  //         return {
  //           id: obj?._id,
  //           name: obj?.type,
  //           src: obj?.url ? fileBaseUrl + obj?.url : ''
  //         }
  //       })
  //     const front = all?.find(obj => obj?.name?.toLowerCase()?.includes('depan'))
  //     const side = all?.find(obj => obj?.name?.toLowerCase()?.includes('samping'))
  //     const back = all?.find(obj => obj?.name?.toLowerCase()?.includes('belakang'))

  //     return [front, side, back]
  //   } return []

  // }, [defaultData, fabric])

  const images = useMemo(() => {
    if (data?.length) {
      const all = data
        ?.find(obj => obj?.product?.productCode === fabricCode?.replaceAll('::', '/'))
        ?.product?.simulation?.images?.map(obj => {
          return {
            id: obj?._id,
            name: obj?.type,
            src: obj?.url ? fileBaseUrl + obj?.url : ''
          }
        })
      const front = all?.find(obj => obj?.name?.toLowerCase()?.includes('depan'))
      const side = all?.find(obj => obj?.name?.toLowerCase()?.includes('samping'))
      const back = all?.find(obj => obj?.name?.toLowerCase()?.includes('belakang'))

      return [front, side, back]
    } return []
  }, [data, fabricCode])


  useEffect(() => {
    const filterdImages = images?.filter(obj => obj?.src)
    if (filterdImages?.length) {
      setSelectedImg(filterdImages[0]?.src)
    }
  }, [images])




  const [selectedImg, setSelectedImg] = useState(images?.length ? images[0] : null)
  // const [selectedCategory, setSelectedCategory] = useState(second)


  const { width } = useWindowSize()

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.wrapper}>
          <div className={Styles.left}>
            <div className={Styles.title}>
              <h3>Simulation</h3>
              <Icon icon={'solar_hanger-bold'} size={45} />
            </div>
            <span>Pick category and fabrics, then simulate.</span>
          </div>
          {/* <div className={Styles.right}>
            <img src={Images.BLUE_SHIRT} alt='' />
            <img src={Images.BLACK_SUIT} alt='' />
          </div> */}
        </div>
      </div>
      <div className={Styles.navBack}>
        <Button
          variant='tertiary'
          size='lg'
          buttonText='Back to Products'
          fixedPadding={'0px'}
          withIcon
          iconName={'chevron-left'}
          iconPlacement='start'
          borderRadius='0'
          disableActive
          customIconSize={16}
          onClick={() => navigate('/product')}
        />
      </div>
      <div className={Styles.content}>
        <div className={Styles.top}>
          <div className={Styles.categories}>
            {
              !isLoading
                ?
                categories?.map((each, i) => (
                  <button
                    key={i}
                    // onClick={() => setCategoryQuery(`c=${each?.query}`)}
                    onClick={() => setSelectedCategoryCode(each?.query)}
                    className={selectedCategoryCode === each?.query ? Styles.active : ''}
                  >
                    <Avatar src={each?.img} size='64px' borderRadius='0px 0px 8px 8px' />
                    <span>{each?.label}</span>
                  </button>
                ))
                :
                [...Array(3)]?.map((each, i) => (
                  <Skeleton key={i} className={Styles.category} />
                ))
            }
          </div>
          <div className={Styles.types}>
            {/* <Input
              isDropdown
              value={type}
              setValue={setType}
              dropdownOptions={types?.map(obj => obj?.label)}
            /> */}
            <div className={Styles.typesWrapper}>
              {/* <Button
                variant='tertiary'
                size='lg'
                disableActive
                fixedPadding={'0px'}
                borderRadius='0px'
                withIcon
                iconName={expandTypes ? 'chevron-up' : 'chevron-down'}
                iconPlacement='end'
                buttonText={types?.find(obj => obj?.query?.toLowerCase() === type?.toLowerCase())?.label}
                onClick={() => setExpandTypes(!expandTypes)}
              /> */}

              {expandTypes
                &&
                <div className={Styles.typesBox}>
                  {types?.map((obj, i) => (
                    <div key={i} onClick={() => {
                      // setType(obj?.query?.toLowerCase())
                      setExpandTypes(false)
                    }}>
                      {obj?.label}
                    </div>
                  ))}
                </div>
              }

            </div>

            <Searchbar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder={`Search`}
              width='100%'
              customClassName={Styles.searchbar}
              adornmentPositioning={{ top: '6px', left: '8px' }}
            />
            <div className={Styles.cards}>
              {!isLoading
                ?
                fabrics
                  // ?.filter(obj => obj?.type?.toLowerCase()?.includes(type?.toLowerCase()))
                  ?.filter(obj =>
                    obj?.name?.toLowerCase()?.includes(debouncedSearchTerm?.toLowerCase())
                    || obj?.productCode?.toLowerCase()?.includes(debouncedSearchTerm?.toLowerCase())
                  )
                  ?.filter(obj => obj?.img)
                  ?.map((each, i) => (
                    <button
                      key={i}
                      // className={`${Styles.card} ${fabric?.replaceAll('::', '/') === `${each?.code}` ? Styles.active : ''}`}
                      className={`${Styles.card} ${fabricCode?.replaceAll('::', '/') === `${each?.code}` ? Styles.active : ''}`}
                      // onClick={() => setFabricQuery(`f=${each?.code}`)}
                      onClick={() => {
                        setFabricCode(each?.code)
                        setImagesV2(each?.images)
                      }}
                    >
                      <Avatar
                        src={each?.img}
                        size='100%'
                        borderRadius='0px'
                      />
                      <span>{each?.code}</span>
                      <p>ddd</p>
                    </button>
                  ))
                :
                [...Array(6)]?.map((each, i) => (
                  <Skeleton key={i} className={Styles.card} />
                ))
              }
            </div>
          </div>
          <div className={Styles.simulation}>
            {/* {(!selectedFabric || !selectedCategory) */}
            {(!fabricCode || !selectedCategoryCode)
              ?
              <div className={Styles.empty}>
                <img src={Images.SIMULATION_PLACEHOLDER} alt='' />
                <p>Select <span>Category</span> and <span>Fabric</span> To Preview Simulation</p>
              </div>

              :
              (
                width > 768

                  ?

                  <div className={Styles.notEmpty}>
                    <div className={Styles.imgSelector}>
                      {/* {images?.filter(obj => obj?.src)?.map((each, i) => (
                        <button
                          key={i}
                          onClick={() => setSelectedImg(each)}
                          className={selectedImg?.id === each?.id ? Styles.active : ''}
                        >
                          <img src={each?.src} alt='' />
                        </button>
                      ))} */}
                      {imagesV2?.map((each, i) => (
                        <button
                          key={i}
                          onClick={() => setSelectedImg(each)}
                          className={selectedImg === each ? Styles.active : ''}
                        >
                          {/* <img src={each} alt='' /> */}
                          <Avatar aspectRatio={'unset'} src={each} borderRadius='0px' size='100%' />
                        </button>
                      ))}
                    </div>
                    <div className={Styles.selectedImg}>
                      <img src={selectedImg} alt='' />
                    </div>
                  </div>

                  :
                  <div className={Styles.sliderWrapper}>
                    <Slider
                      // setIndex={setActiveIndex}
                      showDots={true}
                      showArrows={false}
                    >
                      {images?.map((each, i) => (
                        <div
                          key={i}
                          className={Styles.each}
                        >
                          <img src={each?.src} alt='' />
                        </div>
                      ))}
                    </Slider>
                  </div>
              )

            }
          </div>
        </div>
        {
          selectedFabric
          &&
          <div className={Styles.bottom}>
            <Avatar
              src={selectedFabric?.img}
              size='128px'
              borderRadius='8px'
            />
            <div className={Styles.info}>
              <h5>{selectedFabric?.name}</h5>
              <div className={Styles.bottom}>
                <div>
                  <h6>Descriptions</h6>
                  <p>{selectedFabric?.descriptions}</p>
                </div>
                {/* <div>
                  <h6>Details</h6>
                  <p>{selectedFabric?.details}</p>
                </div> */}
              </div>
            </div>
          </div>
        }

        {
          selectedFabric
          &&
          <div className={Styles.actions}>
            {/* <Button
              variant='secondary'
              withIcon
              iconName={'shopping-cart'}
              buttonText='Add to Cart'
              size='lg'
              onClick={() => {
                const defaultCart = localStorage?.getItem('cart') ? JSON.parse(localStorage?.getItem('cart')) : []
                localStorage?.setItem('cart', JSON?.stringify([...defaultCart, selectedFabric]))
                setShowAddToCart(true)
              }}
            /> */}
            <Button
              variant='primary'
              withIcon
              iconName={'arrow-long-right'}
              iconPlacement='end'
              buttonText='Start measure'
              size='lg'
              onClick={() => navigate(`/product/collection/start-measure/${fabricCode?.replaceAll('/', '::')}?c=${selectedCategory.label}`)}
            />
          </div>
        }

      </div>
      {/* <ModalWrapper
        show={showAddToCart}
        desktopPositioning='center'
        mobilePositioning='center'
      >
        {showAddToCart
          &&
          <AddToCartModal
            src={selectedFabric?.img}
            name={selectedFabric?.name}
            size={selectedFabric?.size || ''}
            price={selectedFabric?.price}
            id={selectedFabric?.id}
            data={selectedFabric}
          />}

      </ModalWrapper> */}

    </div>
  )
}