const xlsx = require('xlsx');

export const readUploadFile = (file, setJSON = () => { }) => {
  // e.preventDefault();
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);
      setJSON(json);
    };
    reader.readAsArrayBuffer(file[0]);
  }
}