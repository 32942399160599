import ModalHeader from '@Atom/ModalHeader'
import Styles from './style.module.scss'
import Button from '@Atom/Button'
import useWindowSize from '@Hooks/useWindowSize'

export default function FilterModal({
  children,
  handleClose = () => { },
  extraHeader,
  handleReset = () => { },
  handleApply = () => { },
}) {

  const {width} = useWindowSize()

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'Filter'}
        withIcon
        iconName="filter"
        withCloseButton
        isSticky
        extraComponent={extraHeader}
        handleClose={handleClose}
        paddingX={width <= 768 ? '16px' : '24px'}
        paddingY={width <= 768 ? '16px' : '24px'}
      />
      <div className={Styles.content}>
        {children}
      </div>
      <div className={Styles.actionsWrapper}>
        <Button
          type='button'
          variant='secondary'
          buttonText='Reset'
          size='lg'
          onClick={handleReset}
        />
        <Button
          type='button'
          variant='primary'
          buttonText='Apply Filter'
          withIcon
          iconName={'arrow-long-right'}
          iconPlacement='end'
          size='lg'
          onClick={handleApply}
          customClassName={Styles.applyButton}
        />
      </div>
    </div>
  )
}