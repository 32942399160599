import { API, fileBaseUrl } from "@Config/api";
import { createFile } from "@Helpers/createFile";

export function getCustomers(
  page,
  limit,
  search,
  startDate = '',
  endDate = '',
  numOfItemsLow = '',
  numOfItemsHigh = '',
  numOrdersLow = '',
  numOrdersHigh = '',
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/customer?page=${page}&limit=${limit}&search=${search}&type=TABLE&startDate=${startDate}&endDate=${endDate}&numOfItemsLow=${numOfItemsLow}&numOfItemsHigh=${numOfItemsHigh}&numOrdersLow=${numOrdersLow}&numOrdersHigh=${numOrdersHigh}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}

export function getCustomerList() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/customer?type=LIST`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getCustomerByCode(code, startDate = '', endDate = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/customer/${code}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          startDate,
          endDate
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCustomer(code, startDate='', endDate='',) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/customer/by/${code}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          startDate,
          endDate
        }

      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}


export function updateSizeMeasurement(id, payload) {
  const dataToSend = new FormData()
  dataToSend.append("customerCode", id)
  dataToSend.append("name", payload.name)
  dataToSend.append("remark", payload.remark || '')
  for (let i = 0; i < payload.size.length; i++) {
    dataToSend.append(`measurement[${payload.size[i].name}]`, payload.size[i].value)
  }

  const photosToMap = payload?.photos?.filter(obj => obj?.src)


  for (let i = 0; i <photosToMap.length; i++) {
    dataToSend.append(`photos[${i}][angle]`,photosToMap[i].angle)
    dataToSend.append(`photos[${i}][remark]`,photosToMap[i].remark)
  }

  for (let i = 0; i <photosToMap.length; i++) {
    dataToSend.append(`images`,photosToMap[i].src)
  }
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/pattern`, dataToSend, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (err) {
      reject(err)
    }
  })
}
export function editSizeMeasurement(id, payload, code = '') {

  return new Promise(async (resolve, reject) => {
    try {
      const dataToSend = new FormData()
      dataToSend.append("name", payload.name)
      dataToSend.append("remark", payload.remark)
      for (let i = 0; i < payload.size.length; i++) {
        dataToSend.append(`measurement[${payload.size[i].name}]`, payload.size[i].value)
      }

      const photosToMap = payload?.photos?.filter(obj => obj?.src || obj?.uri)

      for (let i = 0; i < photosToMap?.length; i++) {
        dataToSend.append(`photos[${i}][angle]`, photosToMap[i].angle)
        dataToSend.append(`photos[${i}][remark]`, photosToMap[i].remark)
      }

      for (let i = 0; i < photosToMap?.length; i++) {
        const file = photosToMap[i]?.src instanceof File ? photosToMap[i].src : (
          payload?.photos[i].uri ? await createFile(fileBaseUrl + photosToMap[i].uri?.replaceAll(fileBaseUrl, '')) : ''
        )
        dataToSend.append(`files`, file)
      }
      const { data } = await API.patchForm(`/pattern/${code}`, dataToSend, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (err) {
      reject(err)
    }
  })
}
export function deletePattern(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(`/pattern/${code}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function addCustomer(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/customer`, payload, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
