import { API } from "@Config/api";

export function getWorkshops(page, limit, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/process?page=${page}&limit=${limit}&search=${search}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getWorkshopsReqeust(page, limit, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/process?page=${page}&limit=${limit}&search=${search}&type=REQUEST`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getWorkshopById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/process/${id}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateStatus(id, status) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/process/status/${id}`,
        { status },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateStatusDelivery(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/deliver`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateSchedule(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/process/delivery/${id}`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateWorker(payload, id, type) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/process/assign/${id}?type=${type}`,
        { code: payload },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
