import ModalWrapper from '@Atom/ModalWrapper'
import { readUploadFile } from '@Helpers/readUploadFile'
import AddStockModal from '@Molecule/_modals/AddModals/Stock'
import StockFilterModal from '@Molecule/_modals/FilterModals/Stock'
import StocksFileOverviewModal from '@Molecule/_modals/StocksFileOverviewModal'
import StockListLayout from '@Organism/Stock/StockList'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Styles from './style.module.scss'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function StockListPage() {
  const { packageStockList, packageDeliverSync } = useLoaderData()
  const [showFilter, setShowFilter] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [trigger, setTrigger] = useState('')

  const [file, setFile] = useState(null)
  const [read, setRead] = useState(null)
  const [showFileOverview, setShowFileOverview] = useState(false)

  const readFile = useCallback(() => {
    if (file) {
      setShowFileOverview(true)
      setRead(readUploadFile([file], setRead))
    }
  }, [file])

  useEffect(() => {
    readFile()
  }, [readFile])


  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <StockListLayout
            setShowFilter={setShowFilter}
            setShowAdd={setShowAdd}
            trigger={trigger}
            setTrigger={setTrigger}
            isLoading={true}
            defaultData={{ totalPage: 0, total: 0, products: [] }}
            defaultSync={{
              couriers: [],
              cps: []
            }}
          />
          // <>loading</>
        }
      >
        <Await
          resolve={packageStockList}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageStockList) => (
            <Suspense
              fallback={
                <StockListLayout
                  setShowFilter={setShowFilter}
                  setShowAdd={setShowAdd}
                  trigger={trigger}
                  setTrigger={setTrigger}
                  isLoading={true}
                  defaultData={packageStockList?.data}
                  defaultSync={{
                    couriers: [],
                    cps: []
                  }}
                />
                // <>loading</>
              }
            >
              <Await
                resolve={packageDeliverSync}
                errorElement={<AsyncErrorBoundary />}
              >
                {(packageDeliverSync) => (
                  <StockListLayout
                    setShowFilter={setShowFilter}
                    setShowAdd={setShowAdd}
                    trigger={trigger}
                    setTrigger={setTrigger}
                    defaultData={packageStockList?.data}
                    defaultSync={packageDeliverSync?.data}
                  />
                )}
              </Await>
            </Suspense>


          )}
        </Await>


      </Suspense>
      <div>
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={showFilter}
          setShow={setShowFilter}
        >
          <StockFilterModal
            handleClose={() => {
              setShowFilter(false)
              // setTrigger(Math.random())
            }}
            trigger={trigger}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showAdd}
          setShow={setShowAdd}
        >
          <AddStockModal
            handleClose={() => setShowAdd(false)}
            setFile={setFile}
            setShowFileOverview={setShowFileOverview}
            setTrigger={setTrigger}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showFileOverview}
        >
          <StocksFileOverviewModal
            file={file}
            json={read}
            onClickCancel={() => {
              setShowFileOverview(false)
              setShowAdd(true)
            }}
            onSuccess={() => {
              setShowFileOverview(false)
              setFile(null)
              setTrigger(makeRandomString(5))
              setShowAdd(false)
            }}
          />

        </ModalWrapper>
      </div>

      {/* <ModalWrapper
        show={true}
        mobilePositioning='bottom'
      >
        <WideErrorModal 
        withButton
        />
      </ModalWrapper> */}
    </div>
  )
}