import AlterLayout from '@Organism/Product/Alter'
import Styles from './style.module.scss'
import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function AlterPage() {
  const { customerList } = useLoaderData()
  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <AlterLayout
            isLoading={true}
            customerList={[]}
          />
        }
      >
        <Await
          resolve={customerList}
          errorElement={<AsyncErrorBoundary />}
        >
          {(customerList) => (
            <AlterLayout
              customerList={customerList?.data}
            />
          )}
        </Await>

      </Suspense>
    </div>
  )
}