import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function Checkbox({
  isChecked = false,
  setIsChecked = () => { },
  size = '24px',
  iconSize = '18px',
  disabled=false,
  hideOnDisabled = false
}) {


  if(disabled && hideOnDisabled){
    return (
      <></>
    )
  }

  return (
    <div
      className={`${Styles.container} ${isChecked ? Styles.checked : ''}`}
      style={{ width: size, height: size }}
      onClick={()=> setIsChecked(!isChecked)}
    >
      {isChecked && <Icon icon='check' size={iconSize} />}
    </div>
  )
}