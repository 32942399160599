/* eslint-disable no-unused-vars */
import { sizingTypes } from '@Assets/data/sizingTypes'
import { stockTypes } from '@Assets/data/stockTypes'
import Button from '@Atom/Button'
import ModalWrapper from '@Atom/ModalWrapper'
import { fileBaseUrl } from '@Config/api'
import { allHasValueObjectChecker } from '@Helpers/allHasValueObjectChecker'
import { makeRandomString } from '@Helpers/makeRandomString'
import { omitKey } from '@Helpers/omitKey'
import InputField from '@Molecule/InputField'
import MeasurementCard from '@Molecule/MeasurementCard'
import TextAreaField from '@Molecule/TextAreaField'
import AddToCartConfirmationModal from '@Molecule/_modals/AddToCartConfirmationModal'
import AddToCartModal from '@Molecule/_modals/AddToCartModal'
import { addCustomer, deletePattern, editSizeMeasurement, getCustomerByCode, updateSizeMeasurement } from '@Services/customer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'
import MeasurementField from '@Molecule/MeasurementField'
import Icon from '@Atom/Icon'
import SampleCard from '@Molecule/SampleCard'
import WideErrorModal from '@Molecule/_modals/WideErrorModal'

export default function AlterLayout({ customerList, isLoading }) {
  const navigate = useNavigate()
  const [customerCode, setCustomerCode] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [error, setError] = useState(null)


  const [showAddToCart, setShowAddToCart] = useState(false);

  const [measurementToEdit, setMeasurementToEdit] = useState(null)



  const defaultMeasurements = useMemo(() => {
    return sizingTypes?.map(obj => {
      return {
        label: obj?.title,
        title: obj?.title,
        name: obj?.name,
        value: 0,
        denom: 'cm'
      }
    })
  }, [])


  const [form, setForm] = useState({
    customerCode: '',
    customerName: '',
    customerAddress: '',
    customerWhatsApp: '',
    customerEmail: '',
    notes: '',
    isAlter: true,
    name: '',
    isMeasured: true,
    isCustomSizing: false,
    showMeasurementStatus: true,
    size: '-',
    category: '',
    price: '',
    measurements: defaultMeasurements,
    photos: [
      {
        id: 1,
        name: '',
        src: null
      },
      {
        id: 1,
        name: '',
        src: null
      },
      {
        id: 1,
        name: '',
        src: null
      },
    ]
  })

  useEffect(() => {
    const found = customerList?.find(obj => obj?.name?.toLowerCase() === form?.customerName?.toLowerCase())
    if (found) {
      setForm(prev => {
        return {
          ...prev,
          customerCode: found?.code,
          customerAddress: found?.address,
          customerWhatsApp: found?.phoneNumber,
          customerEmail: found?.email,
        }
      })
    }
  }, [customerList, form?.customerName])

  const [newCustomer, setNewCustomer] = useState(false)


  const [sizeMeasurement, setSizeMeasurement] = useState([]);
  const [selectedMeasurementCode, setSelectedMeasurementCode] = useState("");
  const [selectedMeasurementId, setSelectedMeasurementId] = useState(0);
  const [isCustomMeasurement, setIsCustomMeasurement] = useState(false);

  const [editCustomNameMode, setEditCustomNameMode] = useState(false);


  const [measurementFormTemplate, setMeasurementFormTemplate] = useState({
    size: sizingTypes?.map(obj => {
      return {
        name: obj?.name,
        title: obj?.title,
        value: ''
      }
    }) || [],
    name: `Size ${makeRandomString(5)}`,
    photos: [
      {
        id: 1,
        name: "",
        src: null,
        angle: "FRONT",
        remark: "",
      },
      {
        id: 2,
        name: "",
        src: null,
        angle: "SIDE",
        remark: "",
      },
      {
        id: 3,
        name: "",
        src: null,
        angle: "BACK",
        remark: "",
      },
      {
        id: 4,
        name: "",
        src: null,
        angle: "FRONT-1",
        remark: "",
      },
      {
        id: 5,
        name: "",
        src: null,
        angle: "SIDE-1",
        remark: "",
      },
      {
        id: 6,
        name: "",
        src: null,
        angle: "BACK-1",
        remark: "",
      },
    ],
    remark: "",
  });

  const fetchSize = useCallback(async () => {
    try {
      if (customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === form?.customerWhatsApp?.toUpperCase())?.code || '') {
        // const code = customerList.find(
        //   (el) => el.name === form.customerName
        // ).code;
        const code = customerList?.find(obj => obj?.phoneNumber?.toUpperCase() === form?.customerWhatsApp?.toUpperCase())?.code || ''
        let fetch = await getCustomerByCode(code);
        const res = fetch.data;
  
        for (let i = 0; i < res.length; i++) {
          const arr = [];
          const size = res[i].measurement;
          const keys = Object.keys(size);
          for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            const filter = sizingTypes.map((el) => el.name);
            for (let k = 0; k < filter.length; k++) {
              if (filter[k] === key) {
                arr.push({
                  name: filter[k],
                  title: sizingTypes[k].title,
                  value: size[key],
                });
              }
            }
          }
          res[i].size = arr;
        }
        setSelectedMeasurementCode("#" + res[0]?.code);
        setSelectedMeasurementId(0);
  
        setSizeMeasurement(res?.map(r => {
          return {
            ...r,
            photos: ['FRONT', 'SIDE', 'BACK', 'FRONT-1', 'SIDE-1', 'BACK-1']
              // eslint-disable-next-line array-callback-return
              ?.map((p) => {
                const found = r?.photos?.find(obj => obj?.angle === p)
                // if(found) {
                return {
                  angle: p,
                  remark: found?.remark || p,
                  uri: found?.uri || '',
                  _id: found?._id || ''
                }
                // }
              })
  
          }
        }));
        setMeasurementFormTemplate({
          size: sizingTypes?.map(obj => {
            return {
              name: obj?.name,
              title: obj?.title,
              value: ''
            }
          }) || [],
          name: `Size ${makeRandomString(5)}`,
          photos: [
            {
              id: 1,
              name: "",
              src: null,
              angle: "FRONT",
              remark: "",
            },
            {
              id: 2,
              name: "",
              src: null,
              angle: "SIDE",
              remark: "",
            },
            {
              id: 3,
              name: "",
              src: null,
              angle: "BACK",
              remark: "",
            },
            {
              id: 4,
              name: "",
              src: null,
              angle: "FRONT-1",
              remark: "",
            },
            {
              id: 5,
              name: "",
              src: null,
              angle: "SIDE-1",
              remark: "",
            },
            {
              id: 6,
              name: "",
              src: null,
              angle: "BACK-1",
              remark: "",
            },
          ],
          remark: "",
        });
      } else {
        setSizeMeasurement([]);
      }
    } catch (err) {
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
      console.log(err?.response?.data?.error || err, 'error on fetching sizes')
    }
    
  }, [customerList, form?.customerWhatsApp])


  useEffect(() => {

    if (!newCustomer) {
      fetchSize();
    }
  }, [fetchSize, newCustomer])

  const newCustomerForm = useMemo(() => {
    const formData = new FormData()
    formData.append('name', form?.customerName)
    formData.append('phoneNumber', '62' + form?.customerWhatsApp)
    formData.append('email', form?.customerEmail)
    formData.append('address', form?.customerAddress)
    if (newCustomer) {
      for (let i = 0; i < measurementFormTemplate?.size?.length; i++) {
        formData.append(`size[${measurementFormTemplate?.size[i]?.name}]`, measurementFormTemplate?.size[i]?.value)
      }
      formData.append('size[name]', measurementFormTemplate?.name)
      const photosToMap = measurementFormTemplate?.photos?.filter(obj => obj?.src)
      for (let i = 0; i < photosToMap?.length; i++) {
        formData.append(`photos[${i}][angle]`, photosToMap[i]?.angle)
        formData.append(`photos[${i}][remark]`, photosToMap[i]?.remark)
        formData.append('images', photosToMap[i]?.src || '')
      }
    }

    return formData
  }, [form?.customerAddress, form?.customerEmail, form?.customerName, form?.customerWhatsApp, measurementFormTemplate?.name, measurementFormTemplate?.photos, measurementFormTemplate?.size, newCustomer])

  const addToCart = useCallback(() => {
    // setCustomerCode(customerList?.find(obj => obj?.name?.toUpperCase() === form?.customerName?.toUpperCase())?.code || '')
    setCustomerCode(customerList?.find(obj => obj?.name?.toUpperCase() === form?.customerName?.toUpperCase())?.code || '')
    // const selectedSize =
    //   !newCustomer
    //     ?
    //     (sizeMeasurement.length > 1 && !isCustomMeasurement
    //       ? sizeMeasurement[selectedMeasurementId]
    //       : measurementFormTemplate)
    //     :
    //     sizeMeasurement[selectedMeasurementId]
    //   ;

    const selectedSize =
      sizeMeasurement.length > 0 && !isCustomMeasurement
        ? sizeMeasurement[selectedMeasurementId]
        : measurementFormTemplate;

    let dataFromStorage = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];
    const items = {
      id: new Date().getTime(),
      isReadyToWear: false,
      isAlter: true,
      isFabricOnly: false,
      label: "Alter",
      fabrics: [
        {
          type: form?.category,
          name: form?.name,
          code: makeRandomString(5),
          qty: "1",
          price: form?.price,
          imgUrl: '',
        },
      ],
      notes: form?.notes,
    };


    if (dataFromStorage.some((el) => el.customerName === form.customerName)) {
      dataFromStorage = dataFromStorage.map((el) =>
        el.customerName === form.customerName
          ? {
            ...el,
            sizes:
              el.sizes.some((el1) => el1.sizeName === selectedSize?.name) &&
                selectedSize?.name !== "Default"
                ? el.sizes.map((el1) =>
                  el1.sizeName === selectedSize.name
                    ? {
                      ...el1,
                      items: [...el1.items, items],
                      measurements: selectedSize?.size,
                      code: selectedSize?.code || "",
                    }
                    : el1
                )
                : [
                  ...el.sizes,
                  {
                    measurements: selectedSize.size,
                    code: selectedSize?.code || "",
                    sizeName: selectedSize.name,
                    items: [items],
                    photos: selectedSize.photos || [],
                    id: selectedSize?.code || "",
                  },
                ],
          }
          : el
      );
    } else {
      dataFromStorage.push({
        ...form,
        customerWhatsApp: '62' + form?.customerWhatsApp,
        customerCode: customerList?.find(obj => obj?.name?.toUpperCase() === form?.customerName?.toUpperCase())?.code || '',
        id: new Date().getTime(),
        sizes: [
          {
            measurements: selectedSize?.size,
            sizeName: selectedSize?.name,
            items: [items],
            photos: selectedSize.photos || [],
            id: new Date().getTime(),
            code: selectedSize?.code || "",
          },
        ],
      });
    }
    return dataFromStorage;
  }, [customerList, form, isCustomMeasurement, measurementFormTemplate, selectedMeasurementId, sizeMeasurement]);

  const [onSavingSize, setOnSavingSize] = useState(false)

  const handleSaveAddMeasurement = useCallback(async () => {
    try {
      setOnSavingSize(true)
      const res = await updateSizeMeasurement(
        form.customerCode,
        measurementFormTemplate
      );
      if (res?.status) {
        setSelectedMeasurementCode('#' + res?.data?.code)
        setIsCustomMeasurement(false);
        fetchSize();
      }
      setOnSavingSize(false)
    } catch (err) {
      setOnSavingSize(false)
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
      console.log(err)
    }

  }, [fetchSize, form.customerCode, measurementFormTemplate]);

  const [isAddingToCart, setIsAddingToCart] = useState(false)

  const handleAddToCart = useCallback(async () => {
    if (newCustomer) {
      try {
        const response = await addCustomer(newCustomerForm)
        setCustomerCode(response?.data?.code)
        const addToCartObj = addToCart()?.map(obj => {
          if (obj?.customerWhatsApp === '62' + form?.customerWhatsApp) {
            return {
              ...obj,
              customerCode: response?.data?.code,
              sizes: [
                {
                  ...obj?.sizes[0],
                  code: response?.data?.size?.code,
                  // sizeName: response?.data?.size?.name,
                  photos: response?.data?.size?.photos
                }
              ]
            }
          } else {
            return {
              ...obj
            }
          }
        })
        localStorage?.setItem("cart", JSON?.stringify(addToCartObj));
        setShowAddToCart(true);

      } catch (err) {
        console.log(err?.response?.data?.error || err, 'error on creating new customer')
        setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
      }
    } else {
      localStorage?.setItem("cart", JSON?.stringify(addToCart()));
      setShowAddToCart(true);

    }
  }, [addToCart, form?.customerWhatsApp, newCustomer, newCustomerForm])


  // const [alterForm, setAlterForm]

  // const [sizingMode, setSizingMode] = useState('form')

  const sizeTemplate = useMemo(() => {
    return {
      size: defaultMeasurements,
      photos: [
        {
          id: 1,
          name: '',
          src: null
        },
        {
          id: 2,
          name: '',
          src: null
        },
        {
          id: 3,
          name: '',
          src: null
        },
      ]
    }
  }, [defaultMeasurements])

  const [sizes, setSizes] = useState([
    {
      code: makeRandomString(5),
      ...sizeTemplate
    }
  ])

  // useEffect(() => {
  //   if (newCustomer) {
  //     if (sizes?.length) {
  //       setSelectedMeasurementCode('#' + sizes[0]?.code)
  //       setSelectedMeasurementId(0)
  //       setSizeMeasurement(sizes)
  //     } else {
  //       setSizeMeasurement(prev => [
  //         ...prev,
  //         {
  //           code: makeRandomString(5),
  //           isEditMode: true,
  //           ...sizeTemplate
  //         }])
  //     }

  //   }
  // }, [newCustomer, sizeTemplate, sizes])

  console.log(form?.price, 'price')

  const disableAdd = useMemo(() => {
    // if (!allHasValueObjectChecker(omitKey('customerCode', form)) || isCustomMeasurement) {
    //   return true
    // } return false

    // eslint-disable-next-line eqeqeq
    if(!form?.name || !form?.category || (!form?.price && form?.price != 0)) {
      return true
    }
    if (newCustomer) {
      if (!form?.customerName
        || !form?.customerAddress
        || !form?.customerWhatsApp
        || !measurementFormTemplate?.photos[0]?.src
        || !measurementFormTemplate?.photos[1]?.src
        || !measurementFormTemplate?.photos[2]?.src
      ) {
        return true
      }
    } else {
      return isCustomMeasurement 
      || !form?.customerName
    }
  }, [form?.category, form?.customerAddress, form?.customerName, form?.customerWhatsApp, form?.name, form?.price, isCustomMeasurement, measurementFormTemplate?.photos, newCustomer])

  const [customerSearch, setCustomerSearch] = useState('')


  const template = useMemo(() => {
    return [
      {
        title: 'Customer',

        component:
          !newCustomer
            ?
            <div className={Styles.customer}>
              <InputField
                placeholder={'Select Existing Customer'}
                isDropdown
                // dropdownOptions={customerList?.map((obj) => obj?.name)}
                dropdownOptions={customerList?.map((obj) => `${obj?.name} - ${obj?.phoneNumber}`)}
                // value={form?.customerName}
                value={customerSearch}
                // setValue={(newVal) => setForm(f => {
                //   return {
                //     ...f,
                //     customerName: newVal
                //   }
                // })}
                setValue={(newVal) => {
                  setCustomerSearch(newVal)
                  const findCustomer = newVal?.includes(' - ')
                    ? customerList.find(
                      (el) => el?.phoneNumber === newVal?.split(' - ')[1]
                    )
                    : null
                  return setForm((f) => {
                    return {
                      ...f,
                      customerName: newVal?.includes(' - ') ? newVal?.split(' - ')[0] : newVal,
                      customerAddress: findCustomer?.address,
                      customerWhatsApp: findCustomer?.phoneNumber,
                      customerEmail: findCustomer?.email,
                      customerCode: findCustomer?.code,
                    };
                  });
                }}
              />
              <div className={Styles.orSeparator}>
                <div />
                <span>OR</span>
                <div />
              </div>
              <Button
                variant='secondary'
                withIcon
                iconName={'plus'}
                size='lg'
                buttonText='Add New Customer'
                onClick={() => setNewCustomer(true)}
              />

            </div>
            :
            <div className={Styles.new}>
              <Button
                variant='tertiary'
                buttonText='Cancel'
                withIcon
                iconName={'x-mark'}
                customClassName={Styles.float}
                disableActive
                disableHover
                onClick={() => setNewCustomer(false)}
              />
              <InputField
                title={'Customer Name'}
                placeholder={'Customer name'}
                value={form?.customerName}
                setValue={(newVal) => setForm(f => {
                  return {
                    ...f,
                    customerName: newVal
                  }
                })}
              />
              <InputField
                title={'Customer Address'}
                placeholder={'Customer address'}
                value={form?.customerAddress}
                setValue={(newVal) => setForm(f => {
                  return {
                    ...f,
                    customerAddress: newVal
                  }
                })}
              />
              <InputField
                title={'Customer Whatsapp'}
                type='tel'
                placeholder={'8xxxx'}
                value={form?.customerWhatsApp}
                setValue={(newVal) => setForm(f => {
                  return {
                    ...f,
                    customerWhatsApp: newVal
                  }
                })}
              />
              <InputField
                title={'Customer Email'}
                placeholder={'Customer email'}
                value={form?.customerEmail}
                setValue={(newVal) => setForm(f => {
                  return {
                    ...f,
                    customerEmail: newVal
                  }
                })}
              />
            </div>
      },

      {
        title: 'Product',
        component:
          <div className={Styles.details}>
            <InputField
              title={'Product Name'}
              placeholder={'Product Name'}
              value={form?.name}
              setValue={(newVal) => setForm(f => {
                return {
                  ...f,
                  name: newVal
                }
              })}
            />
            <InputField
              title={'Category'}
              placeholder={'Select Category'}
              value={form?.category}
              setValue={(newVal) => {
                setForm(f => {
                  return {
                    ...f,
                    category: newVal
                  }
                })
              }}
              isDropdown
              dropdownOptions={['SUIT', 'TROUSER', 'SHIRT']}
            />
            {/* <InputField
              title={'Description'}
              placeholder={'Product Details'}
              value={form?.description}
              setValue={(newVal) => setForm(f => {
                return {
                  ...f,
                  description: newVal
                }
              })}
            /> */}

          </div>
      },
      {
        title: 'Size Measurements',
        component:
          newCustomer
            ?
            // <div className={Styles.sizesWrapper}>
            //   {sizeMeasurement?.map((el, idx) => (
            //     <MeasurementCard
            //       key={idx}
            //       index={idx}
            //       // key={idx}
            //       // withRadioButton={false}
            //       onSelect={(val) => {
            //         setSelectedMeasurementCode(val);
            //         setSelectedMeasurementId(idx);
            //       }}
            //       isChecked={"#" + el.code === selectedMeasurementCode}
            //       name={el.name}
            //       code={"#" + el.code}
            //       measurements={el.size}
            //       photos={el.photos.map((el) => el?.uri ? fileBaseUrl + el?.uri : el)}
            //       isEditMode={true}
            //       sizes={sizeMeasurement}
            //       setSizes={setSizeMeasurement}
            //       defaultMeasurements={defaultMeasurements}
            //     />
            //   ))}

            //   <Button
            //     buttonText='Add Other Measurements'
            //     withIcon
            //     iconName={'plus'}
            //     variant='secondary'
            //     size='lg'
            //     // onClick={() => setSizes(prev => [...prev, { id: makeRandomString(5), ...sizeTemplate }])}
            //     onClick={() => setIsCustomMeasurement(true)}
            //   />
            // </div>

            <div className={Styles.customSize}>
              <div>
                <div className={Styles.customName}>
                  {editCustomNameMode ? (
                    <input
                      placeholder="Size Name"
                      value={measurementFormTemplate?.name}
                      onChange={(e) =>
                        setMeasurementFormTemplate((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  ) : (
                    <p>{measurementFormTemplate?.name}</p>
                  )}
                  {!editCustomNameMode && (
                    <button
                      onClick={() => {
                        setEditCustomNameMode(true);
                        // setMeasurementFormTemplate((prev) => ({
                        //   ...prev,
                        //   name: "",
                        // }));
                      }}
                    >
                      <Icon icon={"pencil"} size={24} />
                    </button>
                  )}
                </div>

                {!newCustomer
                  &&

                  <Button
                    variant="tertiary"
                    buttonText="Back to default size"
                    withIcon
                    iconName={"x-mark"}
                    disableActive
                    disableHover
                    onClick={() => setIsCustomMeasurement(false)}
                  />
                }
              </div>
              <div className={Styles.field}>
                {measurementFormTemplate?.size?.map((each, i) => (
                  <MeasurementField
                    key={i}
                    title={each?.title}
                    value={each?.value}
                    setValue={(newVal) => {
                      const newTemplate = measurementFormTemplate?.size?.map(
                        (obj) => {
                          if (obj?.name === each?.name) {
                            return {
                              ...obj,
                              value: newVal,
                            };
                          }
                          return obj;
                        }
                      );
                      setMeasurementFormTemplate((prev) => ({
                        ...prev,
                        size: newTemplate,
                      }));
                    }}
                    placeholder={each?.placeholder}
                    denom={"cm"}

                  />
                ))}
              </div>
              <p className={Styles.titleSize}>Photos</p>
              <div className={Styles.photos}>
                {measurementFormTemplate.photos?.map((eachP, iP) => (
                  <SampleCard
                    key={iP}
                    // isFullWidth
                    size="100%"
                    isOverview
                    src={eachP?.src}
                    setSrc={(newSrc) => {
                      const newPhotos = [...measurementFormTemplate.photos];
                      newPhotos[iP].src = newSrc || "";
                      measurementFormTemplate.photos = newPhotos;
                      setMeasurementFormTemplate((prev) => ({
                        ...prev,
                        photos: newPhotos,
                      }));
                    }}
                  />
                ))}
              </div>
              {!newCustomer && (
                <div style={{ display: "grid", marginTop: "18px" }}>
                  <Button
                    variant="secondary"
                    withIcon
                    iconName={"saved"}
                    size="lg"
                    buttonText="Save"
                    onClick={() => {
                      handleSaveAddMeasurement();
                      // setSelectedMeasurementCode("");
                      // setSelectedMeasurementId("");
                    }}
                  />
                </div>
              )}
            </div>
            :

            (
              !isCustomMeasurement
                ?
                <div className={Styles.sizesWrapper}>
                  {sizeMeasurement.map((el, idx) => (
                    <div key={idx}>
                      <MeasurementCard
                        index={idx}
                        // key={idx}
                        // withRadioButton={false}
                        onSelect={(val) => {
                          setSelectedMeasurementCode(val);
                          setSelectedMeasurementId(idx);
                        }}
                        isChecked={"#" + el.code === selectedMeasurementCode}
                        name={el.name}
                        code={"#" + el.code}
                        measurements={el.size}
                        photos={el.photos.filter(obj => obj?.uri)?.map((el) => el?.uri ? fileBaseUrl + el?.uri : el)}
                        isEditMode={el?.code === measurementToEdit?.code}
                        sizes={sizeMeasurement}
                        setSizes={setSizeMeasurement}
                        defaultMeasurements={defaultMeasurements}
                        handleEdit={() => setMeasurementToEdit(el)}
                        handleDelete={async () => {
                          try {
                            await deletePattern(el?.code)
                            await fetchSize()
                          } catch (err) {
                            setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
                            console.log(err?.response?.data?.error || err, 'error on creating new customer')
                          }
                        }}
                        withCancelEditButton
                        onCancelEdit={() => setMeasurementToEdit(null)}
                        hasToClickSave
                        withSaveButton
                        onSave={async () => {
                          try {
                            await editSizeMeasurement(
                              customerList?.find(obj => obj?.name?.toUpperCase() === form?.customerName?.toUpperCase())?.code || '',
                              el,
                              el?.code
                            )
                            await fetchSize()
                            setMeasurementToEdit(null)
                          } catch (err) {
                            setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
                          }
                        }}
                      />
                    </div>
                  ))}
                  <Button
                    buttonText='Add Custom Measurements'
                    withIcon
                    iconName={'plus'}
                    variant='secondary'
                    size='lg'
                    // onClick={() => setSizeMeasurement(prev => [
                    //   ...prev,
                    //   {
                    //     code: makeRandomString(5),
                    //     name: `CUSTOM MEASUREMENT ${makeRandomString(5)}`,
                    //     isEditMode: true,
                    //     ...sizeTemplate
                    //   }])}
                    onClick={() => setIsCustomMeasurement(true)}
                  />
                </div>
                :
                <div className={Styles.customSize}>
                  <div>
                    <div className={Styles.customName}>
                      {editCustomNameMode ? (
                        <input
                          placeholder="Size Name"
                          value={measurementFormTemplate.name}
                          onChange={(e) =>
                            setMeasurementFormTemplate((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }))
                          }
                        />
                      ) : (
                        <p>{measurementFormTemplate?.name}</p>
                      )}
                      {!editCustomNameMode && (
                        <button
                          onClick={() => {
                            setEditCustomNameMode(true);
                            setMeasurementFormTemplate((prev) => ({
                              ...prev,
                              name: "",
                            }));
                          }}
                        >
                          <Icon icon={"pencil"} size={24} />
                        </button>
                      )}
                    </div>

                    {!newCustomer
                      &&

                      <Button
                        variant="tertiary"
                        buttonText="Back to default size"
                        withIcon
                        iconName={"x-mark"}
                        disableActive
                        disableHover
                        onClick={() => setIsCustomMeasurement(false)}
                      />
                    }
                  </div>
                  <div className={Styles.field}>
                    {measurementFormTemplate?.size?.map((each, i) => (
                      <MeasurementField
                        key={i}
                        title={each?.title}
                        value={each?.value}
                        setValue={(newVal) => {
                          const newTemplate = measurementFormTemplate?.size?.map(
                            (obj) => {
                              if (obj?.name === each?.name) {
                                return {
                                  ...obj,
                                  value: newVal,
                                };
                              }
                              return obj;
                            }
                          );
                          setMeasurementFormTemplate((prev) => ({
                            ...prev,
                            size: newTemplate,
                          }));
                        }}
                        placeholder={each?.placeholder}
                        denom={"cm"}
                      // extraTitleLabel={
                      //   each?.name?.toLowerCase()?.includes("sleeves") ? (
                      //     <div className={Styles.radios}>
                      //       <div
                      //         onClick={() => {
                      //           setData((f) => {
                      //             return {
                      //               ...f,
                      //               sleevesType: "long",
                      //             };
                      //           });
                      //         }}
                      //       >
                      //         <Radio isChecked={data?.sleevesType === "long"} />
                      //         <span>Long</span>
                      //       </div>
                      //       <div
                      //         onClick={() => {
                      //           setData((f) => {
                      //             return {
                      //               ...f,
                      //               sleevesType: "short",
                      //             };
                      //           });
                      //         }}
                      //       >
                      //         <Radio isChecked={data?.sleevesType === "short"} />
                      //         <span>Short</span>
                      //       </div>
                      //     </div>
                      //   ) : undefined
                      // }
                      />
                    ))}
                  </div>
                  <p className={Styles.titleSize}>Photos</p>
                  <div className={Styles.photos}>
                    {measurementFormTemplate.photos?.map((eachP, iP) => (
                      <SampleCard
                        key={iP}
                        // isFullWidth
                        size="100%"
                        isOverview
                        src={eachP?.src}
                        setSrc={(newSrc) => {
                          const newPhotos = [...measurementFormTemplate.photos];
                          newPhotos[iP].src = newSrc || "";
                          measurementFormTemplate.photos = newPhotos;
                          setMeasurementFormTemplate((prev) => ({
                            ...prev,
                            photos: newPhotos,
                          }));
                        }}
                      />
                    ))}
                  </div>
                  {!newCustomer && (
                    <div style={{ display: "grid", marginTop: "18px" }}>
                      <Button
                        variant="secondary"
                        withIcon
                        iconName={"saved"}
                        size="lg"
                        disabled={onSavingSize}
                        buttonText={onSavingSize ? 'Saving Size' : "Save"}
                        onClick={() => {
                          handleSaveAddMeasurement();
                          // setSelectedMeasurementCode("");
                          // setSelectedMeasurementId("");
                        }}
                      />
                    </div>
                  )}
                </div>
            )

      },
      {
        title: 'Notes',
        component:
          <div>
            <TextAreaField
              value={form?.notes}
              setValue={(newVal) => setForm(f => {
                return {
                  ...f,
                  notes: newVal
                }
              })}
              placeholder={'Write notes here'}
            />
          </div>
      },
      {
        title: 'Pricing',
        component:
          <div className={Styles.pricing}>
            <InputField
              isCurrency
              value={form?.price}
              setValue={(newVal) => setForm(f => {
                return {
                  ...f,
                  price: newVal
                }
              })}
            />
          </div>
      },
    ]
  }, [customerList, customerSearch, defaultMeasurements, editCustomNameMode, fetchSize, form?.category, form?.customerAddress, form?.customerEmail, form?.customerName, form?.customerWhatsApp, form?.name, form?.notes, form?.price, handleSaveAddMeasurement, isCustomMeasurement, measurementFormTemplate, measurementToEdit?.code, newCustomer, onSavingSize, selectedMeasurementCode, sizeMeasurement])

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Button
          variant='tertiary'
          size='lg'
          withIcon
          iconName={'chevron-left'}
          buttonText='Back to Products'
          customIconSize={'16px'}
          fixedPadding={'0px'}
          disableActive
          onClick={() => navigate('/product', { replace: true })}
        />
        <div>
          <span>Alter</span>
        </div>
      </div>
      <div className={Styles.content}>
        {
          !newCustomer && !form?.customerName
            ?
            template?.slice(0, 2)?.map((each, i) => (
              <div
                key={i}
              >
                <span>{each?.title}</span>
                {each?.component}
              </div>
            )) :
            template?.map((each, i) => (
              <div
                key={i}
              >
                <span>{each?.title}</span>
                {each?.component}
              </div>
            ))}
      </div>
      {
        !newCustomer && !form?.customerName
          ?
          <></>
          :
          <Button
            buttonText='Add to Cart'
            size='lg'
            onClick={handleAddToCart}
            disabled={disableAdd}
          />
      }

      <ModalWrapper
        show={showAddToCart}
        desktopPositioning="center"
        mobilePositioning="center"
      >
        {showAddToCart && (
          <AddToCartModal
            src={''}
            name={form?.name}
            size={''}
            price={form?.price}
            id={''}
            data={null}
            customerID={customerCode}
          />
        )}
      </ModalWrapper>

      <ModalWrapper
        show={showConfirmation}
        mobilePositioning='center'
        desktopPositioning='center'
      >
        <AddToCartConfirmationModal
          dataToAdd={{
            id: form?.customerCode || form?.customerName,
            customerName: form?.customerName,
            customerAddress: form?.customerAddress,
            customerWhatsApp: form?.customerWhatsApp,
            customerEmail: form?.customerEmail,
            sizes: [
              {
                id: selectedMeasurementCode
              }
            ]
          }}
          setShow={setShowConfirmation}
        />
      </ModalWrapper>
      <ModalWrapper
        show={error}
        setShow={() => setError(null)}
      >
        <WideErrorModal
          code={error?.code || 500}
          error={error?.message || 'Something went wrong!'}
          withButton
          onClose={() => setError(null)}
        />

      </ModalWrapper>
    </div>
  )
}