import { StyleSheet, Font } from "@react-pdf/renderer";


Font.register({
  family: 'Helvetica-Neue',
  fonts: [
    {
      src: '@Assets/fonts/HelveticaNeue-Regular.ttf'
    },
    {
      src: '@Assets/fonts/HelveticaNeue-Medium.ttf',
      fontWeight: 'medium'
    },
  ]
})


const styles = StyleSheet.create({
  text: {
    fontSize: '12px',
    // fontWeight: 'medium',
    fontFamily: 'Helvetica-Bold'
  }
})

export default styles;