import Label from '@Atom/Label'
import Styles from './style.module.scss'
import { priceFormat } from '@Helpers/priceFormat';
import { stringToNumber } from '@Helpers/stringToNumber';

export default function PriceLabel({
  intro = 'intro goes here',
  price = '',
  changeAble,
  onChange,
}) {


  // const [currentValue, updateCurrentValue] = useState(format(+price))

  const handleChange = (e) => {
    const {
      target: { value }
    } = e;

    if (value === "") {
      // updateCurrentValue("");
      return onChange(undefined);
    }

    const valueAsNumber = stringToNumber(value);

    // updateCurrentValue(format(valueAsNumber));
    return onChange(valueAsNumber);
  };

  return (
    <Label
      title={intro}
      desc={
        <div className={Styles.price}>
          <span>Rp</span>
          <input
            type="text"
            value={priceFormat(+price)}
            onChange={handleChange}
            readOnly={!changeAble}
          />
        </div>
      }
    />
  )
}