import ModalHeader from '@Atom/ModalHeader'
import Styles from './style.module.scss'
import Button from '@Atom/Button'
import { useEffect, useMemo, useState } from 'react'
import InputField from '@Molecule/InputField'
import TextAreaField from '@Molecule/TextAreaField'
import Checkbox from '@Atom/Checkbox'
import moment from 'moment'
import { editOrder } from '@Services/order'
import { makeRandomString } from '@Helpers/makeRandomString'



export default function ScheduleSetterModal({
  handleClose = () => { },
  orderId,
  workers,
  setTrigger = () => { }
}) {

  const drivers = useMemo(() => {
    return workers?.couriers
  }, [workers?.couriers])

  const [isConfirming, setIsConfirming] = useState(false)

  const [form, setForm] = useState({
    driverId: '',
    deliveryDate: '',
    address: '',
    note: '',
    isPriority: false
  })

  const handleChange = (newVal, name) => {
    setForm({
      ...form,
      [name]: newVal
    })
  }


  const [driver, setDriver] = useState('')

  useEffect(() => {
    if (driver) {
      setForm(f => ({
        ...f,
        driverId: drivers?.find(obj => obj.name === driver)?.code
      }))
    }
  }, [driver, drivers])



  const handleCancel = () => {
    handleClose()
  }

  const handleConfirm = async () => {
    try {
      setIsConfirming(true)
      const formData = new FormData()
      formData?.append('courierCode', form?.driverId)
      formData?.append('date', moment(new Date(form?.deliveryDate))?.format('YYYY-MM-DD'))
      formData?.append('remark', form?.note)
      formData?.append('status', 'SCHEDULED')
      await editOrder(formData, orderId)
      setIsConfirming(false)
      setTrigger(makeRandomString(5))
      handleClose()
    } catch (err) {
      setIsConfirming(false)
      console.log(err)
    }
    handleClose()
  }
  const disableConfirm = useMemo(() => {
    if (!form?.driverId
      || !form?.deliveryDate
      || isConfirming
    ) {
      return true
    } return false
  }, [form?.deliveryDate, form?.driverId, isConfirming])
  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'Set Schedule'}
        withIcon
        iconName={'calendar'}
        paddingX='2.4rem'
        paddingY='1.6rem'
        isSticky
      />
      <div className={Styles.content}>
        <InputField
          title={'Driver'}
          value={driver}
          setValue={setDriver}
          placeholder={'Select driver'}
          isDropdown={true}
          dropdownOptions={drivers?.map(obj => obj?.name)}
        />
        <InputField
          type='date'
          title={'Delivery Date'}
          value={form?.deliveryDate}
          setValue={(newVal) => handleChange(newVal, 'deliveryDate')}
        />
        {/* <TextAreaField
          title={'Address'}
          value={form?.address}
          setValue={(newVal) => handleChange(newVal, 'address')}
          placeholder={'Address details'}
        /> */}

        <TextAreaField
          title={'Note'}
          value={form?.note}
          setValue={(newVal) => handleChange(newVal, 'note')}
          placeholder={'Note to driver'}
        />


        <div className={Styles.isPriority}>
          <Checkbox
            isChecked={form?.isPriority}
            setIsChecked={(newVal) => handleChange(newVal, 'isPriority')}
          />
          <span>Set as priority</span>
        </div>


      </div>
      <div className={Styles.actionsWrapper}>
        <Button
          type='button'
          variant='secondary'
          buttonText={'Cancel'}
          size='md'
          onClick={handleCancel}
        />
        <Button
          type='button'
          variant='primary'
          buttonText={isConfirming ? 'Confirming...' : 'Confirm'}
          size='md'
          onClick={handleConfirm}
          disabled={disableConfirm}
        />

      </div>
    </div>
  )
}