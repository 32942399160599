import moment from 'moment'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'
import Tag from '@Atom/Tag'
import Label from '@Atom/Label'
import { useRef } from 'react'

export default function DateLabel({
  intro = 'intro goes here',
  date = new Date(),
  extraDate,
  changeAble,
  onChange = () => { },
  disableConvert
}) {
  const dateRef = useRef()

  return (
    <Label
      title={intro}
      desc={
        <div className={`${Styles.date} ${changeAble ? Styles.pointer : ''}`} onClick={() => changeAble && dateRef?.current?.showPicker()}>
          <Icon icon={"calendar"} size={20} />
          <span className={Styles.text}>{disableConvert ? date || 'DD/MM/YYYY' : (date ? moment(new Date(date)).format('ll') : 'DD/MM/YYYY')}</span>
          <input
            type='date'
            ref={dateRef}
            value={date && date !== 'Invalid date' ? moment(new Date(date))?.format('YYYY-MM-DD') : ''}
            onChange={onChange} />
          {
            extraDate && <Tag
              type={'blue'}
              title={moment(new Date(extraDate)).format('L')}
              noBullet
              fixedPadding={'0.2rem 0.4rem'}
              fixedFontSize={'1.2rem'}
            />
          }
        </div>}
    />
  )
}