import template from '@Assets/StockTemplate.xlsx'
import { stockTypes } from '@Assets/data/stockTypes'
import AdornmentLabel from '@Atom/AdornmentLabel'
import Avatar from '@Atom/Avatar'
import Button from '@Atom/Button'
import ModalHeader from '@Atom/ModalHeader'
import ModalWrapper from '@Atom/ModalWrapper'
import { capitalizeWords } from '@Helpers/capitalizeWords'
import { makeRandomString } from '@Helpers/makeRandomString'
import InputField from '@Molecule/InputField'
import SampleCard from '@Molecule/SampleCard'
import TextAreaField from '@Molecule/TextAreaField'
import UploadField from '@Molecule/UploadField'
import WideErrorModal from '@Molecule/_modals/WideErrorModal'
import { addSimulationImages } from '@Services/product'
import { addStock, checkStock } from '@Services/stock'
import Images from '@Theme/Images'
import { useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Styles from './style.module.scss'
import Switcher from '@Atom/Switcher'

export default function AddStockModal({
  handleClose = () => { },
  setFile,
  setShowFileOverview,
  setTrigger = () => { }
}) {
  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState('manual')
  // eslint-disable-next-line no-unused-vars
  const inputRef = useRef()
  const [error, setError] = useState(null)
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [type, setType] = useState('')
  const [fabricCode, setFabricCode] = useState('')
  const [qty, setQty] = useState(1)
  const [desc, setDesc] = useState('')
  const [price, setPrice] = useState('')
  const [image, setImage] = useState(null)
  const [confirmationMode, setConfirmationMode] = useState(false)
  const [found, setFound] = useState(false)
  const [simulations, setSimulations] = useState({
    trouser: {
      front: null,
      back: null,
      side: null
    },
    suit: {
      front: null,
      back: null,
      side: null
    },
    shirt: {
      front: null,
      back: null,
      side: null
    },


  })

  const [isSubmitting, setIsSubmitting] = useState(false)



  // eslint-disable-next-line no-unused-vars
  const switcherOptions = [
    {
      title: 'Upload',
      value: 'upload'
    },
    {
      title: 'Manual',
      value: 'manual'
    },
  ]

  const handleReset = () => {
    setName('')
    setType('')
    setCode('')
    setFabricCode('')
    setQty(1)
    setDesc('')
    setPrice('')
    setImage(null)
    setSimulations({
      trouser: {
        front: null,
        back: null,
        side: null
      },
      suit: {
        front: null,
        back: null,
        side: null
      },
      shirt: {
        front: null,
        back: null,
        side: null
      },

    })
    setConfirmationMode(false)
  }

  const translateType = (eng) => {
    switch (eng) {
      case 'front':
        return 'depan'
      case 'back':
        return 'belakang'
      case 'side':
        return 'samping'
      default:
        return ''
    }
  }

  const disableSubmit = useMemo(() => {
    if (
      !name
      || !type
      || !code
      || !fabricCode
      || !desc
      || !qty
      || !price
      || isSubmitting
      || !image
      // eslint-disable-next-line eqeqeq
      || qty == 0
    ) {
      return true
    } return false
  }, [code, desc, fabricCode, image, isSubmitting, name, price, qty, type])

  const filteredSimulations = useMemo(() => {
    const arr = []
    const trousers = Object.keys(simulations?.trouser).map((key) => [translateType(key), simulations?.trouser[key]])?.filter(obj => obj[1])
    const suits = Object.keys(simulations?.suit).map((key) => [translateType(key), simulations?.suit[key]])?.filter(obj => obj[1])
    const shirts = Object.keys(simulations?.shirt).map((key) => [translateType(key), simulations?.shirt[key]])?.filter(obj => obj[1])

    if (trousers?.length) {
      arr?.push({
        templateCode: 'TEMPLATE-0000001',
        simulations: trousers
      })
    }
    if (suits?.length) {
      arr?.push({
        templateCode: 'TEMPLATE-0000002',
        simulations: suits
      })
    }
    if (shirts?.length) {
      arr?.push({
        templateCode: 'TEMPLATE-0000003',
        simulations: shirts
      })
    }

    return arr

  }, [simulations?.shirt, simulations?.suit, simulations?.trouser])

  const [isConfirming, setIsConfirming] = useState(false)

  const handleConfirm = async () => {
    try {
      setIsConfirming(true)
      const  {data}  = await checkStock(code)
      setFound(data)
      setIsConfirming(false)
      setConfirmationMode(true)
    } catch (err) {
      setIsConfirming(false)
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData?.append('productCode', code)
    formData?.append('name', name)
    formData?.append('fabric', fabricCode)
    formData?.append('quantity', +qty)
    formData?.append('description', desc)
    formData?.append('price', +price)
    formData?.append('type', stockTypes?.find(obj => obj?.title === type)?.query)

    if (image) {
      // formData?.append('imageDescription[0]', 'main')
      // formData?.append('files', image)
      formData?.append('image', image)

    }

    if (type === 'ready') {
      formData?.append('size[length]', 0)
      formData?.append('size[waist]', 0)
      formData?.append('size[chest]', 0)
      formData?.append('size[collar]', 0)
      formData?.append('size[shoulders]', 0)
      formData?.append('size[sleeveLength]', 0)
      formData?.append('size[upperSleeveRim]', 0)
      formData?.append('size[lowerSleeveRim]', 0)
      formData?.append('size[foot]', 0)
      formData?.append('size[kriss]', 0)
    }



    try {
      setIsSubmitting(true)
      const { data } = await addStock(formData)

      if (filteredSimulations?.length) {
        for (let i = 0; i < filteredSimulations.length; i++) {
          const sim = filteredSimulations[i]
          const simulationFormData = new FormData()
          simulationFormData.append('productCode', data?.productCode)
          simulationFormData.append('templateCode', sim?.templateCode)

          for (let j = 0; j < sim?.simulations?.length; j++) {
            const p = sim?.simulations[j]
            simulationFormData.append(`type[${j}]`, p[0])
            simulationFormData.append('files', p[1])
          }

          await addSimulationImages(simulationFormData)


        }
      }

      setTrigger(makeRandomString(5))
      setIsSubmitting(false)
      handleReset()
      handleClose()
    } catch (err) {
      console.log(err)
      setIsSubmitting(false)
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
      // handleClose()
    }

  }


  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive
  } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xlsx', '.numbers', '.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    multiple: false,
    onDrop: (files) => {
      if (files?.length) {
        setFile(files[0])
        // readUploadFile(files)
      }
    }
  });

  // useEffect(()=> {
  //   readUploadFile(file)
  // }, [])

  const baseStyle = useMemo(() => {
    return {
      backgroundColor: 'transparent',
    }
  }, []);


  const focusedStyle = useMemo(() => {
    return {
      // borderColor: '#2196f3',
    }
  }, []);

  const acceptStyle = useMemo(() => {
    return {
      // borderColor: '#00e676',
      // backgroundColor: '#E9ECEF',
      // backgroundColor: 'green'
    }
  }, []);

  const rejectStyle = useMemo(() => {
    return {
      // borderColor: '#ff1744',
      backgroundColor: '#FFBBBB'
    }
  }, []);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragActive ? { backgroundColor: '#E9ECEF' } : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [baseStyle, isFocused, focusedStyle, isDragAccept, acceptStyle, isDragReject, rejectStyle, isDragActive]);


  return (
    <div className={`${Styles.container} ${mode === 'manual' ? Styles.auto : ''}`}>
      {!confirmationMode
        &&
        <ModalHeader
          title={'Add Stock'}
          iconName={'plus'}
          withIcon
          withCloseButton
          handleClose={handleClose}
          paddingX='2.4rem'
          paddingY='1.6rem'
          titleAdditionalComponent={<Switcher
            options={switcherOptions}
            value={mode}
            setValue={setMode}
          />}
          isSticky
        />
      }

      <div className={Styles.content}>
        {mode === 'manual'
          ?
          (!confirmationMode

            ?
            <div className={Styles.formContainer}>
              <InputField
                title={'Product Name'}
                placeholder={'Product Name'}
                value={name}
                setValue={setName}
              />
              <InputField
                title={'Product Code'}
                placeholder={'Product Code'}
                value={code}
                setValue={setCode}
              />
              <InputField
                title={'Fabric Code'}
                placeholder={'Fabric Code'}
                value={fabricCode}
                setValue={setFabricCode}
              />
              <InputField
                title={'Type'}
                placeholder={'Type'}
                value={type}
                setValue={setType}
                isDropdown
                isSelect
                dropdownOptions={stockTypes?.filter(obj => obj?.title !== 'All')?.map(obj => obj?.title)}
              />
              <InputField
                type='number'
                isNumber
                title={'Quantity'}
                placeholder={'Quantity'}
                value={qty}
                setValue={setQty}
                withAdornment
                adornmentPosition={'end'}
                adornmentComponent={<AdornmentLabel>Pcs</AdornmentLabel>}
              />
              <TextAreaField
                title={'Description'}
                placeholder={'Description'}
                value={desc}
                setValue={setDesc}
              />
              <InputField
                title={'Price'}
                placeholder={'Price'}
                value={price}
                setValue={setPrice}
                isCurrency
              />
              <UploadField
                title={'Main Fabric Image'}
                cardTitle={'Main Image'}
                value={image}
                setValue={setImage}
                isFullWidth
              />
              {
                !type?.toLowerCase()?.includes('ready')
                &&
                Object?.entries(simulations)?.map((s, sI) => (
                  <div key={sI} className={Styles.simField}>
                    <div className={Styles.simTitle}>
                      <Avatar
                        withIcon
                        iconName={s[0]}
                        size='20px'
                        borderRadius='4px'
                        iconSize='16px'
                      />
                      <span>{capitalizeWords(s[0])}</span>
                    </div>
                    <div className={Styles.simBoxes}>
                      <SampleCard
                        name={'Front'}
                        isOverview
                        size='100%'
                        src={simulations?.[s[0]]?.front}
                        setSrc={(newSrc) => setSimulations(prev => {
                          return {
                            ...prev,
                            [s[0]]: {
                              ...prev?.[s[0]],
                              front: newSrc
                            }
                          }
                        })}
                      />
                      <SampleCard
                        name={'Back'}
                        isOverview
                        size='100%'
                        src={simulations?.[s[0]]?.back}
                        setSrc={(newSrc) => setSimulations(prev => {
                          return {
                            ...prev,
                            [s[0]]: {
                              ...prev?.[s[0]],
                              back: newSrc
                            }
                          }
                        })}
                      />
                      <SampleCard
                        name={'Side'}
                        isOverview
                        size='100%'
                        src={simulations?.[s[0]]?.side}
                        setSrc={(newSrc) => setSimulations(prev => {
                          return {
                            ...prev,
                            [s[0]]: {
                              ...prev?.[s[0]],
                              side: newSrc
                            }
                          }
                        })}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
            : <div className={Styles.confirmationContainer}>
              <span>Confirmation</span>
              <span>{found ? 'Other product with the same product code has beed added before. Do you want to add the quantity instead?':'Are you sure stock data filled in previously has been correct?'}</span>
            </div>
          )

          :

          <div {...getRootProps({ style })} className={Styles.dndContainer}>
            <img src={!isDragReject ? Images.CLOUD : Images.CIRCLE_X_RED} alt='' />
            {isDragAccept && <span>Release files to upload</span>}
            {isDragReject &&
              <div className={Styles.onReject}>
                <span>File did not match</span>
                <span>Uploaded file must be in .xls format</span>
              </div>
            }
            <input {...getInputProps()} ref={inputRef} type='file' hidden />
            {!isDragActive
              &&
              <div className={Styles.onInactive}>
                <Button
                  variant={'tertiary'}
                  buttonText='Upload File'
                  customClassName={Styles.uploadButton}
                  onClick={() => inputRef?.current?.click()}
                  disableActive
                />
                <span>Or drag file here</span>
                <a
                  href={template}
                  download="Template"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    variant='secondary'
                    buttonText='Download Template'
                    customClassName={Styles.downloadTemplatesButton}
                    withIcon
                    iconName={'document-arrow-down'}
                    iconPlacement='start'
                    size='sm'
                    disableActive
                  />
                </a>

              </div>
            }


          </div>
        }
      </div>
      {
        mode === 'manual'

        &&
        <div className={Styles.actionsWrapper}>
          <Button
            type='button'
            variant='secondary'
            buttonText={!confirmationMode ? 'Clear Form' : 'No, back to form'}
            size='lg'
            onClick={!confirmationMode ? handleReset : () => {
              setConfirmationMode(false)
              setFound(false)
            }}
          />
          <Button
            type='button'
            variant='primary'
            buttonText={!confirmationMode ? 'Submit' : (isSubmitting ? 'Submitting stock...' : (isConfirming ? 'Confirming...' : (found ? 'Yes, add quantity' : 'Yes, submit stock')))}
            size='lg'
            disabled={!confirmationMode ? (disableSubmit ? true : false) : (isSubmitting ? true : false)}
            onClick={!confirmationMode ? handleConfirm : handleSubmit}
          />
        </div>
      }
      {error
        &&
        <ModalWrapper
          show={error}
          setShow={() => {
            setConfirmationMode(false)
            setError(null)
          }
          }
        >
          <WideErrorModal
            code={error?.code || 500}
            error={error?.message || 'Something went wrong!'}
            withButton
            onClose={() => {
              setError(null)
              setConfirmationMode(false)
            }
            }
          />
        </ModalWrapper>

      }
    </div>
  )
}