import Avatar from '@Atom/Avatar'
import Icon from '@Atom/Icon'
import ModalWrapper from '@Atom/ModalWrapper'
import { fileBaseUrl } from '@Config/api'
import PicturesPreviewModal from '@Molecule/_modals/PicturesPreviewModal'
import { useState } from 'react'
import Styles from './style.module.scss'

export default function SizeCard({
  sizeName,
  sizeID,
  measurements,
  customBorderColor = '#E9ECEF',
  photos = [],
  isDefault,
  isV2 = false,
  isV3 = false
}) {
  const [expanded, setExpanded] = useState(false)
  const [activeIndex, setActiveIndex] = useState('')

  return (
    <div className={`${Styles.container} ${isV2 ? Styles.v2 : ''} ${isV3 ? Styles.v3 : ''}`} style={{ borderColor: customBorderColor }}>
      <button
        onClick={() => setExpanded(!expanded)}
      >
        <span>{isDefault ? 'Default' : `Size Name: ${sizeName}`}</span>
        <span>{isDefault ? '' : `#${sizeID}`}</span>
        {isV2 && <span>{expanded ? 'Hide details' : 'Show details'}</span>}
        <Icon
          icon={expanded ? 'chevron-up' : 'chevron-down'}
          size={20}
        />
      </button>
      {
        expanded
        &&
        <div className={Styles.wrapper}>
          {measurements?.length
            ?
            <div className={Styles.measurements}>
              {measurements?.map((m, mIdx) => (
                <div key={mIdx} className={Styles.measurement}>
                  <span>{m?.title || m?.name}</span>
                  <span>{m?.value || '0'} {m?.denom || 'cm'}</span>
                </div>
              ))}
            </div>
            :

            <div className={Styles.noMeasurement}>no measurement</div>
          }

          <div className={Styles.photos}>
            {photos?.map((p, pIdx) => (
              <button
                key={pIdx}
                onClick={() => setActiveIndex(`${pIdx}`)}
              >
                <Avatar
                  size='100%'
                  src={p?.url || p?.uri ? fileBaseUrl + (p?.url?.replaceAll(fileBaseUrl, '') || p?.uri?.replaceAll(fileBaseUrl, '')) : ''}
                  borderRadius='6px'
                  withIcon
                  iconName={'photo'}
                  aspectRatio={1}
                  // icon
                />
              </button>
            ))}
          </div>

        </div>

      }
      {
        activeIndex &&
        <ModalWrapper
          show={activeIndex}
          setShow={() => setActiveIndex('')}
          desktopPositioning='center'
          mobilePositioning='center'
          noPadding
        >
          <PicturesPreviewModal
            srcArr={photos?.map(obj => fileBaseUrl + (obj?.uri?.replaceAll(fileBaseUrl, '') || obj?.url?.replaceAll(fileBaseUrl, '')))}
            slidesToShow={3}
            hideArrows
            hideSubArrows
            onClose={() => setActiveIndex('')}
          />
        </ModalWrapper>
      }
    </div>
  )
}