import { priceFormat } from '@Helpers/priceFormat'
import Styles from './style.module.scss'

export default function PriceDetailsCard({
  items,
  subTotal,
}) {

  return (
    <div className={Styles.priceDetails}>
      <h4>Price Details</h4>
      {!items?.length
        ?
        <span>No items selected</span>
        :
        <div className={Styles.prices}>
          <div className={Styles.priceList}>
            {
              items?.map((item, i) => (
                <div
                  key={i}
                  className={Styles.itemPrice}
                >
                  <span>{item?.name}</span>
                  <span>Rp {priceFormat(item?.price)}</span>
                </div>
              ))
            }
          </div>
          <div className={Styles.subTotal}>
            <div>
              <span>Sub Total</span>
              <span>Rp {priceFormat(subTotal)}</span>
            </div>
            <div>
              <span>Tax (10%)</span>
              <span>Rp {priceFormat(10 / 100 * subTotal)}</span>
            </div>
          </div>
          <div className={Styles.total}>
            <span>Total</span>
            <span>Rp {priceFormat(subTotal + (10 / 100 * subTotal))}</span>
          </div>

        </div>
      }
    </div>
  )
}