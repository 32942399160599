import ModalWrapper from '@Atom/ModalWrapper'
import FeeDetailsModal from '@Molecule/_modals/DetailsModals/Fee'
import FeeListLayout from '@Organism/Fee/FeeList'
import { Suspense, useState } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Styles from './style.module.scss'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'

export default function FeeListPage() {
  const { packageFees } = useLoaderData()
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const defaultFees = sessionStorage?.getItem('defaultFees') ? JSON?.parse(sessionStorage?.getItem('defaultFees')) : []
  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <FeeListLayout
            setSelectedEmployee={setSelectedEmployee}
            defaultData={{
              templateFee: [
                {
                  "name": "TROUSER",
                  fee: defaultFees?.length ? defaultFees?.find(obj => obj?.name?.toLowerCase() === 'trouser')?.fee : ''
                },
                {
                  "name": "SUIT",
                  fee: defaultFees?.length ? defaultFees?.find(obj => obj?.name?.toLowerCase() === 'suit')?.fee : ''
                },
                {
                  "name": "SHIRT",
                  fee: defaultFees?.length ? defaultFees?.find(obj => obj?.name?.toLowerCase() === 'shirt')?.fee : ''
                }
              ],
              fees: [],
              totalPage: 0,
              total: 0
            }}
            isLoading
          />
        }
      >
        <Await
          resolve={packageFees}
          errorElement={<AsyncErrorBoundary />}
        >
          {(packageFees) => (
            <FeeListLayout
              setSelectedEmployee={setSelectedEmployee}
              defaultData={packageFees?.data}
            />

          )}

        </Await>

      </Suspense>
      <div>
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={selectedEmployee}
          setShow={() => setSelectedEmployee(null)}
        >
          <FeeDetailsModal
            handleClose={() => setSelectedEmployee(null)}
            data={selectedEmployee}
          />
        </ModalWrapper>


      </div>
    </div>
  )
}