import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '24px',
    gap: '24px'
  },
  headerWrapper: {
    gap: '12px',
  },
  logo: {
    height: '24px',
    objectFit: 'contain',
    alignSelf: 'flex-start'
  },
  header: {
    display: 'flex',
    gap: '12px'
    // flexDirection: 'row',
    // alignItems: 'center',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

  },
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '24px',
    // fontStyle: 'normal',
    fontWeight: 700,
  },
  subtitle: {
    fontSize: '12px',
    fontStyle: 'normal',
    // fontFamily: 'Helvetica-Bold',
    fontWeight: 500,
    paddingBottom: '12px'
    // lineHeight: '16px', 
  },
  deliverTo: {
    backgroundColor: '#f8f9fa',
    padding: '12px',
    gap: '4px',
    borderRadius: '8px',
    border: '1px solid #dee2e6'
  },
  dtTitle: {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Helvetica-Bold',
  },
  dtSubTitle: {
    fontSize: '14px'
  },
  content: {
    gap: '24px'
  },
  section: {
    gap: '16px',
    borderBottom: '1px solid #dee2e6',
    paddingBottom: '24px'
  },
  sTitle: {
    fontSize: '16px'
  },
  schedule: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    padding: '8px',
    borderRadius: '8px',
    border: '1px solid #dee2e6'
  },
  scLogo: {
    width: '20px',
    objectFit: 'contain',
    gap: '4px',
  },
  scText: {
    fontSize: '16px'
  },
  items: {
    padding: '12px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    border: '1px solid #dee2e6'
  },
  item: {
    flexDirection: 'row',
    gap: '8px'
  },
  iNWrapper: {

  },
  iNumber: {
    height: '20px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    border: '1px solid #dee2e6',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '20px',
    paddingHorizontal: '2px'
  },
  iNText: {
    fontSize: '12px',
  },
  iData: {
    gap: '8px',
    flexGrow: '1'
  },
  iHeader: {
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  iLogo: {
    borderRadius: '4px',
    border: '1px solid #dee2e6',
    backgroundColor: '#e9ecef',
    padding: '2px'
  },
  iLImage: {
    width: '40px',
    objectFit: 'contain'
  },
  iLabel: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '6px',
    fontSize: '14px',
    border: '1px solid #dee2e6'
  },
  iLText: {

  },
  text: {
    fontSize: '14px'
  },
  iDetails: {
    gap: '12px',
  },
  textile: {
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
  },
  tImage: {
    width: '24px',
    aspectRatio: '1',
    borderRadius: '5px'
  },
  grow: {
    flexGrow: '1',
  },
  tName: {
    fontSize: '14px'
  },
  tQty: {
    fontSize: '12px'
  },
  productDetail: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  productName: {
    fontSize: '14px',
    fontFamily: 'Helvetica-Bold',
  },
  notes: {
    gap: '8px',
    marginTop: '20px'
  },
  nTitle: {
    fontSize: '12px',
    fontFamily: 'Helvetica-Bold',
  },
  nText: {
    fontSize: '12px'
  },
  itemSection: {
    marginTop: '50px',
  },
  table: {
    marginTop: '20px',
    display: 'table', 
    width: 'auto', 
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: 'auto', 
    flexDirection: 'row' 
  },
  tabelColNum: {
    width: '10%', 
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol: { 
    width: '45%', 
    borderStyle: 'solid', 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCell: { 
    margin: 'auto', 
    marginTop: 5, 
    fontSize: 10 
  }
})

export default styles