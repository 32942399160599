import { useEffect } from "react";
import Styles from "./style.module.scss";
import usePagination, { DOTS } from "@Hooks/usePagination";
import Icon from "@Atom/Icon";
import { makeRandomString } from "@Helpers/makeRandomString";

const Pagination = ({
  total,
  value,
  setValue,
  pages,
  setPages,
  selectedPage,
  setSelectedPage,
  pageList,
  setPageList,
  totalPage,
  siblingCount = 0,
  bgColor,
  itemListName = 'items',
  extraLabels = [],
  ...props
}) => {
  const paginationRange = usePagination({
    selectedPage,
    siblingCount,
    totalPage,
  });

  const onNext = () => {
    if (selectedPage < totalPage) {
      setSelectedPage(selectedPage + 1);
    }
  };

  const onPrevious = () => {
    if (selectedPage > 1) {
      setSelectedPage(selectedPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  const prevDisabled = selectedPage === 1;
  const nextDisabled = selectedPage === lastPage || totalPage === 0;

  useEffect(() => {
    // setPages(`1-${value}`);
    // setSelectedPage(1);
  }, [value, setPages, setSelectedPage]);

  useEffect(() => {
    const list = [];

    if (total / value >= 1) {
      for (let i = 1; i <= total / value; i++) {
        list.push({ number: i });
      }
    }
    if (total / value < 1) {
      list.push({ number: 1 });
    }
    if (total / value >= 1 && total % value > 0) {
      list.push({ number: Math.floor(total / value + 1) });
    }

    setPageList(list);
  }, [value, total, setPageList]);

  useEffect(() => {
    if (selectedPage > 1) {
      setPages(
        `${+(selectedPage - 1) * +value + 1}-${+(selectedPage - 1) * +value + +value <= total
          ? +(selectedPage - 1) * +value + +value
          : total
        }`
      );
    }
    if (selectedPage <= 1) {
      if (total >= value) {
        setPages(`1-${value}`);
      } else if (total !== 0) {
        setPages(`1-${total}`);
      } else {
        setPages('0-0')
      }
    }
  }, [selectedPage, value, total, setPages]);

  return (
    <div style={{ backgroundColor: bgColor || 'auto' }} className={`${Styles.container}`}>
      <div className={Styles.leftSide}>
        <div className={Styles.content}>
          Showing {pages} of {total} <span>{itemListName}</span>
        </div>
        {extraLabels?.length
          ?
          <div className={Styles.labels}>
            {
              extraLabels?.map((each, i) => (
                <div key={i} className={Styles.label}>
                  <span>{each?.label}</span>
                  <span style={{ color: each?.valueColor }}>{each?.value}</span>
                </div>
              ))

            }
          </div>
          :
          <></>
        }
      </div>
      <div className={Styles.rightSide}>
        <div
          className={`${Styles.arrow} ${prevDisabled ? Styles.disabled : ""
            }`}
          onClick={onPrevious}
        >
          <Icon icon="chevron-left" size={18} />
        </div>
        {paginationRange.map((each) => {
          if (each === DOTS) {
            return (
              <div
                key={each + makeRandomString(5)}
                className={`${Styles.page} ${Styles.disabled}`}
              >
                &#8230;
              </div>
            );
          }
          return (
            <li
              key={each + makeRandomString(5)}
              className={`${selectedPage === each ? Styles.selectedPage : ""
                } ${Styles.page}`}
              onClick={() => setSelectedPage(each)}
            >
              {each}
            </li>
          );
        })}
        <div
          className={`${Styles.arrow} ${nextDisabled ? Styles.disabled : ""
            }`}
          onClick={onNext}
        >
          <Icon icon="chevron-right" size={18} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
