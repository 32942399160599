/* eslint-disable eqeqeq */
import Styles from './style.module.scss'

export default function MeasurementField({
  title,
  value,
  setValue,
  placeholder,
  denom,
  extraTitleLabel,
  customClassName = '',
}) {

  // function onlyMeasurement(event) {
  //   var key = event.which || event.keyCode;
  //   if (key && (key <= 47 || key >= 58) && key != 8) {
  //     if (key != 9 && key != 190 && key != 191 && key != 37 && key != 39) {
  //       event.preventDefault();
  //     }
  //   }
  // }

  return (
    <div className={`${Styles.container} ${customClassName}`}>
      {title
        &&
        <div className={Styles.title}>
          <span>{title}</span>
          {extraTitleLabel}
        </div>
      }

      <div className={Styles.input}>
        <div className={Styles.denom}>{denom}</div>
        <input
          type='text'
          value={value && value != 0 ? value : '0'}
          placeholder={placeholder}
          // onChange={(e) => setValue(Number(e.target.value))}
          onChange={(e)=> {
            setValue(e?.target?.value?.replaceAll('cm', ''))
            if(e?.target?.value && e.target.value[0] === '0') {
              setValue(e.target.value.substring(1, e.target.value.length))
            }
          }}
          // onKeyDown={(e)=> onlyMeasurement(e)}
          // min={0}
        />
      </div>

    </div>
  )
}