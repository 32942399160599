import { useCapitalize } from '@Hooks/useCapitalize';
import useWindowSize from '@Hooks/useWindowSize';
import { useEffect, useMemo, useRef } from 'react';
import Styles from './style.module.scss';

export default function ModalWrapper({
  children,
  desktopPositioning = 'center',
  show,
  setShow = () => { },
  mobilePositioning = 'bottom',
  noPadding = false,
  isOverlayTransparent = false,
  ...props
}) {

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [show])

  const { width } = useWindowSize()

  const paddingInline = useMemo(() => {
    if(noPadding) {
      return '0px'
    }
    if (width > 1440) {
      return (width - 1440) / 2 + 32 + 'px'
    } else {
      return '32px'
    }
  }, [noPadding, width])

  const layoutRef = useRef()
  // const outsideBoxClick = useOutsideClick(layoutRef)


  // useEffect(()=> {
  //   if(show && !outsideBoxClick) {
  //     setShow(false)
  //   }
  // }, [outsideBoxClick, setShow, show])

  return (
    <div
      className={`${Styles.container} ${show ? Styles.onShow : Styles.onHide} ${Styles[`desktop${useCapitalize(desktopPositioning)}`]} ${Styles[`mobile${useCapitalize(mobilePositioning)}`]}`}
      style={{ padding: !noPadding ? '20px' : '', paddingInline: paddingInline }}
    >
      {children}
      <div ref={layoutRef}
        onClick={() => {
          if (show) {
            setShow(false)
          }
        }}
        className={`${Styles.overlay} ${isOverlayTransparent ? Styles.transparent : ''} ${show ? Styles.onShow : Styles.onHide} ${Styles[`desktop${useCapitalize(desktopPositioning)}`]} ${Styles[`mobile${useCapitalize(mobilePositioning)}`]}`} />
    </div>
  )
}