import React from 'react'
import { Link } from 'react-router-dom'

export default function ReactLink() {
  return (
    <Link
      className="App-link"
      to="https://reactjs.org"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn React
    </Link>
  )
}
