import Icon from '@Atom/Icon'
import { useRef, useState } from 'react'
import Styles from './style.module.scss'
import moment from 'moment'

export default function Button({
  type = "button",
  variant = "primary",
  size = "md",
  state = "default",
  iconPlacement = "start",
  withIcon=false,
  iconName='',
  buttonText = 'button',
  onClick,
  fixedPadding,
  disableActive = false,
  borderRadius = '8px',
  disabled,
  customClassName = '',
  customIconSize,
  disableHover = false,
  customColor,
  whiteSpace='nowrap'
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${customClassName} ${Styles[variant]} ${Styles[size]} ${Styles.button} ${!disableActive ? Styles.normal : ''} ${!disableHover ? Styles.withHover : ''}`}
      style={{ padding: fixedPadding || 'auto', borderRadius: borderRadius, color: customColor || '', whiteSpace }}
    >
      {(withIcon && iconPlacement === "start" && state !== 'text-only') && (
        <Icon icon={iconName} size={customIconSize ? customIconSize : (size === 'sm' ? 20 : 24)} />
      )}
      {
        state !== 'icon-only' && <span>{buttonText}</span>
      }
      {(withIcon && iconPlacement === "end" && state !== 'text-only') && (
        <Icon icon={iconName} size={customIconSize ? customIconSize : (size === 'sm' ? 20 : 24)} />
      )}

    </button>
  )
}

export const DatePickerButton = ({ value, onChange }) => {
  const dateRef = useRef()
  const [currentValue, setCurrentValue] = useState(value && value !== 'Invalid date' ? moment(new Date(value))?.format('YYYY-MM-DD') : new Date())

  return (
    <div className={Styles.container} onClick={(e) => {
      e.stopPropagation()
      dateRef.current.showPicker()
    }}
    >
      <input
        type="date"
        ref={dateRef}
        name="file"
        value={currentValue}
        onChange={(e) => {
          e.stopPropagation()
          setCurrentValue(new Date(e.target.value))
        }}
      // onChange={onChange}
      // hidden
      />
      <Button
        type='button'
        variant='secondary'
        state='icon-only'
        size='sm'
        fixedPadding={'0.4rem'}
        iconName={"calendar"}
        onClick={(e) => {
          e.stopPropagation()
          dateRef.current.showPicker()
        }}
        disableActive
        withIcon
      />
    </div>
  )
}