import Images from '@Theme/Images'
import Styles from './style.module.scss'
import Avatar from '@Atom/Avatar'
import { fileBaseUrl } from '@Config/api'
import { useNavigate } from 'react-router-dom'

export default function ProfilePopup() {
  const user = localStorage?.getItem('user') ? JSON.parse(localStorage?.getItem('user')) : null

  const navigate = useNavigate()

  return (
    <div className={Styles.container}>
      <div className={Styles.top}>
        <div className={Styles.bg} />
        <Avatar
          src={user?.imageUrl ? fileBaseUrl + user?.imageUrl : Images?.AVA_DEFAULT}
          size='75px'
          borderRadius='50%'
        />
        <h3>{user?.fullName}</h3>
        <span>{user?.role}</span>
      </div>
      <div className={Styles.bottom}>
        {user?.role !== 'DRIVER'
          &&
          <button onClick={() => navigate('/product/cart')}>Go to Cart</button>
        }
        <button onClick={() => {
          sessionStorage.clear()
          localStorage.clear()
          navigate('/')
        }}>Log Out</button>
      </div>
    </div>
  )
}