import ModalWrapper from '@Atom/ModalWrapper';
import StockFilterModal from '@Molecule/_modals/FilterModals/Stock';
import CheckpointListLayout from '@Organism/Checkpoint/CheckpointList';
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary';
import { Suspense, useState } from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import Styles from './style.module.scss';

export default function CheckpointListPage() {
  const { packageCheckpointList, packageWorkerList } = useLoaderData();
  const [showFilter, setShowFilter] = useState(false);
  const [trigger, setTrigger] = useState('');

  return (
    <div className={Styles.container}>
      <Suspense>
        <Await
          resolve={Promise.all([packageCheckpointList, packageWorkerList])}
          errorElement={<AsyncErrorBoundary />}
        >
          {([resolvedCheckpointList, resolvedWorkerList]) => (
            <CheckpointListLayout
              defaultData={{
                totalPage: resolvedCheckpointList?.data?.totalPage,
                total: resolvedCheckpointList?.data?.total,
                checkpoints: resolvedCheckpointList?.data?.checkpoints || [],
              }}
              workerList={resolvedWorkerList?.data || []}
              trigger={trigger}
              setTrigger={setTrigger}
              setShowFilter={setShowFilter}
              setShowAdd={() => {}}
            />
          )}
        </Await>
      </Suspense>
      <ModalWrapper
        desktopPositioning="right"
        mobilePositioning="bottom"
        show={showFilter}
        setShow={setShowFilter}
      >
        <StockFilterModal
          handleClose={() => {
            setShowFilter(false);
          }}
          trigger={trigger}
        />
      </ModalWrapper>
    </div>
  );
}
