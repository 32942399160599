import ModalHeader from "@Atom/ModalHeader";
import Styles from "./style.module.scss";
import { useEffect, useState } from "react";
import InputField from "@Molecule/InputField";
import UploadField from "@Molecule/UploadField";
import Button from "@Atom/Button";
import { updatePayment } from "@Services/invoice";

export default function PaidOffFormModal({
  onClickCancel,
  orderId,
  remainingValue,
  code,
  fetchData = async () => {}
}) {
  const [nominal, setNominal] = useState(remainingValue);

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);

  const [proof, setProof] = useState(null);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      const res = await updatePayment(orderId, { amount: nominal, image: proof });
      await fetchData()
      if (res.status) {
        onClickCancel()
      }
      setIsSubmitting(false)
    } catch (err) {
      setIsSubmitting(false);
      setError(err?.response?.data?.error);

    }
  
  };

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={"Add Payment"}
        withIcon
        iconName={"plus"}
        paddingY="16px"
        isSticky
      />
      <div className={Styles.content}>
        <InputField
          title={"Payment"}
          type="text"
          isCurrency
          value={nominal}
          setValue={setNominal}
        />
        <UploadField
          title={"Payment Proof"}
          cardTitle={""}
          value={proof}
          setValue={setProof}
          isFullWidth
        />
      </div>
      <div className={Styles.actions}>
        <Button
          variant="secondary"
          size="lg"
          buttonText="Cancel"
          onClick={onClickCancel}
        />
        <Button
          variant="primary"
          size="lg"
          buttonText={isSubmitting ? 'Submitting...' : "Submit"}
          disabled={!nominal || !proof || isSubmitting}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
