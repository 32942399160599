import { employeeTypes } from '@Assets/data/employeeTypes'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import ModalHeader from '@Atom/ModalHeader'
import { makeRandomString } from '@Helpers/makeRandomString'
import useQuery from '@Hooks/useQuery'
import InputField from '@Molecule/InputField'
import { editFee } from '@Services/product'
import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'

export default function FeeSetterModal({
  setShow,
  employeeType = 'tailor',
  defaultFees,
  setTrigger,
}) {
  const query = useQuery()
  const type = query?.get('t') || 'Tailor'
  const [isSubmitting, setIsSubmitting] = useState()

  const feeType = useMemo(() => {
    if (!query?.get('t')) {
      return 'tailorFee'
    } return `${query.get('t')}Fee`
  }, [query])



  const [form, setForm] = useState({
    suitFee: defaultFees[0]?.fee,
    shirtFee: defaultFees[1]?.fee,
    pantsFee: defaultFees[2]?.fee,
  })

  useEffect(() => {
    setForm({
      suitFee: defaultFees[0]?.fee,
      shirtFee: defaultFees[1]?.fee,
      pantsFee: defaultFees[2]?.fee,
    })
  }, [defaultFees, employeeType, feeType])

  const formattedForm = useMemo(() => {
    return {
      type: type?.toUpperCase(),
      data: [
        {
          code: defaultFees[0]?.code,
          fee: form?.suitFee
        },
        {
          code: defaultFees[1]?.code,
          fee: form?.shirtFee
        },
        {
          code: defaultFees[2]?.code,
          fee: form?.pantsFee
        },
      ]
    }
  }, [defaultFees, form?.pantsFee, form?.shirtFee, form?.suitFee, type])

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true)
      await editFee(formattedForm)
      setTrigger(makeRandomString(5))
      setIsSubmitting(false)
      setShow(false)
    } catch (err) {
      setIsSubmitting(false)
      console.log(err)
    }
  }

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'Fee Setup'}
        paddingX='24px'
        paddingY='16px'
        withIcon
        iconName={'cog-6-tooth'}
        isSticky
        extraComponent={
          <div className={Styles.type}>
            <div><Icon icon={employeeType || 'tailor'} size={20} /></div>
            <span>{employeeTypes?.find(obj => obj?.query === employeeType)?.title} Fee</span>
          </div>
        }
      />
      <div className={Styles.content}>
        <InputField
          title={'Suit Fee'}
          value={form?.suitFee}
          setValue={(newVal) => setForm(f => {
            return ({
              ...f,
              suitFee: newVal
            })
          })}
          isCurrency
        />
        <InputField
          title={'Shirt Fee'}
          value={form?.shirtFee}
          setValue={(newVal) => setForm(f => {
            return ({
              ...f,
              shirtFee: newVal
            })
          })}
          isCurrency
        />
        <InputField
          title={'Pants Fee'}
          value={form?.pantsFee}
          setValue={(newVal) => setForm(f => {
            return ({
              ...f,
              pantsFee: newVal
            })
          })}
          isCurrency
        />
      </div>
      <div className={Styles.actions}>
        <Button
          variant='secondary'
          buttonText='Cancel'
          onClick={() => setShow(false)}
          size='lg'
        />
        <Button
          disabled={isSubmitting}
          buttonText={isSubmitting ? 'Confirming...' : 'Confirm'}
          onClick={handleConfirm}
          size='lg'
        />
      </div>
    </div>
  )
}