import ModalWrapper from '@Atom/ModalWrapper'
import useQuery from '@Hooks/useQuery'
import BuyerDetailsModal from '@Molecule/_modals/DetailsModals/Buyer'
import BuyerFilterModal from '@Molecule/_modals/FilterModals/Buyer'
import BuyerListLayout from '@Organism/Buyers/BuyerList'
import AsyncErrorBoundary from '@Organism/_utils/AsyncErrorBoundary'
import { Suspense, useState } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Styles from './style.module.scss'

export default function BuyerListPage() {
  const { packageCustomers } = useLoaderData()
  const [showFilter, setShowFilter] = useState(false)
  const [trigger, setTrigger] = useState('')
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(null)

  const query = useQuery()

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <BuyerListLayout
            defaultData={{
              "customers": [],
              "page": 0,
              "totalPage": 0,
              'total': 0,
              "customerTotal": null
            }}
            trigger={trigger}
            setTrigger={setTrigger}
            setShowFilter={setShowFilter}
            setSelectedCustomerDetails={setSelectedCustomerDetails}
            isLoading={true}
          />
        }
      >
        <Await
          resolve={packageCustomers}
          errorElement={
            <AsyncErrorBoundary />
          }
        >
          {(packageCustomers) => (
            <BuyerListLayout
              defaultData={{
                "customers": packageCustomers?.data?.customerData,
                "page": query.get('p'),
                "totalPage": packageCustomers?.data?.totalPage,
                'total': packageCustomers?.data?.total,
                "customerTotal": packageCustomers?.data?.customerTotal
              }}
              trigger={trigger}
              setTrigger={setTrigger}
              isLoading={false}
              setShowFilter={setShowFilter}
              setSelectedCustomerDetails={setSelectedCustomerDetails}
            />
          )}

        </Await>

      </Suspense>

      <div>
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={selectedCustomerDetails}
          setShow={() => setSelectedCustomerDetails(null)}
        >
          <BuyerDetailsModal
            buyer={selectedCustomerDetails}
            handleClose={() => setSelectedCustomerDetails(null)}
          />
        </ModalWrapper>
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={showFilter}
          setShow={setShowFilter}
        >
          <BuyerFilterModal
            handleClose={() => setShowFilter(false)}
            trigger={trigger}
          />
        </ModalWrapper>

      </div>
    </div>
  )
}