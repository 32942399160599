import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import Button from '@Atom/Button'
import { useMemo, useState } from 'react'
import ThumbnailCard from '@Molecule/ThumbnailCard'
import ModalWrapper from '@Atom/ModalWrapper'
import AddCategoryModal from '@Molecule/_modals/AddModals/Setup'
import DeleteCategoryModal from '@Molecule/_modals/DeleteModals/Category'

export default function SetupLayout() {
  const [addMode, setAddMode] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)

  const categories = useMemo(() => {
    return [
      {
        id: '1',
        title: 'Suit',
        thumbnail: 'https://www.wallpaperflare.com/static/457/356/907/anonymous-monochrome-suits-tie-wallpaper.jpg'
      },
      {
        id: '2',
        title: 'Shirts',
        thumbnail: null
      },
      {
        id: '3',
        title: 'Pants',
        thumbnail: null
      },
    ]
  }, [])
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Icon
          icon={'cog-6-tooth'}
          size={40}
        />
        <h3>Setup</h3>
      </div>

      <div className={Styles.content}>
        <div className={Styles.title}>
          <h4>Thumbnail</h4>
          <Button
            variant='secondary'
            disableActive
            size='md'
            buttonText='Add Other Category'
            withIcon
            iconName={'plus'}
            iconPlacement='start'
            borderRadius='6px'
            onClick={() => setAddMode(true)}
          />
        </div>
        <div className={Styles.categories}>
          {categories?.map((each, i) => (
            <ThumbnailCard
              key={i}
              id={each?.id}
              title={each?.title}
              thumbnail={each?.thumbnail}
              setIdToDelete={setIdToDelete}
            />
          ))}
        </div>
      </div>
      <ModalWrapper
        show={addMode}
      >
        <AddCategoryModal
          setShow={setAddMode}
        />
      </ModalWrapper>
      <ModalWrapper
        show={idToDelete}
      >
        <DeleteCategoryModal 
        setIdToDelete={setIdToDelete}
        />
      </ModalWrapper>
    </div>
  )
}