import Styles from './style.module.scss'

export default function CustomerName({
  name,
  phoneNumber
}) {

  return (
    <div className={Styles.container}>
      <span>{name}</span>
      <span>{phoneNumber}</span>
    </div>
  )
}