import Avatar from '@Atom/Avatar'
import Icon from '@Atom/Icon'
import { priceFormat } from '@Helpers/priceFormat'
import Skeleton from 'react-loading-skeleton'
import Styles from './style.module.scss'

export default function StackedItemInfo({
  picture,
  title = '',
  label = '',
  onClick,
  maxWidth = 'auto',
  imgRadius = '4px',
  withoutPicture,
  renderExtraBottomLabel,
  renderExtraTopLabel,
  titleWidth = '190px',
  titleFontSize = '1.4rem',
  isV2 = false,
  iconName = '',
  imgSize = '3.2rem',
  qty,
  withIcon = false,
  isSkeleton,
  noImageV2 = false,
  isDetailed = false,
  price = ''
}) {

  if (isSkeleton) {
    return (
      <div className={Styles.container} style={{ maxWidth: maxWidth }}>
        {!withoutPicture && !isV2 && <Avatar isSkeleton={isSkeleton} src={picture} size={imgSize} borderRadius={imgRadius} withIcon={withIcon} iconName={iconName} />}
        {isV2
          &&
          <Skeleton duration={2} className={Styles.iconSkeleton} />
        }
        <div className={Styles.label}>
          <Skeleton duration={2} className={Styles.topSkeleton} />
          <Skeleton duration={2} className={Styles.bottomSkeleton} />
        </div>
      </div>
    )
  }

  return (
    <div className={`${Styles.container} ${onClick ? Styles.clickAble : ''}`} style={{ maxWidth: maxWidth }}>
      {!withoutPicture && !isV2 && <Avatar src={picture} size={imgSize} borderRadius={imgRadius} />}
      {
        isV2 && !noImageV2
        &&
        <div className={Styles.icon}>
          <Icon icon={iconName} size={24} />
        </div>
      }
      <div className={`${Styles.label} ${onClick ? Styles.clickAble : ''}`}>
        <div className={Styles.top}>
          <span className={`${onClick ? Styles.clickAble : ''}`} onClick={onClick ? onClick : () => { }} style={{ width: titleWidth, fontSize: titleFontSize }}>{title}</span>
          {renderExtraTopLabel && renderExtraTopLabel()}
        </div>
        <div className={Styles.bottom}>
          <span className={`${onClick ? Styles.clickAble : ''}`} onClick={onClick ? onClick : () => { }} >{label}</span>
          {renderExtraBottomLabel && renderExtraBottomLabel()}
        </div>
      </div>
      {
        (qty
          ||
          (isDetailed && price))
        &&
        <div className={Styles.additional}>

          {qty && <span>{qty}</span>}
          {
            price && <span>Rp{priceFormat(price)}</span>
          }
        </div>
      }
    </div >
  )
}