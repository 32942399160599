/* eslint-disable no-unused-vars */
import Avatar from "@Atom/Avatar";
import Button from "@Atom/Button";
import BuyerNameWithTag from "@Atom/BuyerNameWithTag";
import Icon from "@Atom/Icon";
import ModalHeader from "@Atom/ModalHeader";
import Tag from "@Atom/Tag";
import { fileBaseUrl } from "@Config/api";
import { priceFormat } from "@Helpers/priceFormat";
import { splitCamelCase } from "@Helpers/splitCamelCase";
import useWindowSize from "@Hooks/useWindowSize";
import DateLabel from "@Molecule/DateLabel";
import ItemDetails from "@Molecule/ItemDetails";
import { getInvoice } from "@Services/invoice";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import ItemListCard from "@Molecule/ItemListCard";
import ModalWrapper from "@Atom/ModalWrapper";
import PaidOffFormModal from "@Molecule/_modals/PaidOffFormModal";
import OrderDetailsModal from "../Order";
import { capitalizeWords } from "@Helpers/capitalizeWords";
import InputField from "@Molecule/InputField";

const stringToDate = (str) => {
  const arr = str?.split("/");
  return [arr[1], arr[0], arr[2]]?.join("/");
};

export default function InvoiceDetailsModal({
  order,
  handleClose,
  onOpenPaidOffForm,
  setShow,
  trigger,
  setTrigger,
  workers = null
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [orderMode, setOrderMode] = useState(false)

  const [showPaidOffForm, setShowPaidOffForm] = useState(false)
  const [customerFileInput, setCustomerFileInput] = useState(false)
  const [customerFileForm, setCustomerFileForm] = useState({
    itinery: '',
    remark: ''
  })

  const [data, setData] = useState(order);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getInvoice(order?.code);
      const newData = response?.data;
      setInvoiceDetail(newData)
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.code]);

  useEffect(() => {
    if (order?.orderCode) {
      if (!order?.orderCode || order.orderCode === 'not specified') {
        setData(order);
        setInvoiceDetail(order)
      } else {
        fetchData();
      }
    } else {
        setData(order);
        setInvoiceDetail(order)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, order?.orderCode]);

  const setInvoiceDetail = useCallback(async (orderDetail) => {
    setData((d) => {
      return {
        ...d,
        ...orderDetail,
        id: orderDetail?._id,
        orderDate: orderDetail?.date
          ? moment(new Date(orderDetail?.date)).format("L")
          : "",
        dueDate: orderDetail?.dueDate
          ? moment(new Date(orderDetail?.dueDate)).format("L")
          : "",
        currentDueDate: orderDetail?.dueDate
          ? moment(new Date(orderDetail?.suggestedDueDate)).format("L")
          : "",
        customerName: orderDetail?.customer?.name,
        customerPhoneNumber: orderDetail?.customer?.phone,
        customerAddress: orderDetail?.customer?.address,
        total: 0,
        totalPaid: 0,
        summary: orderDetail?.order ? orderDetail?.order?.products.map((el) => ({
          ...el,
          price: el.textiles
            .map((el1) => el1.price)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            ),
        })) : orderDetail?.summaries,
        paymentPercentage: +orderDetail?.owed > 0 ? 50 : 100,
        paymentHistory: orderDetail?.payments?.map((p) => {
          return {
            id: p?._id,
            nominal: priceFormat(p?.amount),
            date: moment(new Date(p?.date))?.format("L"),
            proof: p?.imageUri ? fileBaseUrl + p?.imageUri?.replaceAll(fileBaseUrl, '') : null,
          };
        }),
        paymentMethod: orderDetail?.paymentMethod,
        itemStatus: orderDetail?.status?.replace(" ", ""),
        itemsArr: orderDetail?.summaries?.map((item) => {
          return {
            ...item,
            id: item?._id,
            category: item?.productType || "-",
            type: "-",
            length: "-",
            price: priceFormat(item?.price),
            img: item?.url,
            measurements: [],
            // measurements: Object.entries(item?.measurement)?.map(m => { return { name: m[0], value: m[1] + ' cm', title: splitCamelCase(m[0]) } }),
            cutter: {
              id: item?.cutterCode,
              name: item?.cutterName,
              img: item?.cutterImg || null,
            },
            tailor: {
              id: item?.tailorCode,
              name: item?.tailorName,
              img: item?.tailorImg || null,
            },
          };
        }),
      };
    });
  }, [])

  const tagTemplate = useMemo(() => {
    const status = data?.itemStatus;
    switch (status) {
      case "ONPROCESS":
        return {
          type: "yellow",
          title: "On Process",
        };
      case "DELIVERED":
        return {
          type: "green",
          title: "Delivered",
        };
      case "READY":
        return {
          type: "black",
          title: "Ready",
        };
      case "SCHEDULED":
        return {
          type: "blue",
          title: "Scheduled",
        };
      default:
        return {
          type: "yellow",
          title: "On Process",
        };
    }
  }, [data?.itemStatus]);

  function getSum(total, num) {
    return total + Math.round(num);
  }

  const totalPaid = useMemo(() => {
    if (data) {
      const nominals = data?.paymentHistory?.map((obj) => {
        return +obj?.nominal?.replaceAll(".", "");
      });

      return nominals?.reduce(getSum, 0);
    }
    return 0;
  }, [data]);

  const outstanding = useMemo(() => {
    return data?.order?.pricing ? +data?.order?.pricing - +totalPaid : 0;
  }, [data?.order?.pricing, totalPaid]);

  const dataMeasure = useMemo(() => {
    if (data?.order) {
      return data?.order?.products?.map((el) => ({
        id: el.pattern.code || "",
        name: el.pattern.name || "default",
        customerPhotos: el?.pattern?.photos?.map((el1, idx) => ({
          ...el1,
          url: fileBaseUrl + el1.uri?.replaceAll(fileBaseUrl, ''),
          id: idx,
        })),
        items: [
          {
            id: el._id,
            isReadyToWear: el.type === "READY-TO-WEAR",
            label: el.category,
            notes: el.notes,
            isAlter: el.textiles[0].type === "ALTER",
            fabrics: el?.textiles?.map((textiles) => ({
              id: textiles._id,
              type: "Primary",
              fabricType: el.type,
              fabricName: textiles.name,
              fabricCode: textiles.code,
              img: fileBaseUrl + textiles.imageUrl?.replaceAll(fileBaseUrl, ''),
            })),
          },
        ],
        measurements:
          el.pattern &&
          Object.entries(el?.pattern?.measurement)
            ?.map((m) => {
              // if (m[0] !== "photos")
              return {
                name: m[0],
                value: m[1],
                title: splitCamelCase(m[0]),
              };
            })
            .filter(
              (fill, idx) => fill.name !== "photos" && fill.name !== "code"
            ),
      }));
    }
  }, [data]);

  const contentTemplate = useMemo(() => {
    return [
      {
        title: "Address",
        component: (
          <div className={Styles.address}>{data?.customerAddress}</div>
        ),
      },
      {
        title: 'Notes',
        component: <div className={Styles.address}>{data?.notes || '-'}</div>
      },
      {
        title: "Order and Due Date",
        component: (
          <div className={Styles.dates}>
            <DateLabel
              intro="Order date"
              date={data?.order?.createdAt ? (data?.order?.createdAt) : (data?.createdAt)}
            />
            <DateLabel
              intro="Due date"
              date={data?.order?.dueDate ? (data?.order?.dueDate) : (data?.createdAt)}
              extraDate={
                data?.currentDueDate ? stringToDate(data?.currentDueDate) : ""
              }
            />
          </div>
        ),
      },
      {
        title: "Items",
        component: (
          <ItemListCard
            mappedData={[
              {
                id: 1,
                orderID: data?.order?.code ?? '-',
                dueDate: new Date(),
                sizeTypes: dataMeasure,
                customerPhotos: [
                  {
                    id: 1,
                    url: "https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg",
                  },
                  {
                    id: 2,
                    url: "https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg",
                  },
                  {
                    id: 3,
                    url: "https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg",
                  },
                  {
                    id: 4,
                    url: "https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg",
                  },
                  {
                    id: 5,
                    url: "https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg",
                  },
                  {
                    id: 6,
                    url: "https://img.freepik.com/free-photo/portrait-handsome-smiling-stylish-young-man-model-dressed-jeans-clothes-fashion-man_158538-5030.jpg",
                  },
                ],
              },
            ]}
          />
        ),
        // <div className={Styles.items}>
        //   {data?.itemsArr?.map((each, i) => (
        //     <div key={i}>
        //       <span>{i + 1}</span>
        //       <Avatar src={each?.img ? fileBaseUrl + each?.img : ''} size='32px' borderRadius='6px' />
        //       <ItemDetails
        //         item={each}
        //       />
        //     </div>
        //   ))}
        // </div>
      },
      {
        title: 'Invoice Date',
        component: (
          <div>
            <DateLabel
              intro=""
              date={data?.createdAt}
            />
          </div>
        )
      },
      {
        title: "Order Summary",
        titleComponent: (
          <div className={Styles.title}>
            <span>Order Summary</span>
            <Tag
              type={data?.status === 'PAID-OFF' ? "green" : "yellow"}
              title={data?.status && data?.status?.length > 0 ? data.status.split('-').map(el => `${el[0]}${el.substring(1, el.length).toLowerCase()}`).join(' ') : 'Waiting For Payment'}
            />
            <Tag type={"black"} title={data?.paymentMethod} noBullet />
          </div>
        ),
        component: (
          <div className={Styles.summaryWrapper}>
            <div className={Styles.itemsTableWrapper}>
              <table className={Styles.itemsTable}>
                <colgroup>
                    <col style={{ width: "30%" }}/>
                    <col style={{ width: "40%" }}/>
                    <col style={{ width: "30%" }}/>
                </colgroup>
                <thead>
                  <tr>
                    <td>Items</td>
                    <td style={{ textAlign: "right"  }}>Qty</td>
                    <td>Price</td>
                  </tr>
                </thead>
                <tbody>
                  {data?.summary?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <div>{item?.name}</div>
                      </td>
                      <td style={{ textAlign: "right"  }}>{item?.qty || 1}</td>
                      <td>Rp {(item?.price * (item.qty || 1)).toLocaleString("id") }</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={Styles.tableFooter}>
                <span>{`Total (${data?.summary?.length} Fabric)`}</span>
                <span>
                  Rp{" "}
                  {data?.summary
                    ?.map((el) => el.price * (el.qty || 1))
                    .reduce(
                      (accumulator, currentValue) => accumulator + currentValue,
                      0
                    )
                    .toLocaleString("id")}
                </span>
              </div>
            </div>

            <Button
              variant="secondary"
              buttonText="Go to Order Details"
              withIcon
              iconName={"arrow-long-right"}
              iconPlacement="end"
              onClick={() => setOrderMode(true)}
            />
          </div>
        ),
      },
      {
        title: 'Payment Details',
        component: (
          <div className={Styles.paymentDetails}>
            <p>
              <span>Type</span>
              <span>{capitalizeWords(data?.paymentType?.replaceAll('-', ' '))}</span>
            </p>
            <p>
              <span>Method</span>
              <span>{capitalizeWords(data?.paymentMethod?.replaceAll('-', ' '))}</span>
            </p>
            <p>
              <span>Status</span>
              <span>{capitalizeWords(data?.status?.replaceAll('-', ' '))}</span>
            </p>
          </div>
        )
      },
      {
        title: "Payment History",
        titleComponent: (
          <div className={`${Styles.title} ${Styles.payment}`}>
            <span>Payment History</span>
            <Button
              size="sm"
              buttonText="Add Payment"
              withIcon
              iconName={"plus"}
              customIconSize={16}
              onClick={() => setShowPaidOffForm(true)}
            />
          </div>
        ),
        component: (
          <div className={Styles.paymentHistoryWrapper}>
            <table className={Styles.itemsTable}>
              <thead>
                <tr>
                  <td style={{ width: '50px' }}>No</td>
                  <td style={{ width: '50px' }}>Img</td>
                  <td>Date</td>
                  <td>Payment</td>
                </tr>
              </thead>
              <tbody>
                {data?.payments?.map((each, i) => (
                  <tr key={i}>
                    <td> {i + 1}</td>
                    <td>
                      <Avatar
                        src={each?.imageUri ? fileBaseUrl + each?.imageUri?.replaceAll(fileBaseUrl, '') : ''}
                        size="24px"
                        borderRadius="4px"
                      />
                    </td>
                    <td>
                      <div>{each?.date ? moment(new Date(each?.date))?.format('DD/MM/yyyy') : ''}</div>
                    </td>
                    <td>Rp {priceFormat(each?.amount || 0)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={Styles.tableFooter}>
              <div>
                <span>Total Paid</span>
                <span>Rp {priceFormat(totalPaid)}</span>
              </div>
              <div>
                <span>Outstanding</span>
                <span>Rp{priceFormat(outstanding)}</span>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Invoice Files",
        component: (
          <div className={Styles.invoiceFilesWrapper}>
            {/* {data?.files?.map((file, i) => (
              <div
                key={i}
                className={Styles.file}
                onClick={() =>
                  window.open(`/invoice/${file?.id}`,'_blank', "rel=noopener noreferrer")
                }
              >
                <Icon icon={"document"} size={32} />
                <div>
                  <span>{`Invoice ${i + 1}`}</span>
                  <span>50kb</span>
                </div>
              </div>
            ))} */}

            {/* {!data?.files?.length && ( */}
            <Button
              onClick={() =>
                window.open(
                  `/invoice-view/${data?.code}`,
                  "_blank",
                  "rel=noopener noreferrer"
                )
              }
              buttonText="Generate Invoice"
              size="lg"
            />
            {/* )} */}
          </div>
        ),
      },
      {
        title: "Customer Files",
        component: (
          <div className={Styles.invoiceFilesWrapper}>
            <Button
              onClick={() =>
                setCustomerFileInput(true)
              }
              buttonText="Generate Customer File"
              size="lg"
            />
          </div>
        ),
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customerAddress, data?.notes, data?.order?.createdAt, data?.order?.dueDate, data?.order?.code, data?.currentDueDate, data?.status, data?.paymentMethod, data?.summary, data?.paymentType, data?.payments, data?.code, dataMeasure, totalPaid, outstanding]);

  const { width } = useWindowSize();

  if (orderMode) {
    return (
      <OrderDetailsModal
        order={order}
        type={order?.orderCode?.split('-')[0] === 'ORDER'
          ?
          'm1'
          :
          (
            order?.orderCode?.split('-')[0] === 'CUSTOMER'
              ?
              'm2'
              :
              'm3'
          )
        }
        workers={workers}
        setTrigger={setTrigger}
        trigger={trigger}
        handleClose={() => {
          // setSelectedOrderDetails(null)
          // setShowDetails(false)
          setOrderMode(false)
        }}
        setShow={setOrderMode}
        onOpenSetSchedule={() => {
          // setShowScheduleSetter(true)
          // setShowDetails(false)
        }}
        onOpenHold={() => {
          // setConfirmationType('hold')
          // setShowActionConfirmation(true)
          // setShowDetails(false)
        }}
        onOpenCancel={() => {
          // setConfirmationType('cancel')
          // setShowActionConfirmation(true)
          // setShowDetails(false)
        }}
        isFromInvoice
      />
    )
  }

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={"Invoice Details"}
        iconName={"list-bullet"}
        withIcon
        withCloseButton
        isSticky
        extraComponent={
          <BuyerNameWithTag
            intro={`Customer`}
            name={`Name: ${data?.customerName}`}
            tagType={tagTemplate?.type}
            tagTitle={tagTemplate?.title}
            noTag
            withPhoneNumber
            phoneNumber={`Phone: ${data?.customerPhoneNumber}`}
            withEmail
            email={`Email: ${data?.customer?.email ?? ''}`}
          />
        }
        handleClose={handleClose}
        paddingX={width <= 768 ? "16px" : "24px"}
        paddingY={width <= 768 ? "16px" : "24px"}
      />
      <div className={Styles.content}>
        {contentTemplate?.filter(ctn => {
            if (!data?.orderCode || data?.orderCode === "not specified") return ctn.title !== "Items" && ctn.title !== "Order and Due Date";
            return true;
          })?.map((content, i) => (
          <div key={i}>
            {content?.titleComponent || <span>{content?.title}</span>}
            {content?.component}
          </div>
        ))}
      </div>
      {/* <div className={Styles.actions}>
        <Button
          type='button'
          variant='secondary'
          // buttonText={!confirmationMode ? 'Clear Form' : 'No, back to form'}
          buttonText='Hold Order'
          size='lg'
          customColor={'#E79800'}
          onClick={onOpenHold}
        // onClick={!confirmationMode ? handleReset : () => setConfirmationMode(false)}
        />
        <Button
          type='button'
          variant='secondary'
          // buttonText={!confirmationMode ? 'Submit' : 'Yes, submit stock'}
          buttonText='Cancel Order'
          size='lg'
          customColor={'#DD0B0B'}
          onClick={onOpenCancel}
        // onClick={!confirmationMode ? () => setConfirmationMode(true) : handleSubmit}
        />
      </div> */}

      {showPaidOffForm
        &&
        <ModalWrapper
          desktopPositioning='center'
          mobilePositioning='bottom'
          show={showPaidOffForm}
          setShow={setShowPaidOffForm}
        >
          <PaidOffFormModal
            onClickCancel={() => {
              setShowPaidOffForm(false)
              // setShowDetails(true)
            }}
            orderId={data?.code}
            remainingValue={data?.paymentType === 'FULL-PAYMENT' ? outstanding || '0' : outstanding || '0'}
            fetchData={fetchData}
          />
        </ModalWrapper>
      }

      {customerFileInput && (
        <ModalWrapper
          desktopPositioning='right'
          mobilePositioning='bottom'
          show={customerFileInput}
          setShow={setCustomerFileInput}
        >
          <div className={Styles.customerFile}>
            <ModalHeader
              title={"Customer File"}
              iconName={"list-bullet"}
              withIcon
              withCloseButton
              isSticky
              handleClose={() => setCustomerFileInput(false)}
              paddingX={width <= 768 ? "16px" : "24px"}
              paddingY={width <= 768 ? "16px" : "24px"}
              extraComponent={
                <BuyerNameWithTag
                  intro={`Customer`}
                  name={`Name: ${data?.customerName}`}
                  tagType={tagTemplate?.type}
                  tagTitle={tagTemplate?.title}
                  noTag
                  withPhoneNumber
                  phoneNumber={`Phone: ${data?.customerPhoneNumber}`}
                  withEmail
                  email={`Email: ${data?.customer?.email ?? ''}`}
                />
              }
            />
             <div className={Styles.contentCustomerFile}>
              <InputField
                title='Itinery'
                value={customerFileForm.itinery}
                setValue={(val) => {
                  setCustomerFileForm((prevVal) => ({
                    ...prevVal,
                    itinery: val
                  }))
                }}
              />
              <InputField
                title='Remark'
                value={customerFileForm.remark}
                setValue={(val) => {
                  setCustomerFileForm((prevVal) => ({
                    ...prevVal,
                    remark: val
                  }))
                }}
              />
              <Button
                onClick={() =>
                  window.open(
                    `/customer-file/${data?.code}?itinery=${customerFileForm.itinery}&remark=${customerFileForm.remark}`,
                    "_blank",
                    "rel=noopener noreferrer"
                  )
                }
                disabled={!customerFileForm.itinery || !customerFileForm.remark}
                buttonText="Generate Customer File"
                size="lg"
              />
            </div>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
}
