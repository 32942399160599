import Button from '@Atom/Button'
import ModalHeader from '@Atom/ModalHeader'
import UploadField from '@Molecule/UploadField'
import { uploadDeliveryProof } from '@Services/delivery'
import { useEffect, useMemo, useState } from 'react'
import WideErrorModal from '../WideErrorModal'
import Styles from './style.module.scss'
import ModalWrapper from '@Atom/ModalWrapper'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function UploadDeliveryPhotoModal({
  onClose = () => { },
  fetchData = () => { },
  data,
  setTrigger = () => {}
}) {
  const [image, setImage] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setImage(null)
  }, [])

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      const formData = new FormData()
      // formData?.append('status', data?.status || '')
      formData?.append('status',  'DELIVERED')
      formData?.append('photos[0][name]', 'FOTO BUKTI')
      formData?.append('images', image)
      await uploadDeliveryProof(formData, data?.code)
      setTrigger(makeRandomString(5))
      fetchData()
      setIsSubmitting(false)
      onClose()
    } catch (err) {
      setIsSubmitting(false)
      console.log(err, 'error on submit')
      setError(err?.response?.data || { code: 500, message: 'Something went wrong' })
    }
  }

  console.log(error, 'err')

  const disableSubmit = useMemo(() => {
    return isSubmitting || !image
  }, [image, isSubmitting])

  return (
    <div className={Styles.container}>
      <ModalHeader
        title={'Upload Photo'}
        iconName={'plus'}
        withIcon
        withCloseButton
        handleClose={onClose}
        paddingX='2.4rem'
        paddingY='1.6rem'
        isSticky
      />
      <div className={Styles.content}>
        <UploadField
          title={'Delivery Photo'}
          cardTitle={'Photo'}
          value={image}
          setValue={setImage}
          isFullWidth
        />
      </div>
      <div className={Styles.actionsWrapper}>
        <Button
          type='button'
          variant='secondary'
          size='lg'
          buttonText='Cancel'
          onClick={onClose}
        />
        <Button
          type='button'
          variant='primary'
          size='lg'
          disabled={disableSubmit}
          buttonText='Submit'
          onClick={handleSubmit}
        />
      </div>
      {error
        &&
        <ModalWrapper
          show={error}
          setShow={() => {
            setError(null)
          }
          }
        >
          <WideErrorModal
            code={error?.code || 500}
            error={error?.message || 'Something went wrong!'}
            withButton
            onClose={() => {
              setError(null)
            }
            }
          />
        </ModalWrapper>

      }
    </div>
  )
}