import Styles from './style.module.scss'

export default function ItemTitle({
  name = '-',
  category = '',
  length = '',
  type = ''
}) {

  return (
    <div className={Styles.container}>
      <span>{name}</span>
      <span>{category && category + ' • '}{length && length + ' • '}{type}</span>
    </div>
  )
}