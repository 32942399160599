import { stockTypes } from '@Assets/data/stockTypes'
import Avatar from '@Atom/Avatar'
import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import { makeRandomString } from '@Helpers/makeRandomString'
import InputField from '@Molecule/InputField'
import MeasurementField from '@Molecule/MeasurementField'
import TextAreaField from '@Molecule/TextAreaField'
import { editStock } from '@Services/stock'
import moment from 'moment'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Styles from './style.module.scss'

export default function DetailsCard({
  title = 'title goes here',
  type = 'type goes here',
  picture = '',
  data = [],
  isLoading = false,
}) {

  const [editMode, setEditMode] = useState()
  const [isSubmtiting, setIsSubmitting] = useState(false)
  const { id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const convertDate = (str) => {
    const arr = str?.split('/')
    return [arr[2], arr[1], arr[0]]?.join('-')
  }

  const defaultForm = useMemo(() => {
    let obj = {}
    for (let i = 0; i < data.length; i++) {
      obj[data[i]?.name] =
        data[i]?.name?.toLowerCase()?.includes('price')
          ? data[i]?.value?.replaceAll('.', '')
          : (data[i]?.name?.toLowerCase()?.includes('dates')
            ? new Date(convertDate(data[i]?.value))
            : (data[i]?.name?.toLowerCase()?.includes('size')
              ? (data[i]?.value?.map(s => ({ ...s, value: s?.value?.split(' ')[0] })))
              : data[i]?.value
            ))
    }
    return obj
  }, [data])

  const [form, setForm] = useState(defaultForm)


  useEffect(() => {
    setForm(defaultForm)
  }, [editMode, defaultForm])

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(elementRef.current.offsetHeight);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const setValue = (value, name) => {
    setForm({
      ...form,
      [name]: value
    })
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      const formData = new FormData()
      formData?.append('name', form['Product Name'])
      formData?.append('price', form['Price'])
      formData?.append('quantity', form['Quantity'] || form['Qty'] || form['Length'])
      formData?.append('description', form['Description'])
      formData?.append('fabric', form['Fabric Code'])
      // formData?.append('productCode', form['Product Code'])
      formData?.append('type', stockTypes?.find(obj => obj?.title === form['Product Type'])?.query)
      if (type === 'ready-to-wear') {
        for (let i = 0; i < form?.Size?.length; i++) {
          const each = form?.Size[i]
          formData?.append(`size[${each?.name}]`, each?.value || '')
        }
      }
      await editStock(formData, id?.replaceAll('::', '%2F')?.replaceAll(' ', '%20'))
      setIsSubmitting(false)

      setEditMode(false)

      navigate(`${pathname}?t=${stockTypes?.find(obj => obj?.title === form['Product Type'])?.query?.toLowerCase()}&h=${makeRandomString(5)}`, { replace: true })

      // formData?.append('type', form['Product name'])
    } catch (err) {
      console.log(err)
      setIsSubmitting(false)

    }
  }


  return (
    <div className={Styles.container}>
      <div className={Styles.header} ref={elementRef}>
        <Icon icon={'queue-list'} size={32} />
        <h3>Details</h3>
        <button onClick={() => setEditMode(!editMode)}>
          <Icon icon={!editMode ? 'pencil' : 'x-mark'} size={24} />
        </button>

      </div>
      <div className={Styles.content}>
        <div className={Styles.contentHeaderWrapper} style={{ position: 'sticky', top: `${height}px` }}>
          <div className={Styles.contentHeader}>
            <Avatar
              src={picture}
              borderRadius='4px'
              size='60px'
            />
            <div>
              {!isLoading
                ?
                <span>{title}</span>
                :
                <Skeleton height={'24px'} width={'100%'} baseColor='#E9ECEF' />
              }
              <span>{stockTypes?.find(obj => obj?.query === type)?.title}</span>
            </div>
          </div>
        </div>

        {/* <div className={Styles.divider} /> */}
        {!editMode
          ?
          <div className={Styles.contentData}>
            {
              data?.map((each, i) => {
                const isDate = each?.value instanceof Date
                return (
                  <div key={i}>
                    <span>{each?.name}</span>
                    {!isLoading
                      ?
                      (each?.render
                        ? each?.render(each)
                        : <span>{isDate ? moment(new Date(each?.value))?.format('L') : each?.value} {each?.name?.includes('Length') && 'm'} {each?.name?.includes('Quantity') && ' pcs'}</span>
                      )
                      :
                      each?.skeleton || <Skeleton className={Styles.span} />
                    }
                  </div>
                )
              })
            }
          </div>
          :
          <div className={Styles.contentData}>
            {
              data?.map((each, i) => (
                <div key={i}>
                  {each?.name?.toLowerCase()?.includes('size')
                    ?
                    <div className={Styles.sizes}>
                      <span>Sizes</span>
                      <div className={Styles.field}>
                        {form?.Size?.map((fObj, iF) => (
                          <InputField
                            key={iF}
                            type={'measurement'}
                            noChevron
                            title={fObj?.title || fObj?.name}
                            withAdornment
                            adornmentComponent={<div className={Styles.denom}>cm</div>}
                            adornmentPosition={'end'}
                            value={fObj?.value || ''}
                            setValue={(newVal) => {
                              const newSizes = form[each?.name]?.map(obj => {
                                if (obj?.name === fObj?.name) {
                                  return {
                                    ...obj,
                                    value: newVal,
                                    // type: 'harian'
                                  }
                                }
                                return obj
                              })
                              setValue(newSizes, each?.name)
                            }}
                            isCurrency={fObj?.name?.toLowerCase() === 'price'}
                            isDropdown={fObj?.name?.toLowerCase()?.includes('type')}
                            isSelect={fObj?.name?.toLowerCase()?.includes('type')}
                            dropdownOptions={stockTypes?.map(obj => obj?.title)}
                          />))}
                      </div>

                    </div>
                    :
                    (
                      each?.name?.toLowerCase()?.includes('details') || each?.name?.toLowerCase()?.includes('description')
                        ?
                        <TextAreaField
                          type={each?.name?.toLowerCase()?.includes('date') ? 'date' : 'text'}
                          title={each?.name}
                          value={form[each?.name]}
                          setValue={(newVal) => setValue(newVal, each?.name)}
                          isCurrency={each?.name?.toLowerCase() === 'price'}
                          isDropdown={each?.name?.toLowerCase()?.includes('type')}
                          isSelect={each?.name?.toLowerCase()?.includes('type')}
                          dropdownOptions={stockTypes?.map(obj => obj?.title)}
                        />
                        :
                        (
                          each?.name?.toLowerCase()?.includes('length') || each?.name?.toLowerCase()?.includes('length')
                            ?
                            <MeasurementField
                              title={each?.name}
                              value={form[each?.name] || ''}
                              placeholder={each?.name}
                              denom={each?.name?.toLowerCase()?.includes('quantity') ? 'pcs' : 'm'}
                              setValue={(newVal) => setValue(newVal, each?.name)}
                            />
                            :
                            (
                              !each?.name?.toLowerCase()?.includes('date')
                              &&
                              < InputField
                                type={each?.name?.toLowerCase()?.includes('date') ? 'date' : (each?.name?.toLowerCase() === 'quantity' || each?.name?.toLowerCase() === 'length' ? 'number' : 'text')}
                                title={each?.name}
                                value={form[each?.name] || ''}
                                setValue={(newVal) => setValue(newVal, each?.name)}
                                isCurrency={each?.name?.toLowerCase() === 'price'}
                                isDropdown={each?.name?.toLowerCase()?.includes('type')}
                                isSelect={each?.name?.toLowerCase()?.includes('type')}
                                dropdownOptions={stockTypes?.filter(obj => obj?.title !== 'All')?.map(obj => obj?.title)}
                                disabled={each?.name === 'Product Code'}

                              />
                            )

                        )

                    )

                  }

                </div>
              ))
            }
            <div className={Styles.actionsWrapper}>
              <Button
                variant='secondary'
                buttonText='Clear Form'
                size='lg'
                fixedPadding={'1rem 1rem'}
                disableActive
              />
              <Button
                variant='primary'
                buttonText={isSubmtiting ? 'Saving Changes...' : 'Make Changes'}
                size='lg'
                fixedPadding={'1rem 1rem'}
                disableActive
                disabled={isSubmtiting}
                onClick={handleSubmit}
              />
            </div>
          </div>}
      </div>


    </div>
  )
}