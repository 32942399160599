import Icon from '@Atom/Icon'
import Tag from '@Atom/Tag'
import { dateLabelFormatter } from '@Helpers/dateLabelFormatter'
import useQuery from '@Hooks/useQuery'
import ActiveFiltersIndicator from '@Molecule/ActiveFiltersIndicator'
import DateLabel from '@Molecule/DateLabel'
import FilterModal from '@Molecule/_reusableLayout/FilterModal'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function LogFilterModal({
  handleClose,
  trigger
}) {

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()

  const defaultDateFrom = query.get('df')
  const defaultDateTo = query.get('dt')
  const defaultLogType = useMemo(() => { return query.get('lt')?.split('-') || [] }, [query])


  const [dateFrom, setDateFrom] = useState(defaultDateFrom || '')
  const [dateTo, setDateTo] = useState(defaultDateTo || '')
  const [logType, setLogType] = useState(defaultLogType)


  useEffect(() => {
    setDateFrom(defaultDateFrom)
    setDateTo(defaultDateTo)
    setLogType(defaultLogType)
  }, [defaultDateFrom, defaultDateTo, defaultLogType, trigger])

  const logTypeTemplate = useMemo(() => {
    return [

      {
        logType: 'STOCK',
        type: 'green',
        text: 'Stock',
        icon: 'rectangle-stack'
      },
      {
        logType: 'ORDER',
        type: 'blue',
        text: 'Order',
        icon: 'archive box'
      },

    ]
  }, [])

  const fixedQuery = useMemo(() => {
    const arr = []

    if (dateFrom) {
      arr.push(`df=${dateFrom}`)
    }
    if (dateTo) {
      arr.push(`dt=${dateTo}`)
    }
    if (logType) {
      arr.push(`lt=${logType?.join('-')}`)
    }

    if (arr?.length) {
      return '?' + arr?.join('&')
    } return ''
  }, [dateFrom, dateTo, logType])

  const filterTemplate = useMemo(() => {
    const arr = []
    if (dateFrom || dateTo) {
      arr.push({
        title: `Date Added: ${dateFrom ? dateFrom?.replaceAll('-', '/') : 'DD/MM/YYYY'} - ${dateTo ? dateTo?.replaceAll('-', '/') : 'DD/MM/YYYY'}`,
        handleRemove: () => {
          setDateFrom('')
          setDateTo('')
        }
      })
    }
    if (logType?.length) {
      for (let i = 0; i < logType?.length; i++) {
        const lType = logType[i];
        arr.push({
          title: `Type: ${logTypeTemplate?.find(obj => obj?.logType === lType)?.text}`,
          handleRemove: () => {
              setLogType(s => [...s?.filter(obj => obj !== lType)])
          }
        })
      }

    }

    return arr
  }, [dateFrom, dateTo, logType, logTypeTemplate])

  const contentTemplate = useMemo(() => {
    return [
      {
        title: 'Date',
        description: (
          <div className={Styles.dateRangeWrapper}>
            <DateLabel
              intro='From'
              date={dateLabelFormatter(dateFrom, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setDateFrom(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
            <DateLabel
              intro='To'
              date={dateLabelFormatter(dateTo, '/') || ''}
              changeAble
              // disableConvert
              onChange={(e) => setDateTo(moment(new Date(e?.target?.value))?.format('DD/MM/YYYY'))}
            />
          </div>
        )
      },
      {
        title: 'Type',
        description: (
          <div className={Styles.logType}>
            {logTypeTemplate?.map((status, i) => (
              <div key={i}>
                <button
                  // onClick={() => setLogType(status?.logtype)}
                  onClick={() => {
                    const found = logType?.find(obj => obj === status?.logType)
                    if (!found) {
                      setLogType(s => [...s, status?.logType])
                    } else {
                      setLogType(s => [...s?.filter(obj => obj !== status?.logType)])
                    }
                  }}
                >
                  <Tag
                    type={status?.type}
                    title={
                      <div className={Styles.tag}>
                        <span>{status?.text}</span>
                        {logType?.find(obj => obj === status?.logType) && (
                          <button
                            // onClick={() => setLogType('')}
                            onClick={() => {
                              const found = logType?.find(obj => obj === status?.logtype)
                              if (!found) {
                                setLogType(s => [...s, status?.logType])
                              } else {
                                setLogType(s => [...s?.filter(obj => obj !== status?.logtype)])
                              }
                            }}
                          >
                            <Icon icon="x-circle-solid" size={20} />
                          </button>
                        )}
                      </div>
                    }
                  />
                </button>


              </div>
            ))}


          </div>
        )
      },

    ]
  }, [dateFrom, dateTo, logType, logTypeTemplate])

  const handleReset = () => {
    // setTypes('')
    setDateFrom('')
    setDateTo('')
    setLogType([])
  }

  const handleApply = () => {
    navigate(pathname + fixedQuery, { replace: true })
    handleClose()
  }

  return (
    <FilterModal
      handleClose={handleClose}
      handleReset={handleReset}
      handleApply={handleApply}
      extraHeader={
        <ActiveFiltersIndicator
          filters={filterTemplate}
        />
      }
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.content}>
          <span>{content?.title}</span>
          {content?.description}
        </div>
      ))}
    </FilterModal>
  )
}