
export const employeeTypes = [
  {
    query: '',
    title: 'Tukang'
  },
  {
    query: 'cutter',
    title: 'Master'
  },
  {
    query: 'salesman',
    title: 'Sales'
  },
  // {
  //   query: 'driver',
  //   title: 'Driver'
  // },
]