import { stockTypes } from '@Assets/data/stockTypes'
import Icon from '@Atom/Icon'
import Input from '@Atom/Input'
import Tag from '@Atom/Tag'
import useQuery from '@Hooks/useQuery'
import ActiveFiltersIndicator from '@Molecule/ActiveFiltersIndicator'
import FilterModal from '@Molecule/_reusableLayout/FilterModal'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function DeliveryFilterModal({
  handleClose,
  trigger
}) {

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()

  const defaultTypes = query.get('tt') || ''
  const defaultScheduledAt = query.get('sa') || ''
  const defaultDeliveredAt = query.get('da') || ''
  const defaultItemStatus = useMemo(() => { return query.get('is')?.split('-') || [] }, [query])


  const [types, setTypes] = useState(defaultTypes)
  const [scheduledAt, setScheduledAt] = useState(defaultScheduledAt)
  const [deliveredAt, setDeliveredAt] = useState(defaultDeliveredAt)
  const [itemStatus, setItemStatus] = useState(defaultItemStatus)


  useEffect(() => {
    setTypes(defaultTypes)
    setScheduledAt(defaultScheduledAt)
    setDeliveredAt(defaultDeliveredAt)
  }, [defaultDeliveredAt, defaultScheduledAt, defaultTypes, trigger])

  const statusTemplate = useMemo(() => {
    return [
      {
        status: 'ONPROCESS',
        type: 'yellow',
        text: 'On Process'
      },
      {
        status: 'DELIVERED',
        type: 'green',
        text: 'Delivered'
      },
      {
        status: 'READY',
        type: 'black',
        text: 'Ready'
      },
      {
        status: 'SCHEDULED',
        type: 'blue',
        text: 'Scheduled'
      },

    ]
  }, [])

  const allQueries = useMemo(() => {
    const arr = []
    for (const param of query) {
      arr.push(param);
    }

    const newArr =
      !arr?.find(obj => obj[0] === 'tt')
        ? (
          !arr?.find(obj => obj[0] === 'sa')
            ?
            (
              !arr?.find(obj => obj[0] === 'da')
                ?
                (
                  !arr?.find(obj => obj[0] === 'is')
                    ?
                    [...arr, ['tt', types], ['sa', scheduledAt], ['da', deliveredAt], ['is', itemStatus?.join('-')]]
                    :
                    [...arr, ['tt', types], ['sa', scheduledAt], ['da', deliveredAt]]
                )
                :
                [...arr, ['tt', types], ['sa', scheduledAt]]

            )
            :
            [...arr, ['df', types]]
        )
        : [...arr]

    return newArr
  }, [deliveredAt, itemStatus, query, scheduledAt, types])


  const fixedQuery = useMemo(() => {
    let str = ''

    const raw = allQueries.join(',')?.split(',')

    const strArr = []

    for (let i = 0; i < raw.length / 2; i++) {
      strArr.push({
        key: raw[i + i],
        value: raw[i + i] === 'tt'
          ? types || ''
          : (
            raw[i + i] === 'sa'
              ? scheduledAt || ''
              :
              (raw[i + i] === 'da'
                ? deliveredAt || ''
                : (
                  raw[i + i] === 'is'
                    ?
                    itemStatus?.join('-') || ''
                    :
                    raw[i + i + 1]
                )
              )
          )
      })
    }


    for (let i = 0; i < strArr.length; i++) {
      const s = strArr[i]
      str += (i === 0 ? `${s?.key}=${s?.value}` : `&${s?.key}=${s?.value}`)
    }


    return str

  }, [allQueries, deliveredAt, itemStatus, scheduledAt, types])

  const filterTemplate = useMemo(() => {
    const arr = []
    if (types) {
      const typesArr = types.split('-')
      for (let i = 0; i < typesArr.length; i++) {
        const type = typesArr[i]
        arr.push({
          title: stockTypes?.find(obj => obj?.query === type)?.title || '-',
          handleRemove: () => {
            const newTypesArr = typesArr?.filter(obj => obj !== type)
            setTypes(newTypesArr?.join('-'))
          }
        })
      }
    }

    if (scheduledAt) {
      arr.push({
        title: `Schedule Date: ${scheduledAt?.replaceAll('-', '/')}`,
        handleRemove: () => {
          setScheduledAt('')
        }
      })
    }
    if (deliveredAt) {
      arr.push({
        title: `Delivery Date: ${deliveredAt?.replaceAll('-', '/')}`,
        handleRemove: () => {
          setDeliveredAt('')
        }
      })
    }

    if (itemStatus?.length) {
      for (let i = 0; i < itemStatus?.length; i++) {
        const status = itemStatus[i];
        arr.push({
          title: `Status: ${statusTemplate?.find(obj => obj?.status === status)?.text}`,
          handleRemove: () => setItemStatus(s => [...s?.filter(s !== status)])
        })
      }

    }

    return arr
  }, [deliveredAt, itemStatus, scheduledAt, statusTemplate, types])

  const contentTemplate = useMemo(() => {
    return [
      {
        title: 'Scheduled At',
        description: (
          <div className={Styles.dateWrapper}>
            <Input
              value={scheduledAt}
              setValue={setScheduledAt}
              placeholder={'Select date'}
              type='date'
            // isDate
            />
          </div>

        )
      },
      {
        title: 'Delivered At',
        description: (
          <div className={Styles.dateWrapper}>
            <Input
              value={deliveredAt}
              setValue={setDeliveredAt}
              placeholder={'Select date'}
              type='date'
            // isDate
            />
          </div>

        )
      },

      {
        title: 'Status',
        description: (
          <div className={Styles.status}>
            {statusTemplate?.map((status, i) => (
              <div key={i}>
                <button
                  // onClick={() => setItemStatus(status?.status)}
                  onClick={() => {
                    const found = itemStatus?.find(obj => obj === status?.status)
                    if (!found) {
                      setItemStatus(s => [...s, status?.status])
                    } else {
                      setItemStatus(s => [...s?.filter(obj => obj !== status?.status)])
                    }
                  }}
                >
                  <Tag
                    type={status?.type}
                    title={status?.text}
                  />
                </button>

                {itemStatus?.find(obj => obj === status?.status) && (
                  <button
                    // onClick={() => setItemStatus('')}
                    onClick={() => {
                      const found = itemStatus?.find(obj => obj === status?.status)
                      if (!found) {
                        setItemStatus(s => [...s, status?.status])
                      } else {
                        setItemStatus(s => [...s?.filter(obj => obj !== status?.status)])
                      }
                    }}
                  >
                    <Icon icon="x-circle" size={20} />
                  </button>
                )}
              </div>
            ))}


          </div>
        )
      },

    ]
  }, [deliveredAt, itemStatus, scheduledAt, statusTemplate])

  const handleReset = () => {
    setTypes('')
    setScheduledAt('')
    setDeliveredAt('')
    setItemStatus([])
  }

  const handleApply = () => {
    navigate(pathname + `?${fixedQuery}`, { replace: true })
    handleClose()
  }

  return (
    <FilterModal
      handleClose={handleClose}
      handleReset={handleReset}
      handleApply={handleApply}
      extraHeader={
        <ActiveFiltersIndicator
          filters={filterTemplate}
        />
      }
    >
      {contentTemplate?.map((content, i) => (
        <div key={i} className={Styles.content}>
          <span>{content?.title}</span>
          {content?.description}
        </div>
      ))}
    </FilterModal>
  )
}