import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function RekhasBG({
  children,
  isMasterEmployee = false,
  activeTab = null,
  setActiveTab = () => { },
  fullHeight,
  maxHeight='980px',
  ...props
}) {

  const employeeTableTypes = [
    {
      iconName: 'list-bullet',
      label: 'List',
      query: 'list'
    },
    {
      iconName: 'fee',
      label: 'Fee',
      query: 'fee'
    },
  ]


  return (
    <div className={Styles.container} style={{ height: isMasterEmployee ? 'auto' : (fullHeight ? '100vh' : 'calc(100vh - 6rem)') , maxHeight}}>
      {isMasterEmployee
        &&
        <div className={Styles.header}>
          <div className={Styles.title}>
            <Icon icon={'user-group'} size={48} />
            <span>Employee</span>
          </div>
          <div className={Styles.types}>
            {employeeTableTypes?.map((obj, i) => (
              <button
               key={i} 
               className={activeTab === obj?.query ? Styles.active : ''}
               onClick={()=> setActiveTab(obj?.query)}
               >
                <Icon icon={obj?.iconName} size={28} />
                <span>{obj?.label}</span>
              </button>
            ))}
          </div>
        </div>

      }
      {/* <RekhasTextLogo />
      <RekhasTextLogo />
      <RekhasTextLogo />
      <RekhasTextLogo /> */}
      <div className={`${Styles.contentContainer} ${isMasterEmployee ? Styles.full : ''}`} >
        {children}
      </div>
    </div>
  )
}