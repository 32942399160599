import { useMemo } from 'react'
import Styles from './style.module.scss'
import Button from '@Atom/Button'
import { updateOrderStatus } from '@Services/order'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function OrderActionConfirmationModal({
  handleClose = () => { },
  setTrigger = () => { },
  type = 'hold',
  orderId
}) {

  const template = useMemo(() => {
    switch (type) {
      case 'cancel':
        return ({
          title: 'Cancel Order',
          desc: 'Are you sure you want to cancel this order?',
          extraDesc: 'Once order cancelled it can’t be retrieved',
          actionText: 'Yes, cancel this order',
          handleConfirm: async () => {
            try {
              await updateOrderStatus(orderId, 'CANCEL')
              setTrigger(makeRandomString(5))
              handleClose()
            } catch (err) {
              console.log(err)
            }
          }
        })
      case 'resume':
        return ({
          title: 'Resume Order',
          desc: 'Are you sure you want to resume this order?',
          actionText: 'Yes, resume this order',
          handleConfirm: async () => {
            try {
              await updateOrderStatus(orderId, 'ON-GOING')
              setTrigger(makeRandomString(5))
              handleClose()
            } catch (err) {
              console.log(err)
            }
          }
        })
      default:
        return ({
          title: 'Hold Order',
          desc: 'Are you sure you want to hold this order?',
          actionText: 'Yes, hold this order',
          handleConfirm: async () => {
            try {
              await updateOrderStatus(orderId, 'HOLD')
              setTrigger(makeRandomString(5))
              handleClose()
            } catch (err) {
              console.log(err)
            }

          }
        })
    }

  }, [handleClose, orderId, setTrigger, type])


  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <h3 className={type === 'hold' || type === 'resume' ? Styles.hold : Styles.cancel}>{template?.title}</h3>
        <p>{template?.desc}</p>
        {template?.extraDesc && <span>{template?.extraDesc}</span>}

      </div>
      <div className={Styles.actions}>
        <Button
          variant='secondary'
          buttonText='No, back to details'
          onClick={handleClose}
          size='lg'
        />
        <Button
          variant='primary'
          buttonText={template?.actionText}
          onClick={template?.handleConfirm}
          size='lg'
        />
      </div>
    </div>
  )
}