import Button from '@Atom/Button'
import ModalHeader from '@Atom/ModalHeader'
import InputField from '@Molecule/InputField'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function SelectCategoryModal({
  handleClose = () => { },
  productCode = ''
}) {

  const [category, setCategory] = useState('')
  const navigate = useNavigate()




  return (
    <div className={`${Styles.container} ${Styles.auto}`}>

      <ModalHeader
        title={'Select Category'}
        // iconName={'plus'}
        withIcon
        withCloseButton
        handleClose={handleClose}
        paddingX='2.4rem'
        paddingY='1.6rem'
        isSticky
      />

      <div className={Styles.content}>

        <div className={Styles.formContainer}>

          <InputField
            title={'Category'}
            placeholder={'Category'}
            value={category}
            setValue={setCategory}
            isDropdown
            isSelect
            dropdownOptions={[
              'SHIRT',
              'SUIT',
              'TROUSER'
            ]}
          />

        </div>

      </div>

      <div className={Styles.actionsWrapper}>
        <Button
          type='button'
          variant='secondary'
          buttonText='Cancel'
          size='lg'
          onClick={handleClose}
        />
        <Button
          type='button'
          variant='primary'
          buttonText='Proceed'
          size='lg'
          disabled={!category}
          onClick={() => navigate(`/product/collection/start-measure/${productCode}?c=${category}`)}

        />
      </div>


    </div>
  )
}